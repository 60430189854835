<template>
  <div id="UbuCommentThreadList">
    <UbuCommentThreadListHeader
      v-model="localSearch"
      :show-closed-threads="showClosedThreads"
      :thread-list-filtered-by-open-close="threadListFilteredByOpenClose"
      :bulks="bulks"
      :is-bulk-pending="isBulkPending"
      :has-media-selected="hasMediaSelected"
      :is-thread-loading="isThreadLoading"
      :sort-options="sortOptions"
      :sort="sort"
      @changeOpenCloseFilter="$emit('changeOpenCloseFilter', $event)"
      @addAllToBulks="$emit('addAllToBulks', threadListFilteredByOpenClose)"
      @clearBulks="$emit('clearBulks', $event)"
      @bulksAction="$emit('bulksAction', threadListFilteredByOpenClose)"
      @refreshComments="$emit('refreshComments')"
      @updateSort="$emit('updateSort', $event)"
      @closeAll="$emit('closeAll', $event)"
    >
      <template slot="bulkActions">
        <UbuButton
          v-if="!showClosedThreads"
          color="2"
          center
          :icon="{name: 'success', filled: true}"
          :loading="isBulkPending"
          @click="$emit('closeManyThreads', true)"
        />
        <UbuButton
          v-else
          color="5"
          center
          :icon="{ name: 'messages', filled: true }"
          :loading="isBulkPending"
          @click="$emit('openManyThreads', false)"
        />
        <b-tooltip
          class="is-flex"
          position="is-left"
          label="You can ask our support to do it"
          multilined
        >
          <UbuSelector
            class="is-flex"
            :disabled="true"
            v-bind="campaignSelector.props || {}"
            v-on="campaignSelector.listeners || {}"
            @select="onBulkCommentCampaignSelected({ campaign: $event, bulks })"
          />
        </b-tooltip>
        <!-- <b-modal
          id="UbuInfluenceLeadsImporterModal"
          v-model="isCardModalActive"
          scroll="keep"
        >
          <UbuInfluenceLeadsImporterModalList
            class="is-color5"
            :list="bulkSuccess"
            :title="'Successfully added'"
            :is-research-ended="isResearchEnded"
          />
          <UbuInfluenceLeadsImporterModalList
            class="is-color8"
            :list="bulkAlready"
            :title="'Already in campaign'"
            :is-research-ended="true"
          />
          <UbuInfluenceLeadsImporterModalList
            class="is-color2"
            :list="bulkFail"
            :title="'Not found'"
            :is-research-ended="isResearchEnded"
          />
          <UbuButton
            class="mutibar column end"
            center
            @click="isCardModalActive = !isCardModalActive"
          >
            Okay
          </UbuButton>
        </b-modal> -->
      </template>
    </UbuCommentThreadListHeader>

    <!-- <pre>
      {{ media }}
    </pre> -->
    <UbuCommentThreadCard
      v-if="media"
      :key="media.mediaFBId"
      class="UbuThreads_stickyCard is-top"
      :comment="{
        from: {
          profilePicture: media.thumbnail_url || media.media_url,
          fullName: 'Post',
          isVideo: !media.thumbnail_url && media.media_type === 'VIDEO',
        },
        createdAt: media.createdAt,
        children: allChilds,
        text: media.caption || media.description || media.title,
        isPost: true,
      }"
      :is-thread-selected="media.mediaFBId === selectedThreadId"
      @onSelectCard="$emit('onSelectCard', media.mediaFBId)"
    />
    <hr class="ubu-divider thin">

    <RecycleScroller
      v-if="threadListFilteredByOpenClose.length"
      v-slot="{ item }"
      key-field="commentId"
      class="virtualScroller"
      :item-size="72"
      :buffer="600"
      :items="threadListFilteredByOpenClose"
    >
      <UbuCommentThreadCard
        :key="item.commentId"
        :comment="item"
        :is-thread-selected="item.commentId === selectedThreadId"
        :is-bulk-selected="!!bulks.find((b) => b.commentId === item.commentId)"
        @onSelectCard="$emit('onSelectCard', item.commentId)"
        @onBulkSelect="$emit('onBulkSelect', item)"
      />
    </RecycleScroller>
    <div
      v-else
      class="has-text-centered"
    >
      <p class="no-comment">No {{ showClosedThreads ? 'closed' : 'open' }} comments...</p>
    </div>
  </div>
</template>

<script>
import AddToCampaignMixin from '@/addons/$mixins/AddToCampaign';
/**
 * AddToCampaignMixin.js
 * Props:
 *  campaignSelect
 *  modalCampaignAdded
 */
export default {
  name: 'UbuCommentThreadList',
  mixins: [AddToCampaignMixin],
  model: {
    prop: 'search',
  },
  props: {
    media: {
      type: Object,
      default: () => null,
    },
    allChilds: {
      type: Array,
      default: () => [],
    },
    threadListFilteredByOpenClose: {
      type: Array,
      default: () => [],
    },
    source: {
      type: String,
      default: null,
    },
    search: {
      type: String,
      required: true,
    },
    showClosedThreads: {
      type: Boolean,
      default: false,
    },
    hasMediaSelected: {
      type: Boolean,
      default: false,
    },
    selectedThreadId: {
      type: String,
      default: '',
    },
    bulks: {
      type: Array,
      default: () => [],
    },
    isThreadLoading: {
      type: Boolean,
      default: false,
    },
    isBulkPending: {
      type: Boolean,
      default: false,
    },
    sortOptions: {
      type: Array,
      default: () => [],
    },
    sort: {
      type: String,
      default: 'asc:timestamp',
    },
  },
  computed: {
    localSearch: {
      get() { return this.search; },
      set(value) { this.$emit('input', value); },
    },
  },
};
</script>
