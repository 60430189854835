const listData = [
  {
    username: 'toto',
    profile_pic_url: 'https://picsum.photos/200',
    contactId: 585716476,
    addedOn: 1624960802,
    followers: 1000000,
    engagement: 5,
    location: 'Paris',
    language: 'French',
    gender: 'Male',
    keywords: 'Influencer',
    businessCategory: 'Photography',
  },
  {
    username: 'tata',
    profile_pic_url: 'https://picsum.photos/200',
    contactId: 8744298542,
    addedOn: 1624960700,
    followers: 150,
    engagement: 50,
    location: 'Paris',
    language: 'English',
    gender: 'Female',
    keywords: 'Influencer',
    businessCategory: 'Photography',
  },
  {
    username: 'titi',
    profile_pic_url: 'https://picsum.photos/200',
    contactId: 4543272141,
    addedOn: 1624960602,
    followers: 810,
    engagement: 85,
    location: 'London',
    language: 'Arabic',
    gender: 'Unknown',
    keywords: 'Influencer',
    businessCategory: 'Photography',
  },
  {
    username: 'toto',
    profile_pic_url: 'https://picsum.photos/200',
    contactId: 585716476,
    addedOn: 1624960802,
    followers: 10,
    engagement: 5,
    location: 'Paris',
    language: 'French',
    gender: 'Male',
    keywords: 'Influencer',
    businessCategory: 'Photography',
  },
];

export {
  // eslint-disable-next-line import/prefer-default-export
  listData,
};
