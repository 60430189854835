<template>
  <section id="TheMentionsGallery">
    <h1 class="ubu-title">
      Mentions
      <sup v-if="nbMentions !== '0'">{{ nbMentions }}</sup>
    </h1>
    <UbuMentionsFilters
      v-model="searchValue"
      class="TheMentionsGallery_filters"
      :disabled="loading"
      :is-loading="isLoading"
      :table-view="tableView"
      :show-stories="showStories"
      :show-posts="showPosts"
      :check-box-filters="checkBoxFilters"
      :checked-rows="checkedRows"
      :campaign-list="campaignList"
      :contact-groups="Object.values(contactGroups)"
      @changeDate="fetchMentions($event)"
      @setRangeFilter="setRangeFilter($event)"
      @setEngagementFilter="setEngagementFilter($event)"
      @setCheckFilter="setCheckFilter($event)"
      @toggleTableView="setTableView($event)"
      @toggleStories="setStories($event)"
      @togglePosts="setPosts($event)"
      @clearCheckedRow="checkedRows = []"
      @selectCampaign="addContactsIntoCampaign($event)"
      @assignContactsToContactGroup="assignContactsToContactGroup($event)"
      @createContactGroupAndAssignMany="createContactGroupAndAssignMany($event)"
    />
    <div class="TheMentionsGallery_stats">
      <UbuCommonAnalyticsCard
        :value="filteredMentions.length"
        title="Total mentions"
        icon="mentions"
        :color="9"
        :size="20"
        :loading="loading"
      />
      <!-- tooltip-label="The total count of likes comments and save" -->
      <UbuCommonAnalyticsCard
        v-if="showPosts || tableView"
        :value="numberOfInteractions"
        title="Total interactions"
        icon="influence"
        :color="8"
        :size="20"
        :loading="loading"
      />
      <!-- tooltip-label="The total count of estimated reach based on
        engagement and number of followers of people mentioning you" -->
      <UbuCommonAnalyticsCard
        :value="estimatedReach"
        title="Total reach"
        icon="eye"
        :color="3"
        :size="20"
        :loading="loading"
      />
      <!-- tooltip-label="The average engagement of all the posts mentioning you" -->
      <UbuCommonAnalyticsCard
        v-if="(showPosts || tableView) && averagePostEngagement"
        :value="averagePostEngagement"
        title="Engaement per post"
        icon="mentions"
        :color="9"
        :size="20"
        :loading="loading"
        is-percent
      />
      <!-- tooltip-label="The number of
      followers earned during this period thanks to those mentions" -->
      <UbuCommonAnalyticsCard
        :value="followersEarned"
        title="Followers Earned"
        icon="account"
        :color="12"
        :size="20"
        :loading="loading"
        is-persent
      />
    </div>
    <div class="flex mb-4">
      <UbuButton
        v-if="filteredMentions.length && !tableView"
        class="UbuWorkspaceCustoms_button"
        secondary
        :loading="mentionsDownloading"
        @click="downloadMentions({ mentions: filteredMentions })"
      >
        Download all
      </UbuButton>
      <UbuButton
        v-if="isReportFetched"
        class="UbuWorkspaceCustoms_button ml-2"
        secondary
        :loading="mentionsDownloading"
        @click="isModalAutoReport = true"
      >
        Auto report
      </UbuButton>
    </div>
    <!-- <div
      v-if="!loading && !isDriveActive"
      class="mb-4 ml-4"
    >
      <p class="mb-2">Please check if your Google Drive is connected</p>
      <b-button
        to="/workspace/connection"
        class="TheMentionsGallery_button ubuButton is-primary"
        tag="router-link"
      >
        Connection
      </b-button>
    </div> -->
    <UbuCommonMentionsCarousel
      v-if="showGallery"
      v-model="indexMedia"
      :items="currentMentions"
      @close="showGallery = false; influencerMentions = []"
      @openNewMessage="openNewMessage($event)"
    />
    <div
      v-if="loading && !tableView"
      class="grid"
    >
      <UbuCommonMentionCard
        v-for="index in 20"
        :key="index"
        is-loading
      />
    </div>
    <div
      v-else-if="!tableView"
      class="grid"
    >
      <UbuCommonMentionCard
        v-for="media in currentMentions"
        :key="media.id"
        :media="media"
        @show="showFullScreen(currentMentions.indexOf(media))"
      />
    </div>
    <UbuInfluenceMentionsTable
      v-else-if="tableView"
      :list-filtered="filteredInfluencers"
      :checked-rows="checkedRows"
      :thread-groups-list="Object.values(threadGroups)"
      :contact-groups-list="Object.values(contactGroups)"
      :campaign-list="campaignList.filter(({ ended }) => !ended)"
      :is-generate-report-loading="isGenerateReportLoading"
      :is-statistics-loading="isStatisticsLoading"
      @showCarousel="showInfluencerMentions($event)"
      @checkRows="checkRows($event)"
      @addCustom="addCustom($event)"
      @removeCustom="removeCustom($event)"
      @generateReport="generateReport($event)"
      @updateContact="updateContact($event)"
      @createThreadGroupAndAssign="createThreadGroupAndAssign($event)"
      @createContactGroupAndAssign="createContactGroupAndAssign($event)"
      @openNewMessage="openNewMessage($event)"
      @fetchTiktokUser="fetchTiktokUser($event)"
      @openModalEditUsernameTiktok="showModalEditUsernameTiktok($event)"
    />
    <div
      v-if="!loading && filteredMentions.length === 0"
      class="TheMentionsGallery_noResult"
    >
      <p v-if="filteredMentions.length === 0">
        Oops, no mentions were found for those filters 📡
      </p>
    </div>
    <b-pagination
      v-if="!tableView"
      v-model="currentPage"
      class="TheMentionsGallery_pagination"
      :per-page="mentionsPerPage"
      :total="filteredMentions.length"
      :range-before="5"
      :range-after="5"
    />
    <div class="flex">
      <download-csv
        v-if="tableView"
        :data="filteredInfluencers"
        :fields="csvFields"
        :name="csvName"
      >
        <UbuButton
          class="UbuWorkspaceCustoms_button"
          secondary
        >
          Export Table
        </UbuButton>
      </download-csv>
    </div>
    <b-modal
      v-model="isMessageModalActive"
      :width="640"
      scroll="keep"
    >
      <UbuCommonNewMessageModal
        @close="isMessageModalActive = false"
        @sendNewMessage="sendNewMessage({user: userNewMessage, message: $event});"
      />
    </b-modal>
    <b-modal
      v-model="isEndedModalActive"
      :width="640"
      scroll="keep"
    >
      <UbuCommonModal
        title="Contact(s) added to campaign"
        @close="isEndedModalActive = false"
      >
        <template slot="info">
          <p>
            {{ contactAddedToCampaign }} have been added to campaign
          </p>
          <p>
            {{ contactAlreadyInCampaign }} were already in this campaign
          </p>
        </template>
        <template slot="actions">
          <UbuButton
            grow
            center
            secondary
            class="m-2"
            @click="isEndedModalActive = false"
          >
            Close
          </UbuButton>
        </template>
      </UbuCommonModal>
    </b-modal>
    <b-modal
      v-model="isModalEditUsernameTiktok"
      :width="640"
      scroll="keep"
    >
      <UbuContactPanelModalTiktokUsername
        @close="isModalEditUsernameTiktok = false"
        @addFromModal="updateTiktokUsername($event)"
      />
    </b-modal>
    <b-modal
      v-model="isModalAutoReport"
      :width="640"
      scroll="keep"
    >
      <UbuMentionsModalAutoReport
        :report="report"
        @close="isModalAutoReport = false"
        @addFromModal="subscribeReport($event)"
        @removeFromModal="deleteSubscribedReports($event)"
      />
    </b-modal>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { shortenNumber } from '../$utils/formate';
import MentionsMixin from './$mixins/MentionsMixin';
import {
  getTitleListWithoutDuplicate,
  getListWithoutDuplicate,
} from '../crm/$utils/filterManagement';

export default {
  name: 'TheMentionsGallery',
  mixins: [MentionsMixin],
  data() {
    return {
      showGallery: false,
      indexMedia: 0,
      isLoading: false,
      influencerMentions: [],
      searchValue: '',
      followerRange: [],
      averageReachRange: [],
      followersEarnedRange: [],
      currentLabelCheckList: [],
      currentLanguageCheckList: [],
      engagementRange: {},
      currentPage: 1,
      mentionsPerPage: 50,
      mentionsDownloading: false,
      dateFilter: [],
      tableView: false,
      showStories: true,
      showPosts: false,
      checkedRows: [],
      contactAddedToCampaign: 0,
      contactAlreadyInCampaign: 0,
      isEndedModalActive: false,
      isGenerateReportLoading: null,
      isMessageModalActive: false,
      isModalAutoReport: false,
      userNewMessage: null,
      isReportFetched: false,
      report: {},
      csvFields: ['username', 'followerCount', 'engagement', 'lastMentionOrTagPost', 'storyMentionCount', 'postMentionCount', 'mention', 'status', 'averagePostEngagement', 'averageReach', 'language', 'newFollowers'],
    };
  },
  computed: {
    ...mapGetters({
      fetchIsDone: 'Sequence/getterFetchDone',
      isDriveActive: 'Channel/getterIsDriveConnected',
      currentChannel: 'Channel/getterCurrentChannel',
      campaignActivitiesDetails: 'TheInfluence/getterCampaignActivitiesDetails',
      currentChannelId: 'Channel/getterCurrentChannelId',
      threadGroups: 'ThreadGroup/getterThreadGroups',
      contactGroups: 'ContactGroup/getterContactGroups',
      mentions: 'TheMentions/getterCurrentMentions',
      influencers: 'TheMentions/getterCurrentInfluencers',
    }),
    loading() {
      return this.isLoading || !this.fetchIsDone;
    },
    filteredMentions() {
      let result = this.influencerMentions.length ? this.influencerMentions : this.mentions;
      if (this.searchValue) {
        const mySearch = this.searchValue.toLowerCase();
        result = result.reduce((acc, media) => {
          const { username, caption, id } = media;
          if (username && username.toLowerCase().includes(mySearch)) {
            if (!acc.find((r) => r.id === id)) acc.push(media);
          }
          if (caption && caption.toLowerCase().includes(mySearch)) {
            if (!acc.find((r) => r.id === id)) acc.push(media);
          }
          return acc;
        }, []);
      }
      const influencersUsernames = this.filteredInfluencers.map((user) => user.username);
      result = result.filter((media) => influencersUsernames.includes(media.username));
      if (!this.showPosts) {
        result = result.filter((media) => media.product_type !== 'post');
      }
      if (!this.showStories) {
        result = result.filter((media) => media.product_type !== 'story');
      }
      if (this.followerRange.length === 2) {
        const [min, max] = this.followerRange;
        result = result.filter((media) => media.followers >= min && media.followers <= max);
      }
      if (this.engagementRange.min) {
        const { min, max } = this.engagementRange;
        result = result.filter(
          (mention) => {
            if (mention.instaUser && mention.instaUser.engagement) {
              return mention.instaUser.engagement >= min && mention.instaUser.engagement <= max;
            }
            return false;
          },
        );
      }
      return result;
    },
    filteredInfluencers() {
      let result = this.influencers || [];
      if (this.searchValue) {
        const mySearch = this.searchValue.toLowerCase();
        result = result.filter((user) => user.username.toLowerCase().includes(mySearch));
      }
      if (this.followerRange.length === 2) {
        const [min, max] = this.followerRange;
        result = result.filter((user) => user.followerCount >= min && user.followerCount <= max);
      }
      if (this.averageReachRange.length === 2) {
        const [min, max] = this.averageReachRange;
        result = result.filter((user) => user.averageReach >= min && user.averageReach <= max);
      }
      if (this.followersEarnedRange.length === 2) {
        const [min, max] = this.followersEarnedRange;
        result = result.filter((user) => user.newFollowers >= min && user.newFollowers <= max);
      }
      if (this.engagementRange.min) {
        const { min, max } = this.engagementRange;
        result = result.filter(
          (user) => user.engagement >= min && user.engagement <= max,
        );
      }
      if (this.currentLabelCheckList.length) {
        result = result
          .filter(({ contactGroup }) => {
            const labels = contactGroup.map(({ title }) => title);
            return labels.some((c) => this.currentLabelCheckList.includes(c));
          });
      }
      if (this.currentLanguageCheckList.length) {
        result = result
          .filter(({ language }) => (language
            ? this.currentLanguageCheckList.includes(language) : null));
      }
      return result;
    },
    currentMentions() {
      const start = (this.currentPage - 1) * this.mentionsPerPage;
      const end = this.currentPage * this.mentionsPerPage;
      return this.filteredMentions.slice(start, end);
    },
    estimatedReach() {
      let reach = 0;
      this.filteredMentions.forEach((mention) => {
        if (mention.instaUser && mention.instaUser.follower_count) {
          const engagement = (mention.instaUser.engagement < 5
            ? 5 : mention.instaUser.engagement) / 100 || 0.05;
          reach += engagement * mention.instaUser.follower_count * 5;
        }
      });
      return reach;
    },
    averagePostEngagement() {
      let engagement = 0;
      let nbUsersWithPosts = 0;
      this.filteredInfluencers.forEach((user) => {
        engagement += user.averagePostEngagement || 0;
        if (user.postMentionCount > 0) nbUsersWithPosts += 1;
      });
      if (engagement) engagement /= nbUsersWithPosts;
      if (engagement === 0) return null;
      return Math.round(engagement * 100) / 100;
    },
    numberOfInteractions() {
      let interactions = 0;
      this.filteredMentions.forEach((mention) => {
        interactions += mention.like_count || 0;
        interactions += mention.comments_count || 0;
      });
      return interactions;
    },
    nbMentions() {
      return shortenNumber(this.filteredMentions.length);
    },
    followersEarned() {
      return this.filteredInfluencers.reduce(
        (sum, user) => sum + (user.newFollowers || 0), 0,
      ) || 0;
    },
    labelList() {
      return getTitleListWithoutDuplicate(this.influencers, 'contactGroup');
    },
    languageList() {
      return getListWithoutDuplicate(this.influencers, 'language');
    },
    checkBoxFilters() {
      return [
        {
          name: 'contactGroup',
          buttonTitle: 'Labels',
          modalTitle: 'Labels',
          value: this.labelList,
        },
        {
          name: 'language',
          buttonTitle: 'Language',
          modalTitle: 'Language',
          value: this.languageList,
        },
      ];
    },
    csvName() {
      const { username } = this.currentChannel;
      let minDate = this.$moment();
      let maxDate = this.$moment();
      if (this.dateFilter) {
        if (this.dateFilter.type === 'simple') {
          minDate = minDate.subtract({ days: this.dateFilter.value });
        } else if (this.dateFilter.type === 'custom') {
          minDate = this.$moment(this.dateFilter.value[0]);
          maxDate = this.$moment(this.dateFilter.value[1]);
        }
      }
      const minDateStr = this.$moment(minDate).format('YYYY-MM-DD');
      const maxDateStr = this.$moment(maxDate).format('YYYY-MM-DD');
      if (this.$moment(minDate).isSame(maxDate, 'day')) return `influencers mentions ${username} ${minDateStr}.csv`;
      return `influencers mentions ${username} ${minDateStr} to ${maxDateStr}.csv`;
    },
  },
  watch: {
    fetchIsDone: {
      handler(isDone) {
        if (isDone) {
          this.getSubscribedReports();
          this.fetchMentions();
        }
      },
    },
  },
  mounted() {
    if (this.fetchIsDone) {
      this.getSubscribedReports();
      this.fetchMentions();
    }
  },
  methods: {
    ...mapActions({
      _assignThreadToThreadGroup: 'TheThreadHeader/assignThreadToThreadGroup',
      _removeThreadFromThreadGroup: 'TheThreadHeader/removeThreadFromThreadGroup',
      _createThreadGroupAndAssign: 'TheThreadHeader/createThreadGroupAndAssign',
      _assignContactToContactGroup: 'TheThreadHeader/assignContactToContactGroup',
      _removeContactFromContactGroup: 'TheThreadHeader/removeContactFromContactGroup',
      _createContactGroupAndAssign: 'TheThreadHeader/createContactGroupAndAssign',
      _generateReport: 'TheCRM/generateReport',
      _getSubscribedReports: 'TheMentions/getSubscribedReports',
      _subscribeReport: 'TheMentions/subscribeReport',
      _deleteSubscribedReports: 'TheMentions/deleteSubscribedReports',
      getContactByUsername: 'TheInfluence/getContactByUsername',
      deleteActivity: 'TheInfluence/deleteActivity',
      loadMentions: 'TheMentions/fetchMentions',
      dlMentions: 'TheMentions/downloadMentions',
      setUnscheduled: 'TheInfluence/setUnscheduled',
      updateContact: 'TheContactPanel/updateContact',
      sendMessage: 'TheInbox/sendMessage',
    }),
    fetchMentions(dateFilter) {
      this.isLoading = true;
      this.currentPage = 1;
      this.dateFilter = dateFilter;
      this.loadMentions({ dateFilter })
        .then(() => {
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    setEngagementFilter(range) {
      this.engagementRange = range;
    },
    showFullScreen(index) {
      this.indexMedia = index;
      this.showGallery = true;
    },
    folderNameGenerator() {
      const { username } = this.currentChannel;
      let minDate = this.$moment();
      let maxDate = this.$moment();
      if (this.dateFilter) {
        if (this.dateFilter.type === 'simple') {
          minDate = minDate.subtract({ days: this.dateFilter.value });
        } else if (this.dateFilter.type === 'custom') {
          minDate = this.$moment(this.dateFilter.value[0]);
          maxDate = this.$moment(this.dateFilter.value[1]);
        }
      }
      const minDateStr = this.$moment(minDate).format('YYYY-MM-DD');
      const maxDateStr = this.$moment(maxDate).format('YYYY-MM-DD');
      if (this.$moment(minDate).isSame(maxDate, 'day')) return `Mentions ${username} ${minDateStr}`;
      return `Mentions ${username} ${minDateStr} to ${maxDateStr}`;
    },
    downloadMentions({ mentions }) {
      this.mentionsDownloading = true;
      this.dlMentions({ mentions, folderName: this.folderNameGenerator() }).then(() => {
        this.mentionsDownloading = false;
        this.$buefy.snackbar.open({
          message: 'You will soon receive your mentions by email',
          type: 'is-success',
          position: 'is-bottom-right',
          indefinite: true,
        });
      }).catch(() => {
        this.mentionsDownloading = false;
      });
    },
    setTableView(event) {
      this.tableView = event;
      if (this.tableView) {
        this.showStories = true;
        this.showPosts = true;
      }
    },
    setStories() {
      this.showStories = true;
      this.showPosts = false;
      this.tableView = false;
    },
    setPosts() {
      this.showStories = false;
      this.showPosts = true;
      this.tableView = false;
    },
    showInfluencerMentions(mentions) {
      console.log('🚀 ~ file: TheMentionsGallery.vue ~ line 469 ~ showInfluencerMentions ~ mentions', mentions);
      this.influencerMentions = mentions;
      this.showFullScreen(0);
    },
    setRangeFilter(event) {
      const { name, rangeValues } = event;
      if (name === 'followerRange') this.followerRange = rangeValues;
      if (name === 'postRange') this.postRange = rangeValues;
      if (name === 'averageReachRange') this.averageReachRange = rangeValues;
      if (name === 'followersEarnedRange') this.followersEarnedRange = rangeValues;
    },
    setCheckFilter(event) {
      const { name, checked } = event;
      if (name === 'contactGroup') this.currentLabelCheckList = checked;
      if (name === 'language') this.currentLanguageCheckList = checked;
    },
    checkRows(event) {
      this.checkedRows = [...event];
    },
    getSubscribedReports() {
      this._getSubscribedReports()
        .then((response) => {
          this.isReportFetched = true;
          if (response.length) {
            [this.report] = response;
          }
        });
    },
    subscribeReport(payload) {
      this._subscribeReport({ payload })
        .then((response) => {
          if (response) {
            this.isModalAutoReport = false;
            this.$buefy.snackbar.open({
              message: 'Your changes were saved',
              type: 'is-success',
              position: 'is-bottom-right',
            });
            this.getSubscribedReports();
          }
        });
    },
    deleteSubscribedReports({ id }) {
      this._deleteSubscribedReports({ payload: { id } })
        .then((response) => {
          if (response) {
            this.isModalAutoReport = false;
            this.report = {};
            this.$buefy.snackbar.open({
              message: 'Your Auto report was deactivated',
              type: 'is-success',
              position: 'is-bottom-right',
            });
          }
        });
    },
  },
};
</script>
