<template>
  <div
    id="UbuInfluenceLeadsWaitingReview"
    class="pipelineTable multibar column"
  >
    <div class="pipelineTable_header multibar start row-vcentered">
      <p class="pipelineTable_counter start ubu-header4">
        {{ leads.length }}
      </p>
      <p class="pipelineTable_title start ubu-header4">
        Waitings for review
      </p>
      <p class="pipelineTable_counter end">
        <b-button
          v-if="checkedRows.length === 0 || checkedRows.length === leads.length"
          class="is-rounded has-campaign-color"
          @click="$emit('reviewAll', leads)"
        >
          review all
        </b-button>
        <b-button
          v-else
          class="is-rounded has-campaign-color"
          @click="$emit('reviewSelecteds', checkedRows)"
        >
          review selected
        </b-button>
      </p>
    </div>

    <div
      v-if="leads.length"
      class="start pipelineTable_content"
    >
      <form>
        <b-field>
          <b-input
            v-model="search"
            placeholder="Search for a contact"
            icon="magnify"
            class="ubuInput"
            expanded
          />
        </b-field>
      </form>
      <b-table
        :data="filteredLeads"
        :checked-rows.sync="localCheckedRows"
        checkable
      >
        <b-table-column
          field="username"
        >
          <template v-slot="{ row: contact }">
            <UbuContactCard
              :contact="contact"
              class=""
            />
          </template>
        </b-table-column>

        <b-table-column
          field="actions"
        >
          <template v-slot="{ row: contact }">
            <div class="multibar">
              <b-button
                class="ubuInfluenceButton is-rounded end"
                @click="$emit('reviewOne', contact)"
              >
                Review
              </b-button>

              <b-button
                class="ubuInfluenceIconButton is-rounded end"
                @click="$emit('removeOneLead', contact)"
              >
                <UbuIcon
                  size="16px"
                  name="cross"
                  outlined
                  clickable
                  @click="$emit('removeOneLead', contact)"
                />
              </b-button>
            </div>
          </template>
        </b-table-column>
      </b-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UbuInfluenceLeadsWaitingReview',
  props: {
    leads: {
      type: Array,
      required: true,
    },
    checkedRows: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      search: '',
    };
  },
  computed: {
    localCheckedRows: {
      get() { return this.checkedRows; },
      set(value) { this.$emit('updateCheckeds', value); },
    },
    filteredLeads() {
      return this.search
        ? this.leads
          .filter(({ username, full_name: fn }) => username.toLowerCase().includes(this.search)
          || (fn && fn.toLowerCase().includes(this.search)))
        : this.leads;
    },
  },
};
</script>

<style lang="scss">
#UbuInfluenceLeadsWaitingReview {
  & .pipelineTable_content form {
    padding-left: 16px;
    padding-right: 16px;
    min-width: 600px;
  }
  & .table td, & .table th {
    border: none;
    & .b-checkbox.checkbox input[type=checkbox] + .check {
      // width: 36px;
      // height: 36px;
      // border-radius: 50%;
      border: 1px solid var(--mercury);
      margin: 0px;
    }
  }
}
</style>
