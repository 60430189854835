<template>
  <section id="TheWorkspaceTeam">
    <div class="workspace-view-header">
      <h1 class="ubu-header1">Team</h1>
      <UbuButton @click="getToken()">
        Invite teammates
      </UbuButton>
      <!-- <div
        v-else
        class="TheUbuWorkspaceTeam_generateLink"
      >
        <p>Invitation link:</p>
        <div class="flex row x-centered">
          <UbuIcon
            name="bookmark"
            filled
          />
          <div
            v-clipboard:click="fullLink"
            class="TheUbuWorkspaceTeam_link"
          >
            {{ fullLink }}
          </div>
        </div>
      </div> -->
    </div>
    <div
      v-for="user in localUbuUsers"
      :key="user.ubuUserId"
      class="TheUbuWorkspaceTeam_list"
    >
      <div class="TheUbuWorkspaceTeam_row">
        <UbuWorkspaceUserTemplate
          :user="user"
        />

        <UbuButton
          class="a-self-center"
          color="2"
          @click="deleteTeamMember(user)"
        >
          Remove
        </UbuButton>
      </div>
      <div class="TheUbuWorkspaceTeam_divider" />
    </div>
    <b-modal
      v-model="openModalInvitation"
      :width="640"
      scroll="keep"
    >
      <UbuCommonModal title="Invitation link">
        <div
          slot="info"
          class="mt-6 mb-6"
        >
          <UbuSimpleInput
            v-model="fullLink"
            readonly
            searchbar
            class="text-color-2 mb-5"
          >
            <template slot="append-icon">
              <UbuIcon
                v-clipboard:click="fullLink"
                name="compose"
                color="2"
                clickable
                @click="copiedSnackbar()"
              />
            </template>
          </UbuSimpleInput>
          <div class="pl-1">
            Sharing this link with your teammates will give them access to this channel.
            It will expired in 2 hours.
          </div>
        </div>
        <template slot="actions">
          <UbuButton
            grow
            center
            @click="openModalInvitation = false"
          >
            Close
          </UbuButton>
        </template>
      </UbuCommonModal>
    </b-modal>
  </section>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { snackSuccess, snackError } from '@/addons/$catalog/snackbar';
import routes from '../../../conf/routes';

export default {
  name: 'TheWorkspaceTeam',
  data() {
    return {
      baseUrl: `${routes.api.url}/auth/login/`,
      openModalInvitation: false,
    };
  },
  computed: {
    ...mapGetters({
      channelUsers: 'TheWorkspace/channelUsers',
      currentChannelId: 'Channel/getterCurrentChannelId',
      currentInviteToken: 'TheWorkspace/getterCurrentInviteToken',
    }),
    localUbuUsers() {
      return this.channelUsers.users
        .filter((ubuUser) => ubuUser.ubuUserId !== this.channelUsers.me.ubuUserId)
        .map((ubuUser) => (ubuUser.initials
          ? ubuUser
          : ({
            ...ubuUser,
            initials: `${ubuUser.firstName.charAt(0)}${ubuUser.lastName.charAt(0)}`,
          })));
    },
    fullLink() {
      return this.baseUrl + this.currentInviteToken;
    },
  },
  methods: {
    ...mapActions({
      addMember: 'TheWorkspace/addMember',
      deleteMember: 'TheWorkspace/deleteMember',
      getInviteToken: 'TheWorkspace/getInviteToken',
    }),

    getToken() {
      if (!this.currentInviteToken) {
        this.getInviteToken({ channelId: this.currentChannelId })
          .then(({ response }) => {
            if (response) {
              this.openModalInvitation = !this.openModalInvitation;
            } else {
              this.$buefy.snackbar.open({
                ...snackError,
                message: 'We can\'t generate an invitation link',
                onAction: () => this.getToken(),
              });
            }
          });
      } else {
        this.openModalInvitation = !this.openModalInvitation;
      }
    },

    copiedSnackbar() {
      this.$buefy.snackbar.open({
        ...snackSuccess,
        message: 'Link copied to clipboard',
      });
    },

    deleteTeamMember(user) {
      this.deleteMember({ ...user, channelId: this.currentChannelId });
    },
  },
};
</script>
