<template>
  <div
    :class="['UbuBadge_wrapper', { UbuBadge_important: important }]"
    v-on="$listeners"
  >
    <span class="UbuBadge_round" />
    <span
      v-if="value"
      class="UbuBadge_value"
    >
      {{ value }}
    </span>
  </div>
</template>
<script>
export default {
  name: 'UbuBadge',
  props: {
    important: Boolean,
    value: {
      type: [Number, String],
      default: 0,
    },
    color: {
      type: String,
      default: 'narvalo',
    },
  },
};
</script>
<style lang="scss" scoped>
.UbuBadge_wrapper {
  display: flex;
  padding: 2px 6px;
  background-color: transparent;
  align-items: center;
  font-size: 0.75rem;

  .UbuBadge_round {
    background-color: var(--cloudburst60);
    width: 4px;
    height: 4px;
    border-radius: 50%;
  }

  .UbuBadge_value {
    margin-left: 10px;
    color: var(--cloudburst60);
    font-weight: 500;
  }

  &.UbuBadge_important {
    .UbuBadge_round {
      background-color: var(--color2);
      width: 6px;
      height: 6px;
    }

    .UbuBadge_value {
      margin-left: 8px;
      font-weight: 600;
    }
  }
}
</style>
