<template>
  <!-- itms {{ items }} -->
  <UbuButton
    v-click-outside="e => dropdownOpened = false"
    class="UbuDropdownSelector_trigger"
    :active="dropdownOpened"
    center
    :tooltip="tooltip"
    v-bind="$attrs"
    :color="values.length === 1 ? values[0][colorProp] : null"
    @click="dropdownOpened = !dropdownOpened"
  >
    <template slot="icon">
      <div class="UbuDropdownSelector_selectedIcon ellipsis">
        <UbuIcon
          v-if="!values.length"
          class=""
          filled
          :name="icon"
        />
        <UbuIcon
          v-for="(value, index) in values"
          :key="`selected-${index}`"
          filled
          class=""
          :name="icon"
          :color="values.length > 1 ? value[colorProp] : null"
        />
      </div>
    </template>
    <template v-if="values.length === 1">
      <span class="ellipsis">{{ values[0][labelProp] }}</span>
    </template>
    <template slot="dropdown">
      <!-- {{ value }} -->
      <div
        v-if="!textSended"
        class="UbuDropdownSelector_content"
      >
        <UbuSimpleInput
          v-model="searchText"
          searchbar
          style="margin-bottom: 12px"
          :placeholder="placeholder"
          focus
        />
        <div
          v-for="(item) in filterByText(values)"
          :key="item._id"
          class="UbuDropdownSelector_row"
        >
          <div :class="`bg-color-${item[colorProp] || '1'} ubu-text2 UbuDropdownSelector_item ellipsis`">
            <UbuIcon
              size="17.5px"
              filled
              :name="icon"
            />
            <span class="ellipsis">
              {{ item[labelProp] }}
            </span>
          </div>
          <UbuIcon
            size="17.5px"
            clickable
            color="2"
            name="failure"
            @click="removeItem(item)"
          />
        </div>
        <hr v-if="filterByText(selectables).length && filterByText(values).length">
        <div
          v-for="(item) in filterByText(selectables)"
          :key="item._id"
          class="UbuDropdownSelector_row cursor-pointer"
          @click="selectItem(item)"
        >
          <div
            :class="`text-color-${item[colorProp] || '1'}
            color-text-hover ubu-text2 UbuDropdownSelector_item ellipsis`"
          >
            <UbuIcon
              size="17.5px"
              filled
              :name="icon"
            />
            {{ item[labelProp] }}
          </div>
          <UbuIcon
            size="17.5px"
            name="add"
          />
        </div>

        <div v-if="searchText.length">
          <span>Create new {{ kind }}:</span>
          <UbuButton
            grow
            :icon="icon"
            @click="sendLabel($event)"
          >
            {{ searchText }}
          </UbuButton>
        </div>
      </div>
      <UbuColorPicker
        v-if="textSended"
        :max-per-row="3"
        :icon="icon"
        @selectColor="selectColor($event)"
      />
    </template>
  </UbuButton>
</template>

<script>
export default {
  name: 'UbuDropdownSelector',
  props: {
    values: {
      type: Array,
      default: () => [],
    },
    kind: {
      type: String,
      default: 'tag',
    },
    items: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: 'tag',
    },
    labelProp: {
      type: String,
      default: 'title',
    },
    colorProp: {
      type: String,
      default: 'color',
    },
    idProp: {
      type: String,
      default: 'customTagId',
    },
    tooltip: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    searchText: '',
    textSended: false,
    dropdownOpened: false,
  }),
  computed: {
    selectables() {
      const selectables = this.items
        .filter((item) => !this.values.find((v) => v[this.idProp] === item[this.idProp]));
      return selectables;
    },
  },
  watch: {
    key() {
      this.dropdownOpened = false;
    },
    dropdownOpened() {
      this.searchText = '';
      this.textSended = false;
    },
  },
  methods: {
    filterByText(items) {
      if (!this.searchText) { return items; }
      const text = this.searchText.toLowerCase().trim();
      return items.filter((item) => item[this.labelProp].toLowerCase().includes(text));
    },
    sendLabel() {
      this.textSended = true;
    },
    selectColor(color) {
      const newItem = { [this.labelProp]: this.searchText, [this.colorProp]: color };
      this.$emit('add', newItem);
      this.dropdownOpened = false;
    },
    selectItem(item) {
      this.$emit('select', item);
    },
    removeItem(item) {
      this.$emit('remove', item);
    },
  },
};
</script>
<style lang="scss" scoped>
  .UbuDropdownSelector_trigger {
    max-width: 200px;
  }
  .UbuDropdownSelector_content {
    width: 240px;
    cursor: initial;
    input {
      margin-bottom: 24px;
    }
    .UbuButton:not(:last-child) {
      margin-bottom: 6px;
    }
  }

  .UbuDropdownSelector_row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .UbuDropdownSelector_item {
      display: flex;
      align-items: center;
      flex-grow: 1;
      border-radius: 38px;
      padding: 14.5px;
      height: 36px;

      > * {
          margin-right: 5px;
      }
      margin: 2px 10px 2px 0;
    }
  }

  hr {
    margin-top: 12px;
    margin-bottom: 12px;
    border: none;
    border-top: 1px solid;
    border-color: var(--mercury);
  }

  .UbuDropdownSelector_selectedIcon {
    > *:not(:last-child) {
        margin-right: 6.25px;
    }
    font-size: 17.5px;
  }
</style>
