import builder from '../../$utils/axios';
import builderBlob from '../../$utils/axiosBlob';
import builderBlobApi from '../../$utils/axiosBlobApi';
import builderMentions from '../../$utils/axiosMentions';
import builderIo from '../../$utils/axiosIo';

export default class MentionsProvider {
  static fetchMentions({ username, payload }) {
    return builderMentions({
      method: 'post',
      url: `/driveSearch/${username}`,
      data: payload,
    });
  }

  static fetchMentions2({ channelId, payload }) {
    return builder({
      method: 'post',
      url: `/channel/${channelId}/driveSearch2`,
      data: payload,
    });
  }

  static fetchAnalytics({ channelId, payload }) {
    return builder({
      method: 'post',
      url: `/channel/${channelId}/analytics`,
      data: payload,
    });
  }

  static downloadMentions(payload) {
    return builderIo({
      method: 'post',
      url: '/packageDownloadEmails',
      data: payload,
    });
  }

  static subscribeReport(payload) {
    const { channelId } = payload;
    return builder({
      method: 'post',
      url: `channel/${channelId}/subscribed_package`,
      data: payload,
    });
  }

  static getSubscribedReports(payload) {
    const { channelId } = payload;
    return builder({
      method: 'get',
      url: `channel/${channelId}/subscribed_packages`,
      data: payload,
    });
  }

  static deleteSubscribedReports(payload) {
    const { channelId, id } = payload;
    return builder({
      method: 'delete',
      url: `channel/${channelId}/subscribed_package/${id}`,
      data: payload,
    });
  }

  static downloadMedias(payload) {
    return builderBlob({
      method: 'post',
      url: '/packageDownloadLink',
      data: payload,
    });
  }

  static fetchHashtags({ channelId }) {
    return builder({
      method: 'get',
      url: `/channel/${channelId}/hashtags`,
    });
  }

  static getHashtag({ channelId, name }) {
    return builder({
      method: 'get',
      url: `/channel/${channelId}/hashtag/${name}`,
    });
  }

  static startHashtag({ channelId, payload }) {
    return builder({
      method: 'post',
      url: `/channel/${channelId}/searchHashtag`,
      data: payload,
    });
  }

  static stopHashtag({ channelId, name }) {
    return builder({
      method: 'get',
      url: `/channel/${channelId}/stopHashtag/${name}`,
    });
  }

  static fecthGraphAnalytics({ payload: { channelId, ...payload } }) {
    return builder({
      method: 'post',
      url: `/channel/${channelId}/graph_followers_engagement`,
      data: payload,
    });
  }

  static downloadAnalyticsXslsx({ channelId, payload }) {
    return builderBlobApi({
      method: 'post',
      url: `/channel/${channelId}/analyticsXLSX`,
      data: payload,
    });
  }
}
