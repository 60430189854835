import { arrayMapper } from '../../../$utils/prototypes';
import {
  updateDatasIfExists,
  // updateObjectRelationIfExists,

} from '../../$utils/dataMapper';
import { yaerApi } from '../../../$providers/index';

/* eslint-disable max-len */
export default {
  namespaced: true,

  state: {
    orders: {},
  },

  getters: {
    getterOrders: (state) => state.orders,
  },

  mutations: {
    SET_ORDERS(state, newDatas) { state.orders = updateDatasIfExists({ newDatas, actualDatas: state.orders, key: 'orderOid' }); },

    RESET_STORES(state) {
      state.orders = {};
    },
  },

  actions: {
    fetchShopifyOrders({ commit, rootGetters }, { shopifyShopSsid }) {
      const { 'Channel/getterCurrentChannelId': channelId } = rootGetters;

      return Promise.resolve()
        .then(() => yaerApi.api.shopifyOrders.report({ payload: { channelId, shopifyShopSsid } }))
        .then(({ result }) => {
          if (!result) return;
          const orderList = result.map((order) => ({ ...order, orderOid: order._id }));

          const mappedOrder = arrayMapper(orderList, 'orderOid');

          commit('SET_ORDERS', mappedOrder);

          const shopifyShopHasOrderBis = orderList
            .reduce((acc, row) => {
              const { orderOid } = row;
              if (!acc[shopifyShopSsid]) {
                acc[shopifyShopSsid] = [orderOid];
                return acc;
              }
              acc[shopifyShopSsid].push(orderOid);
              return acc;
            }, {});

          commit('Shopify/ShopifyShop/SET_SHOPIFY_SHOP_HAS_ORDER_BIS', shopifyShopHasOrderBis, { root: true });
        });
    },
    // fetchShopifyOrdersFromInstagram({ rootGetters }, { shopifyShopSsid }) {
    //   const { 'Channel/getterCurrentChannelId': channelId } = rootGetters;

    //   return Promise.resolve()
    //     .then(() => yaerApi.api.shopifyOrders.source.report({ payload: { channelId, shopifyShopSsid, source: 'Instagram' } }))
    //     .then(({ result }) => {
    //       if (!result) return undefined;
    //       const response = result.map((order) => ({ ...order, orderOid: order._id }));

    //       return { response };
    //     });
    // },
  },

};
