import builder from '../../$utils/axios';

export default class CommonProvider {
  static setThreadClosed({ payload: { threadId, channelId, closed } }) {
    return builder({ method: 'get', url: `/channel/${channelId}/thread/${threadId}/setClosed/${closed}` });
  }

  static setThreadReadAndClose({ payload: { threadId, channelId } }) {
    return builder({ method: 'get', url: `/channel/${channelId}/thread/${threadId}/readClose` });
  }

  static setManyThreadsClosed({ payload: { threadIds, channelId, closed } }) {
    console.log('setManyThreadsClosed', threadIds, channelId, closed);
    return builder({
      method: 'post',
      url: `/channel/${channelId}/closeMany/${closed}`,
      data: {
        thread_ids: threadIds,
      },
    });
  }

  static setThreadRead({ payload: { channelId, threadId } }) {
    return builder({ method: 'get', url: `/channel/${channelId}/thread/${threadId}/seen` });
  }

  static setThreadReadClose({ payload: { channelId, threadId } }) {
    return builder({ method: 'get', url: `/channel/${channelId}/thread/${threadId}/readClose` });
  }

  static setThreadDone({ payload: { channelId, threadId, threadGroupId } }) {
    return builder({ method: 'get', url: `/channel/${channelId}/thread/${threadId}/done/${threadGroupId}` });
  }

  static setThreadMute({ payload: { channelId, threadId, isMuted } }) {
    return builder({ method: 'get', url: `/channel/${channelId}/thread/${threadId}/setUbuMuted/${isMuted}` });
  }

  static setUserReport({ payload: { channelId, userPk } }) {
    return builder({ method: 'get', url: `/channel/${channelId}/user/${userPk}/flagUser` });
  }

  static assignSubjectsToCustom({ payload: { channelId, customTagId, subjects } }) {
    return builder({
      method: 'post',
      url: `/channel/${channelId}/customs/subjects/push`,
      data: { id: customTagId, subjects },
    });
  }

  static removeSubjectsFromCustom({ payload: { channelId, customTagId, subjects } }) {
    return builder({
      method: 'post',
      url: `/channel/${channelId}/customs/subjects/pull`,
      data: { id: customTagId, subjects },
    });
  }

  static assignSubjectToCustom({ payload: { channelId, customTagId, subject } }) {
    return builder({
      method: 'post',
      url: `/channel/${channelId}/customs/subject/push`,
      data: { id: customTagId, subject },
    });
  }

  static removeSubjectFromCustom({ payload: { channelId, customTagId, subject } }) {
    return builder({
      method: 'post',
      url: `/channel/${channelId}/customs/subject/pull`,
      data: { id: customTagId, subject },
    });
  }

  static createNewCustomAndAssign({
    payload: {
      channelId, subject, title, color, type,
    },
  }) {
    const data = { title, color, type };
    if (subject) { data.subject = `${subject}`; }
    return builder({ method: 'post', url: `/channel/${channelId}/customs`, data });
  }

  static createNewCustomAndAssignMany({
    payload: {
      channelId, subjects, title, color, type,
    },
  }) {
    const data = { title, color, type };
    if (subjects) { data.subjects = subjects.map((s) => `${s}`); }
    return builder({ method: 'post', url: `/channel/${channelId}/customs`, data });
  }

  static sendMessage(payload) {
    const { channelId, contactId, message } = payload;

    return builder({
      method: 'post',
      url: `/channel/${channelId}/user/${contactId}/message`,
      data: { message },
    });
  }

  static deleteMessage(payload) {
    const { channelId, threadId, messageId } = payload;
    return builder({
      method: 'get',
      url: `/channel/${channelId}/thread/${threadId}/deleteItem/${messageId}`,
    });
  }

  static sendNote(payload) {
    const {
      channelId, threadId, text, timestamp,
    } = payload;
    return builder({
      method: 'post',
      url: `/channel/${channelId}/thread/${threadId}/note`,
      data: { note: { text, timestamp } },
    });
  }

  static syncThread(payload) {
    const {
      channelId, threadId,
    } = payload;
    return builder({
      method: 'get',
      url: `/channel/${channelId}/thread/${threadId}/moreItems`,
    });
  }

  static syncItemsWithLastPermanent(payload) {
    const {
      channelId, threadId, itemId, count,
    } = payload;
    return builder({
      method: 'get',
      url: `/channel/${channelId}/thread/${threadId}/moreItemsFromFor/${itemId}/${count}`,
    });
  }

  static searchThreadsItems(payload) {
    const { channelId, search } = payload;

    return builder({
      method: 'get',
      url: `/channel/${channelId}/searchThreadsAll/${search}`,
    });
  }

  static translateText({ channelId, messages, target }) {
    return builder({
      method: 'post',
      url: `/channel/${channelId}/translate`,
      data: { messages, target },
    });
  }
}
