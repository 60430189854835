export default {
  namespaced: true,
  state: {
    modal: {
      cmp: null,
      props: {},
    },
  },
  getters: {
    getterModal: (state) => state.modal,
  },
  actions: {
    setModal({ commit }, modal) {
      commit('SET_MODAL', modal);
    },
  },
  mutations: {
    SET_MODAL(state, modal) { state.modal = modal; },
  },
};
