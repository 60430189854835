<template>
  <div
    id="UbuMentionsModalAddHashtag"
    class="workspace-modal"
  >
    <div class="modal-header">
      <p class="modal-header-title ubu-title">Auto report</p>

      <UbuIcon
        name="cross"
        clickable
        @click="closeModal()"
      />
    </div>
    <div class="UbuModal_note">
      <h2>NOTE:</h2>
      <p class="ubu-text1">{{ noteText }}</p>
    </div>
    <div>
      <b-field label="Activate/deactivate auto report">
        <b-switch v-model="localIsActive" />
      </b-field>
      <b-field label="Recipients">
        <b-input
          v-model="localRecipients"
          type="textarea"
          :placeholder="`email@one.com
email@two.com
email@three.com
        `"
        />
      </b-field>
    </div>
    <div class="modal-footer mt-4">
      <UbuButton
        class="mr-2"
        center
        grow
        @click="closeModal()"
      >
        Cancel
      </UbuButton>
      <UbuButton
        center
        grow
        secondary
        @click="addFromModal()"
      >
        Save
      </UbuButton>
    </div>
  </div>
</template>

<script>

export default {
  name: 'UbuMentionsModalAutoReport',
  props: {
    report: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isSwitched: null,
      name: '',
      textareaValue: null,
      noteText: 'You will receive every Sunday a report containing your mentions (stories/posts) as well as your analytics of the week.',
    };
  },
  computed: {
    localRecipients: {
      get() {
        if (!this.report.params || !this.report.params.recipients) return '';
        return this.report.params.recipients.join('\n') || '';
      },
      set(value) { this.textareaValue = value; },
    },
    localIsActive: {
      get() {
        if (!this.report.params) return false;
        return true;
      },
      set(value) { this.isSwitched = value; },
    },
  },
  methods: {
    addFromModal() {
      if (this.localIsActive === true && this.isSwitched === false) return this.$emit('removeFromModal', { id: this.report._id });
      const recipients = this.textareaValue ? this.textareaValue.split('\n') : this.report?.params?.recipients;
      if (!recipients) return undefined;
      return this.$emit('addFromModal', { recipients });
    },
    closeModal() {
      this.$emit('close');
    },
  },
};
</script>
