import { snackError } from '../../$catalog/snackbar';

export default {
  computed: {
    alreadyUsedTemplateTitle() {
      return Object.values(this.templateList).map(({ title }) => title);
    },
  },
  methods: {

    handleTitleSpace() {
      this.templateTitle = this.templateTitle.replace(/\s/gi, '-');
    },

    checkTemplateTitle(currentTitle) {
      const { message } = this.canUseTemplateTitle(currentTitle);
      if (message) {
        this.$buefy.snackbar.open({ message, ...snackError });
        return false;
      }
      return true;
    },

    checkTemplateData(data) {
      const { message } = this.canUseTemplateData(data);
      if (message) {
        this.$buefy.snackbar.open({ message, ...snackError });
        return false;
      }
      return true;
    },

    checkTemplate(currentTitle, data) {
      const { message } = this.canUseTemplateTitle(currentTitle);
      const { message: _message } = this.canUseTemplateData(data);
      if (message || _message) {
        this.$buefy.snackbar.open({ message: message || _message, ...snackError });
        return false;
      }
      return true;
    },

    canUseTemplateTitle() {
      if (this.alreadyUsedTemplateTitle.includes(this.templateTitle)) return { message: 'This title is already used' };
      if (this.templateTitle.split(' ').length !== 1) return { message: 'This title can\'t have white space' };
      if (!this.templateTitle.length) return { message: 'This title can\'t be empty' };
      return true;
    },

    canUseTemplateData(data) {
      if (!data.length) return { message: 'This content can\'t be empty' };
      if (!data.trim()) return { message: 'This content can\'t have white space' };
      return true;
    },
  },
};
