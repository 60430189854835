import moment from 'moment';
import { yaerApi } from '@/addons/$providers';
import query from '../../dataStores/stores/query';
import CommentProvider from '../providers/Comment.provider';
import { instagramPostMapper } from '../../$utils/mappers/post';

export default {
  namespaced: true,

  state: {
    loadedMediaComments: {},
    currentMediaFBId: null,
    currentThreadId: null,
  },

  getters: {
    getterLoadedMediaComments: (state) => state.loadedMediaComments,
    getterChannelContacts: (_state, _getters, _rootState, rootGetters) => {
      const { 'Sequence/getterFetchDone': fetchDone } = rootGetters;

      if (!fetchDone) return { contacts: [] };

      const {
        'Channel/getterCurrentChannelId': channelId,
        'Channel/getterChannels': channels,
        'Channel/getterChannelsHasContactsBis': channelsHasContactsBis,
        'Activity/getterActivities': activities,
        'Campaign/getterCampaigns': campaigns,
        'Contact/getterContacts': contacts,
        'Contact/getterContactsHasActivitiesBis': contactsHasActivitiesBis,
        'Contact/getterContactsHasContactGroupsBis': contactsHasContactGroupBis,
        'Contact/getterContactsHasThreadsBis': contactsHasThreadsBis,
        'ContactGroup/getterContactGroups': contactGroups,
        'Thread/getterThreadsHasThreadGroupsBis': threadsHasThreadGroupBis,
        'ThreadGroup/getterThreadGroups': threadGroups,
        'Thread/getterThreads': threads,
      } = rootGetters;

      const response = {
        ...channels[channelId],
        contacts: query.findObjectLite({
          model: 'contacts',
          relationTable: channelsHasContactsBis,
          dataTable: contacts,
          fromId: channelId,
        })
          // .filter((contact) => contact)
          .map((contact) => ({
            ...contact,
            thread: query.findObjectLite({
              model: 'activities',
              relationTable: contactsHasThreadsBis,
              dataTable: threads,
              fromId: contact.contactId,
            })
              .reduce((acc, thread) => (thread || {}), {}),
            mentions: contact.mentionCount || [],
            tagsPosts: contact.tagCount || [],
            contactGroup: query.findObjectLite({
              model: 'contactGroups',
              relationTable: contactsHasContactGroupBis,
              dataTable: contactGroups,
              fromId: contact.contactId,
            }),

            threadGroup: (contactsHasThreadsBis[contact.contactId] || [])
              .flatMap((threadId) => query.findObjectLite({
                model: 'threadGroups',
                relationTable: threadsHasThreadGroupBis,
                dataTable: threadGroups,
                fromId: threadId,
              })),

            activities: query.findObjectLite({
              model: 'activities',
              relationTable: contactsHasActivitiesBis,
              dataTable: activities,
              fromId: contact.contactId,
            })
              .map((activity) => ({
                ...activity,
                campaignDetail: campaigns[activity.campaignId],
              })),
          })),
      };
      return response;
    },
    getterCurrentMedia: (_state, getters, _rootState, rootGetters) => {
      const { 'Media/getterMediasFB': medias } = rootGetters;
      const { getterCurrentMediaFBId } = getters;
      const currentMedia = medias[getterCurrentMediaFBId];
      return currentMedia;
    },
    getterCurrentSource: (_state, getters) => {
      const { getterCurrentMedia } = getters;
      if (getterCurrentMedia) {
        return getterCurrentMedia.source;
      }
      return null;
    },
    getterCurrentThreadId: (state) => state.currentThreadId,
    getterCurrentMediaFBId: (state) => state.currentMediaFBId,
    getterChannelMedias: (_state, _getters, _rootState, rootGetters) => {
      const {
        'Sequence/getterFetchMedias': isFetchedMedias,
      } = rootGetters;

      if (!isFetchedMedias) return { comments: [] };

      const {
        'Channel/getterFbChannelId': channelId,
        'Channel/getterChannelsHasMediasFB': channelHasMediasFb,
        'Media/getterMediasFB': mediasFB,
      } = rootGetters;

      const response = {
        medias: query.find({
          relationTable: channelHasMediasFb,
          dataTable: mediasFB,
          fromId: { key: 'channelId', value: channelId },
          toId: { key: 'mediaFBId' },
        }).filter((m) => !!m),
      };
      return response;
    },
    getterMediaComments: (_state, _getters, _rootState, rootGetters) => {
      const {
        'Sequence/getterFetchMedias': isFetchedMedias,
      } = rootGetters;

      if (!isFetchedMedias) return { comments: [] };

      const {
        'Channel/getterFbChannel': fbChannel,
        'TheComment/getterCurrentMediaFBId': currentMediaFBId,
        'Comment/getterComments': comments,
        'Media/getterMediasFBHasComments': mediasFBHasComments,
      } = rootGetters;
      const { pageId, username } = fbChannel;
      const response = {
        commentList: query.find({
          model: 'contacts',
          relationTable: mediasFBHasComments,
          dataTable: comments,
          fromId: { key: 'mediaFBId', value: currentMediaFBId },
          toId: { key: 'commentId' },
        })
          .map((c) => ({
            closed: false,
            ...c,
            timestamp: moment(c.createdAt).valueOf(),
            item_type: 'comment',
            contact: { username: c.username, fullName: c.full_name ? c.full_name : '' },
          }))
          .map((item) => {
            if (item.source === 'facebook') {
              return {
                ...item,
                isMe: !!(item.from && item.from.participantId === pageId),
              };
            }
            return {
              ...item,
              isMe: !!(item.from && item.from.username === username),
            };
          }),
      };

      const { commentList, ...rest } = response;

      const { childs, parents } = commentList.reduce((acc, comment) => {
        // little hack waiting to fix this from API (parseInt)
        if (comment.parentId && parseInt(comment.parentId, 10)) {
          acc.childs.push(comment);
          return acc;
        }
        acc.parents.push(comment);
        return acc;
      }, { childs: [], parents: [] });

      const res = parents.map((parent) => {
        const { commentId } = parent;
        const children = childs.filter((row) => row.parentId === commentId);
        if (children.length) {
          return ({ ...parent, children });
        }
        return parent;
      }).sort((a, b) => b.createdAt - a.createdAt);

      return { comments: res, ...rest };
    },
  },

  actions: {
    UP_currentMediaFBId({ commit }, mediaFBId) {
      commit('SET_CURRENT_MEDIAFBID', mediaFBId);
    },
    UP_currentThreadId({ commit }, threadId) {
      commit('SET_CURRENT_THREADID', threadId);
    },
    UP_mediaLoaded({ commit }, mediaFBId) {
      commit('UP_MEDIA_LOADED', mediaFBId);
      // commit('SET_CURRENT_THREADID', mediaFBId);
    },
    openCloseThread({ commit, rootGetters }, thread) {
      const {
        'TheComment/getterCurrentMediaFBId': mediaFBId,
        'Media/getterMediasFB': mediasFB,
        'Channel/getterCurrentChannelId': channelId,
      } = rootGetters;
      const { open_parent_comments_count: opcc } = mediasFB[mediaFBId];
      const updatedComment = {
        [thread.commentId]: {
          ...thread,
        },
      };
      const openParentCommentCount = thread.closed
        ? opcc - 1 : opcc + 1;
      commit('Comment/SET_COMMENTS', updatedComment, { root: true });
      commit('Media/UPDATE_MEDIA_OPEN_COUNT', { mediaFBId, newCount: openParentCommentCount }, { root: true });
      return CommentProvider
        .openCloseThread({ ...thread, channelId });
    },
    closeManyThreads({ commit, rootGetters }, { channelId, closed, threads }) {
      const {
        'TheComment/getterCurrentSource': source,
      } = rootGetters;
      const commentIds = threads.map((t) => t.commentId);
      return CommentProvider
        .closeManyThreads({
          channelId, closed, threadIds: commentIds, source,
        }).then(() => {
          commit('Comment/CLOSE_COMMENTS', { commentIds, closed }, { root: true });
        });
    },
    closeAllComments({ commit, rootGetters }, { channelId, media }) {
      const {
        'TheComment/getterCurrentSource': source,
      } = rootGetters;
      return CommentProvider
        .closeAllComments({
          channelId, mediaId: media.mediaFBId, source,
        }).then(() => {
          commit('Comment/CLOSE_ALL_COMMENTS', { mediaId: media.mediaFBId }, { root: true });
          commit('Media/UPDATE_MEDIA_OPEN_COUNT', { mediaFBId: media.mediaFBId, newCount: 0 }, { root: true });
        });
    },
    postComment({ commit, getters, rootGetters }, {
      channelId,
      parentId,
      targetId,
      postId,
      timestamp,
      message,
      source,
      from,
    }) {
      const _parentId = parentId !== postId ? parentId : null;
      let formatedMessage = message;
      if (from && _parentId) {
        const formatMention = source === 'instagram' ? `@${from.username}` : `@[${from.participantId}]`;
        formatedMessage = `${formatMention} ${message}`;
      }
      let fn;
      const params = {
        channelId,
        parentId: _parentId,
        targetId,
        postId,
        message: formatedMessage,
      };
      if (source === 'facebook') {
        if (_parentId) {
          fn = CommentProvider.postCommentFb(params);
        } else {
          alert("You can't post root comments on facebook for now.");
          fn = Promise.reject();
          return undefined;
        }
      } else if (_parentId) {
        fn = CommentProvider.postComment(params);
      } else {
        console.log('Posting a root comment');
        fn = CommentProvider.postRootComment(params);
      }

      return fn.then(({ response, error }) => {
        if (error) {
          return { error };
        }
        const {
          'Channel/getterFbChannel': fbChannel,
        } = rootGetters;
        const { username, pageId } = fbChannel;
        const newCommentId = response.id;
        const newComment = {
          [newCommentId]: {
            isPending: true,
            source,
            commentId: newCommentId,
            parentId: _parentId,
            mediaId: postId,
            attachments: [],
            createdAt: new Date(timestamp).getTime(),
            publishedAt: new Date(timestamp).getTime(),
            closedAt: null,
            uniType: 'isComment',
            reactions: [],
            canComment: false,
            canLike: false,
            isHidden: false,
            text: message,
            message,
            mentions: [],
            isMe: true,
            level: 1,
            from: source === 'instagram' ? {
              participantId: username,
              username,
              fullName: username,
              link: `https://instagram.com/${username}`,
              profilePicture: null,
            } : {
              participantId: pageId,
              username: null,
              fullName: username,
              link: `https://facebook.com/${pageId}`,
              profilePicture: null,
            },
          },
        };
        commit('Comment/SET_COMMENTS', newComment, { root: true });
        commit('Media/SET_MEDIASFB_HAS_COMMENTS', [{ commentId: newCommentId, mediaFBId: getters.getterCurrentMediaFBId }], { root: true });
        return response;
      }).catch((error) => ({ error }));
    },
    /**
     * Reload media sert dans 2 use cases:
     *   - si on trigger @error d'un BImage
     *   - Ou si on click sur le media (pour le refresh + vérifier que le post existe toujours sur Insta)
     */
    reloadMedia({ commit }, { channelId, media, replaceImage = true }) {
      return CommentProvider
        .fetchMedia({ channelId, mediaId: media.id })
        .then(({ response, error }) => {
          if (response) {
            // On enlève le isUnread car collision avec le fait que isUnread est changé just avant ce reload (via /commentsDB), or on veut unread quand on sort du media
            const { isUnread, ...fromApiMedia } = response;
            const reloadedMedia = {
              [media.mediaFBId]: {
                ...instagramPostMapper(fromApiMedia),
                // On enlève isMention car certains medias sont corrompus coté API et renvoie un isMention = true quand il ne faut pas.
                isMention: media.isMention,
              },
            };
            if (!replaceImage) {
              /**
               * On ne remplace pas l'image si le reload s'effectue dans le contexte d'un clic sur media,
               * seulement via un trigger mediaPicError.
               * Ici on reset à l'ancienne image. (oui c'est dirty)
               * @TODO Lorsque API fixé on peut remove cette partie
               */
              reloadedMedia[media.mediaFBId].media_url = media.media_url;
              reloadedMedia[media.mediaFBId].thumbnail_url = media.thumbnail_url;
            }
            commit('Media/SET_MEDIAS_FB', reloadedMedia, { root: true });
          }
          return { response, error };
        });
    },
    deleteComment({ commit }, { comment, channelId }) {
      const { commentId, source } = comment;
      if (source === 'instagram') {
        return CommentProvider
          .deleteComment({ commentId, channelId })
          .then(({ response, error }) => {
            if (error) return { error };
            commit('Comment/UNSET_COMMENTS', commentId, { root: true });
            commit('Media/UNSET_MEDIASFB_HAS_COMMENTS', commentId, { root: true });

            return { response };
          });
      }
      if (source === 'facebook') {
        return yaerApi.api.comments.delete({ payload: { commentId, channelId } })
          .then(({ success, error }) => {
            if (error) return { error };
            commit('Comment/UNSET_COMMENTS', commentId, { root: true });
            commit('Media/UNSET_MEDIASFB_HAS_COMMENTS', commentId, { root: true });
            return { success };
          });
      }
      return Promise.resolve();
    },
  },

  mutations: {
    RESET_STORES(state) {
      state.loadedMediaComments = {};
      state.currentMediaFBId = null;
      state.currentThreadId = null;
    },
    UP_MEDIA_LOADED(state, mediaFBId) {
      state.loadedMediaComments = {
        ...state.loadedMediaComments,
        [mediaFBId]: true,
      };
      state.currentThreadId = mediaFBId;
    },
    SET_CURRENT_MEDIAFBID(state, mediaFBId) { state.currentMediaFBId = mediaFBId; },
    SET_CURRENT_THREADID(state, threadId) { state.currentThreadId = threadId; },
  },
};
