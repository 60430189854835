<template>
  <div>
    <div id="UbuContactPanel">
      <UbuContactPanelHeader
        v-model="panel"
        :contact="getterContactPanelData"
        @change="changePanel($event)"
      />
      <div
        v-if="panel.key && panel.key !== 'orders'"
        class="ubu-header1 UbuContactPanel_title"
      >
        {{ panel.title }} -
      </div>

      <UbuContactPanelInfos
        v-if="!panel.key"
        :contact="getterContactPanelData"
        :amount-generated-from-links="amountGeneratedFromLinks / 100"
        :channel-id="channelId"
        :is-generate-report-loading="isGenerateReportLoading"
        :is-statistics-loading="isStatisticsLoading"
        @updateContact="updateContact($event)"
        @generateReport="createReport($event)"
        @hardRefresh="hardRefresh($event)"
        @fetchTiktokUser="fetchTiktokUser($event)"
        @openModalEditUsernameTiktok="isModalEditUsernameTiktok = true"
      />
      <UbuContactPanelNotes
        v-else-if="panel.key === 'notes'"
        :notes="getterContactPanelData.manualNotes"
      />
      <UbuContactPanelMessages
        v-else-if="panel.key === 'messages'"
        :contact="getterContactPanelData"
        :messages="getterContactPanelData.messages"
        :gossips="getterContactPanelData.gossips"
      />
      <UbuContactPanelMentions
        v-else-if="panel.key === 'mentions'"
        :medias="medias"
        :is-loading="isMentionsLoading"
      />
      <UbuContactPanelOrders
        v-else-if="panel.key === 'orders'"
        :affiliation-list="affiliationListToCurrentContact"
        :active-campaign-list="activeCampaigns"
        :has-shopify-shop="hasShopifyShop"
        @openModalAddLink="$emit('openModalAddLink', currentContactId)"
        @assignCampaignToLink="assignCampaignToLink($event)"
        @removeContactToLink="removeContactToLink($event)"
        @openModalCustomLinkUtm="openModalCustomLinkUtm($event)"
      />
      <b-modal
        v-model="isModalEditUsernameTiktok"
        :width="640"
        scroll="keep"
      >
        <UbuContactPanelModalTiktokUsername
          @close="isModalEditUsernameTiktok = false"
          @addFromModal="updateTiktokUsername($event)"
        />
      </b-modal>

      <b-modal
        v-model="isCustomLinkUtmModalActive"
        :width="640"
        scroll="keep"
      >
        <UbuContactPanelModalShopifyLinkUtmCustom
          :selected-link="selectedLink"
          :shopify-utm-list="getterShopifyUtmList"
          @close="isCustomLinkUtmModalActive = false"
        />
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { snackSuccess, snackError } from '../$catalog/snackbar';

export default {
  name: 'TheContactPanel',
  props: {
    contactId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      panel: {
        icon: null,
        key: null,
        title: null,
      },
      medias: null,
      isGenerateReportLoading: null,
      isStatisticsLoading: false,
      isMentionsLoading: true,
      isModalEditUsernameTiktok: false,
      isCustomLinkUtmModalActive: false,
      selectedLink: null,
    };
  },
  computed: {
    ...mapGetters({
      getterContactPanelData: 'TheContactPanel/getterContactPanelData',
      channelId: 'Channel/getterCurrentChannelId',
      getterShopifyShop: 'TheContactPanel/getterShopifyShop',
      getterCampaigns: 'TheContactPanel/getterCampaigns',
      getterShopifyUtmList: 'TheWorkspace/getterShopifyUtmList',
    }),
    hasShopifyShop() {
      return !!this.getterShopifyShop.length;
    },
    allDiscountCode() {
      return this.getterShopifyShop.reduce((acc, row) => {
        const { discountCode } = row;
        if (discountCode) acc.push(...discountCode);

        return acc;
      }, []);
    },
    currentContactId() {
      return this.getterContactPanelData.contactId;
    },
    allReferralLink() {
      return this.getterShopifyShop.reduce((acc, row) => {
        const { referralLink } = row;
        if (referralLink) acc.push(...referralLink);

        return acc;
      }, []);
    },
    affiliationListToCurrentContact() {
      return this.allDiscountCode.concat(this.allReferralLink)
        .filter(({ contactId }) => contactId === this.currentContactId)
        .sort((a, b) => this.$moment(b.createdOn) - this.$moment(a.createdOn));
    },
    activeCampaigns() {
      return this.getterCampaigns.filter(({ ended }) => !ended);
    },
    amountGeneratedFromLinks() {
      return this.affiliationListToCurrentContact.reduce((acc, row) => {
        const { order } = row;
        if (order.length) {
          return acc + order.reduce((acc2, o) => acc2 + o.amountUsd, 0);
        }
        return acc;
      }, 0);
    },
  },
  watch: {
    contactId: {
      immediate: true,
      handler(contactId) {
        if (contactId) {
          this.loadContactData();
        }
      },
    },
  },
  methods: {
    ...mapActions({
      loadPanel: 'TheContactPanel/loadPanel',
      UP_currentContactId: 'TheContactPanel/UP_currentContactId',
      updateContact: 'TheContactPanel/updateContact',
      generateReport: 'TheCRM/generateReport',
      fetchMediasByUsername: 'TheContactPanel/fetchMediasByUsername',
      _hardRefresh: 'TheContactPanel/hardRefresh',
      fetchMedia: 'Media/fetchMedia',
      _reportShopifyShop: 'TheContactPanel/reportShopifyShop',
      _reportShopifyDiscountCodes: 'TheContactPanel/reportShopifyDiscountCodes',
      _reportShopifyLink: 'TheContactPanel/reportShopifyLink',
      assignDiscountCodeToCampaign: 'Shopify/DiscountCode/assignDiscountCodeToCampaign',
      removeDiscountCodeToContact: 'Shopify/DiscountCode/removeDiscountCodeToContact',
      assignReferralLinkToCampaign: 'Shopify/ReferralLink/assignReferralLinkToCampaign',
      removeReferralLinkToContact: 'Shopify/ReferralLink/removeReferralLinkToContact',
      _fetchTiktokUser: 'Contact/fetchTiktokUser',
      _updateTiktokUsername: 'Contact/updateTiktokUsername',
      reportUtm: 'TheWorkspace/reportUtm',
    }),
    openModalCustomLinkUtm(event) {
      const { shopifyShopSsid } = event;

      this.reportUtm(shopifyShopSsid);

      this.isCustomLinkUtmModalActive = true;
      this.selectedLink = event;
    },
    changePanel({ key }) {
      return key === 'mentions' ? this.loadMedias() : undefined;
    },
    loadMedias() {
      this.isMentionsLoading = true;
      this.fetchMediasByUsername(
        {
          channelId: this.channelId,
          username: this.getterContactPanelData.username,
          threadId: this.getterContactPanelData.threadId,
        },
      ).then((medias) => {
        this.medias = medias;
        this.isMentionsLoading = false;
      });
      console.log('🚀 ~ file: TheContactPanel.vue ~ line 153 ~ loadMedias ~ this.getterContactPanelData', this.getterContactPanelData);
    },
    loadContactData() {
      this.panel = {
        icon: null,
        key: null,
        title: null,
      };
      const p = Promise.resolve()
        .then(() => this.UP_currentContactId({
          channelId: this.channelId,
          contactId: this.contactId,
        }))
        .then(() => this.$emit('onContactDataLoaded'));
      return p;
    },
    createReport(event) {
      if (event.contact.follower_count > 5000 && !event.contact.is_private) {
        this.isGenerateReportLoading = event.username;
        this.generateReport(event)
          .then(({ response, error }) => {
            if (error) {
              this.$buefy.snackbar.open({
                ...snackError,
                message: `Error while loading ${event.username} report`,
                onAction: () => this.createReport(event),
              });
              this.isGenerateReportLoading = null;
              return;
            }
            this.$buefy.snackbar.open({
              ...snackSuccess,
              type: 'is-link',
              indefinite: true,
              message: `Click to open ${event.username} report`,
              onAction: () => window
                .open(response.public_url, `${event.username} generated report`),
            });
            this.isGenerateReportLoading = null;
          });
      } else {
        this.$buefy.snackbar.open({
          ...snackError,
          actionText: null,
          message: 'Error contact must have more than 5000 followers & not private',
        });
      }
    },
    reportShopifyShop() {
      this._reportShopifyShop({ channelId: this.channelId });
    },
    reportShopifyDiscountCodes() {
      this._reportShopifyDiscountCodes({
        channelId: this.channelId,
        shopifyShopSsid: this.currentShopifyShopSsid,
      });
    },
    reportShopifyLink() {
      this._reportShopifyLink({
        channelId: this.channelId,
        shopifyShopSsid: this.currentShopifyShopSsid,
      });
    },
    assignCampaignToLink(event) {
      const { campaignId: newCampaignId, code: { shopifyShopSsid, type, ...rest } } = event;

      // if campaignId emited already assigned to this link, we remove relation
      const campaignIsAlreadyAssignToLink = rest.campaignId === newCampaignId;
      const campaignIdToReturn = campaignIsAlreadyAssignToLink ? null : newCampaignId;

      if (type === 'discount') {
        this.assignDiscountCodeToCampaign({
          discountCode: rest,
          campaignId: campaignIdToReturn,
          shopifyShopSsid,
        });
      }
      if (type === 'referral') {
        this.assignReferralLinkToCampaign({
          referralLink: rest,
          campaignId: campaignIdToReturn,
          shopifyShopSsid,
        });
      }
    },
    removeContactToLink(event) {
      const {
        type, shopifyShopSsid, ...rest
      } = event;

      if (type === 'discount') {
        this.removeDiscountCodeToContact({ discountCode: rest, shopifyShopSsid });
      }
      if (type === 'referral') {
        this.removeReferralLinkToContact({ referralLink: rest, shopifyShopSsid });
      }
    },
    hardRefresh(event) {
      this.isStatisticsLoading = true;
      this._hardRefresh(event).then(() => { this.isStatisticsLoading = false; });
    },
    fetchTiktokUser(event) {
      this.isStatisticsLoading = true;
      this._fetchTiktokUser(event).then(() => { this.isStatisticsLoading = false; });
    },
    updateTiktokUsername({ username }) {
      this._updateTiktokUsername({ username, contactId: this.getterContactPanelData.contactId })
        .then(({ error }) => {
          this.isModalEditUsernameTiktok = false;
          if (error) {
            this.$buefy.snackbar.open({
              ...snackError,
              message: 'Error while setting/updating TikTok username',
            });
          } else {
            this.fetchTiktokUser({ username, contactId: this.getterContactPanelData.contactId });
            this.$buefy.snackbar.open({
              ...snackSuccess,
              message: `The TikTok username has been set to @${username}. 
              Thank you, the statistics will be updated soon.`,
            });
          }
        });
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/common';
@import 'ContactPanel';

</style>
