var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"UbuCRMTable"}},[_c('b-table',{staticClass:"list-table",attrs:{"data":_vm.listFiltered,"default-sort":['username', 'asc'],"loading":_vm.loading,"paginated":"","sticky-header":true,"per-page":"30","pagination-rounded":"","hoverable":"","pagination-size":"is-small"}},[_c('b-table-column',{attrs:{"label":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('UbuButton',{attrs:{"secondary":"","center":""},on:{"click":function($event){return _vm.$emit('showHashtag', row.username)}}},[_vm._v(" show ")])]}}])}),_c('b-table-column',{attrs:{"field":"username","label":"Username","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"username-table"},[_vm._v(" "+_vm._s(row.username)+" ")])]}}])}),_c('b-table-column',{attrs:{"field":"followerCount","label":"Followers","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var followerCount = ref.row.followerCount;
return [(followerCount)?_c('span',[_vm._v(_vm._s(followerCount.toLocaleString('en'))+" ")]):_c('span',[_vm._v("-")])]}}])}),_c('b-table-column',{attrs:{"field":"views","label":"Views","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var views = ref.row.views;
return [(views)?_c('span',[_vm._v(_vm._s(views.toLocaleString('en')))]):_c('span',[_vm._v("-")])]}}])}),_c('b-table-column',{attrs:{"field":"engagement","label":"Engagement","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var engagement = ref.row.engagement;
return [(engagement && Number(engagement) >= 0.5)?_c('span',[_vm._v(" "+_vm._s(Number(engagement).toFixed(2))+"% ")]):_c('span',[_vm._v("-")])]}}])}),_c('b-table-column',{attrs:{"field":"averagePostEngagement","label":"Average Post Engagement","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var averagePostEngagement = ref.row.averagePostEngagement;
return [(averagePostEngagement)?_c('span',[_vm._v(" "+_vm._s(Number(averagePostEngagement).toFixed(2))+"% ")]):_c('span',[_vm._v("-")])]}}])}),_c('b-table-column',{attrs:{"field":"averagePostLikes","label":"Average Post Likes","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var averagePostLikes = ref.row.averagePostLikes;
return [(averagePostLikes)?_c('span',[_vm._v(" "+_vm._s(averagePostLikes.toLocaleString('en'))+" ")]):_c('span',[_vm._v("-")])]}}])}),_c('b-table-column',{attrs:{"field":"averagePostComments","label":"Average Post Comments","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var averagePostComments = ref.row.averagePostComments;
return [(averagePostComments)?_c('span',[_vm._v(" "+_vm._s(averagePostComments.toLocaleString('en'))+" ")]):_c('span',[_vm._v("-")])]}}])}),_c('b-table-column',{attrs:{"field":"averageReach","label":"Average Reach","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var averageReach = ref.row.averageReach;
return [(averageReach)?_c('span',[_vm._v(" "+_vm._s(averageReach.toLocaleString('en'))+" ")]):_c('span',[_vm._v("-")])]}}])}),_c('b-table-column',{attrs:{"field":"nbPosts","label":"Number of posts with keywords","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var nbPosts = ref.row.nbPosts;
return [(nbPosts)?_c('span',[_vm._v(_vm._s(nbPosts.toLocaleString('en')))]):_c('span',[_vm._v("-")])]}}])}),_c('b-table-column',{attrs:{"field":"age","label":"Age","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var age = ref.row.age;
return [(age && age !== '0')?_c('span',[_vm._v(_vm._s(age))]):_c('span',[_vm._v("-")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }