<template>
  <div
    id="UbuInfluenceMentionsTikTok"
  >
    <div
      class="grid"
    >
      <UbuStatisticCard
        title="Number of influencers"
        :content="nbInfluencers"
        :is-loading="isTiktokLoading"
      />
      <UbuStatisticCard
        title="Number of videos"
        :content="nbVideos"
        :is-loading="isTiktokLoading"
      />
      <UbuStatisticCard
        title="Total views"
        :content="totalViews"
        :is-loading="isTiktokLoading"
      />
      <UbuStatisticCard
        title="Total interactions"
        :content="totalInteractions"
        :is-loading="isTiktokLoading"
      />
      <UbuStatisticCard
        title="Average post engagement"
        :content="averagePostEngagement"
        :is-loading="isTiktokLoading"
      />
    </div>
    <UbuInfluenceMentionsTikTokTable
      v-if="tableView && (filteredInfluencers.length || isTiktokLoading)"
      :list-filtered="filteredInfluencers"
      :loading="isTiktokLoading"
      @showHashtag="showHashtag($event)"
    />
    <div
      v-if="!filteredInfluencers.length && !isTiktokLoading"
      class="noTiktokMedias"
    >
      <p class="ubu-header3">You don’t have any TikTok medias for this campaign... Yet! 🤓</p>
      <UbuButton
        light
        active
        class="mt-3"
        @click="$emit('openTikTokSettings', $event)"
      >
        Add TikTok Influencers
      </UbuButton>
    </div>
    <b-modal
      v-model="showInfluencerHashtagMedia"
      :width="700"
      scroll="keep"
      @close="influencerHashtagMedia = null"
    >
      <div
        v-masonry
        transition-duration=".25s"
        item-selector=".masonry-item"
        class="masonry-container"
        horizontal-order="true"
        fit-width="true"
      >
        <div
          v-for="media in influencerHashtagMedia"
          :key="media.id"
          v-masonry-tile
          class="masonry-item"
        >
          <UbuMentionsHashtagsMediaCard
            :media="media"
          />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { shortenNumber } from '../../$utils/formate';

export default {
  name: 'UbuInfluenceMentionsTikTok',
  props: {
    tiktokOrder: {
      type: Object,
      default: () => ({}),
    },
    isTiktokLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      tableView: true,
      influencerHashtagMedia: null,
      showInfluencerHashtagMedia: false,
    };
  },
  computed: {
    nbVideos() {
      if (!this.tiktokOrder.medias) return '';
      return shortenNumber(this.tiktokOrder.medias.length);
    },
    nbInfluencers() {
      return shortenNumber(this.filteredInfluencers.length);
    },
    totalViews() {
      if (!this.tiktokOrder.medias) return '';
      const views = this.tiktokOrder.medias.reduce(
        (acc, m) => acc + m.views, 0,
      );
      return shortenNumber(views);
    },
    totalInteractions() {
      if (!this.tiktokOrder.medias) return '';
      const interactions = this.tiktokOrder.medias.reduce(
        (acc, m) => acc + m.interactions, 0,
      );
      return shortenNumber(interactions);
    },
    averagePostEngagement() {
      if (!this.filteredInfluencers.length) return '';
      const engagement = this.filteredInfluencers.reduce(
        (acc, m) => acc + m.averagePostEngagement, 0,
      ) / this.filteredInfluencers.length;
      return `${Math.round(engagement * 100) / 100}%`;
    },
    filteredInfluencers() {
      if (this.tiktokOrder.influencers) return this.tiktokOrder.influencers;
      return [];
    },
  },
  methods: {
    showHashtag(username) {
      this.influencerHashtagMedia = this.tiktokOrder.medias.filter(
        (media) => media.username === username,
      );
      if (this.influencerHashtagMedia) this.showInfluencerHashtagMedia = true;
    },
  },
};
</script>
