<template>
  <div id="UbuWorkspaceConfirmation">
    <UbuCommonModal :title="title">
      <div slot="info">
        <!-- <UbuSimpleInput
          v-model="firstFiled"
          class="UbuWorkspaceModalConfirmation_input pl-3"
          type="email"
          :placeholder="firstPlaceholder"
        />
        <UbuSimpleInput
          v-model="scndFiled"
          class="UbuWorkspaceModalConfirmation_input pl-3"
          type="email"
          :placeholder="scndPlaceholder"
        /> -->
        <b-field>
          <b-input
            ref="firstFiled"
            v-model="firstFiled"
            custom-class="UbuWorkspaceModalConfirmation_input pl-3"
            type="email"
            :placeholder="firstPlaceholder"
            required
            validation-message="Valid email address is required"
          />
        </b-field>
        <b-field>
          <b-input
            ref="scndFiled"
            v-model="scndFiled"
            custom-class="UbuWorkspaceModalConfirmation_input pl-3"
            type="email"
            :placeholder="scndPlaceholder"
            required
            validation-message="Valid email address is required"
          />
        </b-field>
      </div>
      <UbuButton
        slot="actions"
        grow
        center
        secondary
        :disabled="!allowedToSend"
        @click="submit()"
      >
        Save
      </UbuButton>
    </UbuCommonModal>
  </div>
</template>

<script>
export default {
  name: 'UbuWorkspaceModalConfirmation',
  props: {
    title: {
      type: String,
      default: 'Default Confirmation Title',
    },
    firstPlaceholder: {
      type: String,
      default: '',
    },
    scndPlaceholder: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    firstFiled: '',
    scndFiled: '',
  }),
  computed: {
    allowedToSend() {
      if (this.firstFiled && this.scndFiled) {
        if (this.firstFiled === this.scndFiled) {
          return true;
        }
        return false;
      }
      return false;
    },
  },
  methods: {
    submit() {
      if (this.$refs.firstFiled.checkHtml5Validity() && this.$refs.scndFiled.checkHtml5Validity()) {
        this.$emit('confirmAndEgual', this.firstFiled);
      }
    },
  },
};
</script>
