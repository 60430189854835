<template>
  <section id="UbuWorkspaceTag">
    <div class="workspace-view-header">
      <h1 class="ubu-header1">Tags</h1>
      <UbuButton
        :icon="{ name: 'add', filled: true }"
        @click="isCardModalActive = !isCardModalActive"
      >
        Add Tag
      </UbuButton>
    </div>
    <b-modal
      v-model="isCardModalActive"
      :width="640"
      scroll="keep"
    >
      <UbuWorkspaceModalAddTag
        :is-card-modal-active="isCardModalActive"
        :new-custom="newCustom"
        @close="isCardModalActive = false"
        @selectColor="selectColor($event)"
        @addFromModal="addFromModal($event)"
      />
    </b-modal>

    <UbuWorkspaceCustoms
      v-for="tag in tags"
      :key="tag.customTagId"
      :custom="tag"
      :has-keywords="true"
      :is-updatable="true"
      @selectColor="selectColor($event)"
      @editFromModal="editFromModal($event)"
      @deleteCustom="removeTag($event)"
      @updateTagWithData="updateData($event)"
    />
  </section>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { snackSuccess, snackError } from '../$catalog/snackbar';

export default {
  name: 'TheWorkspaceTags',
  data() {
    return {
      isCardModalActive: false,
      newCustom: {
        title: '',
        type: 'tag',
        color: 1,
      },
    };
  },
  computed: {
    ...mapGetters({
      tags: 'ThreadGroup/getterCurrentThreadGroups',
      currentChannelId: 'Channel/getterCurrentChannelId',
    }),
  },
  methods: {
    ...mapActions({
      addTag: 'TheWorkspace/addTag',
      updateTag: 'TheWorkspace/updateTag',
      updateTagWithData: 'TheWorkspace/updateTagWithData',
      deleteTag: 'TheWorkspace/deleteTag',
    }),

    addFromModal(event) {
      this.addTag({ ...event, channelId: this.currentChannelId })
        .then(({ response }) => {
          this.isCardModalActive = false;
          if (response) {
            this.$buefy.snackbar.open({
              message: 'Tag was added',
              ...snackSuccess,
            });
          } else {
            this.$buefy.snackbar.open({
              message: 'Tag was not added',
              ...snackError,
            });
          }
        });
    },

    editFromModal(event) {
      this.updateTag({ ...event, channelId: this.currentChannelId })
        .then(({ response }) => {
          this.isCardModalActive = false;
          if (response) {
            this.$buefy.snackbar.open({
              message: 'Tag was updated',
              ...snackSuccess,
            });
          } else {
            this.$buefy.snackbar.open({
              message: 'Tag was not updated',
              ...snackError,
            });
          }
        });
    },

    updateData(event) {
      this.updateTagWithData({ ...event, channelId: this.currentChannelId })
        .then(({ response }) => {
          this.isCardModalActive = false;
          if (response) {
            this.$buefy.snackbar.open({
              message: 'Tag was updated',
              ...snackSuccess,
            });
          } else {
            this.$buefy.snackbar.open({
              message: 'Tag was not updated',
              ...snackError,
            });
          }
        });
    },

    selectColor(event) {
      this.newCustom.color = event;
    },

    removeTag(event) {
      const deletedTag = {
        ...event,
        deleted: true,
      };
      this.deleteTag({ ...deletedTag, channelId: this.currentChannelId })
        .then(({ response }) => {
          this.isCardModalActive = false;
          if (response) {
            this.$buefy.snackbar.open({
              message: 'Tag was deleted',
              ...snackSuccess,
            });
          } else {
            this.$buefy.snackbar.open({
              message: 'Tag was not deleted',
              ...snackError,
            });
          }
        });
    },
  },
};
</script>
