/* eslint-disable object-curly-newline */
/* eslint-disable import/prefer-default-export */
import builder from '../engine';

const thread = {
  report: ({ payload, httpQuery }) => builder({ url: `/api/channel/${payload.cid}/thread`, method: 'REPORT', payload, httpQuery, target: 'clark' }),
};
const crmColumn = {
  report: ({ payload, httpQuery }) => builder({ url: '/api/crmColumn', method: 'REPORT', payload, httpQuery, target: 'clark' }),
};

const ping = ({ payload, httpQuery }) => builder({ url: '/ping', method: 'GET', payload, httpQuery, target: 'clark' });

export {
  ping,
  thread,
  crmColumn,
};
