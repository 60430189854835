import { responseMapper } from '../../$utils/prototypes';
import internals from './internals';

/* eslint-disable max-len */
export default {
  namespaced: true,

  state: {
    mentions: {},
  },

  getters: {
    getterMentions: (state) => state.mentions,
  },

  actions: {
    fetchMentionsByChannel({ commit }) {
      return Promise.resolve()
        .then(() => Promise.all(internals.channelsIds.map((channelId) => internals.fetchMentionsByChannel(channelId))))
        .then((fetchMentionsByChannel) => {
          // //console.log('🚀 ~ file: Mention.store.js ~ line 21 ~ .then ~ fetchMentionsByChannel', fetchMentionsByChannel);

          const mentionsByChannelsList = fetchMentionsByChannel
            .map((row) => (
              row.error
                ? row
                : row.response
                  .map((mention) => ({
                    ...mention, channelId: row.channelId, mentionId: mention._id, contactId: mention.user_id,
                  }))
            ));
          // console.log('🚀 ~ file: Mention.store.js ~ line 33 ~ .then ~ mentionsByChannelsList', mentionsByChannelsList);

          const mentionList = responseMapper(mentionsByChannelsList, 'mentionId');
          // console.log('🚀 ~ file: Mention.store.js ~ line 35 ~ .then ~ mentionList', mentionList);
          commit('SET_MENTIONS', mentionList);

          const contactsHasMentions = Object.values(mentionList).map(({ mentionId, contactId }) => ({ mentionId, contactId }));
          // console.log('🚀 ~ file: Mention.store.js ~ line 40 ~ .then ~ contactsHasMentions', contactsHasMentions);
          commit('Contact/SET_CONTACTS_HAS_MENTIONS', contactsHasMentions, { root: true });
        });
    },
  },
  mutations: {
    SET_MENTIONS(state, mentions) { state.mentions = mentions; },
    RESET_STORES(state) {
      state.mentions = {};
    },
  },
};
