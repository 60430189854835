<template>
  <div id="UbuContactPanelShopifyCard">
    <div class="shopify-card-header">
      <p class="affiliation-title">{{ affiliation.title }} {{ affiliationType }}</p>

      <div v-if="showUsername && contactAssigned">
        <p class="ubu-info pr-2">@{{ contactAssigned.username }}</p>
      </div>

      <div
        v-if="affiliation.campaign"
        class="affiliation-campaign"
      >
        <b-tooltip
          v-if="affiliation.campaignId"
          :label="campaignAssigned.name"
        >
          <UbuIcon
            v-if="affiliation.campaignId"
            name="influence"
            filled
            :style="`color: ${campaignAssigned.color}`"
          />
        </b-tooltip>
      </div>
      <p class="affiliation-date">{{ affiliation.when }}</p>
    </div>

    <div class="shopify-card-center">
      <b-tooltip
        label="Click to copy"
        position="is-bottom"
      >
        <p
          v-clipboard:click="affiliation.link"
          class="affiliation-link"
          @click="clipboardSuccessHandler()"
        >
          {{ affiliation.link }}
        </p>
      </b-tooltip>
    </div>

    <div class="shopify-card-footer">
      <p class="affiliation-amount">
        <span class="amount-value is-positive">
          {{ affiliationOrderAmount }}$
        </span> generated |
        <span class="amount-value is-positive">
          {{ affiliation.order.length }}
        </span> orders
      </p>
    </div>
  </div>
</template>

<script>
import { snackSuccess } from '../../$catalog/snackbar';

export default {
  name: 'UbuContactPanelShopifyCard',
  props: {
    affiliation: {
      type: Object,
      required: true,
    },
    showUsername: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    affiliationOrderAmount() {
      return this.affiliation.order.length ? this.affiliation.order.reduce((acc, row) => {
        const { amountUsd } = row;
        // eslint-disable-next-line no-param-reassign
        if (amountUsd) acc += Math.round((amountUsd / 100)); // amount is in cents
        return acc;
      }, 0) : 0;
    },
    affiliationType() {
      return this.affiliation.type === 'referral' ? '(referral)' : '(discount)';
    },
    campaignAssigned() {
      return this.affiliation.campaign;
    },
    contactAssigned() {
      return this.affiliation.contact;
    },
  },
  methods: {
    clipboardSuccessHandler() {
      this.$buefy.snackbar.open({
        ...snackSuccess,
        message: 'Link copied to clipboard',
      });
    },
  },
};
</script>

<style lang="scss">
#UbuContactPanelShopifyCard {
  padding: 16px 0;
  & .shopify-card-header {
    display: flex;
    align-items: center;
    & .affiliation-title {
      color: var(--cloudbusrt);
      font-size: 14px;
      padding-right: 4px;
      font-weight: 500;
    }
    & .affiliation-campaign {
      padding: 0 4px;
      border-left: 1px solid var(--mercury);
      border-right: 1px solid var(--mercury);
    }
    & .affiliation-date {
      font-size: 12px;
      color: var(--darkgrey);
      padding-left: 4px;
    }
  }
  & .shopify-card-center {
    display: flex;
    justify-content: space-between;
    align-items: center;
    & .affiliation-link {
      font-size: 12px;
      color: var(--geraldine);
      cursor: pointer;
    }
    // & .action-redirect {
    //   color: var(--cloudburst);
    // }
  }
  & .shopify-card-footer {
    & .affiliation-amount {
      color: var(--darkgrey);
      font-size: 12px;
      & .amount-value {
        font-size: 16px;
        font-weight: 600;
        &.is-positive {
          color: var(--success);
        }
      }
    }
  }
  // & .collapse-content {
  //   border: 1px solid var(--mercury);
  //   border-radius: 8px;
  //   max-height: 200px;
  //   overflow-y: auto;
  //   & .collapse-item {
  //     cursor: pointer;
  //     display: flex;
  //     justify-content: space-between;
  //   }
  // }
}
</style>
