<template>
  <div class="UbuContactPanelNotes_wrapper">
    <div v-if="notes && notes.length">
      <template
        v-for="note in mappedNotes"
      >
        <UbuNote
          :key="note._id"
          class="UbuContactPanelNotes_note"
          :text="typeof note.text === 'string' ? note.text : note.text.text"
          :date="note.date"
          :ubu-user="note.ubu_user"
        />
      </template>
    </div>
    <div
      v-else
      class="UbuContactPanelNotes_empty ubu-subtext1"
    >
      No private notes
    </div>
  </div>
</template>
<script>
export default {
  name: 'UbuContactPanelNotes',
  props: {
    notes: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    mappedNotes() {
      return this.notes
        .map((note) => ({ ...note, date: this.$moment.unix(note.timestamp / 1000) }))
        .filter(({ text }) => text)
        .sort((a, b) => b.timestamp - a.timestamp);
    },
  },
};
</script>
<style lang="scss" scoped>
  .UbuContactPanelNotes_wrapper {
    display: flex;
    flex-direction: column;

    .UbuContactPanelNotes_note {
      margin: 6px 0;
      width: 100%;
      justify-content: center;
    }

    .UbuContactPanelNotes_empty {
      color: var(--darkgrey);
      align-self: center;
      margin: 24px;
    }
  }
</style>
