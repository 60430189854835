<template>
  <UbuBaseModal
    :title="title"
    :pic="'Error.gif'"
  >
    <template slot="info">
      {{ description }}
    </template>
    <template slot="actions">
      <div class="UbuReconnectChannel_actions">
        <UbuSimpleDropdown
          grow
          center
          class="mr-1"
          default-label="Ignore and switch to..."
          :value="null"
          :options="options"
          @input="selectNewChannel($event)"
        />
        <UbuButton
          class="ml-2"
          center
          grow
          secondary
          :href="redirectTo"
        >
          {{ actionTitle }}
        </UbuButton>
      </div>
    </template>
  </UbuBaseModal>
</template>
<script>
import UbuBaseModal from '@/components/UbuModals/UbuBaseModal';
import { mapActions, mapGetters } from 'vuex';
import routes from '../../../conf/routes';

export default {
  components: {
    UbuBaseModal,
  },
  props: {
    kind: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      routes,
    };
  },
  computed: {
    title() {
      switch (this.kind) {
        case 'reconnect':
          return `Your channel @${this.currentChannel.username} has been disconnected.`;
        case 'challenge':
          return `An Instagram code is required to login to @${this.currentChannel.username}.`;
        case '2fa':
          return `Instagram requires a two-factor authentication for @${this.currentChannel.username}.`;
        case 'wait':
          return `Your channel @${this.currentChannel.username} is overloaded.`;
        default:
          return 'N/A';
      }
    },
    description() {
      switch (this.kind) {
        case 'reconnect':
          return 'Please connect to your channel, or ignore and switch to another channel.';
        case 'challenge':
          return 'Please click “send my code” and open the Instagram app to find your code, or ignore and switch to another channel.';
        case '2fa':
          return 'Please click “Enter 2FA” and open the Instagram app to find your code, or ignore and switch to another channel 💡';
        case 'wait':
          return 'Please click “Enter 2FA” and open the Instagram app to find your code, or ignore and switch to another channel 💡';
        default:
          return 'N/A';
      }
    },
    actionTitle() {
      switch (this.kind) {
        case 'reconnect':
          return 'Reconnect channel';
        case 'challenge':
          return 'Send my code';
        case '2fa':
          return 'Enter 2FA';
        default:
          return 'N/A';
      }
    },
    redirectTo() {
      const oldBaseUrl = this.routes.api.url;
      switch (this.kind) {
        case 'reconnect':
          return `${oldBaseUrl}/api/instalogin`;
        case 'challenge':
          return `${oldBaseUrl}/api/challenge/${this.currentChannel.username}`;
        case '2fa':
          return `${oldBaseUrl}/api/2fa/${this.currentChannel.username}`;
        default:
          return 'N/A';
      }
    },
    ...mapGetters({
      channels: 'Channel/getterChannels',
      currentChannel: 'Channel/getterCurrentChannel',
    }),
    options() {
      return Object.values(this.channels)
        .filter((c) => c.channelId !== this.currentChannel.channelId)
        .map((c) => ({
          key: c.channelId, label: `@${c.username}`,
        }));
    },
  },
  methods: {
    selectNewChannel(channelId) {
      this.switchChannel({ channel: this.channels[channelId] });
      this.$emit('close');
    },
    ...mapActions({
      switchChannel: 'Channel/switchChannel',
    }),
  },

};
</script>
<style lang="scss">
  .UbuReconnectChannel_actions {
    display: flex;
    flex-grow: 1;
    > * {
      width: 50%;
    }
  }
</style>
