<template>
  <div
    class="UbuAnalyticsGraph"
  >
    <b-skeleton
      height="15px"
      width="30%"
    />
    <b-skeleton
      height="260px"
      width="100%"
    />
  </div>
</template>

<script>

export default {
  name: 'UbuAnalyticsLoadingGraph',
};
</script>
