const defaultTooltip = {
  position: 'is-bottom',
};

const navigationTooltip = {
  position: 'is-top',
  badge: false,
  delay: 700,
  size: 'is-small',
  multilined: true,
};

export {
  navigationTooltip,
  defaultTooltip,
};
