/* eslint-disable no-multi-spaces */

import TheCRM from './TheCRM';

export default [
  {
    path: '/crm/:listId?',
    name: 'CRM',
    component: TheCRM,
    meta: {
      auth: true,
      requireData: true,
      overrideActiveIcon: 'influence',
    },
  },
];
