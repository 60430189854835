<template>
  <div id="TheComment">
    <TheSidebar
      :menu-items="menu"
      :menu-items-first-column="menuItemsFirstColumn"
    />

    <template v-if="getterFbChannel && getterFbChannel.connected">
      <UbuCommentMediaList
        :media-list="mediaList"
        :selected-media-id="getterCurrentMediaFBId"
        :is-media-loading="isMediaLoading"
        :is-loading="isLoading"
        @selectMedia="selectMedia($event)"
        @refreshMedias="loadToFetchMedias()"
        @mediaPicError="mediaPicError($event)"
      />
      <UbuCommentThreadList
        v-if="selectedMediaIsLoaded"
        v-model="search"
        :media="getterCurrentMedia"
        :all-childs="getterMediaComments && getterMediaComments.comments"
        :source="getterCurrentSource"
        :thread-list-filtered-by-open-close="threadListFilteredByOpenClose"
        :show-closed-threads="showClosedThreads"
        :selected-thread-id="getterCurrentThreadId"
        :bulks="bulks"
        :has-media-selected="!!getterCurrentMediaFBId"
        :is-thread-loading="isThreadLoading"
        :is-bulk-pending="isBulkPending"
        :sort-options="sortOptions"
        :sort="sort"
        @closeAll="closeAllComments"
        @onSelectCard="UP_currentThreadId($event)"
        @changeOpenCloseFilter="changeOpenCloseFilter($event)"
        @clearBulks="clearBulks()"
        @openManyThreads="openMany($event)"
        @closeManyThreads="closeMany($event)"
        @refreshComments="loadToFetchThreads()"
        @updateSort="updateSort($event)"
      />
        <!-- @bulksAction="selectFirstBulk($event)" -->
        <!-- @onBulkSelect="onBulkSelect($event)" -->
        <!-- @addAllToBulks="addAllToBulks($event)" -->

      <div
        v-if="selectedMediaIsLoaded"
        class="threadPanel-wrapper"
      >
        <!-- v-if="selectedThread.messages.length" -->
        <TheThreadPanel
          :selected-thread="selectedThread"
          :contact="null"
          :is-panel-comment="true"
          :selected-reply="selectedReply"
          :is-closed-thread="isClosedThread"
          :template-list="getterTemplates"
          :comments-button-loading="actionButtonLoading"
          @clickToReply="changeSelectedReply($event)"
          @cancelReply="cancelSelectedReply()"
          @sendComment="sendComment($event)"
          @sendCommentAndCloseThread="sendCommentAndCloseThread($event)"
          @openCloseThread="openClose(selectedThread.origin, $event)"
          @saveTemplate="saveTemplate($event)"
          @rateThread="rateThread($event)"
          @delete="deleteComment({ comment: $event, channelId: getterFbChannelId })"
          @resend="resendComment($event)"
        />
      </div>

      <div
        v-if="!selectedMediaIsLoaded"
        class="TheInbox_empty"
      >
        <UbuLoader />
      </div>
      <UbuCommentPanel
        v-if="currentMedia && currentMedia.mediaFBId"
        :key="currentMedia.mediaFBId"
        :when="$moment(currentMedia.createdAt).format('YYYY-MM-DD')"
        :url-img="currentMedia.thumbnail_url || currentMedia.media_url"
        :url-type="(currentMedia.thumbnail_url && 'IMAGE') || currentMedia.media_type"
        :url-redirect="currentMedia.permalink"
        :like-count="currentMedia.likeCount"
        :comment-count="currentMedia.commentsCount"
        :share-count="currentMedia.shareCount"
        :register-count="currentMedia.saved"
        :caption="currentMedia.caption"
        :title="currentMedia.title"
        :dynamic-parent="currentMedia.dynamicParent || null"
        :reactions="currentMedia.reactions"
      />
    </template>

    <div
      v-else
      class="dashboard-error-content"
    >
      <p>We couldn't load your comments, please check if your Facebook Channel is connected</p>
      <b-button
        to="/workspace/connection"
        class="dashboard-error-button ubuButton is-primary"
        tag="router-link"
      >
        Connection
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import TheCommentMixin from './$mixins/TheCommentMixin';
import { navigationTooltip } from '../$catalog/tooltip';
import { handleReconnectFacebook } from '@/addons/$utils/errors';

export default {
  name: 'TheComment',
  mixins: [TheCommentMixin],
  data() {
    return {
      search: '',
      showClosedThreads: false,
      bulks: [],
      selectedReply: null,
      isMediaLoading: false,
      isThreadLoading: false,
      actionButtonLoading: false,
      isBulkPending: false,
      menuItemsFirstColumn: [
        {
          href: '/workspace/connection',
          icon: 'add',
          text: 'Facebook channel',
        },
      ],
      sortOptions: [
        { key: 'asc:timestamp', label: 'Newest' },
        { key: 'desc:timestamp', label: 'Oldest' },
      ],
      sort: 'asc:timestamp',
    };
  },
  computed: {
    ...mapGetters({
      getterChannelMedias: 'TheComment/getterChannelMedias',
      getterLoadedMediaComments: 'TheComment/getterLoadedMediaComments',
      getterFbChannel: 'Channel/getterFbChannel',
      getterFbChannelId: 'Channel/getterFbChannelId',
      getterCurrentMediaFBId: 'TheComment/getterCurrentMediaFBId',
      getterCurrentSource: 'TheComment/getterCurrentSource',
      getterCurrentMedia: 'TheComment/getterCurrentMedia',
      getterMediaComments: 'TheComment/getterMediaComments',
      getterCurrentThreadId: 'TheComment/getterCurrentThreadId',
      getterTemplates: 'TheWorkspace/getterTemplates',
      // getterCurrentChannelId: 'Channel/getterCurrentChannelId',
      getterCurrentChannel: 'Channel/getterCurrentChannel',
      fetchIsDone: 'Sequence/getterFetchMedias',
      adsResponse: 'Sequence/getterFetchAds',
      initialized: 'Sequence/getterFetchInitial',
      getterIsFbConnectionNeeded: 'Sequence/getterIsFbConnectionNeeded',
      getterIsIgConnectionNeeded: 'Sequence/getterIsIgConnectionNeeded',
    }),
    selectedMediaIsLoaded() {
      const id = this.getterCurrentMediaFBId;
      const mapped = this.getterLoadedMediaComments;
      if (!id) return false;
      if (mapped[id]) {
        return true;
      }
      return false;
    },
    isLoading() {
      return !this.getterChannelMedias.medias;
    },
    menu() {
      if (!this.getterChannelMedias.medias) {
        return [];
      }
      const medias = this.getterChannelMedias.medias.filter((m) => !m.isMention);
      const mentions = this.getterChannelMedias.medias.filter((m) => m.isMention);
      const dc = (resp) => ({
        href: '/workspace/connection',
        infoText: resp === false ? 'Disconnected' : '...',
        tooltip: {
          ...navigationTooltip,
          label: resp === false
            ? 'We have some troubles to load your this data. It may be due to missing permissions or expired connexion. Please click here to reconnect'
            : 'Loading',
          multilined: true,
        },
      });
      const igFilter = {
        icon: { type: 'picto', name: '/img/instagram-logo.svg' },
        text: 'Instagram',
        ...(this.getterIsIgConnectionNeeded ? dc(!this.getterIsIgConnectionNeeded) : {
          href: '/comment/instagram',
          tooltip: {
            ...navigationTooltip,
            label: 'Your instagram ad & organic posts',
          },
          count: medias.filter((m) => m.source === 'instagram' && m.open_parent_comments_count > 0).length,
          hasUnread: !!medias
            .find((media) => media.source === 'instagram' && media.open_parent_comments_count > 0),
        }),
      };

      const fbFilter = {
        icon: { type: 'picto', name: '/img/facebook-logo.svg' },
        text: 'Facebook',
        ...(this.getterIsFbConnectionNeeded ? dc(!this.getterIsFbConnectionNeeded) : {
          href: '/comment/facebook',
          tooltip: {
            ...navigationTooltip,
            label: 'Your facebook ad & organic posts',
          },
          count: medias.filter((m) => m.source === 'facebook' && m.open_parent_comments_count > 0).length,
          hasUnread: !!medias
            .find((media) => media.source === 'facebook' && media.open_parent_comments_count > 0),
        }),
      };

      const adsFilter = {
        icon: 'ad',
        text: 'Ads',
        ...(this.adsResponse !== true ? dc(this.adsResponse) : {
          href: '/comment/ad',
          tooltip: {
            ...navigationTooltip,
            label: 'Your ad posts refreshed every 2 hours',
            multilined: true,
          },
          count: medias.filter((media) => media.isAd).length,
          hasUnread: !!medias.find((media) => media.isAd && media.open_parent_comments_count > 0),
        }),
      };

      return [
        {
          icon: 'medias',
          text: 'All posts',
          href: '/comment/all',
          tooltip: {
            ...navigationTooltip,
            label: 'Your ad & organic posts',
          },
          count: medias.filter((m) => m.open_parent_comments_count > 0).length,
          hasUnread: !!medias.find((media) => media.open_parent_comments_count > 0),
        }, adsFilter,
        {
          icon: 'organic',
          text: 'Organic',
          href: '/comment/organic',
          tooltip: {
            ...navigationTooltip,
            label: 'Your organic posts',
          },
          count: medias.filter((m) => !m.isAd && m.open_parent_comments_count > 0).length,
          hasUnread: !!medias.find((media) => !media.isAd && media.open_parent_comments_count > 0),
        },
        igFilter,
        fbFilter,
        {
          icon: 'mentions',
          text: 'Mentions',
          href: '/comment/mentions',
          tooltip: {
            ...navigationTooltip,
            label: 'Posts or comments where you have been mentionned last 14 days',
          },
          count: mentions.filter((m) => m.open_parent_comments_count > 0).length,
          hasUnread: !!mentions.find((media) => media.open_parent_comments_count > 0),
        },
      ];
    },
  },
  watch: {
    getterIsIgConnectionNeeded: {
      immediate: true,
      handler(connexionNeeded) {
        if (connexionNeeded) {
          handleReconnectFacebook({ dispatch: this.$store.dispatch });
        }
      },
    },
    // initialized: {
    //   immediate: true,
    //   handler(isInit) {
    //     if (isInit && !this.fetchIsDone) {
    //       this.fetchComments();
    //     }
    //   },
    // },
  },
  methods: {
    ...mapActions({
      UP_currentThreadId: 'TheComment/UP_currentThreadId',
      UP_currentMediaFBId: 'TheComment/UP_currentMediaFBId',
      UP_mediaLoaded: 'TheComment/UP_mediaLoaded',
      fetchCommentsDBByChannel: 'Comment/fetchCommentsDBByChannel',
      fetchCommentsInstaByChannel: 'Comment/fetchCommentsInstaByChannel',
      fetchCommentsFacebookByChannel: 'Comment/fetchCommentsFacebookByChannel',
      fetchMediasByChannel: 'Media/fetchMediasByChannel',
      addTemplate: 'TheWorkspace/addTemplate',
      openCloseThread: 'TheComment/openCloseThread',
      closeManyThreads: 'TheComment/closeManyThreads',
      postComment: 'TheComment/postComment',
      reloadMedia: 'TheComment/reloadMedia',
      closeAll: 'TheComment/closeAllComments',
      _deleteComment: 'TheComment/deleteComment',
      hardFetchComments: 'Sequence/hardFetchComments',
      fetchComments: 'Sequence/fetchComments',
    }),
    loadToFetchMedias() {
      this.isMediaLoading = true;
      // this.fetcFbOrganic()
      //   .then((result) => {
      //     console.log('Result', result);
      //   });
      // this.fetchMediasByChannel()
      console.log('Starting hard refresh');
      this.hardFetchComments()
        .then(() => { this.isMediaLoading = false; });
    },
    loadToFetchThreads() {
      this.isThreadLoading = true;
      if (this.getterCurrentSource === 'instagram') {
        this.fetchCommentsInstaByChannel({
          channelId: this.getterFbChannelId,
          mediaFBId: this.getterCurrentMediaFBId,
        })
          .then(() => { this.isThreadLoading = false; });
      } else {
        this.fetchCommentsFacebookByChannel({
          channelId: this.getterFbChannelId,
          mediaFBId: this.getterCurrentMediaFBId,
        })
          .then(() => { this.isThreadLoading = false; });
      }
    },
    mediaPicError(event) {
      this.reloadMedia({
        channelId: this.getterFbChannelId,
        media: event,
      });
    },
    updateSort(event) {
      this.sort = event;
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/common';
@import 'Comment';

#TheComment {
  height: 100%;
  display: flex;
  & .threadPanel-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    border-right: 1px solid var(--mercury);
    // min-width: 522px;
  }
  & #TheThreadPanel {
    & .UbuThreadMessage_scroller {
      height: calc(100vh - 52px);
    }
  }
  & .dashboard-error-content {
    display: block;
    padding: 40px;
    font-size: 1rem;
    font-weight: 500;
    & .dashboard-error-button {
      width: 150px;
      margin-top: 10px;
    }
  }
}
</style>
