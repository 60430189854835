<template>
  <div
    id="UbuCRMViewModal"
    class="workspace-modal"
  >
    <div class="modal-header">
      <p class="modal-header-title ubu-title">Create view</p>

      <UbuIcon
        name="cross"
        clickable
        @click="$emit('close')"
      />
    </div>

    <b-field label="Name">
      <b-input
        v-model="viewName"
        placeholder="My view..."
      />
    </b-field>

    <div class="column-selector">
      <b-field
        grouped
        label="Select your preferences"
        position="is-centered"
        group-multiline
        addons
      >
        <b-checkbox-button
          v-model="selectedColumns"
          type="is-selected"
          native-value="username"
        >
          Username
        </b-checkbox-button>
        <b-checkbox-button
          v-model="selectedColumns"
          type="is-selected"
          native-value="email"
        >
          Email
        </b-checkbox-button>
        <b-checkbox-button
          v-model="selectedColumns"
          type="is-selected"
          native-value="threadGroup"
        >
          Tags
        </b-checkbox-button>
        <b-checkbox-button
          v-model="selectedColumns"
          type="is-selected"
          native-value="contactGroup"
        >
          Labels
        </b-checkbox-button>
        <b-checkbox-button
          v-model="selectedColumns"
          type="is-selected"
          native-value="followerCount"
        >
          Followers
        </b-checkbox-button>
        <b-checkbox-button
          v-model="selectedColumns"
          type="is-selected"
          native-value="engagement"
        >
          Engagement
        </b-checkbox-button>
        <b-checkbox-button
          v-model="selectedColumns"
          type="is-selected"
          native-value="activeCampaign"
        >
          Active campaigns
        </b-checkbox-button>
        <b-checkbox-button
          v-model="selectedColumns"
          type="is-selected"
          native-value="pastCampaign"
        >
          Past campaigns
        </b-checkbox-button>
        <b-checkbox-button
          v-model="selectedColumns"
          type="is-selected"
          native-value="storyMentionCount"
        >
          Story mention
        </b-checkbox-button>
        <b-checkbox-button
          v-model="selectedColumns"
          type="is-selected"
          native-value="postMentionCount"
        >
          Post mention
        </b-checkbox-button>
        <b-checkbox-button
          v-model="selectedColumns"
          type="is-selected"
          native-value="ageAudience"
        >
          Age audience
        </b-checkbox-button>
        <b-checkbox-button
          v-model="selectedColumns"
          type="is-selected"
          native-value="locationAudience"
        >
          Location audience
        </b-checkbox-button>
        <b-checkbox-button
          v-model="selectedColumns"
          type="is-selected"
          native-value="languageAudience"
        >
          Language audience
        </b-checkbox-button>
        <b-checkbox-button
          v-model="selectedColumns"
          type="is-selected"
          native-value="genderAudience"
        >
          Gender audience
        </b-checkbox-button>
        <b-checkbox-button
          v-model="selectedColumns"
          type="is-selected"
          native-value="lastMentionOrTagPost"
        >
          Last mention
        </b-checkbox-button>
        <b-checkbox-button
          v-model="selectedColumns"
          type="is-selected"
          native-value="lastAddedCampaign"
        >
          Last campaign add
        </b-checkbox-button>
        <b-checkbox-button
          v-model="selectedColumns"
          type="is-selected"
          native-value="lastMessageTS"
        >
          Last message
        </b-checkbox-button>
        <b-checkbox-button
          v-model="selectedColumns"
          type="is-selected"
          native-value="categoryEnum"
        >
          Business category
        </b-checkbox-button>
        <b-checkbox-button
          v-model="selectedColumns"
          type="is-selected"
          native-value="categoryName"
        >
          Type
        </b-checkbox-button>
        <b-checkbox-button
          v-model="selectedColumns"
          type="is-selected"
          native-value="tiktokUsername"
        >
          Tiktok username
        </b-checkbox-button>
      </b-field>
    </div>
    <div class="modal-footer mt-4">
      <b-button
        expanded
        class="is-rounded is-light"
        @click="$emit('close')"
      >
        Cancel
      </b-button>
      <b-button
        expanded
        class="is-rounded is-geraldine"
        @click="createView()"
      >
        Create view
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UbuCRMViewModal',
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      viewName: '',
      selectedColumns: [],
    };
  },
  methods: {
    createView() {
      if (this.viewName.trim().length && this.selectedColumns.length) {
        this.$emit('createView', { name: this.viewName.trim(), columns: this.selectedColumns });
      }
    },
  },
};
</script>
