<template>
  <div class="UbuAnalyticsGraph">
    <span class="ubu-subtext1">{{ title }}</span>
    <canvas :id="chartId" />
  </div>
</template>

<script>
import Chart from 'chart.js/auto';
import { lineSegmentConfig } from '../$utils/analyticsChartData';

export default {
  name: 'UbuAnalyticsGraph',
  props: {
    chartId: {
      type: String,
      required: true,
    },
    labels: {
      type: Array,
      default: () => [],
    },
    data: {
      type: [Array, Object],
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    chartType: {
      type: String,
      required: true,
    },
    isFollowers: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      lineSegmentConfig,
      currentChart: null,
    };
  },
  computed: {
    engagementGradient() {
      const ctx = document.getElementById(this.chartId);
      const gradient = ctx.getContext('2d').createLinearGradient(0, 0, 0, 450);
      gradient.addColorStop(0, 'rgba(111, 109, 227, 0.5)');
      gradient.addColorStop(0.5, 'rgba(111, 109, 227, 0.25)');
      gradient.addColorStop(1, 'rgba(111, 109, 227, 0)');
      return gradient;
    },
    followersGadient() {
      const ctx = document.getElementById(this.chartId);
      const gradient = ctx.getContext('2d').createLinearGradient(0, 0, 0, 450);
      gradient.addColorStop(0, 'rgba(255, 98, 164, 0.5)');
      gradient.addColorStop(0.5, 'rgba(255, 98, 164, 0.25)');
      gradient.addColorStop(1, 'rgba(255, 98, 164, 0)');
      return gradient;
    },
    configToUse() {
      if (this.chartType === 'lineSegment') return this.lineSegmentConfig(this.labels, this.data, this.isFollowers ? this.followersGadient : this.engagementGradient, this.isFollowers ? '#FF62A4' : '#6F6DE3');
      return null;
    },
  },
  watch: {
    data() {
      if (this.currentChart) this.currentChart.destroy();
      const ctx = document.getElementById(this.chartId);
      this.currentChart = new Chart(ctx, this.configToUse);
    },
  },
  mounted() {
    const ctx = document.getElementById(this.chartId);
    this.currentChart = new Chart(ctx, this.configToUse);
  },
};
</script>
