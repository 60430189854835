<template>
  <div
    id="UbuFilterSelector"
    v-click-outside="() => showModal = false"
  >
    <button
      :class="['filterSelector-button ubu-text1', {'active': active}]"
      @click="showModal = !showModal"
    >
      {{ buttonTitle }}
    </button>
    <div
      v-if="showModal"
      class="filterSelector-modal"
    >
      <span class="ubu-text1 filterSelector-title">{{ modalTitle }}</span>
      <div class="filterSelector-container checkbox-selector">
        <b-field v-if="defaultOption.name">
          <b-checkbox
            v-model="isOptionSelected"
            :native-value="true"
          >
            {{ defaultOption.name }}
          </b-checkbox>
        </b-field>

        <b-field
          v-for="(item, index) in checkboxList"
          :key="index"
        >
          <b-checkbox
            v-model="checkboxGroup"
            :native-value="item"
          >
            {{ item }}
          </b-checkbox>
        </b-field>
      </div>

      <div class="filterSelector-modal-button-group">
        <UbuButton
          grow
          center
          class="filterSelector-modal-button"
          @click="reset()"
        >
          Reset
        </UbuButton>
        <UbuButton
          grow
          center
          secondary
          class="FollowersSelector_UbuButton"
          @click="applyFilters()"
        >
          Apply filter
        </UbuButton>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'UbuCheckboxSelector',
  props: {
    buttonTitle: {
      type: String,
      default: '',
    },
    modalTitle: {
      type: String,
      default: '',
    },
    checkboxList: {
      type: Array,
      default: () => [],
    },
    defaultOption: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showModal: false,
      checkboxGroup: [],
      isOptionSelected: false,
    };
  },
  computed: {
    active() {
      return this.showModal
      || this.checkboxGroup.length || this.isOptionSelected;
    },
  },
  methods: {
    reset() {
      this.checkboxGroup = [];
      this.isOptionSelected = false;
      this.showModal = false;
      this.$emit('setCheckFilter', { checked: this.checkboxGroup });
    },
    applyFilters() {
      this.showModal = false;
      this.$emit('setCheckFilter', { checked: this.checkboxGroup, option: this.isOptionSelected });
    },
  },
};
</script>
