<template>
  <b-tooltip
    v-bind="tooltip"
    position="is-bottom"
    :active="!!tooltip && !disabled"
  >
    <component
      :is="to && !disabled ? 'router-link' : 'a'"
      :class="[
        'UbuIconButton_wrapper',
        {
          UbuIconButton_disabled: disabled,
          UbuIconButton_isActive: isActive,
          UbuIconButton_hasText: $slots.default,
          UbuIconButton_hoverable: hoverable,
        },
      ]"
      :disabled="disabled"
      :to="!disabled ? to : null"
      @click="!disabled ? $emit('click', $event) : () => {}"
    >
      <UbuIcon
        :size="size"
        class="UbuIconButton_icon"
        :name="icon"
        :filled="filled"
      />
    </component>
  </b-tooltip>
</template>

<script>
export default {
  name: 'UbuIconButton',
  props: {
    color: {
      type: String,
      default: '',
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '',
    },
    to: {
      type: String,
      default: '',
    },
    target: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hoverable: {
      type: Boolean,
      default: false,
    },
    filled: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: Object,
      default: () => {},
    },
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.UbuIconButton_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: none;
  border-radius: 12px;
  height: 34px;
  min-width: 34px;
  text-decoration: none;

  .UbuIconButton_icon {
    font-size: 20px;
    font-weight: 600;
    color: var(--cloudburst);
  }

  &.UbuIconButton_disabled {
    opacity: .5;
    pointer-events: none;
    cursor: not-allowed;
  }

  &.UbuIconButton_isActive,
  &.router-link-active {
    background-color: var(--narvalo);
    &.lighten {
      background-color: var(--givry);
    }
  }

  &.UbuIconButton_hoverable:not(.UbuIconButton_isActive):not(.router-link-active) {
    &:hover {
      background-color: var(--narvalo60);
    }
  }
}
</style>
