<template>
  <div
    id="UbuContactPanelModalAddShopifyLink"
    class="workspace-modal"
  >
    <section>
      <div class="modal-header">
        <p class="modal-header-title ubu-title">Add link</p>

        <UbuIcon
          name="cross"
          clickable
          @click="$emit('close', $event)"
        />
      </div>
      <b-field
        grouped
        class="radio-field"
      >
        <b-radio-button
          v-model="newLink.type"
          native-value="referral"
          @input="clearCodeInput()"
        >
          <div class="radio-container">
            <h3 class="ubu-subtitle">Referral</h3>
            <p class="ubu-info radio-description">
              Referral codes are dedicated URLs that your influencers share with their community
              as part of a referral marketing program.
            </p>
          </div>
        </b-radio-button>

        <b-radio-button
          v-model="newLink.type"
          native-value="discount"
          @input="clearCodeInput()"
        >
          <div class="radio-container">
            <h3 class="ubu-subtitle">Discount</h3>
            <p class="ubu-info radio-description">
              Discount codes are custom codes from your shopify store that influencers
              can share with their community to give them discounts at checkout.
            </p>
          </div>
        </b-radio-button>
      </b-field>
    </section>

    <section
      v-if="newLink.type"
      class="mt-4"
    >
      <b-field grouped>
        <b-field expanded>
          <template #label>
            <div class="label-container">
              Select a store
            </div>
          </template>

          <b-select
            v-model="newLink.shopifyShopSsid"
            expanded
            @input="clearCodeInput()"
          >
            <option
              v-for="shop in shopifyShopList"
              :key="shop.shopifyShopListSsid"
              :value="shop.shopifyShopSsid"
            >
              {{ shop.shopExtrefId }}
            </option>
          </b-select>
        </b-field>

        <b-field
          v-if="newLink.type === 'referral'"
          :type="isReferralExist ? 'is-danger' : null"
          :message="isReferralExist ? 'Referral already exists' : null"
          expanded
        >
          <template #label>
            <div class="label-container">
              Create a code
            </div>
          </template>

          <b-input
            v-model="newLink.code"
            class="input-code"
            placeholder="Your code..."
            expanded
            maxlength="16"
            @keydown.native.space.prevent
          />
        </b-field>

        <b-field
          v-if="newLink.type === 'discount'"
          expanded
        >
          <template #label>
            <div class="label-container">
              <p>
                Select an existing code or <a
                  class="link-shopify"
                  :href="`https://${currentShop.shopExtrefId}`"
                  target="_blank"
                >create one</a>
              </p>
            </div>
          </template>

          <b-select
            v-model="newLink.code"
            placeholder="Discount code..."
            expanded
            :disabled="!discountCodeAvailable.length"
            @focus="$emit('refreshDiscountCode', currentShop)"
            @input="checkIfLinkIsAlreadyUseByOtherContact($event)"
          >
            <option
              v-for="code in discountCodeAvailable"
              :key="code.discountCodeDcid"
              :value="code.discountCodeDcid"
              selected
            >
              {{ code.title }}
            </option>
          </b-select>
        </b-field>
      </b-field>

      <b-field label="Add utm">
        <b-switch
          v-model="hasUtm"
          :disabled="!newLink.code"
          @input="triggerFetchUtm()"
        />
      </b-field>

      <div
        v-if="hasUtm"
      >
        <div
          class="buttons"
          @click="$emit('close', $event)"
        >
          <router-link
            to="/workspace/shopify"
            class="ubu-info text-geraldine"
          >
            + Create new utm parameters
          </router-link>
        </div>

        <b-field>
          <b-select
            v-model="utmParameters.sourceCode"
            placeholder="Source utm..."
            expanded
          >
            <option
              v-for="option in sourceUtmParameters"
              :key="option.utmId"
              :value="option.value"
            >
              {{ option.value }}
            </option>
          </b-select>
        </b-field>

        <b-field>
          <b-select
            v-model="utmParameters.mediumCode"
            placeholder="Medium utm..."
            expanded
          >
            <option
              v-for="option in mediumUtmParameters"
              :key="option.utmId"
              :value="option.value"
            >
              {{ option.value }}
            </option>
          </b-select>
        </b-field>

        <b-field>
          <b-select
            v-model="utmParameters.campaignCode"
            placeholder="Campaign utm..."
            expanded
          >
            <option
              v-for="option in campaignUtmParameters"
              :key="option.utmId"
              :value="option.value"
            >
              {{ option.value }}
            </option>
          </b-select>
        </b-field>

        <b-field>
          <b-input
            disabled
            expanded
            :value="urlPreview"
          />
          <b-tooltip label="Click to copy">
            <b-button
              v-clipboard:copy="urlPreview"
              v-clipboard:success="onCopy"
              type="is-light"
              icon-right="content-copy"
            />
          </b-tooltip>
        </b-field>
      </div>

      <b-field
        expanded
        class="mt-2"
      >
        <template #label>
          <div class="label-container">
            Link to a campaign (optionnal)
          </div>
        </template>
        <b-select
          v-model="newLink.campaignId"
          expanded
          :disabled="!activeCampaignList.length"
        >
          <option
            selected
            :value="null"
          >
            Select a campaign
          </option>

          <option
            v-for="campaign in activeCampaignList"
            :key="campaign.campaignId"
            :value="campaign.campaignId"
          >
            {{ campaign.name }}
          </option>
        </b-select>
      </b-field>

      <div class="buttons is-right mt-4">
        <b-button
          label="Add"
          class="is-geraldine is-rounded"
          :disabled="!fieldAreCompleted"
          :loading="isLoading"
          @click="addLink()"
        />
      </div>
    </section>
  </div>
</template>

<script>
import { snackSuccess } from '../../$catalog/snackbar';

export default {
  name: 'UbuContactPanelModalAddShopifyLink',
  props: {
    activeCampaignList: {
      type: Array,
      default: () => [],
    },
    shopifyShopList: {
      type: Array,
      default: () => [],
    },
    utmParameterList: {
      type: Array,
      default: () => [],
    },
    contactId: {
      type: Number,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      newLink: {
        shopifyShopSsid: '',
        codeLabel: '',
        code: null,
        campaignId: null,
        type: '',
      },
      externalLinkToShop: '',
      hasUtm: false,
      utmParameters: {
        sourceCode: null,
        mediumCode: null,
        campaignCode: null,
      },
    };
  },
  computed: {
    sourceUtmParameters() {
      return this.utmParameterList.length ? this.utmParameterList.filter(({ type }) => type === 'source') : [];
    },
    mediumUtmParameters() {
      return this.utmParameterList.length ? this.utmParameterList.filter(({ type }) => type === 'medium') : [];
    },
    campaignUtmParameters() {
      return this.utmParameterList.length ? this.utmParameterList.filter(({ type }) => type === 'campaign') : [];
    },
    urlPreview() {
      let url = `${this.currentShop.websiteUrl}`;
      if (this.newLink.type === 'referral') {
        const queries = [`?ref=${this.newLink.code}`];

        if (this.utmParameters.sourceCode) {
          queries.push(`utm_source=${this.utmParameters.sourceCode}`);
        }
        if (this.utmParameters.mediumCode) {
          queries.push(`utm_medium=${this.utmParameters.mediumCode}`);
        }
        if (this.utmParameters.campaignCode) {
          queries.push(`utm_campaign=${this.utmParameters.campaignCode}`);
        }

        url += queries.join('&');
      }
      if (this.newLink.type === 'discount') {
        if (this.newLink.code) {
          const selectedDiscount = this.discountCodeAvailable
            .find(({ discountCodeDcid }) => discountCodeDcid === this.newLink.code);

          url += `/discount/${selectedDiscount.title}`;

          // eslint-disable-next-line max-len
          if (this.utmParameters.sourceCode || this.utmParameters.mediumCode || this.utmParameters.campaignCode) {
            url += '?';

            const queries = [];

            if (this.utmParameters.sourceCode) {
              queries.push(`utm_source=${this.utmParameters.sourceCode}`);
            }
            if (this.utmParameters.mediumCode) {
              queries.push(`utm_medium=${this.utmParameters.mediumCode}`);
            }
            if (this.utmParameters.campaignCode) {
              queries.push(`utm_campaign=${this.utmParameters.campaignCode}`);
            }

            url += queries.join('&');
          }
        }
      }
      return url;
    },
    fieldAreCompleted() {
      return this.newLink.shopifyShopSsid.length && this.newLink.code;
    },
    currentShop() {
      return this.shopifyShopList
        .find(({ shopifyShopSsid }) => shopifyShopSsid === this.newLink.shopifyShopSsid);
    },
    discountCodeListByCurrentShop() {
      return this.currentShop.discountCode;
    },
    discountCodeAvailable() {
      // return only discount unassigned on current contact
      return this.discountCodeListByCurrentShop.reduce((acc, row) => {
        if (row.contactId !== this.contactId) acc.push(row);
        return acc;
      }, []);
    },
    isReferralExist() {
      return !!this.currentShop.referralLink
        .find(({ referralCode }) => referralCode === this.newLink.code);
    },
  },
  mounted() {
    this.selectFirstShop();
  },
  methods: {
    triggerFetchUtm() {
      if (this.hasUtm === true) {
        this.$emit('fetchUtm', { shopifyShopSsid: this.newLink.shopifyShopSsid });
      }
      if (this.hasUtm === false) {
        this.utmParameters.sourceCode = null;
        this.utmParameters.mediumCode = null;
        this.utmParameters.campaignCode = null;
      }
    },
    addLink() {
      this.$emit('addLink', this.newLink);

      this.newLink = {
        shopifyShopSsid: '',
        codeLabel: '',
        code: null,
        campaignId: null,
        type: '',
      };
      this.hasUtm = false;
    },
    clearCodeInput() {
      // clear code input when switch beteween ref/discount
      this.newLink.code = null;
      this.hasUtm = false;
      this.utmParameters = {
        sourceCode: null,
        mediumCode: null,
        campaignCode: null,

      };
    },
    selectFirstShop() {
      this.newLink.shopifyShopSsid = this.shopifyShopList[0].shopifyShopSsid;
    },
    checkIfLinkIsAlreadyUseByOtherContact(event) {
      const selectedDiscount = this.discountCodeAvailable
        .find(({ discountCodeDcid }) => discountCodeDcid === event);

      if (selectedDiscount.contactId) {
        // link is already use by other contact warn user
        this.$buefy.dialog.alert('Care ! This discount code is alrealdy assigned to an other influencer');
      }
    },
    onCopy() {
      this.$buefy.snackbar.open({
        message: 'Copied to clipboard.',
        ...snackSuccess,
      });
    },
  },
};
</script>

<style lang="scss">
#UbuContactPanelModalAddShopifyLink {
  & .radio-field {
    & .field.is-grouped {
      display: flex;
      justify-content: space-between;
      & .control {
        width: 50%;
      }
    }
    & .radio-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    & .radio-description {
      white-space: normal;
      padding-top: 5px;
    }
  }
  & .b-radio.radio.button {
    // padding: 40px 20px;
    height: 110px;
  }
  & .label-container {
    display: flex;
    justify-content: space-between;
    & .link-shopify {
      color: var(--geraldine);
    }
  }
  & .button {
    background-color: transparent;
    &.is-primary {
      border: 2px solid var(--geraldine);
      color: var(--cloudbusrt);
    }
  }
  & .button.is-primary:focus:not(:active), .button.is-primary.is-focused:not(:active) {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}
</style>
