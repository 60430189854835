<template>
  <div
    id="UbuInfluenceLeadsAddLeadsBulk"
    class="ubuCard"
  >
    <div class="card-header">
      <p class="ubu-card-title">
        Add Instagram usernames, one per line.
      </p>
    </div>
    <div class="card-content">
      <form>
        <b-field>
          <b-input
            v-model="usernames"
            type="textarea"
            :placeholder="`username_one
username_two
username_three
        `"
          />
        </b-field>
      </form>
    </div>
    <div
      v-if="usernames.length"
      class="card-footer no-padding"
    >
      <b-button
        expanded
        class="has-campaign-color has-no-radius ubu-header3"
        @click="$emit('sendUsernames', usernames)"
      >
        <span>{{ getCountInBulkUserImporter }}</span>
      </b-button>
    </div>
  </div>
</template>

<script>
import BulkImportMixin from '../../$mixins/BulkImportMixin';

export default {
  name: 'UbuInfluenceLeadsAddLeadsBulk',
  mixins: [BulkImportMixin],
  data() {
    return {
      usernames: '',
    };
  },
  computed: {
    getCountInBulkUserImporter() {
      const count = this.convertListOfNamesIntoArrayOfNames(this.usernames);
      return count > 1 ? `Add ${count.length} leads` : `Add ${count.length} lead`;
    },
  },
};
</script>

<style lang="scss">
#UbuInfluenceLeadsAddLeadsBulk {
  & .card {
    background-color: var(--soapstone);
  }
  & textarea {
    color: var(--cloudburst);
    min-height: 300px;
    border: none;
    resize: none;
    border-radius: 0;
    background-color: var(--mercury20);
    margin: 0 0;
    font-size: 0.85rem;
    font-weight: 500;
    -webkit-box-shadow: none;
    box-shadow: none;
    &:active {
      color: var(--cloudburst);
      -webkit-box-shadow: none;
      box-shadow: none;
    }
    &::placeholder {
      color: var(--cloudburst30);
    }
  }
  & .has-no-radius {
    border-radius: 0;
  }
}
</style>
