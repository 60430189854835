<template>
  <div class="UbuMessageWrapper_wrapper">
    <div v-if="loading">Loading</div>
    <slot />
    <div class="UbuMessageWrapper_timestamp ubu-subtext1">
      <template v-if="username.length">{{ username }} - </template>{{ date }}
    </div>
    <div class="UbuMessageWrapper_like">
      <span
        v-for="(emoji, index) in emojiList"
        :key="index"
      >
        {{ emoji }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UbuCommonMessageWrapper',
  props: {
    date: {
      type: String,
      required: true,
    },
    username: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    emojiList: {
      type: Array,
      default: () => ([]),
    },
  },
};
</script>

<style lang="scss">
.UbuMessageWrapper_wrapper {
  max-width: 100%;
  border-radius: 24px;
  overflow: hidden;
  transition: all .1s ease-out;
  position: relative;
  &:hover {
    // padding-bottom: 12px;
    // min-width: 200px;
    .UbuMessageWrapper_timestamp {
      display: flex;
      margin-top: 12px;
      padding: 0 12px 12px 12px;
      height: auto;
      // bottom: 8px;
      opacity: 1;
      color: var(--cola60);
    }
  }

  &:not(.UbuMessageWrapper_mine) {
    border: 1px solid var(--cola10);
  }
  &.UbuMessageWrapper_mine {
    background-color: var(--givry);
  }
  &.UbuMessageWrapper_like {
    display: flex;
    bottom: -10px;
    position: absolute;
    left: -3px;
    font-size: 15px;
    border-radius: 25px;
    padding: 8px;
    min-width: 25px;
    height: 25px;
    justify-content: center;
    text-align: center;
    align-items: center;

    > *:not(:last-child) {
      margin-right: 4px;
    }
  }
  .UbuMessageWrapper_timestamp {
    // bottom: 0px;
    width: 100%;
    height: 0;
    justify-content: flex-end;
    display: none;
    // position: absolute;
    transition: all .1s ease-in-out;
    opacity: 0;
    right: 12px;
  }
}
</style>
