<template>
  <div id="UbuCommonMentionsCarousel">
    <b-carousel
      v-model="localIndex"
      :autoplay="false"
      with-carousel-list
      :indicator="false"
      :overlay="gallery"
    >
      <b-carousel-item
        v-for="(item, i) in items"
        :key="i"
      >
        <div class="UbuMentionsCarousel_item">
          <UbuCommonMentionCardLarge
            :media="item"
            @openNewMessage="$emit('openNewMessage', $event)"
          />
        </div>
      </b-carousel-item>
      <span
        v-if="gallery"
        class="modal-close is-large"
        @click="switchGallery(false)"
      />
      <template #list="props">
        <b-carousel-list
          v-model="localIndex"
          :data="items"
          v-bind="al"
          as-indicator
          @switch="props.switch($event, false)"
        >
          <template
            slot="item"
            slot-scope="props"
          >
            <div class="UbuMentionsCarousel_listItem">
              <UbuCommonMentionsCarouselListItem :item="props" />
            </div>
          </template>
        </b-carousel-list>
      </template>
      <template
        #overlay
        @click="switchGallery(false)"
      />
    </b-carousel>
  </div>
</template>

<script>
export default {
  name: 'UbuCommonMentionsCarousel',
  model: {
    prop: 'indexMedia',
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    indexMedia: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      gallery: true,
      al: {
        itemsToShow: 4,
        breakpoints: {
          550: {
            itemsToShow: 6,
          },
          1000: {
            itemsToShow: 8,
          },
        },
      },
    };
  },
  computed: {
    localIndex: {
      get() { return this.indexMedia; },
      set(value) { this.$emit('input', value); },
    },
  },
  methods: {
    switchGallery(value) {
      this.gallery = value;
      this.$emit('close');
      if (value) {
        document.documentElement.classList.add('is-clipped');
      } else {
        document.documentElement.classList.remove('is-clipped');
      }
    },
  },
};
</script>
