<template>
  <a
    :href="link"
    target="_blank"
    class="ubu-instalink"
  >{{ name }}&nbsp;<UbuIcon name="externalLink" />&nbsp;</a>
</template>

<script>
export default {
  name: 'UbuFacebookName',
  props: {
    link: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
  },
};
</script>
