var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-tooltip',_vm._b({attrs:{"position":"is-bottom","active":!!_vm.tooltip && !_vm.disabled}},'b-tooltip',_vm.tooltip,false),[_c(_vm.to && !_vm.disabled ? 'router-link' : 'a',{tag:"component",class:[
      'UbuIconButton_wrapper',
      {
        UbuIconButton_disabled: _vm.disabled,
        UbuIconButton_isActive: _vm.isActive,
        UbuIconButton_hasText: _vm.$slots.default,
        UbuIconButton_hoverable: _vm.hoverable,
      } ],attrs:{"disabled":_vm.disabled,"to":!_vm.disabled ? _vm.to : null},on:{"click":function($event){!_vm.disabled ? _vm.$emit('click', $event) : function () {}}}},[_c('UbuIcon',{staticClass:"UbuIconButton_icon",attrs:{"size":_vm.size,"name":_vm.icon,"filled":_vm.filled}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }