<template>
  <b-table
    class="list-table"
    :data="listFiltered"
    :checked-rows="checkedRows"
    :selected="selectedRow"
    :default-sort="['addedOn', 'desc']"
    checkable
    paginated
    per-page="20"
    pagination-rounded
    pagination-size="is-small"
    @check="$emit('checkRow', $event)"
    @click="$emit('selectRow', $event)"
    @check-all="$emit('checkAllRows', $event)"
  >
    <b-table-column
      v-slot="props"
      field="username"
      label="Username"
      sortable
    >
      <div class="username-table">
        <b-image
          class="profilePic-table"
          :src="props.row.profile_pic_url"
          :lazy="true"
          placeholder="/img/expired_image.png"
          src-fallback="/img/expired_image.png"
          rounded
        />
        {{ props.row.username }}
      </div>
    </b-table-column>
    <b-table-column
      v-slot="props"
      field="addedOn"
      label="Date added"
      sortable
    >
      {{ props.row.addedOn }}
    </b-table-column>
    <b-table-column
      v-slot="props"
      field="followers"
      label="Followers"
      sortable
    >
      {{ props.row.followers }}
    </b-table-column>
    <b-table-column
      v-slot="props"
      field="engagement"
      label="Engagement"
      sortable
    >
      {{ props.row.engagement }}%
    </b-table-column>
    <b-table-column
      v-slot="props"
      field="location"
      label="Location"
      sortable
    >
      {{ props.row.location }}
    </b-table-column>
    <b-table-column
      v-slot="props"
      field="keywords"
      label="Keywords"
      sortable
    >
      {{ props.row.keywords }}
    </b-table-column>
    <b-table-column
      v-slot="props"
      field="businessCategory"
      label="Business Category"
      sortable
    >
      {{ props.row.businessCategory }}
    </b-table-column>
  </b-table>
</template>

<script>
export default {
  name: 'UbuLeadsTable',
  props: {
    listFiltered: {
      type: Array,
      default: () => [],
    },
    checkedRows: {
      type: Array,
      default: () => [],
    },
    selectedRow: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
