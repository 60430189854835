/* eslint-disable camelcase */
import moment from 'moment';
import MentionsProvider from '../providers/Mentions.provider';
import { formateDateToLabelGraph2 } from '../$utils/analytics';
import { mentionsFormater, findLastMentionOrTagPost } from '../$utils/mentions';
import query from '../../dataStores/stores/query';
import { updateDatasIfExists } from '../../dataStores/$utils/dataMapper';
import { arrayMapper } from '../../$utils/prototypes';
import { mapActivitiesFromCampaign } from '../../crm/$utils/filterManagement';

export default {
  namespaced: true,

  state: {
    hashtags: {},
    currentMentions: [],
    currentInfluencers: [],
  },

  getters: {
    getterHashtagsArray: (state) => Object.values(state.hashtags).map((h) => h),
    getterHashtags: (state) => state.hashtags,
    getterCurrentMentions: (state) => state.currentMentions,
    getterCurrentInfluencers: (state, _getters, _rootState, rootGetters) => {
      const { 'Sequence/getterFetchDone': fetchDone } = rootGetters;
      if (!fetchDone) return [];
      const {
        'Contact/getterContactsHasContactGroupsBis': contactsHasContactGroupBis,
        'ContactGroup/getterContactGroups': contactGroups,
        'Activity/getterActivities': activities,
        'Campaign/getterCampaigns': campaigns,
        'Contact/getterContactsHasActivitiesBis': contactsHasActivitiesBis,
        'Channel/getterCurrentChannelId': channelId,
        'Channel/getterChannelsHasContactsBis': channelsHasContactsBis,
        'Contact/getterContacts': contacts,
        'Contact/getterContactsHasThreadsBis': contactsHasThreadsBis,
        'Thread/getterThreadsHasThreadGroupsBis': threadsHasThreadGroupBis,
        'ThreadGroup/getterThreadGroups': threadGroups,
        'Thread/getterThreads': threads,
      } = rootGetters;

      const allContacts = query.findObjectLite({
        model: 'contacts',
        relationTable: channelsHasContactsBis,
        dataTable: contacts,
        fromId: channelId,
      })
        .map((contact) => ({
          ...contact,
          thread: query.findObjectLite({
            model: 'activities',
            relationTable: contactsHasThreadsBis,
            dataTable: threads,
            fromId: contact.contactId,
          })
            .reduce((acc, thread) => (thread || {}), {}),
          mentions: contact.mentionCount || [],
          tagsPosts: contact.tagCount || [],
          contactGroup: query.findObjectLite({
            model: 'contactGroups',
            relationTable: contactsHasContactGroupBis,
            dataTable: contactGroups,
            fromId: contact.contactId,
          }),

          threadGroup: (contactsHasThreadsBis[contact.contactId] || [])
            .flatMap((threadId) => query.findObjectLite({
              model: 'threadGroups',
              relationTable: threadsHasThreadGroupBis,
              dataTable: threadGroups,
              fromId: threadId,
            })),

          activities: query.findObjectLite({
            model: 'activities',
            relationTable: contactsHasActivitiesBis,
            dataTable: activities,
            fromId: contact.contactId,
          })
            .map((activity) => ({
              ...activity,
              campaignDetail: campaigns[activity.campaignId],
            })),
        }));

      return state.currentInfluencers.map((influencer) => ({
        ...influencer,
        ...allContacts.find((contact) => contact.contactId === influencer.userPk),
      })).map((influencer) => ({
        ...influencer,
        contactGroup: influencer.contactGroup || [],
        contactGroups: influencer.contactGroup || [],
        threadGroup: influencer.threadGroup || [],
        activities: influencer.activities || [],
        emails: influencer.emails || [],
        phones: influencer.phones || [],
        websites: influencer.websites || [],
        engagement: influencer.engagement,
        lastMentionOrTagPost: findLastMentionOrTagPost(influencer),
        posted: influencer.mention ? 'Yes' : 'No',
      })).map((influencer) => ({
        ...influencer,
        activeCampaign: mapActivitiesFromCampaign(influencer, false),
      })).filter((a) => a.engagement > 0.3 || a.lastMentionOrTagPost
      || a.contactGroup.length || a.threadGroup.length || a.activities.length || a.source);
    },
  },

  actions: {
    fetchMentions({ commit, rootGetters }, { dateFilter }) {
      commit('SET_MENTIONS', []);
      commit('SET_INFLUENCERS', []);
      const {
        'Channel/getterCurrentChannel': currentChannel,
        'Channel/getterCurrentChannelId': channelId,
      } = rootGetters;
      const { username } = currentChannel;
      let minDate = moment();
      let maxDate = moment();
      let globalStats;
      if (dateFilter) {
        if (dateFilter.type === 'simple') {
          minDate = minDate.subtract({ days: dateFilter.value });
        } else if (dateFilter.type === 'custom') {
          minDate = moment(dateFilter.value[0]);
          maxDate = moment(dateFilter.value[1]);
        }
      }
      const payload = {
        withUsers: true,
        minDate: minDate.format(),
        maxDate: maxDate.format(),
      };

      const payloadStat = {
        minDate: minDate.subtract({ days: 3 }).toDate(),
        maxDate: maxDate.toDate(),
      };
      const nbDays = maxDate.diff(minDate, 'days');

      let mentionsDriveSearch = [];
      let influencers = [];
      return Promise.resolve()
        .then(() => MentionsProvider.fetchAnalytics({ channelId, payload: payloadStat }))
        .then(({ response }) => {
          console.log('🚀 ~ file: TheMentions.store.js ~ line 143 ~ .then ~ response', response);
          globalStats = response;
        })
        .then(() => MentionsProvider.fetchMentions({ username, payload }))
        .then(({ response, error }) => {
          console.log('🚀 ~ file: TheMentions.store.js ~ line 121 ~ .then ~ response', response);
          if (error) return;
          mentionsDriveSearch = [...mentionsFormater(response)];
        })
        .then(() => MentionsProvider.fetchMentions2({ channelId, payload }))
        .then(({ response, error }) => {
          console.log('🚀 ~ file: TheMentions.store.js ~ line 157 ~ .then ~ response', response);
          const frannel = response.filter(({ name }) => name.includes('fra'));
          console.log('🚀 ~ file: TheMentions.store.js ~ line 157 ~ .then ~ frannel', frannel);
          if (error) throw new Error('Unable to fetch mentions');

          const mergedArray = [...mentionsDriveSearch, ...mentionsFormater(response)].reduce(
            (acc, media) => {
              if (!acc.find((data) => data.id === media.id)) {
                acc.push(media);
              }
              return acc;
            }, [],
          );

          const nbDaysMentions = mergedArray.reduce((acc, media) => {
            if (!acc.includes(media.date)) acc.push(media.date);
            return acc;
          }, []).length;

          const globalNewFollowers = (nbDaysMentions * globalStats?.new_followers) / nbDays;
          influencers = mergedArray.reduce((acc, media) => {
            const found = acc.find((m) => m.userPk === media.userPk);
            if (!found) {
              acc.push({
                userPosts: media.userPosts,
                userStories: media.userStories,
                userPk: media.userPk,
                contactId: media.userPk,
                username: media.username,
                engagement: media.engagement,
                language: media.language || '',
                followerCount: media.followers,
                follower_count: media.followers,
                storyMentionCount: media.userStories.length,
                nbDaysWithStories: media.userStories.reduce((a, m) => {
                  if (!a.includes(m.date)) a.push(m.date);
                  return a;
                }, []).length,
                postMentionCount: media.userPosts.length,
                mentionsUser: [...found?.mentionsUser || [], media],
                mention: true,
              });
            } else {
              found.mentionsUser.push(media);
            }
            return acc;
          }, []).map((user) => ({
            ...user,
            averagePostEngagement: (user.userPosts.reduce((sum, media) => {
              if (!media.followers) return sum;
              const likeCount = media.like_count || 0;
              const commentsCount = media.comments_count || 0;
              const engagement = (likeCount + commentsCount) / media.followers;
              return sum + engagement;
            }, 0) / user.postMentionCount) * 100,
            engagementCleaned: (user.engagement < 0.5) || !user.engagement ? 1 : user.engagement,
          })).map((user) => ({
            ...user,
            averageReach: (user.engagementCleaned / 100) * user.followerCount * 4,
            impressions: (user.engagementCleaned / 100) * user.followerCount
             * 5 * user.mentionsUser.length,
          }));

          const globalMentionsImpressions = influencers.reduce((sum, user) => {
            if (!user.impressions) return sum;
            return sum + user.impressions;
          }, 0);

          influencers = influencers.map((user) => ({
            ...user,
            newFollowers: Math.round(
              (user.impressions * globalNewFollowers) / globalMentionsImpressions,
            ),
          }));
          commit('SET_MENTIONS', mergedArray);
          return influencers.map((influencer) => influencer.userPk);
        })
        .then((pks) => this.dispatch('Contact/fetchMissingListContacts', { pks }))
        .then(() => commit('SET_INFLUENCERS', influencers))
        .catch((error) => {
          console.log('🚀 ~ file: TheMentions.store.js ~ line 70 ~ fetchMentions ~ error', error);
          throw new Error('Unable to fetch mentions');
        });
    },
    loadAnalyticsInbox({ rootGetters }, dateFilter) {
      const {
        'Channel/getterCurrentChannel': currentChannel,
        'Channel/getterCurrentChannelId': channelId,
      } = rootGetters;
      const { username } = currentChannel;
      const { value, type, subtype } = dateFilter;
      const context = {};

      let {
        firstRangeDate,
        secondRangeDate,
      } = {};

      if (type === 'simple') {
        if (subtype === 'previousWeek') {
          firstRangeDate = {
            maxDate: moment().day(0).add({ days: 1 }).format('YYYY-MM-DD'),
            minDate: moment().day(-6).format('YYYY-MM-DD'),
          };
          secondRangeDate = {
            maxDate: moment().day(0).add({ days: 1 }).subtract({ week: 1 })
              .format('YYYY-MM-DD'),
            minDate: moment().day(-6).subtract({ week: 1 }).format('YYYY-MM-DD'),
          };
        }
        if (subtype === 'currentWeek') {
          firstRangeDate = {
            maxDate: moment().add({ days: 1 }).format('YYYY-MM-DD'),
            minDate: moment().subtract({ days: 6 }).format('YYYY-MM-DD'),
          };
          secondRangeDate = {
            maxDate: moment().subtract({ days: 6 }).format('YYYY-MM-DD'),
            minDate: moment().subtract({ days: 6 * 2 + 1 }).format('YYYY-MM-DD'),
          };
        }
        if (subtype === 'previousMonth') {
          firstRangeDate = {
            maxDate: moment().subtract({ month: 1 }).endOf('month').add({ days: 1 })
              .format('YYYY-MM-DD'),
            minDate: moment().subtract({ month: 1 }).startOf('month').format('YYYY-MM-DD'),
          };
          secondRangeDate = {
            maxDate: moment().subtract({ month: 2 }).endOf('month').add({ days: 1 })
              .format('YYYY-MM-DD'),
            minDate: moment().subtract({ month: 2 }).startOf('month').format('YYYY-MM-DD'),
          };
        }
        if (subtype === 'currentMonth') {
          firstRangeDate = {
            maxDate: moment().add({ days: 1 }).format('YYYY-MM-DD'),
            minDate: moment().subtract({ month: 1 }).format('YYYY-MM-DD'),
          };
          secondRangeDate = {
            maxDate: moment().add({ days: 1 }).subtract({ month: 1 }).format('YYYY-MM-DD'),
            minDate: moment().subtract({ month: 2 }).format('YYYY-MM-DD'),
          };
        }
      }

      if (type === 'custom') {
        const diffBetween = moment(value[1]).diff(value[0], 'days');
        firstRangeDate = {
          maxDate: moment(value[1]).add({ days: 1 }).format('YYYY-MM-DD'),
          minDate: moment(value[0]).format('YYYY-MM-DD'),
        };
        secondRangeDate = {
          maxDate: moment(value[0]).add({ days: 1 }).format('YYYY-MM-DD'),
          minDate: moment(value[0]).subtract({ days: diffBetween }).format('YYYY-MM-DD'),
        };
      }

      return Promise.resolve()
        .then(() => MentionsProvider.fetchAnalytics({ channelId, payload: { ...firstRangeDate } }))
        .then(({ response }) => {
          context.firstRangeDate = {
            id: 1,
            data: response,
            initialTimestamp: response.stat_initial_timestamp
              ? response.stat_initial_timestamp : null,
          };
        })
        .then(() => MentionsProvider.fetchAnalytics({ channelId, payload: { ...secondRangeDate } }))
        .then(({ response }) => {
          context.secondRangeDate = {
            id: 2,
            data: response,
          };
        })
        .then(() => context);
    },
    fetchAccountAnalyticsFirst({ rootGetters }, dateFilter) {
      const {
        'Channel/getterCurrentChannel': currentChannel,
        'Channel/getterCurrentChannelId': channelId,
      } = rootGetters;
      const { username } = currentChannel;
      const { value, type, subtype } = dateFilter;
      const context = {};
      let {
        firstRangeDate,
        secondRangeDate,
      } = {};

      if (type === 'simple') {
        if (subtype === 'previousWeek') {
          firstRangeDate = {
            maxDate: moment().day(0).add({ days: 1 }).format('YYYY-MM-DD'),
            minDate: moment().day(-6).format('YYYY-MM-DD'),
          };
          secondRangeDate = {
            maxDate: moment().day(0).add({ days: 1 }).subtract({ week: 1 })
              .format('YYYY-MM-DD'),
            minDate: moment().day(-6).subtract({ week: 1 }).format('YYYY-MM-DD'),
          };
        }
        if (subtype === 'currentWeek') {
          firstRangeDate = {
            maxDate: moment().add({ days: 1 }).format('YYYY-MM-DD'),
            minDate: moment().add({ days: 1 }).subtract({ week: 1 }).format('YYYY-MM-DD'),
          };
          secondRangeDate = {
            maxDate: moment().add({ days: 1 }).subtract({ week: 1 }).format('YYYY-MM-DD'),
            minDate: moment().add({ days: 1 }).subtract({ week: 2 }).format('YYYY-MM-DD'),
          };
        }
        if (subtype === 'previousMonth') {
          firstRangeDate = {
            maxDate: moment().subtract({ month: 1 }).endOf('month').add({ days: 1 })
              .format('YYYY-MM-DD'),
            minDate: moment().subtract({ month: 1 }).startOf('month').format('YYYY-MM-DD'),
          };
          secondRangeDate = {
            maxDate: moment().subtract({ month: 2 }).endOf('month').add({ days: 1 })
              .format('YYYY-MM-DD'),
            minDate: moment().subtract({ month: 2 }).startOf('month').format('YYYY-MM-DD'),
          };
        }
        if (subtype === 'currentMonth') {
          firstRangeDate = {
            maxDate: moment().add({ days: 1 }).format('YYYY-MM-DD'),
            minDate: moment().subtract({ month: 1 }).format('YYYY-MM-DD'),
          };
          secondRangeDate = {
            maxDate: moment().add({ days: 1 }).subtract({ month: 1 }).format('YYYY-MM-DD'),
            minDate: moment().subtract({ month: 2 }).format('YYYY-MM-DD'),
          };
        }
      }

      if (type === 'custom') {
        const diffBetween = moment(value[1]).diff(value[0], 'days');
        firstRangeDate = {
          maxDate: moment(value[1]).add({ days: 1 }).format('YYYY-MM-DD'),
          minDate: moment(value[0]).format('YYYY-MM-DD'),
        };
        secondRangeDate = {
          maxDate: moment(value[0]).add({ days: 1 }).format('YYYY-MM-DD'),
          minDate: moment(value[0]).subtract({ days: diffBetween }).format('YYYY-MM-DD'),
        };
      }

      return Promise.resolve()
        .then(() => MentionsProvider.fetchAnalytics({ channelId, payload: { ...firstRangeDate } }))
        .then(({ response }) => {
          const { new_followers, medias } = response;
          let prepareMediasDataV2 = {};
          let { followers_difference } = response;

          if (followers_difference > new_followers) followers_difference = new_followers;

          if (medias && medias.length) {
            const prepareMediasData = medias.reduce((acc, row) => {
              const keyToUse = ['like_count', 'comments_count', 'reach'];

              if (row.product_type === 'story') {
                if (!acc.storiesReach) acc.storiesReach = row.reach || 0;
                else acc.storiesReach += row.reach || 0;
              } else if (!acc.postsReach) acc.postsReach = row.reach || 0;
              else acc.postsReach += row.reach || 0;

              keyToUse.forEach((key) => {
                if (row[key]) {
                  if (!acc[key]) {
                    acc[key] = row[key] || 0;
                    return acc;
                  }
                  acc[key] += row[key] || 0;
                  return acc;
                }
                return acc;
              });

              return acc;
            }, {});

            const nbPosts = medias.filter((m) => m.product_type !== 'story').length;
            const nbStories = medias.filter((m) => m.product_type === 'story').length;

            prepareMediasDataV2 = {
              ...prepareMediasData,
              commentsPerPostAverage: Math.round(prepareMediasData.comments_count / nbPosts),
              reachByPostAverage: Math.round(prepareMediasData.postsReach / nbPosts),
              likesPerPostAverage: Math.round(prepareMediasData.like_count / nbPosts),
              reachByStoryAverage: Math.round(prepareMediasData.storiesReach / nbStories),
              postsReach: prepareMediasData.postsReach,
              storiesReach: prepareMediasData.storiesReach,
            };
          } else {
            prepareMediasDataV2 = {
              like_count: 0,
              comments_count: 0,
              reach: 0,
              commentsPerPostAverage: 0,
              reachByPostAverage: 0,
              likesPerPostAverage: 0,
              reachByStoryAverage: 0,
              postsReach: 0,
              storiesReach: 0,
            };
          }

          context.firstRangeDate = {
            id: 1,
            data: {
              ...prepareMediasDataV2,
              ...response,
              followers_difference,
              new_followers,
            },
            // date: formateDateToLabelGraph2(firstRangeDate),
            initialTimestamp: response.stat_initial_timestamp
              ? response.stat_initial_timestamp : null,
            dayArray: response.dayArray,
          };
        })
        .then(() => MentionsProvider.fetchAnalytics({ channelId, payload: { ...secondRangeDate } }))
        .then(({ response }) => {
          const { new_followers, medias } = response;
          let prepareMediasDataV2 = {};
          let { followers_difference } = response;

          if (followers_difference > new_followers) followers_difference = new_followers;

          if (medias && medias.length) {
            const prepareMediasData = medias.reduce((acc, row) => {
              const keyToUse = ['like_count', 'comments_count', 'reach'];

              if (row.product_type === 'story') {
                if (!acc.storiesReach) acc.storiesReach = row.reach || 0;
                else acc.storiesReach += row.reach || 0;
              } else if (!acc.postsReach) acc.postsReach = row.reach || 0;
              else acc.postsReach += row.reach || 0;

              keyToUse.forEach((key) => {
                if (row[key]) {
                  if (!acc[key]) {
                    acc[key] = row[key] || 0;
                    return acc;
                  }
                  acc[key] += row[key] || 0;
                  return acc;
                }
                return acc;
              });

              return acc;
            }, {});

            const nbPosts = medias.filter((m) => m.product_type !== 'story').length;
            const nbStories = medias.filter((m) => m.product_type === 'story').length;

            prepareMediasDataV2 = {
              ...prepareMediasData,
              commentsPerPostAverage: Math.round(prepareMediasData.comments_count / nbPosts),
              reachByPostAverage: Math.round(prepareMediasData.postsReach / nbPosts),
              likesPerPostAverage: Math.round(prepareMediasData.like_count / nbPosts),
              reachByStoryAverage: Math.round(prepareMediasData.storiesReach / nbStories),
              postsReach: prepareMediasData.postsReach,
              storiesReach: prepareMediasData.storiesReach,
            };
          } else {
            prepareMediasDataV2 = {
              like_count: 0,
              comments_count: 0,
              reach: 0,
              commentsPerPostAverage: 0,
              reachByPostAverage: 0,
              likesPerPostAverage: 0,
              reachByStoryAverage: 0,
              postsReach: 0,
              storiesReach: 0,
            };
          }

          context.secondRangeDate = {
            id: 2,
            data: {
              ...prepareMediasDataV2,
              ...response,
              followers_difference,
              new_followers,
            },
            // date: formateDateToLabelGraph2(secondRangeDate),
          };
        })
        .then(() => context);
    },
    fetchAccountAnalyticsSeconde({ rootGetters }, dateFilter) {
      // const { 'Channel/getterCurrentChannel': currentChannel } = rootGetters;
      // const { username } = currentChannel;
      // const { value, type, subtype } = dateFilter;
      // const context = {};
      // let {
      //   thirdRangeDate,
      //   fourthRangeDate,
      //   fithRangeDate,
      //   sixthRangeDate,
      // } = {};

      // if (type === 'simple') {
      //   if (subtype === 'previousWeek') {
      //     thirdRangeDate = {
      //       maxDate: moment().day(0).add({ days: 1 }).subtract({ week: 2 })
      //         .format('YYYY-MM-DD'),
      //       minDate: moment().day(-6).subtract({ week: 2 }).format('YYYY-MM-DD'),
      //     };
      //     fourthRangeDate = {
      //       maxDate: moment().day(0).add({ days: 1 }).subtract({ week: 3 })
      //         .format('YYYY-MM-DD'),
      //       minDate: moment().day(-6).subtract({ week: 3 }).format('YYYY-MM-DD'),
      //     };
      //     fithRangeDate = {
      //       maxDate: moment().day(0).add({ days: 1 }).subtract({ week: 4 })
      //         .format('YYYY-MM-DD'),
      //       minDate: moment().day(-6).subtract({ week: 4 }).format('YYYY-MM-DD'),
      //     };
      //     sixthRangeDate = {
      //       maxDate: moment().day(0).add({ days: 1 }).subtract({ week: 5 })
      //         .format('YYYY-MM-DD'),
      //       minDate: moment().day(-6).subtract({ week: 5 }).format('YYYY-MM-DD'),
      //     };
      //   }
      //   if (subtype === 'currentWeek') {
      //     thirdRangeDate = {
      //       maxDate: moment().add({ days: 1 }).subtract({ week: 2 }).format('YYYY-MM-DD'),
      //       minDate: moment().add({ days: 1 }).subtract({ week: 3 }).format('YYYY-MM-DD'),
      //     };
      //     fourthRangeDate = {
      //       maxDate: moment().add({ days: 1 }).subtract({ week: 3 }).format('YYYY-MM-DD'),
      //       minDate: moment().add({ days: 1 }).subtract({ week: 4 }).format('YYYY-MM-DD'),
      //     };
      //     fithRangeDate = {
      //       maxDate: moment().add({ days: 1 }).subtract({ week: 4 }).format('YYYY-MM-DD'),
      //       minDate: moment().add({ days: 1 }).subtract({ week: 5 }).format('YYYY-MM-DD'),
      //     };
      //     sixthRangeDate = {
      //       maxDate: moment().add({ days: 1 }).subtract({ week: 5 }).format('YYYY-MM-DD'),
      //       minDate: moment().add({ days: 1 }).subtract({ week: 6 }).format('YYYY-MM-DD'),
      //     };
      //   }
      //   if (subtype === 'previousMonth') {
      //     thirdRangeDate = {
      //       maxDate: moment().add({ days: 1 }).subtract({ month: 3 }).endOf('month')
      //         .add({ days: 1 })
      //         .format('YYYY-MM-DD'),
      //       minDate: moment().subtract({ month: 3 }).startOf('month').format('YYYY-MM-DD'),
      //     };
      //     fourthRangeDate = {
      //       maxDate: moment().add({ days: 1 }).subtract({ month: 4 }).endOf('month')
      //         .add({ days: 1 })
      //         .format('YYYY-MM-DD'),
      //       minDate: moment().subtract({ month: 4 }).startOf('month').format('YYYY-MM-DD'),
      //     };
      //     fithRangeDate = {
      //       maxDate: moment().add({ days: 1 }).subtract({ month: 5 }).endOf('month')
      //         .add({ days: 1 })
      //         .format('YYYY-MM-DD'),
      //       minDate: moment().subtract({ month: 5 }).startOf('month').format('YYYY-MM-DD'),
      //     };
      //     sixthRangeDate = {
      //       maxDate: moment().add({ days: 1 }).subtract({ month: 6 }).endOf('month')
      //         .add({ days: 1 })
      //         .format('YYYY-MM-DD'),
      //       minDate: moment().subtract({ month: 6 }).startOf('month').format('YYYY-MM-DD'),
      //     };
      //   }
      //   if (subtype === 'currentMonth') {
      //     thirdRangeDate = {
      //       maxDate: moment().add({ days: 1 }).subtract({ month: 2 }).format('YYYY-MM-DD'),
      //       minDate: moment().subtract({ month: 3 }).format('YYYY-MM-DD'),
      //     };
      //     fourthRangeDate = {
      //       maxDate: moment().add({ days: 1 }).subtract({ month: 3 }).format('YYYY-MM-DD'),
      //       minDate: moment().subtract({ month: 4 }).format('YYYY-MM-DD'),
      //     };
      //     fithRangeDate = {
      //       maxDate: moment().add({ days: 1 }).subtract({ month: 4 }).format('YYYY-MM-DD'),
      //       minDate: moment().subtract({ month: 5 }).format('YYYY-MM-DD'),
      //     };
      //     sixthRangeDate = {
      //       maxDate: moment().add({ days: 1 }).subtract({ month: 5 }).format('YYYY-MM-DD'),
      //       minDate: moment().subtract({ month: 6 }).format('YYYY-MM-DD'),
      //     };
      //   }
      // }

      // if (type === 'custom') {
      //   const diffBetween = moment(value[1]).diff(value[0], 'days');
      //   thirdRangeDate = {
      //     maxDate: moment(value[0]).add({ days: 1 }).subtract({ days: diffBetween }).format('YYYY-MM-DD'),
      //     minDate: moment(value[0]).subtract({ days: diffBetween * 2 }).format('YYYY-MM-DD'),
      //   };
      //   fourthRangeDate = {
      //     maxDate: moment(value[0]).add({ days: 1 }).subtract({ days: diffBetween * 2 }).format('YYYY-MM-DD'),
      //     minDate: moment(value[0]).subtract({ days: diffBetween * 3 }).format('YYYY-MM-DD'),
      //   };
      //   fithRangeDate = {
      //     maxDate: moment(value[0]).add({ days: 1 }).subtract({ days: diffBetween * 3 }).format('YYYY-MM-DD'),
      //     minDate: moment(value[0]).subtract({ days: diffBetween * 4 }).format('YYYY-MM-DD'),
      //   };
      //   sixthRangeDate = {
      //     maxDate: moment(value[0]).add({ days: 1 }).subtract({ days: diffBetween * 4 }).format('YYYY-MM-DD'),
      //     minDate: moment(value[0]).subtract({ days: diffBetween * 5 }).format('YYYY-MM-DD'),
      //   };
      // }

      // return Promise.resolve()
      //   .then(() => MentionsProvider.fetchAnalytics({ username, payload: { ...thirdRangeDate } }))
      //   .then(({ response }) => {
      //     const { followers_difference } = response;
      //     let { new_followers } = response;

      //     if (new_followers < followers_difference) new_followers = followers_difference;

      //     context.thirdRangeDate = {
      //       id: 3,
      //       data: { ...response, followers_difference, new_followers },
      //       date: formateDateToLabelGraph2(thirdRangeDate),
      //     };
      //   })
      //   .then(() => MentionsProvider.fetchAnalytics({ username, payload: { ...fourthRangeDate } }))
      //   .then(({ response }) => {
      //     const { followers_difference } = response;
      //     let { new_followers } = response;

      //     if (new_followers < followers_difference) new_followers = followers_difference;

      //     context.fourthRangeDate = {
      //       id: 4,
      //       data: { ...response, followers_difference, new_followers },
      //       date: formateDateToLabelGraph2(fourthRangeDate),
      //     };
      //   })
      //   .then(() => MentionsProvider.fetchAnalytics({ username, payload: { ...fithRangeDate } }))
      //   .then(({ response }) => {
      //     const { followers_difference } = response;
      //     let { new_followers } = response;

      //     if (new_followers < followers_difference) new_followers = followers_difference;

      //     context.fithRangeDate = {
      //       id: 5,
      //       data: { ...response, followers_difference, new_followers },
      //       date: formateDateToLabelGraph2(fithRangeDate),
      //     };
      //   })
      //   .then(() => MentionsProvider.fetchAnalytics({ username, payload: { ...sixthRangeDate } }))
      //   .then(({ response }) => {
      //     const { followers_difference } = response;
      //     let { new_followers } = response;

      //     if (new_followers < followers_difference) new_followers = followers_difference;

      //     context.sixthRangeDate = {
      //       id: 6,
      //       data: { ...response, followers_difference, new_followers },
      //       date: formateDateToLabelGraph2(sixthRangeDate),
      //     };
      //   })
      //   .then(() => context);
    },
    fetchGraphAnalytics({ rootGetters }, range) {
      const { 'Channel/getterCurrentChannelId': channelId } = rootGetters;

      const [range_start, range_end] = range.value;

      return MentionsProvider
        .fecthGraphAnalytics({ payload: { channelId, range_start: moment(range_start).format('YYYY-MM-DD'), range_end: moment(range_end).format('YYYY-MM-DD') } })
        .then(({ response }) => {
          if (!response) return undefined;
          return response;
        });
    },
    downloadMentions({ rootGetters }, { mentions }) {
      const { 'Authenticate/getterCurrentUbuUser': user } = rootGetters;
      const { email } = user;

      const medias = mentions.map((mention) => ({
        id: mention.id,
        file_name: `${mention.username}-${mention.date}-${mention.product_type}-${mention.id}${mention.isImage ? '.jpg' : '.mp4'}`,
      }));
      return MentionsProvider
        .downloadMentions({ medias, recipients: [email] })
        .then(() => {
        })
        .catch((error) => {
          console.log('🚀 ~ file: TheMentions.store.js ~ line 181 ~ downloadMentions ~ error', error);
          throw new Error('Unable to download mentions');
        });
    },
    downloadMedias(_, { medias, folderName }) {
      const mediasToDownload = medias.map((media) => ({
        url: media.mediaUrl,
        file_name: `${media.username}-${media.date}-${media.source}${media.mediaType === 'VIDEO' ? '.mp4' : '.jpg'}`,
      }));
      return MentionsProvider
        .downloadMedias({ medias: mediasToDownload })
        .then((res) => {
          const blob = new Blob([res.data]);
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `${folderName}.zip`;
          link.click();
        })
        .catch((error) => {
          console.log('🚀 ~ file: TheMentions.store.js ~ line 181 ~ downloadMentions ~ error', error);
          throw new Error('Unable to download mentions');
        });
    },
    downloadAnalyticsXslsx({ rootGetters }, { payload }) {
      const {
        'Channel/getterCurrentChannel': currentChannel,
        'Channel/getterCurrentChannelId': channelId,
      } = rootGetters;
      const { username } = currentChannel;
      return MentionsProvider
        .downloadAnalyticsXslsx({ channelId, payload })
        .then((res) => {
          const blob = new Blob([res.data]);
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `Ubu_analytics_${username}_${moment(payload.minDate).format('YYYY-MM-DD')}_${moment(payload.maxDate).format('YYYY-MM-DD')}.xlsx`;
          link.click();
        })
        .catch((error) => {
          console.log('🚀 ~ file: TheMentions.store.js ~ line 743 ~ downloadAnalyticsXslsx ~ error', error);
          throw new Error('Unable to download analytics');
        });
    },
    subscribeReport({ rootGetters }, { payload }) {
      const { 'Channel/getterCurrentChannelId': channelId } = rootGetters;
      return MentionsProvider
        .subscribeReport({ channelId, ...payload })
        .then(({ response }) => {
          if (!response) return undefined;
          return response;
        })
        .catch((error) => {
          console.log('🚀 ~ file: TheMentions.store.js ~ line 784 ~ subscribeReport ~ error', error);
          throw new Error('Unable to subscribe report');
        });
    },
    getSubscribedReports({ rootGetters }) {
      const { 'Channel/getterCurrentChannelId': channelId } = rootGetters;
      return MentionsProvider
        .getSubscribedReports({ channelId })
        .then(({ response }) => {
          if (!response) return undefined;
          return response;
        })
        .catch((error) => {
          console.log('🚀 ~ file: TheMentions.store.js ~ line 784 ~ subscribeReport ~ error', error);
          throw new Error('Unable to getSubscribedReports');
        });
    },
    deleteSubscribedReports({ rootGetters }, { payload }) {
      const { 'Channel/getterCurrentChannelId': channelId } = rootGetters;
      return MentionsProvider
        .deleteSubscribedReports({ channelId, ...payload })
        .then(({ response }) => {
          if (!response) return undefined;
          return response;
        })
        .catch((error) => {
          console.log('🚀 ~ file: TheMentions.store.js ~ line 784 ~ subscribeReport ~ error', error);
          throw new Error('Unable to delete report subscription');
        });
    },
    fetchHashtags({ commit, rootGetters }) {
      const {
        'Channel/getterCurrentChannelId': channelId,
      } = rootGetters;

      return MentionsProvider
        .fetchHashtags({ channelId })
        .then(({ response }) => {
          let hashtags = [...response.hashtags, ...response.old_hashtags];
          hashtags = hashtags.map((hashtag) => ({
            name: hashtag.name,
            hashtag,
            isFetched: false,
          }));
          const mappedHashtags = arrayMapper(hashtags, 'name');
          commit('SET_HASHTAGS', mappedHashtags);
          return response.hashtags;
        })
        .catch((error) => {
          console.log('🚀 ~ file: TheMentions.store.js ~ line 211 ~ fetchHashtags ~ error', error);
          throw new Error('Unable to fetch hashtags');
        });
    },
    fetchHashtag({ commit, rootGetters }, { name }) {
      commit('SET_INFLUENCERS', []);
      const {
        'Channel/getterCurrentChannelId': channelId,
        'Activity/getterActivities': activities,
        'Campaign/getterCampaigns': campaigns,
        'Contact/getterContactsHasActivitiesBis': contactsHasActivitiesBis,
        'TheMentions/getterHashtags': hashtags,
        'Contact/getterContactsHasContactGroupsBis': contactsHasContactGroupBis,
        'ContactGroup/getterContactGroups': contactGroups,
      } = rootGetters;

      if (hashtags[name]) {
        if (hashtags[name].isFetched) return hashtags[name];
      }

      let influencers = [];
      return MentionsProvider
        .getHashtag({ channelId, name })
        .then(({ response }) => {
          console.log('🚀 ~ file: TheMentions.store.js ~ line 224 ~ .then ~ response', response);
          const hashtag = {
            name: response.name,
            medias: response.medias || [],
            tiktokMedias: response.tiktok_medias || [],
          };
          hashtag.medias = hashtag.medias.map((m) => ({
            id: m.id,
            caption: m.caption,
            mediaUrl: `https://io.getubu.com/media/${m.id}`,
            mediaType: m.media_type,
            username: m.user ? m.user.username : '',
            profilePicUrl: m.user ? m.user.profile_pic_url : '',
            userPk: m.user ? m.user.pk : '',
            timestamp: m.ts,
            date: moment(m.ts).format('YYYY-MM-DD'),
            source: 'instagram',
            platform: 'Instagram',
            location: m.location?.name,
            likeCount: m.like_count,
            commentCount: m.comment_count,
            views: 0,
            followerCount: m.user ? m.user.follower_count : 0,
            skeletonHeight: (m.original_height / m.original_width) * 220,
            language: m.user?.language,
          })).map((m) => ({
            ...m,
            postEngagement: ((m.likeCount + m.commentCount) / m.followerCount) * 100,
          })).map((m) => ({
            ...m,
            reach: ((m.postEngagement / 100) * m.followerCount) * 5,
          }));
          hashtag.tiktokMedias = hashtag.tiktokMedias.map((m) => ({
            id: m.id,
            caption: m.desc,
            mediaUrl: `https://io.getubu.com/tiktokMedia/${m.id}`,
            mediaType: 'VIDEO',
            username: m.author.uniqueId,
            userPk: m.author.id,
            likeCount: m.stats.diggCount,
            commentCount: m.stats.commentCount,
            followerCount: m.authorStats.followerCount,
            profilePicUrl: m.author.avatarThumb,
            timestamp: m.createTime * 1000,
            date: moment(m.createTime * 1000).format('YYYY-MM-DD'),
            views: m.stats.playCount,
            reach: m.stats.playCount,
            source: 'tiktok',
            platform: 'TikTok',
            skeletonHeight: (m.video.height / m.video.width) * 220,
          })).map((m) => ({
            ...m,
            postEngagement: ((m.likeCount + m.commentCount) / m.views) * 100,
          }));
          hashtag.medias = [...hashtag.tiktokMedias, ...hashtag.medias]
            .sort((a, b) => b.timestamp - a.timestamp);

          influencers = hashtag.medias.reduce((acc, media) => {
            const found = acc.find((m) => m.userPk === media.userPk);
            if (!found) {
              acc.push({
                userPk: media.userPk,
                contactId: media.userPk,
                username: media.username,
                followerCount: media.followerCount,
                platform: media.platform,
                source: media.source,
                views: media.views,
                locations: [...found?.locations || [], media.location],
                medias: [...found?.medias || [], media],
                language: media.language || '',
              });
            } else {
              found.medias.push(media);
              found.locations = [...new Set([...found.locations, media.location])];
              found.views += media.views;
            }
            return acc;
          }, []).map((user) => ({
            ...user,
            nbPosts: user.medias.length,
            views: user.medias.reduce(
              (sum, media) => sum + media.views, 0,
            ),
            averagePostEngagement: user.medias.reduce(
              (sum, media) => sum + media.postEngagement, 0,
            ) / user.medias.length,
            averagePostLikes: Math.floor(user.medias.reduce(
              (sum, media) => sum + media.likeCount, 0,
            ) / user.medias.length),
            averagePostComments: Math.floor(user.medias.reduce(
              (sum, media) => sum + media.commentCount, 0,
            ) / user.medias.length),
            averageReach: Math.floor(user.medias.reduce(
              (sum, media) => sum + media.reach, 0,
            ) / user.medias.length),
            activities: query.findObjectLite({
              model: 'activities',
              relationTable: contactsHasActivitiesBis,
              dataTable: activities,
              fromId: user.userPk,
            })
              .map((activity) => ({
                ...activity,
                campaignDetail: campaigns[activity.campaignId],
              })),
            contactGroup: query.findObjectLite({
              model: 'contactGroups',
              relationTable: contactsHasContactGroupBis,
              dataTable: contactGroups,
              fromId: user.userPk,
            }),
          }));
          const newHashtag = {
            [hashtag.name]: {
              isFetched: true,
              name: hashtag.name,
              hashtag,
              influencers,
            },
          };
          commit('SET_HASHTAGS', newHashtag);
          return influencers.filter((influencer) => influencer.source === 'instagram').map((influencer) => influencer.userPk);
        })
        .then((pks) => this.dispatch('Contact/fetchMissingListContacts', { pks }))
        .then(() => commit('SET_INFLUENCERS', influencers))
        .catch((error) => {
          console.log('🚀 ~ file: TheMentions.store.js ~ line 227 ~ getHashtag ~ error', error);
          throw new Error('Unable to fetch hashtag');
        });
    },
    startHashtag({ commit, rootGetters }, payload) {
      const {
        'Channel/getterCurrentChannelId': channelId,
      } = rootGetters;
      return MentionsProvider
        .startHashtag({ channelId, payload })
        .then(({ response, error }) => {
          if (error && error.message === 'hashtag credits expired') throw new Error('In order to add more hashtags, please contact us on intercom');
          else if (error) throw new Error('An error occured');
          console.log('🚀 ~ file: TheMentions.store.js ~ line 209 ~ .then ~ response', response);
          const newHashtag = {
            [payload.name]: {
              isFetched: false,
              name: payload.name,
            },
          };
          commit('SET_HASHTAGS', newHashtag);
          return response;
        });
    },
    stopHashtag({ rootGetters }, name) {
      const {
        'Channel/getterCurrentChannelId': channelId,
      } = rootGetters;
      return MentionsProvider
        .stopHashtag({ channelId, name })
        .then(({ response }) => {
          console.log('🚀 ~ file: TheMentions.store.js ~ line 255 ~ .then ~ response', response);
          return response;
        })
        .catch((error) => {
          console.log('🚀 ~ file: TheMentions.store.js ~ line 260 ~ stopHashtag ~ error', error);
          throw new Error('Unable to stop hashtags');
        });
    },
  },
  mutations: {
    SET_HASHTAGS(state, newDatas) { state.hashtags = updateDatasIfExists({ newDatas, actualDatas: state.hashtags, key: 'name' }); },
    SET_MENTIONS(state, mentions) { state.currentMentions = mentions; },
    SET_INFLUENCERS(state, influencers) { state.currentInfluencers = influencers; },
  },
};
