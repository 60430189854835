<template>
  <UbuCommonMessageCard
    :is-me="isMe"
    :date="date"
    is-media
    @deleteMessage="$emit('deleteMessage')"
  >
    <template v-slot:image>
      <b-carousel
        v-if="carouselMediaImgUrl.length && carouselMediaImgUrl.length > 1"
        :arrows="false"
        :arrow-hover="true"
        :pause-hover="true"
        :interval="5000"
      >
        <b-carousel-item
          v-for="(media, index) in carouselMediaImgUrl"
          :key="index"
        >
          <UbuCommonVideo
            v-if="media.hasVideo && media.hasBucket"
            :video-url="media.videoUrl"
            :image-url="media.imageUrl"
            :has-bucket="media.hasBucket"
          />
          <UbuCommonImage
            v-else
            :url="media.imageUrl"
            :has-bucket="media.hasBucket"
          />
        </b-carousel-item>
      </b-carousel>
      <UbuCommonVideo
        v-else-if="carouselMediaImgUrl[0].hasVideo && carouselMediaImgUrl[0].hasBucket"
        :video-url="carouselMediaImgUrl[0].videoUrl"
        :image-url="carouselMediaImgUrl[0].imageUrl"
        :has-bucket="carouselMediaImgUrl[0].hasBucket"
      />
      <UbuCommonImage
        v-else
        :url="carouselMediaImgUrl[0].imageUrl"
        :has-bucket="carouselMediaImgUrl[0].hasBucket"
      />
    </template>

    <template
      v-if="username"
      v-slot:content
    >
      <div class="card-content-text">
        <UbuInstaName
          class="ubu-instalink-msg"
          :username="username"
        />
        <p
          v-if="text"
          class="ubuTextElipsed"
        >
          {{ text }}
        </p>
      </div>
    </template>
  </UbuCommonMessageCard>
</template>

<script>
export default {
  name: 'UbuCommonPostShare',
  props: {
    isMe: {
      type: Boolean,
      default: false,
    },
    date: {
      type: String,
      required: true,
    },
    carouselMediaImgUrl: {
      type: Array,
      default: () => ([]),
    },
    hasBucket: {
      type: Boolean,
      default: false,
    },
    hasVideo: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      required: true,
    },
    username: {
      type: String,
      required: true,
    },
  },
};
</script>
