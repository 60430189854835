<template>
  <div class="UbuNote_wrapper">
    <div
      class="UbuNote_text ubu-text3"
    >
      {{ cleanedText }}
    </div>
    <span class="UbuNote_timestamp ubu-subtext1">{{ displayUserName }} {{ formatedDate }}</span>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'UbuNote',
  props: {
    note: {
      type: Object,
      default: () => {},
    },
    user: {
      type: String,
      default: '',
    },
    ubuUser: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    date: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters({
      getterUbuUserById: 'UbuUser/getterUbuUserById',
    }),
    displayUserName() {
      const ubuUser = this.getterUbuUserById(this.ubuUser);
      // console.log('ubuUser', ubuUser);
      if (ubuUser) {
        return `${ubuUser.name} - `;
      }
      if (this.user) return `${this.user} - `;
      return '';
    },
    formatedDate() {
      return this.date
        .format('YY-MM-DD HH:mm');
    },
    cleanedText() {
      const regex = /(<([^>]+)>)/ig;
      return this.text.replace(regex, '');
    },
  },
};
</script>
<style lang="scss" scoped>
.UbuNote_wrapper {
  background-color: var(--note);
  border-radius: 24px;
  padding: 12px 16px;
  display: inline-block;
  text-align: center;
  justify-content: center;
  align-self: center;
  width: auto;
  .UbuNote_text{
    display: flex;
    justify-content: center;
    color: var(--cola);
  }
  .UbuNote_timestamp {
    color: var(--cola);
  }
}
</style>
