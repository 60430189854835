<template>
  <div class="UbuNoteInput_wrapper">
    <img src="@/assets/pictos/pin.svg">
    <textarea
      v-model="localNote"
      @blur="updateUser"
    />
  </div>
</template>
<script>
// import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'UbuNoteInput',
  props: {
    contact: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      noteValue: '',
    };
  },
  computed: {
    contactPk() {
      if (this.contact) return this.contact.pk;
      return null;
    },
    localNote: {
      get() {
        return this.contact.contactPanelNotes[0];
      },
      set(newValue) {
        this.noteValue = newValue;
      },
    },
  },
  methods: {
    updateUser(event) {
      const myContact = {
        ...this.contact,
        notes: [...this.contact.contactPanelNotes],
      };

      myContact.notes = [event.target.value];
      console.log('NOTE VAL -> this.noteValue', this.noteValue);
      console.log('NOTE VAL -> event.target.value', event.target.value);

      this.$emit('updateContact', {
        contact: myContact,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.UbuNoteInput_wrapper {
  position: relative;
  height: 250px;
  background-color: var(--note);
  width: auto;
  border-radius: 12px;
  padding: 20px;
  img {
    position: absolute;
    left: 0px;
    right: 0px;
    top: -20px;
    width: auto;
    margin: auto;
  }

  textarea {
    color: var(--cola);
    font-family: PatrickHand;
    font-size: 18px;
    width: 100%;
    outline: none;
    max-height: 100%;
    height: 100%;
    resize: none;
    border: none;
    background: none;
  }
}
</style>
