<template>
  <section
    id="TheCRM"
    class="flex"
  >
    <TheSidebar
      :menu-items="menu"
      :menu-items-first-column="menuItemsFirstColumn"
    />
    <div
      id="UbuCRMBoardNew"
    >
      <div class="crm-top">
        <div>
          <h1 class="ubu-title">Lists</h1>
          <p
            v-if="list"
            class="ubu-info"
          >
            {{ `${list.name} created on ${$moment(list.created)
              .format('YYYY-MM-DD')}  |  ${list.usernames.length} users` }}
          </p>
        </div>
        <UbuButton
          :icon="{ name: 'add', filled: true }"
          secondary
          @click="isModalAddListActive = !isModalAddListActive"
        >
          New list
        </UbuButton>
      </div>
      <div class="header">
        <div class="listButtons">
          <UbuButton
            center
            simple
            :active="!list"
            :tooltip="{
              label: activeContactTooltipLabel,
              position: 'is-right',
              delay: 500,
            }"
            @click="redirectTo('/crm')"
          >
            Active contacts
          </UbuButton>
          <UbuButton
            v-for="(listItem, index) in lists"
            :key="index"
            center
            simple
            :active="list && list.listId === listItem.listId"
            @click="redirectTo(`/crm/${listItem.listId}`)"
          >
            {{ listItem.name }}
          </UbuButton>
        </div>
      </div>

      <b-modal
        v-model="isModalAddListActive"
        :width="640"
        scroll="keep"
      >
        <UbuCRMModalAddList
          v-model="usernameSearch"
          :username-search-result="usernameSearchResult"
          @close="isModalAddListActive = false"
          @addFromModal="createList($event)"
        />
      </b-modal>

      <b-modal
        v-model="isViewModalActive"
        :width="640"
        scroll="keep"
      >
        <UbuCRMViewModal
          :columns="currentColumns"
          @close="isViewModalActive = false"
          @createView="createView($event)"
        />
      </b-modal>

      <UbuCRMFilters
        v-model="searchValue"
        :check-box-filters="checkBoxFilters"
        :range-filters="rangeFilters"
        :number-input-range-filters="numberInputRangeFilters"
        :checked-rows="checkedRows"
        :radio-filters="radioFilters"
        :filter-list-sorted-by-ordinal="filterListSortedByOrdinal"
        :columns="currentColumns"
        :active-campaign-list="activeCampaigns"
        :list="list"
        :is-enrich-profiles-loading="isEnrichProfilesLoading"
        :is-enrinch-profiles-disabled="isEnrinchProfilesDisabled"
        :enrich-profiles-tooltip-label="enrichProfilesTooltipLabel"
        :custom-c-r-m-columns="customCRMColumns"
        :selected-custom="selectedCustom"
        :contact-groups="Object.values(contactGroups)"
        @setRangeFilter="setRangeFilter($event)"
        @setCheckFilter="setCheckFilter($event)"
        @setNumberInputFilter="setNumberInputFilter($event)"
        @setRadioFilter="setRadioFilter($event)"
        @changeColumns="currentColumns = $event"
        @selectCampaign="addContactsIntoCampaign($event)"
        @removeUserFromList="hideUsersFromList()"
        @clearCheckedRow="checkedRows = []"
        @enrichProfiles="enrichProfiles()"
        @customColumns="customColumns($event)"
        @openCreateView="isViewModalActive = true"
        @assignContactsToContactGroup="assignContactsToContactGroup($event)"
        @createContactGroupAndAssignMany="createContactGroupAndAssignMany($event)"
      />

      <UbuCRMTable
        :list-filtered="listFiltered"
        :checked-rows="checkedRows"
        :selected-row="selectedRow"
        :thread-groups-list="Object.values(threadGroups)"
        :contact-groups-list="Object.values(contactGroups)"
        :active-campaign-list="activeCampaigns"
        :columns="currentColumns"
        :loading="!fetchIsDone || isTableLoading"
        :is-generate-report-loading="isGenerateReportLoading"
        :is-custom-list="list !== null"
        :all-referral-concat-discount="allReferralConcatDiscount"
        :has-shopify-shop="hasShopifyShop"
        :is-statistics-loading="isStatisticsLoading"
        @checkRow="checkRow($event)"
        @selectRow="selectRow($event)"
        @checkAllRows="checkAllRows($event)"
        @addCustom="addCustom($event)"
        @removeCustom="removeCustom($event)"
        @generateReport="generateReport($event)"
        @updateContact="updateContact($event)"
        @createThreadGroupAndAssign="createThreadGroupAndAssign($event)"
        @createContactGroupAndAssign="createContactGroupAndAssign($event)"
        @openModalAddLink="$emit('openModalAddLink', $event)"
        @assignCampaignToLink="assignCampaignToLink($event)"
        @removeContactToLink="removeContactToLink($event)"
        @openNewMessage="openNewMessage($event)"
        @hardRefresh="hardRefresh($event)"
        @fetchTiktokUser="fetchTiktokUser($event)"
        @openModalEditUsernameTiktok="showModalEditUsernameTiktok($event)"
      />
      <div class="crm-bottom">
        <download-csv
          :data="listFiltered"
          :fields="csvFields"
          :name="list ? `export-${list.name}-list.csv` : 'export-active-contacts-list.csv'"
        >
          <UbuButton
            class="UbuWorkspaceCustoms_button"
            secondary
          >
            Export list
          </UbuButton>
        </download-csv>
        <UbuButton
          v-if="list"
          secondary
          @click="endList(list)"
        >
          End list
        </UbuButton>
      </div>
      <b-modal
        v-model="isMessageModalActive"
        :width="640"
        scroll="keep"
      >
        <UbuCommonNewMessageModal
          @close="isMessageModalActive = false"
          @sendNewMessage="sendNewMessage({user: userNewMessage, message: $event});"
        />
      </b-modal>
      <b-modal
        v-model="isEndedModalActive"
        :width="640"
        scroll="keep"
      >
        <UbuCommonModal
          title="Contact(s) added to campaign"
          @close="isEndedModalActive = false"
        >
          <template slot="info">
            <p>
              <strong>
                {{ contactAddedToCampaign }}
              </strong>
              have been added to campaign
            </p>
            <p>
              <strong>
                {{ contactAlreadyInCampaign }}
              </strong>
              were already in this campaign
            </p>
          </template>
          <template slot="actions">
            <UbuButton
              grow
              center
              secondary
              class="m-2"
              @click="isEndedModalActive = false"
            >
              Close
            </UbuButton>
          </template>
        </UbuCommonModal>
      </b-modal>
      <b-modal
        v-model="isModalEditUsernameTiktok"
        :width="640"
        scroll="keep"
      >
        <UbuContactPanelModalTiktokUsername
          @close="isModalEditUsernameTiktok = false"
          @addFromModal="updateTiktokUsername($event)"
        />
      </b-modal>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { snackSuccess, snackError } from '../$catalog/snackbar';
import { debounce } from '../$utils/helpers';
import CommonMixin from '../$mixins/CommonMixin';
import DataManagementMixin from './$mixins/DataManagementMixin';
import FilterManagementMixin from './$mixins/FilterManagementMixin';

export default {
  name: 'TheCRM',
  mixins: [CommonMixin, DataManagementMixin, FilterManagementMixin],
  data() {
    return {
      isViewModalActive: false,
      isEnrichProfilesLoading: false,
      isGenerateReportLoading: null,
      isEndedModalActive: false,
      contactAddedToCampaign: 0,
      contactAlreadyInCampaign: 0,
      usernameSearch: '',
      usernameSearchResult: [],
      isModalAddListActive: false,
      isTableLoading: false,
      checkedRows: [],
      isPanelActive: false,
      selectedRow: {},
      isMessageModalActive: false,
      userNewMessage: null,
      isStatisticsLoading: false,
      isModalEditUsernameTiktok: false,
      currentEditTiktokContactId: null,
      columns: [
        'username',
        'email',
        'threadGroup',
        'contactGroup',
        'followerCount',
        'engagement',
        'activeCampaign',
        'pastCampaign',
        'storyMentionCount',
        'postMentionCount',
        'ageAudience',
        'locationAudience',
        'languageAudience',
        'genderAudience',
        'lastMentionOrTagPost',
        'lastAddedCampaign',
        'lastMessageTS',
        // 'categoryEnum',
        'categoryName',
        'tiktokUsername',
        'location',
        'growth',
        'shopifyLinksCount',
        'shopifyAmountOrders',
        'shopifyTotalOrders',
        'follows',
        'language',
        'age',
        'gender',
        'category',
      ],
      // TEST
      // customCRMColumns: [
      // {
      //   name: 'getubu',
      //   columns: ['username', 'email'],
      // },
      // ],
      currentColumns: [
        'username',
        'email',
        'threadGroup',
        'contactGroup',
        'followerCount',
        'engagement',
        'activeCampaign',
        'pastCampaign',
        'storyMentionCount',
        'postMentionCount',
        'ageAudience',
        'locationAudience',
        'languageAudience',
        'genderAudience',
        'lastMentionOrTagPost',
        'lastAddedCampaign',
        'lastMessageTS',
        // 'categoryEnum',
        'categoryName',
        'tiktokUsername',
        'location',
        'growth',
        'shopifyLinksCount',
        'shopifyAmountOrders',
        'shopifyTotalOrders',
        'follows',
        'language',
        'age',
        'gender',
        'category',
      ],
      selectedCustom: null,
      csvFields: [
        'username',
        'full_name',
        'follower_count',
        'average_like',
        'average_comment',
        'engagement',
        'business_email',
        'public_email',
        'public_phone_number',
        'emails',
        'phones',
        'websites',
        'location',
        'tiktokUsername',
        'storyMentionCount',
        'postMentionCount',
      ],
      // TEST
      enrichProfilesTooltipLabel: 'Enrich profiles (>5000 followers & not be private account)',
      activeContactTooltipLabel: 'people who reached you recently, people who are labeled/tagged, in active campaigns and also people with more than 5k followers who mentionned you since you use ubu.',
    };
  },
  computed: {
    ...mapGetters({
      getter: 'TheCRM/getterChannelContacts',
      campaignActivitiesDetails: 'TheInfluence/getterCampaignActivitiesDetails',
      sidebarWidth: 'TheSidebar/getterWidth',
      fetchIsDone: 'Sequence/getterFetchDone',
      threadGroups: 'ThreadGroup/getterThreadGroups',
      contactGroups: 'ContactGroup/getterContactGroups',
      lists: 'TheCRM/getterLists',
      currentChannelId: 'Channel/getterCurrentChannelId',
      customCRMColumns: 'TheCRM/getterCustomCRMColumns',
      getterShopifyShop: 'TheContactPanel/getterShopifyShop',
      // getterCampaigns: 'TheContactPanel/getterCampaigns',
      // getterContactPanelData: 'TheContactPanel/getterContactPanelData',
    }),
    menu() {
      if (!this.fetchIsDone) return [];
      return this.campaignList
        .filter(({ ended }) => !ended)
        .sort((a, b) => b.createdOn - a.createdOn)
        .map((campaign) => ({
          icon: 'influence',
          text: campaign.title,
          href: `/influence/${campaign.campaignId}`,
          count: 0,
          hasUnread: !!campaign.campaignHasUnread,
        }));
    },
    menuItemsFirstColumn() {
      return [
        {
          icon: 'crm',
          text: 'Crm',
          href: '/crm',
        },
        {
          icon: 'settings',
          text: 'Campaigns',
          href: '/influence',
        },
        {
          icon: 'overview',
          text: 'Audit',
          href: '/audit',
        },
      ];
    },
    // activeCampaigns() {
    //   return this.getterCampaigns.filter(({ ended }) => !ended);
    // },
    allChecked() {
      return this.listFiltered.length && this.checkedRows.length === this.listFiltered.length;
    },
    currentContactId() {
      return this.selectedRow ? this.selectedRow.contactId : null;
    },
    isEnrinchProfilesDisabled() {
      if (this.checkedRows.length > 30) {
        this.updateEnrichProfilesTooltipLabel('max');
        return true;
      }
      if (this.checkedRows.length && !!this.checkedRows
        .find((row) => row.followerCount < 5000 || row.is_private)) {
        this.updateEnrichProfilesTooltipLabel('accountUnallowed');
        return true;
      }
      return false;
    },
    hasShopifyShop() {
      return !!this.getterShopifyShop.length;
    },
    allDiscountCode() {
      return this.getterShopifyShop.reduce((acc, row) => {
        const { discountCode } = row;
        if (discountCode) acc.push(...discountCode);

        return acc;
      }, []);
    },
    allReferralLink() {
      return this.getterShopifyShop.reduce((acc, row) => {
        const { referralLink } = row;
        if (referralLink) acc.push(...referralLink);

        return acc;
      }, [])
        .sort((a, b) => this.$moment(b.createdOn) - this.$moment(a.createdOn));
    },
    allReferralConcatDiscount() {
      return this.allDiscountCode.concat(this.allReferralLink);
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    usernameSearch: debounce(function (newVal) {
      this.usernameSearchResult = [];
      if (newVal === '') return;
      this.searchUser(
        { channelId: this.currentChannelId, username: newVal },
      ).then((response) => {
        if (response) {
          const alreadyAsked = this.lists.map(({ name }) => name);
          this.usernameSearchResult = response
            .map((user) => ({ ...user, listAlreadyExist: alreadyAsked.includes(user.username) }));
        }
      });
    }, 700),
    $route() {
      if (this.list) {
        this.isTableLoading = true;
        if (this.list.isCustomList) {
          this.fetchMissingListContactsByUsernames({
            usernames: this.list.usernames,
          }).then(() => { this.isTableLoading = false; });
        } else {
          this.fetchMissingListContacts({
            pks: this.list.userPks,
          }).then(() => { this.isTableLoading = false; });
        }
      }
    },
    fetchIsDone() {
      if (this.list) {
        this.isTableLoading = true;
        if (this.list.isCustomList) {
          this.fetchMissingListContactsByUsernames({
            usernames: this.list.usernames,
          }).then(() => { this.isTableLoading = false; });
        } else {
          this.fetchMissingListContacts({
            pks: this.list.userPks,
          }).then(() => { this.isTableLoading = false; });
        }
      }
      this.fetchUsersMentions();
    },
  },
  mounted() {
    if (this.fetchIsDone) this.fetchUsersMentions();
  },
  methods: {
    ...mapActions({
      _assignThreadToThreadGroup: 'TheThreadHeader/assignThreadToThreadGroup',
      _removeThreadFromThreadGroup: 'TheThreadHeader/removeThreadFromThreadGroup',
      _createThreadGroupAndAssign: 'TheThreadHeader/createThreadGroupAndAssign',
      _assignContactToContactGroup: 'TheThreadHeader/assignContactToContactGroup',
      _removeContactFromContactGroup: 'TheThreadHeader/removeContactFromContactGroup',
      _createContactGroupAndAssign: 'TheThreadHeader/createContactGroupAndAssign',
      _assignThreadToUbuUser: 'TheThreadHeader/assignThreadToUbuUser',
      _removeThreadFromUbuUser: 'TheThreadHeader/removeThreadFromUbuUser',
      _generateReport: 'TheCRM/generateReport',
      _assignContactsToContactGroup: 'TheThreadList/assignContactsToContactGroup',
      _createContactGroupAndAssignMany: 'TheThreadList/createContactGroupAndAssignMany',
      _hardRefresh: 'TheContactPanel/hardRefresh',
      _enrichProfiles: 'TheCRM/enrichProfiles',
      _fetchTiktokUser: 'Contact/fetchTiktokUser',
      _updateTiktokUsername: 'Contact/updateTiktokUsername',
      setStatus: 'TheInfluence/setStatus',
      searchUser: 'TheCRM/searchUser',
      addList: 'TheCRM/createList',
      stopList: 'TheCRM/endList',
      getContactByUsername: 'TheInfluence/getContactByUsername',
      setUnscheduled: 'TheInfluence/setUnscheduled',
      deleteActivity: 'TheInfluence/deleteActivity',
      fetchMissingListContacts: 'Contact/fetchMissingListContacts',
      fetchMissingListContactsByUsernames: 'Contact/fetchMissingListContactsByUsernames',
      removeUserFromList: 'TheCRM/removeUserFromList',
      removeUserFromCustomList: 'TheCRM/removeUserFromCustomList',
      updateContact: 'TheContactPanel/updateContact',
      saveCustomSetting: 'TheCRM/saveCustomSetting',
      assignDiscountCodeToCampaign: 'Shopify/DiscountCode/assignDiscountCodeToCampaign',
      removeDiscountCodeToContact: 'Shopify/DiscountCode/removeDiscountCodeToContact',
      assignReferralLinkToCampaign: 'Shopify/ReferralLink/assignReferralLinkToCampaign',
      removeReferralLinkToContact: 'Shopify/ReferralLink/removeReferralLinkToContact',
      sendMessage: 'TheInbox/sendMessage',
      fetchUsersMentions: 'TheCRM/fetchUsersMentions',
    }),
    redirectTo(to) {
      const { currentRoute: { path } } = this.$router;
      if (path !== to) this.$router.push(to);
    },
    selectRow(event) {
      this.selectedRow = event;
    },
    checkAllRows(event) {
      this.checkedRows = [...event];
    },
    checkRow(event) {
      // this.showFilters = false;
      this.checkedRows = [...event];
    },
    generateReport(event) {
      // to report, contact must have more than 5k followers & !private account
      if (event.followerCount > 5000 && !event.is_private) {
        this.isGenerateReportLoading = event.username;
        this._generateReport(event)
          .then(({ response, error }) => {
            if (error) {
              this.$buefy.snackbar.open({
                ...snackError,
                message: `Error while loading ${event.username} report`,
                onAction: () => this.generateReport(event),
              });
              this.isGenerateReportLoading = null;
              return;
            }
            this.$buefy.snackbar.open({
              ...snackSuccess,
              type: 'is-link',
              indefinite: true,
              message: `Click to open ${event.username} report`,
              onAction: () => window
                .open(response.public_url, `${event.username} generated report`),
            });
            this.isGenerateReportLoading = null;
          });
      } else {
        this.$buefy.snackbar.open({
          ...snackError,
          actionText: null,
          message: 'Error contact must have more than 1500 followers & not be private account',
        });
      }
    },
    hideUsersFromList() {
      if (this.list.isCustomList) {
        this.removeUserFromCustomList({
          list: this.list,
          usernames: this.checkedRows.map(
            (r) => r.username,
          ),
        })
          .then(() => {
            this.checkedRows = [];
          });
      } else {
        this.removeUserFromList({ list: this.list, pks: this.checkedRows.map((r) => r.pk) })
          .then(() => {
            this.checkedRows = [];
          });
      }
    },
    endList(list) {
      this.stopList(list.listId).then(({ error }) => {
        if (!error) {
          this.$buefy.snackbar.open({
            ...snackSuccess,
            message: `list ${list.name} was ended`,
          });
        }
      });
    },
    customColumns(settingsName) {
      const settings = this.customCRMColumns.find(({ name }) => name === settingsName);
      // console.log('🚀 ~ file: TheCRM.vue ~ line 429 ~ customColumns ~ settings', settings);
      if (settingsName === 'default') {
        this.currentColumns = [...this.columns];
        this.selectedCustom = null;
      }
      if (settings) {
        this.currentColumns = [...settings.columns];
        this.selectedCustom = settings.name;
      }
    },
    enrichProfiles() {
      this.isEnrichProfilesLoading = true;
      const usernames = this.checkedRows.map(({ username }) => username);
      this._enrichProfiles({ channelId: this.channelId, usernames })
        .then(({ error }) => {
          if (error) {
            this.$buefy.snackbar.open({
              ...snackError,
              message: 'Error while loading enrich profiles',
              onAction: () => this.enrichProfiles(),
            });
            this.isEnrichProfilesLoading = false;
            return;
          }
          this.$buefy.snackbar.open({
            ...snackSuccess,
            message: 'Contacts have been updated',
          });
          this.isEnrichProfilesLoading = false;
          this.checkedRows = [];
          // on perd la reference apres la MàJ des contacts faite dans le store
          // (l'ancienne version du contact est dans checkedRows et n'est pas remplacé avec l'update)
          // on doit trouver une meilleur solution que de simplement vider la liste de contacts checked
        });
    },
    createView(event) {
      this.saveCustomSetting(event);
      this.selectedCustom = event.name;
      this.currentColumns = [...event.columns];
      this.isViewModalActive = false;
    },
    updateEnrichProfilesTooltipLabel(errorName) {
      if (errorName === 'max') this.enrichProfilesTooltipLabel = 'Too much selected contacts (30max)';
      if (errorName === 'accountUnallowed') this.enrichProfilesTooltipLabel = 'Enrich profiles (>5000 followers & not be private account)';
    },
    assignCampaignToLink(event) {
      const { campaignId: newCampaignId, code: { shopifyShopSsid, type, ...rest } } = event;

      // if campaignId emited already assigned to this link, we remove relation
      const campaignIsAlreadyAssignToLink = rest.campaignId === newCampaignId;
      const campaignIdToReturn = campaignIsAlreadyAssignToLink ? null : newCampaignId;

      if (type === 'discount') {
        this.assignDiscountCodeToCampaign({
          discountCode: rest,
          campaignId: campaignIdToReturn,
          shopifyShopSsid,
        });
      }
      if (type === 'referral') {
        this.assignReferralLinkToCampaign({
          referralLink: rest,
          campaignId: campaignIdToReturn,
          shopifyShopSsid,
        });
      }
    },
    removeContactToLink(event) {
      const {
        type, shopifyShopSsid, ...rest
      } = event;

      if (type === 'discount') {
        this.removeDiscountCodeToContact({ discountCode: rest, shopifyShopSsid });
      }
      if (type === 'referral') {
        this.removeReferralLinkToContact({ referralLink: rest, shopifyShopSsid });
      }
    },
    openNewMessage(event) {
      this.userNewMessage = event;
      this.isMessageModalActive = true;
    },
    sendNewMessage(event) {
      console.log('🚀 ~ file: TheCRM.vue ~ line 607 ~ sendNewMessage ~ event', event);
      this.isMessageModalActive = false;
      const { user: { pk, username }, message } = event;
      const messageParsed = message.replace('{name}', username);

      const snackToWait = this.$buefy.snackbar.open({
        ...snackSuccess,
        message: 'Sending message...',
      });

      this.sendMessage({
        channelId: this.currentChannelId,
        contactId: pk,
        message: messageParsed,
      })
        .then(({ error }) => {
          snackToWait.close();
          if (error) {
            this.$buefy.snackbar.open({
              ...snackError,
              message: 'Error while sending',
              actionText: 'Retry',
              onAction: () => this.sendNewMessage(event),
            });
          } else {
            this.$buefy.snackbar.open({
              ...snackSuccess,
              message: 'Message was sent successfully',
            });
            this.usernameSearch = '';
          }
        });
    },
    hardRefresh(event) {
      this.isStatisticsLoading = true;
      this._hardRefresh(event).then(() => { this.isStatisticsLoading = false; });
    },
    fetchTiktokUser(event) {
      this.isStatisticsLoading = true;
      this._fetchTiktokUser(event).then(() => { this.isStatisticsLoading = false; });
    },
    showModalEditUsernameTiktok({ contactId }) {
      this.isModalEditUsernameTiktok = true;
      this.currentEditTiktokContactId = contactId;
    },
    updateTiktokUsername({ username }) {
      this._updateTiktokUsername({ username, contactId: this.currentEditTiktokContactId })
        .then(({ error }) => {
          this.isModalEditUsernameTiktok = false;
          if (error) {
            this.$buefy.snackbar.open({
              ...snackError,
              message: 'Error while setting/updating TikTok username',
            });
          } else {
            this.fetchTiktokUser({ username, contactId: this.currentEditTiktokContactId });
            this.$buefy.snackbar.open({
              ...snackSuccess,
              message: `The TikTok username has been set to @${username}. 
              Thank you, the statistics will be updated soon.`,
            });
          }
        });
    },
  },
};
</script>

<style lang="scss">
@import 'Crm';
</style>
