import builder from '../../$utils/axios';

export default class InboxProvider {
  static sendMessage(payload) {
    const { channelId, contactId, message } = payload;

    return builder({
      method: 'post',
      url: `/channel/${channelId}/user/${contactId}/message`,
      data: { message },
    });
  }

  static deletMessage(payload) {
    const { channelId, threadId, messageId } = payload;
    return builder({
      method: 'get',
      url: `/channel/${channelId}/thread/${threadId}/deleteItem/${messageId}`,
    });
  }

  static sendNote(payload) {
    const {
      channelId, threadId, text, timestamp,
    } = payload;
    return builder({
      method: 'post',
      url: `/channel/${channelId}/thread/${threadId}/note`,
      data: { note: { text, timestamp } },
    });
  }

  static syncThread(payload) {
    const {
      channelId, threadId,
    } = payload;
    return builder({
      method: 'get',
      url: `/channel/${channelId}/thread/${threadId}/moreItems`,
    });
  }

  static syncItemsWithLastPermanent(payload) {
    const {
      channelId, threadId, itemId, count,
    } = payload;
    return builder({
      method: 'get',
      url: `/channel/${channelId}/thread/${threadId}/moreItemsFromFor/${itemId}/${count}`,
    });
  }
}
