<template>
  <div
    id="UbuCRMViewSettings"
    class="list-filters"
  >
    <div>
      <h1 class="ubu-header1">Table preferences</h1>
      <p
        v-if="!customCRMColumns.length"
        class="ubu-info viewSettings-text"
      >
        You don't have any custom views...
      </p>
      <b-field
        v-else
        grouped
        position="is-centered"
        group-multiline
      >
        <b-button
          v-for="(item, index) in customCRMColumns"
          :key="index"
          :label="item.name"
          :active="item.name === selectedCustom"
          class="is-transparent is-rounded is-dashed control"
          @click="$emit('customColumns', item.name)"
        />
        <b-button
          class="is-transparent is-rounded is-dashed control"
          label="show all"
          :active="!selectedCustom"
          @click="$emit('customColumns', 'default')"
        />
      </b-field>

      <b-button
        class="is-rounded is-light"
        @click="$emit('openCreateView', $event)"
      >
        <UbuIcon
          name="add"
          filled
        />
        Create view
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UbuCRMViewSettings',
  props: {
    customCRMColumns: {
      type: Array,
      default: () => [],
    },
    selectedCustom: {
      type: String,
      default: null,
    },
  },
};
</script>
