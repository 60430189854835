import { responseMapper, arrayMapper } from '../../$utils/prototypes';
import { updateDatasIfExists } from '../$utils/dataMapper';
import internals from './internals';
// import GlobalProvider from '../providers/global.provider';

import { tryParseJson } from '../../$utils/formate';

function setCustom({ commit }, customTagList) {
  commit('SET_CUSTOM_TAGS', customTagList);

  const {
    contactGroup,
    contactsHasContactGroup,
    threadGroup,
    templates,
    threadsHasThreadGroup,
    // threadsHasContactGroup,
    assignments,
    threadsHasUbuUser,
    channelsHasContactGroup,
    channelsHasThreadGroup,
    channelsHasTemplates,
  } = Object.entries(customTagList).reduce((acc, [customTagId, customsDatas]) => {
    const {
      type,
      title,
      subjects,
      channelId,
      ...restCustom
    } = customsDatas;
    if (type === 'label') {
      acc.contactGroup.push({
        ...restCustom,
        contactGroupId: customTagId,
        customTagId,
        type,
        title,
      });
      acc.channelsHasContactGroup.push({
        contactGroupId: customTagId,
        channelId,
      });
      subjects.filter((d) => d).forEach((contactId) => {
        acc.contactsHasContactGroup.push({
          contactId,
          contactGroupId: customTagId,
        });
      });
    } else if (type === 'tag') {
      acc.threadGroup.push({
        ...restCustom,
        threadGroupId: customTagId,
        customTagId,
        type,
        title,
      });

      acc.channelsHasThreadGroup.push({
        threadGroupId: customTagId,
        channelId,
      });
      subjects.filter((d) => d).forEach((threadId) => {
        acc.threadsHasThreadGroup.push({
          threadId,
          threadGroupId: customTagId,
        });
      });
    } else if (type === 'template') {
      acc.templates.push({
        ...restCustom,
        templateId: customTagId,
        customTagId,
        type,
        title,
      });

      acc.channelsHasTemplates.push({
        templateId: customTagId,
        channelId,
      });
    } else if (type === 'assignment') {
      acc.assignments.push({
        customTagId,
        ubuUserId: title,
        ...restCustom,
      });
      subjects.filter((d) => d).forEach((threadId) => {
        acc.threadsHasUbuUser.push({
          ubuUserId: title,
          threadId,
        });
      });
    }
    return acc;
  }, {
    contactGroup: [],
    contactsHasContactGroup: [],
    threadGroup: [],
    templates: [],
    threadsHasThreadGroup: [],
    threadsHasContactGroup: [],
    assignments: [],
    threadsHasUbuUser: [],
    channelsHasThreadGroup: [],
    channelsHasContactGroup: [],
    channelsHasTemplates: [],
  });
  commit('Template/SET_TEMPLATES', arrayMapper(templates, 'templateId'), { root: true });

  commit('ContactGroup/SET_CONTACT_GROUP', arrayMapper(contactGroup, 'contactGroupId'), { root: true });
  // commit('Contact/SET_CONTACTS_HAS_CONTACT_GROUP', contactsHasContactGroup, { root: true });
  const contactsHasContactGroupBis = contactsHasContactGroup
    .reduce((acc, row) => {
      const { contactId, contactGroupId } = row;
      if (!contactGroupId || contactGroupId === 'null') return acc;
      if (!acc[contactId]) {
        acc[contactId] = [contactGroupId];
        return acc;
      }
      acc[contactId].push(contactGroupId);
      return acc;
    }, {});
  commit('Contact/SET_CONTACT_HAS_CONTACT_GROUPS_BIS', contactsHasContactGroupBis, { root: true });

  const contactsGroupsHasContactsBis = contactsHasContactGroup
    .reduce((acc, row) => {
      const { contactId, contactGroupId } = row;
      if (!contactGroupId || contactGroupId === 'null') return acc;
      if (!acc[contactGroupId]) {
        acc[contactGroupId] = [contactId];
        return acc;
      }
      acc[contactGroupId].push(contactId);
      return acc;
    }, {});
  commit('ContactGroup/SET_CONTACT_GROUPS_HAS_CONTACTS_BIS', contactsGroupsHasContactsBis, { root: true });

  commit('ThreadGroup/SET_THREAD_GROUP', arrayMapper(threadGroup, 'threadGroupId'), { root: true });
  // commit('Thread/SET_THREADS_HAS_THREAD_GROUP', threadsHasThreadGroup, { root: true });

  const threadsHasThreadGroupBis = threadsHasThreadGroup
    .reduce((acc, row) => {
      const { threadId, threadGroupId } = row;
      if (!threadGroupId || threadGroupId === 'null') return acc;
      if (!acc[threadId]) {
        acc[threadId] = [threadGroupId];
        return acc;
      }
      acc[threadId].push(threadGroupId);
      return acc;
    }, {});
  commit('Thread/SET_THREADS_HAS_THREAD_GROUPS_BIS', threadsHasThreadGroupBis, { root: true });

  const threadsGroupsHasThreadsBis = threadsHasThreadGroup
    .reduce((acc, row) => {
      const { threadId, threadGroupId } = row;
      if (!threadGroupId || threadGroupId === 'null') return acc;
      if (!acc[threadGroupId]) {
        acc[threadGroupId] = [threadId];
        return acc;
      }
      acc[threadGroupId].push(threadId);
      return acc;
    }, {});
  commit('ThreadGroup/SET_THREAD_GROUPS_HAS_THREADS_BIS', threadsGroupsHasThreadsBis, { root: true });

  // const threadsHasUbuUser = threadsHasUbuUser
  //   .reduce((acc, row) => {
  //     const { threadId, ubuUserId } = row;
  //     if (!ubuUserId || ubuUserId === 'null') return acc;
  //     if (!acc[threadId]) {
  //       acc[threadId] = [ubuUserId];
  //       return acc;
  //     }
  //     acc[threadId].push(ubuUserId);
  //     return acc;
  //   }, {});
  // commit('Thread/SET_THREADS_HAS_THREAD_GROUPS_BIS', threadsHasThreadGroupBis, { root: true });

  const _threadsHasUbuUser = threadsHasUbuUser
    .reduce((acc, row) => {
      const { threadId, ubuUserId } = row;
      if (!ubuUserId || ubuUserId === 'null') return acc;
      if (!acc[threadId]) {
        acc[threadId] = [ubuUserId];
        return acc;
      }
      acc[threadId].push(ubuUserId);
      return acc;
    }, {});
  commit('Thread/SET_THREADS_HAS_UBUUSERS', _threadsHasUbuUser, { root: true });

  const ubuUserHasThreads = threadsHasUbuUser
    .reduce((acc, row) => {
      const { threadId, ubuUserId } = row;
      if (!ubuUserId || ubuUserId === 'null') return acc;
      if (!acc[ubuUserId]) {
        acc[ubuUserId] = [threadId];
        return acc;
      }
      acc[ubuUserId].push(threadId);
      return acc;
    }, {});
  commit('UbuUser/SET_UBUUSERS_HAS_THREADS', ubuUserHasThreads, { root: true });
  commit('UbuUser/SET_ASSIGNMENTS', assignments, { root: true });
  // commit('Thread/SET_THREADS_HAS_THREAD_GROUP', threadsHasContactGroup, { root: true });

  const channelsHasCustomTags = Object.values(customTagList)
    .map(({ customTagId, channelId }) => ({ customTagId, channelId }));
  commit('Channel/SET_CHANNELS_HAS_CUSTOM_TAGS', channelsHasCustomTags, { root: true });
  commit('Channel/SET_CHANNELS_HAS_CONTACT_GROUPS', channelsHasContactGroup, { root: true });
  commit('Channel/SET_CHANNELS_HAS_THREAD_GROUPS', channelsHasThreadGroup, { root: true });
  commit('Channel/SET_CHANNELS_HAS_TEMPLATES', channelsHasTemplates, { root: true });
}

/* eslint-disable max-len */
export default {
  namespaced: true,

  state: {
    customTags: {},
  },

  getters: {
    getterCustomTags: (state) => state.customTags,
  },

  actions: {
    fetchCustomsByChannel({ commit }) {
      return Promise.resolve()
        .then(() => Promise.all(internals.channelsIds.map((channelId) => internals.fetchCustomsByChannel(channelId))))
        .then((fetchCustomsByChannel) => {
          // console.log('🚀 ~ file: CustomTag.store.js ~ line 22 ~ .then ~ fetchCustomsByChannel', fetchCustomsByChannel);
          const customTagsByChannelsList = fetchCustomsByChannel
            .map((row) => (row.error
              ? row
              : row.response
                .filter(({ _id }) => _id)
                .map((customTag) => ({
                  ...customTag,
                  channelId: row.channelId,
                  customTagId: customTag._id,
                  data: customTag.data ? tryParseJson(customTag.data) : customTag.data,
                }))));
          // //console.log('🚀 ~ file: CustomTag.store.js ~ line 33 ~ .then ~ customTagsByChannelsList', customTagsByChannelsList);

          const customTagList = responseMapper(customTagsByChannelsList, 'customTagId');
          // console.log('🚀 ~ file: CustomTag.store.js ~ line 44 ~ .then ~ customTagList', customTagList);
          setCustom({ commit }, customTagList);
        });
    },
    SOCKET_setCustom({ commit }, payload) {
      setCustom({ commit }, { [payload.customId]: { ...payload } });
    },
    SOCKET_customSubject({ getters, commit }, payload) {
      const {
        id, action, subjects, subject,
      } = payload;
      const custom = getters.getterCustomTags[id];
      // console.log('🚀 ~ file: CustomTag.store.js ~ line 195 ~ SOCKET_customSubject ~ custom', custom);
      if (custom.type === 'tag' || custom.type === 'label') {
        console.log('Socket removing group', custom, payload);
        const _subs = subject ? [subject] : subjects;
        if (action === 'push') {
          custom.subjects = [...custom.subjects, _subs];
          setCustom({ commit }, { [id]: { ...custom } });
        } else {
          if (custom.type === 'tag') {
            const rel = _subs.map((s) => ({
              threadGroupId: id,
              threadId: s,
            }));
            commit('Thread/UNSET_THREADS_HAS_THREAD_GROUP', rel, { root: true });
          }
          if (custom.type === 'label') {
            const rel = _subs.map((s) => ({
              contactGroupId: id,
              contactId: String(s),
            }));
            commit('Contact/UNSET_CONTACTS_HAS_CONTACT_GROUP', rel, { root: true });
          }
        }
      }
      return undefined;
    },
  },
  mutations: {
    SET_CUSTOM_TAGS(state, newDatas) { state.customTags = updateDatasIfExists({ newDatas, actualDatas: state.customTags, key: 'customTagId' }); },
    REMOVE_CUSTOM_TAGS(state, customTagId) { delete state.customTags[customTagId]; },
    RESET_STORES(state) {
      state.customTags = {};
    },
  },
};
