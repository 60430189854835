<template>
  <div
    :class="['UbuCommonAnalyticsCard', {isSmall: isSmall}]"
    :style="`width: calc(${size}% - ${isSmall ? '8px' : '24px'})`"
  >
    <div
      v-if="loading"
      class="UbuCommonAnalyticsCardWrapper"
    >
      <b-skeleton
        circle
        width="34px"
        height="34px"
        position="is-centered"
      />
      <b-skeleton
        width="25%"
        height="25px"
        position="is-centered"
      />
      <b-skeleton
        width="60%"
        height="20px"
        position="is-centered"
      />
      <b-skeleton
        width="25%"
        height="20px"
        position="is-centered"
      />
    </div>
    <div
      v-else
      class="UbuCommonAnalyticsCardWrapper"
    >
      <div :class="`iconWrapper color${color}`">
        <i :class="`ubu-icons ubu-icons-${icon}-${iconFilled ? 'filled' : 'outlined'}`" />
      </div>
      <b-tooltip
        :label="`${ value.toLocaleString('fr') }${ isPercent ? '%' : '' }${ isDollar ? '$' : '' }`"
        position="is-bottom"
      >
        <span
          class="value"
        >{{ formatedValue }}{{ isPercent ? '%' : '' }}{{ isDollar ? '$' : '' }}</span>
      </b-tooltip>
      <span class="analyticsCardTitle">{{ title }}</span>
      <b-tooltip
        v-if="growthPercent"
        multilined
        :label="`Last period value: ${ lastPeriodvalue.toLocaleString('fr') }
        ${ isPercent ? '%' : '' }${ isDollar ? '$' : '' }`"
        position="is-bottom"
      >
        <div
          :class="['evolution', 'ubu-text2', {isNegative: growthPercent < 0}]"
        >
          <i :class="`ubu-icons ubu-icons-stat-arrow-${growthPercent > 0 ? 'up' : 'down'}`" />
          <span>{{ formatedGrowthPercent }}%</span>
        </div>
      </b-tooltip>
    </div>
  </div>
</template>

<script>
import { shortenNumber } from '../../$utils/formate';

export default {
  name: 'UbuCommonAnalyticsCard',
  props: {
    value: {
      type: Number,
      default: 0,
    },
    lastPeriodvalue: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    color: {
      type: Number,
      default: 1,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Number,
      default: 50,
    },
    isPercent: {
      type: Boolean,
      default: false,
    },
    isDollar: {
      type: Boolean,
      default: false,
    },
    growthPercent: {
      type: Number,
      default: null,
    },
    iconFilled: {
      type: Boolean,
      default: false,
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    isPositive() {
      return true;
    },
    formatedValue() {
      return shortenNumber(this.value);
    },
    formatedGrowthPercent() {
      return Math.round(this.growthPercent * 100) / 100;
    },
  },
  methods: {
  },
};
</script>
