import Vue from 'vue';
import moment from 'moment';
import App from './App';
// import './registerServiceWorker';
import router from './router';
import store from './store';
import { lib as addonsLib, smarts as addonsSmarts } from './addons';

import './extensions';

Vue.config.productionTip = false;
Vue.prototype.$moment = moment;

Object.keys(addonsLib).forEach((componentKey) => {
  Vue.use(addonsLib[componentKey]);
});
Object.keys(addonsSmarts).forEach((componentKey) => {
  Vue.use(addonsSmarts[componentKey]);
});

window.app = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
