import AuthenticateProvider from '../providers/auth.provider';
/* eslint-disable max-len */
export default {
  namespaced: true,

  state: {
    authenticate: null,
    inviteToken: null,
    resetPasswordToken: null,
  },

  getters: {
    getterCurrentUbuUserId: (state) => state.authenticate && state.authenticate.ubuUserId,
    getterAuthenticate: (state) => state.authenticate,
    getterInviteToken: (state) => state.inviteToken,
    getterResetPasswordToken: (state) => state.resetPasswordToken,
    getterCurrentUbuUser: (state, getters, _rootState, rootGetters) => {
      const ubuUsers = rootGetters['UbuUser/getterUbuUsers'];
      return state.authenticate && ubuUsers ? ubuUsers[getters.getterCurrentUbuUserId] : null;
    },
  },

  actions: {
    fetchAuthenticate({ commit }) {
      return Promise.resolve()
        .then(() => AuthenticateProvider.getAuthenticate())
        .then(({ response, error }) => {
          console.log('🚀 ~ file: Authenticate.store.js ~ line 36 ~ .then ~ response', response);
          if (error) throw new Error('Unable to authenticate');

          commit('SET_AUTHENTICATE', response);
          return true;
        })
        .catch((error) => {
          console.warn('🚀 ~ file: Authenticate.store.js ~ line 26 ~ fetchAuthenticate ~ error', error);
          return false;
        });
    },
    login({ commit }, { email, password }) {
      return Promise.resolve()
        .then(() => AuthenticateProvider.postAuthenticate({ email, password }))
        .then(({ response, error }) => {
          console.warn('🚀 ~ file: Authenticate.store.js ~ line 51 ~ .then ~ error', error);
          if (error) throw new Error('Unable to login');
          console.log('🚀 ~ file: Authenticate.store.js ~ line 22 ~ .then ~ response', response);
          commit('SET_AUTHENTICATE', response);

          commit('Sequence/RESET_STORES', null, { root: true });
          commit('Activity/RESET_STORES', null, { root: true });
          commit('Campaign/RESET_STORES', null, { root: true });
          commit('Contact/RESET_STORES', null, { root: true });
          commit('ContactGroup/RESET_STORES', null, { root: true });
          commit('CustomTag/RESET_STORES', null, { root: true });
          commit('Gossip/RESET_STORES', null, { root: true });
          commit('Media/RESET_STORES', null, { root: true });
          commit('Mention/RESET_STORES', null, { root: true });
          commit('Message/RESET_STORES', null, { root: true });
          commit('Note/RESET_STORES', null, { root: true });
          commit('TagPost/RESET_STORES', null, { root: true });
          commit('Template/RESET_STORES', null, { root: true });
          commit('Thread/RESET_STORES', null, { root: true });
          commit('ThreadGroup/RESET_STORES', null, { root: true });
          commit('UbuUser/RESET_STORES', null, { root: true });
          commit('Channel/RESET_STORES', null, { root: true });
        });
    },
    sendEmailResetPassword(_, email) {
      console.log('🚀 ~ file: Authenticate.store.js ~ line 60 ~ resetPassword ~ email', email);
      return Promise.resolve()
        .then(() => AuthenticateProvider.sendEmailResetPassword({ email }))
        .then(({ response, error }) => {
          console.log('🚀 ~ file: Authenticate.store.js ~ line 63 ~ .then ~ response', response);
          if (error) throw new Error("User doesn't exist");
          return true;
        });
    },
    resetPassword({ getters }, password) {
      const token = getters.getterResetPasswordToken;

      return Promise.resolve()
        .then(() => AuthenticateProvider.confirmEmail({ token }))
        .then(({ error }) => {
          if (error) {
            console.log('🚀 ~ file: Authenticate.store.js ~ line 74 ~ .then ~ error', error);
            throw new Error('Unable to reset password');
          }
        })
        .then(() => AuthenticateProvider.resetPassword({ token: password }))
        .then(({ error }) => {
          if (error) {
            console.log('🚀 ~ file: Authenticate.store.js ~ line 80 ~ .then ~ error', error);
            throw new Error('Unable to reset password');
          }
          return true;
        });
    },
    editPassword(_, password) {
      return Promise.resolve()
        .then(() => AuthenticateProvider.resetPassword({ token: password }))
        .then(({ response, error }) => {
          console.log('🚀 ~ file: Authenticate.store.js ~ line 101 ~ .then ~ response', response);
          if (error) {
            console.log('🚀 ~ file: Authenticate.store.js ~ line 80 ~ .then ~ error', error);
            throw new Error('Unable to reset password');
          }
          return true;
        });
    },
    signup(_, payload) {
      // console.log('🚀 ~ file: Authenticate.store.js ~ line 60 ~ signup ~ payload', payload);

      return Promise.resolve()
        .then(() => AuthenticateProvider.signup(payload))
        .then(({ response, error }) => {
          console.log('🚀 ~ file: Authenticate.store.js ~ line 104 ~ .then ~ error', error);
          console.log('🚀 ~ file: Authenticate.store.js ~ line 20 ~ .then ~ response', response);
          if (error) throw new Error('Email already exists');
          // return true;
        });
      // .catch((error) => {
      //   console.log('🚀 ~ file: Authenticate.store.js ~ line 25 ~ login ~ error', error);
      //   return error;
      // });
    },
    logout({ commit }) {
      return Promise.resolve()
        .then(() => AuthenticateProvider.logout())
        .then((response) => {
          console.log('🚀 ~ file: Authenticate.store.js ~ line 50 ~ .then ~ response', response);

          commit('UNSET_AUTHENTICATE');
        });
    },
    canUseToken({ commit, getters }) {
      const inviteToken = getters.getterInviteToken;
      if (!inviteToken) return undefined;

      return Promise.resolve()
        .then(() => AuthenticateProvider.canUseToken({ inviteToken }))
        .then(({ response, error }) => {
          console.log('🚀 ~ file: Authenticate.store.js ~ line 141 ~ .then ~ error', error);
          console.log('🚀 ~ file: Authenticate.store.js ~ line 141 ~ .then ~ response', response);
          if (error) {
            commit('SET_INVITE_TOKEN', null);
            return { error };
          }
          return { response };
        });
    },
    sendToken({ getters }) {
      const inviteToken = getters.getterInviteToken;
      if (!inviteToken) return undefined;

      return Promise.resolve()
        .then(() => AuthenticateProvider.sendToken({ inviteToken }))
        .then(({ error }) => {
          if (error && error.message === 'user already in channel') return { error: { ...error, message: 'You were invited to a channel you already have' } };
          if (error) throw new Error('Token expired, please contact your admin');
          return this.dispatch('Authenticate/fetchAuthenticate');
        });
    },
    UP_inviteToken({ commit }, inviteToken) {
      console.log('🚀 ~ file: Authenticate.store.js ~ line 68 ~ UP_inviteToken ~ inviteToken', inviteToken);
      if (inviteToken) commit('SET_INVITE_TOKEN', inviteToken);
    },
    UP_resetPasswordToken({ commit }, token) {
      commit('SET_RESET_PASSWORD_TOKEN', token);
    },
  },
  mutations: {
    SET_AUTHENTICATE(state, authenticate) { state.authenticate = authenticate; },
    SET_INVITE_TOKEN(state, inviteToken) { state.inviteToken = inviteToken; },
    SET_RESET_PASSWORD_TOKEN(state, token) { state.resetPasswordToken = token; },
    UNSET_AUTHENTICATE(state) { state.authenticate = null; },
  },
};
