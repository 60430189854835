<template>
  <div
    id="UbuInfluencePipelineModalMessageScheduled"
    class="workspace-modal"
  >
    <div class="modal-header">
      <p class="modal-header-title ubu-title">Message</p>

      <UbuIcon
        name="cross"
        clickable
        @click="$emit('close', $event)"
      />
    </div>
    <div class="modal-content">
      <div class="modal-content-text">
        {{ message }}
      </div>
    </div>
    <div class="modal-footer">
      <b-button
        label="Close"
        class="is-light is-rounded"
        @click="$emit('close', $event)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'UbuInfluencePipelineModalMessageScheduled',
  props: {
    message: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss">
#UbuInfluencePipelineModalMessageScheduled {
  & .modal-content-text {
    max-height: 450px;
    overflow-y: auto;
    white-space: pre-line;
  }
}
</style>
