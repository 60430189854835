import { responseMapper } from '../../$utils/prototypes';
import internals from './internals';

/* eslint-disable max-len */
export default {
  namespaced: true,

  state: {
    tagsPost: {},
  },

  getters: {
    gettertagsPosts: (state) => state.tagsPost,
  },

  actions: {
    fetchTagsPostByChannel({ commit }) {
      return Promise.resolve()
        .then(() => Promise.all(internals.channelsIds.map((channelId) => internals.fetchTagsByChannel(channelId))))
        .then((fetchTagsByChannel) => {
          const tagsByChannelsList = fetchTagsByChannel
            .map((contactByChannel) => (
              contactByChannel.error
                ? contactByChannel
                : contactByChannel.response
                  .map((tagsPost) => ({
                    ...tagsPost, channelId: contactByChannel.channelId, tagPostId: tagsPost.pk, contactId: tagsPost.user.pk,
                  }))
            ));
          // //console.log('🚀 ~ file: TagPost.store.js ~ line 30 ~ .then ~ tagsByChannelsList', tagsByChannelsList);

          const tagsPostList = responseMapper(tagsByChannelsList, 'tagPostId');
          // console.log('🚀 ~ file: TagPost.store.js ~ line 33 ~ .then ~ tagsPostList', tagsPostList);
          commit('SET_TAGS_POST', tagsPostList);

          const contactsHasTags = Object.values(tagsPostList).map(({ tagPostId, contactId }) => ({ tagPostId, contactId }));
          // console.log('🚀 ~ file: TagPost.store.js ~ line 38 ~ .then ~ contactsHasTags', contactsHasTags);
          commit('Contact/SET_CONTACTS_HAS_TAGS_POST', contactsHasTags, { root: true });
        });
    },
  },
  mutations: {
    SET_TAGS_POST(state, tagsPost) { state.tagsPost = tagsPost; },
    RESET_STORES(state) {
      state.tagsPost = {};
    },
  },
};
