var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(function (e) { return _vm.open = false; }),expression:"e => open = false"}],class:['UbuSimpleDropdown_wrapper', {'UbuSimpleDropdown_label': _vm.label}]},[_c('label',[(_vm.label)?_c('span',[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_vm._v("   "),(_vm.help)?_c('b-tooltip',{attrs:{"multilined":"","label":_vm.help,"position":"is-bottom"}},[_c('UbuIcon',{attrs:{"name":"info"}})],1):_vm._e()],1),_c('UbuButton',_vm._b({staticClass:"UbuSimpleDropdown_trigger",attrs:{"active":_vm.open},on:{"click":function($event){_vm.open = !_vm.open}}},'UbuButton',_vm.$attrs,false),[_c('div',{staticClass:"UbuSimpleDropdown_triggerText ellipsis"},[_c('span',{staticClass:"ellipsis"},[_vm._v(" "+_vm._s(_vm.selectedValue && _vm.selectedValue.label || _vm.defaultLabel)+" ")]),_c('UbuIcon',{class:[
          {'UbuSimpleDropdown_exp': _vm.open},
          'UbuSimpleDropdown_expOpen'
        ],attrs:{"name":"expand"}})],1),_c('div',{staticClass:"UbuSimpleDropdown_options",attrs:{"slot":"dropdown"},slot:"dropdown"},[(_vm.clearable && _vm.value)?_c('div',{class:[
          'ellipsis',
          'UbuSimpleDropdown_option' ],staticStyle:{"font-weight":"300"},on:{"click":function () { _vm.$emit('input', null); _vm.open = false }}},[_c('UbuIcon',{attrs:{"name":"cross"}}),_vm._v(" Clear "+_vm._s(_vm.value)+" ")],1):_vm._e(),(!_vm.options.length && !_vm.value)?_c('div',{staticClass:"UbuSimpleDropdown_option UbuSimpleDropdown_unselectable"},[_vm._v(" "+_vm._s(_vm.noValuesLabel)+" ")]):_vm._e(),_vm._l((_vm.options),function(opt){return _c('div',{key:opt.key,class:[
          'ellipsis',
          'UbuSimpleDropdown_option',
          { 'UbuSimpleDropdown_optionSelected': opt === _vm.selectedValue }
        ],on:{"click":function () { _vm.$emit('input', opt.key); _vm.open = false }}},[_vm._v(" "+_vm._s(opt.label)+" ")])})],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }