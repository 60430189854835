const itemHasMedia = [
  'reel_share',
  'media_share',
  'story_share',
  'felix_share',
  'voice_media',
  'product_share',
  'media',
  'clip',
  'animated_media',
];
const itemHasDetails = [
  // 'reel_share',
  'media_share',
  'story_share',
  'felix_share',
  // 'voice_media',
  'product_share',
  'profile',
  // 'media',
  'clip',
];

export default {
  computed: {
    threadItems() {
      if (this.isPanelComment) {
        return this.selectedThread.messages;
      }
      const { messages = [], notes = [] } = this.selectedThread;
      // console.log('🚀 ~ file: TheThreadPanelMixin.js ~ line 28 ~ threadItems ~ messages', messages);

      // const mappedMessages = messages.map((item) => {
      //   const {
      //     item: serializedItem,
      //     contactId,
      //     receiver,
      //     user_id: senderId,
      //     ...restItem
      //   } = item;
      //   // Prepare formated item
      //   const newMedia = this.foundMediaForItemType(restItem);
      //   const newDetails = this.foundDetailsForItemType(restItem);
      //   const uniType = this.foundItemType(restItem, newMedia);

      //   // Remove useless and verbose objects
      //   /* eslint-disable camelcase */
      //   const {
      //     reel_share,
      //     media_share,
      //     story_share,
      //     felix_share,
      //     product_share,
      //     voice_media,
      //     media,
      //     clip,
      //     animated_media,
      //     profile,
      //     ...cleanItem
      //   } = restItem;
      //   /* eslint-enable camelcase */

      //   return {
      //     newMedia,
      //     newDetails,
      //     uniType,
      //     contactId,
      //     receiver,
      //     senderId,
      //     userId: senderId,
      //     isMe: contactId !== senderId,
      //     ...cleanItem,
      //   };
      // });

      return [...messages, ...notes];
    },
  },
  methods: {
    foundItemType(item, newMedia) {
      const {
        item_type: type,
        reel_share: reelShare = {},
        item_subtype: itemSubtype = '',
      } = item;

      /* eslint-disable no-multi-spaces */
      if (type === 'text')                                                                        return 'isText';
      if (type === 'error')                                                                       return 'isError';
      if (type === 'reel_share' && (reelShare.type === 'mention'  || itemSubtype === 'mention'))  return 'isStoryMention';
      if (type === 'reel_share' && (reelShare.type === 'reply'    || itemSubtype === 'reply'))    return 'isStoryReply';
      if (type === 'reel_share' && (reelShare.type === 'reaction' || itemSubtype === 'reaction')) return 'isStoryReact';
      if (type === 'media' && !newMedia.hasVideo)                                                 return 'isMediaImage';
      if (type === 'media' && newMedia.hasVideo)                                                  return 'isMediaVideo';
      if (type === 'story_share')                                                                 return 'isStoryShare';
      if (type === 'media_share')                                                                 return 'isPostShare';
      if (type === 'product_share')                                                               return 'isProductShare';
      if (type === 'felix_share')                                                                 return 'isFelixShare';
      if (type === 'voice_media')                                                                 return 'isVoiceMedia';
      if (type === 'clip')                                                                        return 'isClip';
      if (type === 'animated_media')                                                              return 'isAnimatedMedia';
      if (type === 'profile')                                                                     return 'isProfileShare';
      if (type === 'link')                                                                        return 'isLink';
      if (type === 'action_log')                                                                  return 'isActionLog';
      if (type === 'comment')                                                                     return 'isComment';
      return 'UNKNOWN';
      /* eslint-enable no-multi-spaces */
    },
    foundDetailsForItemType(item) {
      const { item_type: type } = item;

      let details = null;

      if (itemHasDetails.includes(type)) {
        /* eslint-disable no-multi-spaces */
        if (type === 'product_share') { details = this.destructureDetail(item.product_share.product); }
        if (type === 'media_share')   { details = this.mergeBoth(this.destructureDetail(item.media_share.caption), this.destructureDetail(item.media_share.user)); }
        if (type === 'clip')          { details = this.mergeBoth(this.destructureDetail(item.clip.clip.caption), this.destructureDetail(item.clip.clip.caption.user)); }
        if (type === 'story_share')   { details = this.destructureDetail(item.story_share.media ? item.story_share.media.user : item.story_share.media); }
        if (type === 'felix_share')   { details = this.destructureDetail(item.felix_share.video.user); }
        if (type === 'profile')       { details = this.destructureDetail(item.profile); }
        /* eslint-enable no-multi-spaces */
      }
      return details || null;
    },
    mergeBoth(detailA, detailB) {
      return {
        description: detailA.description || detailB.description,
        name: detailA.name || detailB.name,
        link: detailA.link || detailB.link,
        fullName: detailA.fullName || detailB.fullName,
        username: detailA.username || detailB.username,
        profilePicUrl: detailA.profilePicUrl || detailB.profilePicUrl,
        text: detailA.text || detailB.text,
      };
    },
    destructureDetail(dataToDestructure) {
      if (!dataToDestructure) {
        return {
          description: '',
          name: '',
          link: '',
          fullName: '',
          username: '',
          profilePicUrl: '',
          text: '',
        };
      }

      const {
        description = '',
        name = '',
        external_url: link = '',
        full_name: fullName = '',
        username = '',
        profile_pic_url: profilePicUrl = '',
        text = '',
      } = dataToDestructure;

      return {
        description,
        name,
        link,
        fullName,
        username,
        profilePicUrl,
        text,
      };
    },
    foundMediaForItemType(item) {
      const { item_type: type, has_bucket_media: hasBucket } = item;

      let media = null;

      if (itemHasMedia.includes(type)) {
        /* eslint-disable no-multi-spaces */
        if (type === 'reel_share')     { media = this.destructureMedia(item.reel_share ? item.reel_share.media : null, hasBucket); }
        if (type === 'story_share')    { media = this.destructureMedia(item.story_share.media, hasBucket); }
        if (type === 'media_share')    { media = this.destructureMedia(item.media_share, hasBucket); }
        if (type === 'clip')           { media = this.destructureMedia(item.clip.clip, hasBucket); }
        if (type === 'media')          { media = this.destructureMedia(item.media, hasBucket); }
        if (type === 'felix_share')    { media = this.destructureMedia(item.felix_share.video, hasBucket); }
        if (type === 'product_share')  { media = this.destructureMedia(item.product_share.product.main_image, hasBucket); }
        if (type === 'animated_media') { media = this.destructureMedia(item.animated_media.images.fixed_height, hasBucket); }
        if (type === 'voice_media')    { media = this.destructureMedia(item.voice_media.media.audio, hasBucket); }
        /* eslint-enable no-multi-spaces */
      }
      return media || null;
    },
    destructureMedia(mediaToDestructure, hasBucket) {
      if (!mediaToDestructure) {
        return {
          id: '',
          audioUrl: '',
          imageUrl: '',
          videoUrl: '',
          carouselList: [],
          hasBucket: false,
          hasVideo: false,
        };
      }

      const {
        id = '',
        audio_src: audioUrl = '',
        image_versions2: imageList = [],
        video_versions: videoList = [],
        carousel_media: carouselList = [],
        url = '',
      } = mediaToDestructure;

      let formatedCarouselList = [];

      if (carouselList) {
        formatedCarouselList = carouselList.map((media) => {
          const {
            id: mediaId,
            image_versions2: mediaImageList = [],
            video_versions: mediaVideoList = [],
          } = media;
          return {
            id: mediaId,
            imageUrl: this.getImageFromList(mediaImageList),
            videoUrl: this.getVideoFromList(mediaVideoList),
            hasBucket: hasBucket || false,
            hasVideo: !!(mediaVideoList && mediaVideoList.length > 0),
          };
        });
      }

      return {
        id,
        audioUrl,
        imageUrl: url || this.getImageFromList(imageList),
        videoUrl: this.getVideoFromList(videoList),
        carouselList: formatedCarouselList || [],
        hasBucket: hasBucket || false,
        hasVideo: !!(videoList && videoList.length > 0),
      };
    },
    getImageFromList(imageList) {
      const { candidates } = imageList;

      if (candidates) {
        if (candidates[0]) {
          return candidates[0].url;
        }
        return '';
      }

      return '';
    },
    getVideoFromList(videoList) {
      if (videoList && videoList.length && videoList[0]) {
        return videoList[0].url;
      }

      return '';
    },
  },
};
