import {
  updateDatasIfExists,
  updateObjectRelationIfExists,
  removeKeyToMap,
  addNewKeysToMap,
} from '../$utils/dataMapper';
/* eslint-disable max-len */
export default {
  namespaced: true,

  state: {
    threadGroups: {},
    threadsGroupsHasThreadsBis: {},
  },

  getters: {
    getterCurrentThreadGroups: (state) => Object.values(state.threadGroups),
    getterThreadGroups: (state) => state.threadGroups,
    getterThreadGroupsHasThreadsBis: (state) => state.threadsGroupsHasThreadsBis,
  },

  actions: {
  },

  mutations: {
    SET_THREAD_GROUP(state, newDatas) { state.threadGroups = updateDatasIfExists({ newDatas, actualDatas: state.threadGroups, key: 'threadGroupId' }); },
    REMOVE_THREAD_GROUP(state, threadGroupId) { delete state.threadGroups[threadGroupId]; },

    SET_THREAD_GROUPS_HAS_THREADS_BIS(state, newDatas) { state.threadsGroupsHasThreadsBis = updateObjectRelationIfExists({ newDatas, actualDatas: state.threadsGroupsHasThreadsBis }); },
    ADD_THREAD_TO_THREAD_GROUP(state, { threadIds, threadGroupId }) { state.threadsGroupsHasThreadsBis = addNewKeysToMap({ map: state.threadsGroupsHasThreadsBis, key: threadGroupId, values: threadIds }); },
    REMOVE_THREAD_TO_THREAD_GROUP(state, { threadId, threadGroupId }) { state.threadsGroupsHasThreadsBis = removeKeyToMap({ map: state.threadsGroupsHasThreadsBis, key: threadGroupId, value: threadId }); },

    RESET_STORES(state) {
      state.threadGroups = {};
      state.threadsGroupsHasThreadsBis = {};
    },
  },
};
