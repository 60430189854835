<template>
  <b-button
    id="UbuEnhancer"
    :class="`multibar row-vcentered ${styleFor}`"
    disabled
  >
    <template v-if="isString">
      <UbuIcon
        size="16px"
        :name="config.iconName"
        filled
      />
      <span>
        {{ char }}
      </span>
    </template>
    <template v-else>
      <UbuIcon
        v-for="(_, index) in char"
        :key="index"
        size="16px"
        :name="config.iconName"
        filled
        :color="config.colorMapping[index]"
      />
    </template>
  </b-button>
</template>

<script>
export default {
  name: 'UbuEnhancer',
  props: {
    char: {
      type: [String, Array],
      required: true,
    },
    config: {
      type: Object,
      default: () => ({
        colorMapping: ['12', '3', '9', '11'],
        iconName: 'contact',
      }),
    },
  },
  computed: {
    isString() { return typeof this.char === 'string'; },
    styleFor() {
      return this.isString ? 'lonely' : 'multi';
    },
  },
};
</script>

<style lang="scss">
#UbuEnhancer {
  min-width: 140px;
  border-radius: 63px;
  padding: 8px 27.5px;
  height: 34px;
  border: none;
  &:disabled {
    cursor: default;
  }
  &.multi {
    background-color: #E9E9E9;
  }
  &.lonely {
    background-color: rgba(255, 98, 164, 0.2);
    color: #FF62A4;
  }
}
</style>
