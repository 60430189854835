<template>
  <UbuCommonMessageCard
    :is-me="isMe"
    :date="date"
    :emoji-list="emojiList"
    is-media
    @deleteMessage="$emit('deleteMessage')"
  >
    <template v-slot:image>
      <UbuCommonImage
        :url="imageUrl"
      />
    </template>

    <template v-slot:content>
      <a
        target="_blank"
        :href="linkUrl"
      >
        <div>
          <span>
            {{ title }}
          </span>
        </div>
        <div class="ubuTextElipsed">
          {{ text }}
        </div>
      </a>
    </template>
  </UbuCommonMessageCard>
</template>

<script>

export default {
  name: 'UbuCommonProductShare',
  props: {
    isMe: {
      type: Boolean,
      default: false,
    },
    date: {
      type: String,
      required: true,
    },
    emojiList: {
      type: Array,
      default: () => ([]),
    },
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    linkUrl: {
      type: String,
      required: true,
    },
    imageUrl: {
      type: String,
      required: true,
    },
  },
};
</script>
