<template>
  <div class="px-3 UbuThreadHeader_wrapper">
    <template v-if="thread && thread.contact">
      <UbuContactName
        class="UbuThreadHeader_contactName"
        :contact="thread.contact"
      />
      <div class="fgrow" />
      <UbuStatusDropdown
        v-if="thread.activity"
        :current-status="thread.activity.status"
        :status-list="statusList"
        @changeStatus="changeStatus({ ...thread.activity, toStatus: $event })"
      />
      <div class="UbuThreadHeader_selectors">
        <UbuSelector
          :key="`assignmentSelector-${thread.threadId}`"
          class="UbuThreadHeader_selector"
          reduced
          :values="assignationItems(thread.assignments)"
          :choices="assignationItems(ubuUsers)"
          :paths="{ id: 'ubuUserId', label: 'name' }"
          label-icon="assignation"
          search-placeholder="Search a user to assign"
          :max="1"
          :tooltip="{ label: `Assign to`, position: 'is-left' }"
          @select="assignThreadToUbuUser({ thread, ubuUser: $event })"
          @remove="removeThreadFromUbuUser({ thread, ubuUser: $event })"
        />
        <UbuSelector
          :key="`threadGroupSelector-${thread.threadId}`"
          class="UbuThreadHeader_selector"
          :values="threadGroupItems(thread.threadGroups)"
          :choices="threadGroupItems(threadGroups)"
          :paths="{ id: 'threadGroupId', label: 'title' }"
          label-icon="tag"
          :tooltip="{ label: `Add a tag`, position: 'is-left' }"
          search-placeholder="Create or search tags..."
          create-placeholder="Create new tag:"
          @select="assignThreadToThreadGroup({ thread, threadGroup: $event })"
          @remove="removeThreadFromThreadGroup({ thread, threadGroup: $event })"
          @create="createThreadGroupAndAssign({ thread, ...$event })"
        />
        <UbuSelector
          :key="`contactGroupSelector-${thread.threadId}`"
          class="UbuThreadHeader_selector"
          :values="contactGroupItems(thread.contactGroups)"
          :choices="contactGroupItems(contactGroups)"
          :paths="{ id: 'contactGroupId', label: 'title' }"
          label-icon="contact"
          :tooltip="{ label: `Add to contact`, position: 'is-left' }"
          search-placeholder="Create or search contacts..."
          create-placeholder="Create new contact:"
          @select="assignContactToContactGroup({ thread, contactGroup: $event })"
          @remove="removeContactFromContactGroup({ thread, contactGroup: $event })"
          @create="createContactGroupAndAssign({ thread, ...$event})"
        />
        <!-- Re-enable this later, when we can distinct campaign ended or not -->
        <!-- <UbuSelector
          v-bind="campaignSelector.props"
          :choices="campaignItems(campaigns)"
          :values="campaignItems(thread.campaigns)"
          @select="assignContactsToCampaign({ threads: [thread], campaign: $event })"
        /> -->
      </div>
      <TheThreadActionButton
        v-if="!!thread"
        :thread="thread"
        @translateThread="$emit('translateThread', $event)"
      />
    </template>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { snackSuccess, snackError } from '../$catalog/snackbar';
import AddToCampaignMixin from '@/addons/$mixins/AddToCampaign';

export default {
  name: 'TheThreadHeader',
  mixins: [AddToCampaignMixin],
  inject: {
    statusList: {
      // From TheInfluenceMessage
      default: [
        { name: 'InProgress', icon: 'send', color: '' },
        { name: 'Success', icon: 'success', color: '5' },
        { name: 'Failure', icon: 'failure', color: '2' },
      ],
    },
  },
  props: {
    thread: {
      type: Object,
      default: () => {},
    },
    statusList: {
      type: Array,
      default() { return this.statusList; },
    },
  },
  computed: {
    ...mapGetters({
      threadGroups: 'ThreadGroup/getterCurrentThreadGroups',
      contactGroups: 'ContactGroup/getterCurrentContactGroups',
      campaigns: 'Campaign/getterCurrentCampaigns',
      ubuUsers: 'UbuUser/getterCurrentUbuUsers',
    }),
    // ...mapGetters('customs', ['tagsFromThread']),
    // tags: sync('customs/tags'),
    // folders: sync('customs/folders'),
  },
  methods: {
    ...mapActions({
      _assignThreadToThreadGroup: 'TheThreadHeader/assignThreadToThreadGroup',
      _removeThreadFromThreadGroup: 'TheThreadHeader/removeThreadFromThreadGroup',
      _createThreadGroupAndAssign: 'TheThreadHeader/createThreadGroupAndAssign',
      _assignContactToContactGroup: 'TheThreadHeader/assignContactToContactGroup',
      _removeContactFromContactGroup: 'TheThreadHeader/removeContactFromContactGroup',
      _createContactGroupAndAssign: 'TheThreadHeader/createContactGroupAndAssign',
      _assignThreadToUbuUser: 'TheThreadHeader/assignThreadToUbuUser',
      _removeThreadFromUbuUser: 'TheThreadHeader/removeThreadFromUbuUser',
      setStatus: 'TheInfluence/setStatus',
    }),
    assignationItems(items) {
      return (items || []).map((ubuUser) => ({
        ...ubuUser,
        icon: {
          type: 'user',
          name: ubuUser.initials,
          color: ubuUser.color,
          size: 18,
        },
      }));
    },
    threadGroupItems(items) {
      return (items || []).map((threadGroup) => ({
        ...threadGroup,
        icon: 'tag',
      }));
    },
    contactGroupItems(items) {
      return (items || []).map((contactGroup) => ({
        ...contactGroup,
        icon: 'contact',
      }));
    },
    changeStatus(event) {
      // console.log('🚀 ~ file: TheThreadHeader.vue ~ line 104 ~ changeStatus ~ event', event);

      const { toStatus: status, ...activity } = event;
      const thisActivity = {
        ...activity,
        status,
      };
      this.setStatus(thisActivity);
    },
    assignThreadToThreadGroup($event) {
      this._assignThreadToThreadGroup($event)
        .then(({ title, threadName, error }) => {
          if (error) {
            this.$buefy.snackbar.open({
              ...snackError,
              message: `Error while assigning "${threadName}" to tag "${title}"`,
              onAction: () => this.assignThreadToThreadGroup($event),
            });
          }
        });
    },
    removeThreadFromThreadGroup($event) {
      this._removeThreadFromThreadGroup($event)
        .then(({ title, threadName, error }) => {
          if (error) {
            this.$buefy.snackbar.open({
              ...snackError,
              message: `Error while removing "${threadName}" from tag "${title}"`,
              onAction: () => this.removeThreadFromThreadGroup($event),
            });
          } else {
            this.$buefy.snackbar.open({
              ...snackSuccess,
              message: `Thread "${threadName}" has been removed from tag "${title}"`,
            });
          }
        });
    },
    createThreadGroupAndAssign($event) {
      this._createThreadGroupAndAssign($event)
        .then(({ title, threadName, error }) => {
          if (error) {
            this.$buefy.snackbar.open({
              ...snackError,
              message: `Error while creating tag "${title}",
               thread "${threadName}" is not assigned`,
              onAction: () => this.createThreadGroupAndAssign($event),
            });
          } else {
            this.$buefy.snackbar.open({
              ...snackSuccess,
              message: `Thread "${threadName}" has been assigned to newly created tag "${title}"`,
            });
          }
        });
    },
    assignContactToContactGroup($event) {
      console.log('🚀 ~ file: TheThreadHeader.vue ~ line 189 ~ assignContactToContactGroup ~ $event', $event);
      this._assignContactToContactGroup($event)
        .then(({ title, threadName, error }) => {
          if (error) {
            this.$buefy.snackbar.open({
              ...snackError,
              message: `Error while assigning "${threadName}" to contact folder "${title}"`,
              onAction: () => this.assignContactToContactGroup($event),
            });
          } else {
            this.$buefy.snackbar.open({
              ...snackSuccess,
              message: `Contact "${threadName}" has been assigned to contact folder "${title}"`,
            });
          }
        });
    },
    removeContactFromContactGroup($event) {
      this._removeContactFromContactGroup($event)
        .then(({ title, threadName, error }) => {
          if (error) {
            this.$buefy.snackbar.open({
              ...snackError,
              message: `Error while removing "${threadName}" from contact folder "${title}"`,
              onAction: () => this.removeThreadFromThreadGroup($event),
            });
          } else {
            this.$buefy.snackbar.open({
              ...snackSuccess,
              message: `Contact "${threadName}" has been removed from contact folder "${title}"`,
            });
          }
        });
    },
    createContactGroupAndAssign($event) {
      this._createContactGroupAndAssign($event)
        .then(({ title, threadName, error }) => {
          if (error) {
            this.$buefy.snackbar.open({
              ...snackError,
              message: `Error while creating contact folder "${title}",
               contact "${threadName}" is not assigned`,
              onAction: () => this.createThreadGroupAndAssign($event),
            });
          } else {
            this.$buefy.snackbar.open({
              ...snackSuccess,
              message: `Contact "${threadName}" has been assigned to newly 
              created contact folder "${title}"`,
            });
          }
        });
    },
    assignThreadToUbuUser($event) {
      this._assignThreadToUbuUser($event)
        .then(({ name, threadName, error }) => {
          if (error) {
            this.$buefy.snackbar.open({
              ...snackError,
              message: `Error while assigning "${threadName}" to user "${name}"`,
              onAction: () => this.assignThreadToUbuUser($event),
            });
          } else {
            this.$buefy.snackbar.open({
              ...snackSuccess,
              message: `Contact "${threadName}" has been assigned to user "${name}"`,
            });
          }
        });
    },
    removeThreadFromUbuUser($event) {
      this._removeThreadFromUbuUser($event)
        .then(({ name, threadName, error }) => {
          if (error) {
            this.$buefy.snackbar.open({
              ...snackError,
              message: `Error while removing "${threadName}" from user "${name}"`,
              onAction: () => this.removeThreadFromUbuUser($event),
            });
          } else {
            this.$buefy.snackbar.open({
              ...snackSuccess,
              message: `Contact "${threadName}" has been removed from user "${name}"`,
            });
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.UbuThreadHeader_wrapper {
  background-color: var(--soapstone);
  height: 52px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--mercury);
  > *:not(:last-child) {
      margin-right: 8px;
  }

  .UbuThreadHeader_dropdownParent {
    display: inline-flex;
    position: relative;

    .UbuThreadHeader_dropdown {
      z-index: 100;
      position: absolute;
      top: calc(100% + 6px);
      right: 0;
      background-color: var(--soapstone);
      box-shadow: 0px 0px 10px var(--box-shadow);
      border-radius: 12px;
      padding: 10px 15px;
      width: max-content;
      max-width: 200px;
    }
  }

  // .UbuThreadHeader_contactName {
    // max-width: 20%;
  // }
  .UbuThreadHeader_selector {
  }
  .UbuThreadHeader_selectors {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    > * {
      margin: 0 4px !important;
      max-width: 100px;
    }
  }
}
</style>
