import { get } from 'lodash';

const isThreadUnread = (thread) => {
  const { lsa, last_permanent_item: lastPermanentItem, contactId } = thread;

  let isUnread = false;
  if (lastPermanentItem) {
    const { user_id: userId, isMe, timestamp } = lastPermanentItem;
    if (!isMe || String(userId) === String(contactId)) {
      if (Number(lsa) < Number(timestamp)) {
        isUnread = true;
      }
    }
  } else {
    console.warn('🚀 ~ file: TheInfluenceMessages.vue ~ line 145 ~ .map ~ thread.threadId', thread.threadId);
  }
  return isUnread;
};

const getLastItem = (thread) => thread.last_permanent_item;

const getLastItems = (thread, count) => {
  const lasts = [
    getLastItem(thread),
    ...thread.messages.slice(1, count - 1),
  ];
  return lasts;
};

const getLastTimestamp = (thread) => {
  const item = getLastItem(thread);
  return item && (+(item.lastTts) || +(item.timestamp) || 0);
};

const getFollowersCount = (thread) => get(thread, 'contact.follower_count', -1);

const isMention = (item) => item.uniType === 'isStoryMention';

const isReaction = (item) => item.uniType === 'isStoryReply';

const isReply = (item) => item.uniType === 'isStoryReact';

const keywords = [
  'portfolio',
  'partenariat',
  'collab',
  'collabs',
  'collaboration',
  'collaborate',
  'agence',
  'partenariats',
  'Ambassadeur',
  'Ambassadeurs',
  'INFLUENCE',
  'influencers',
  'campaign',
  'blogger',
  'gifting',
  'Gift',
  'partner',
  'partnership',
  'photographer',
  'photograph',
  'communauté',
  'community',
  'portfólio',
  'parceria',
  'collab',
  'colaborações',
  'colaboração',
  'colaborar',
  'agência',
  'parcerias',
  'Embaixador',
  'Embaixadora',
  'Embaixadores',
  'INFLUENCE',
  'influenciadores',
  'Influenciadora',
  'Influencer',
  'campanha',
  'blogger',
  'blogueira',
  'seeding',
  'parceiro',
  'parceria',
  'fotógrafo',
  'fotografia',
  'comunidade',
  'portafolio',
  'asociación',
  'collab',
  'colaboraciones',
  'colaboración',
  'colaborar',
  'agencia',
  'parcería',
  'Embajador',
  'Embajadores',
  'Influencia',
  'influencers',
  'Campaña',
  'blogger',
  'gift',
  'seeding',
  'partner',
  'partnership',
  'fotógrafo',
  'fotografía',
  'comunidad',
].map((kw) => kw.toLowerCase());

// const threadIsOpportunity = (thread) => {
//   const lasts = getLastItemsFromOther(thread, 5);
//   console.log('Getting lasts', thread, lasts);
//   const threadHaveKeywords = lasts.find((last) => {
//     if (last && !last.isMe && last.text) {
//       const toCompare = last.text.toLowerCase();
//       const hasKw = keywords.find((keyword) => toCompare.includes(keyword));
//       if (hasKw) {
//         return last;
//       }
//     }
//     return false;
//   });
//   return !!threadHaveKeywords;
// };

const threadIsOpportunity = (thread) => {
  // if (thread.threadGroups.length) { return false; }
  const last = getLastItem(thread);
  if (last && !last.isMe && last.text) {
    const toCompare = last.text.toLowerCase();
    return !!keywords.find((keyword) => toCompare.includes(keyword));
  }
  return false;
};

const threadIsImportant = (thread) => {
  // if (thread.threadGroups.length) { return false; }
  const lastItem = getLastItem(thread);
  if (lastItem && !lastItem.isMe && lastItem.text) {
    return lastItem.text.length > 50 || lastItem.text.includes('?');
  }
  return false;
};

const threadIsMention = (thread) => {
  const last = getLastItem(thread);
  if (!last) { return false; }
  return isMention(last);
};

const threadIsOpen = (item) => (item.closed === undefined ? true : !item.closed);

const threadIsSent = (thread) => {
  const last = getLastItem(thread);
  if (!last) return false;
  return last.isMe;
};

const threadIsUnread = (thread) => {
  const { lsa = 0, last_permanent_item: lastPermanentItem, contactId } = thread;
  let isUnread = false;
  if (lastPermanentItem) {
    const { userId, timestamp } = lastPermanentItem;
    if (String(userId) === String(contactId)) {
      if (lsa < timestamp) {
        isUnread = true;
      }
    }
  }
  return isUnread;
};

const threadIsMute = (thread) => thread && !!thread.ubu_muted;

const getThreadName = (thread) => get(thread, 'contact.full_name')
    || get(thread, 'contact.username')
    || thread.title
    || 'Unfoundable name';

const fillAccFolders = (acc, thread) => {
  if (threadIsOpen(thread)) {
    acc[thread.is].opened.push(thread);
    if (!threadIsMute(thread) && !threadIsSent(thread)) {
      acc[thread.is].count += 1;
      if (!acc[thread.is].hasUnread && thread.isUnread) { acc[thread.is].hasUnread = true; }
    }
  } else {
    acc[thread.is].closed.push(thread);
  }
  if (thread.is === 'opportunities' || thread.is === 'importants') {
    // return fillAccFolders(acc, { ...thread, is: 'inbox' });
    acc.inbox.push(thread);
  }
  return acc;
};

const filterThreads = (threads, filter) => {
  let _threads = threads || [];
  if (filter === 'muted') {
    _threads = _threads.filter((t) => t.ubu_muted);
  } else {
    _threads = _threads.filter((t) => !t.ubu_muted);
    switch (filter) {
      case 'received':
        _threads = _threads.filter((t) => !threadIsSent(t));
        break;
      case 'sent':
        _threads = _threads.filter((t) => threadIsSent(t));
        break;
      case 'unread':
        _threads = _threads.filter((t) => t.isUnread === true);
        break;
      case 'tags':
        _threads = _threads.filter((t) => t.threadGroups.length);
        break;
      case 'folders':
        _threads = _threads.filter((t) => t.contactGroups.length);
        break;
      case 'untagged':
        _threads = _threads.filter((t) => !t.threadGroups.length);
        break;
      case 'unlabeled':
        _threads = _threads.filter((t) => !t.contactGroups.length);
        break;
      case 'assigned':
        _threads = _threads.filter((t) => t.assignments.length);
        break;
      case 'unassigned':
        _threads = _threads.filter((t) => !t.assignments.length);
        break;
      case 'all':
      default:
        break;
    }
  }
  return _threads;
};

const sortThreads = (threads, sort) => {
  let _threads = threads || [];
  _threads = _threads.sort((a, b) => {
    const [direction, key] = sort.split(':');
    let _a = 0;
    let _b = 0;
    switch (key) {
      case 'timestamp':
        _a = getLastTimestamp(a);
        _b = getLastTimestamp(b);
        break;
      case 'followers':
        _a = getFollowersCount(a);
        _b = getFollowersCount(b);
        break;
      default:
        break;
    }
    if (direction === 'asc') return _b - _a;
    return _a - _b;
  });
  return _threads;
};

const threadsMeta = (threads, meta) => {
  const _threads = filterThreads(threads, meta.defaultFilter);
  const hasUnread = !!_threads.find((t) => t.isUnread);
  const count = _threads.length;
  return {
    pageTitle: meta.title,
    count,
    hasUnread,
  };
};

const defineThreadIs = (thread, myId) => {
  let is = null;
  if (thread.kind === 'influence') {
    is = 'influence';
  } else if (thread.assignments.length) {
    if (myId && thread.assignments.find((ubuUsers) => ubuUsers.ubuUserId === myId)) {
      is = 'assignment';
    }
  } else if (threadIsMention(thread)) {
    is = 'mentions';
  } else if (threadIsOpportunity(thread)) {
    is = 'opportunities';
  } else if (threadIsImportant(thread)) {
    is = 'importants';
  } else {
    is = 'inbox';
  }
  return is;
};

export {
  isThreadUnread,
  getLastItem,
  getLastItems,
  getLastTimestamp,
  getFollowersCount,
  getThreadName,
  isMention,
  isReaction,
  isReply,
  threadIsOpportunity,
  threadIsImportant,
  threadIsMention,
  threadIsOpen,
  threadIsSent,
  threadIsUnread,
  threadIsMute,
  threadsMeta,
  fillAccFolders,
  filterThreads,
  sortThreads,
  defineThreadIs,
};
