import { responseMapper } from '../../$utils/prototypes';
import {
  updateDatasIfExists,
  updateObjectRelationIfExists,
  addNewKeysToMap,
  removeKeyToMap,
} from '../$utils/dataMapper';
import internals from './internals';

/* eslint-disable max-len */
export default {
  namespaced: true,

  state: {
    campaigns: {},
    campaignsHasActivitiesBis: {},
    campaignsHasContactBis: {},
    campaignsHasActivities: [],
    campaignsByChannelsListErrors: {},

    campaignsHasDiscountCodesBis: {},
    campaignsHasReferralLinksBis: {},
  },

  getters: {
    getterCampaigns: (state) => state.campaigns,
    getterCampaignsHasActivitiesData: (state) => state.campaignsHasActivities,
    getterCampaignsHasActivitiesBis: (state) => state.campaignsHasActivitiesBis,
    getterCampaignsHasContactsBis: (state) => state.campaignsHasContactBis,
    getterCurrentCampaigns: (state) => Object.values(state.campaigns),
    getterCampaignsHasDiscountCodesBis: (state) => state.campaignsHasDiscountCodesBis,
    getterCampaignsHasReferralLinksBis: (state) => state.campaignsHasReferralLinksBis,
  },

  mutations: {
    // SET_CAMPAIGNS(state, campaigns) { state.campaigns = campaigns; },
    SET_CAMPAIGNS(state, newDatas) { state.campaigns = updateDatasIfExists({ newDatas, actualDatas: state.campaigns, key: 'campaignId' }); },
    // SET_CAMPAIGNS_BY_CHANNEL_LIST_ERRORS(state, campaignsByChannelsListErrors) { state.campaignsByChannelsListErrors = campaignsByChannelsListErrors; },
    // SET_CAMPAIGNS_HAS_ACTIVITIES(state, newDatas) { state.campaignsHasActivities = updateRelationsIfExists(state.campaignsHasActivities, newDatas); },
    SET_CAMPAIGNS_HAS_ACTIVITIES_BIS(state, newDatas) { state.campaignsHasActivitiesBis = updateObjectRelationIfExists({ newDatas, actualDatas: state.campaignsHasActivitiesBis }); },
    SET_CAMPAIGNS_HAS_REFERRAL_LINKS_BIS(state, newDatas) { state.campaignsHasReferralLinksBis = updateObjectRelationIfExists({ newDatas, actualDatas: state.campaignsHasReferralLinksBis }); },
    REMOVE_REFERRAL_LINK_TO_CAMPAIGN(state, { campaignId, referralLinkRlid }) { state.campaignsHasReferralLinksBis = removeKeyToMap({ map: state.campaignsHasReferralLinksBis, key: campaignId, value: referralLinkRlid }); },
    SET_CAMPAIGNS_HAS_DISCOUNT_CODES_BIS(state, newDatas) { state.campaignsHasDiscountCodesBis = updateObjectRelationIfExists({ newDatas, actualDatas: state.campaignsHasDiscountCodesBis }); },
    REMOVE_DISCOUNT_CODE_TO_CAMPAIGN(state, { campaignId, discountCodeDcid }) { state.campaignsHasDiscountCodesBis = removeKeyToMap({ map: state.campaignsHasDiscountCodesBis, key: campaignId, value: discountCodeDcid }); },

    ADD_ACTIVITIES_TO_CAMPAIGN(state, { activityIds, campaignId }) { state.campaignsHasActivitiesBis = addNewKeysToMap({ map: state.campaignsHasActivitiesBis, key: campaignId, values: activityIds }); },
    REMOVE_ACTIVITY_TO_CAMPAIGN(state, { campaignId, activityId }) { state.campaignsHasActivitiesBis = removeKeyToMap({ map: state.campaignsHasActivitiesBis, key: campaignId, value: activityId }); },

    SET_CAMPAIGNS_HAS_CONTACTS_BIS(state, newDatas) { state.campaignsHasContactBis = updateObjectRelationIfExists({ newDatas, actualDatas: state.campaignsHasContactBis }); },
    // UNSET_CAMPAIGNS_HAS_ACTIVITIES(state, activityIdToRemove) { state.campaignsHasActivities.splice(state.campaignsHasActivities.findIndex(({ activityId }) => activityId === activityIdToRemove), 1); },
    RESET_STORES(state) {
      state.activities = {};
      state.campaignsHasActivitiesBis = {};
      state.campaignsHasContactBis = {};
      state.campaignsHasActivities = [];
      state.campaignsByChannelsListErrors = {};

      state.campaignsHasDiscountCodesBis = {};
      state.campaignsHasReferralLinksBis = {};
    },
  },

  actions: {
    fetchCampaigns({ commit }) {
      return Promise.resolve()
        .then(() => Promise.all(internals.channelsIds.map((channelId) => internals.fetchCampaignsByChannel(channelId))))
        .then((fetchCampaignsByChannel) => {
          const campaignsByChannelsList = fetchCampaignsByChannel
            .map((row) => (
              row.error
                ? row
                : row.response
                  .map((campaign) => ({ ...campaign, channelId: row.channelId, campaignId: campaign._id }))
            ));

          const fetchedCampaignList = responseMapper(campaignsByChannelsList, 'campaignId');
          // console.log('🚀 ~ file: Campaign.store.js ~ line 37 ~ .then ~ fetchedCampaignList', fetchedCampaignList);
          commit('SET_CAMPAIGNS', fetchedCampaignList);

          const channelsHasCampaigns = Object.values(fetchedCampaignList).map(({ channelId, campaignId }) => ({ channelId, campaignId }));
          // console.log('🚀 ~ file: Campaign.store.js ~ line 41 ~ .then ~ channelsHasCampaigns', channelsHasCampaigns);
          commit('Channel/SET_CHANNELS_HAS_CAMPAIGNS', channelsHasCampaigns, { root: true });
          internals.channelsHasCampaigns = channelsHasCampaigns;

          const channelsHasCampaignsBis = channelsHasCampaigns
            .reduce((acc, row) => {
              const { channelId, campaignId } = row;
              if (!acc[channelId]) {
                acc[channelId] = [campaignId];
                return acc;
              }
              acc[channelId].push(campaignId);
              return acc;
            }, {});

          commit('Channel/SET_CHANNELS_HAS_CAMPAIGNS_BIS', channelsHasCampaignsBis, { root: true });
        });
    },
  },

};
