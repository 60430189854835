<template>
  <UbuBaseModal
    :title="title"
    :pic="'Error.gif'"
  >
    <template slot="info">
      {{ description }}
    </template>
    <template slot="actions">
      <div class="UbuReconnectChannel_actions">
        <UbuButton
          class="ml-2"
          center
          grow
          secondary
          v-bind="{ href: '/add-facebook-channel.html', target: '_self' }"
        >
          {{ actionTitle }}
        </UbuButton>
      </div>
    </template>
  </UbuBaseModal>
</template>
<script>
import UbuBaseModal from '@/components/UbuModals/UbuBaseModal';

export default {
  components: {
    UbuBaseModal,
  },
  computed: {
    title() {
      return 'Your facebook channel is disconnected';
    },
    description() {
      return 'Please reconnect your facebook channel to access the Comment section';
    },
    actionTitle() {
      return 'Reconnect';
    },
  },

};
</script>
<style lang="scss">
  .UbuReconnectChannel_actions {
    display: flex;
    flex-grow: 1;
    > * {
      width: 50%;
    }
  }
</style>
