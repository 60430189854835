<template>
  <div id="MentionsDashboard">
    <h1>Analytics</h1>
    <div class="buttons">
      <b-button
        v-for="(section, index) in analyticsSections"
        :key="index"
        tag="router-link"
        class="is-transparent is-rounded"
        :to="section.path"
        :active="isButtonActive(section.path)"
      >
        <span class="button-content">
          {{ section.name }}
        </span>
      </b-button>
    </div>

    <router-view />
  </div>
</template>

<script>
export default {
  name: 'TheMentionsAnalytics',
  data() {
    return {
      analyticsSections: [
        { name: 'Account', path: '/activities/analytics/account', icon: 'instagram' },
        { name: 'Inbox', path: '/activities/analytics/inbox', icon: 'inbox' },
        { name: 'Shopify', path: '/activities/analytics/shopify', icon: 'shop' },
      ],
    };
  },
  methods: {
    isButtonActive(path) {
      return this.$router.currentRoute.matched.some(
        (route) => route.path === path,
      );
    },
  },
};
</script>

<style lang="scss">
#MentionsDashboard {
  & .button-content {
    display: flex;
    align-items: center;
  }
}
</style>
