<template>
  <UbuCommonMessageCard
    :is-me="isMe"
    :date="date"
    :download-url="downloadUrl"
    is-media
    @deleteMessage="$emit('deleteMessage')"
  >
    <template v-slot:image>
      <UbuCommonImage
        :url="url"
        :has-bucket="hasBucket"
      />
    </template>
  </UbuCommonMessageCard>
</template>
<script>

export default {
  name: 'UbuCommonMediaForImage',
  props: {
    isMe: {
      type: Boolean,
      default: false,
    },
    hasBucket: {
      type: Boolean,
      default: false,
    },
    date: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    downloadUrl: {
      type: String,
      default: '',
    },
  },
};
</script>
