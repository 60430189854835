<template>
  <div class="comment-footer">
    <div class="comment-infos">
      <UbuCommonCommentInfosUser
        v-if="from"
        :img-is-square="imgIsSquare"
        :from="from"
        :is-pending="isPending"
      />
      <div
        v-else
        class="ubu-text-2"
      >
        Missing name*
      </div>
      <div class="divider" />
      <div class="comment-infos__published-at">
        {{ setTimeFromNow }}
      </div>
      <template v-if="count">
        <div class="divider" />
        <b-tooltip :label="allReaction">
          <div
            class="comment-reactions"
          >
            <div
              v-for="(reaction, i) in reactionFiltered"
              :key="i"
              class="comment-reactions__reaction"
            >
              <span v-if="reaction.count > 0">
                {{ reaction.emoji }}
              </span>
            </div>
            <span class="comment-reactions__count">{{ count }}</span>
          </div>
        </b-tooltip>
      </template>
    </div>
  </div>
</template>

<script>
import { timeToNow } from '../../$utils/dates';

export default {
  name: 'UbuCommonCommentFooter',
  props: {
    options: {
      type: Object,
      default: () => {},
    },
    from: {
      type: Object,
      default: null,
    },
    reactions: {
      type: Array,
      default: () => [],
    },
    publishedAt: {
      type: Number,
      default: 0,
    },
    reactionCount: {
      type: Number,
      default: 0,
    },
    isPending: {
      type: Boolean,
      default: false,
    },
    imgIsSquare: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    setTimeFromNow() {
      return timeToNow(this.publishedAt);
    },
    allReaction() {
      const { reactions } = this;
      let text = '';

      for (let i = 0; i < reactions.length; i++) {
        if (reactions[i].count) {
          text += `${reactions[i].count} ${reactions[i].emoji} `;
        }
      }
      return text;
    },
    count() {
      return this.reactions.reduce((prev, next) => prev + next.count, 0);
    },
    reactionFiltered() {
      const { reactions } = this;
      const filter = (reactions || [])
        .sort((a, b) => b.count - a.count)
        .slice(0, 3);
      return filter;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
