import { use, registerComponent } from '../../$utils/plugins';

// const UbuWorkspaceUserTemplate = () => import(/* webpackChunkName: "addonWorkspace" */ './UbuWorkspaceUserTemplate');
// const UbuWorkspaceAccountChannelTemplate = () => import(/* webpackChunkName: "addonWorkspace" */ './UbuWorkspaceAccountChannelTemplate');
// const UbuWorkspaceCustoms = () => import(/* webpackChunkName: "addonWorkspace" */ './UbuWorkspaceCustoms');
// const UbuWorkspaceModalEditTag = () => import(/* webpackChunkName: "addonWorkspace" */ './UbuWorkspaceModalEditTag');
// const UbuWorkspaceModalEditFolder = () => import(/* webpackChunkName: "addonWorkspace" */ './UbuWorkspaceModalEditFolder');
// const UbuWorkspaceModalAddTag = () => import(/* webpackChunkName: "addonWorkspace" */ './UbuWorkspaceModalAddTag');
// const UbuWorkspaceModalAddFolder = () => import(/* webpackChunkName: "addonWorkspace" */ './UbuWorkspaceModalAddFolder');
// const UbuWorkspaceModalAddTemplate = () => import(/* webpackChunkName: "addonWorkspace" */ './UbuWorkspaceModalAddTemplate');
// const UbuWorkspaceTemplate = () => import(/* webpackChunkName: "addonWorkspace" */ './UbuWorkspaceTemplate');
// const UbuWorkspaceModalEditTemplate = () => import(/* webpackChunkName: "addonWorkspace" */ './UbuWorkspaceModalEditTemplate');
// const UbuWorkspaceModalEditName = () => import(/* webpackChunkName: "addonWorkspace" */ './UbuWorkspaceModalEditName');
// const UbuWorkspaceModalEditPassword = () => import(/* webpackChunkName: "addonWorkspace" */ './UbuWorkspaceModalEditPassword');
// const UbuWorkspaceModalConfirmation = () => import(/* webpackChunkName: "addonWorkspace" */ './UbuWorkspaceModalConfirmation');
// const UbuWorkspaceKeywords = () => import(/* webpackChunkName: "addonWorkspace" */ './UbuWorkspaceKeywords');
// const UbuWorkspaceModalAddKeyword = () => import(/* webpackChunkName: "addonWorkspace" */ './UbuWorkspaceModalAddKeyword');

import UbuWorkspaceUserTemplate from './UbuWorkspaceUserTemplate';
import UbuWorkspaceAccountChannelTemplate from './UbuWorkspaceAccountChannelTemplate';
import UbuWorkspaceCustoms from './UbuWorkspaceCustoms';
import UbuWorkspaceModalEditTag from './UbuWorkspaceModalEditTag';
import UbuWorkspaceModalEditFolder from './UbuWorkspaceModalEditFolder';
import UbuWorkspaceModalAddTag from './UbuWorkspaceModalAddTag';
import UbuWorkspaceModalAddFolder from './UbuWorkspaceModalAddFolder';
import UbuWorkspaceModalAddTemplate from './UbuWorkspaceModalAddTemplate';
import UbuWorkspaceTemplate from './UbuWorkspaceTemplate';
import UbuWorkspaceModalEditTemplate from './UbuWorkspaceModalEditTemplate';
import UbuWorkspaceModalEditName from './UbuWorkspaceModalEditName';
import UbuWorkspaceModalEditPassword from './UbuWorkspaceModalEditPassword';
import UbuWorkspaceModalConfirmation from './UbuWorkspaceModalConfirmation';
import UbuWorkspaceKeywords from './UbuWorkspaceKeywords';
import UbuWorkspaceModalAddKeyword from './UbuWorkspaceModalAddKeyword';
import UbuWorkspaceConnectionCard from './UbuWorkspaceConnectionCard';
import UbuWorkspaceConnectModal from './UbuWorkspaceConnectModal';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, UbuWorkspaceUserTemplate);
    registerComponent(Vue, UbuWorkspaceAccountChannelTemplate);
    registerComponent(Vue, UbuWorkspaceCustoms);
    registerComponent(Vue, UbuWorkspaceModalEditTag);
    registerComponent(Vue, UbuWorkspaceModalEditFolder);
    registerComponent(Vue, UbuWorkspaceModalAddTag);
    registerComponent(Vue, UbuWorkspaceModalAddFolder);
    registerComponent(Vue, UbuWorkspaceModalAddTemplate);
    registerComponent(Vue, UbuWorkspaceTemplate);
    registerComponent(Vue, UbuWorkspaceModalEditTemplate);
    registerComponent(Vue, UbuWorkspaceModalEditName);
    registerComponent(Vue, UbuWorkspaceModalEditPassword);
    registerComponent(Vue, UbuWorkspaceModalConfirmation);
    registerComponent(Vue, UbuWorkspaceKeywords);
    registerComponent(Vue, UbuWorkspaceModalAddKeyword);
    registerComponent(Vue, UbuWorkspaceConnectionCard);
    registerComponent(Vue, UbuWorkspaceConnectModal);
  },
};

use(Plugin);

export default Plugin;

export {
  UbuWorkspaceUserTemplate,
  UbuWorkspaceAccountChannelTemplate,
  UbuWorkspaceCustoms,
  UbuWorkspaceModalEditTag,
  UbuWorkspaceModalEditFolder,
  UbuWorkspaceModalAddTag,
  UbuWorkspaceModalAddFolder,
  UbuWorkspaceModalAddTemplate,
  UbuWorkspaceTemplate,
  UbuWorkspaceModalEditTemplate,
  UbuWorkspaceModalEditName,
  UbuWorkspaceModalEditPassword,
  UbuWorkspaceModalConfirmation,
  UbuWorkspaceKeywords,
  UbuWorkspaceModalAddKeyword,
  UbuWorkspaceConnectionCard,
  UbuWorkspaceConnectModal,
};
