<template>
  <section
    id="TheInfluenceAudit"
  >
    <h1 class="ubu-title">Audit Generator</h1>
    <div class="AuditGenerator">
      <h1 class="ubu-title">How does it work 💡</h1>
      <ul class="ubu-text3">
        <li>Search the instagram username you want a report of and click “Generate report”.</li>
        <li>
          Account targeted must have more than 5000 followers and not to be a private account.
        </li>
        <li>
          (Loading can take +/- 30sec)
        </li>
      </ul>
      <div class="flex mb-5">
        <UbuSimpleInput
          v-model="searchValue"
          class="searchbar"
          placeholder="Search a username..."
          searchbar
          :value="searchValue"
        >
          <UbuIcon
            slot="placeholderIcon"
            name="search"
          />
        </UbuSimpleInput>

        <b-button
          class="is-ligth is-rounded"
          :loading="isGenerateReportLoading"
          @click="generateAudit()"
        >
          Generate report
        </b-button>
      </div>
      <p>Example:</p>
      <img
        src="img/favikon_example.png"
        alt="Audit Example"
      >
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { snackSuccess, snackError } from '../$catalog/snackbar';

export default {
  name: 'TheInfluenceAudit',
  data() {
    return {
      searchValue: '',
      isGenerateReportLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      currentChannelId: 'Channel/getterCurrentChannelId',
    }),
  },
  methods: {
    ...mapActions({
      generateReport: 'TheCRM/generateReport',
    }),
    generateAudit() {
      if (this.searchValue.length) {
        if (this.searchValue.match('https://www.instagram.com/')) {
          const url = new URL(this.searchValue);
          this.searchValue = url.pathname.replaceAll('/', '');
        }

        this.isGenerateReportLoading = true;
        this.generateReport({
          channelId: this.currentChannelId,
          username: this.searchValue,
        })
          .then(({ response, error }) => {
            if (error) {
              this.$buefy.snackbar.open({
                ...snackError,
                duration: 5000,
                actionText: null,
                message: 'Account target must have more than 5000 followers & not be private',
              });
              this.isGenerateReportLoading = false;
              this.searchValue = '';
              return;
            }
            this.$buefy.snackbar.open({
              ...snackSuccess,
              type: 'is-link',
              indefinite: true,
              message: `Click to open ${response.username} report`,
              onAction: () => window
                .open(response.public_url, `${response.username} generated report`),
            });
            this.isGenerateReportLoading = false;
            this.searchValue = '';
          });
      }
    },
  },
};
</script>
