<template>
  <div
    id="UbuMentionsHashtagsMediaCard"
    :style="[isLoading || !mediaLoaded ? {
      height: isLoading ? randomHeight() : `${media.skeletonHeight}px`} : {height: 'auto'}]"
  >
    <div
      v-if="isLoading || !mediaLoaded"
      class="UbuMentionsHashtagsMediaCard_skeleton"
    >
      <b-skeleton
        :rounded="false"
        :active="true"
        height="1000"
      />
    </div>
    <div v-if="media">
      <div @click="playPause()">
        <b-icon
          v-if="media.mediaType === 'VIDEO' && !isLoading && mediaLoaded"
          :icon="isVideoPlaying ? 'pause' : 'play'"
          class="playButton"
        />
      </div>
      <div class="UbuMentionsHashtagsMediaCard_overlay">
        <div class="user">
          <a
            v-if="media.source === 'instagram'"
            class="avatar"
            :href="linkUserProfile"
            target="_blank"
          >
            <UbuInstaImg
              has-bucket
              bucket-folder="profile_pic"
              :insta-src="media.userPk"
              fallback="/img/no_profile_pict.svg"
            />
          </a>
          <a
            v-else
            class="avatar"
            :href="linkUserProfile"
            target="_blank"
          >
            <UbuInstaImg
              :insta-src="media.profilePicUrl"
              fallback="/img/no_profile_pict.svg"
            />
          </a>
          <a
            class="username ubu-text2"
            :href="linkUserProfile"
            target="_blank"
          >
            @{{ media.username }}
          </a>
          <span class="ubu-text2">
            {{ followerCount }} followers
          </span>
          <span class="ubu-text2">
            {{ media.date }}
          </span>
          <span
            v-if="media.views"
            class="ubu-text2"
          >
            {{ viewsCount }} views
          </span>
          <span class="ubu-text2">{{ media.postEngagement.toFixed(2) }}% engagement</span>
        </div>
        <div class="ubu-text2 UbuMentionsHashtagsMediaCard_likes">
          <div class="falign-center interaction">
            <span style="color: #FF62A4">{{ likeCount }}</span>
            <UbuIcon
              class="interaction-icon"
              name="heart"
              filled
              color="12"
            />
          </div>
          |
          <div class="falign-center interaction">
            <span style="color: #3CA6F2">{{ commentCount }}</span>
            <UbuIcon
              class="interaction-icon"
              name="replies"
              filled
              color="8"
            />
          </div>
        </div>
      </div>
      <b-image
        v-if="media.mediaType !== 'VIDEO'"
        class="UbuMentionsHashtagsMediaCard_image"
        :src="media.mediaUrl"
        lazy
        @load="mediaLoaded = true"
      />
      <video
        v-else
        :ref="media.mediaUrl"
        loop
        class="UbuMentionsHashtagsMediaCard_image"
        @loadeddata="mediaLoaded = true"
      >
        <source
          :src="media.mediaUrl"
          :type="'video/mp4'"
        >
        Sorry, your browser doesn't support embedded videos.
      </video>
      <div :class="['mediaSource', {'spinning': isVideoPlaying}]">
        <b-image
          v-if="media.source === 'instagram'"
          class="source"
          src="/img/instagram-logo.svg"
        />
        <b-image
          v-else-if="media.source === 'facebook'"
          class="source"
          src="/img/facebook-logo.svg"
        />
        <b-image
          v-else-if="media.source === 'tiktok'"
          class="source"
          src="/img/tiktok-logo.svg"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { shortenNumber } from '../../$utils/formate';

export default {
  name: 'UbuMentionsHashtagsMediaCard',
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    media: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      mediaLoaded: false,
      isVideoPlaying: false,
    };
  },
  computed: {
    followerCount() {
      return shortenNumber(this.media.followerCount);
    },
    likeCount() {
      return shortenNumber(this.media.likeCount);
    },
    commentCount() {
      return shortenNumber(this.media.commentCount);
    },
    viewsCount() {
      return shortenNumber(this.media.views);
    },
    linkUserProfile() {
      return this.media.source === 'tiktok' ? `https://www.tiktok.com/@${this.media.username}` : `https://instagram.com/${this.media.username}`;
    },
    skeletonHeight() {
      if (!this.media) return `${this.media.skeletonHeight}px` || null;
      return null;
    },
  },
  methods: {
    randomHeight() {
      const heights = ['220px', '380px'];
      return heights[Math.floor(Math.random() * heights.length)];
    },
    playPause() {
      if (this.isVideoPlaying) this.$refs[this.media.mediaUrl].pause();
      else this.$refs[this.media.mediaUrl].play();
      this.isVideoPlaying = !this.isVideoPlaying;
    },
  },
};
</script>
