<template>
  <div class="UbuEllipsis_wrapper">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'UbuEllipsis',
};
</script>

<style lang="scss">
.UbuEllipsis_wrapper {
  overflow-wrap: break-word;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &::after {
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 50px;
    background: linear-gradient(to right, var(--soapstone00), var(--soapstone));
  }
}
</style>
