<template>
  <div
    id="UbuInfluenceLeadsComposeMessage"
    class="pipelineTable multibar column"
  >
    <div class="pipelineTable_header multibar start row-vcentered">
      <p class="pipelineTable_counter start">
        <b-button
          class="is-inverted is-dark"
          @click="$emit('removeSelected')"
        >
          <UbuIcon
            size="16px"
            name="back"
            outlined
          />
        </b-button>
      </p>
      <p class="pipelineTable_title start ubu-header3">
        Compose message
      </p>
      <p class="pipelineTable_counter end">
        <b-button
          class="is-rounded has-campaign-color"
          :disabled="!isButtonDisabled"
          @click="sendMessages()"
        >
          review & send {{ leads.length }}
        </b-button>
      </p>
    </div>

    <div class="start pipelineTable_content">
      <div class="card ubuCard">
        <div class="card-header">
          <p class="ubu-card-title">
            <b-radio
              v-model="localRadio"
              name="name"
              native-value="custom"
            />
            Custom message
          </p>
          <div class="card-header-icon">
            <b-button
              class="is-rounded end is-light"
              :disabled="!customText.length"
              @click="$emit('saveNewTemplate', customText)"
            >
              Save as template
            </b-button>
          </div>
        </div>

        <div class="card-content">
          <UbuTemplateTextarea
            v-model="customText"
            :template-list="templates"
            show-controls
          />
        </div>
      </div>

      <br>
      <div
        v-for="(template, index) in localTemplates"
        :key="index"
      >
        <UbuTemplateCard
          v-model="localRadio"
          :template="template"
          @deleteCampaignTemplate="$emit('deleteCampaignTemplate', $event)"
        />

        <br>
      </div>
    </div>
  </div>
</template>

<script>
import { snackWarning } from '../../$catalog/snackbar';

export default {
  name: 'UbuInfluenceLeadsComposeMessage',
  props: {
    leads: {
      type: Array,
      required: true,
    },
    campaignDetail: {
      type: Object,
      required: true,
    },
    templates: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      radio: 'custom',
      customText: '',
      textToSend: '',
    };
  },
  computed: {
    localTemplates() {
      const templates = this.campaignDetail.messages.map((template, index) => ({
        template,
        title: `Template ${index + 1}`,
        radioValue: `${index}`,
      }));

      templates.push({
        title: 'Add to campaign without message',
        radioValue: 'empty',
      });

      return templates;
    },
    isButtonDisabled() {
      if (!this.localRadio || !this.localRadio.length) {
        return false;
      }
      if (this.localRadio === 'custom') {
        if (this.customText.length > 0) {
          return true;
        }
        return false;
      }
      return true;
    },
    localRadio: {
      get() { return this.radio; },
      set(newValue) {
        this.radio = newValue;
        let textToSend = '';
        if (newValue === 'custom') {
          textToSend = this.customText;
        } else if (newValue === 'empty') {
          textToSend = '';
        } else if (newValue !== 'custom' || newValue !== 'empty') {
          textToSend = this.localTemplates
            .find(({ radioValue }) => radioValue === newValue).template;
        }
        this.textToSend = textToSend;
      },
    },
  },
  watch: {
    customText() {
      // eslint-disable-next-line max-len
      const reg = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
      if (this.customText.match(reg)) {
        this.$buefy.snackbar.open({
          ...snackWarning,
          message: 'We recommend that you do not put links in your outbound messages. This is detrimental to the quality of responses and can create spam (via email or dm).',
          indefinite: true,
        });
      }
    },
  },
  methods: {
    sendMessages() {
      const payload = {
        targets: this.leads,
        message: this.localRadio === 'custom' ? this.customText : this.textToSend,
      };
      if (this.localRadio === 'empty') {
        this.$emit('setDelivereds', payload);
      } else {
        this.$emit('reviewMessage', payload);
      }
    },
  },
};
</script>

<style lang="scss">
#UbuInfluenceLeadsComposeMessage {
  & .pipelineTable_content{
    padding: 16px;
  }
}
.ubuTextarea textarea {
  border: none;
  box-shadow: none;
  resize: none;
}
</style>
