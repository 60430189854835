<template>
  <div id="UbuCommentMediaPost">
    <div
      class="mediaPost-container"
      @click="$emit('selectMedia', $event)"
    >
      <div :class="[{'mediaDirectionBadge': isUnread}]">
        <div :class="[{'mediaBadge': isUnread}]" />
      </div>
      <UbuMedia
        :key="mediaUrl"
        :class="['mediaPost', {'active' : isActive}]"
        :src="mediaUrl"
        :media-type="mediaType"
        :media-url="mediaUrl"
        style="max-height: 70px"
        lazy
        ratio="1by1"
        placeholder="/img/expired_image.png"
        src-fallback="/img/expired_image.png"
        @error="onMediaError"
      />
      <div class="mediaDirectionSource">
        <UbuIcon
          v-if="source === 'instagram'"
          class="mediaSource"
          type="picto"
          name="/img/instagram-logo.svg"
          color="5"
        />
        <UbuIcon
          v-else-if="source === 'facebook'"
          class="mediaSource"
          type="picto"
          name="/img/facebook-logo.svg"
          color="5"
        />
        <UbuIcon
          v-else-if="source === 'tiktok'"
          class="mediaSource"
          type="picto"
          name="/img/tiktok-logo.svg"
          color="5"
        />
        <UbuIcon
          v-if="isAd"
          class="mediaSourceIcon"
          name="ad"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UbuCommentMediaPost',
  props: {
    mediaUrl: {
      type: String,
      default: null,
    },
    mediaType: {
      type: String,
      default: 'image',
    },
    source: {
      type: String,
      default: 'instagram',
    },
    isUnread: {
      type: Boolean,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    isAd: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    refreshTries: 0,
  }),
  methods: {
    onMediaError($event) {
      if (this.refreshTries < 1) {
        this.$emit('mediaPicError', $event);
        this.refreshTries += 1;
      }
    },
  },
};
</script>
