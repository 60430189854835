/* eslint-disable camelcase */
import moment from 'moment';

// const reduceDataToGraph2 = (analyticsData) => Object.values(analyticsData)
//   .sort((a, b) => b.id - a.id)
//   .reduce((acc, row) => {
//     const {
//       date,
//       data: {
//         followers_difference,
//         mean_engagement,
//       },
//     } = row;

//     acc.labels.push(date);

//     acc.engagement.push(mean_engagement);
//     acc.growthFollowers.push(followers_difference);

//     return acc;
//   }, {
//     labels: [],
//     engagement: [],
//     growthFollowers: [],
//   });

// const formateDateToLabelGraph2 = (range) => `${moment(range.minDate).format('MMM DD')} - ${moment(range.maxDate).subtract({ days: 1 }).format('MMM DD')}`;

const formateMedias = ({ medias, followerCount }) => {
  const mediasWithoutDuplicate = medias.reduce((acc, media) => {
    if (acc.find((data) => data.fb_id === media.fb_id)) {
      const duplicates = acc.filter((data) => data.fb_id === media.fb_id);
      const mostCompleteObj = duplicates.reduce((prev, current) => (
        (Object.keys(prev).length > Object.keys(current).length) ? prev : current));
      if (Object.keys(media).length > Object.keys(mostCompleteObj).length) acc.push(media);
    } else acc.push(media);
    return acc;
  }, []);

  return mediasWithoutDuplicate.map((m) => ({
    mediaUrl: `https://io.getubu.com/media/${m.id}`,
    totalInteractions: m.engagement || null,
    commentCount: m.comments_count || 0,
    likeCount: m.like_count || 0,
    savedCount: m.saved || 0,
    impressions: m.impressions || 0,
    reach: m.reach || 0,
    date: moment(m.ts).format('YYYY-MM-DD'),
    timestamp: m.ts,
    mediaType: m.media_type || 'IMAGE',
    isStory: m.product_type === 'story',
    tapsForward: m.taps_forward || null,
    tapsBack: m.taps_back || null,
    exits: m.exits || null,
    replies: m.replies || null,
  })).map((m) => ({
    ...m,
    engagement: ((m.totalInteractions
      || (m.likeCount + m.commentCount + m.savedCount)) / followerCount) * 100,
    engagementOnReach: m.reach ? (((m.totalInteractions
        || (m.likeCount + m.commentCount + m.savedCount)) / m.reach) * 100) : null,
    reachRate: m.reach ? ((m.reach / followerCount) * 100) : null,
    likesOnReach: m.reach ? ((m.likeCount / m.reach) * 100) : null,
    commentsOnReach: m.reach ? ((m.commentCount / m.reach) * 100) : null,
    savesOnReach: m.reach ? ((m.savedCount / m.reach) * 100) : null,
    completion: m.exits && m.tapsForward
    && m.impressions ? ((m.exits + m.tapsForward) / m.impressions) * 100 : null,
    viewPerUser: m.reach && m.impressions ? m.impressions / m.reach : null,
  })).reduce((acc, media) => {
    const analyticsDataList = [];
    if (media.likeCount) {
      analyticsDataList.push(
        {
          name: 'Likes',
          size: 50,
          color: 2,
          icon: 'heart',
          value: media.likeCount,
        },
      );
    }
    if (media.commentCount) {
      analyticsDataList.push(
        {
          name: 'Comments',
          size: 50,
          color: 8,
          icon: 'comments',
          value: media.commentCount,
        },
      );
    }
    if (media.savedCount) {
      analyticsDataList.push(
        {
          name: 'Saves',
          size: 50,
          color: 4,
          icon: 'bookmark',
          value: media.savedCount,
        },
      );
    }
    if (media.engagement) {
      analyticsDataList.push(
        {
          name: 'Engagement',
          size: 50,
          color: 9,
          icon: 'influence',
          isPercent: true,
          value: media.engagement,
        },
      );
    }
    if (media.engagementOnReach) {
      analyticsDataList.push(
        {
          name: 'Engagement on reach',
          size: 50,
          color: 9,
          icon: 'influence',
          isPercent: true,
          value: media.engagementOnReach,
        },
      );
    }
    if (media.reach) {
      analyticsDataList.push(
        {
          name: 'Reach',
          size: 50,
          color: 5,
          icon: 'account',
          value: media.reach,
        },
      );
    }
    if (media.reachRate) {
      analyticsDataList.push(
        {
          name: 'Reach rate',
          size: 50,
          color: 5,
          icon: 'account',
          isPercent: true,
          value: media.reachRate,
        },
      );
    }
    if (media.impressions) {
      analyticsDataList.push(
        {
          name: 'Prints',
          size: 50,
          color: 7,
          icon: 'eye',
          value: media.impressions,
        },
      );
    }
    if (media.viewPerUser) {
      analyticsDataList.push(
        {
          name: 'Views per user',
          size: 50,
          color: 7,
          icon: 'eye',
          value: media.viewPerUser,
        },
      );
    }
    if (media.likesOnReach) {
      analyticsDataList.push(
        {
          name: 'Likes on reach',
          size: 50,
          color: 2,
          icon: 'heart',
          isPercent: true,
          value: media.likesOnReach,
        },
      );
    }
    if (media.commentsOnReach) {
      analyticsDataList.push(
        {
          name: 'Comments on reach',
          size: 50,
          color: 8,
          icon: 'comments',
          isPercent: true,
          value: media.commentsOnReach,
        },
      );
    }
    if (media.savesOnReach) {
      analyticsDataList.push(
        {
          name: 'Saves on reach',
          size: 50,
          color: 4,
          icon: 'bookmark',
          isPercent: true,
          value: media.savesOnReach,
        },
      );
    }
    if (media.exits) {
      analyticsDataList.push(
        {
          name: 'Exits',
          size: 50,
          color: 8,
          icon: 'info',
          value: media.exits,
        },
      );
    }
    if (media.completion) {
      analyticsDataList.push(
        {
          name: 'Completion',
          size: 50,
          color: 8,
          icon: 'info',
          isPercent: true,
          value: media.completion,
        },
      );
    }
    if (media.tapsForward) {
      analyticsDataList.push(
        {
          name: 'Taps Forward',
          size: 50,
          color: 9,
          icon: 'influence',
          value: media.tapsForward,
        },
      );
    }
    if (media.tapsBack) {
      analyticsDataList.push(
        {
          name: 'Taps Back',
          size: 50,
          color: 9,
          icon: 'influence',
          value: media.tapsBack,
        },
      );
    }
    if (media.replies) {
      analyticsDataList.push(
        {
          name: 'Replies',
          size: 50,
          color: 7,
          icon: 'replies',
          value: media.replies,
        },
      );
    }

    acc.push({ ...media, analyticsDataList });
    return acc;
  }, []).sort((a, b) => b.timestamp - a.timestamp);
};

export {
  // eslint-disable-next-line import/prefer-default-export
  formateMedias,
  // formateDateToLabelGraph2,
  // reduceDataToGraph2,
};
