import { threadIsSent, threadIsOpen, threadIsMute } from '@/addons/$utils/threadUtilities';

const defaultFilterOptions = [
  { key: 'all', label: 'All' },
  { key: 'received', label: 'Received' },
  { key: 'sent', label: 'Sent' },
  { key: 'tags', label: 'Tags' },
  { key: 'untagged', label: 'Untagged' },
  { key: 'folders', label: 'Contacts' },
  { key: 'unlabeled', label: 'Unlabeled' },
  { key: 'unread', label: 'Unread' },
  { key: 'assigned', label: 'Assigned' },
  { key: 'unassigned', label: 'Unassigned' },
  { key: 'muted', label: 'Muted' },
];

const withoutTagsFilterOptions = [
  { key: 'all', label: 'All' },
  { key: 'received', label: 'Received' },
  { key: 'sent', label: 'Sent' },
  { key: 'folders', label: 'Contacts' },
  { key: 'unlabeled', label: 'Unlabeled' },
  { key: 'unread', label: 'Unread' },
  { key: 'assigned', label: 'Assigned' },
  { key: 'unassigned', label: 'Unassigned' },
  { key: 'muted', label: 'Muted' },
];

const withoutTagsSentsFilterOptions = [
  { key: 'received', label: 'Received' },
  { key: 'folders', label: 'Contacts' },
  { key: 'unlabeled', label: 'Unlabeled' },
  { key: 'unread', label: 'Unread' },
  { key: 'assigned', label: 'Assigned' },
  { key: 'unassigned', label: 'Unassigned' },
  { key: 'muted', label: 'Muted' },
];

const defaultSortOptions = [
  { key: 'asc:timestamp', label: 'Newest' },
  { key: 'desc:timestamp', label: 'Oldest' },
  { key: 'asc:followers', label: 'Most followers' },
];

const metas = {
  inbox: {
    title: 'Inbox',
    filterOptions: withoutTagsFilterOptions,
    defaultFilter: 'received',
    sortOptions: defaultSortOptions,
    defaultSort: 'asc:timestamp',
  },
  assignment: {
    title: 'Assignment',
    // assigned & unassigned removed
    filterOptions: [
      { key: 'all', label: 'All' },
      { key: 'received', label: 'Received' },
      { key: 'sent', label: 'Sent' },
      { key: 'tags', label: 'Tags' },
      { key: 'untagged', label: 'Untagged' },
      { key: 'folders', label: 'Contacts' },
      { key: 'unlabeled', label: 'Unlabeled' },
      { key: 'unread', label: 'Unread' },
      { key: 'muted', label: 'Muted' },
    ],
    defaultFilter: 'all',
    sortOptions: defaultSortOptions,
    defaultSort: 'asc:timestamp',
  },
  importants: {
    title: 'Importants',
    filterOptions: withoutTagsSentsFilterOptions,
    defaultFilter: 'received',
    sortOptions: defaultSortOptions,
    defaultSort: 'asc:timestamp',
  },
  mentions: {
    title: 'Mentions',
    filterOptions: withoutTagsFilterOptions,
    defaultFilter: 'received',
    sortOptions: defaultSortOptions,
    /* Spécial case: sorted by followers in mentions */
    defaultSort: 'asc:followers',
  },
  opportunities: {
    title: 'Opportunities',
    filterOptions: withoutTagsSentsFilterOptions,
    defaultFilter: 'received',
    sortOptions: defaultSortOptions,
    defaultSort: 'asc:timestamp',
  },
  influence: {
    title: 'Influence',
    filterOptions: defaultFilterOptions,
    /* Influence default to 'all' filter */
    defaultFilter: 'all',
    sortOptions: defaultSortOptions,
    defaultSort: 'asc:timestamp',
  },
  all: {
    title: 'All',
    filterOptions: defaultFilterOptions,
    defaultFilter: 'all',
    sortOptions: defaultSortOptions,
    defaultSort: 'asc:timestamp',
  },
  tag: {
    title: 'Tag',
    filterOptions: withoutTagsFilterOptions,
    defaultFilter: 'all',
    sortOptions: defaultSortOptions,
    defaultSort: 'asc:timestamp',
  },
  folder: {
    title: 'Label',
    filterOptions: [
      /**
       * Filters 'contact', 'unlabeled' removed because there is only contact threads here
       */
      { key: 'all', label: 'All' },
      { key: 'received', label: 'Received' },
      { key: 'sent', label: 'Sent' },
      { key: 'tags', label: 'Tags' },
      { key: 'untagged', label: 'Untagged' },
      { key: 'unread', label: 'Unread' },
      { key: 'assigned', label: 'Assigned' },
      { key: 'unassigned', label: 'Unassigned' },
      { key: 'muted', label: 'Muted' },
    ],
    defaultFilter: 'all',
    sortOptions: defaultSortOptions,
    defaultSort: 'asc:timestamp',
  },
};

export const mostRelevantThreadRouterLink = (thread, _is) => {
  const { threadId } = thread;
  const is = (_is || thread.is);
  const query = {};
  const params = {};
  const name = is ? `in.${is}` : 'in.all';
  if (!threadIsOpen(thread)) { query.show = 'closed'; }
  if (threadIsMute(thread)) query.filter = 'muted';
  else if (threadIsSent(thread) && is && metas[is].defaultFilter === 'received') query.filter = 'sent';
  query.tId = threadId;
  return { name, query, params };
};

export default metas;
