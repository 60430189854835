<template>
  <div id="UbuCRMTable">
    <b-table
      class="list-table"
      :data="listFiltered"
      :checked-rows="checkedRows"
      :selected="selectedRow"
      :default-sort="['username', 'asc']"
      :loading="loading"
      checkable
      paginated
      :sticky-header="true"
      :opened-detailed="openedDetails"
      :detailed="true"
      :show-detail-icon="true"
      detail-key="contactId"
      per-page="30"
      pagination-rounded
      hoverable
      pagination-size="is-small"
      @check="$emit('checkRow', $event)"
      @check-all="$emit('checkAllRows', $event)"
      @click="selectRow($event)"
    >
      <b-table-column
        v-slot="{ row }"
        :visible="!!columns.find((name) => name === 'username')"
        field="username"
        label="Username"
        sortable
      >
        <div class="username-table">
          {{ row.username || '-' }}
        </div>
      </b-table-column>
      <b-table-column
        v-slot="{ row: { type, followerCount } }"
        :visible="!!columns.find((name) => name === 'categoryName')"
        field="type"
        label="Type"
        sortable
      >
        <div v-if="followerCount >= 5000">{{ type || '-' }}</div>
        <div v-else>
          <div v-if="type">{{ type }} </div>
          <b-tooltip
            v-else
            :label="missingTypeTooltipLabel"
            position="is-right"
          >
            <div>-</div>
          </b-tooltip>
        </div>
      </b-table-column>
      <b-table-column
        v-slot="{ row: { category } }"
        :visible="!!columns.find((name) => name === 'category')"
        field="category"
        label="Category"
        sortable
      >
        <div>{{ category || '-' }}</div>
      </b-table-column>
      <b-table-column
        v-slot="{ row: { followerCount } }"
        :visible="!!columns.find((name) => name === 'followerCount')"
        field="followerCount"
        label="Followers"
        sortable
      >
        <span v-if="followerCount">{{ separateThousandsBySpace(followerCount) || '-' }}</span>
      </b-table-column>
      <b-table-column
        v-slot="{ row: { engagement } }"
        :visible="!!columns.find((name) => name === 'engagement')"
        field="engagement"
        label="Engagement"
        sortable
      >
        <span v-if="engagement && Number(engagement) >= 0.5">
          {{ Math.round(engagement * 100) / 100 }}%
        </span>
        <span v-else>
          -
        </span>
      </b-table-column>
      <b-table-column
        v-slot="{ row: { language } }"
        :visible="!!columns.find((name) => name === 'language')"
        field="language"
        label="Language"
        sortable
      >
        {{ language ? language : '-' }}
      </b-table-column>
      <b-table-column
        v-slot="{ row: { email } }"
        :visible="!!columns.find((name) => name === 'email')"
        field="email"
        label="Email"
        sortable
      >
        {{ email || '-' }}
      </b-table-column>
      <b-table-column
        v-slot="{ row: { age } }"
        :visible="!!columns.find((name) => name === 'age')"
        field="age"
        label="Age"
        sortable
      >
        {{ age || '-' }}
      </b-table-column>
      <b-table-column
        v-slot="{ row: { gender } }"
        :visible="!!columns.find((name) => name === 'gender')"
        field="gender"
        label="Gender"
        sortable
      >
        {{ gender || '-' }}
      </b-table-column>
      <b-table-column
        v-if="hasShopifyShop"
        v-slot="{ row: { shopifyLinksCount } }"
        :visible="!!columns.find((name) => name === 'shopifyLinksCount')"
        field="shopifyLinkCount"
        label="Affiliations & coupons"
        sortable
      >
        {{ shopifyLinksCount || '-' }}
      </b-table-column>

      <b-table-column
        v-if="hasShopifyShop"
        v-slot="{ row: { shopifyAmountOrders } }"
        :visible="!!columns.find((name) => name === 'shopifyAmountOrders')"
        field="shopifyAmountOrders"
        label="Revenues generated"
        sortable
      >
        {{ shopifyAmountOrders > 0 ? separateThousandsBySpace(shopifyAmountOrders) : '-' }}
      </b-table-column>

      <b-table-column
        v-if="hasShopifyShop"
        v-slot="{ row: { shopifyTotalOrders } }"
        :visible="!!columns.find((name) => name === 'shopifyTotalOrders')"
        field="shopifyTotalOrders"
        label="Orders generated"
        sortable
      >
        {{ shopifyTotalOrders || '-' }}
      </b-table-column>
      <b-table-column
        :visible="!!columns.find((name) => name === 'threadGroup')"
        field="threadGroup"
        label="Tags"
        sortable
      >
        <template v-slot="{ row: { threadGroup } }">
          <UbuCRMTableCustomCell
            v-if="threadGroup.length"
            :groups="threadGroup"
            icon-name="tag"
          />
          <span v-else>
            -
          </span>
        </template>
      </b-table-column>
      <b-table-column
        :visible="!!columns.find((name) => name === 'contactGroup')"
        field="contactGroup"
        label="Labels"
        sortable
      >
        <template v-slot="{ row: { contactGroup } }">
          <UbuCRMTableCustomCell
            v-if="contactGroup.length"
            :groups="contactGroup"
            icon-name="contact"
          />
          <span v-else>
            -
          </span>
        </template>
      </b-table-column>
      <b-table-column
        :visible="!!columns.find((name) => name === 'activeCampaign')"
        field="activeCampaign"
        label="Active Campaigns"
        sortable
      >
        <template v-slot="{ row: { activeCampaign } }">
          <UbuCRMTableCustomCell
            v-if="activeCampaign.length"
            :groups="activeCampaign"
            icon-name="influence"
          />
          <span v-else>
            -
          </span>
        </template>
      </b-table-column>
      <b-table-column
        :visible="!!columns.find((name) => name === 'pastCampaign')"
        field="pastCampaign"
        label="Past Campaigns"
        sortable
      >
        <template v-slot="{ row: { pastCampaign } }">
          <UbuCRMTableCustomCell
            v-if="pastCampaign.length"
            :groups="pastCampaign"
            icon-name="influence"
          />
          <span v-else>
            -
          </span>
        </template>
      </b-table-column>
      <!-- <b-table-column
        :visible="!!columns.find((name) => name === 'pastCampaign')"
        field="lists"
        label="Lists"
        sortable
      >
        <template v-slot="{ row: { lists } }">
          <UbuCRMTableCustomCell
            v-if="lists.length"
            :groups="lists"
            icon-name="crm"
          />
          <span v-else>
            -
          </span>
        </template>
      </b-table-column> -->
      <b-table-column
        v-slot="{ row: { storyMentionCount } }"
        :visible="!!columns.find((name) => name === 'storyMentionCount')"
        field="storyMentionCount"
        label="Story Mention"
        sortable
      >
        {{ storyMentionCount || '-' }}
      </b-table-column>
      <b-table-column
        v-slot="{ row: { postMentionCount } }"
        :visible="!!columns.find((name) => name === 'postMentionCount')"
        field="postMentionCount"
        label="Post Mention"
        sortable
      >
        {{ postMentionCount || '-' }}
      </b-table-column>
      <b-table-column
        v-slot="{ row: { ageAudience } }"
        :visible="!!columns.find((name) => name === 'ageAudience')"
        field="ageAudience.name"
        label="Age audience"
        sortable
      >
        {{ ageAudience ? ageAudience.name : '-' }}
      </b-table-column>
      <b-table-column
        v-slot="{ row: { location } }"
        :visible="!!columns.find((name) => name === 'location')"
        field="location"
        label="Location"
        sortable
      >
        {{ location || '-' }}
      </b-table-column>
      <b-table-column
        v-slot="{ row: { locationAudience } }"
        :visible="!!columns.find((name) => name === 'locationAudience')"
        field="locationAudience.name"
        label="Location audience"
        sortable
      >
        {{ locationAudience ? locationAudience.name : '-' }}
      </b-table-column>
      <b-table-column
        v-slot="{ row: { languageAudience } }"
        :visible="!!columns.find((name) => name === 'languageAudience')"
        field="languageAudience.name"
        label="Language audience"
        sortable
      >
        {{ languageAudience ? languageAudience.name : '-' }}
      </b-table-column>
      <b-table-column
        v-slot="{ row: { genderAudience } }"
        :visible="!!columns.find((name) => name === 'genderAudience')"
        field="genderAudience.name"
        label="Gender audience"
        sortable
      >
        {{ genderAudience ? genderAudience.name : '-' }}
      </b-table-column>
      <b-table-column
        v-slot="{ row: { lastMentionOrTagPost } }"
        :visible="!!columns.find((name) => name === 'lastMentionOrTagPost')"
        field="lastMentionOrTagPost"
        label="Last Mention"
        sortable
      >
        {{ parseTimestamp(lastMentionOrTagPost) || '-' }}
      </b-table-column>
      <b-table-column
        v-slot="{ row: { lastAddedCampaign } }"
        :visible="!!columns.find((name) => name === 'lastAddedCampaign')"
        field="lastAddedCampaign"
        label="Last Campaign add"
        sortable
      >
        {{ parseTimestamp(lastAddedCampaign) || '-' }}
      </b-table-column>
      <b-table-column
        v-slot="{ row: { lastMessageTS } }"
        :visible="!!columns.find((name) => name === 'lastMessageTS')"
        field="lastMessageTS"
        label="Last message"
        sortable
      >
        {{ parseTimestamp(lastMessageTS) || '-' }}
      </b-table-column>
      <b-table-column
        v-slot="{ row: { tiktokUsername } }"
        :visible="!!columns.find((name) => name === 'tiktokUsername')"
        field="tiktokUsername"
        label="Tiktok username"
        sortable
      >
        {{ tiktokUsername || '-' }}
      </b-table-column>
      <b-table-column
        v-slot="{ row: { follows } }"
        :visible="!!columns.find((name) => name === 'follows')"
        field="follows"
        label="Follows"
        sortable
      >
        <div v-if="follows.length">
          <span
            v-for="(account, index) in follows"
            :key="index"
          >
            {{ index > 0 ? ' ,' : '' }}
            {{ `@${account}` }}
          </span>
        </div>
        <span v-else>-</span>
      </b-table-column>
      <b-table-column
        v-slot="{ row: { growth } }"
        field="growth"
        label="Growth"
        sortable
      >
        <b-image
          v-if="typeof growth === 'number'"
          class="arrow-svg"
          :src="growth > 0 ? '../img/arrow-up.svg' : '../img/arrow-down.svg'"
        />
        <span v-else>-</span>
      </b-table-column>

      <template #detail="{ row }">
        <UbuCRMTableDetail
          :row="row"
          :thread-groups-list="threadGroupsList"
          :contact-groups-list="contactGroupsList"
          :active-campaign-list="activeCampaignList"
          :is-generate-report-loading="isGenerateReportLoading"
          :all-referral-concat-discount="allReferralConcatDiscount"
          :has-shopify-shop="hasShopifyShop"
          :is-statistics-loading="isStatisticsLoading"
          show-new-message-button
          @addCustom="$emit('addCustom', $event)"
          @removeCustom="$emit('removeCustom', $event)"
          @generateReport="$emit('generateReport', row)"
          @updateContact="$emit('updateContact', $event)"
          @createThreadGroupAndAssign="$emit('createThreadGroupAndAssign', {
            thread: row.thread, ...$event, username: row.username })"
          @createContactGroupAndAssign="$emit('createContactGroupAndAssign',{
            thread: {
              contactId: row.contactId, channelId: row.channelId
            }, ...$event, username: row.username })"
          @openModalAddLink="$emit('openModalAddLink', $event)"
          @assignCampaignToLink="$emit('assignCampaignToLink', $event)"
          @removeContactToLink="$emit('removeContactToLink', $event)"
          @openNewMessage="$emit('openNewMessage', $event)"
          @hardRefresh="$emit('hardRefresh', $event)"
          @fetchTiktokUser="$emit('fetchTiktokUser', $event)"
          @openModalEditUsernameTiktok="$emit('openModalEditUsernameTiktok', $event)"
        />
      </template>
      <template v-slot:empty>
        <div
          v-if="isCustomList && !loading"
          class="emptyList"
        >
          <h1>It can take up to 24h to get leads into your list ⏳</h1>
          <h2>Then new leads will be automatically added to your list everyday!</h2>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import { timeToNow } from '../../$utils/dates';
import { separateThousandsBySpace } from '../../$utils/formate';

export default {
  name: 'UbuCRMTable',
  props: {
    listFiltered: {
      type: Array,
      default: () => [],
    },
    checkedRows: {
      type: Array,
      default: () => [],
    },
    selectedRow: {
      type: Object,
      default: () => {},
    },
    threadGroupsList: {
      type: Array,
      default: () => [],
    },
    contactGroupsList: {
      type: Array,
      default: () => [],
    },
    activeCampaignList: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    allReferralConcatDiscount: {
      type: Array,
      default: () => [],
    },
    isGenerateReportLoading: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    hasShopifyShop: {
      type: Boolean,
      default: false,
    },
    isCustomList: {
      type: Boolean,
      default: false,
    },
    isStatisticsLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      openedDetails: [],
      activeTab: 0,
      separateThousandsBySpace,
      missingTypeTooltipLabel: 'only for >5k followers',
    };
  },
  methods: {
    parseTimestamp(value) {
      return value ? timeToNow(value) : null;
    },
    selectRow(event) {
      this.$emit('selectRow', event);
      // this.toggleDetails(event);
    },
    // toggleDetails(event) {
    //   if (!this.openedDetails.includes(event.contactId)) {
    //     this.openedDetails.push(event.contactId);
    //   } else {
    //     const i = this.openedDetails.findIndex((id) => id === event.contactId);
    //     this.openedDetails.splice(i, 1);
    //   }
    // },
  },
};
</script>
