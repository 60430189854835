<template>
  <div
    id="UbuCommonImage"
  >
    <UbuInstaImg
      :insta-src="url"
      :has-bucket="hasBucket"
      :bucket-folder="bucketFolder"
    />
    <b-modal
      v-model="isModalOpen"
    >
      <p
        class="image"
        style="max-height: 100vh"
      >
        <UbuInstaImg
          :insta-src="url"
          :has-bucket="hasBucket"
          :bucket-folder="bucketFolder"
        />
      </p>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'UbuCommonImage',
  props: {
    url: {
      type: String,
      required: true,
    },
    hasBucket: {
      type: Boolean,
      default: false,
    },
    bucketFolder: {
      type: String,
      default: 'media',
    },
  },
  data() {
    return {
      isModalOpen: false,
    };
  },
};
</script>
