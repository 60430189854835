<template>
  <b-radio
    class="UbuRadio"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>
<script>
export default {
  name: 'UbuRadio',
};
</script>
<style lang="scss">
    .b-radio.radio input[type=radio] {
        &:checked {
            + .check {
                border-color: var(--geraldine) !important;
                &:hover {
                    border-color: var(--geraldine) !important;
                }
            }
        }
        + .check {
            &:before {
                background-color: var(--geraldine) !important;
            }
        }
        &:not(:disabled) {
            &:hover {
                border-color: var(--geraldine) !important;
            }
            + .check {
                &:hover {
                    border-color: var(--geraldine) !important;
                }
            }
        }
    }
    .b-radio.radio:hover input[type=radio] {
        &:not(:disabled) {
            border-color: var(--geraldine) !important;
            + .check {
                border-color: var(--geraldine) !important;
            }
        }
    }
</style>
