import moment from 'moment';
import CommonProvider from '../providers/common.provider';

export default {
  namespaced: true,

  state: {
    currentThreadId: null,
  },

  getters: {
  },

  actions: {
    readThread({ commit }, thread) {
      const { threadId, channelId } = thread;

      return CommonProvider.setThreadRead({ payload: { channelId, threadId } })
        .then(({ response }) => {
          commit('Thread/READ_THREAD', { threadId, timestamp: response.payload ? response.payload.timestamp : moment().format() }, { root: true });
        })
        .catch((error) => {
          console.warn('🚀 ~ file: TheThreadActionButton.store.js ~ line 24 ~ readThread ~ error', error);
        });
    },
    unmuteThread({ commit }, thread) {
      const { threadId, channelId } = thread;

      return CommonProvider.setThreadMute({ payload: { channelId, threadId, isMuted: 'false' } })
        .then(() => { commit('Thread/MUTE_THREAD', { threadId, isMuted: false }, { root: true }); })
        .catch((error) => {
          console.warn('🚀 ~ file: TheThreadActionButton.store.js ~ line 37 ~ unmuteThread ~ error', error);
        });
    },
    muteThread({ commit }, thread) {
      const { threadId, channelId } = thread;

      return CommonProvider.setThreadMute({ payload: { channelId, threadId, isMuted: 'true' } })
        .then(() => { commit('Thread/MUTE_THREAD', { threadId, isMuted: true }, { root: true }); })
        .catch((error) => {
          console.warn('🚀 ~ file: TheThreadActionButton.store.js ~ line 50 ~ muteThread ~ error', error);
        });
    },
    closeThread({ commit }, thread) {
      const { threadId, channelId } = thread;
      return CommonProvider.setThreadClosed({ payload: { channelId, threadId, closed: 'true' } })
        .then(({ error }) => {
          if (error) return { error };

          commit('Thread/CLOSE_THREAD', { threadId, channelId, isClosed: true }, { root: true });
          return { response: true };
        });
    },
    openThread({ commit }, thread) {
      const { threadId, channelId } = thread;

      return CommonProvider.setThreadClosed({ payload: { channelId, threadId, closed: 'false' } })
        .then(({ error }) => {
          if (error) return { error };

          commit('Thread/CLOSE_THREAD', { threadId, channelId, isClosed: false }, { root: true });
          return { response: true };
        });
    },
    reportUser(_, thread) {
      const { channelId, contactId } = thread;

      return CommonProvider.setUserReport({ payload: { contactId, channelId } })
        .then((response) => {
          console.log('🚀 ~ file: TheThreadActionButton.store.js ~ line 55 ~ .then ~ response', response);
        })
        .catch((error) => {
          console.warn('🚀 ~ file: TheThreadActionButton.store.js ~ line 76 ~ openThread ~ error', error);
        });
    },
  },
  mutations: {
    $UPDATE_CURRENT_THREAD_ID: (state, threadId) => { state.currentThreadId = threadId; },
  },
};
