<template>
  <section id="TheInfluenceAnalytics">
    <div class="dashboard-title p-bottom">
      <h1 class="ubu-title">
        Performance
      </h1>
      <b-tooltip
        class="ml-2 a-self-center"
        :label="tooltipLabel"
        size="is-large"
        position="is-bottom"
        multilined
      >
        <UbuIcon name="info" />
      </b-tooltip>
    </div>

    <div class="flex mb-3">
      <UbuDatePicker
        v-if="currentCampaign"
        v-model="localDateRange"
        active
        :min-date="this.$moment(currentCampaign.created).toDate()"
        @changeDate="fetchAnalytics()"
      />
    </div>

    <template v-if="isConnectedToFb">
      <div class="analyticsGroup">
        <h1 class="ubu-header2">Campaign</h1>
        <div class="analyticsCardGroup">
          <UbuCommonAnalyticsCard
            v-for="(item, index) in influenceAnalytics"
            :key="index"
            :value="item.value"
            :title="item.name"
            :icon="item.icon"
            :color="item.color"
            :size="item.size"
            :loading="item.loading"
          />
        </div>
      </div>

      <div class="analyticsGroup">
        <h1 class="ubu-header2">Account</h1>
        <div class="analyticsCardGroup">
          <UbuCommonAnalyticsCard
            v-for="(item, index) in accountAnalytics"
            :key="index"
            :value="item.value"
            :title="item.name"
            :icon="item.icon"
            :color="item.color"
            :size="item.size"
            :loading="item.loading"
          />
        </div>
      </div>

      <div
        v-if="getterShopifyShop.length"
        class="dashboard-box"
      >
        <div class="analyticsGroup">
          <h1 class="ubu-header2">Shopify</h1>
          <div class="analyticsCardGroup">
            <UbuCommonAnalyticsCard
              v-for="(item, index) in shopifyAnalytics"
              :key="index"
              :value="item.value"
              :title="item.name"
              :icon="item.icon"
              :color="item.color"
              :size="item.size"
              :loading="item.loading"
            />
          </div>
        </div>
      </div>

      <div v-if="getterShopifyShop.length">
        <b-table
          v-if="fetchIsDone && referralLinksConcatDiscountCodes.length"
          :data="referralLinksConcatDiscountCodes"
          :loading="isLoading"
          paginated
          per-page="10"
          pagination-rounded
          pagination-size="is-small"
        >
          <b-table-column
            v-slot="{ row }"
            field="link"
            label="Link"
          >
            <span class="text-geraldine">{{ row.link }}</span>
          </b-table-column>

          <b-table-column
            v-slot="{ row }"
            field="type"
            label="Type"
          >
            {{ row.type }}
          </b-table-column>

          <b-table-column
            v-slot="{ row }"
            field="order"
            label="Revenues"
          >
            {{ calculateTotalAmount(row.order) }} $
          </b-table-column>

          <b-table-column
            v-slot="{ row }"
            field="order"
            label="Orders"
          >
            {{ row.order.length }}
          </b-table-column>

          <b-table-column
            v-slot="{ row }"
            field="contactId"
            label="Influencer"
          >
            {{ findUsernameIntoCampaign(row.contactId) || '-' }}
          </b-table-column>

          <b-table-column
            label="Campaign"
          >
            <UbuIcon
              name="influence"
              filled
              :style="`color: ${currentCampaign.color}`"
            />
          </b-table-column>
        </b-table>
      </div>
    </template>

    <div
      v-else
      class="dashboard-error-content"
    >
      <p>We couldn't load your analytics, please check if your Facebook Channel is connected</p>
      <b-button
        to="/workspace/connection"
        class="dashboard-error-button ubuButton is-primary"
        tag="router-link"
      >
        Connection
      </b-button>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import { catalogAnalytics } from './$catalog/analytics';
import { convertColor } from '../$utils/formate';
// import { catalog } from '../$catalog/analytics';
// import { shortenNumber } from '../$utils/formate';

export default {
  name: 'TheInfluenceAnalytics',
  data() {
    return {
      catalogAnalytics,
      dateFilter: { type: 'simple', value: 1 },
      isLoading: false,
      analyticsData: [],
      tooltipLabel: 'You need to connect your facebook account in order to access your statistics (home=>workspace=>connections)',
      newDateRange: null,
      allStatusNotAllowed: [
        'scheduled',
        'unscheduled',
      ],
    };
  },
  computed: {
    ...mapGetters({
      fetchIsDone: 'Sequence/getterFetchDone',
      getterFbChannel: 'Channel/getterFbChannel',
      campaignActivitiesDetails: 'TheInfluence/getterCampaignActivitiesDetails',
      getterShopifyShop: 'TheWorkspace/getterShopifyShop',
    }),
    localDateRange: {
      get() { return this.newDateRange || this.dateRange; },
      set(value) { this.newDateRange = value; },
    },
    alls() {
      return this.activities
        .filter(({ contactDetail }) => contactDetail)
        .map(({
          activityDetail: {
            status,
          },
        }) => ({
          status,
        }));
    },
    currentCampaign() {
      return this.campaignActivitiesDetails.campaigns
        .find((campaign) => campaign.campaignId === this.$route.params.campaignId);
    },
    activities() {
      return this.currentCampaign ? this.currentCampaign.activities : [];
    },
    influenceAnalytics() {
      return this.analyticsData.filter((a) => a.type === 'campaign').sort((a, b) => a.ordinal - b.ordinal);
    },
    accountAnalytics() {
      return this.analyticsData.filter((a) => a.type === 'account').sort((a, b) => a.ordinal - b.ordinal);
    },
    shopifyAnalytics() {
      return this.analyticsData.filter((a) => a.type === 'shopify').sort((a, b) => a.ordinal - b.ordinal);
    },
    successActivities() {
      return this.activities.filter(({ activityDetail }) => activityDetail.status === 'success');
    },
    mentionCountOnCampaign() {
      return this.activities
        .reduce((acc, activity) => {
          const {
            contactDetail: { mentionCount },
            activityDetail: {
              addedOn,
              ended,
              endDate,
              updated,
            },
          } = activity;

          if (!mentionCount || !mentionCount.length) { return acc; }

          const notEndeds = mentionCount.filter((m) => m > addedOn);
          if (!ended) {
            // eslint-disable-next-line no-param-reassign
            acc += notEndeds.length;
            return acc;
          }
          const when = endDate || updated;
          const endeds = notEndeds.filter((m) => m < when);
          // eslint-disable-next-line no-param-reassign
          acc += endeds.length;
          return acc;
        }, 0);
    },
    influencersPostCount() {
      return this.activities
        .reduce((acc, activity) => {
          const {
            contactDetail: { mentionCount },
            activityDetail: { addedOn },
          } = activity;

          if (mentionCount && mentionCount.length && mentionCount.find((m) => m > addedOn)) {
            // eslint-disable-next-line no-param-reassign
            acc += 1;
            return acc;
          }
          return acc;
        }, 0);
    },
    influencersReach() {
      return this.alls
        .filter(({ status }) => !this.allStatusNotAllowed.includes(status) && status);
    },
    nbDaysFromCampaignCreation() {
      return moment().diff(this.currentCampaign.created, 'days');
    },
    nbDaysFromCampaignEnded() {
      return this.currentCampaign.ended ? moment().diff(this.currentCampaign.updated, 'days') : 0; // key endDate not available every time
    },
    creationDate() {
      if (!this.currentCampaign) return 0;
      if (!this.currentCampaign.created) return 0;
      return this.$moment(this.currentCampaign.created).toDate() || 0;
    },
    endDate() {
      if (!this.currentCampaign) return 0;
      if (!this.currentCampaign.ended) return 0;
      return this.$moment(this.currentCampaign.updated).toDate() || 0;
    },
    dateRange() {
      const start = this.creationDate || this.$moment().toDate();
      const end = this.endDate || this.$moment().toDate();
      return [start, end];
    },
    isConnectedToFb() {
      return this.getterFbChannel && this.getterFbChannel.connected;
    },
    referralLinksConcatDiscountCodes() {
      const { discountCodes, referralLinks } = this.currentCampaign;
      return discountCodes.concat(referralLinks);
    },
    totalRevenuesByReferralLinks() {
      return this.currentCampaign.referralLinks
        .reduce((acc, row) => acc + row.order
          .reduce((ac, order) => ac + (order.amountUsd / 100), 0), 0);
    },
    totalOrderByReferralLinks() {
      return this.currentCampaign.referralLinks.reduce((acc, row) => acc + row.order.length, 0);
    },
    totalRevenuesByDiscountCodes() {
      return this.currentCampaign.discountCodes
        .reduce((acc, row) => acc + row.order
          .reduce((ac, order) => ac + (order.amountUsd / 100), 0), 0);
    },
    totalOrderByDiscountCodes() {
      return this.currentCampaign.discountCodes.reduce((acc, row) => acc + row.order.length, 0);
    },
  },
  watch: {
    fetchIsDone: {
      handler(isDone) {
        if (isDone && this.isConnectedToFb) this.fetchAnalytics();
      },
    },
  },
  mounted() {
    this.fetchLoadingCatalog();
    this.UP_currentCampaignId(this.$route.params.campaignId);
    if (this.fetchIsDone && this.isConnectedToFb) this.fetchAnalytics();
  },
  methods: {
    ...mapActions({
      loadAnalytics: 'TheInfluence/fetchAnalytics',
      UP_currentCampaignId: 'TheInfluence/UP_currentCampaignId',
    }),
    fetchAnalytics() {
      this.isLoading = true;
      this.loadAnalytics({ dateRange: this.localDateRange })
        .then(({ response }) => {
          const analytics = {
            ...response,
            influencersReach: this.influencersReach.length,
            mentionsNumbers: this.mentionCountOnCampaign,
            influencersPostCount: this.influencersPostCount,
            success: this.successActivities.length,
            totalRevenuesByReferralLinks: this.totalRevenuesByReferralLinks,
            totalOrderByReferralLinks: this.totalOrderByReferralLinks,
            totalRevenuesByDiscountCodes: this.totalRevenuesByDiscountCodes,
            totalOrderByDiscountCodes: this.totalOrderByDiscountCodes,
          };

          this.analyticsData = Object.entries(analytics).reduce((acc, row) => {
            const [key, value] = row;
            if (this.catalogAnalytics[key]) {
              acc.push(
                {
                  color: Number(convertColor(this.currentCampaign.color)),
                  ...this.catalogAnalytics[key],
                  value,
                },
              );
            }
            return acc;
          }, []);

          this.isLoading = false;
        });
    },
    fetchLoadingCatalog() {
      this.analyticsData = Object.values(catalogAnalytics).reduce((acc, stat) => {
        acc.push({
          ...stat,
          loading: true,
        });
        return acc;
      }, []);
    },
    calculateTotalAmount(orderList) {
      if (!orderList.length) return 0;
      return orderList.reduce((acc, row) => {
        const { amountUsd } = row;
        // eslint-disable-next-line
        return acc += (amountUsd / 100);
      }, 0);
    },
    findUsernameIntoCampaign(contactId) {
      const findContact = this.currentCampaign.activities
        .find(({ contactDetail }) => contactDetail.contactId === contactId);

      return findContact ? findContact.contactDetail.username : null;
    },
  },
};
</script>

<style lang="scss">
#TheInfluenceAnalytics {
  height: calc(100vh - 75px);
  max-height: calc(100vh - 75px);
  padding: 16px;
  overflow-y: auto;
  & .p-bottom {
    padding-bottom: 20px;
  }
  & .table {
    background-color: transparent;
  }
  & h1 {
    &.ubu-header2 {
      margin-bottom: 24px;
    }
  }
}
</style>
