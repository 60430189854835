export default {
  computed: {
    activities() {
      return this.tableDetail.reverseOrder ? this.activitiesDesc : this.activitiesAsc;
    },
    activitiesAsc() {
      return this.sortedActivitites;
    },
    activitiesDesc() {
      return this.sortedActivitites.reverse();
    },
    sortedActivitites() {
      const activities = [...Object.entries(this.dailyGroupedActivities)];
      return activities.sort((a, b) => this.$moment(a[0]).unix() - this.$moment(b[0]).unix());
    },
    dailyGroupedActivities() {
      return this.sortAndCollect([...this.rows]);
    },
    hasMentions() {
      return [...this.rows]
        .filter((row) => row.mentions.length > 0)
        .sort((a, b) => a.when > b.when);
    },
    others() {
      return [...this.rows]
        .filter((row) => row.mentions.length === 0)
        .sort((a, b) => a.when > b.when);
    },
    tableColor() {
      return this.tableDetail.color ? `table-color-${this.tableDetail.color}` : 'table-color-default';
    },
  },
  methods: {
    sortAndCollect(myData) {
      const reduced = myData
        // .sort((a, b) => (a.when < b.when ? -1 : 1))
        .sort((a, b) => this.$moment(a.when).unix() - this.$moment(b.when).unix())
        .reduce((acc, data) => {
          const formated = this.$moment(data.when).format('YYYY-MM-DD');
          if (!acc[formated]) {
            acc[formated] = [data];
            return acc;
          }
          acc[formated].push(data);
          return acc;
        }, {});

      return reduced;
    },
    isToday(date) {
      const today = this.$moment().startOf('day');
      const dateToCheck = this.$moment(date, 'YYYY-MM-DD').startOf('day');
      return today.isSame(dateToCheck, 'day') ? 'Today' : date;
    },
    labelTooltip(mentionList) {
      const countMentions = mentionList.length;
      return countMentions > 1 ? `${countMentions} mentions` : `${countMentions} mention`;
    },
    toggleOpenedRow(username) {
      if (this.openedDetailRow === username) {
        this.openedDetailRow = '';
      } else {
        this.openedDetailRow = username;
      }
    },
    statusNameFromTitle(title) {
      let status;
      switch (title) {
        case 'In Progress': status = 'inProgess'; break;
        case 'Delivered': status = 'delivered'; break;
        case 'Talking': status = 'talking'; break;
        case 'Scheduled': status = 'scheduled'; break;
        case 'Success': status = 'success'; break;
        case 'Failure': status = 'failure'; break;
        default: break;
      }
      // //console.log('🚀 ~ file: Influence.mixin.js ~ line 79 ~ statusNameFromTitle ~ status', status);
      return status;
    },
  },
};
