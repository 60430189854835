<template>
  <div id="UbuTHShowClosed">
    <UbuButton
      grow
      center
      :active="value === 'opened'"
      simple
      @click="$emit('input', 'opened')"
    >
      Open
    </UbuButton>
    <UbuButton
      grow
      center
      :disabled="!closedAvailables"
      :active="value === 'closed'"
      simple
      @click="$emit('input', 'closed')"
    >
      Closed
    </UbuButton>
    <UbuButton
      grow
      center
      dashed
      :disabled="!closedAvailables"
      :active="value === 'all'"
      @click="$emit('input', 'all')"
    >
      + Show all
    </UbuButton>
  </div>
</template>
<script>
export default {
  name: 'UbuTHShowClosed',
  props: {
    closedAvailables: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Boolean, String],
      default: false,
    },
  },
};
</script>
