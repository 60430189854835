export const threadGroupTemplate = (raw) => {
  const cooked = {
    ...raw,
    channelId: raw.channel,
    customTagId: raw._id,
    threadGroupId: raw._id,
    data: raw.data ? JSON.parse(raw.data) : raw.data,
  };
  console.log('COOKED', raw, cooked);
  return cooked;
};

export default {
  threadGroupTemplate,
};
