<template>
  <div :class="['UbuThreadCard_wrapper']">
    <div
      class="UbuThreadCard_avatar"
      @click="$emit('onBulkSelect', $event)"
    >
      <UbuInstaImg
        class="UbuThreadCard_avatarImg"
        :insta-src="contact.has_bucket_media ? String(contact.contactId) : contact.profile_pic_url"
        :has-bucket="contact.has_bucket_media || false"
        :bucket-folder="'profile_pic'"
        :fallback="null"
        rounded
      />
    </div>
    <div
      class="UbuThreadCard_textBox"
      @click="$emit('onSelect', $event)"
    >
      <div
        :class="[
          'UbuThreadCard_textRow',
          'ubu-text1',
        ]"
      >
        <span>
          {{ displayableName }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UbuCommonSearchCard',
  props: {
    contact: {
      type: Object,
      required: true,
    },
    source: {
      type: String,
      default: 'instagram',
    },
  },
  computed: {
    displayableName() {
      if (!this.contact) return 'Unfoundable name';
      const { full_name: fullName, username } = this.contact;
      return fullName || username;
    },
  },
};
</script>

<style lang="scss" scoped>
$lend: 95%;
$lstart: 60%;

.UbuThreadCard_wrapper {
  cursor: pointer;
  display: flex;
  border-radius: 12px;
  &.UbuThreadCard_wrapperSelected {
    background-color: var(--givry);
    .UbuThreadCard_textBox {
      &::after {
        background: linear-gradient(to right, var(--givry00) $lstart, var(--givry) $lend);
      }
    }
    .UbuThreadCard_avatar {
      .UbuThreadCard_avatarImg {
        .UbuThreadCard_source {
          background-color: var(--givry);
        }
      }
    }
  }

  &:hover:not(.UbuThreadCard_wrapperSelected) {
    background-color: var(--peachcream);
    .UbuThreadCard_textBox {
      &::after {
        background: linear-gradient(to right, var(--peachcream00) $lstart, var(--peachcream) $lend);
      }
    }
    .UbuThreadCard_avatar {
      .UbuThreadCard_avatarImg {
        .UbuThreadCard_source {
          background-color: var(--peachcream);
        }
      }
    }
  }

  .UbuThreadCard_avatar {
    padding: 14px 8px;
    .UbuThreadCard_avatarImg {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: var(--geraldine);
      background-size: cover;
      box-sizing: border-box;
      &.UbuThreadCard_broSelected {
        border: 1px solid var(--mercury);
        background-color: transparent;
      }

      .UbuThreadCard_source {
        position: absolute;
        right: -3px;
        top: 31px; //calcul à refaire
        height: 24px;
        width: 24px;
        border-radius: 50%;
        background-color: var(--soapstone);
        //display: flex;
        display: none;
        justify-content: center;
        align-items: center;
        img {
          width: 12px;
          height: 12px;
        }
      }
    }
  }

  .UbuThreadCard_textBox {
    padding: 12px 8px 12px 0;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-around;
    flex-grow: 1;
    overflow-wrap: break-word;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &::after {
        content: ' ';
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 100%;
        background: linear-gradient(to right, var(--soapstone00) $lstart, var(--soapstone) $lend,);
    }

    .UbuThreadCard_textRow {
      display: flex;
      align-items: center;
      .UbuIcon_wrapper {
        font-size: 15px;
        margin-right: 6px;
      }
    }
  }

  .UbuThreadCard_right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;

    padding: 19px 8px 19px 0;
    .UbuThreadCard_date {
      font-weight: 500;
      font-size: 10px;
      line-height: 13px;
      color: var(--darkgrey)
    }

    .UbuThreadCard_badge {

    }
  }
}
</style>
