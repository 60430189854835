<template>
  <div
    id="UbuMentionsModalAddHashtag"
    class="workspace-modal"
  >
    <div class="modal-header">
      <p class="modal-header-title ubu-title">Create new hashtag</p>

      <UbuIcon
        name="cross"
        clickable
        @click="closeModal()"
      />
    </div>
    <div class="UbuModal_note">
      <h2>NOTE:</h2>
      <p class="ubu-text1">{{ noteText }}</p>
    </div>
    <div class="modal-content">
      <UbuTextField
        v-model="name"
        class="nameInput ubu-text3"
        label="Hastag name"
        placeholder="Hashtag name..."
        :error="errorName"
      />
    </div>
    <div class="modal-footer mt-4">
      <UbuButton
        class="mr-2"
        center
        grow
        @click="closeModal()"
      >
        Cancel
      </UbuButton>
      <UbuButton
        center
        grow
        secondary
        @click="addFromModal()"
      >
        Create
      </UbuButton>
    </div>
  </div>
</template>

<script>

export default {
  name: 'UbuMentionsModalAddHashtag',
  props: {
  },
  data() {
    return {
      name: '',
      noteText: 'Indicate a hashtag name and hundreds of posts/reels/tiktok will be automatically added to your list! 😎',
    };
  },
  computed: {
    errorName() {
      if (!this.name) return '';
      return this.name.match(/^[0-9a-z]+$/) ? '' : 'You can only use letters and numbers';
    },
  },
  methods: {
    addFromModal() {
      if (this.name.match(/^[0-9a-z]+$/)) this.$emit('addFromModal', { name: this.name });
    },
    closeModal() {
      this.$emit('close');
    },
  },
};
</script>
