<template>
  <UbuCommonMessageCard
    :is-me="isMe"
    :date="date"
    is-media
    @deleteMessage="$emit('deleteMessage')"
  >
    <template v-slot:image>
      <UbuInstaImg
        :insta-src="url"
      />
    </template>
  </UbuCommonMessageCard>
</template>

<script>

export default {
  name: 'UbuCommonGif',
  props: {
    isMe: {
      type: Boolean,
      default: false,
    },
    date: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },
};
</script>
