import { snackError, snackSuccess } from '../../$catalog/snackbar';
import { postAsCommentMapper } from '@/addons/$utils/mappers/comment';

export default {
  computed: {
    mediaList() {
      const { filter } = this.$route.params;
      if (!this.getterChannelMedias.medias) {
        return [];
      }
      const medias = this.getterChannelMedias.medias.filter((m) => !m.isMention);
      switch (filter) {
        case 'ad':
          return medias.filter((media) => media.isAd);
        case 'organic':
          return medias.filter((media) => !media.isAd);
        case 'facebook':
          return medias.filter((media) => media.source === 'facebook');
        case 'instagram':
          return medias.filter((media) => media.source === 'instagram');
        case 'mentions':
          return this.getterChannelMedias.medias.filter((m) => m.isMention);
        default:
          return medias;
      }
    },
    currentMediaComments() {
      return this.getterMediaComments.comments;
    },
    currentMedia() {
      const currentMediaFBId = this.getterCurrentMediaFBId;
      return currentMediaFBId
       && this.getterChannelMedias.medias && this.getterChannelMedias.medias.length
        ? this.getterChannelMedias.medias
          .find((media) => media.mediaFBId === this.getterCurrentMediaFBId) : {};
    },
    filteredParentComment() {
      // threadList filtered by input search
      const cardList = this.currentMediaComments;
      if (this.search) {
        return cardList.filter((t) => {
          if (!t || !t.from) return false;
          // eslint-disable-next-line camelcase
          const { from, text } = t;
          const { fullName, username } = from;
          const isMatching = false
            || this.match(username)
            || this.match(fullName)
            || this.match(text);
          return isMatching;
        });
      }
      return cardList;
    },
    threadListFilteredByOpenClose() {
      const isAsc = this.sort === 'asc:timestamp';
      return this.filteredParentComment
        .filter((c) => (this.showClosedThreads ? c.closed : !c.closed))
        .sort((a, b) => (isAsc ? (b.createdAt - a.createdAt) : (a.createdAt - b.createdAt)));
    },
    isClosedThread() {
      return this.selectedThread.origin && this.selectedThread.origin.closed
        ? this.selectedThread.origin.closed : false;
    },
    selectedThread() {
      if (!this.getterCurrentThreadId || !this.getterCurrentMediaFBId) {
        this.changeSelectedReply(null);
        return { origin: {}, messages: [], contact: null };
      }

      const realMedia = this.getterCurrentMedia;
      if (realMedia && this.getterCurrentThreadId === this.getterCurrentMediaFBId) {
        // return { origin: {}, messages: [] };
        const m = postAsCommentMapper(realMedia);
        const maxCmt = 10;
        const comments = this.currentMediaComments
          .slice(0, maxCmt);
        const fakeComment = {
          commentId: m.commentId,
          createdAt: m.createdAt,
          text: m.text,
          from: {
            ...m.from,
          },
          children: comments,
          hasMore: this.currentMediaComments.length > maxCmt,
          canComment: realMedia.canComment,
          isMe: !realMedia.isMention,
          options: { show: false },
          isPost: true,
        };
        this.changeSelectedReply({ ...m, ...fakeComment });
        return {
          origin: m,
          // messages: [...comments] /*[{ ...m, children: comments }]*/,
          // messages: [{...m, children: comments}] /*[{ ...m, children: comments }]*/,
          messages: [fakeComment],
          contact: m.from,
        };
      }
      const threadSelected = this.currentMediaComments
        .find((comment) => comment.commentId === this.getterCurrentThreadId);
      if (!threadSelected) {
        console.warn('Not normal', this.getterCurrentThreadId);
        this.changeSelectedReply(null);
        return { origin: {}, messages: [] };
      }
      const { children, ...parent } = threadSelected;
      this.changeSelectedReply(parent);
      let comments = [parent];
      if (!children) {
        return {
          origin: parent,
          messages: comments,
          contact: parent.from,
        };
      }
      comments = comments.concat(children);
      comments.sort((a, b) => a.createdAt - b.createdAt);

      return {
        origin: parent,
        messages: comments,
        contact: parent.from,
      };
    },
  },
  methods: {
    match(text) {
      if (!text) return false;
      return text.toLowerCase().includes(this.search.toLowerCase());
    },
    selectMedia(media) {
      // clear currentThreadId if media change
      this.UP_currentThreadId(null)
        .then(() => {
          // clear bulks if media change
          this.bulks = [];
          return true;
        })
        .then(() => {
          if (this.getterCurrentMediaFBId) return this.$store.commit('Media/SET_MEDIA_READ', this.getterCurrentMediaFBId, { root: true });
          return true;
        })
        .then(() => this.UP_currentMediaFBId(media.mediaFBId))
        .then(() => {
          this.fetchCommentsDBByChannel(media)
            .then(() => {
              this.UP_mediaLoaded(media.mediaFBId);
              if (media.source === 'instagram') {
                this.reloadMedia({
                  channelId: this.getterFbChannelId,
                  media,
                  replaceImage: false,
                }).then(({ error }) => {
                  if (error && error.statusText === 'deleted') {
                    this.$buefy.snackbar.open({
                      ...snackError,
                      message: 'This media seems being deleted on Instagram, it will be removed on your next refresh.',
                      onAction: null,
                      actionText: null,
                    });
                  }
                  // else if (error) {
                  //   this.$buefy.snackbar.open({
                  //     ...snackError,
                  //     message: 'We can\'t access this media anymore for unknown reasons.',
                  //     onAction: null,
                  //     actionText: null,
                  //   });
                  // }
                });
              }
            });
        });
    },
    onBulkSelect(event) {
      // if event._id yet into bulks remove it else push into bulks
      return this.bulks.find((b) => b.commentId === event.commentId)
        ? this.bulks.splice(this.bulks.indexOf(event), 1)
        : this.bulks.push(event);
    },
    addAllToBulks(selected) {
      this.bulks = [...selected];
    },
    clearBulks() {
      this.bulks = [];
    },
    selectFirstBulk(event) {
      if (event.length) this.bulks.push(event[0]);
    },
    changeOpenCloseFilter(event) {
      this.showClosedThreads = event;
      const first = this.threadListFilteredByOpenClose[0];
      this.UP_currentThreadId((first && first.commentId) || null);
    },
    saveTemplate(event) {
      this.addTemplate({ ...event, channelId: this.getterFbChannelId });
    },

    sendComment(event) {
      this.actionButtonLoading = true;
      const { commentId: targetId, source, from } = this.selectedReply;
      const myComment = {
        targetId,
        channelId: this.getterFbChannelId,
        parentId: this.getterCurrentThreadId,
        postId: this.getterCurrentMediaFBId,
        timestamp: this.$moment().format(),
        message: `${event}`,
        source,
        from,
      };
      this.postComment(myComment).then(() => { this.actionButtonLoading = false; });
    },
    resendComment(event) {
      console.log('🚀 ~ file: TheCommentMixin.js ~ line 118 ~ resendComment ~ event', event);
      this.actionButtonLoading = true;
      this._deleteComment({ comment: event, channelId: this.getterFbChannelId })
        .then(() => this.postComment({
          channelId: event.channelId || this.getterFbChannelId,
          timestamp: this.$moment().format(),
          commentId: event.commentId || this.getterCurrentThreadId,
          parentId: event.parentId || this.getterCurrentThreadId,
          targetId: event.targetId || this.getterCurrentThreadId,
          postId: event.postId || this.getterCurrentMediaFBId,
          message: event.message,
          source: event.source,
        }))
        .then(() => { this.actionButtonLoading = false; });
    },
    sendCommentAndCloseThread(event) {
      const { commentId: targetId, source, from } = this.selectedReply;

      this.actionButtonLoading = true;
      const myComment = {
        channelId: this.getterFbChannelId,
        parentId: this.getterCurrentThreadId,
        postId: this.getterCurrentMediaFBId,
        targetId,
        isMe: true,
        timestamp: this.$moment().format(),
        message: event,
        from,
        source,
      };
      this.postComment(myComment).then(({ error }) => {
        this.actionButtonLoading = false;
        if (error) {
          this.$buefy.snackbar.open({
            ...snackError,
            message: 'Error on Send & Close',
            onAction: () => this.sendCommentAndCloseThread(event),
          });
        } else return this.openClose(this.selectedThread.origin, { closed: true });
        return undefined;
      });
    },
    openClose(thread, event) {
      this.actionButtonLoading = true;
      const myThread = {
        channelId: this.getterFbChannelId,
        ...thread,
        closed: event.closed,
      };

      // quand on open/close on doit auto select le next thread, si pas de next on select le previous, si ni l'un ni l'autre return null
      // on recup l'index du current thread
      const currentIndex = this.threadListFilteredByOpenClose
        .findIndex((t) => t.commentId === thread.commentId);

      // on recup le commentId du next ou du previous thread
      const myList = this.threadListFilteredByOpenClose;
      const nextThreadId = myList[currentIndex + 1] ? myList[currentIndex + 1].commentId : null;
      const previousThreadId = myList[currentIndex - 1] ? myList[currentIndex - 1].commentId : null;
      const threadIdToUse = nextThreadId || previousThreadId;

      this.openCloseThread(myThread)
        .then(() => {
          this.actionButtonLoading = false;
          this.UP_currentThreadId(
            threadIdToUse,
          );
        });
    },
    cancelSelectedReply() {
      this.selectedReply = null;
    },
    changeSelectedReply(comment) {
      this.selectedReply = comment;
    },
    closeMany() {
      this.isBulkPending = true;
      this.closeManyThreads({
        channelId: this.getterFbChannelId,
        closed: true,
        threads: this.bulks,
      })
        .then(() => {
          this.$buefy.snackbar.open({
            ...snackSuccess,
            message: `${this.bulks.length} comments has been closed`,
          });
          this.UP_currentThreadId(
            this.threadListFilteredByOpenClose.length
              ? this.threadListFilteredByOpenClose[0].commentId : null,
          );
          this.bulks = [];
          this.isBulkPending = false;
        }).catch(() => {
          this.$buefy.snackbar.open({
            ...snackError,
            message: `Error while closing ${this.bulks.length} comments`,
          });
          this.isBulkPending = false;
        });
    },
    openMany() {
      this.isBulkPending = true;

      this.closeManyThreads({
        channelId: this.getterFbChannelId,
        closed: false,
        threads: this.bulks,
      })
        .then(() => {
          this.$buefy.snackbar.open({
            ...snackSuccess,
            message: `${this.bulks.length} comments has been opened`,
          });
          this.UP_currentThreadId(
            this.threadListFilteredByOpenClose.length
              ? this.threadListFilteredByOpenClose[0].commentId : null,
          );
          this.bulks = [];
          this.isBulkPending = false;
        }).catch(() => {
          this.$buefy.snackbar.open({
            ...snackError,
            message: `Error while opening ${this.bulks.length} comments`,
          });
          this.isBulkPending = false;
        });
    },
    closeAllComments() {
      this.isBulkPending = true;

      this.closeAll({ media: this.getterCurrentMedia, channelId: this.getterFbChannelId })
        .then(() => {
          this.$buefy.snackbar.open({
            ...snackSuccess,
            message: 'All comments has been closed',
          });
          this.UP_currentThreadId(
            this.threadListFilteredByOpenClose.length
              ? this.threadListFilteredByOpenClose[0].commentId : null,
          );
          this.isBulkPending = false;
        }).catch(() => {
          this.$buefy.snackbar.open({
            ...snackError,
            message: 'Error while closing all comments',
          });
          this.isBulkPending = false;
        });
    },
    rateThread(event) {
      console.log('🚀 ~ file: TheComment.vue ~ line 187 ~ rateThread ~ event', event);
      // waiting routes
    },
    deleteComment(event) {
      this._deleteComment(event)
        .then(({ error }) => {
          if (error) {
            this.$buefy.snackbar.open({
              ...snackError,
              message: 'Error on delete comment',
              onAction: () => this.deleteComment(event),
            });
          }
        });
    },
  },
};
