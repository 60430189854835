<template>
  <div class="card ubuCard">
    <div class="card-header">
      <p class="ubu-card-title">
        <b-radio
          v-model="localRadio"
          name="name"
          :native-value="template.radioValue"
        />
        {{ template.title }}
      </p>
      <b-button
        v-if="template.template && template.template.length"
        rounded
        class="is-transparent"
        @click="$emit('deleteCampaignTemplate', template)"
      >
        <UbuIcon name="trash" />
      </b-button>
    </div>
    <div
      v-if="template.template && template.template.length"
      class="card-content"
    >
      <p style="white-space: pre-line;">
        {{ template.template }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UbuTemplateCard',
  model: {
    prop: 'radio',
  },
  props: {
    template: {
      type: Object,
      required: true,
    },
    radio: {
      type: String,
      required: true,
    },
  },
  computed: {
    localRadio: {
      get() { return this.radio; },
      set(newValue) { this.$emit('input', newValue); },
    },
  },
};
</script>
