<template>
  <section id="TheWorkspaceFolders">
    <div class="workspace-view-header">
      <h1 class="ubu-header1">Contact labels</h1>
      <UbuButton
        :icon="{ name: 'add', filled: true }"
        @click="isCardModalActive = !isCardModalActive"
      >
        Add label
      </UbuButton>
      <b-modal
        v-model="isCardModalActive"
        :width="640"
        scroll="keep"
      >
        <UbuWorkspaceModalAddFolder
          :is-card-modal-active="isCardModalActive"
          :new-custom="newCustom"
          @close="isCardModalActive = false"
          @selectColor="selectColor($event)"
          @addFromModal="addFromModal($event)"
        />
      </b-modal>
    </div>
    <UbuWorkspaceCustoms
      v-for="(label) in labels"
      :key="label.contactGroupId"
      :custom="label"
      :is-updatable="true"
      @selectColor="selectColor($event)"
      @editFromModal="editFromModal($event)"
      @deleteCustom="removeContactLabel($event)"
    />
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { snackSuccess, snackError } from '../$catalog/snackbar';

export default {
  name: 'TheWorkspaceFolders',
  data() {
    return {
      isCardModalActive: false,

      newCustom: {
        title: '',
        type: 'label',
        color: 1,
      },
    };
  },
  computed: {
    ...mapGetters({
      currentChannelId: 'Channel/getterCurrentChannelId',
      labels: 'ContactGroup/getterCurrentContactGroups',
    }),
  },
  methods: {
    ...mapActions({
      addContactLabel: 'TheWorkspace/addContactLabel',
      updateContactLabel: 'TheWorkspace/updateContactLabel',
      deleteContactLabel: 'TheWorkspace/deleteContactLabel',
    }),
    addFromModal(event) {
      this.addContactLabel({ ...event, channelId: this.currentChannelId })
        .then(({ response }) => {
          this.isCardModalActive = false;
          if (response) {
            this.$buefy.snackbar.open({
              message: 'Contact label was added',
              ...snackSuccess,
            });
          } else {
            this.$buefy.snackbar.open({
              message: 'Contact label was not added',
              ...snackError,
            });
          }
        });
    },
    editFromModal(event) {
      this.updateContactLabel({ ...event, channelId: this.currentChannelId })
        .then(({ response }) => {
          if (response) {
            this.$buefy.snackbar.open({
              message: 'Contact label was updated',
              ...snackSuccess,
            });
          } else {
            this.$buefy.snackbar.open({
              message: 'Contact label was not updated',
              ...snackError,
            });
          }
        });
    },
    selectColor(event) {
      this.newCustom.color = event;
    },
    removeContactLabel(event) {
      const deletedContactLabel = {
        ...event,
        deleted: true,
      };
      this.deleteContactLabel({ ...deletedContactLabel, channelId: this.currentChannelId })
        .then(({ response }) => {
          if (response) {
            this.$buefy.snackbar.open({
              message: 'Contact label was deleted',
              ...snackSuccess,
            });
          } else {
            this.$buefy.snackbar.open({
              message: 'Contact label was not deleted',
              ...snackError,
            });
          }
        });
    },
  },
};
</script>
