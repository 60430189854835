<template>
  <div class="workspace-modal">
    <div class="modal-header">
      <p class="modal-header-title ubu-title">Change name</p>

      <UbuIcon
        name="cross"
        clickable
        @click="$emit('close', $event)"
      />
    </div>
    <div class="modal-content">
      <div class="modal-content-input-wrapper">
        <UbuSimpleInput
          v-model="localUser.firstName"
          class="modal-content-input pl-3"
          :focus="true"
          placeholder="First name..."
        />
        <UbuSimpleInput
          v-model="localUser.lastName"
          class="modal-content-input pl-3"
          :focus="false"
          placeholder="Last name..."
        />
      </div>
    </div>
    <div class="modal-footer">
      <UbuButton
        grow
        center
        secondary
        @click="$emit('editFromModal', localUser)"
      >
        Save
      </UbuButton>
    </div>
  </div>
</template>

<script>

export default {
  name: 'UbuWorkspaceModalEditName',
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dropdownOpened: false,
      localUser: {
        ...this.user,
      },
    };
  },
};
</script>
