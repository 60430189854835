<template>
  <div class="UbuContactPanelInfos_wrapper">
    <UbuContactName
      class="UbuContactName"
      :contact="contact"
    />
    <div class="UbuContactPanelInfos_sep" />
    <UbuContactDataCategory
      class="UbuContactDataCategory"
      title="Statistics"
      :current-source="currentSource"
      :is-tiktok-available="isTiktoker"
      :is-tiktok-hidden="isTiktokHidden"
      @changeSource="changeSource($event)"
      @openModalEditUsernameTiktok="$emit('openModalEditUsernameTiktok', $event)"
    >
      <div v-if="!statMissing && currentSource === 'instagram'">
        <UbuContactDataRow
          type="instaLink"
          :username="contact.username"
        />
        <UbuContactDataRow
          emoji="💘"
          :is-loading="isStatisticsLoading"
          :text="`${contact.follower_count.toLocaleString('en') || 0} followers`"
        />
        <UbuContactDataRow
          v-if="contact.engagement"
          emoji="🎯"
          :is-loading="isStatisticsLoading"
          :hide-text="contact.engagement < 0.3"
          :text="`${Math.round(contact.engagement * 100) / 100}% engagement`"
        />
        <UbuContactDataRow
          v-if="contact.average_comment"
          emoji="💬"
          :is-loading="isStatisticsLoading"
          :hide-text="isRefreshAskable"
          :text="`${contact.average_comment} comments per post`"
        />
        <UbuContactDataRow
          v-if="contact.average_like"
          emoji="❤️"
          :is-loading="isStatisticsLoading"
          :hide-text="isRefreshAskable"
          :text="`${contact.average_like} likes per post`"
        />
        <UbuContactDataRow
          v-if="amountGeneratedFromLinks"
          emoji="🛍"
          :is-loading="isStatisticsLoading"
          :text="`${amountGeneratedFromLinks}$ generated`"
        />
        <UbuContactDataRow
          v-if="cleanedPublicEmail"
          emoji="📧"
          :is-loading="isStatisticsLoading"
          :text="`${cleanedPublicEmail}`"
          can-copy
        />
        <b-button
          v-if="isRefreshAskable"
          expanded
          :loading="isStatisticsLoading"
          class="is-rounded is-light"
          @click="hardRefresh()"
        >
          Refresh statistics
        </b-button>
        <b-tooltip
          class="tooltip-generateReport"
          label=">5000 followers & not private"
          position="is-bottom"
        >
          <b-button
            :disabled="!reportIsAskable"
            expanded
            :loading="isGenerateReportLoading === contact.username"
            class="is-rounded is-light"
            @click="$emit('generateReport', {
              channelId: channelId,
              username: contact.username,
              contact
            })"
          >
            Generate report
          </b-button>
        </b-tooltip>
      </div>

      <div
        v-else-if="statMissing && currentSource === 'instagram'"
        class="UbuContactDataCategory_statMissing"
      >
        <span class="UbuContactDataCategory_statMissing-text">
          Couldn’t load stats 😢
        </span>
        <a
          class="UbuContactDataCategory_statMissing-button"
          @click="hardRefresh()"
        >
          Refresh
        </a>
      </div>

      <div v-else-if="currentSource === 'tiktok'">
        <UbuContactDataRow
          type="tiktokLink"
          :username="contact.tiktok_username"
          :tooltip="{
            label: tooltipTikTokLink,
            position: 'is-bottom',
            multilined: true,
          }"
        />
        <UbuContactDataRow
          emoji="💘"
          :is-loading="isStatisticsLoading"
          :text="tiktokFollowerCount"
        />
        <UbuContactDataRow
          emoji="🎯"
          :is-loading="isStatisticsLoading"
          :text="tiktokEngagement"
        />
        <UbuContactDataRow
          emoji="❤️"
          :is-loading="isStatisticsLoading"
          :text="tiktokLikes"
        />
        <b-tooltip
          class="tooltip-generateReport"
          :label="tooltipTikTokChangeUsernameButton"
          position="is-bottom"
          multilined
        >
          <b-button
            expanded
            class="is-rounded is-light"
            @click="$emit('openModalEditUsernameTiktok', $event)"
          >
            Change TikTok username
          </b-button>
        </b-tooltip>
      </div>
    </UbuContactDataCategory>
    <div class="UbuContactPanelInfos_sep" />
    <UbuNoteInput
      v-if="contact.contactPanelNotes"
      class="UbuNoteInput"
      :contact="contact"
      @updateContact="$emit('updateContact', { ...$event, channelId })"
    />
    <div class="UbuContactPanelInfos_sep" />
    <UbuContactDataCategory
      class="UbuContactDataCategory"
      title="Information"
    >
      <UbuContactDataRow
        v-if="contact.emails"
        emoji="💌"
        type="input"
      >
        <input
          v-model="localEmail"
          placeholder="Email..."
          @blur="updateUserEmail($event)"
        >
      </UbuContactDataRow>
      <UbuContactDataRow
        v-if="contact.phones"
        emoji="☎️"
        type="input"
      >
        <input
          v-model="localPhone"
          placeholder="Phone..."
          @blur="updateUserPhone($event)"
        >
      </UbuContactDataRow>
      <UbuContactDataRow
        v-if="contact.websites"
        emoji="🚀"
        type="input"
      >
        <input
          v-model="localWebsite"
          placeholder="Website..."
          @blur="updateUserWebsite($event)"
        >
      </UbuContactDataRow>
      <!--<UbuContactDataRow emoji="📍" type="input">
        <input placeholder="Location...">
      </UbuContactDataRow>
      <UbuContactDataRow emoji="🎂" type="input">
        <input placeholder="Birthday...">
      </UbuContactDataRow>-->
    </UbuContactDataCategory>
    <div class="UbuContactPanelInfos_sep" />
  </div>
</template>

<script>
import UbuContactDataRow from './UbuContactDataRow';
import UbuContactDataCategory from './UbuContactDataCategory';
import UbuNoteInput from './UbuNoteInput';

export default {
  name: 'UbuContactPanelInfos',
  components: {
    UbuContactDataRow,
    UbuContactDataCategory,
    UbuNoteInput,
  },
  props: {
    contact: {
      type: Object,
      default: () => ({}),
    },
    channelId: {
      type: String,
      default: '',
    },
    isGenerateReportLoading: {
      type: String,
      default: null,
    },
    amountGeneratedFromLinks: {
      type: Number,
      default: 0,
    },
    isStatisticsLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      panel: null,
      emailValue: null,
      phoneValue: null,
      websiteValue: null,
      currentSource: 'instagram',
      tooltipTikTokLink: 'If there is any mistake you can change the username by clicking on the button below.',
      tooltipTikTokChangeUsernameButton: 'This account was found by an algorithm, if this is the wrong TikTok account you can change the username by clicking here.',
    };
  },
  computed: {
    contactPk() {
      if (this.contact) return this.contact.pk;
      return null;
    },
    reportIsAskable() {
      return !this.contact.is_private && this.contact?.follower_count > 5000;
    },
    statMissing() {
      if (
        this.contact
        && this.contact.follower_count
        && this.contact.follower_count !== 0
      ) {
        return false;
      }
      return true;
    },
    isRefreshAskable() {
      const engagement = this.contact.engagement || 0;
      return engagement < 0.3 && this.contact?.follower_count > 5000 && !this.contact.is_private;
    },
    isTiktoker() {
      const username = this.contact.tiktok_username;
      if (username && username !== 'NA') return true;
      return false;
    },
    isTiktokHidden() {
      if (this.contact.is_private || this.contact.follower_count < 5000) return true;
      return false;
    },
    tiktokFollowerCount() {
      const followerCount = this.contact?.tiktokUser?.followerCount;
      if (!followerCount) return null;
      return `${followerCount.toLocaleString('en') || 0} followers`;
    },
    tiktokEngagement() {
      const engagement = this.contact?.tiktokUser?.engagement;
      if (!engagement) return null;
      return `${Math.round(engagement * 100) / 100}% engagement`;
    },
    tiktokLikes() {
      const likes = this.contact?.tiktokUser?.heartCount;
      if (!likes) return null;
      return `${likes.toLocaleString('en') || 0} likes`;
    },
    cleanedPublicEmail() {
      if (!this.contact || !this.contact?.public_email) return null;
      const publicEmail = this.contact?.public_email;
      if (publicEmail === 'NA' || publicEmail === 'NA1' || publicEmail === 'NA2' || publicEmail === 'NA3') return null;
      return publicEmail;
    },
    localEmail: {
      get() {
        return this.contact.emails[0];
      },
      set(newValue) {
        this.emailValue = newValue;
      },
    },
    localPhone: {
      get() {
        return this.contact.phones[0];
      },
      set(newValue) {
        this.phoneValue = newValue;
      },
    },
    localWebsite: {
      get() {
        return this.contact.websites[0];
      },
      set(newValue) {
        this.websiteValue = newValue;
      },
    },
  },
  methods: {
    updateUserEmail(event) {
      const myContact = {
        ...this.contact,
        emails: [event.target.value],
      };

      if (event.target.value !== this.contact.emails[0]) {
        this.$emit('updateContact', {
          channelId: this.channelId,
          contact: myContact,
        });
      }
    },
    updateUserPhone(event) {
      const myContact = {
        ...this.contact,
        phones: [event.target.value],
      };

      if (event.target.value !== this.contact.phones[0]) {
        this.$emit('updateContact', {
          channelId: this.contact.channelId,
          contact: myContact,
        });
      }
    },
    updateUserWebsite(event) {
      const myContact = {
        ...this.contact,
        websites: [event.target.value],
      };

      if (event.target.value !== this.contact.websites[0]) {
        this.$emit('updateContact', {
          channelId: this.contact.channelId,
          contact: myContact,
        });
      }
    },
    // generateReport() {
    //   // this.isReportModalActive = !this.isReportModalActive;
    //   this.$emit('generateReport', {
    //     channelId: this.channelId,
    //     username: this.contact.username,
    //   });
    // },
    hardRefresh() {
      this.$emit('hardRefresh', {
        channelId: this.channelId,
        contactId: this.contact.contactId,
      });
    },
    changeSource(source) {
      this.currentSource = source;
      if (source === 'instagram') return;
      if (!this.contact.tiktokUser && this.contact.tiktok_username) this.$emit('fetchTiktokUser', { username: this.contact.tiktok_username, contactId: this.contact.contactId });
    },
  },
};
</script>
