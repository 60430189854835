// import allowedAddons from '../conf/addons';
const allowedAddons = [
  'ui',
  'authenticate',
  'common',
  'contactPanel',
  'inbox',
  'crm',
  'leads',
  'dataStores',
  'documentation',
  'influence',
  'sidebar',
  'workspace',
  'comment',
  'mentions',
  'socket',
  'error',
];

const addonsFolder = require.context('./addons', true, /.js$/);

const addons = addonsFolder.keys()
  .reduce((acc, path) => {
    const [myAddon] = path.split('/').slice(1);
    if (allowedAddons.includes(myAddon) && !acc[myAddon]) {
      // eslint-disable-next-line
      acc[myAddon] = require(`./addons/${myAddon}`).default;
    }
    return acc;
  }, {});

const register = (addonsList, key) => Object.entries(addonsList)
  .reduce((acc, [addonKey, addonValue]) => {
    if (allowedAddons.includes(addonKey) && addonValue[key]) acc.push(addonValue[key]);
    return acc;
  }, []);

const routes = { ...register(addons, 'routes') };

const stores = { ...register(addons, 'stores') };

const lib = { ...register(addons, 'lib') };

const smarts = { ...register(addons, 'smarts') };

export {
  routes,
  stores,
  lib,
  smarts,
};
