<template>
  <div
    id="UbuInfluencePipelineInProgressesTable"
    :class="`pipelineTable multibar column ${tableColor}`"
  >
    <div
      class="pipelineTable_header multibar start"
      @click="$emit('toggleResumeOpen')"
    >
      <p class="start multibar row-vcentered">
        <UbuIcon
          size="20px"
          :name="tableDetail.icon || 'send'"
          :color="tableDetail.color"
          outlined
        />
      </p>
      <p class="pipelineTable_title start">
        {{ tableDetail.title }}
      </p>
      <p class="pipelineTable_counter end">
        {{ rows.length }}
      </p>
    </div>

    <div class="start pipelineTable_content">
      <div
        v-if="hasMentions.length"
        class="pipelineTable_dailySpliter multibar ubu-subtext1"
      >
        <span>
          Mentions
        </span>
      </div>

      <div
        v-for="(row) in hasMentions"
        :key="row.username"
        class="pipelineTable_row start"
      >
        <UbuInfluencePipelineTableRow
          :row="row"
          :opened-detail-row="openedDetailRow"
          :has-detail="tableDetail.hasDetail"
          has-mentions
          :from-status="tableDetail.status"
          @toggleOpenedRow="toggleOpenedRow(row.username)"
          @changeStatus="$emit('changeStatus', $event)"
          @deleteActivity="$emit('deleteActivity', $event)"
        />
      </div>

      <div
        v-if="others.length"
        class="pipelineTable_dailySpliter multibar ubu-subtext1"
      >
        <span>
          Others
        </span>
      </div>

      <div
        v-for="(row) in others"
        :key="row.username"
        class="pipelineTable_row start"
      >
        <UbuInfluencePipelineTableRow
          :row="row"
          :opened-detail-row="openedDetailRow"
          :has-detail="tableDetail.hasDetail"
          :has-mentions="false"
          :from-status="tableDetail.status"
          @toggleOpenedRow="toggleOpenedRow(row.username)"
          @changeStatus="$emit('changeStatus', $event)"
          @deleteActivity="$emit('deleteActivity', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import InfluenceMixin from '../$mixins/Influence.mixin';

export default {
  name: 'UbuInfluencePipelineTableMention',
  mixins: [InfluenceMixin],
  props: {
    rows: {
      type: Array,
      required: true,
    },
    campaignDetail: {
      type: Object,
      required: true,
    },
    tableDetail: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      openedDetailRow: '',
    };
  },
  methods: {
  },
};
</script>
