import { updateDatasIfExists } from '../$utils/dataMapper';
/* eslint-disable max-len */
export default {
  namespaced: true,

  state: {
    templates: {},
  },

  getters: {
    getterTemplates: (state) => state.templates,
  },

  actions: {
  },

  mutations: {
    SET_TEMPLATES(state, newDatas) { state.templates = updateDatasIfExists({ newDatas, actualDatas: state.templates, key: 'templateId' }); },
    REMOVE_TEMPLATE(state, templateId) { delete state.templates[templateId]; },
    RESET_STORES(state) {
      state.templates = {};
    },
  },
};
