<template>
  <div id="UbuCommonMessageList">
    <div
      ref="scroller"
      class="UbuThreadMessage_scroller ubu-scrollbar"
      :style="scrollerStyle"
    >
      <div
        v-for="([day, array], index) of dailyGroupedItems"
        :key="index"
        class="UbuThreadMessage_day"
      >
        <div class="UbuThreadMessage_dayText ubu-subtext1">{{ getDay(day) }}</div>
        <template
          v-if="!isPanelComment"
        >
          <div
            v-for="(item) of array"
            :key="item.id"
            class="UbuThreadMessage_item multibar"
          >
            <UbuCommonMessage
              :item="item"
              :contact="contact"
              @clickToReply="$emit('clickToReply', item)"
              @resend="$emit('resend', $event)"
              @deleteMessage="$emit('deleteMessage', $event)"
              @deleteComment="$emit('deleteComment', $event)"
              @resendComment="$emit('resendComment', $event)"
              @translateMessage="$emit('translateMessage', { item, ...$event })"
            />
          </div>
        </template>
        <!-- {{ array }} -->
        <ul
          v-if="isPanelComment"
        >
          <div class="conversation_container">
            <UbuCommonCommentRecursive
              v-for="(item) in array"
              :key="`cmt-${item.commentId}`"
              :comment="item"
              :nesting="0"
              :level-nesting="levelNesting"
              :show-all="show"
              :selected-id="selectedReply && selectedReply.commentId"
              :options="item.options || options"
              :is-error="item.isError"
              :is-pending="item.isPending"
              @makeConversation="makeConversation(item)"
              @clickToReply="$emit('clickToReply', item)"
              @resend="$emit('resend', $event)"
              @delete="$emit('delete', $event)"
              @markAsRead="$emit('markAsRead', item)"
              @reportComment="$emit('reportComment', item)"
              @translateMessage="$emit('translateMessage', { item, ...$event })"
            />
          </div>
        </ul>
        <!-- @resend="$emit('resend', $event)"
          @translateMessage="$emit('translateMessage', { item, ...$event })" -->
      </div>
      <div
        v-if="!isPanelComment"
        class="UbuThreadMessage_loadMoreSection"
      >
        <UbuButton
          :loading="syncThreadIsLoading"
          icon="assignation"
          @click="$emit('syncThread')"
        >
          Sync messages
        </UbuButton>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import MessageMixin from '../$mixins/MessageMixin';
import { stampToDatetime } from '../../$utils/dates';

// import items from '../stores/data';

export default {
  name: 'UbuCommonMessageList',
  mixins: [MessageMixin],
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    contact: {
      type: null,
      default: () => {},
    },
    syncThreadIsLoading: {
      type: Boolean,
      required: false,
    },
    messageBoxHeight: {
      type: Number,
      default: 130,
    },
    isPanelComment: {
      type: Boolean,
      default: false,
    },
    selectedReply: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      // items,
      levelNesting: 0,
      show: false,
      options: {
        activeGlobale: true,
        delete: true,
        markAsRead: false,
        report: false,
        like: false,
        translation: {
          activeTranslate: true,
          traduction_fr: true,
          traduction_en: true,
          traduction_de: true,
          traduction_es: true,
        },
      },
    };
  },
  computed: {
    dailyGroupedItems() {
      const myItems = this.items;
      // const unmappable = myItems
      //   .filter(({ newMedia, item_type, noteId }) => !newMedia && (!noteId || item_type === 'text'));
      const mapped = myItems
        .filter(({ createdAt, timestamp }) => createdAt || timestamp)
        .map((item) => ({ ...item, when: stampToDatetime(item.createdAt || item.timestamp) }))
        .sort((a, b) => (a.when.valueOf() - b.when.valueOf()))
        .reduce((acc, data) => {
          const formated = this.$moment(data.when).format('YYYY-MM-DD');
          if (!acc[formated]) {
            acc[formated] = [data];
            return acc;
          }
          acc[formated].push(data);
          return acc;
        }, {});

      let clearNote = true;
      return Object.entries(mapped)
        // used to remove notes when notes are before 1rst day with message
        .map(([day, array]) => {
          const messageCount = array.filter((item) => !item.noteId).length;
          if (messageCount > 0) { clearNote = false; }

          return clearNote ? undefined : [day, array];
        })
        .filter((i) => i)
        .sort((a, b) => (b[1][0].when.valueOf() - a[1][0].when.valueOf()));
    },
    scrollerStyle() {
      return `padding-bottom: ${this.messageBoxHeight + 50}px;`;
    },
  },
  mounted() {
    this.computeHeight();
  },
  updated() {
    this.computeHeight();
  },
  methods: {
    scrolltoBottom() {
      this.$refs.scroller.scrollTop = this.$refs.scroller.scrollHeight;
    },
    computeHeight() {
      if (this.$refs.messageBox) this.messageBoxHeight = this.$refs.messageBox.$el.clientHeight;
    },
    getDay(day) {
      return this.$moment(day).calendar(null, {
        sameDay: '[Today] ☀️',
        lastDay: '[Yesterday]',
        lastWeek: '[Last] dddd',
        sameElse: 'YYYY-MM-DD',
      });
    },
    makeConversation(item) {
      // Vue.set(item, 'children', item.children);
    },
    showAll() {
      this.show = !this.show;
    },
  },
};
</script>

<style lang="scss">
#UbuCommonMessageList {
display: flex;
flex-grow: 1;
flex-direction: column;
background-color: var(--seashellpeach);
// position: relative;
// height: calc(100% - 190px);

& .UbuThreadMessage_scroller {
  display: flex;
  flex-direction: column-reverse;
  // flex-direction: column;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  padding-bottom: 200px;
  & .UbuThreadMessage_item {
    display: flex;
    & > * {
      // IMPORTANT
      max-width: 300px;
    }
    & .UbuThreadMessage_note {
      margin: 3px;
    }
    & .UbuThreadMessage_message {
      margin: 4px 16px;
    }
  }
}

  & .UbuThreadMessage_day {
    display: flex;
    flex-direction: column;
    padding-top: 24px;
    & .UbuThreadMessage_dayText {
      text-align: center;
      padding: 16px;
      color: var(--cola60);
    }
    li.item {
      // padding-right: 2em;
    }
  }
}
.UbuThreadMessage_loadMoreSection {
  display: flex;
  justify-content: center;
  padding: 20px;
}
</style>
