/* eslint-disable max-len */
import { get } from 'lodash';
import query from '../../dataStores/stores/query';
import {
  threadIsOpen,
  defineThreadIs,
  threadIsUnread,
} from '../../$utils/threadUtilities';

import InboxProvider from '../providers/Inbox.provider';

export default {
  namespaced: true,

  state: {
    currentThreadId: null,
  },

  getters: {
    /**
     * Mapping de tous les threads avec le minimum required pour pouvoir les rangers dans les différentes box
     */
    getterThreadsMap: (_state, _getters, _rootState, rootGetters) => {
      const {
        'Contact/getterContacts': contacts,
        // 'Contact/getterContactsHasContactGroup': contactsHasContactGroup,
        'Contact/getterContactsHasContactGroupsBis': contactsHasContactGroupBis,
        'ContactGroup/getterContactGroups': contactGroups,
        'Thread/getterThreads': threads,
        // 'Thread/getterThreadsHasThreadGroup': threadsHasThreadGroup,
        'Thread/getterThreadsHasThreadGroupsBis': threadsHasThreadGroupBis,
        'UbuUser/getterUbuUsers': ubuUsers,
        'Campaign/getterCampaigns': _campaigns,
        'Contact/getterContactsHasCampaignsBis': contactsHasCampaigns,
        // 'UbuUser/getterAssignments': assignments,
        'Thread/getterThreadsHasUbuUsers': threadsHasUbuUsers,
        'ThreadGroup/getterThreadGroups': threadGroups,
        'Sequence/getterFetchInbox': fetchDone,
      } = rootGetters;

      if (!fetchDone) return null;
      /** Remove query threads by channel, because stores are now empty after each switches */
      const _threads = Object.keys(threads)
        .reduce((acc, threadId) => {
          const thread = threads[threadId];
          acc[threadId] = {
            ...thread,
            contact: contacts[thread.contactId],
            contactGroups: query.findObjectLite({
              relationTable: contactsHasContactGroupBis,
              dataTable: contactGroups,
              fromId: thread.contactId,
            }),
            threadGroups: query.findObjectLite({
              relationTable: threadsHasThreadGroupBis,
              dataTable: threadGroups,
              fromId: threadId,
            }),
            campaigns: query.findObjectLite({
              relationTable: contactsHasCampaigns,
              dataTable: _campaigns,
              fromId: thread.contactId,
            }),
            assignments: query.findObjectLite({
              relationTable: threadsHasUbuUsers,
              dataTable: ubuUsers,
              fromId: threadId,
            })/* .map(((ubuUser) => ({ ...ubuUser, assignments: assignments[ubuUser.ubuUserId] }))) */,
            messages: [],
            notes: [],
            isUnread: threadIsUnread(thread),
          };
          return acc;
        }, {});
      // console.log('Assignments threads', _threads);
      // console.log('Fetch inbox is done prob', _threads);
      return _threads;
    },

    /**
     * Mapping des threads map box et group
     */
    getterThreadsDatas: (_state, getters, _rootState, rootGetters) => {
      const baseValue = {
        inbox: { opened: [], closed: [] },
        importants: { opened: [], closed: [] },
        opportunities: { opened: [], closed: [] },
        mentions: { opened: [], closed: [] },
        influence: { opened: [], closed: [] },
        assignment: { opened: [], closed: [] },
        all: { opened: [], closed: [] },
        threadGroups: {},
        contactGroups: {},
      };
      const {
        getterThreadsMap: threadMap,
      } = getters;
      const {
        'Authenticate/getterAuthenticate': me,
      } = rootGetters;
      const _threads = Object.keys(threadMap || {})
        .reduce((acc, threadId) => {
          const thread = threadMap[threadId];
          thread.is = defineThreadIs(thread, me.ubuUserId);
          if (threadIsOpen(thread)) {
            if (thread.is) { acc[thread.is].opened.push(thread); }
            acc.all.opened.push(thread);
          } else {
            if (thread.is) { acc[thread.is].closed.push(thread); }
            acc.all.closed.push(thread);
          }
          thread.threadGroups.forEach((tg) => {
            if (!acc.threadGroups[tg.threadGroupId]) acc.threadGroups[tg.threadGroupId] = [];
            acc.threadGroups[tg.threadGroupId].push(thread);
          });
          thread.contactGroups.forEach((cg) => {
            if (!acc.contactGroups[cg.contactGroupId]) acc.contactGroups[cg.contactGroupId] = [];
            acc.contactGroups[cg.contactGroupId].push(thread);
          });
          return acc;
        }, baseValue);
      // console.log('Mapped', _threads);
      return _threads;
    },

    getterCurrentThreadId: (state) => state.currentThreadId,

    /**
     * Mapping du thread en cours, on récupère le thread déjà mappé dans threadsMap (donc avec threadGroups, contactGroups et contact)
     * Auquel on rajoute les messages et les notes.
     */
    getterCurrentThread: (_state, getters, rootState, rootGetters) => {
      const {
        getterCurrentThreadId: currentThreadId,
        // getterThreadsMap: threadsMap,
      } = getters;
      if (!currentThreadId) { return null; }
      const {
        'Message/getterMessages': messages,
        'Contact/getterContacts': contacts,
        'Thread/getterThreads': threads,
        'Note/getterNotes': notes,
        'Campaign/getterCampaigns': _campaigns,
        'Contact/getterContactsHasCampaignsBis': contactsHasCampaigns,
        // 'Activity/getterActivities': activities,
        // 'Activity/getterActivityHasCampaign': getterContactsHasActivitiesBis,
        // 'Thread/getterThreadsHasMessages': threadsHasMessages,
        'Thread/getterThreadsHasMessagesBis': threadsHasMessagesBis,
        // 'Thread/getterThreadsHasNotes': threadsHasNotes,
        'Thread/getterThreadsHasNotesBis': threadsHasNotesBis,
        'UbuUser/getterUbuUsers': ubuUsers,
        'Contact/getterContactsHasContactGroupsBis': contactsHasContactGroupBis,
        'ContactGroup/getterContactGroups': _contactGroups,
        'Thread/getterThreadsHasThreadGroupsBis': threadsHasThreadGroupBis,
        'ThreadGroup/getterThreadGroups': _threadGroups,
        'Thread/getterThreadsHasUbuUsers': threadsHasUbuUsers,
      } = rootGetters;

      const thread = threads[currentThreadId];
      // console.log('🚀 ~ file: TheInbox.store.js ~ line 142 ~ thread', thread);

      if (!thread) { return null; }

      const contact = contacts[thread.contactId];
      const campaigns = query.findObjectLite({
        relationTable: contactsHasCampaigns,
        dataTable: _campaigns,
        fromId: thread.contactId,
      });
      const contactGroups = query.findObjectLite({
        relationTable: contactsHasContactGroupBis,
        dataTable: _contactGroups,
        fromId: thread.contactId,
      });
      const threadGroups = query.findObjectLite({
        relationTable: threadsHasThreadGroupBis,
        dataTable: _threadGroups,
        fromId: thread.threadId,
      });
      const response = {
        ...thread,
        contact,
        campaigns,
        contactGroups,
        threadGroups,
        isUnread: threadIsUnread(thread),
        assignments: query.findObjectLite({
          relationTable: threadsHasUbuUsers,
          dataTable: ubuUsers,
          fromId: currentThreadId,
        }),
        messages: query.findObjectLite({
          relationTable: threadsHasMessagesBis,
          dataTable: messages,
          fromId: thread.threadId,
        })
          .map((message) => ({
            ...message,
            ubuUser: ubuUsers[message.ubu_user],
          })),
        notes: query.findObjectLite({
          relationTable: threadsHasNotesBis,
          dataTable: notes,
          fromId: thread.threadId,
        })
          .map((note) => {
            const myNote = { ...note };
            if (!myNote.text) {
              myNote.text = 'BUG';
            }
            if (myNote.ubu_user) {
              myNote.ubuUser = ubuUsers[myNote.ubu_user];
            }
            if (myNote.text && myNote.text[0] === '{') {
              const { text } = JSON.parse(myNote.text);
              myNote.text = text;
            }
            return myNote;
          }),
      };
      return response;
    },

    getterThreadGroupsWithThreads: (_state, getters, _rootState, rootGetters) => {
      const {
        'ThreadGroup/getterThreadGroups': threadGroups,
      } = rootGetters;
      const {
        getterThreadsDatas: threadsDatas,
      } = getters;
      return Object.keys(threadGroups)
        .map((threadGroupId) => ({
          ...threadGroups[threadGroupId],
          threads: (threadsDatas && threadsDatas.threadGroups[threadGroupId]) || [],
        }));
    },
    getterContactGroupsWithThreads: (_state, getters, _rootState, rootGetters) => {
      const {
        'ContactGroup/getterContactGroups': contactGroups,
      } = rootGetters;
      const {
        getterThreadsDatas: threadsDatas,
      } = getters;
      return Object.keys(contactGroups)
        .map((contactGroupId) => ({
          ...contactGroups[contactGroupId],
          threads: (threadsDatas && threadsDatas.contactGroups[contactGroupId]) || [],
        }));
    },
  },

  actions: {
    sequence() {
      return Promise.resolve()
        .then(() => this.dispatch('Sequence/fetchInbox'))
        .then((ret) => console.log('Return TheInboxSequence', ret));
    },

    /**
     * Ce que j'appel "lastTts" est une propriété dans le last permanent item qui va donner la priorité sur le tri sur une date plutôt que le vrai timestamp du last permanent.
     * @see addons/$utils/threadUtilities.js => getLastTimestamp
     * Le tri se base donc sur ce .lastTts avant le .timestamp du last permanent pour permettre de garder la position d'un thread dans la liste et éviter qu'il soit retrié
     * Cela permet entre autre que lorsqu'on envoie un message ou qu'on en reçoit un sur le thread en cours, d'éviter qu'il se repositionne automatiquement
     * et permettre que le "send & close & select next" ne selectionne pas le second thread (car le thread serait repositionné en première position)
     * le lastTts est mis a jours dans:
     * @see addons/common/stores/TheThreadPanel.store.js => sendMessage
     * @see addons/dataStores/stores/Thread.store.js => SOCKET_newThread
     * On veut, lorsqu'on change de folder, réinitialiser cette donnée pour avoir le vrai tri
     */
    resetLastTts({ commit, rootGetters }) {
      const {
        'Thread/getterThreads': threads,
      } = rootGetters;

      const withLastTtsThreads = Object.values(threads)
        .filter((thread) => thread.last_permanent_item && thread.last_permanent_item.lastTts)
        .map((thread) => thread.threadId);
      if (withLastTtsThreads.length) {
        commit('Thread/REMOVE_LAST_TTS_OF_THREADS', { threadIds: withLastTtsThreads }, { root: true });
      }
    },

    checkLastPermanent({ commit }, { thread }) {
      // console.log('🚀 ~ file: TheInbox.store.js ~ line 371 ~ checkLastPermanent ~ thread', thread);
      const {
        channelId, threadId, contactId, messages,
      } = thread;
      // const { item_id: itemId } = lastPermanent;
      const [lastMessage] = messages.sort((a, b) => Number(b.timestamp) - Number(a.timestamp));
      const _lastPermanentTimestamp = get(thread, 'last_permanent_item.timestamp');
      const shouldCheck = !lastMessage || !lastMessage.timestamp
      || !_lastPermanentTimestamp
      || _lastPermanentTimestamp > lastMessage.timestamp;

      if (!shouldCheck) return undefined;

      return InboxProvider.syncItemsWithLastPermanent({
        channelId, threadId, itemId: null, count: 10,
      })
        .then(({ response }) => {
          if (response && response.length) {
            const mappedItems = response.map((item) => ({
              ...item,
              // ...tryParseJson(item.item),
              messageId: item.item_id,
              contactId,
              threadId,
            }));
            commit('Message/SET_MESSAGES', mappedItems, { root: true });
            // commit('Thread/SET_THREADS_HAS_MESSAGES', mappedItems.map((m) => ({ messageId: m.messageId, threadId })), { root: true });
            commit('Thread/ADD_MESSAGES_TO_THREAD', { threadId, messageIds: mappedItems.map(({ messageId }) => messageId) }, { root: true });
          }
        });
    },

    $update_currentThreadId({ commit }, { newThread }) {
      // console.log('🚀 ~ file: TheInbox.store.js ~ line 261 ~ $update_currentThreadId ~ newThread', newThread);
      // if (oldThread) {
      //   const [lastMessage] = oldThread.messages.sort((a, b) => Number(b.timestamp) - Number(a.timestamp));
      //   if (String(oldThread.last_permanent_item.item_id) !== String(lastMessage.item_id)) {
      //     commit('Thread/UPDATE_LAST_PERMANENT_ON_THREAD', { threadId: oldThread.threadId, newDatas: { ...lastMessage } }, { root: true });
      //   }
      // }
      if (!newThread) { commit('$UPDATE_CURRENT_THREAD_ID', null); } else {
        commit('$UPDATE_CURRENT_THREAD_ID', newThread.threadId);
      }
    },
    sendMessage({ commit }, { channelId, contactId, message }) {
      return InboxProvider
        .sendMessage({ channelId, contactId, message })
        .then(({ response, error }) => {
          console.log('🚀 ~ file: TheInbox.store.js ~ line 310 ~ .then ~ response', response);
          if (error) return { error };
          // console.log('🚀 ~ file: TheInbox.store.js ~ line 321 ~ .then ~ response', response);
          const newMessage = {
            [response.item_id]: {
              ...response,
              contactId,
              threadId: response.thread_id,
              messageId: response.item_id,
              isMe: true,
              uniType: 'isText',
            },
          };
          commit('Message/SET_MESSAGES', newMessage, { root: true });
          commit('Thread/ADD_MESSAGES_TO_THREAD', { threadId: response.thread_id, messageIds: [response.item_id] }, { root: true });
          commit('Thread/UPDATE_LAST_PERMANENT_ON_THREAD', {
            threadId: response.thread_id,
            newDatas: {
              ...response,
              contactId,
              threadId: response.thread_id,
              messageId: response.item_id,
              isMe: true,
              uniType: 'isText',
            },
          }, { root: true });

          return { response };
        });
    },
  },
  mutations: {
    $UPDATE_CURRENT_THREAD_ID: (state, threadId) => { state.currentThreadId = threadId; },
  },
};
