<template>
  <router-link
    class="UbuTabLink_wrapper"
    v-bind="$attrs"
  >
    <slot />
  </router-link>
</template>
<script>
export default {
  name: 'UbuTabLink',
};
</script>
<style lang="scss" scoped>
.UbuTabLink_wrapper {
  padding-bottom: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-transform: initial;
  text-decoration: none;
  position: relative;
  opacity: .2;
  color: var(--cloudburst);

  &::after {
    position: absolute;
    content: '';
    width: 0;
    height: 4px;
    bottom: 0;
    left: 0;
    border: 2px;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 14px;
    background: var(--geraldine);
    border-radius: 14px;
    transition: width .2s linear;
  }
  &.router-link-active {
    font-weight: bold;
    color: var(--geraldine);
    opacity: 1;
    &::after {
      width: 100%;
    }
  }
}
</style>
