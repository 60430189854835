<template>
  <div
    :class="['UbuMenu_wrapper', { 'isReduced': isReduced }]"
  >
    <UbuSidebarTopbar
      v-if="!isReduced"
      :navigation="navigation"
    />
    <div
      v-else
      :class="[
        'UbuMenu_burgerButton',
        { UbuMenu_burgerButtonOpened: isSubMenuVisible },
      ]"
      @click="isSubMenuVisible = !isSubMenuVisible"
    >
      <span />
      <span />
      <span />
      <span />
    </div>
    <UbuSidebarChannelSelector
      :is-reduced="isReduced"
      :channels="channels"
      :current-channel="currentChannel"
      :current-channel-id="currentChannelId"
      style="margin: 16px 0"
      @switchChannel="switchChannel({
        channel: $event,
        isComment: !!$route.meta.requireFacebookData
      })"
    />

    <b-progress
      v-if="progressBar < 100"
      :value="progressBar"
      type="is-success"
      size="is-medium"
      show-value
    >
      Loading: {{ progressBar }}%
    </b-progress>

    <div
      v-if="menuItemsFirstColumn.length"
      :style="!isReduced ? 'opacity: 0;' : ''"
      class="ubu-divider"
    />
    <div
      v-if="menuItemsFirstColumn.length"
      class="UbuMenu_menu"
    >
      <UbuMenuButton
        v-for="(item, index) in menuItemsFirstColumn"
        :key="index"
        class="UbuMenu_menuItem"
        :to="item.href"
        :is-reduced="isReduced"
        :icon="item.icon"
        :disabled="item.disabled"
        v-bind="{ important: item.hasUnread, badge: item.count }"
      >
        {{ item.text }}
      </UbuMenuButton>
    </div>

    <div
      :style="!isReduced ? 'opacity: 0;' : ''"
      class="ubu-divider"
    />
    <div class="UbuMenu_menu">
      <UbuMenuButton
        v-for="(item, index) in menuItems"
        :key="index"
        class="UbuMenu_menuItem"
        :to="item.href"
        :is-reduced="isReduced"
        :icon="item.icon"
        :disabled="item.disabled"
        :tooltip="item.tooltip"
        :important="item.hasUnread || false"
        :badge="item.count || 0"
        :info-text="item.infoText"
      >
        {{ item.text }}
      </UbuMenuButton>
    </div>
    <slot :isReduced="isReduced" />
    <div
      :class="['UbuMenu_subMenu', { UbuMenu_subMenuOpened: isSubMenuVisible }]"
      :style="!isReduced ? 'opacity: 0;' : ''"
    >
      <UbuMenuButton
        v-for="(item, index) in navigation"
        :key="index"
        class="UbuMenu_subMenu-item"
        :to="item.to"
        :icon="item.icon"
      >
        {{ item.text }}
      </UbuMenuButton>
    </div>
    <!-- <b-button
      id="UbuMenuResizeButton"
      :class="[{ isReduced: isReduced }]"
      @click="UP_sidebarState(!isReduced)"
    >
      <UbuIcon
        size="16px"
        name="expand"
        outlined
      />
    </b-button> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TheSidebar',
  props: {
    menuItemsFirstColumn: {
      type: Array,
      default: () => [],
    },
    menuItems: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isSubMenuVisible: false,
    };
  },
  computed: {
    ...mapGetters({
      width: 'TheSidebar/getterWidth',
      isReduced: 'TheSidebar/getterIsReduced',
      navigation: 'TheSidebar/getterNavigation',
      currentChannelId: 'Channel/getterCurrentChannelId',
      currentChannel: 'Channel/getterCurrentChannel',
      channels: 'Channel/getterChannels',

      isFetchedInitial: 'Sequence/getterFetchInitial',
      isFetchedCampaigns: 'Sequence/getterFetchCampaigns',
      // isFetchedMedias: 'Sequence/getterFetchMedias',
      isFetchedThreads: 'Sequence/getterFetchThreads',
      isFetchedThreadsDb: 'Sequence/getterFetchThreadsDb',
      isFetchedThreadsDBLabeled: 'Sequence/getterFetchThreadsDBLabeled',
      isFetchedThreadsDBClosed: 'Sequence/getterFetchThreadsDBClosed',
      isFetchedInbox: 'Sequence/getterFetchInbox',
      isFetchedContacts: 'Sequence/getterFetchContacts',
      isFetchedActions: 'Sequence/getterFetchActions',
      isFetchedDone: 'Sequence/getterFetchDone',
    }),
    progressBar() {
      let value = 1;
      /* eslint-disable no-multi-spaces */
      if (this.isFetchedInitial)          value += 9;
      if (this.isFetchedThreads)          value += 15;
      if (this.isFetchedThreadsDb)        value += 20;
      if (this.isFetchedThreadsDBLabeled) value += 30;
      if (this.isFetchedThreadsDBClosed)  value += 10;
      if (this.isFetchedCampaigns)        value += 5;
      if (this.isFetchedContacts)         value += 5;
      if (this.$route.matched[0].path === '/influence' && this.isFetchedActions)       value += 10;
      if (this.isFetchedDone)             value = 100;
      /* eslint-enable no-multi-spaces */
      return value;
    },
  },
  methods: {
    ...mapActions({
      UP_sidebarState: 'TheSidebar/UP_sidebarState',
      switchChannel: 'Channel/switchChannel',
    }),
  },
};
</script>

<style lang="scss" scoped>
.UbuMenu_wrapper {
  background-color: var(--givry);
  display: flex;
  flex-direction: column;
  padding: 16px;
  min-width: 240px;
  position: relative;
  overflow-y: auto;
  width: 240px;
  overflow-x: hidden;
  max-height: 100vh;

  &.isReduced {
    min-width: 90px;
  }
  // & #UbuMenuResizeButton {
  //   &:active, &:focus {
  //     box-shadow: none;
  //   }
  // }
  // &:hover {
  //   & #UbuMenuResizeButton {
  //     position: absolute;
  //     right: -25px;
  //     top: calc(50vh - 20px);
  //     background-color: var(--givry);
  //     border: none;
  //     border-radius: 8px;
  //     rotate: 90deg;
  //     padding-top: 0;
  //     padding-bottom: 0;
  //     z-index: 50;
  //     &.isReduced {
  //       rotate: 270deg;
  //     }
  //   }
  // }
  // &:not(:hover) {
  //   & #UbuMenuResizeButton {
  //     display: none;
  //   }
  // }
  .UbuMenu_more{
    height: 34px;
    width: 34px;
  }
  .UbuMenu_subMenu {
    width: 0px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0px;
    z-index: 10;
    background-color: var(--seashellpeach);
    box-shadow: 4px 0px 0px rgba(0, 0, 0, 0.03);
    overflow: hidden;
    padding: 24px 0;
    transition: all 0.2s ease-in-out;
    box-sizing: content-box;
    .UbuMenu_subMenu-item {
      margin: 4px 0;
    }
    &.UbuMenu_subMenuOpened {
      padding: 24px 12px;
      right: -204px;
      width: 180px;
    }
  }

  .UbuMenu_menu {
    margin: 12px 0;
    .UbuMenu_menuItem {
      margin: 4px 0;
    }
  }

  .UbuMenu_burgerButton {
    width: 17px;
    height: 14px;
    display: block;
    position: relative;
    margin: 10px 0;
    align-self: center;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    span {
      display: block;
      position: absolute;
      height: 1px;
      width: 100%;
      background: var(--cloudburst);
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.25s ease-in-out;
      transition: 0.25s ease-in-out;
      &:nth-child(1) {
        top: 0px;
      }
      &:nth-child(2),
      &:nth-child(3) {
        top: 6px;
      }
      &:nth-child(4) {
        top: 12px;
      }
    }
    &.UbuMenu_burgerButtonOpened {
      span {
        &:nth-child(1) {
          top: 18px;
          width: 0%;
          left: 50%;
        }
        &:nth-child(2) {
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
        }
        &:nth-child(3) {
          -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
        }
        &:nth-child(4) {
          top: 18px;
          width: 0%;
          left: 50%;
        }
      }
    }
  }
}
</style>
