<template>
  <div
    v-if="isConnectedToFb"
    id="TheMentionsAnalyticsInbox"
  >
    <UbuAnalyticsDatepicker
      class="mb-5"
      @changeDate="fetchAnalytics($event)"
    />

    <b-message
      v-if="analyticsAvailableSince"
      type="is-danger"
    >
      <p class="ubu-text3">
        Oldest record start from  {{ formatAnalyticsAvailableSinceToDate }},
        when you first connected to Ubu.
      </p>
    </b-message>

    <template v-if="reducedAnalyticsData">
      <div class="analyticsGroup">
        <h1 class="ubu-header2">Overview</h1>
        <div class="analyticsCardGroup">
          <UbuCommonAnalyticsCard
            v-for="(item, index) in reducedAnalyticsData.overview"
            :key="index"
            :value="item.value"
            :title="item.name"
            :icon="item.icon"
            :color="item.color"
            :size="item.size"
            :loading="item.loading"
          />
        </div>
      </div>

      <div
        v-if="reducedAnalyticsData.tags.length"
        class="analyticsGroup"
      >
        <h1 class="ubu-header2">Tags</h1>
        <div class="analyticsCardGroup">
          <UbuCommonAnalyticsCard
            v-for="(item, index) in reducedAnalyticsData.tags"
            :key="index"
            :value="item.value"
            :title="item.name"
            :icon="item.icon"
            :color="item.color"
            :size="item.size"
            :loading="item.loading"
            icon-filled
          />
        </div>
      </div>

      <div
        v-if="reducedAnalyticsData.assignments.length"
        class="analyticsGroup"
      >
        <h1 class="ubu-header2">Assignments</h1>
        <div class="analyticsCardGroup">
          <UbuCommonAnalyticsCard
            v-for="(item, index) in reducedAnalyticsData.assignments"
            :key="index"
            :value="item.value"
            :title="item.name"
            :icon="item.icon"
            :color="item.color"
            :size="item.size"
            :loading="item.loading"
          />
        </div>
      </div>
    </template>
  </div>

  <div
    v-else
    class="dashboard-error-content"
  >
    <p>We couldn't load your analytics, please check if your Facebook Channel is connected</p>
    <b-button
      to="/workspace/connection"
      class="dashboard-error-button ubuButton is-primary"
      tag="router-link"
    >
      Connection
    </b-button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { analyticsCatalog } from './$catalog/analytics';

export default {
  name: 'TheMentionsAnalyticsInbox',
  data() {
    return {
      selectedRange: { type: 'simple', subtype: 'currentWeek' },
      isLoading: false,
      analyticsAvailableSince: null,
      reducedAnalyticsData: null,
    };
  },
  computed: {
    ...mapGetters({
      fetchIsDone: 'Sequence/getterFetchDone',
      getterFbChannel: 'Channel/getterFbChannel',
      getterUbuUsers: 'UbuUser/getterUbuUsers',
      getterThreadGroups: 'ThreadGroup/getterThreadGroups',
    }),
    isConnectedToFb() {
      return this.getterFbChannel && this.getterFbChannel.connected;
    },
    formatAnalyticsAvailableSinceToDate() {
      return this.analyticsAvailableSince
        ? this.$moment(this.analyticsAvailableSince).format('YYYY-MM-DD')
        : null;
    },
  },
  watch: {
    fetchIsDone(isDone) {
      if (isDone && this.isConnectedToFb) {
        this.fetchAnalytics(this.selectedRange);
      }
    },
  },
  mounted() {
    this.fetchLoadingCatalog();
    if (this.fetchIsDone && this.isConnectedToFb) {
      this.fetchAnalytics(this.selectedRange);
    }
  },
  methods: {
    ...mapActions({
      loadAnalyticsInbox: 'TheMentions/loadAnalyticsInbox',
    }),
    fetchAnalytics(range) {
      this.fetchLoadingCatalog();
      this.isLoading = true;
      this.analyticsAvailableSince = null;

      this.loadAnalyticsInbox(range)
        .then((response) => {
          const {
            firstRangeDate,
            // secondRangeDate,
            firstRangeDate: { initialTimestamp },
          } = response;
          const suffixTag = '_(tag)';

          if (initialTimestamp) {
            this.analyticsAvailableSince = initialTimestamp;
          }

          this.reducedAnalyticsData = Object.entries(firstRangeDate.data)
            .reduce((acc, row) => {
              const [key, value] = row;
              // const valueSecondRange = secondRangeDate.data[key];

              if (analyticsCatalog[key] && analyticsCatalog[key].type === 'inbox') {
                const isOverview = analyticsCatalog[key].subtype.find(({ type: t }) => t === 'overview');

                if (isOverview) {
                  acc.overview.push({
                    ...analyticsCatalog[key],
                    value,
                    size: isOverview.size,
                    ordinal: isOverview.ordinal,
                  });

                  acc.overview = acc.overview.sort((a, b) => a.ordinal - b.ordinal);
                  return acc;
                }
              }
              if (key.includes(suffixTag)) {
                const nameWithoutSuffixTag = key.replace(suffixTag, '');
                const ubuUser = this.getterUbuUsers[nameWithoutSuffixTag];
                if (ubuUser) {
                  acc.assignments.push({
                    name: `${ubuUser.name || ubuUser.email}`,
                    value,
                    size: 25,
                    icon: 'assign',
                    color: 1,
                  });
                  return acc;
                }

                const currentTag = Object.values(this.getterThreadGroups).find(({ title }) => title.toLocaleLowerCase().includes(nameWithoutSuffixTag.toLocaleLowerCase().replace('_', ' ')));

                acc.tags.push({
                  name: currentTag ? currentTag.title : nameWithoutSuffixTag.replace('_', ' '),
                  value,
                  size: 25,
                  icon: 'tag',
                  color: currentTag ? Number(currentTag.color) : 1,
                });
              }
              return acc;
            }, { overview: [], tags: [], assignments: [] });

          this.isLoading = false;
        });
    },
    fetchLoadingCatalog() {
      this.reducedAnalyticsData = Object.values(analyticsCatalog).reduce((acc, stat) => {
        if (stat.type === 'inbox') {
          if (stat.subtype.find(({ type: t }) => t === 'overview')) {
            const currentRowSubtypeFromCatalog = stat.subtype.find(({ type: t }) => t === 'overview');

            acc.overview.push({
              ...stat,
              size: currentRowSubtypeFromCatalog.size,
              ordinal: currentRowSubtypeFromCatalog.ordinal,
              loading: true,
            });

            acc.overview = acc.overview.sort((a, b) => a.ordinal - b.ordinal);
          }
          return acc;
        }
        return acc;
      }, {
        overview: [], tags: [], assignments: [],
      });
      this.reducedAnalyticsData.tags.push({
        size: 25,
        ordinal: 1,
        loading: true,
      });
      this.reducedAnalyticsData.assignments.push({
        size: 25,
        ordinal: 1,
        loading: true,
      });
    },
  },
};
</script>
