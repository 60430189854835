<template>
  <section
    v-if="fetchDone"
    id="UbuInfluenceSettings"
  >
    <section id="UbuInfluenceSettingsLeftColumn">
      <div class="card ubuCard">
        <div class="card-header multibar">
          <p class="ubu-card-title start">
            Campaign
          </p>
          <p class="card-header-icon end">
            <b-button
              class="is-rounded ubuInfluenceButton"
              @click="isEndedModalActive = true"
            >
              <span>
                End Campaign
              </span>
            </b-button>
          </p>
        </div>
        <div class="card-content">
          <UbuInfluenceSettingsCampaignForm
            v-model="localCampaignDetail"
            :has-changes="hasChanges"
            @updateCampaignDetail="updateCampaignDetail($event)"
          />
        </div>
      </div>

      <div class="card ubuCard">
        <div class="card-header">
          <p class="ubu-card-title">
            Leads
          </p>
          <div class="card-header-icon">
            <b-field>
              <b-input
                v-model="searchThread"
                rounded
                icon="magnify"
                placeholder="Search..."
                class="ubuInput"
                expanded
              />
            </b-field>
          </div>
        </div>
        <div class="card-content">
          <b-table
            :data="filteredLeads"
            class="table-no-head ubuBTable"
            paginated
            per-page="8"
            pagination-rounded
            pagination-size="is-small"
          >
            <b-table-column v-slot="props">
              <UbuContactCard
                :contact="props.row"
                class="start"
              />
            </b-table-column>
            <b-table-column v-slot="props">
              <b-button class="end ubuInfluenceButton is-rounded">
                <span>
                  {{ props.row.status }}
                </span>
              </b-button>
            </b-table-column>
            <b-table-column
              v-slot="props"
              numeric
            >
              <b-button
                v-if="props.row.ended"
                class="end ubuInfluenceButton is-rounded is-danger"
              >
                <span>
                  Ended
                </span>
              </b-button>
            </b-table-column>
            <b-table-column
              v-slot="{ row }"
              numeric
            >
              <b-button
                v-if="row.ended"
                class="end ubuInfluenceButton is-rounded"
                @click="confirmDelete(row)"
              >
                <span>
                  Delete
                </span>
              </b-button>
              <b-button
                v-if="!row.ended"
                class="end ubuInfluenceButton is-rounded"
                @click="endActivity(row)"
              >
                <span>
                  End
                </span>
              </b-button>
            </b-table-column>
          </b-table>
        </div>
      </div>
    </section>

    <section id="UbuInfluenceSettingsRightColumn">
      <div class="card ubuCard">
        <div class="card-header multibar">
          <p class="ubu-card-title start">
            Campaign
          </p>
          <p class="card-header-icon end">
            <b-button
              class="ubuInfluenceButton is-rounded"
              @click="showNewTemplateForm = !showNewTemplateForm"
            >
              <UbuIcon
                size="16px"
                name="add"
                filled
              />
              <span>
                Add Template
              </span>
            </b-button>
          </p>
        </div>
        <div class="card-content">
          <div
            v-if="showNewTemplateForm"
            class="card ubuCard"
          >
            <div class="card-header">
              <p class="ubu-card-title multibar column">
                New Custom template
              </p>
              <p class="card-header-icon">
                <b-button
                  class="ubuInfluenceButton is-rounded"
                  @click="addCampaignTemplate()"
                >
                  <UbuIcon
                    size="16px"
                    name="add"
                    filled
                  />
                  <span>
                    Save
                  </span>
                </b-button>
              </p>
            </div>
            <div class="card-content">
              <UbuTemplateTextarea
                v-model="newTemplateText"
                :template-list="templates"
                show-controls
              />
            </div>
          </div>

          <br>

          <div
            v-for="(template, index) in currentCampaign.messages"
            :key="index"
          >
            <div class="card ubuCard">
              <div class="card-header multibar">
                <p class="ubu-card-title start">
                  Template {{ index + 1 }}
                </p>
                <p class="end">
                  <b-button
                    class="is-transparent is-rounded"
                    @click="deleteCampaignTemplate(template)"
                  >
                    <UbuIcon name="trash" />
                  </b-button>
                </p>
              </div>
              <div class="card-content">
                <p>
                  {{ template }}
                </p>
              </div>
            </div>

            <br>
          </div>
        </div>
      </div>
    </section>
    <b-modal
      v-model="isEndedModalActive"
      :width="640"
      scroll="keep"
    >
      <UbuCommonModal
        title="End campaign ?"
        @close="isEndedModalActive = false"
      >
        <template slot="info">
          <p>
            Are you sure you want to end
            <strong>
              {{ currentCampaign.name }}
            </strong>
            ? You won’t be able to undo this action.
          </p>
        </template>
        <template slot="actions">
          <UbuButton
            grow
            center
            class="m-2"
            @click="isEndedModalActive = false"
          >
            Cancel
          </UbuButton>
          <UbuButton
            grow
            center
            secondary
            class="m-2"
            @click="closeCampaign()"
          >
            End campaign
          </UbuButton>
        </template>
      </UbuCommonModal>
    </b-modal>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { snackError } from '../$catalog/snackbar';
import CommonMixin from '../$mixins/CommonMixin';

export default {
  name: 'TheInfluenceSettings',
  mixins: [CommonMixin],
  data() {
    return {
      campaignDetail: {
        name: null,
        color: null,
      },
      newTemplateText: '',
      searchThread: '',
      showNewTemplateForm: false,
      isEndedModalActive: false,
    };
  },
  computed: {
    ...mapGetters({
      currentChannelId: 'Channel/getterCurrentChannelId',
      getterChannelContacts: 'TheInfluence/getterChannelContacts',
      campaignActivitiesDetails: 'TheInfluence/getterCampaignActivitiesDetails',
      templates: 'TheWorkspace/getterTemplates',
      fetchDone: 'Sequence/getterFetchDone',
    }),
    hasChanges() {
      const { name, color } = this.localCampaignDetail;
      if (name !== this.currentCampaign.name || color !== this.currentCampaign.color) {
        return true;
      }
      return false;
    },
    localCampaignDetail: {
      get() {
        return {
          name: this.campaignDetail.name || this.currentCampaign.name,
          color: this.campaignDetail.color || this.currentCampaign.color,
        };
      },
      set(newValue) {
        console.log('🚀 ~ file: TheInfluenceSettings.vue ~ line 190 ~ set ~ newValue', newValue);
        this.campaignDetail = newValue;
      },
    },
    channelId() {
      return this.campaignActivitiesDetails._id || '';
    },
    currentCampaign() {
      return this.campaignActivitiesDetails.campaigns.length
        ? this.campaignActivitiesDetails.campaigns
          .find((campaign) => campaign.campaignId === this.$route.params.campaignId)
        : {};
    },
    activities() {
      return this.currentCampaign.activities ? this.currentCampaign.activities : [];
    },
    alls() {
      return this.activities
        .filter(({ contactDetail, activityDetail }) => contactDetail && activityDetail.status !== 'unscheduled')
        .map(({
          activityDetail: {
            created: when, status, activityId, ended = false, data,
          },
          contactDetail: {
            // eslint-disable-next-line camelcase
            username, full_name, profile_pic_url, has_bucket_media, contactId,
          },
        }) => ({
          activityId,
          username,
          full_name,
          profile_pic_url,
          has_bucket_media,
          contactId: String(contactId),
          when,
          status,
          data,
          ended,
        }));
    },
    sortedLeads() {
      return [...this.alls].sort((a, b) => (a.username < b.username ? -1 : 1));
    },
    filteredLeads() {
      return this.sortedLeads
        .filter((row) => (this.searchThread !== '' ? (row.username && row.username.toLowerCase().includes(this.searchThread)) || (row.full_name && row.full_name.toLowerCase().includes(this.searchThread)) : row));
    },
  },
  watch: {
    newTemplateText() {
      // eslint-disable-next-line max-len
      const reg = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
      if (this.newTemplateText.match(reg)) {
        this.$buefy.snackbar.open({
          ...snackError,
          actionText: 'OK',
          message: 'We recommend that you do not put links in your outbound messages. This is detrimental to the quality of responses and can create spam (via email or dm).',
          indefinite: true,
        });
      }
    },
  },
  methods: {
    ...mapActions({
      updateCampaign: 'TheInfluence/updateCampaign',
      setStatus: 'TheInfluence/setStatus',
      endCampaign: 'TheInfluence/endCampaign',
      deleteActivity: 'TheInfluence/deleteActivity',
    }),
    updateCampaignDetail(event) {
      const { activities, ...rest } = this.currentCampaign;
      const myCampaign = {
        ...rest,
        ...event,
      };
      this.updateCampaign(myCampaign);
    },

    closeCampaign() {
      // need endpoint
      return this.endCampaign({
        _id: this.$route.params.campaignId,
        ended: true,
        name: this.currentCampaign.name,
        channel: this.currentChannelId,
      })
        .then(() => {
          this.isEndedModalActive = false;
          this.$router.push('/influence');
        });
    },
    addCampaignTemplate() {
      const { activities, messages, ...rest } = this.currentCampaign;
      const myCampaign = {
        ...rest,
        messages: [...messages, this.newTemplateText],
      };
      this.updateCampaign(myCampaign)
        .then(() => {
          this.showNewTemplateForm = false;
        });
    },
    deleteCampaignTemplate(template) {
      const { activities, messages, ...rest } = this.currentCampaign;
      const newMessages = messages;
      newMessages.splice(newMessages.indexOf(template), 1);
      const myCampaign = {
        ...rest,
        messages: newMessages,
      };
      this.updateCampaign(myCampaign);
    },

    endActivity(event) {
      const thisActivity = {
        ...event,
        _id: event.activityId,
        ended: true,
        campaignId: this.currentCampaign.campaignId,
        channel: this.channelId,
      };
      this.setStatus(thisActivity);
    },

    confirmDelete(event) {
      this.$buefy.dialog.confirm({
        title: 'Deleting account',
        // eslint-disable-next-line max-len
        message: `Are you sure you want to <b>permanently remove</b> @${event.username} from campaign ? <br> All datas (stats, activities, mentions) will be definitively deleted. <br> <b>This action cannot be undone.</b>`,
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.removeActivity(event),
      });
    },

    removeActivity(event) {
      this.deleteActivity({
        activityId: event.activityId,
        contactId: event.contactId,
        campaignId: this.$route.params.campaignId,
        channelId: this.campaignActivitiesDetails._id,
      });
    },
  },
};
</script>

<style lang="scss">
#UbuInfluenceSettings {
  display: flex;
  justify-content: space-evenly;
  height: calc(100vh - 75px);
  max-height: calc(100vh - 75px);
  & .ubuCard.card {
    background-color: var(--soapstone);
  }
  & .ubuCard .card-content {
    padding: 12px 24px;
    color: var(--cloudburst30);
    p {
      white-space: pre-line;
    }
  }
  & > * {
    width: 100%;
    margin: 16px;
  }
  #UbuInfluenceSettingsLeftColumn {
    // display: flex;
    // justify-content: space-evenly;
    & .ubuCard:first-child  {
      margin-bottom: 16px;
      overflow: initial;
    }
    & .dropdown-menu {
      min-width: initial;
      & .dropdown-item {
        padding: 0.375rem 1rem;
      }
    }
  }
}
</style>
