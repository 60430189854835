import { arrayMapper } from '../../../$utils/prototypes';
import {
  updateDatasIfExists,
  updateObjectRelationIfExists,

} from '../../$utils/dataMapper';
import { yaerApi } from '../../../$providers/index';

/* eslint-disable max-len */
export default {
  namespaced: true,

  state: {
    shopifyShop: {},
    // currentShopifyShopSsid: '612cbc2b92c8f935be3ac76c',
    shopifyShopHasReferralLinkBis: {},
    shopifyShopHasDiscountCodeBis: {},
    shopifyShopHasOrderBis: {},
  },

  getters: {
    getterShopifyShop: (state) => state.shopifyShop,
    // getterCurrentShopifyShopSsid: (state) => state.currentShopifyShopSsid,
    getterShopifyShopHasReferralLinkBis: (state) => state.shopifyShopHasReferralLinkBis,
    getterShopifyShopHasDiscountCodeBis: (state) => state.shopifyShopHasDiscountCodeBis,
    getterShopifyShopHasOrderBis: (state) => state.shopifyShopHasOrderBis,
  },

  mutations: {
    SET_SHOPIFY_SHOP(state, newDatas) { state.shopifyShop = newDatas; },
    // SET_CURRENT_SHOPIFY_SHOP_SSID(state, data) { state.currentShopifyShopSsid = data; },
    SET_SHOPIFY_SHOP_HAS_REFERRAL_LINK_BIS(state, newDatas) { state.shopifyShopHasReferralLinkBis = updateObjectRelationIfExists({ newDatas, actualDatas: state.shopifyShopHasReferralLinkBis }); },
    SET_SHOPIFY_SHOP_HAS_DISCOUNT_CODE_BIS(state, newDatas) { state.shopifyShopHasDiscountCodeBis = updateObjectRelationIfExists({ newDatas, actualDatas: state.shopifyShopHasDiscountCodeBis }); },
    SET_SHOPIFY_SHOP_HAS_ORDER_BIS(state, newDatas) { state.shopifyShopHasOrderBis = updateObjectRelationIfExists({ newDatas, actualDatas: state.shopifyShopHasOrderBis }); },

    RESET_STORES(state) {
      state.shopifyShop = {};
      state.shopifyShopHasReferralLinkBis = {};
      state.shopifyShopHasDiscountCodeBis = {};
      state.shopifyShopHasOrderBis = {};
    },
  },

  actions: {
    fetchShopifyShop({ commit, rootGetters }) {
      const { 'Channel/getterCurrentChannelId': channelId } = rootGetters;

      return Promise.resolve()
        .then(() => yaerApi.api.shopifyShop.report({ payload: { channelId } }))
        .then(({ result }) => {
          console.log('🚀 ~ file: ShopifyShop.store.js ~ line 51 ~ .then ~ result', result);
          if (!result) return null;
          const shopifyShopByChannel = result.map((ss) => ({ ...ss, shopifyShopSsid: ss._id }));
          console.log('🚀 ~ file: ShopifyShop.store.js ~ line 53 ~ .then ~ shopifyShopByChannel', shopifyShopByChannel);

          const fetchedShopifyShopList = arrayMapper(shopifyShopByChannel, 'shopifyShopSsid');
          commit('SET_SHOPIFY_SHOP', fetchedShopifyShopList);
          return shopifyShopByChannel;
        });
    },
    connectToShopify({ commit, rootGetters }, {
      urlWebsite, apiVersion, urlStore, password, sharedSecret,
    }) {
      const { 'Channel/getterCurrentChannelId': channelId } = rootGetters;

      return yaerApi.api.shopifyShop.post({
        payload: {
          channelId, websiteUrl: urlWebsite, apiVersion, shopExtrefId: urlStore, password, sharedSecret,
        },
      })
        .then(({ result, success }) => {
          if (!result) return success;
          console.log('🚀 ~ file: ShopifyShop.store.js ~ line 69 ~ .then ~ result', result);

          const newShop = {
            [result._id]: {
              ...result,
              shopifyShopSsid: result._id,
            },
          };

          commit('SET_SHOPIFY_SHOP', newShop);

          return { success };
        });
    },
  },

};
