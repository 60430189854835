<template>
  <div id="UbuCommentThreadHeader">
    <template v-if="!isPost">
      <div
        v-if="from"
        class="ubuContactName"
      >
        <div class="ellipsis ubu-subtitle">{{ from.fullName }}</div>
        <UbuInstaName
          v-if="source === 'instagram'"
          class="ubu-instalink"
          :username="from.username"
        />
        <UbuFacebookName
          v-if="source === 'facebook'"
          :id="from.participantId"
          :name="from.fullName"
          :link="from.link"
        />
      </div>
      <div
        v-else
        class="ubuContactName"
      >
        <UbuUndefinedUser />
      </div>

      <div class="fgrow" />

      <div class="ubuButton-group">
        <UbuCommentThreadHeaderButtons
          @rateThread="$emit('rateThread', $event)"
          @translateThread="$emit('translateThread', $event)"
        />
      </div>
    </template>
  </div>
</template>
<script>

export default {
  name: 'UbuCommentThreadHeader',
  props: {
    from: {
      type: Object,
      default: null,
    },
    source: {
      type: String,
      default: null,
    },
    isPost: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    imgFallback() {
      return '/img/no_profile_pict.svg';
    },
  },
};
</script>
