<template>
  <div
    id="UbuThreadCard"
    :class="[{
      'UbuThreadCard_wrapperSelected': selected,
      'UbuThreadCard_isAway': isAway,
    }]"
  >
    <div
      class="
      UbuThreadCard_avatar"
      @click="$emit('onBulkSelect', $event)"
    >
      <div
        :class="['UbuThreadCard_avatarImg', {
          'UbuThreadCard_avatarSelected': bulkSelected,
          'UbuThreadCard_broSelected': broSelected && !bulkSelected,
        }]"
      >
        <UbuInstaImg
          v-show="!bulkSelected && !broSelected"
          :key="`contact/${contact.contactId}`"
          :insta-src="contact.has_bucket_media
            ? String(contact.contactId) : contact.profile_pic_url"
          :has-bucket="contact.has_bucket_media || false"
          :bucket-folder="'profile_pic'"
          fallback="/img/no_profile_pict.svg"
        />
        <img
          v-if="bulkSelected"
          class="UbuThreadCard_checkMark"
          src="@/assets/pictos/is_ok.svg"
        >
        <div
          v-if="!broSelected && !bulkSelected"
          class="UbuThreadCard_source"
        >
          <img
            v-if="source === 'instagram'"
            src="@/assets/pictos/instagram-logo.svg"
          >
          <img
            v-if="source === 'facebook'"
            src="@/assets/pictos/facebook-logo.svg"
          >
        </div>
      </div>
    </div>
    <div
      class="UbuThreadCard_textBox"
      @click="$emit('onSelect', $event)"
    >
      <div
        :class="[
          'UbuThreadCard_textRow',
          'ubu-text1', contactGroups.length ? `text-color-${contactGroups[0].color}` :
            '']"
      >
        <UbuIcon
          v-for="contactGroup in contactGroups"
          :key="`contactGroup/${contact.contactId}/${contactGroup.contactGroupId}`"
          :color="contactGroup.color"
          filled
          name="contact"
        />
        {{ getFullName }}
      </div>
      <div
        :class="[
          'UbuThreadCard_textRow',
          'ubu-text2', threadGroups.length ? `text-color-${threadGroups[0].color}` : ''
        ]"
      >
        <UbuUserPicture
          v-for="ubuUser in assignments"
          :key="`assignment/${contact.contactId}/${ubuUser.ubuUserId}`"
          :size="18"
          :initial-name="ubuUser.initials"
          :color="ubuUser.color"
        />
        <UbuIcon
          v-for="threadGroup in threadGroups"
          :key="`threadGroup/${contact.contactId}/${threadGroup.threadGroupId}`"
          :color="threadGroup.color"
          filled
          name="tag"
        />
        {{ getLastMessageText }}
      </div>
    </div>
    <div class="UbuThreadCard_right">
      <span class="UbuThreadCard_date">{{ getParsedDate }}</span>
      <UbuIcon
        v-if="!contactSentLast"
        name="send"
        size="12px"
        filled
      />
      <b-tooltip
        v-else-if="unread"
        label="Mark as read"
        position="is-left"
        class="UbuThreadCard_badgeContainer"
      >
        <UbuBadge
          class="UbuThreadCard_badge"
          important
          @click="$emit('onBadgeClick', $event)"
        />
      </b-tooltip>
    </div>
  </div>
</template>

<script>
import { get } from 'lodash';
import { timeToNow } from '../../$utils/dates';

export default {
  name: 'UbuThreadCard',
  props: {
    broSelected: {
      type: Boolean,
      default: false,
    },
    contact: {
      type: Object,
      default: () => {},
    },
    lastMessage: {
      type: Object,
      default: () => {},
    },
    selected: {
      type: Boolean,
      default: false,
    },
    bulkSelected: {
      type: Boolean,
      default: false,
    },
    unread: {
      type: Boolean,
      default: false,
    },
    source: {
      type: String,
      default: 'instagram',
    },
    threadGroups: {
      type: Array,
      default: () => [],
    },
    contactGroups: {
      type: Array,
      default: () => [],
    },
    assignments: {
      type: Array,
      default: () => [],
    },
    isAway: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getParsedDate() {
      const { timestamp } = this.lastMessage;
      const date = timeToNow(timestamp);
      return date;
    },

    getProfilePic() {
      return get(this.contact, 'profile_pic_url');
    },
    getFullName() {
      // return this.lastMessage.timestamp;
      return this.contact
                && (this.contact.full_name
                || this.contact.username
                || 'Unfoundable name');
    },
    contactSentLast() {
      return !this.lastMessage.isMe;
    },
    getLastMessageText() {
      if (this.lastMessage) {
        const { isMe, uniType } = this.lastMessage;

        switch (uniType) {
          /* eslint-disable no-multi-spaces */
          case 'isText':            return this.lastMessage.text;
          case 'isStoryMention':    return isMe ? 'mentioned them in your story'  : 'mentioned you in their story';
          case 'isStoryReact':      return isMe ? 'you reacted to their story'    : 'reacted to your story';
          case 'isStoryReply':      return isMe ? 'you replied to their story'    : 'replied to your story';
          case 'isMediaImage':      return isMe ? 'you shared a media'            : 'shared a media';
          case 'isMediaVideo':      return isMe ? 'you shared a video'            : 'shared a video';
          case 'isClip':            return isMe ? 'you shared a clip'             : 'shared a clip';
          case 'isAnimatedMedia':   return isMe ? 'you shared a GIF'              : 'shared a GIF';
          case 'isVoiceMedia':      return isMe ? 'you shared an audio'           : 'shared an audio';
          case 'isStoryShare':      return isMe ? 'you shared a story'            : 'shared a story';
          case 'isPostShare':       return isMe ? 'you shared a post'             : 'shared a post';
          case 'isProductShare':    return isMe ? 'you shared a product'          : 'shared a product';
          case 'isProfileShare':    return isMe ? 'you shared a profile'          : 'shared a profile';
          case 'isLink':            return isMe ? 'you shared a link'             : 'shared a link';
          case 'isFelixShare':      return isMe ? 'you shared an IGTV'            : 'shared an IGTV';
          case 'isActionLog':       return isMe ? 'you liked a message'           : 'liked a message';
          case 'isComment':         return isMe ? 'you comment a post'            : 'commented a post';
            /* eslint-enable no-multi-spaces */
          default: return 'message not supported';
        }
      }
      return '';
    },
  },
  mounted() {
  },
};
</script>
