<template>
  <UbuBaseModal
    title="Importing contact data"
  >
    <div
      id="UbuInfluenceLeadsImporterModal"
      slot="info"
    >
      <UbuInfluenceLeadsImporterModalList
        class="is-color5"
        :list="bulkSuccess"
        :title="'Successfully added'"
        :is-research-ended="isResearchEnded"
      />
      <UbuInfluenceLeadsImporterModalList
        class="is-color8"
        :list="bulkAlready"
        :title="'Already in campaign'"
        :is-research-ended="true"
      />
      <UbuInfluenceLeadsImporterModalList
        class="is-color2"
        :list="bulkFail"
        :title="'Not found'"
        :is-research-ended="isResearchEnded"
      />
    </div>
  </UbuBaseModal>
</template>

<script>
import UbuBaseModal from '@/components/UbuModals/UbuBaseModal';

export default {
  components: {
    UbuBaseModal,
  },
  props: {
    isResearchEnded: {
      type: Boolean,
      default: false,
    },
    bulkAlready: {
      type: Array,
      default: () => [],
    },
    bulkFail: {
      type: Array,
      default: () => [],
    },
    bulkSuccess: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
