import { use, registerComponent } from '../../$utils/plugins';

// const UbuMentionsFilters = () => import(/* webpackChunkName: "addonMentions" */ './UbuMentionsFilters');

import UbuMentionsFilters from './UbuMentionsFilters';
import UbuAnalyticsGraph from './UbuAnalyticsGraph';
import UbuMentionsModalAddHashtag from './UbuMentionsModalAddHashtag';
import UbuMentionsHashtagsMediaCard from './UbuMentionsHashtagsMediaCard';
import UbuMentionsHashtagsTable from './UbuMentionsHashtagsTable';
import UbuMentionsHashtagsFilters from './UbuMentionsHashtagsFilters';
import UbuAnalyticsLoadingGraph from './UbuAnalyticsLoadingGraph';
import UbuAnalyticsDatepicker from './UbuAnalyticsDatepicker';
import UbuAnalyticsPostModal from './UbuAnalyticsPostModal';
import UbuMentionsModalAutoReport from './UbuMentionsModalAutoReport';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, UbuMentionsFilters);
    registerComponent(Vue, UbuAnalyticsGraph);
    registerComponent(Vue, UbuMentionsModalAddHashtag);
    registerComponent(Vue, UbuMentionsHashtagsMediaCard);
    registerComponent(Vue, UbuMentionsHashtagsTable);
    registerComponent(Vue, UbuMentionsHashtagsFilters);
    registerComponent(Vue, UbuAnalyticsLoadingGraph);
    registerComponent(Vue, UbuAnalyticsDatepicker);
    registerComponent(Vue, UbuAnalyticsPostModal);
    registerComponent(Vue, UbuMentionsModalAutoReport);
  },
};

use(Plugin);

export default Plugin;

export {
  UbuMentionsFilters,
  UbuAnalyticsGraph,
  UbuMentionsModalAddHashtag,
  UbuMentionsHashtagsMediaCard,
  UbuMentionsHashtagsTable,
  UbuMentionsHashtagsFilters,
  UbuAnalyticsLoadingGraph,
  UbuAnalyticsDatepicker,
  UbuAnalyticsPostModal,
  UbuMentionsModalAutoReport,
};
