<template>
  <svg
    class="UbuLoader_wrapper rotating"
    width="62"
    height="61"
    viewBox="0 0 62 61"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :style="{'fill':`var(--${colorName})`}"
  >
    <path
      d="M32.8299 18.3C32.8299 16.2787 32.0106 14.64 30.9999 14.64C29.9892 14.64 29.1699 16.2787 29.1699 18.3C29.1699 20.3214 29.9892 21.96 30.9999 21.96C32.0106 21.96 32.8299 20.3214 32.8299 18.3Z"
    />
    <path
      d="M38.6849 20.8495C39.6955 19.099 39.8053 17.2702 38.93 16.7649C38.0548 16.2595 36.5259 17.269 35.5152 19.0195C34.5045 20.7701 34.3948 22.5988 35.27 23.1042C36.1453 23.6095 37.6742 22.6001 38.6849 20.8495Z"
    />
    <path
      d="M42.4802 25.9849C44.2307 24.9742 45.2402 23.4453 44.7348 22.5701C44.2295 21.6948 42.4007 21.8045 40.6502 22.8152C38.8996 23.8259 37.8902 25.3548 38.3955 26.2301C38.9009 27.1053 40.7296 26.9956 42.4802 25.9849Z"
    />
    <path
      d="M43.1999 32.33C45.2212 32.33 46.8599 31.5107 46.8599 30.5C46.8599 29.4894 45.2212 28.67 43.1999 28.67C41.1785 28.67 39.5399 29.4894 39.5399 30.5C39.5399 31.5107 41.1785 32.33 43.1999 32.33Z"
    />
    <path
      d="M40.6507 38.1847C42.4013 39.1954 44.2301 39.3052 44.7354 38.4299C45.2407 37.5546 44.2313 36.0258 42.4807 35.0151C40.7302 34.0044 38.9014 33.8946 38.3961 34.7699C37.8907 35.6452 38.9002 37.1741 40.6507 38.1847Z"
    />
    <path
      d="M35.515 41.9805C36.5257 43.7311 38.0545 44.7405 38.9298 44.2352C39.8051 43.7298 39.6953 41.9011 38.6846 40.1505C37.674 38.4 36.1451 37.3905 35.2698 37.8959C34.3945 38.4012 34.5043 40.23 35.515 41.9805Z"
    />
    <path
      d="M29.1701 42.7C29.1701 44.7213 29.9894 46.36 31.0001 46.36C32.0108 46.36 32.8301 44.7213 32.8301 42.7C32.8301 40.6786 32.0108 39.04 31.0001 39.04C29.9894 39.04 29.1701 40.6786 29.1701 42.7Z"
    />
    <path
      d="M23.3151 40.1505C22.3045 41.901 22.1947 43.7298 23.07 44.2351C23.9452 44.7405 25.4741 43.731 26.4848 41.9805C27.4955 40.2299 27.6052 38.4012 26.73 37.8958C25.8547 37.3905 24.3258 38.3999 23.3151 40.1505Z"
    />
    <path
      d="M19.5198 35.0151C17.7693 36.0258 16.7598 37.5547 17.2652 38.4299C17.7705 39.3052 19.5993 39.1955 21.3498 38.1848C23.1004 37.1741 24.1098 35.6452 23.6045 34.7699C23.0991 33.8947 21.2704 34.0044 19.5198 35.0151Z"
    />
    <path
      d="M18.8001 28.67C16.7788 28.67 15.1401 29.4893 15.1401 30.5C15.1401 31.5106 16.7788 32.33 18.8001 32.33C20.8215 32.33 22.4601 31.5106 22.4601 30.5C22.4601 29.4893 20.8215 28.67 18.8001 28.67Z"
    />
    <path
      d="M21.3493 22.8153C19.5987 21.8046 17.7699 21.6948 17.2646 22.5701C16.7593 23.4454 17.7687 24.9742 19.5193 25.9849C21.2698 26.9956 23.0986 27.1054 23.6039 26.2301C24.1093 25.3548 23.0998 23.8259 21.3493 22.8153Z"
    />
    <path
      d="M26.485 19.0195C25.4743 17.2689 23.9455 16.2595 23.0702 16.7648C22.1949 17.2702 22.3047 19.0989 23.3154 20.8495C24.326 22.6 25.8549 23.6095 26.7302 23.1041C27.6055 22.5988 27.4957 20.77 26.485 19.0195Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'UbuLoader',
  props: {
    colorName: {
      type: String,
      default: 'geraldine',
    },
  },
};
</script>
<style lang="scss">
    $duration: 5s;
    @-webkit-keyframes rotating /* Safari and Chrome */ {
        from {
            -webkit-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        to {
            -webkit-transform: rotate(360deg);
            -o-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
    @keyframes rotating {
        from {
            -ms-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        to {
            -ms-transform: rotate(360deg);
            -moz-transform: rotate(360deg);
            -webkit-transform: rotate(360deg);
            -o-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
    .rotating {
        -webkit-animation: rotating $duration linear infinite;
        -moz-animation: rotating $duration linear infinite;
        -ms-animation: rotating $duration linear infinite;
        -o-animation: rotating $duration linear infinite;
        animation: rotating $duration linear infinite;
    }
</style>
