<template>
  <div>
    <h3 class="ubu-welcome py-6">Welcome back !</h3>
    <form
      class="UbuLoginForm"
      @keyup.enter="log()"
    >
      <UbuTextField
        v-model="form.email"
        label="Email"
        placeholder="name@company.com"
        :error="errorEmail"
      />
      <UbuTextField
        v-model="form.password"
        label="Password"
        type="password"
        placeholder="**********"
        :error="errorPw"
      />
      <router-link
        to="/auth/reset-password"
        class="UbuLoginBox_forgotPassword"
      >
        I forgot my password... 🤡
      </router-link>
      <p
        v-if="error != null"
        class="UbuLoginBox_errorText"
      >
        {{ error }}
      </p>
      <UbuButton
        grow
        center
        big
        secondary
        @click="log()"
      >
        Login
      </UbuButton>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { snackError } from '@/addons/$catalog/snackbar';

export default {
  name: 'TheAuthenticateLogin',
  data: () => ({
    form: {
      email: '',
      password: '',
    },
    error: null,
    errorEmail: null,
    errorPw: null,
  }),
  mounted() {
    return Promise.resolve()
      .then(() => this.UP_inviteToken(this.$route.params.token))
      .then(() => {
        if (this.$route.params.token) {
          this.canUseToken().then(({ error }) => { if (error) this.$router.push('/auth/login'); });
        }
      });
  },
  methods: {
    ...mapActions({
      login: 'Authenticate/login',
      UP_inviteToken: 'Authenticate/UP_inviteToken',
      sendToken: 'Authenticate/sendToken',
      fetchAll: 'Sequence/fetchAll',
      canUseToken: 'Authenticate/canUseToken',
    }),
    log() {
      return Promise.resolve()
        .then(() => this.login(this.form))
        .then(() => this.sendToken())
        .then((response) => {
          if (response && response.error) {
            this.$buefy.snackbar.open({
              ...snackError,
              message: response.error.message,
              onAction: () => this.log(),
            });
          }
          this.$router.push(process.env.VUE_APP_ROOT_PAGE || 'home');
        })
        .then(() => this.fetchAll())
        .catch((error) => {
          this.$buefy.toast.open({
            duration: 5000,
            message: error.message,
            type: 'is-danger',
          });
        });
    },
  },
};
</script>

<style lang="scss">
  :root {
    a.UbuLoginBox_forgotPassword {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: var(--darkgrey60);
      text-align: right;
      text-decoration: none;
    }
    .UbuLoginBox_errorText{
      text-align: center;
      margin: 0;
      color: var(--error);
    }
  }
</style>
