import { threadHasContactGroup, threadHasThreadGroup } from '@/addons/$utils/customs';
import { threadIsOpen } from '@/addons/$utils/threadUtilities';
import moment from 'moment';
import CommonProvider from '../providers/common.provider';

export default {
  namespaced: true,

  state: {
  },

  getters: {
  },

  actions: {
    readThread({ commit }, thread) {
      const { threadId, channelId } = thread;

      return CommonProvider.setThreadRead({ payload: { channelId, threadId } })
        .then(({ response }) => {
          commit('Thread/READ_THREAD', { threadId, timestamp: response.payload ? response.payload.timestamp : moment().format() }, { root: true });
        })
        .catch((error) => {
          console.warn('🚀 ~ file: TheThreadActionButton.store.js ~ line 24 ~ readThread ~ error', error);
        });
    },

    assignThreadsToThreadGroup({ commit, rootGetters }, { threadGroup, threads }) {
      const channelId = rootGetters['Channel/getterCurrentChannelId'];
      const _threads = threads.filter((t) => !threadHasThreadGroup(t, threadGroup));
      const threadIds = _threads.map((t) => t.threadId);
      const { title, threadGroupId } = threadGroup;
      return CommonProvider
        .assignSubjectsToCustom({
          payload: {
            channelId,
            customTagId: threadGroupId,
            subjects: threadIds,
          },
        })
        .then(({ response, error }) => {
          if (response) {
            // const threadsHasThreadGroup = _threads.map((thread) => ({
            //   threadId: thread.threadId,
            //   threadGroupId,
            // }));
            // commit('Thread/SET_THREADS_HAS_THREAD_GROUP', threadsHasThreadGroup, { root: true });
            commit('ThreadGroup/ADD_THREAD_TO_THREAD_GROUP', { threadIds, threadGroupId }, { root: true });
            threadIds.forEach((threadId) => {
              commit('Thread/ADD_THREAD_GROUP_TO_THREAD', { threadId, threadGroupIds: [threadGroupId] }, { root: true });
            });
            return { count: threadIds.length, title };
          }
          return { error, title, count: threadIds.length };
        }).catch((error) => ({ error, title, count: threadIds.length }));
    },
    removeThreadsFromThreadGroup({ commit, rootGetters }, { threadGroup, threads }) {
      const channelId = rootGetters['Channel/getterCurrentChannelId'];
      const _threads = threads.filter((t) => threadHasThreadGroup(t, threadGroup));
      const threadIds = _threads.map((t) => t.threadId);
      const { title, threadGroupId } = threadGroup;
      const count = threadIds.length;
      return CommonProvider
        .removeSubjectsFromCustom({
          payload: {
            channelId,
            customTagId: threadGroupId,
            subjects: threadIds,
          },
        })
        .then(({ response, error }) => {
          if (response) {
            // const threadsHasThreadGroup = _threads.map((thread) => ({
            //   threadId: thread.threadId,
            //   threadGroupId,
            // }));
            // commit('Thread/UNSET_THREADS_HAS_THREAD_GROUP', threadsHasThreadGroup, { root: true });
            threadIds.forEach((threadId) => {
              commit('ThreadGroup/REMOVE_THREAD_TO_THREAD_GROUP', { threadId, threadGroupId }, { root: true });
              commit('Thread/REMOVE_THREAD_GROUP_TO_THREAD', { threadId, threadGroupId }, { root: true });
            });
            return { count, title };
          }
          return { error, title, count };
        })
        .catch((error) => ({ error, title, count }));
    },
    createThreadGroupAndAssignMany({ dispatch, rootGetters }, { threads, title, color }) {
      const channelId = rootGetters['Channel/getterCurrentChannelId'];
      const threadIds = threads.map((t) => t.threadId);
      const count = threadIds.length;
      return CommonProvider
        .createNewCustomAndAssignMany({
          payload: {
            channelId, color, title, subjects: threadIds, type: 'tag',
          },
        })
        .then(({ response, error }) => {
          if (response) {
            return dispatch('CustomTag/fetchCustomsByChannel', null, { root: true })
              .then(() => ({ count, title }));
          }
          return { error, title, count };
        })
        .catch((error) => ({ error, title, count }));
    },
    assignContactsToContactGroup({ commit, rootGetters }, { contactGroup, threads }) {
      const channelId = rootGetters['Channel/getterCurrentChannelId'];
      const _threads = threads.filter((t) => !threadHasContactGroup(t, contactGroup));
      const contactIds = _threads.map((t) => t.contactId);
      const count = contactIds.length;
      const { title, contactGroupId } = contactGroup;
      return CommonProvider
        .assignSubjectsToCustom({
          payload: {
            channelId,
            customTagId: contactGroupId,
            subjects: contactIds,
          },
        })
        .then(({ response, error }) => {
          if (response) {
            // const contactHasContactGroup = _threads.map((thread) => ({
            //   contactId: String(thread.contactId),
            //   contactGroupId: contactGroup.contactGroupId,
            // }));
            // commit('Contact/SET_CONTACTS_HAS_CONTACT_GROUP', contactHasContactGroup, { root: true });
            commit('ContactGroup/ADD_CONTACT_TO_CONTACT_GROUP', { contactIds, contactGroupId }, { root: true });
            contactIds.forEach((contactId) => {
              commit('Contact/ADD_CONTACT_GROUP_TO_CONTACT', { contactId, contactGroupIds: [contactGroupId] }, { root: true });
            });
            return { count, title };
          }
          return { error, title, count };
        }).catch((error) => ({ error, title, count }));
    },
    removeContactsFromContactGroup({ commit, rootGetters }, { contactGroup, threads }) {
      const channelId = rootGetters['Channel/getterCurrentChannelId'];
      const _threads = threads.filter((t) => threadHasContactGroup(t, contactGroup));
      const contactIds = _threads.map((t) => t.contactId);
      const { title, contactGroupId } = contactGroup;
      const count = contactIds.length;
      return CommonProvider
        .removeSubjectsFromCustom({
          payload: {
            channelId,
            customTagId: contactGroupId,
            subjects: contactIds,
          },
        })
        .then(({ response, error }) => {
          if (response) {
            // const contactHasContactGroup = _threads.map((thread) => ({
            //   contactId: String(thread.contactId),
            //   contactGroupId: contactGroup.contactGroupId,
            // }));
            // commit('Contact/UNSET_CONTACTS_HAS_CONTACT_GROUP', contactHasContactGroup, { root: true });
            contactIds.forEach((contactId) => {
              commit('Contact/REMOVE_CONTACT_GROUP_TO_CONTACT', { contactId, contactGroupId }, { root: true });
              commit('ContactGroup/REMOVE_CONTACT_TO_CONTACT_GROUP', { contactId, contactGroupId }, { root: true });
            });
            return { count, title };
          }
          return { error, title, count };
        }).catch((error) => ({ error, title, count }));
    },
    createContactGroupAndAssignMany({ dispatch, rootGetters }, { threads, title, color }) {
      const channelId = rootGetters['Channel/getterCurrentChannelId'];
      const contactIds = threads.map((t) => String(t.contactId));
      const count = contactIds.length;
      return CommonProvider
        .createNewCustomAndAssignMany({
          payload: {
            channelId, color, title, subjects: contactIds, type: 'label',
          },
        })
        .then(({ response, error }) => {
          if (response) {
            return dispatch('CustomTag/fetchCustomsByChannel', null, { root: true })
              .then(() => ({ count, title }));
          }
          return { error, title, count };
        })
        .catch((error) => ({ error, title, count }));
    },

    doneManyThreads({ dispatch }, { threads, threadGroup }) {
      const { title } = threadGroup;
      return Promise.all([
        dispatch('closeManyThreads', { threads }),
        dispatch('removeThreadsFromThreadGroup', { threads, threadGroup }),
      ])
        // eslint-disable-next-line no-unused-vars
        .then(([_closeResp, ungroupResp]) => {
          const { response, error } = ungroupResp;
          if (response) {
            return { count: threads.length, title };
          }
          return { count: threads.length, title, error };
        });
    },
    openManyThreads({ commit, rootGetters }, { threads }) {
      const channelId = rootGetters['Channel/getterCurrentChannelId'];
      const threadIds = threads.filter((t) => !threadIsOpen(t)).map((t) => t.threadId);
      return CommonProvider
        .setManyThreadsClosed({ payload: { threadIds, channelId, closed: false } })
        .then(({ response, error }) => {
          if (response) {
            commit('Thread/CLOSE_THREADS', { threadIds, isClosed: false }, { root: true });
            return { count: threadIds.length };
          }
          return { count: threadIds.length, error };
        });
    },
    closeManyThreads({ commit, rootGetters }, { threads }) {
      const channelId = rootGetters['Channel/getterCurrentChannelId'];
      const threadIds = threads.filter(threadIsOpen).map((t) => t.threadId);
      return CommonProvider
        .setManyThreadsClosed({ payload: { threadIds, channelId, closed: true } })
        .then(({ response, error }) => {
          if (response) {
            commit('Thread/CLOSE_THREADS', { threadIds, isClosed: true }, { root: true });
            return { count: threadIds.length };
          }
          return { count: threadIds.length, error };
        });
    },
    searchThreadsItems({ rootGetters }, search) {
      console.log('🚀 ~ file: TheThreadList.store.js ~ line 216 ~ searchThreadsItems ~ search', search);
      const channelId = rootGetters['Channel/getterCurrentChannelId'];
      return CommonProvider.searchThreadsItems({ channelId, search })
        .then(({ response }) => response);
    },

  },
};
