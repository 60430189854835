<template>
  <div class="UbuMenu_topmenu">
    <UbuIconButton
      v-for="(item, index) in navigation"
      :key="index"
      :to="item.to"
      :disabled="item.disabled"
      :target="item.target"
      :is-active="isIconActive(item.icon)"
      hoverable
      :icon="item.icon"
      :tooltip="{ label: item.text, position: 'is-bottom' }"
    />
  </div>
</template>

<script>
export default {
  name: 'UbuSidebarTopbar',
  props: {
    navigation: {
      type: Array,
      required: true,
    },
  },
  methods: {
    isIconActive(iconName) {
      return this.$router.currentRoute.matched.some(
        (route) => route.meta.overrideActiveIcon === iconName,
      );
    },
  },
};
</script>

<style lang="scss" scoped>
  .UbuMenu_topmenu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
</style>
