import { get } from 'lodash';
import moment from 'moment';
const getFalsyValue = (val) => !(val === false);

const defaultOpts = {
  activeGlobale: true,
  delete: true,
  markAsRead: false,
  report: false,
  like: false,
  translation: {
    activeTranslate: true,
    traduction_fr: true,
    traduction_en: true,
    traduction_de: true,
    traduction_es: true,
  },
};

const reg = /(?:@)([A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_]))?)/gi;
function instagramCommentMapper(comment, level = 0) {
  const mentions = comment.text ? [...comment.text.matchAll(reg)] : [];
  // console.log('Matching', comment.text, [...mentions]);
  let d = moment(comment.timestamp);
  if (!d.isValid()) {
    d = moment(parseInt(comment.timestamp, 10));
  }
  const { correspondent } = comment;
  const newComment = {
    isPending: comment.isPending || false,
    source: 'instagram',
    commentId: comment.id,
    graphId: comment.id,
    targetId: null,
    channelId: comment.channelId || comment.channel_id || null,
    postId: comment.media_id,
    mediaFBId: comment.mediaFBId || comment.media_id || comment.postId || null,
    parentId: comment.parent_id || null,
    closed: comment.closed || false,
    muted: comment.muted || false,
    level: level || comment.parent_id ? 1 : 0,
    attachments: [],
    createdAt: d.valueOf(),
    publishedAt: d.valueOf(),
    closedAt: d.valueOf(),
    uniType: 'isComment',
    reactions: comment.like_count > 0 ? [{
      type: 'LIKE',
      emoji: '❤️',
      count: comment.like_count,
    }] : [],
    // __childs: comment.replies ? comment.replies.map((rep) => instagramCommentMapper(rep, 1)) : [],
    canComment: getFalsyValue(comment.can_comment),
    canLike: true,
    isMention: comment.isMention || false,
    isHidden: comment.hidden || false,
    text: comment.text || '',
    mentions: mentions ? mentions.map((match) => ({
      participantId: match[1],
      label: match[0],
      offset: match.index,
      length: match[0].length,
      href: `https://instagram.com/${match[1]}`,
    })) : [],
    from: comment.username ? {
      participantId: get(comment, 'correspondent.pk', null),
      username: comment.username || null,
      fullName: get(comment, 'correspondent.full_name', comment.username),
      link: `https://instagram.com/${comment.username}`,
      profilePicture: correspondent && correspondent.pk && `https://io.getubu.com/profile_pic/${correspondent.pk}`,
      correspondent,
    } : null,
    options: comment.isMention ? { ...defaultOpts, delete: false } : null,
    // __old: comment,
  };
  return newComment;
}

function facebookCommentMapper(comment, level) {
  let d = moment(comment.created_time);
  if (!d.isValid()) {
    d = moment(parseInt(comment.created_time, 10));
  }
  const newComment = {
    isPending: comment.isPending || false,
    commentId: comment.id,
    graphId: comment.id,
    isMention: comment.isMention || false,
    targetId: null,
    channelId: comment.channelId || comment.channel_id || null,
    postId: comment.media_id,
    parentId: comment.parent_id || null,
    closed: comment.closed || false,
    mediaFBId: comment.mediaFBId || comment.media_id || comment.postId || null,
    muted: comment.muted || false,
    text: comment.text || comment.message || '',
    level: level || comment.parent_id ? 1 : 0,
    attachments: comment.attachments || [],
    createdAt: d.valueOf(),
    publishedAt: d.valueOf(),
    closedAt: new Date(comment.closedTime).getTime() || null,
    uniType: 'isComment',
    source: 'facebook',
    reactions: comment.reactions || [],
    // __childs: comment.replies ? comment.replies.map((rep) => instagramCommentMapper(rep, 1)) : [],
    canComment: getFalsyValue(comment.can_comment),
    canLike: comment.can_like || true,
    isHidden: comment.hidden || false,
    mentions: comment.message_tags && comment.message_tags.map((tag) => {
      return {
        participantId: tag.id,
        offset: tag.offset,
        length: tag.length,
        label: tag.name,
        // eslint-disable-next-line no-script-url
        href: tag.type === 'page' ? `https://facebook.com/${tag.id}` : null,
      };
    }),
    from: comment.from ? {
      participantId: comment.from.id || null,
      username: null,
      fullName: comment.from.name,
      link: comment.from.link || null,
      profilePicture: get(comment, 'from.picture.data.url'),
    } : null,
    // __old: comment,
    options: comment.isMention ? { ...defaultOpts, delete: false } : null,
  };
  return newComment;
}

function postAsCommentMapper(post) {
  const comment = {
    ...post,
    text: post.caption || 'Post without text',
    username: post.username,
    id: post.graphId,
    media_id: post.postId,
    parent_id: null,
  };

  let mapped;
  if (comment.source === 'instagram') {
    mapped = instagramCommentMapper({
      ...comment,
      timestamp: post.createdAt,
    });
  } else {
    mapped = facebookCommentMapper({
      ...comment,
      created_time: post.createdAt,
      from: {
        name: 'Post',
        id: '123',
        fullName: 'Post',
        link: post.permalink,
      },
      can_comment: false,
    });
  }
  return {
    ...mapped,
    from: {
      ...mapped.from,
      profilePicture: post.thumbnail_url || post.media_url,
      isVideo: post.media_type === 'VIDEO',
    },
    isPost: true,
  };
}

export {
  postAsCommentMapper,
  instagramCommentMapper,
  facebookCommentMapper,
};
