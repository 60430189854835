<template>
  <section
    id="TheHome"
    class="flex"
  >
    <TheSidebar :menu-items="ubuMenuItems" />
    <div class="UbuInfluenceHomePage_wrapper">
      <header class="UbuInfluenceHomePage_header">
        <h1 class="ubu-title">Home</h1>
        <b-button
          label="Go to newsletter"
          class="is-geraldine is-rounded"
          tag="a"
          target="_blank"
          href="https://www.sellingsocial.blog/"
        />
      </header>
      <section class="UbuInfluenceHomePage_section">
        <h2 class="UbuInfluenceHomePage_section-title">Discover Ubu 🚀</h2>
        <div class="UbuInfluenceHomePage_cards">
          <div
            v-for="tutorial in tutorials"
            :key="tutorial.title"
            class="UbuInfluenceHomePage_card"
          >
            <div class="UbuInfluenceHomePage_card-header">
              <p class="UbuInfluenceHomePage_card-title ubu-text3">
                {{ tutorial.title }}
              </p>
            </div>
            <div
              class="UbuInfluenceHomePage_card-thumbnail"
              :style="`background-image: url('${tutorial.thumbnailUrl}')`"
            >
              <a
                class="UbuInfluenceHomePage_card-btnPlay"
                :href="tutorial.href"
                target="_blank"
              >
                <UbuIconButton
                  icon="play"
                  is-active
                  filled
                  color="3"
                  :style="'font-size: 20px;'"
                />
              </a>
            </div>
          </div>
        </div>
      </section>
      <section class="UbuInfluenceHomePage_section">
        <h2 class="UbuInfluenceHomePage_section-title">What’s new ? 💪</h2>
        <div
          v-for="(items, index) in newsItems"
          :key="index"
          class="UbuInfluenceHomePage_content"
        >
          <div class="multibar">
            <UbuIcon
              :name="items.icon"
              outlined
              :color="items.color"
              size="30px"
              class="start"
            />
            <div class="start">
              <p class="UbuInfluenceHomePage_content-title ubu-header3">
                {{ items.title }}
              </p>
              <p class="UbuInfluenceHomePage_content-description ubu-text3">
                {{ items.description }}
              </p>
              <p class="UbuInfluenceHomePage_content-date ubu-text3">
                {{ items.date }}
              </p>
            </div>
          </div>
        </div>
      </section>

      <div class="ubu-divider" />
      <!-- <section class="UbuInfluenceHomePage_section">
        <h2 class="UbuInfluenceHomePage_section-title">Coming soon ⏳</h2>
        <div
          v-for="(items, index) in comingItems"
          :key="index"
          class="UbuInfluenceHomePage_content"
        >
          <div class="multibar">
            <UbuIcon
              :name="items.icon"
              outlined
              :color="items.color"
              size="30px"
              class="start"
            />
            <div class="start">
              <p class="UbuInfluenceHomePage_content-title ubu-header3">
                {{ items.title }}
              </p>
              <p class="UbuInfluenceHomePage_content-description ubu-text3">
                {{ items.description }}
              </p>
            </div>
          </div>
        </div>
      </section>
      <div class="ubu-divider" /> -->
    </div>
  </section>
</template>

<script>
/* eslint-disable object-curly-newline */
import CommonMixin from '../$mixins/CommonMixin';

export default {
  name: 'TheHome',
  mixins: [CommonMixin],
  data() {
    return {
      ubuMenuItems: [
        {
          text: 'What\'s new',
          icon: 'home',
          href: '/home',
        },
        {
          text: 'Workspace',
          icon: 'settings',
          href: '/workspace',
        },
        {
          text: 'Account',
          icon: 'account',
          href: '/account',
        },
      ],
      tutorials: [
        { title: 'Inbox tutorial', thumbnailUrl: 'img/coming_soon/Ubu_Tutorial_1.png', href: 'https://www.loom.com/share/75d3bc001dbf45d1a8470ccc5f35612b' },
        { title: 'Activities tutorial', thumbnailUrl: 'img/coming_soon/Ubu_Tutorial_2.png', href: 'https://www.loom.com/share/823316cdacdc4076afe5666bbafb3731' },
        { title: 'Workspace tutorial', thumbnailUrl: 'img/coming_soon/Ubu_Tutorial_3.png', href: 'https://www.loom.com/share/5087e573b3034124855f7ff3d2fead3d' },
      ],
      comingItems: [
        { icon: 'inbox', color: 11, title: 'Multi-channel integration', description: 'Manage your interactions coming from Facebook & TikTok.' },
        { icon: 'inbox', color: 2, title: 'E-commerce integration', description: 'See the order history of people you’re talking to.' },
        { icon: 'settings', color: 3, title: 'Team member assignation', description: 'Assign a teammate to deal with a specific conversation.' },
        { icon: 'influence', color: 4, title: 'Analytics of Influence campaigns', description: 'Get access of all content and statistics about each your influence campaign.' },
      ],
      newsItems: [
        { icon: 'inbox', color: 12, title: 'Compose new messages', description: 'From your Inbox, you can now send an Instagram message to a person you\'ve never talked to before!', date: '2021/09/02' },
        { icon: 'settings', color: 11, title: 'Translations', description: 'Translate messages and comments you receive in up to 4 languages (for now).', date: '2021/09/02' },
        { icon: 'activity', color: 10, title: 'File names in downloads', description: 'When downloading content from the app, file names will now be shown.', date: '2021/09/02' },
        { icon: 'influence', color: 9, title: 'New CRM filters', description: 'We\'ve added new powerful filters to enhance your workflow.', date: '2021/09/02' },
        { icon: 'activity', color: 8, title: 'Brand new graph to better understand your metrics !', description: 'Enjoy a new detailed graph that will help you track your performance and understand your growth.', date: '2021/07/25' },
        { icon: 'activity', color: 1, title: 'Download all mentions !', description: 'You can now download all your mentions from both Activity and Influence in a .zip file.', date: '2021/07/25' },
        { icon: 'influence', color: 2, title: 'New CRM interface.', description: 'We just shipped a friendlier and more powerful CRM so it\'s easy for you to find anyone in your community and engage them.', date: '2021/07/25' },
        { icon: 'influence', color: 3, title: 'Get lists of fresh influencers.', description: 'You can now create lists of influencers to engage in Influence.', date: '2021/07/25' },
        { icon: 'inbox', color: 4, title: 'Assign teammates !', description: 'You can now assign conversations directly to anyone in your team instead of using tags.', date: '2021/07/13' },
        { icon: 'inbox', color: 5, title: 'Search bar improvements.', description: 'We’ve drastically improved our search bar to be as accurate as possible when searching for names & usernames.', date: '2021/07/13' },
        { icon: 'influence', color: 6, title: 'Analytics', description: 'Get more analytics on your account from the activities section (powerful graphs and custom reports are coming soon)', date: '2021/07/13' },
        { icon: 'activity', color: 7, title: 'Search and generate reports, for anyone.', description: 'Go over to the Influence page => audit and start generating detailed reports for any account you want.', date: '2021/07/13' },
        { icon: 'settings', color: 8, title: 'New shortcut is here !', description: 'Depending on where you are in the app, use Cmd + Enter on mac or Ctrl + Enter on windows to trigger one of the following actions: Close/Open - Send & Close - Done - Send & Done.', date: '2021/07/13' },
        { icon: 'inbox', color: 2, title: 'Send emoji and {close or done}', description: 'You can now send an emoji while hovering over the “close” or “done” button. The conversation will be closed or done after the emoji is sent.', date: '2021/07/13' },
        { icon: 'inbox', color: 9, title: 'The instagram inbox you deserve: powerful & elegant.', description: 'We’ve updated our branding and design to make Ubu more enjoyable for you to use. Things will keep looking better and better in weeks to come and your feedback is well appreciated. Please send your feedback to enzo@getubu.com', date: '2021/06/16' },
        { icon: 'influence', color: 10, title: 'A brand new experience to better manage your campaigns ! ', description: 'Ubu Influence has never been more powerful. Rapidly send mass messages to influencers and easily track the progress of your conversations from your campaign pipeline. Find all the mentions from each campaign and get reports on how your ambassadors performed.', date: '2021/06/16' },
        { icon: 'activity', color: 11, title: 'A gallery of people who love you...', description: 'Enjoy a delightful gallery with all the content you’re mentionned in, stories or posts. Connect to your Google Drive to save all your content forever.', date: '2021/06/16' },
        { icon: 'comments', color: 12, title: 'A brand new comments space.', description: 'Enjoy a new board to manage your comments directly in Ubu !', date: '2021/06/16' },
        { icon: 'settings', color: 4, title: 'The dark side of the force is a pathway to many abilities...', description: 'Dark mode is finally here. You can turn it on by going to “Workspace” => “Theme”.', date: '2021/06/16' },
      ],
    };
  },
};
</script>
