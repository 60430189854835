import ErrorHandler from '@/addons/$utils/errors';
import store from '@/store';

export default {
  parseResponse: (response) => {
    if (response.data.success) {
      return ({ response: response.data.result || true });
    }
    const err = {
      error: {
        message: response.data.reason,
        status: 'BackendError',
        statusText: response.data.error,
        ...response.data,
      },
    };
    ErrorHandler.generic({ dispatch: store.dispatch }, err);
    return err;
  },
  parseError: (error) => {
    if (error.response) {
      return ({
        error: {
          message: error.response.data,
          status: error.response.status,
          statusText: error.response.statusText,
        },
      });
    }
    return {
      error: {
        message: 'network',
        status: 500,
        statusText: 'error',
      },
    };
  },
};
