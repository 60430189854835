<template>
  <div
    id="UbuCommentTextareaHeader"
  >
    <div class="ubuTextareaHeaderCard">
      <div class="card-content">
        <div
          class="card-avatar"
          style="min-width: 36px;"
        >
          <b-image
            v-if="profilePicUrl"
            :key="profilePicUrl"
            class="card-avatar-image"
            style="min-width: 36px;"
            ratio="1by1"
            :src="profilePicUrl"
            src-fallback="https://upload.wikimedia.org/wikipedia/commons/5/59/Empty.png"
          />
          <div
            v-else
            class="card-avatar-unfoundable"
          >
            <UbuIcon
              name="placeholder"
              filled
            />
          </div>
        </div>
        <div class="card-text-box">
          <div class="card-title ubu-text2">
            <UbuIcon
              name="replies"
              color="8"
            />
            Replying to {{ username }}
          </div>
          <div class="card-text ubu-text1">
            {{ text }}
          </div>
        </div>
      </div>
      <UbuIcon
        name="cross"
        clickable
        @click="$emit('cancelReply', $event)"
      />
    </div>
    <div class="ubu-divider comments" />
  </div>
</template>

<script>
export default {
  name: 'UbuCommentTextareaHeader',
  props: {
    from: {
      type: Object,
      default: null,
    },
    text: {
      type: String,
      required: true,
    },
  },
  computed: {
    username() {
      if (!this.from) {
        return 'Missing name';
      }
      if (this.from.username) {
        return `@${this.from.username}`;
      }
      return (this.from.fullName);
    },
    profilePicUrl() {
      if (this.from && this.from.profilePicture) {
        return this.from.profilePicture;
      }
      return null;
    },
  },
};
</script>
