<template>
  <div
    v-if="!isError"
    class="UbuMentionSmall_wrapper"
  >
    <div
      v-if="isLoading || !isImageLoaded"
      class="UbuMentionSmall_skeleton"
    >
      <b-skeleton
        :rounded="false"
        :active="isLoading || !isImageLoaded"
        height="400px"
      />
    </div>
    <div v-if="media">
      <div class="UbuMentionSmall_header">
        <span class="UbuMentionSmall_date ubu-text2">
          {{ $moment(media.createdOn).format('YYYY-MM-DD') }}
        </span>
        <a
          :href="media.downloadUrl"
          target="_blank"
        >
          <UbuIcon
            class="UbuMentionSmall_downloadIcon"
            name="download"
          />
        </a>
      </div>
      <img
        v-if="isImage"
        class="UbuMentionSmall_image"
        :src="media.url"
        @error="hasImageError = true"
        @load="onImgLoad()"
        @click="$emit('showMention', media)"
      >
      <video
        v-else
        ref="video"
        class="UbuMentionSmall_video"
        :controls="isModal"
        @loadeddata="onImgLoad()"
        @click="$emit('showMention', media)"
      >
        <source
          :src="media.url"
          :type="'video/mp4'"
          @error="hasVideoError = true"
        >
        Sorry, your browser doesn't support embedded videos.
      </video>
    </div>
  </div>
</template>
<script>
import routes from '../../../../conf/routes';

export default {
  name: 'UbuMentionSmall',
  props: {
    media: {
      type: Object,
      default: () => ({}),
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      routes,
      isImageLoaded: false,
      hasVideoError: false,
      hasImageError: false,
    };
  },
  computed: {
    isError() {
      return this.hasVideoError && this.hasImageError;
    },
    isImage() {
      if (this.hasVideoError) return true;
      if (this.hasImageError) return false;
      return this.media.isImage;
    },
  },
  methods: {
    onImgLoad() {
      this.isImageLoaded = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.UbuMentionSmall_wrapper {
  border-radius: 12px;
  background-color: grey;
  position: relative;
  overflow: hidden;
  line-height: 0;
  .UbuMentionSmall_skeleton {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 2;
    background-color: var(--seashellpeach);
  }
  .UbuMentionSmall_header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10.5px 13px 10.5px 8px;
    z-index: 1;
    text-shadow: 0px 0px 5px black;
        .UbuMentionSmall_date {
      color: #fffaf8;
    }
    .UbuMentionSmall_downloadIcon {
      font-size: 17px;
      cursor: pointer;
      color: #fffaf8;
      transition: transform .4s cubic-bezier(0.34, 1.56, 0.64, 1);
      &:hover {
        transform: translateY(2px);
      }
      &:active {
        transform: translateY(5px);
      }
    }
  }
  .UbuMentionSmall_image {
    border-color: transparent;
    border-radius: 12px;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .UbuMentionSmall_video {
    border: none;
    border-radius: 12px;
    height: 100%;
    width: 100%;
  }
}
</style>
