<template>
  <div>
    <div class="multibar row-vcentered">
      <UbuContactCard
        :contact="row"
        class="start"
      />

      <div class="end control multibar row-vcentered">
        <div class="flex ubu-f-center">
          <b-tooltip
            label="Removed"
            position="is-left"
          >
            <UbuIcon
              v-if="row.ended"
              class="end flex ubu-f-center"
              size="24px"
              color="2"
              name="failure"
              filled
              @click="$emit('toggleOpenedRow', row.username)"
            />
          </b-tooltip>
        </div>

        <div
          v-if="hasMentions"
          class="end"
        >
          <b-tooltip
            :label="labelTooltip(row)"
            position="is-left"
          >
            <button class="button is-rounded has-campaign-color-light">
              <UbuIcon
                size="16px"
                name="mentions"
                outlined
              />
            </button>
          </b-tooltip>
        </div>

        <div
          v-if="row.data && row.data.scheduledSend && fromStatus === 'scheduled'"
          class="end"
        >
          <div class="scheduled-box">
            {{ $moment(row.data.scheduledSend).format('HH:mm') }}
          </div>
        </div>

        <UbuIcon
          v-if="hasDetail"
          class="end"
          clickable
          size="20px"
          name="more"
          @click="$emit('toggleOpenedRow', row.username)"
        />
      </div>
    </div>

    <UbuInfluencePipelineButtonChangeStatus
      v-if="hasDetail && openedDetailRow === row.username"
      :username="row.username"
      :from-status="fromStatus"
      @changeStatus="$emit('changeStatus', $event)"
      @deleteActivity="$emit('deleteActivity', row)"
      @showMessageSent="$emit('showMessageSent', row)"
    />
  </div>
</template>

<script>
export default {
  name: 'UbuInfluencePipelineTableRow',
  props: {
    row: {
      type: Object,
      required: true,
    },
    openedDetailRow: {
      type: String,
      required: true,
    },
    fromStatus: {
      type: String,
      required: true,
    },
    hasDetail: {
      type: Boolean,
      default: false,
    },
    hasMentions: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    labelTooltip(row) {
      // //console.log('🚀 ~ file: UbuInfluencePipelineTableRow.vue ~ line 83 ~ labelTooltip ~ row', row);
      const { mentions, tagsPosts } = row;
      const countM = mentions.length;
      const countT = tagsPosts.length;
      const mentionMsg = countM > 1 ? `${countM} mentions` : `${countM} mention`;
      const tagMsg = countT > 1 ? `${countT} tags` : `${countT} tag`;
      return `${mentionMsg} ${tagMsg}`;
    },
  },
};
</script>

<style lang="scss">
  .control {
    & > * {
      margin: 0 4px;

      & .scheduled-box {
        padding: 0 12px;
        height: 34px;
        border: 1px solid var(--mercury);
        border-radius: 6px;
        color: var(--darkgrey);
        font-size: 0.75rem;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      & .button {
        height: 24px;
        width: 24px;
      }
    }
  }
</style>
