/* eslint-disable max-len */
import { convertColor } from '../../$utils/formate';

const getTitleListWithoutDuplicate = (list, key) => {
  if (list.length) {
    return list
      .reduce((acc, row) => [
        ...acc,
        ...row[key].reduce((_acc, { title }) => (!acc.includes(title)
          ? [..._acc, title.trim()]
          : _acc), [])], []).sort();
  }
  return [];
};

const getAudienceListWithoutDuplicate = (list, key) => {
  if (list.length) {
    return list.reduce((acc, row) => {
      if (row[key] && !acc.includes(row[key].name)) acc.push(row[key].name);
      return acc;
    }, []).sort();
  }
  return [];
};

const parseAndKeepMaxOccurrence = ({ audience }, key) => {
  if (!audience) return null;
  try {
    return JSON.parse(audience)[key].length ? JSON.parse(audience)[key]
      .reduce((prev, current) => ((prev.occurrences > current.occurrences)
        ? prev : current)) : null;
  } catch (e) {
    return audience[key].length ? audience[key]
      .reduce((prev, current) => ((prev.occurrences > current.occurrences)
        ? prev : current)) : null;
  }
};

const getListWithoutDuplicate = (list, key) => list
  .reduce((acc, row) => {
    // console.log('🚀 ~ file: filterManagement.js ~ line 37 ~ .reduce ~ row', row);
    if (row[key] && !acc.includes(row[key])) acc.push(row[key]);
    return acc;
  }, []).sort();

const filtersAvailable = (filters, columns) => filters.reduce((acc, row) => {
  // display filter if column is active
  if (columns.includes(row.name)) acc.push(row);
  return acc;
}, []);

const mapActivitiesFromCampaign = ({ activities }, isEnded) => (activities.length
  ? activities
    .filter(({ ended }) => (isEnded ? ended : !ended))
    .map((act) => ({
      title: act.campaignDetail.name,
      color: convertColor(act.campaignDetail.color),
      campaignId: act.campaignDetail.campaignId,
      activityId: act.activityId,
      campaignStep: act.status,
    })) : []);

const testDataLocation = ({ location, locations }) => {
  let locationsFirst = null;
  if (locations && locations.length) {
    locationsFirst = locations[0]?.name || null;
  }
  if (!location || location === null) return locationsFirst;
  if (!location.trim().length) return locationsFirst;
  if (location.includes('undefined/undefined')) return locationsFirst;
  if (location.includes('null/null')) return locationsFirst;
  return location;
};

const filterByContactId = (array, id) => array.filter(({ contactId }) => contactId === id);

const calculateShopifyReferralAndDiscountCount = ({ referralLinks, discountCodes }) => referralLinks.length + discountCodes.length;

const calculateShopifyOrderCount = ({ referralLinks, discountCodes }) => discountCodes.concat(referralLinks).reduce((acc, { order }) => acc + order.length, 0);

const calculateShopifyAmountOrders = ({ referralLinks, discountCodes }) => discountCodes.concat(referralLinks).reduce((acc, { order }) => {
  if (order.length) {
    // divise par 100 amountUsd est en cents
    // eslint-disable-next-line no-param-reassign
    order.forEach(({ amountUsd }) => { acc += Math.round((amountUsd / 100)); });
  }
  return acc;
}, 0);

const parseGender = ({ gender }) => {
  // if (!gender) return null;
  if (Number(gender) === 1) return 'Male';
  if (Number(gender) === 2) return 'Female';
  return null;
};

const parseAge = ({ age }) => {
  if (!age || Number(age) === 0) return null;
  const isRange = age.includes('-');
  if (isRange) {
    const splitRange = age.split('-');
    const [min, max] = splitRange;
    const average = (Number(min) + Number(max)) / 2;
    return Math.round(average);
  }
  return Math.round(Number(age));
};

const parseEmail = (account) => {
  const email = account.email || account.business_email || account.public_email || null;
  if (email === 'NA' || email === 'NA1' || email === 'NA2' || email === 'NA3') return null;
  return email;
};

export {
  getTitleListWithoutDuplicate,
  parseAndKeepMaxOccurrence,
  getAudienceListWithoutDuplicate,
  getListWithoutDuplicate,
  filtersAvailable,
  mapActivitiesFromCampaign,
  testDataLocation,
  filterByContactId,
  calculateShopifyReferralAndDiscountCount,
  calculateShopifyOrderCount,
  calculateShopifyAmountOrders,
  parseGender,
  parseAge,
  parseEmail,
};
