<template>
  <div id="UbuCommentThreadListHeader">
    <div v-if="!bulks.length">
      <div class="ubuThreadList-mainAction">
        <UbuButton
          grow
          center
          :active="!showClosedThreads"
          simple
          @click="$emit('changeOpenCloseFilter', false)"
        >
          Open
        </UbuButton>
        <UbuButton
          grow
          center
          :active="showClosedThreads"
          simple
          @click="$emit('changeOpenCloseFilter', true)"
        >
          Closed
        </UbuButton>
        <b-button
          v-if="hasMediaSelected"
          class="ubuButton is-rounded is-light threadRefresh"
          @click="$emit('refreshComments')"
        >
          <UbuIcon
            :class="[{ 'refresh-spin' : isThreadLoading }]"
            name="refresh"
            outlined
            size="16px"
          />
        </b-button>
      </div>

      <UbuCommentThreadListHeaderFilters
        v-model="localSearch"
        :filter-box-is-open="filterBoxIsOpen"
        :is-bulk-pending="isBulkPending"
        :show-close-all="!showClosedThreads"
        @bulksAction="$emit('bulksAction', $event)"
        @closeAll="$emit('closeAll', $event)"
        @toggleFilterBoxIsOpen="filterBoxIsOpen = $event"
      />
      <div v-if="filterBoxIsOpen">
        <UbuSimpleDropdown
          label="Sort by"
          :options="sortOptions"
          :value="sort"
          class="m-2"
          @input="$emit('updateSort', $event)"
        />
      </div>
    </div>

    <div
      v-else
      class="ubuThreadList-bulks"
    >
      <div class="ubuThreadList-bulkHeader">
        <a
          v-if="bulks.length < threadListFilteredByOpenClose.length"
          class="text-geraldine ubu-text1"
          @click="$emit('addAllToBulks', $emit)"
        >
          Select All
        </a>
        <a
          v-else
          class="text-geraldine ubu-text1"
          @click="$emit('clearBulks', $event)"
        >
          Unselect All
        </a>
        <a
          class="text-darkgrey60 ubu-text1"
          @click="$emit('clearBulks', $event)"
        >
          Cancel
        </a>
      </div>
      <div class="ubuThreadList-bulkActions">
        <slot
          name="bulkActions"
          :bulks="bulks"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UbuCommentThreadListHeader',
  model: {
    prop: 'search',
  },
  props: {
    search: {
      type: String,
      required: true,
    },
    showClosedThreads: {
      type: Boolean,
      default: false,
    },
    hasMediaSelected: {
      type: Boolean,
      default: false,
    },
    bulks: {
      type: Array,
      default: () => [],
    },
    threadListFilteredByOpenClose: {
      type: Array,
      default: () => [],
    },
    sortOptions: {
      type: Array,
      default: () => [],
    },
    sort: {
      type: String,
      default: 'asc:timestamp',
    },
    isThreadLoading: {
      type: Boolean,
      default: false,
    },
    isBulkPending: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filterBoxIsOpen: false,
    };
  },
  computed: {
    localSearch: {
      get() { return this.search; },
      set(value) { this.$emit('input', value); },
    },
  },
};
</script>
