var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"UbuWorkspaceConnectionCard"}},[_c('div',{staticClass:"multibar row-vcentered"},[_c('b-image',{class:['card-icon start', { active: _vm.item.isActive }],attrs:{"rounded":"","src":_vm.item.iconPath}}),_c('div',{staticClass:"card-body start"},[_c('h1',{staticClass:"card-title"},[_vm._v(_vm._s(_vm.item.title))]),_c('p',{staticClass:"card-text"},[_vm._v(_vm._s(_vm.item.text))])]),(_vm.submenu)?_c('UbuSimpleDropdown',{staticStyle:{"max-width":"30%"},attrs:{"clearable":"","help":_vm.submenu.help,"label":_vm.submenu.title,"options":_vm.submenu.choices,"value":_vm.submenu.value},on:{"input":function ($event) { return _vm.submenu.onChoose($event); }}}):_vm._e(),_c('UbuButton',_vm._g(_vm._b({staticClass:"end is-light is-rounded"},'UbuButton',_vm.item.linkRedirect && {
        tag: 'a',
        href: _vm.item.linkRedirect,
        target: '_blank',
        color: (_vm.item.isActive && 5) || undefined
      },false),!_vm.item.linkRedirect ? {
        click: function () { return _vm.$emit('openModal', _vm.item); },
      } : {}),[_c('UbuIcon',_vm._b({attrs:{"slot":"icon","color":_vm.item.isActive ? 5 : 2},slot:"icon"},'UbuIcon',{
          type: 'char',
          name: '•',
          size: 20,
          strong: true,
        },false)),_vm._v(" "+_vm._s(_vm.item.isActive ? 'Connected' : 'Connect')+" ")],1)],1),(_vm.item.connectedShop && _vm.item.connectedShop.length)?_c('div',{staticClass:"content"},[_vm._v(" Store already connected: "),_c('ul',_vm._l((_vm.item.connectedShop),function(shop){return _c('li',{key:shop.shopifyShopSsid,staticClass:"ubu-info"},[_vm._v(" "+_vm._s(shop.shopExtrefId)+" ")])}),0)]):_vm._e(),_c('div',{staticClass:"ubu-divider"})])}
var staticRenderFns = []

export { render, staticRenderFns }