<template>
  <div :class="['UbuThreadCard_wrapper', { 'UbuThreadCard_wrapperSelected': selected }]">
    <!-- <div
      class="UbuThreadCard_avatar"
      @click="$emit('onBulkSelect', $event)"
    >
      <img
        v-if="contact"
        class="UbuThreadCard_checkMark"
        src="@/assets/pictos/is_ok.svg"
      >
      <UbuInstaImg
        v-else
        class="UbuThreadCard_avatarImg"
        :insta-src="contact.has_bucket_media ? String(contact.contactId) : contact.profile_pic_url"
        :has-bucket="contact.has_bucket_media || false"
        :bucket-folder="'profile_pic'"
        :fallback="'../../img/no_profile_pict.svg'"
        rounded
      />
    </div> -->
    <div
      class="UbuThreadCard_avatar"
      @click="$emit('onBulkSelect', $event)"
    >
      <div
        :class="['UbuThreadCard_avatarImg', {
          'UbuThreadCard_avatarSelected': isBulkSelected,
        }]"
      >
        <img
          v-if="isBulkSelected"
          class="UbuThreadCard_checkMark"
          src="@/assets/pictos/is_ok.svg"
        >
        <UbuInstaImg
          v-else
          class="UbuThreadCard_avatarImg"
          :insta-src="contact.has_bucket_media
            ? String(contact.contactId) : contact.profile_pic_url"
          :has-bucket="contact.has_bucket_media || false"
          :bucket-folder="'profile_pic'"
          :fallback="'../../img/no_profile_pict.svg'"
          rounded
        />
      </div>
    </div>
    <div
      class="UbuThreadCard_textBox"
      @click="$emit('onSelect', $event)"
    >
      <div
        :class="[
          'UbuThreadCard_textRow',
          'ubu-text1',
          displaybleContactGroup ? `text-color-${displaybleContactGroup.color}` : ''
        ]"
      >
        <UbuIcon
          v-if="displaybleContactGroup"
          filled
          name="contact"
        />
        <span>
          {{ displayableName }}
        </span>
      </div>
      <div
        :class="[
          'UbuThreadCard_textRow',
          'ubu-text2',
          displaybleThreadGroup ? `text-color-${displaybleThreadGroup.color}` : ''
        ]"
      >
        <UbuIcon
          v-if="displaybleThreadGroup"
          filled
          name="tag"
        />
        <span>
          {{ lastNotification }}
        </span>
      </div>
    </div>
    <div class="UbuThreadCard_right">
      <span
        class="UbuThreadCard_date"
        style="white-space: nowrap;"
      >{{ displaybleDate }}</span>
      <b-icon
        v-if="!lastItem.isMe && isUnread"
        icon="circle"
        class="ubuCircleRed"
      />
      <UbuIcon
        v-else-if="lastItem.isMe"
        filled
        size="12px"
        name="send"
      />
    </div>
  </div>
</template>

<script>
import { timeToNow } from '../../$utils/dates';

export default {
  name: 'UbuInfluenceMessageThreadCard',
  props: {
    lastItem: {
      type: Object,
      required: true,
    },
    contact: {
      type: Object,
      required: true,
    },
    contactGroup: {
      type: Array,
      default: () => ([]),
    },
    threadGroup: {
      type: Array,
      default: () => ([]),
    },
    source: {
      type: String,
      default: 'instagram',
    },
    isUnread: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    isBulkSelected: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    displayableName() {
      if (!this.contact) return 'Unfoundable name';
      const { full_name: fullName, username } = this.contact;
      return fullName || username;
    },
    displaybleDate() {
      const { timestamp } = this.lastItem;
      // const date = stampToDatetime(timestamp);
      // console.log('🚀 ~ file: UbuInfluenceMessageThreadCard.vue ~ line 121 ~ displaybleDate ~ date', date);
      // return this.$moment(date).calendar({
      //   sameDay: 'HH:mm',
      //   lastDay: '[Yesterday]',
      // });
      const date = timeToNow(timestamp);
      return date;
    },
    displaybleContactGroup() {
      if (!this.contactGroup.length) {
        return false;
      }
      const [contactGroupToDisplay] = this.contactGroup;
      return contactGroupToDisplay;
    },
    displaybleThreadGroup() {
      if (!this.threadGroup.length) {
        return false;
      }
      const [threadGroupToDisplay] = this.threadGroup;
      return threadGroupToDisplay;
    },
    lastNotification() {
      if (this.lastItem) {
        const { isMe, uniType } = this.lastItem;

        switch (uniType) {
          /* eslint-disable no-multi-spaces */
          case 'isText':            return this.lastItem.text;
          case 'isStoryMention':    return isMe ? 'mentioned them in your story'  : 'mentioned you in their story';
          case 'isStoryReact':      return isMe ? 'you reacted to their story'    : 'reacted to your story';
          case 'isStoryReply':      return isMe ? 'you replied to their story'    : 'replied to your story';
          case 'isMediaImage':      return isMe ? 'you shared a media'            : 'shared a media';
          case 'isMediaVideo':      return isMe ? 'you shared a video'            : 'shared a video';
          case 'isClip':            return isMe ? 'you shared a clip'             : 'shared a clip';
          case 'isAnimatedMedia':   return isMe ? 'you shared a GIF'              : 'shared a GIF';
          case 'isVoiceMedia':      return isMe ? 'you shared an audio'           : 'shared an audio';
          case 'isStoryShare':      return isMe ? 'you shared a story'            : 'shared a story';
          case 'isPostShare':       return isMe ? 'you shared a post'             : 'shared a post';
          case 'isProductShare':    return isMe ? 'you shared a product'          : 'shared a product';
          case 'isProfileShare':    return isMe ? 'you shared a profile'          : 'shared a profile';
          case 'isLink':            return isMe ? 'you shared a link'             : 'shared a link';
          case 'isFelixShare':      return isMe ? 'you shared an IGTV'            : 'shared an IGTV';
          case 'isActionLog':       return isMe ? 'you liked a message'           : 'liked a message';
          case 'isComment':         return isMe ? 'you comment a post'            : 'commented a post';
            /* eslint-enable no-multi-spaces */
          default: return 'message not supported';
        }
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
$lend: 95%;
$lstart: 60%;

.UbuThreadCard_wrapper {
  cursor: pointer;
  display: flex;
  border-radius: 12px;
  &.UbuThreadCard_wrapperSelected {
    background-color: var(--givry);
    .UbuThreadCard_textBox {
      &::after {
        background: linear-gradient(to right, var(--givry00) $lstart, var(--givry) $lend);
      }
    }
    .UbuThreadCard_avatar {
      .UbuThreadCard_avatarImg {
        .UbuThreadCard_source {
          background-color: var(--givry);
        }
      }
    }
  }

  &:hover:not(.UbuThreadCard_wrapperSelected) {
    background-color: var(--peachcream);
    .UbuThreadCard_textBox {
      &::after {
        background: linear-gradient(to right, var(--peachcream00) $lstart, var(--peachcream) $lend);
      }
    }
    .UbuThreadCard_avatar {
      .UbuThreadCard_avatarImg {
        .UbuThreadCard_source {
          background-color: var(--peachcream);
        }
      }
    }
  }

  .UbuThreadCard_avatar {
    padding: 14px 8px;
    .UbuThreadCard_avatarImg {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: var(--geraldine);
      background-size: cover;
      box-sizing: border-box;
      &.UbuThreadCard_broSelected {
        border: 1px solid var(--mercury);
        background-color: transparent;
      }

      .UbuThreadCard_source {
        position: absolute;
        right: -3px;
        top: 31px; //calcul à refaire
        height: 24px;
        width: 24px;
        border-radius: 50%;
        background-color: var(--soapstone);
        //display: flex;
        display: none;
        justify-content: center;
        align-items: center;
        img {
          width: 12px;
          height: 12px;
        }
      }
    }
  }

  .UbuThreadCard_textBox {
    padding: 12px 8px 12px 0;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-around;
    flex-grow: 1;
    overflow-wrap: break-word;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &::after {
        content: ' ';
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 100%;
        background: linear-gradient(to right, var(--soapstone00) $lstart, var(--soapstone) $lend,);
    }

    .UbuThreadCard_textRow {
      display: flex;
      align-items: center;
      .UbuIcon_wrapper {
        font-size: 15px;
        margin-right: 6px;
      }
    }
  }

  .UbuThreadCard_right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;

    padding: 19px 8px 19px 0;
    .UbuThreadCard_date {
      font-weight: 500;
      font-size: 10px;
      line-height: 13px;
      color: var(--darkgrey)
    }

    .UbuThreadCard_badge {

    }
  }
}
</style>
