<template>
  <div id="UbuCRMTableCustomCell">
    <!-- <template v-if="groups.length === 1">
      <div
        v-for="(tag, index) in groups"
        :key="index"
        attached
        class="control"
      >
        <UbuCRMTableTag
          :tag="tag"
          has-icon-tag
          :icon-tag="{ name: iconName, size: '14px' }"
        />
      </div>
    </template> -->

    <b-tooltip
      :label="groups.map(({ title }) => title).join(' - ')"
      multilined
      size="is-large"
      position="is-right"
    >
      <!-- <b-tag
        rounded
      > -->
      <UbuIcon
        v-for="(tag, index) in groups"
        :key="index"
        :name="iconName"
        size="14px"
        :color="tag.color"
        filled
      />
      <!-- </b-tag> -->
    </b-tooltip>
  </div>
</template>

<script>
export default {
  name: 'UbuCRMTableCustomCell',
  props: {
    groups: {
      type: Array,
      default: () => [],
    },
    iconName: {
      type: String,
      default: 'tag',
    },
  },
};
</script>
