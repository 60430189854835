const lineSegmentOptions = {
  responsive: true,
  maintainAspectRatio: false,
  radius: 5,
  interaction: {
    intersect: false,
  },
  scales: {
    x: {
      grid: {
        // display: false,
        lineWidth: 0.5,
      },
    },
    y: {
      grid: {
      //   display: false,
        lineWidth: 0.5,
      },
      // beginAtZero: true,
    },
  },
  layout: {
    // padding: 10,
  },
  plugins: {
    legend: {
      display: false,
    },
  },
};

const lineSegmentConfig = (labels, data, gradient, color) => ({
  type: 'line',
  data: {
    labels,
    datasets: [{
      data,
      borderColor: color,
      pointBackgroundColor: color,
      pointHoverBackgroundColor: 'white',
      borderWidth: 1,
      pointBorderColor: 'white',
      pointHoverBorderColor: color,
      backgroundColor: gradient,
      fill: true,
      tension: 0.4,
      spanGaps: true,
    }],
  },
  options: lineSegmentOptions,
});

export {
  // eslint-disable-next-line import/prefer-default-export
  lineSegmentConfig,
};
