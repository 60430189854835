<template>
  <div id="UbuWorkspaceConnectionCard">
    <div class="multibar row-vcentered">
      <b-image
        rounded
        :class="['card-icon start', { active: item.isActive }]"
        :src="item.iconPath"
      />
      <div class="card-body start">
        <h1 class="card-title">{{ item.title }}</h1>
        <p class="card-text">{{ item.text }}</p>
      </div>

      <UbuSimpleDropdown
        v-if="submenu"
        clearable
        style="max-width: 30%"
        :help="submenu.help"
        :label="submenu.title"
        :options="submenu.choices"
        :value="submenu.value"
        @input="($event) => submenu.onChoose($event)"
      />

      <UbuButton
        class="end is-light is-rounded"
        v-bind="item.linkRedirect && {
          tag: 'a',
          href: item.linkRedirect,
          target: '_blank',
          color: (item.isActive && 5) || undefined
        }"
        v-on="!item.linkRedirect ? {
          click: () => $emit('openModal', item),
        } : {}"
      >
        <UbuIcon
          slot="icon"
          v-bind="{
            type: 'char',
            name: '•',
            size: 20,
            strong: true,
          }"
          :color="item.isActive ? 5 : 2"
        />
        {{ item.isActive ? 'Connected' : 'Connect' }}
      </UbuButton>
    </div>
    <div
      v-if="item.connectedShop && item.connectedShop.length"
      class="content"
    >
      Store already connected:
      <ul>
        <li
          v-for="shop in item.connectedShop"
          :key="shop.shopifyShopSsid"
          class="ubu-info"
        >
          {{ shop.shopExtrefId }}
        </li>
      </ul>
    </div>
    <div class="ubu-divider" />
  </div>
</template>

<script>
export default {
  name: 'UbuWorkspaceConnectionCard',
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => {},
    },
    submenu: {
      type: Object,
      default: () => null,
    },
  },
};
</script>
