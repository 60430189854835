<template>
  <div
    :class="
      ['UbuButton',{
        'UbuButton_grow': grow,
        'UbuButton_disabled': disabled,
        'UbuButton_loading': loading
      },]"
  >
    <div
      v-if="reactions && showReactions"
      class="UbuButton_reactions"
    >
      <div
        v-if="reactionsTooltip"
        class="reactions_tooltip"
      >
        <span>{{ reactionsTooltip }}</span>
      </div>
      <div class="reactions_list">
        <div
          v-for="(emoji, index) in reactions"
          :key="index"
          class="emoji"
          @click="$emit('react', emoji);"
        >
          {{ emoji }}
        </div>
      </div>
    </div>
    <b-tooltip
      :class="['is-flex', { 'UbuButton_grow': grow }]"
      v-bind="tooltip"
      :active="!!tooltip && !!tooltip.label"
    >
      <a
        ref="UbuButton"
        :class="['UbuButton_wrapper', 'ellipsis', {
          'UbuButton_active': active,
          'UbuButton_primary': primary,
          'UbuButton_secondary': secondary,
          'UbuButton_big': big,
          'UbuButton_center': center,
          'UbuButton_grow': grow,
          'UbuButton_dashed': dashed,
          'UbuButton_simple': simple,
          'UbuButton_disabled': disabled,
          'UbuButton_loading' : loading,
          'UbuButton_note': note,
          'UbuButton_template': template,
          'UbuButton_light': light,
        },color ? `UbuButton_color${color}`: '', textColor ?
          `UbuButton_text-color${textColor}`: '']"
        v-bind="$attrs"
        @click="onClick"
      >
        <div v-if="loading">
          <UbuLoader
            class="UbuButton_icon"
            width="25px"
            :color-name="color ? `color${color}` : null"
          />
        </div>
        <div
          v-else-if="icon || $slots.icon"
          :style="$slots.default ? 'margin-right: 6px' : 'white-space: nowrap'"
          :class="['UbuButton_icon', {
            'ellipsis': !$slots.default,
          }]"
        >
          <slot name="icon">
            <UbuIcon v-bind="_icon" />
          </slot>
        </div>
        <slot />
      </a>
      <div
        v-if="$slots.dropdown && active"
        class="UbuButton_dropdown"
      >
        <slot name="dropdown" />
      </div>
    </b-tooltip>
  </div>
</template>
<script>
// import UbuIcon from '@/ui/UbuIcon';

export default {
  name: 'UbuButton',
  props: {
    active: Boolean,
    primary: Boolean,
    secondary: Boolean,
    textOnly: Boolean,
    note: Boolean,
    template: Boolean,
    big: Boolean,
    color: [Number, String],
    textColor: [Number, String],
    center: Boolean,
    dashed: Boolean,
    icon: [Object, String],
    grow: Boolean,
    simple: Boolean,
    disabled: Boolean,
    light: Boolean,
    tooltip: {
      type: Object,
      default: () => {},
    },
    reactionsTooltip: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    reactions: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      showReactions: true,
    };
  },
  computed: {
    _icon() {
      if (typeof this.icon === 'string') { return { name: this.icon }; }
      return this.icon;
    },
  },
  methods: {
    onClick($event) {
      if (!this.loading && !this.disabled && !this.$attrs.href) { this.$emit('click', $event); }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/utils';
.UbuButton_grow {
  display: flex;
  flex-grow: 1;
  max-width: 100%;
}
.UbuButton {
  position: relative;
  cursor: pointer;

  & .UbuButton_disabled{
    cursor: not-allowed;
  }

  .UbuButton_dropdown {
      @extend .ubu-dropdown;
      max-height: 500px;
      overflow-y: auto;
      // width: 220px;
      // right: -90px;
  }

  &:hover:not(.UbuButton_disabled, .UbuButton_loading){
    .UbuButton_reactions{
      visibility: visible;
      opacity: 1;
      bottom: 34px;
    }
  }

  .UbuButton_reactions{
      visibility: hidden;
      padding-bottom: 8px;
      position: absolute;
      bottom: 28px;
      right: 0;
      opacity: 0;
      transition: all .1s ease-in .5s;
      .reactions_tooltip {
        background: var(--givry);
        border-radius: 4px;
        padding: 4px;
        font-size: 14px;
        font-weight: 600;
        color: var(--cloudburst);
        box-shadow: 0px 2px 5px var(--box-shadow);
        margin-bottom: 4px;
        text-align: center;
      }
  }

  .reactions_list {
    height: 40px;
    border-radius: 20px;
    padding: 4px 12px;
    display: flex;
    align-items: center;
    gap: 8px;
    background: var(--seashellpeach);
    box-shadow: 0px 2px 5px var(--box-shadow);
    .emoji{
      transition: transform .25s cubic-bezier(0.34, 1.56, 0.64, 1);
      &:hover {
        transform: scale(1.5);
        transform-origin: bottom;
      }
    }
  }

  &.UbuButton_flatHover:hover:not(.UbuButton_disabled, .UbuButton_loading) {
    @for $i from 1 through 12 {
      & .UbuButton_color#{$i} {
        color: var(--soapstone);
        background-color: var(--color#{$i});
      }
    }
  }
}
.UbuButton_wrapper {
  text-decoration: none;
  align-items: center;
  display: flex;
  position: relative;
  height: 34px;
  padding: 8px 12px;
  border: 1px solid transparent;
  border-radius: 12px;
  font-size: 0.85rem;
  line-height: 17px;
  font-weight: 600;
  box-sizing: border-box;

  &:not(.UbuButton_textOnly) {
    background-color: var(--mercury60);
  }
  color: var(--darkgrey);
  transition: all .1s ease-out;
  // border-color: var(--mercury);

  &.UbuButton_disabled, &.UbuButton_loading {
    opacity: .5;
  }

  .UbuButton_icon {
    font-size: 17.5px;
  }

  &.UbuButton_center {
    justify-content: center;
  }

  &.UbuButton_dashed {
    background: none;
    border: 1px dashed var(--darkgrey60);
    border-radius: 8px;

    &:hover {
      transform: none !important;
      border-color: var(--darkgrey);
    }

    &.UbuButton_active, &:focus {
      background: none;
      border-color: var(--geraldine);
      color: var(--geraldine);
    }
  }

  &.UbuButton_simple {
    background-color: transparent;
    border: none;
    color: var(--cloudbust);
    &.UbuButton_active {
        color: var(--cloudbust);
        background-color: var(--narvalo);
    }

    &:hover:not(.UbuButton_active) {
        color: var(--cloudbust);
        background-color: var(--narvalo60);
    }
  }

  &.UbuButton_light {
    background-color: transparent;
    border: none;
    color: var(--darkgrey);
    &.UbuButton_active {
        color: var(--darkgrey);
        background-color: var(--mercury60);
    }

    &:hover:not(.UbuButton_active) {
        color: var(--darkgrey);
        background-color: var(--mercury40);
    }
  }

  &.UbuButton_note {
    color: var(--cola);
    background-color: var(--cola20);
    &:hover {
      background-color: var(--cola30);
    }
    &:active {
      color: var(--soapstone);
      background-color: var(--cola);
    }
  }

  &.UbuButton_template {
    color: var(--cloudburst);
    background-color: var(--cloudburst20);
    &:hover {
      background-color: var(--cloudburst30);
    }
    &:active {
      color: var(--soapstone);
      background-color: var(--cloudburst);
    }
  }

  @for $i from 1 through 12 {
    &.UbuButton_color#{$i} {
      color: var(--color#{$i});
      background-color: var(--color#{$i}_lighten);
      // border: 1px solid var(--color#{$i}_lighten);
      border: none;
      &:hover {
        color: var(--color#{$i});
        background-color: var(--color#{$i}_lighten);
        // border-color: var(--color#{$i});
      }

      &.UbuButton_active {
        color: var(--soapstone);
        background-color: var(--color#{$i});
      }
    }

      &.UbuButton_text-color#{$i} {
        color: var(--color#{$i});
        border: none;
        &:hover {
          color: var(--color#{$i});
        }

        &.UbuButton_active {
          color: var(--color#{$i}) !important;
        }
      }
  }

  &.UbuButton_big {
    font-size: 18px;
    height: 45px;
  }

  &:focus {
    outline: none;
    color: var(--soapstone);
    background-color: var(--geraldine);
  }

  &:not(.UbuButton_disabled, .UbuButton_simple, .UbuButton_dashed, .UbuButton_loading
  , .UbuButton_light) {
    &.UbuButton_active, &:hover {
      transform: scale(1.05);
    }
    &.UbuButton_active, &:focus {
      color: var(--soapstone);
      background-color: var(--geraldine);
    }
  }

  &.UbuButton_primary {
    background-color: transparent;
    border-color: var(--geraldine);
    color: var(--geraldine);
  }

  &.UbuButton_secondary {
    color: var(--soapstone);
    &:not(.UbuButton_textOnly) {
      background-color: var(--geraldine);
    }
    border-color: var(--geraldine);

    &:hover:not(.UbuButton_disabled, .UbuButton_loading) {
      color: var(--geraldine);
      border-color: var(--geraldine);
      background-color: transparent;
    }

    &.UbuButton_active, &:focus {
      color: var(--geraldine);
      border-color: var(--geraldine);
      background-color: transparent;
      border-width: 2px;
    }
  }
}
</style>
