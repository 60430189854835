import {
  updateDatasIfExists,
  updateObjectRelationIfExists,
  addNewKeysToMap,
  removeKeyToMap,
} from '../$utils/dataMapper';
/* eslint-disable max-len */

function contactHasContactGroup(thread, contactGroup) {
  return thread.contactGroups.find((cg) => cg.contactGroupId === contactGroup.contactGroupId);
}

export default {
  namespaced: true,

  state: {
    contactGroups: {},
    contactsGroupsHasContactsBis: {},
  },

  getters: {
    getterContactGroups: (state) => state.contactGroups,
    getterContactGroupsHasContactBis: (state) => state.contactsGroupsHasContactsBis,
    getterCurrentContactGroups: (state) => Object.values(state.contactGroups),
  },

  actions: {
    removeContactGroupToContacts({ dispatch }, { contactGroup, threads }) {
      return Promise.all(threads
        .map((thread) => dispatch('removeContactGroupToContact', { thread, contactGroup })));
    },
    addContactGroupToContacts({ dispatch }, { contactGroup, threads }) {
      return Promise.all(threads
        .map((thread) => dispatch('addContactGroupToContact', { thread, contactGroup })));
    },
    removeContactGroupToContact({ commit, dispatch }, { contactGroup, thread }) {
      if (contactHasContactGroup(thread, contactGroup)) {
        return Promise.resolve()
          .then(() => dispatch('CustomTag/removeCustomFromSubject', { customTagId: contactGroup.customTagId, subject: thread.contactId }, { root: true }))
          .then(({ response, error }) => {
            if (error) return { error };

            commit('Contact/UNSET_CONTACTS_HAS_CONTACT_GROUP', { contactId: `${thread.contactId}`, contactGroupId: contactGroup.contactGroupId }, { root: true });
            return { response };
          });
      }
      return false;
    },
    addContactGroupToContact({ commit, dispatch }, { contactGroup, thread }) {
      if (!contactHasContactGroup(thread, contactGroup)) {
        return Promise.resolve()
          .then(() => dispatch('CustomTag/assignCustomToSubject', { customTagId: contactGroup.customTagId, subject: thread.contactId }, { root: true }))
          .then(({ response, error }) => {
            if (error) return { error };

            commit('Contact/SET_CONTACTS_HAS_CONTACT_GROUP', [{ contactId: `${thread.contactId}`, contactGroupId: contactGroup.contactGroupId }], { root: true });
            return { response };
          });
      }
      return false;
    },
    createContactGroupAndAssign({ dispatch }, { thread, title, color }) {
      dispatch('CustomTag/createNewCustom', {
        subject: `${thread.contactId}`, title, color, type: 'label',
      }, { root: true }).then(() => {

        // commit('SET_THREAD_GROUP');
        // commit('Thread/SET_THREADS_HAS_THREAD_GROUP', [{ threadId: thread.threadId, threadGroupId: threadGroup.threadGroupId }], { root: true });
      });
      // commit('SET_THREAD_GROUP', )
      // commit('Thread/SET_THREADS_HAS_THREAD_GROUP', [{ threadId: thread.threadId, threadGroupId: threadGroup.threadGroupId }], { root: true });
    },
    createContactGroupAndAssignMany({ dispatch }, { threads, title, color }) {
      dispatch('CustomTag/createNewCustomAndAssignMany', {
        subjects: threads.map((t) => `${t.contactId}`), title, color, type: 'label',
      }, { root: true }).then(() => {

        // commit('SET_THREAD_GROUP');
        // commit('Thread/SET_THREADS_HAS_THREAD_GROUP', [{ threadId: thread.threadId, threadGroupId: threadGroup.threadGroupId }], { root: true });
      });
      // commit('SET_THREAD_GROUP', )
      // commit('Thread/SET_THREADS_HAS_THREAD_GROUP', [{ threadId: thread.threadId, threadGroupId: threadGroup.threadGroupId }], { root: true });
    },
  },

  mutations: {
    SET_CONTACT_GROUP(state, newDatas) { state.contactGroups = updateDatasIfExists({ newDatas, actualDatas: state.contactGroups, key: 'contactGroupId' }); },
    REMOVE_CONTACT_GROUP(state, contactGroupId) { delete state.contactGroups[contactGroupId]; },

    SET_CONTACT_GROUPS_HAS_CONTACTS_BIS(state, newDatas) { state.contactsGroupsHasContactsBis = updateObjectRelationIfExists({ newDatas, actualDatas: state.contactsGroupsHasContactsBis }); },
    ADD_CONTACT_TO_CONTACT_GROUP(state, { contactIds, contactGroupId }) { state.contactsGroupsHasContactsBis = addNewKeysToMap({ map: state.contactsGroupsHasContactsBis, key: contactGroupId, values: contactIds }); },
    REMOVE_CONTACT_TO_CONTACT_GROUP(state, { contactId, contactGroupId }) { state.contactsGroupsHasContactsBis = removeKeyToMap({ map: state.contactsGroupsHasContactsBis, key: contactGroupId, value: contactId }); },

    RESET_STORES(state) {
      state.contactGroups = {};
      state.contactsGroupsHasContactsBis = {};
    },
  },
};
