<template>
  <div
    id="UbuCommonAnalyticsCell"
    :class="{ 'cell-extended' : isExtended }"
  >
    <div :class="['cell-wrapper', { 'is-grouped' : grouped.length > 0}]">
      <div
        v-if="isLoading"
        class="cell-skeleton"
      >
        <b-skeleton
          width="35%"
          height="12px"
        />
        <b-skeleton size="is-medium" />
      </div>

      <b-carousel
        v-else-if="grouped.length > 0"
        :arrow-hover="false"
        :indicator="false"
        :pause-info="false"
      >
        <b-carousel-item
          v-for="(i, index) in grouped"
          :key="index"
        >
          <section class="centered">
            <h3 class="ubu-instalink">{{ i.name }}</h3>
            <div :class="['cell-value', { 'positive' : i.isPositive }]">
              {{ i.value || 'N/A' }}
            </div>
          </section>
          <b-progress
            :value="index"
            :max="grouped.length"
            type="is-success"
          />
        </b-carousel-item>
      </b-carousel>

      <div v-else>
        <div class="cell-title">
          <UbuIcon
            v-if="icon"
            :name="icon"
            class="pr-1"
          />
          <b-image
            v-if="svg"
            rounded
            style="width: 25px;"
            src="/img/ubu-logo.svg"
          />

          <h3 class="ubu-instalink">{{ name }}</h3>

          <b-tooltip
            v-if="tooltipInfo"
            class="ml-2"
            :label="tooltipInfo"
            position="is-top"
            multilined
          >
            <UbuIcon
              name="info"
              size="12px"
            />
          </b-tooltip>
        </div>
        <div
          :class="['cell-value',
                   { 'positive' : isPositive && hasValue },
                   { 'negative' : !isPositive && hasValue }]"
        >
          <b-tooltip
            :label="`compared period: ${previousValue}`"
            position="is-right"
            :active="!!previousValue"
          >
            {{ value }}{{ hasPourcent && hasValue ? '%' : '' }}{{ hasDollar && hasValue ? ' $' : '' }}
          </b-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UbuCommonAnalyticsCell',
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    previousValue: {
      type: String,
      default: '',
    },
    isPositive: {
      type: Boolean,
      default: false,
    },
    hasValue: {
      type: Boolean,
      default: false,
    },
    hasPourcent: {
      type: Boolean,
      default: false,
    },
    hasDollar: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: null,
    },
    svg: {
      type: String,
      default: null,
    },
    isExtended: {
      type: Boolean,
      default: false,
    },
    tooltipInfo: {
      type: String,
      default: null,
    },
    grouped: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
