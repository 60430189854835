var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['UbuThreadCard_wrapper', { 'UbuThreadCard_wrapperSelected': _vm.selected }]},[_c('div',{staticClass:"UbuThreadCard_avatar",on:{"click":function($event){return _vm.$emit('onBulkSelect', $event)}}},[_c('div',{class:['UbuThreadCard_avatarImg', {
        'UbuThreadCard_avatarSelected': _vm.isBulkSelected,
      }]},[(_vm.isBulkSelected)?_c('img',{staticClass:"UbuThreadCard_checkMark",attrs:{"src":require("@/assets/pictos/is_ok.svg")}}):_c('UbuInstaImg',{staticClass:"UbuThreadCard_avatarImg",attrs:{"insta-src":_vm.contact.has_bucket_media
          ? String(_vm.contact.contactId) : _vm.contact.profile_pic_url,"has-bucket":_vm.contact.has_bucket_media || false,"bucket-folder":'profile_pic',"fallback":'../../img/no_profile_pict.svg',"rounded":""}})],1)]),_c('div',{staticClass:"UbuThreadCard_textBox",on:{"click":function($event){return _vm.$emit('onSelect', $event)}}},[_c('div',{class:[
        'UbuThreadCard_textRow',
        'ubu-text1',
        _vm.displaybleContactGroup ? ("text-color-" + (_vm.displaybleContactGroup.color)) : ''
      ]},[(_vm.displaybleContactGroup)?_c('UbuIcon',{attrs:{"filled":"","name":"contact"}}):_vm._e(),_c('span',[_vm._v(" "+_vm._s(_vm.displayableName)+" ")])],1),_c('div',{class:[
        'UbuThreadCard_textRow',
        'ubu-text2',
        _vm.displaybleThreadGroup ? ("text-color-" + (_vm.displaybleThreadGroup.color)) : ''
      ]},[(_vm.displaybleThreadGroup)?_c('UbuIcon',{attrs:{"filled":"","name":"tag"}}):_vm._e(),_c('span',[_vm._v(" "+_vm._s(_vm.lastNotification)+" ")])],1)]),_c('div',{staticClass:"UbuThreadCard_right"},[_c('span',{staticClass:"UbuThreadCard_date",staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm.displaybleDate))]),(!_vm.lastItem.isMe && _vm.isUnread)?_c('b-icon',{staticClass:"ubuCircleRed",attrs:{"icon":"circle"}}):(_vm.lastItem.isMe)?_c('UbuIcon',{attrs:{"filled":"","size":"12px","name":"send"}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }