<template>
  <section
    id="TheMention"
    class="flex"
  >
    <TheSidebar
      :menu-items-first-column="menuItemsFirstColumn"
    />
    <router-view :key="$route.path" />
  </section>
</template>

<script>

export default {
  name: 'TheMentions',
  computed: {
    menuItemsFirstColumn() {
      return [
        {
          icon: 'overview',
          text: 'Analytics',
          href: '/activities/analytics',
        },
        {
          icon: 'mentions',
          text: 'Mentions',
          href: '/activities/mentions',
        },
        {
          icon: 'hashtag',
          text: 'Hashtags',
          href: '/activities/hashtags',
        },
      ];
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/common';
@import 'Mentions';

</style>
