const catalogAnalytics = {
  // CAMPAIGN
  influencersReach: {
    name: 'Influencers reach',
    ordinal: 1,
    type: 'campaign',
    size: 25,
    icon: 'influence',
  },
  mentionsNumbers: {
    name: 'Mentions',
    ordinal: 2,
    type: 'campaign',
    size: 25,
    icon: 'influence',
  },
  influencersPostCount: {
    name: 'Active influencers ',
    ordinal: 3,
    type: 'campaign',
    size: 25,
    icon: 'influence',
  },
  success: {
    name: 'Success',
    ordinal: 4,
    type: 'campaign',
    size: 25,
    icon: 'influence',
  },

  // ACCOUNT
  final_followers: {
    name: 'Total followers',
    ordinal: 1,
    type: 'account',
    size: 50,
    icon: 'account',
    color: 12,
  },
  followers_difference: {
    name: 'Followers growth',
    ordinal: 2,
    type: 'account',
    icon: 'account',
    size: 50,
    color: 12,
  },
  new_followers: {
    name: 'New followers',
    type: 'account',
    ordinal: 3,
    icon: 'account',
    color: 12,
    size: 50,
  },
  mean_engagement_total: {
    name: 'Engagement rate',
    type: 'account',
    icon: 'love',
    color: 9,
    isPercent: true,
    ordinal: 4,
    size: 50,
  },
  impressions: {
    name: 'Total prints',
    type: 'account',
    icon: 'eye',
    color: 4,
    ordinal: 5,
    size: 25,
  },
  reach: {
    name: 'Total reach',
    type: 'account',
    icon: 'eye',
    color: 4,
    ordinal: 6,
    size: 50,
  },
  profile_views: {
    name: 'Profile views',
    type: 'account',
    icon: 'eye',
    color: 4,
    ordinal: 7,
    size: 25,
  },
  website_clicks: {
    name: 'Website clicks',
    type: 'account',
    icon: 'influence',
    color: 8,
    ordinal: 8,
    size: 25,
  },
  email_contacts: {
    name: 'Email clicks',
    type: 'account',
    icon: 'influence',
    color: 8,
    ordinal: 9,
    size: 25,
  },
  phone_call_clicks: {
    name: 'Phone call clicks',
    type: 'account',
    icon: 'influence',
    color: 8,
    ordinal: 10,
    size: 25,
  },
  text_message_clicks: {
    name: 'Text message clicks',
    type: 'account',
    icon: 'influence',
    color: 8,
    ordinal: 11,
    size: 25,
  },

  // SHOPIFY
  totalRevenuesByReferralLinks: {
    name: 'Total revenues from referral codes',
    ordinal: 1,
    type: 'shopify',
    size: 50,
    color: 5,
    icon: 'money',
    isDollar: true,
  },
  totalOrderByReferralLinks: {
    name: 'Total orders from referral codes',
    ordinal: 2,
    type: 'shopify',
    size: 50,
    color: 8,
    icon: 'shop',
  },
  totalRevenuesByDiscountCodes: {
    name: 'Total revenues from discount codes',
    ordinal: 3,
    type: 'shopify',
    size: 50,
    color: 5,
    icon: 'money',
    isDollar: true,
  },
  totalOrderByDiscountCodes: {
    name: 'Total orders from discount codes',
    ordinal: 4,
    type: 'shopify',
    size: 50,
    color: 8,
    icon: 'shop',
  },
};

export {
  // eslint-disable-next-line import/prefer-default-export
  catalogAnalytics,
};
