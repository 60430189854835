<template>
  <div>
    <h3 class="ubu-welcome py-3">
      {{ title }}
    </h3>
    <form
      v-if="!isEmailSent && !isReset"
      class="UbuLoginForm"
      @keyup.enter="sendEmail()"
    >
      <UbuTextField
        v-model="form.email"
        label="Email"
        placeholder="name@company.com"
      />
      <UbuButton
        grow
        center
        big
        type="submit"
        secondary
        :loading="isLoading"
        @click="sendEmail()"
      >
        Reset password (1/2)
      </UbuButton>
    </form>
    <form
      v-if="isReset"
      class="UbuLoginForm"
      @keyup.enter="reset()"
    >
      <UbuTextField
        v-model="form.password"
        label="Password"
        type="password"
        placeholder="**********"
        :error="errorPw"
      />
      <UbuTextField
        v-model="form.confirmPassword"
        label="Password"
        type="password"
        placeholder="**********"
        :error="errorPw"
      />
      <UbuButton
        grow
        center
        big
        type="submit"
        secondary
        :loading="isLoading"
        @click="reset()"
      >
        Reset password (2/2)
      </UbuButton>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'TheAuthenticateResetPassword',
  data() {
    return {
      isEmailSent: false,
      isReset: false,
      isLoading: false,
      form: {
        email: '',
        password: '',
        confirmPassword: '',
      },
      errorPw: null,
    };
  },
  computed: {
    title() {
      if (this.isReset) {
        return 'Enter your new password 👇';
      }
      return this.isEmailSent ? 'Check your emails to reset your password 👊 ' : 'Enter the email of your Ubu account to reset your password👇';
    },
  },
  mounted() {
    return Promise.resolve()
      .then(() => {
        const { token } = this.$route.params;
        if (token) {
          this.isReset = true;
          this.UP_resetPasswordToken(token);
        }
      });
  },
  methods: {
    ...mapActions({
      sendEmailResetPassword: 'Authenticate/sendEmailResetPassword',
      UP_resetPasswordToken: 'Authenticate/UP_resetPasswordToken',
      resetPassword: 'Authenticate/resetPassword',
    }),
    sendEmail() {
      this.isLoading = true;
      this.sendEmailResetPassword(this.form.email)
        .then(() => {
          this.isEmailSent = true;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.$buefy.toast.open({
            duration: 5000,
            message: error.message,
            type: 'is-danger',
          });
        });
    },
    reset() {
      if (this.form.password !== this.form.confirmPassword) {
        this.errorPw = "Passwords doesn't match";
      } else {
        this.errorPw = null;
        this.isLoading = true;
        this.resetPassword(this.form.password)
          .then(() => {
            this.isLoading = false;
            this.$buefy.toast.open({
              duration: 5000,
              message: 'Password successfully changed !',
              type: 'is-success',
            });
          })
          .then(() => this.$router.push('/auth/login'))
          .catch((error) => {
            this.isLoading = false;
            this.$buefy.toast.open({
              duration: 5000,
              message: error.message,
              type: 'is-danger',
            });
          });
      }
    },
  },
};
</script>

<style lang="scss">
  @import '@/styles/forms';
</style>
