<template>
  <div class="workspace-modal">
    <div class="modal-header">
      <p class="modal-header-title ubu-title">Add new template</p>

      <UbuIcon
        name="cross"
        clickable
        @click="$emit('close', $event)"
      />
    </div>
    <div class="modal-content">
      <div class="modal-content-input-wrapper">
        <UbuSimpleInput
          v-model="templateTitle"
          class="modal-content-input pl-3"
          :focus="true"
          placeholder="Title..."
          @keyup="handleTitleSpace()"
        />
        <UbuTemplateTextarea
          v-model="data"
          :template-list="templateList"
          show-controls
        />
      </div>
    </div>
    <div class="modal-footer">
      <UbuButton
        grow
        center
        secondary
        @click="addFromModal()"
      >
        Save
      </UbuButton>
    </div>
  </div>
</template>

<script>
import TemplateMixin from '../../common/$mixins/TemplateMixin';

export default {
  name: 'UbuWorkspaceModalAddTemplate',
  mixins: [TemplateMixin],
  props: {
    templateList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dropdownOpened: false,
      templateTitle: '',
      data: '',
    };
  },
  methods: {
    addFromModal() {
      if (this.checkTemplate(undefined, this.data)) this.$emit('addFromModal', { title: this.templateTitle, data: this.data });
    },
  },
};
</script>
