<template>
  <div class="workspace-modal">
    <div class="modal-header">
      <p class="modal-header-title ubu-title">Add keywords</p>

      <UbuIcon
        name="cross"
        clickable
        @click="$emit('close', $event)"
      />
    </div>
    <form class="modal-content">
      <textarea
        v-model="newKeywords"
        class="modal-content-textarea ubu-text3"
        type="textarea"
        placeholder="Add keywords, one per line..."
      />
    </form>
    <div class="modal-footer">
      <UbuButton
        grow
        center
        secondary
        @click="addKeywords()"
      >
        Add keywords
      </UbuButton>
    </div>
  </div>
</template>

<script>
import AddKeywordsMixin from '../../$mixins/AddKeywordsMixin';

export default {
  name: 'UbuWorkspaceModalAddKeyword',
  mixins: [AddKeywordsMixin],
  props: {
    keywords: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dropdownOpened: false,
      newKeywords: '',
    };
  },
  methods: {
    addKeywords() {
      const arrayNewKeywords = this.convertListOfKeywordsIntoArrayOfKeywords(
        this.newKeywords,
      );
      const arrayKeywords = [...this.keywords, ...arrayNewKeywords];
      this.$emit('addKeywords', arrayKeywords);
    },
  },
};
</script>
