<template>
  <div
    v-click-outside="e => opened = false"
    class="UbuChannelSelector_wrapper"
  >
    <a
      v-if="!isReduced"
      class="UbuChannelSelector_expanded ubu-text1"
      @click="opened = !opened"
    >
      <span class="ellipsis">@{{ currentChannel ? currentChannel.username : 'N/A' }}</span>
      <UbuIcon
        name="expand"
        :class="[
          {'UbuChannelSelector_arrowUp': opened},
          'UbuChannelSelector_arrow'
        ]"
      />
      <div
        v-if="opened"
        class="UbuChannelSelector_dropdown"
      >
        <template v-for="channel in channels">
          <a
            :key="channel._id"
            :class="['UbuChannelSelector_choices', 'ellipsis', {
              'UbuChannelSelector_selected': channel._id === currentChannel._id
            }]"
            @click="$emit('switchChannel', channel)"
          >
            @{{ channel.username }}
          </a>
        </template>
      </div>
    </a>
    <div
      v-else
      class="UbuChannelSelector_reduced"
    >
      <div class="UbuChannelSelector_reduced-imageList">
        <template v-for="channel in channels">
          <div
            :key="channel._id"
            :data-channelId="channel._id"
            class="UbuChannelSelector_reduced-image"
            :style="`background-image: url(${channel.data.profile_pic_url});
              ${channel._id === currentChannel._id ? 'z-index: 10;' : 'z-index: 0;'}`"
            @click="toggleDropdownImg(channel)"
          />
        </template>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'UbuSidebarChannelSelector',
  props: {
    isReduced: {
      type: Boolean,
      default: false,
    },
    currentChannelId: {
      type: String,
      default: '',
    },
    currentChannel: {
      type: Object,
      default: () => ({}),
    },
    channels: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    opened: false,
  }),
  methods: {
    /**
     * @todo remove this or fix vanilla javascript
     */
    toggleDropdownImg(channel) {
      this.opened = !this.opened;
      let images = document.getElementsByClassName('UbuChannelSelector_reduced-image');
      images = Array.prototype.slice.call(images); // So we can then use splice after
      const imageList = document.querySelector('.UbuChannelSelector_reduced-imageList');
      const firstImage = document.querySelector('.UbuChannelSelector_reduced-image');
      const imageHeight = firstImage.getBoundingClientRect().height;
      const marginTop = 16; // Space between the images
      const { currentChannelId } = this;
      images.forEach((item, i) => {
        if (item.dataset.channelid === currentChannelId) {
          images.splice(i, 1);
          // Put the image associated to the currentChannel at the start of the images array
          images.unshift(item);
        }
      });
      if (this.opened) {
        imageList.style.height = `${(imageHeight + marginTop) * images.length}px`;
        imageList.classList.add('UbuChannelSelector_elasticTransition');
        // Show every images changing their top property
        images.forEach((_, i) => {
          const image = images[i];
          const top = `${(imageHeight + marginTop) * i}px`;
          image.classList.add('UbuChannelSelector_elasticTransition');
          image.style.top = top;
        });
        // for (let i = 0; i < images.length; i++) {
        //   const image = images[i];
        //   const top = `${(imageHeight + marginTop) * i}px`;
        //   image.classList.add('UbuChannelSelector_elasticTransition');
        //   image.style.top = top;
        // }
      } else {
        imageList.style.height = `${imageHeight}px`;
        imageList.classList.remove('UbuChannelSelector_elasticTransition');
        // Hide every images setting their top property to 0
        // for (let i = 0; i < images.length; i++) {
        //   const image = images[i];
        //   image.classList.remove('UbuChannelSelector_elasticTransition');
        //   image.style.top = '0';
        // }
        images.forEach((_, i) => {
          const image = images[i];
          image.classList.remove('UbuChannelSelector_elasticTransition');
          image.style.top = '0';
        });
        this.$emit('switchChannel', channel);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/utils';

.UbuChannelSelector_wrapper{
  .UbuChannelSelector_reduced{
    display: flex;
    justify-content: center;
    .UbuChannelSelector_reduced-imageList{
      position: relative;
      width: 28px;
      height: 28px;
      transition: all 0.2s ease-in-out;
      .UbuChannelSelector_reduced-image{
        position: absolute;
        top: 0;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background-color: var(--geraldine);
        background-size: cover;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
      }
    }
    .UbuChannelSelector_elasticTransition{
      transition: all 0.4s cubic-bezier(0.34, 1.56, 0.64, 1) !important;
    }
  }
  .UbuChannelSelector_expanded {
    height: 28px;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    color: var(--cloudburst);

    .UbuChannelSelector_arrow{
      margin-left: 8px;
      height: 12px;
      transition: transform .1s ease-out;
    }

    .UbuChannelSelector_arrowUp {
      transition: transform .1s ease-out;
      &.UbuChannelSelector_arrow {
        transform: rotate(180deg);
        transition: transform .1s ease-out;
      }
    }

    .UbuChannelSelector_dropdown {
      @extend .ubu-dropdown;
      left: 0;
      right: 0;

      .UbuChannelSelector_choices {
        color: var(--cloudburst);
        padding: 12px;
        display: block;
        border-radius: 16px;
        margin-bottom: 6px;
        &:hover {
          background-color: var(--narvalo60);
        }
        &.UbuChannelSelector_selected {
          background-color: var(--narvalo);
        }
      }
    }
  }
}
</style>
