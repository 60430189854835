/* eslint-disable global-require */
/* eslint-disable import/prefer-default-export */
const barbara = {
  api: require('./barbara/api'),
  shopify: require('./barbara/shopify'),
};
const clark = {
  api: require('./clark/api'),
};
const yaerApi = {
  api: require('./yaerApi/api'),
};

const providers = {
  barbara,
  clark,
  yaerApi,
};

export default providers;

export {
  barbara,
  clark,
  yaerApi,
};
