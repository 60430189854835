<template>
  <section>
    hello
  </section>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'TheDocumentationCommentsData',
  mounted() {
    return Promise.resolve()
      .then(() => this.fetchMedias());
  },
  methods: {
    ...mapActions({
      fetchMedias: 'Sequence/fetchMedias',
    }),
  },
};
</script>

<style lang="scss">
</style>
