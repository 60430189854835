import { get } from 'lodash';

const getFalsyValue = (val) => !(val === false);

function toBucketMedia(post) {
  const {
    // eslint-disable-next-line camelcase
    media_url,
    // eslint-disable-next-line camelcase
    thumbnail_url,
    // eslint-disable-next-line camelcase
    has_bucket_media,
    id,
    // eslint-disable-next-line camelcase
    media_type,
  } = post;

  // eslint-disable-next-line camelcase
  if (has_bucket_media && media_type !== 'VIDEO') {
    const newUrl = `https://io.getubu.com/media/${id}`;
    return {
      media_url: newUrl,
      thumbnail_url: newUrl,
    };
  }
  return {
    media_url,
    thumbnail_url,
  };
}

function instagramPostMapper(post) {
  const newPost = {
    ...post,
    source: 'instagram',
    postId: post._id || null,
    graphId: post.fb_id || post.mediaFBId || post.ig_id || null,
    createdAt: post.ts || new Date(post.timestamp).getTime() || 0,
    publishedAt: post.ts || new Date(post.timestamp).getTime() || 0,
    isAd: post.is_ad || false,
    channelPk: post.channel || null,
    canShare: getFalsyValue(post.can_share),
    canLike: true,
    canComment: getFalsyValue(post.can_comment),
    isHidden: getFalsyValue(post.hidden),
    permalink: post.permalink || null,
    reactions: post.like_count ? [{
      type: 'LIKE',
      emoji: '❤️',
      count: post.like_count,
    }] : [],
    commentsCount: post.comments_count || 0,
    reactionCount: post.like_count || 0,
    shareCount: post.share_count || 0,
    media_url: post.media_url,
    thumbnail_url: post.thumbnail_url,
    caption: post.caption || null,
    title: post.video_title || null,
    participants: {},
    tags: [],
    mentions: [],
    open_parent_comments_count: post.open_parent_comments_count || 0,
    isUnread: post.isUnread,
    ...toBucketMedia(post),
    //isMention: false,
    // __original: post,
  };
  return newPost;
}

function instagramMentionPostMapper(post) {
  const _p = instagramPostMapper(post);
  return {
    ..._p,
    isMention: true,
  };
}

function facebookPostMapper(post) {
  const newPost = {
    source: 'facebook',
    postId: post._id || null,
    graphId: post.id || null,
    createdAt: post.ts || new Date(post.createdTime).getTime() || 0,
    publishedAt: post.ts || new Date(post.createdTime).getTime() || 0,
    isAd: post.is_ad || post.isAd || false,
    channelPk: post.channel || null,
    channelId: post.channelId || post.channel_id || null,
    canShare: getFalsyValue(post.can_share),
    canLike: getFalsyValue(post.can_like),
    canComment: false /*getFalsyValue(post.can_comment)*/,
    isHidden: getFalsyValue(post.isHidden),
    isPublished: post.is_published || false,
    // is_inline_created: post.is_inline_created || false,
    isDynamic: post.is_dynamic || false,
    dynamicParent: post.is_dynamic && post.dynamic_parent,
    permalink: post.permalink || post.permalink_url || null,
    reactions: post.reactions.map(({ _id, ...rest }) => rest),
    media_url: post.media_url,
    thumbnail_url: post.thumbnail_url,
    commentsCount: post.comments_count || post.comment_count || 0,
    reactionCount: (post.reaction_count || 0),
    shareCount: post.share_count || 0,
    title: post.title,
    caption: post.caption || post.message || post.story || get(post, 'attachments[0].description') || get(post, 'attachments[0].title') || null,
    open_parent_comments_count: post.open_parent_comments_count || 0,
    isUnread: post.isUnread,
    // ...post,
  };
  return newPost;
}

export {
  instagramPostMapper,
  instagramMentionPostMapper,
  facebookPostMapper,
};
