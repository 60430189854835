<template>
  <div id="UbuCommentThreadHeaderHeaderButtons">
    <!-- <UbuButton
      class="ubuRating-button"
      @click="$emit('rateThread', 'good')"
    >
      <span class="ubu-header1">
        👍
      </span>
    </UbuButton>
    <UbuButton
      class="ubuRating-button"
      @click="$emit('rateThread', 'medium')"
    >
      <span class="ubu-header1">
        😐
      </span>
    </UbuButton>
    <UbuButton
      class="ubuRating-button"
      @click="$emit('rateThread', 'bad')"
    >
      <span class="ubu-header1">
        👎
      </span>
    </UbuButton> -->

    <b-dropdown
      aria-role="list"
      position="is-bottom-left"
    >
      <template #trigger>
        <UbuButton
          class="a-self-center"
          :icon="{ name: 'more' }"
          :filled="false"
        />
      </template>

      <b-dropdown-item
        class="translate-action"
        custom
      >
        <span>
          <UbuIcon
            name="translate"
          /> Translate to: </span>

        <!-- <b-tooltip label="Translate">
          <UbuIcon name="info" />
        </b-tooltip> -->
      </b-dropdown-item>

      <b-dropdown-item
        v-for="lang in languageList"
        :key="lang.value"
        :value="lang.value"
        @click="selectLanguage(lang.value)"
      >
        <div class="language-content">
          <b-image
            class="language-flag"
            :src="lang.icon"
          />
          {{ lang.label }}
        </div>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
export default {
  name: 'UbuCommentThreadHeaderButtons',
  data() {
    return {
      languageList: [
        { label: 'English', value: 'en', icon: '/img/UK.svg' },
        { label: 'French', value: 'fr', icon: '/img/France.svg' },
        { label: 'Spanish', value: 'es', icon: '/img/Spain.svg' },
        { label: 'German', value: 'de', icon: '/img/Germany.svg' },
      ],
    };
  },
  methods: {
    selectLanguage(lang) {
      this.$emit('translateThread', { target: lang });
    },
  },
};
</script>
