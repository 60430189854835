<template>
  <b-dropdown
    id="UbuSelector"
    ref="dropdown"
    :max-height="300"
    :class="['ubuDropdown', { limitWidth: !creating }]"
    :close-on-click="false"
    :triggers="['click']"
    :disabled="disabled"
    @active-change="isActive = $event; isActive ? resetToDefault() : null"
  >
    <template #trigger="{ active }">
      <UbuSelectorLabel
        grow
        center
        :reduced="reduced"
        :active="active"
        :values="values"
        :default-label-icon="labelIcon"
        :icon-prop="_p.icon"
        :label-prop="_p.label"
        :color-prop="_p.color"
        :tooltip="tooltip"
      />
    </template>

    <template v-if="!creating">
      <template v-if="creatable || searchPlaceholder">
        <UbuSimpleInput
          v-if="isActive"
          v-model="searchText"
          searchbar
          style="margin-bottom: 12px"
          :placeholder="searchPlaceholder"
          focus
        />
      </template>

      <template v-if="error === 'max-value'">
        <div class="UbuSelector_errorMsg">
          Can't select more than {{ max }} value{{ max > 1 ? 's' : '' }}
        </div>
      </template>
      <template v-if="filteredValues.length">
        <b-dropdown-item
          v-for="item in filteredValues"
          :key="`value/${item[_p.id] || item[_p.label]}`"
          :value="item"
          custom
          aria-role="listitem"
          class="UbuSelector_row"
        >
          <div :class="['UbuSelector_choice ubu-text2 ellipsis', `bg-color-${item[_p.color]}`]">
            <UbuIcon
              v-if="!item[_p.img]"
              filled
              v-bind="typeof item[_p.icon] === 'object' ? item[_p.icon] : { name: item[_p.icon] }"
            />
            <b-image
              v-else
              style="width: 17.5px"
              rounded
              :src="item[_p.img]"
            />
            <span class="UbuSelector_choiceContent ellipsis">
              {{ item[_p.label] }}
            </span>
          </div>
          <UbuIcon
            v-if="removable"
            class="UbuSelector_action"
            clickable
            color="2"
            name="failure"
            @click.stop="removeItem(item)"
          />
        </b-dropdown-item>
        <b-dropdown-item
          v-if="filteredSelectables.length"
          separator
        />
      </template>
      <template v-if="filteredSelectables.length">
        <b-dropdown-item
          v-for="item in filteredSelectables"
          :key="`choice/${item[_p.id] || item[_p.label]}`"
          custom
          aria-role="listitem"
          class="UbuSelector_row"
          :value="item"
        >
          <a
            :class="['UbuSelector_choice color-text-hover ubu-text2 ellipsis', `text-color-${item[_p.color]}`]"
            @click="selectItem(item)"
          >
            <UbuIcon
              v-if="!item[_p.img]"
              filled
              v-bind="typeof item[_p.icon] === 'object' ? item[_p.icon] : { name: item[_p.icon] }"
            />
            <b-image
              v-else
              style="width: 17.5px"
              rounded
              :src="item[_p.img]"
            />
            <span class="UbuSelector_choiceContent ellipsis">
              {{ item[_p.label] }}
            </span>
          </a>
          <UbuIcon
            v-if="removable"
            class="UbuSelector_action is-remove"
            size="17.5px"
            style="pointer: cursor"
            name="add"
          />
        </b-dropdown-item>
      </template>

      <template v-if="creatable && searchText.length > 0">
        <b-dropdown-item separator />
        <div v-if="searchText.length">
          <span>{{ createPlaceholder }}</span>
          <UbuButton
            grow
            :icon="labelIcon"
            @click.stop="sendLabel($event)"
          >
            {{ searchText }}
          </UbuButton>
        </div>
      </template>
    </template>
    <template v-else>
      <UbuIcon
        clickable
        name="back"
        @click.stop="resetToDefault"
      />
      <UbuColorPicker
        :max-per-row="3"
        :icon="labelIcon"
        @selectColor="createItem($event)"
      />
    </template>
  </b-dropdown>
</template>

<script>

const defaultPaths = {
  icon: 'icon',
  color: 'color',
  id: 'id',
  label: 'label',
  img: 'img',
};

export default {
  name: 'UbuSelector',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    reduced: {
      type: Boolean,
      default: false,
    },
    labelIcon: {
      type: String,
      default: 'influence',
    },
    createPlaceholder: {
      type: String,
      default: 'Create new',
    },
    searchPlaceholder: {
      type: String,
      default: null,
    },
    max: {
      type: Number,
      default: null,
    },
    values: {
      type: Array,
      default: () => ([]),
    },
    choices: {
      type: Array,
      default: () => ([]),
    },
    paths: {
      type: Object,
      default: () => ({ ...defaultPaths }),
    },
    tooltip: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    searchText: '',
    creating: null,
    error: null,
    isActive: false,
  }),
  computed: {
    _p() {
      return { ...defaultPaths, ...this.paths };
    },
    removable() {
      return !!this.$listeners.remove;
    },
    creatable() {
      return !!this.$listeners.create;
    },
    selectables() {
      const selectables = this.choices
        .filter((item) => !this.values
          .find((v) => (this._p.id ? v[this._p.id] === item[this._p.id] : v === item)));
      return selectables;
    },
    filteredValues() {
      return this.filterItems(this.values, this.searchText);
    },
    filteredSelectables() {
      return this.filterItems(this.selectables, this.searchText);
    },
  },
  methods: {
    resetToDefault() {
      this.searchText = '';
      this.creating = null;
      this.error = null;
    },
    filterItems(items, search) {
      if (!this.searchText) return items;
      const text = search.toLowerCase().trim();
      return items.filter((item) => item[this._p.label].toLowerCase().includes(text));
    },
    selectItem(item) {
      if (this.max && this.values.length + 1 > this.max) {
        this.setError('max-value');
        return;
      }
      this.$emit('select', item);
    },
    removeItem(item) {
      this.$emit('remove', item);
    },
    sendLabel($event) {
      this.creating = $event;
    },
    createItem(color) {
      const newItem = { [this._p.label]: this.searchText, [this._p.color]: color };
      this.$emit('create', newItem);
      this.resetToDefault();
    },
    setError(error) {
      this.error = error;
      setTimeout(() => { this.error = null; }, 3000);
    },
  },
};
</script>

<style lang="scss">
#UbuSelector {
  &.ubuDropdown div.dropdown-trigger {
    max-width: 100%;
    display: flex;
    flex-grow: 1;
    justify-content: center;
  }

  &.ubuDropdown div.dropdown-menu div.dropdown-content {
    max-height: 500px;
    overflow-y: auto
  }
  &.limitWidth {
    &.ubuDropdown div.dropdown-menu div.dropdown-content {
      width: 270px;
    }
  }
  .UbuSelector_row {
    /* Reset buefy dropdown styles */
    padding: 0;
    min-width: 200px;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;

    &:hover {
      background-color: inherit;
    }
    &:not(:last-child) {
      margin-bottom: 4px;
    }

    .UbuSelector_choice {
      border-radius: 12px;
      width: inherit;
      height: 36px;
      padding: 0 12px;
      display: flex;
      align-items: center;
      flex-grow: 1;
      .UbuIcon_wrapper {
        font-size: 17.5px !important;
      }
      .UbuSelector_choiceContent {
        margin-left: 12px
      }
    }
  }
  .UbuSelector_errorMsg {
    text-align: center;
    font-size: .7rem;
    color: var(--error);
    margin-bottom: 4px;
  }

  .UbuSelector_action {
    font-size: 17.5px !important;
    margin-left: 6px;

    &.is-remove {
      color: var(--cloudburst);
    }
  }
}
</style>
