<template>
  <div id="UbuCommentListHeaderFilterActions">
    <UbuSimpleInput
      v-model="localSearch"
      placeholder="Search..."
      searchbar
    >
      <UbuIcon
        slot="placeholderIcon"
        name="search"
      />
    </UbuSimpleInput>

    <UbuButton
      icon="filter"
      :active="filterBoxIsOpen"
      :tooltip="{ label: 'Filter', position: 'is-bottom' }"
      @click="$emit('toggleFilterBoxIsOpen', !filterBoxIsOpen)"
    />

    <UbuButton
      v-if="showCloseAll"
      color="2"
      center
      :icon="{name: 'success', filled: true}"
      :loading="isBulkPending"
      :tooltip="{ label: 'Close all comments', position: 'is-bottom' }"
      @click="$emit('closeAll', true)"
    />

    <!-- <UbuButton
      icon="bulk"
      @click="$emit('bulksAction', $event)"
    /> -->
  </div>
</template>

<script>
export default {
  name: 'UbuCommentThreadListHeaderFilters',
  model: {
    prop: 'search',
  },
  props: {
    showCloseAll: {
      type: Boolean,
      default: false,
    },
    search: {
      type: String,
      required: true,
    },
    filterBoxIsOpen: {
      type: Boolean,
      default: false,
    },
    isBulkPending: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    localSearch: {
      get() { return this.search; },
      set(value) { this.$emit('input', value); },
    },
  },
};
</script>
