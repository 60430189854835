import { instagramPostMapper, facebookPostMapper, instagramMentionPostMapper } from '@/addons/$utils/mappers/post';
import { yaerApi } from '@/addons/$providers';
import { arrayMapper } from '../../$utils/prototypes';
import { updateDatasIfExists, updateRelationsIfExists } from '../$utils/dataMapper';
import internals from './internals';
import MediaProvider from '../providers/media.provider';

import store from '@/store';

const mapMedias = ({ commit }, { response, channelId, mapper }) => {
  if (!(Array.isArray(response))) {
    return null;
  }
  const medias = response
    .map((item) => {
      if (mapper) return mapper({ channelId, ...item });
      if (item.source === 'instagram') return instagramPostMapper({ channelId, ...item });
      if (item.source === 'facebook') return facebookPostMapper({ channelId, ...item });
      console.warn('No source or mapper for', item);
      return item;
    })
    .map((media) => ({ ...media, channelId, mediaFBId: media.graphId }))
  //   .map((m) => {
  //     // console.log('GETTERS', getters);
  //     const list = JSON.parse(JSON.stringify(getters.getterMediasFB));
  //     console.log('Store root get', list, m.mediaFBId);
  //     if (list[m.mediaFBId]) {
  //       console.log('Media still present', m);
  //     }
  //     console.log('Length:', Object.values(list).length);
  //     return m;
  //   });
  // console.log('Length', medias.length, medias);
  const mappedMedias = arrayMapper(medias, 'mediaFBId');
  commit('SET_MEDIAS_FB', mappedMedias);
  const channelHasMediasFb = Object.values(mappedMedias)
    .map(({ mediaFBId }) => ({ channelId, mediaFBId }));
  commit('Channel/SET_CHANNEL_HAS_MEDIASFB', channelHasMediasFb, { root: true });
};

/* eslint-disable max-len */
export default {
  namespaced: true,

  state: {
    medias: {},
    mediasFB: {},
    mediasFBHasComments: [],
  },

  getters: {
    getterMedias: (state) => state.medias,
    getterMediasFB: (state) => state.mediasFB,
    getterMediasFBHasComments: (state) => state.mediasFBHasComments,
  },

  actions: {
    
    fetchMediasDBByChannel({ commit, getters }) {
      if (!internals.fbChannelId) return undefined;

      return Promise.resolve()
        .then(() => yaerApi.api.posts.instagram({ payload: { channelId: internals.fbChannelId } }))
        .then(({ success, result }) => {
          if (!success) {
            return false;
          }
          return mapMedias({ commit, getters }, { response: result, channelId: internals.fbChannelId, mapper: instagramPostMapper });
        });
    },
    fetchMentionnedMediaDbByChannel({ commit, getters }) {
      if (!internals.fbChannelId) return undefined;

      return Promise.resolve()
        .then(() => yaerApi.api.posts.instagramMentions({ payload: { channelId: internals.fbChannelId } }))
        .then(({ success, result }) => {
          if (!success) {
            return false;
          }
          return mapMedias({ commit, getters }, { response: result, channelId: internals.fbChannelId, mapper: instagramMentionPostMapper });
        });
    },
    fetchMediasFbDBByChannel({ commit, getters }) {
      if (!internals.fbChannelId) return undefined;

      return Promise.resolve()
        .then(() => yaerApi.api.posts.facebook({ payload: { channelId: internals.fbChannelId } }))
        .then(({ success, result }) => {
          console.log('Media fb', success, result);
          if (!success) {
            return false;
          }
          return mapMedias({ commit, getters }, { response: result, channelId: internals.fbChannelId, mapper: facebookPostMapper });
        });
    },
    fetchFbOrganic({ commit, getters }) {
      if (!internals.fbChannelId) return undefined;

      return Promise.resolve()
        .then(() => yaerApi.api.posts.fetchFbOrganic({ payload: { channelId: internals.fbChannelId } }))
        .then(({ success, result, reason }) => {
          if (!success) {
            return reason;
          }
          return mapMedias({ commit, getters }, { response: result, channelId: internals.fbChannelId, mapper: facebookPostMapper });
        });
    },
    fetchAllAds({ commit, getters }) {
      if (!internals.fbChannelId) return undefined;

      return Promise.resolve()
        .then(() => yaerApi.api.posts.fetchAllAds({ payload: { channelId: internals.fbChannelId } }))
        .then(({ success, result, reason }) => {
          if (!success) {
            return { success, reason };
          }
          mapMedias({ commit, getters }, { response: result, channelId: internals.fbChannelId, mapper: null });
          return { success: true };
        });
    },
    fetchDynamics({ commit, getters }) {
      if (!internals.fbChannelId) return undefined;

      return Promise.resolve()
        .then(() => yaerApi.api.posts.fetchDynamics({ payload: { channelId: internals.fbChannelId } }))
        .then(({ success, result, reason }) => {
          if (!success) {
            console.warn('Error Dynamics', result);
            return { success, reason };
          }
          console.log('Dynamics are', result);
          mapMedias({ commit, getters }, { response: result, channelId: internals.fbChannelId, mapper: facebookPostMapper });
          return { success: true };
        });
    },
    fetchMediasByChannel({ commit, getters }) {
      if (!internals.fbChannelId) return undefined;

      return Promise.resolve()
        // .then(() => Promise.all(internals.channelsIds.map((channelId) => internals.fetchMedias({ payload: { channelId } }))))
        .then(() => internals.fetchMedias({ payload: { channelId: internals.fbChannelId } }))
        .then(({ response, error }) => {
          if (error) {
            console.warn('🚀 ~ file: Media.store.js ~ line 27 ~ .then ~ error', error);
            return error.reason;
          }
          return mapMedias({ commit, getters }, { response, channelId: internals.fbChannelId, mapper: instagramPostMapper });
        });
    },
    fetchMedia({ commit, getters }, threadId) {
      return Promise.resolve()
        .then(() => MediaProvider.getMedias({ payload: { threadId } }))
        .then((response) => {
          if (response.error) {
            // Gestion des erreurs
          }
          return ({ threadId, ...response });
        })
        .then(({ response: mediaList }) => {
          console.log('🚀 ~ file: Media.store.js ~ line 65 ~ .then ~ mediaList', mediaList);
          const medias = mediaList.map((media) => {
            const [mediaName, createdOn, size] = media;
            return {
              threadId,
              mediaId: mediaName,
              mediaName,
              createdOn,
              size,
            };
          });

          const mappedMedias = arrayMapper(medias, 'mediaId');
          console.log('🚀 ~ file: Media.store.js ~ line 78 ~ .then ~ mappedMedias', mappedMedias, Object.values(mappedMedias).length);
          commit('SET_MEDIAS', mappedMedias);

          const threadsHasMedias = Object.values(mappedMedias).map(({ mediaId }) => ({ threadId, mediaId }));
          console.log('🚀 ~ file: Media.store.js ~ line 82 ~ .then ~ threadsHasMedias', threadsHasMedias);

          commit('Thread/SET_THREADS_HAS_MEDIAS', threadsHasMedias, { root: true });
        });
    },
  },
  mutations: {
    SET_MEDIAS(state, newDatas) { state.medias = updateDatasIfExists({ newDatas, actualDatas: state.medias, key: 'mediaId' }); },
    SET_MEDIAS_FB(state, newDatas) {
      // (Object.values(newDatas)).map((d) => {
      //   console.log('Iter', d);
      //   if (state.mediasFB[d.mediaFBId]) { console.log('Still exist lol', d, state.mediasFB[d.mediaFBId]); }
      // });
      state.mediasFB = updateDatasIfExists({ newDatas, actualDatas: state.mediasFB, key: 'mediaFBId' });
    },
    UNSET_MEDIAS_FB(state, mediaFBId) {
      const myMedias = Object.values({ ...state.mediasFB }).filter((c) => c.mediaFBId !== mediaFBId);
      state.mediasFB = arrayMapper(myMedias, 'mediaFBId');
    },
    SET_MEDIASFB_HAS_COMMENTS(state, newDatas) { state.mediasFBHasComments = updateRelationsIfExists(state.mediasFBHasComments, newDatas); },
    SET_MEDIA_READ(state, id) {
      if (id && state.mediasFB[id]) {
        state.mediasFB[id].isUnread = false;
      }
    },
    UNSET_MEDIASFB_HAS_COMMENTS(state, commentId) { state.mediasFBHasComments = state.mediasFBHasComments.filter((mc) => mc.commentId !== commentId); },
    UPDATE_MEDIA_OPEN_COUNT(state, { mediaFBId, newCount }) { state.mediasFB[mediaFBId].open_parent_comments_count = newCount; },
    INC_OPENED_COUNT(state, ids) {
      ids.forEach((id) => {
        if (state.mediasFB[id]) { state.mediasFB[id].open_parent_comments_count += 1; }
      });
    },
    RESET_STORES(state) {
      state.medias = {};
      state.mediasFB = {};
      state.mediasFBHasComments = [];
    },
  },
};
