<template>
  <div id="UbuCommonVideo">
    <div
      v-if="!playVideo"
      class="UbuMedia_preview"
    >
      <UbuIcon
        class="UbuMedia_previewIcon"
        name="play"
        size="24px"
        color="1"
        filled
        clickable
        @click="turnOnMediaAndPlay($event)"
      />
      <UbuInstaImg
        :insta-src="imageUrl"
        :has-bucket="hasBucket"
      />
    </div>
    <video
      v-else
      ref="video"
      controls
      autoplay
      preload="none"
      :poster="imageUrl"
    >
      <source
        v-if="hasBucket"
        :src="bucketUrl"
        :type="mimeType"
        @error="$emit('regenerateVideo', $event)"
      >
      <source
        v-else
        :src="videoUrl"
        :type="mimeType"
        @error="$emit('regenerateVideo', $event)"
      >
      Sorry, your browser doesn't support embedded videos.
    </video>
  </div>
</template>

<script>
export default {
  name: 'UbuCommonVideo',
  props: {
    videoUrl: {
      type: String,
      required: true,
    },
    imageUrl: {
      type: String,
      required: true,
    },
    mimeType: {
      type: String,
      default: 'video/mp4',
    },
    hasBucket: {
      type: Boolean,
      default: false,
    },
    isExpired: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      playVideo: false,
      loadingImage: true,
      isModalOpen: false,
    };
  },
  computed: {
    bucketUrl() {
      // @TODO check encodedValidity
      return `https://io.getubu.com/media/${this.videoUrl}`;
    },
  },
  methods: {
    turnOnMediaAndPlay() {
      this.playVideo = true;
      this.$emit('turnOnMedia');
    },
  },
};
</script>

<style lang="scss">
#UbuCommonVideo {
  display: flex;
  & .UbuMedia_preview {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    & .UbuMedia_previewIcon {
      position: absolute;
      height: 24px;
      width: 24px;
      z-index: 20;
      & i {
        color: white;
        font-size: 24px;
      }
    }
  }
}
</style>
