export default {
  namespaced: true,

  state: {
    isReduced: false,
    width: 240,
    navigation: [
      {
        icon: 'home',
        to: process.env.VUE_APP_FACEBOOK_OFFICIEL_TEST ? '/workspace/connection' : '/home',
        text: 'Account',
      },
      {
        icon: 'inbox',
        to: '/in',
        text: 'Inbox',
        disabled: process.env.VUE_APP_FACEBOOK_OFFICIEL_TEST === 'true',
      },
      {
        icon: 'activity',
        text: 'Activities',
        to: '/activities',
        disabled: process.env.VUE_APP_FACEBOOK_OFFICIEL_TEST === 'true',
      },
      {
        icon: 'influence',
        to: '/influence',
        text: 'Influence',
        disabled: process.env.VUE_APP_FACEBOOK_OFFICIEL_TEST === 'true',
      },
      {
        icon: 'comments',
        text: 'Comment',
        to: '/comment',
      },
    ],
  },

  getters: {
    getterIsReduced: (state) => state.isReduced,
    getterWidth: (state) => state.width,
    getterNavigation: (state) => state.navigation,
  },

  actions: {
    UP_sidebarState({ commit }, isReduced) {
      console.log('🚀 ~ file: TheUbuSidebar.store.js ~ line 15 ~ UP_isReduced ~ isReduced', isReduced);
      commit('UPDATE_IS_REDUCED', isReduced);
      commit('UPDATE_WIDTH', isReduced ? 90 : 240);
    },
  },
  mutations: {
    UPDATE_IS_REDUCED: (state, isReduced) => { state.isReduced = isReduced; },
    UPDATE_WIDTH: (state, width) => { state.width = width; },
  },
};
