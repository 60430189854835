<template>
  <div class="workspace-modal">
    <template v-if="modalType === 'shopifyModal'">
      <div class="modal-header">
        <p class="modal-header-title ubu-title">Connect to shopify</p>

        <UbuIcon
          name="cross"
          clickable
          @click="$emit('close', $event)"
        />
      </div>

      <div class="mb-3">
        <a
          class="is-externalLink"
          href="https://intercom.help/getubu/en/articles/5591331-how-to-connect-ubu-with-shopify"
          target="_blank"
        >How to connect Ubu to Shopify ? <UbuIcon name="externalLink" /></a>
      </div>

      <b-field label="Website url">
        <b-input
          v-model="newShopifyConnect.urlWebsite"
          placeholder="https://myWebsite.com"
          required
        />
      </b-field>

      <b-field>
        <template #label>
          Shopify store url
          <b-tooltip
            label="Example: store.myshopify.com"
          >
            <UbuIcon name="info" />
          </b-tooltip>
        </template>
        <b-input
          v-model="newShopifyConnect.urlStore"
          placeholder="myWebsite.myshopify.com"
          required
        />
      </b-field>

      <b-field label="API key">
        <b-input
          v-model="newShopifyConnect.apiKey"
          placeholder="ab4ba4479d477e154dab"
          required
        />
      </b-field>

      <b-field label="Password">
        <b-input
          v-model="newShopifyConnect.password"
          type="password"
          password-reveal
          placeholder="********"
          required
        />
      </b-field>

      <b-field label="Exemple url">
        <b-input
          v-model="newShopifyConnect.exempleUrl"
          placeholder="https://96428e60fd41275be41cc075142c4def:shppa_4f061c4bfbb54d5cbe..."
          required
        />
      </b-field>

      <b-field label="SharedSecret">
        <b-input
          v-model="newShopifyConnect.sharedSecret"
          placeholder="4f061c4bfbb54d5cbe60b8fde73a34a7..."
          required
        />
      </b-field>

      <br>
      <div class="buttons is-right">
        <b-button
          label="Close"
          class="is-light is-rounded"
          @click="$emit('close', $event)"
        />
        <b-button
          label="Connect"
          class="is-geraldine is-rounded"
          :loading="isConnexionLoading"
          @click="connectShopify()"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { snackError } from '../../$catalog/snackbar';

export default {
  name: 'UbuWorkspaceConnectModal',
  props: {
    modalType: {
      type: String,
      required: true,
    },
    isConnexionLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      newShopifyConnect: {
        urlWebsite: '',
        urlStore: '',
        apiKey: '',
        password: '',
        exempleUrl: '',
        sharedSecret: '',
      },
    };
  },
  methods: {
    connectShopify() {
      const {
        urlWebsite, apiKey, urlStore, password, exempleUrl, sharedSecret,
      } = this.newShopifyConnect;

      if (urlWebsite.length && apiKey.length && urlStore.length
          && password.length && exempleUrl.length && sharedSecret.length) {
        this.$emit('connectShopify', this.newShopifyConnect);
      } else {
        this.$buefy.snackbar.open({
          ...snackError,
          message: 'You must complete all field',
          actionText: null,
        });
      }
    },
  },
};
</script>

<style>

</style>
