import { use, registerComponent } from '../../$utils/plugins';

// const UbuCommentMediaList = () => import(/* webpackChunkName: "addonUi" */ './UbuCommentMediaList');
// const UbuCommentMediaPost = () => import(/* webpackChunkName: "addonUi" */ './UbuCommentMediaPost');
// const UbuCommentThreadList = () => import(/* webpackChunkName: "addonUi" */ './UbuCommentThreadList');
// const UbuCommentThreadListHeader = () => import(/* webpackChunkName: "addonUi" */ './UbuCommentThreadListHeader');
// const UbuCommentThreadCard = () => import(/* webpackChunkName: "addonUi" */ './UbuCommentThreadCard');
// const UbuCommentThreadListHeaderFilters = () => import(/* webpackChunkName: "addonUi" */ './UbuCommentThreadListHeaderFilters');
// const UbuCommentThreadHeader = () => import(/* webpackChunkName: "addonUi" */ './UbuCommentThreadHeader');
// const UbuCommentTextarea = () => import(/* webpackChunkName: "addonUi" */ './UbuCommentTextarea');
// const UbuCommentThreadHeaderButtons = () => import(/* webpackChunkName: "addonUi" */ './UbuCommentThreadHeaderButtons');
// const UbuCommentTextareaHeader = () => import(/* webpackChunkName: "addonUi" */ './UbuCommentTextareaHeader');
// const UbuCommentPanel = () => import(/* webpackChunkName: "addonUi" */ './UbuCommentPanel');

import UbuCommentMediaList from './UbuCommentMediaList';
import UbuCommentMediaPost from './UbuCommentMediaPost';
import UbuCommentThreadList from './UbuCommentThreadList';
import UbuCommentThreadListHeader from './UbuCommentThreadListHeader';
import UbuCommentThreadCard from './UbuCommentThreadCard';
import UbuCommentThreadListHeaderFilters from './UbuCommentThreadListHeaderFilters';
import UbuCommentThreadHeader from './UbuCommentThreadHeader';
import UbuCommentTextarea from './UbuCommentTextarea';
import UbuCommentThreadHeaderButtons from './UbuCommentThreadHeaderButtons';
import UbuCommentTextareaHeader from './UbuCommentTextareaHeader';
import UbuCommentPanel from './UbuCommentPanel';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, UbuCommentMediaList);
    registerComponent(Vue, UbuCommentMediaPost);
    registerComponent(Vue, UbuCommentThreadList);
    registerComponent(Vue, UbuCommentThreadListHeader);
    registerComponent(Vue, UbuCommentThreadCard);
    registerComponent(Vue, UbuCommentThreadListHeaderFilters);
    registerComponent(Vue, UbuCommentThreadHeader);
    registerComponent(Vue, UbuCommentTextarea);
    registerComponent(Vue, UbuCommentThreadHeaderButtons);
    registerComponent(Vue, UbuCommentTextareaHeader);
    registerComponent(Vue, UbuCommentPanel);
  },
};

use(Plugin);

export default Plugin;

export {
  UbuCommentMediaList,
  UbuCommentMediaPost,
  UbuCommentThreadList,
  UbuCommentThreadListHeader,
  UbuCommentThreadCard,
  UbuCommentThreadListHeaderFilters,
  UbuCommentThreadHeader,
  UbuCommentTextarea,
  UbuCommentThreadHeaderButtons,
  UbuCommentTextareaHeader,
  UbuCommentPanel,
};
