<template>
  <div class="UbuInfluenceHomePage_wrapper">
    <header class="UbuInfluenceHomePage_header multibar">
      <h1 class="ubu-title start">Campaigns</h1>
      <b-button
        class="is-rounded is-light end"
        @click="isCardModalActive = !isCardModalActive"
      >
        <UbuIcon
          size="16px"
          name="add"
          filled
        />
        <span>
          New campaign
        </span>
      </b-button>
    </header>

    <section>
      <h2 class="UbuInfluenceHomePage_section-title">Live campaigns 🚀</h2>
      <br>
      <b-table
        :data="liveCampaigns"
        paginated
        per-page="10"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        pagination-rounded
        pagination-size="is-small"
        class="table-no-head ubuBTable"
      >
        <b-table-column
          v-slot="{ row: campaign }"
        >
          <div class="multibar">
            <UbuIcon
              name="influence"
              filled
              class="UbuInfluenceHomePage_picture a-self-center mr-2 start"
              :style="`color: ${campaign.color}`"
              size="25px"
            />
            <div class="multibar column start">
              <div class="ubu-text1">{{ campaign.name }}</div>
              <div class="flex ubu-subtext1 text-cloudburst60">
                <div>{{ $moment(campaign.created).format('YYYY-MM-DD') }}</div>
              </div>
            </div>
          </div>
        </b-table-column>

        <b-table-column
          v-slot="{ row: campaign }"
          numeric
        >
          <div class="multibar">
            <b-button
              tag="router-link"
              class="button ubuButton is-rounded is-light end"
              :to="`/influence/${campaign.campaignId}`"
            >
              See campaign
            </b-button>
            <UbuButton
              class="a-self-center m-1 end"
              color="2"
              @click="endCampaignModal(campaign)"
            >
              End
            </UbuButton>
          </div>
        </b-table-column>
      </b-table>
    </section>

    <section>
      <h2 class="UbuInfluenceHomePage_section-title">Ended campaigns ⏳</h2>
      <br>
      <b-table
        :data="endedCampaigns"
        paginated
        per-page="5"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        pagination-rounded
        pagination-size="is-small"
        class="table-no-head ubuBTable"
      >
        <b-table-column
          v-slot="{ row: campaign }"
        >
          <div class="multibar">
            <UbuIcon
              name="influence"
              filled
              class="UbuInfluenceHomePage_picture a-self-center mr-2 start"
              :style="`color: ${campaign.color}`"
              size="25px"
            />
            <div class="multibar column start">
              <div class="ubu-text1">{{ campaign.name }}</div>
              <div class="flex ubu-subtext1 text-cloudburst60">
                <div>
                  {{ $moment(campaign.created).format('YYYY-MM-DD') }}
                  -
                  {{ $moment(campaign.updated).format('YYYY-MM-DD') }}
                </div>
              </div>
            </div>
          </div>
        </b-table-column>
        <b-table-column
          v-slot="{ row: campaign }"
          numeric
        >
          <div class="multibar">
            <b-button
              tag="router-link"
              class="button ubuButton is-rounded is-light end"
              :to="`/influence/${campaign.campaignId}`"
            >
              See campaign
            </b-button>
          </div>
        </b-table-column>
      </b-table>
    </section>
    <b-modal
      v-model="isCardModalActive"
      :width="640"
      scroll="keep"
    >
      <UbuInfluenceModalCreateCampaign
        :is-card-modal-active="isCardModalActive"
        :new-campaign="newCampaign"
        @close="isCardModalActive = false"
        @selectColor="selectColor($event)"
        @addFromModal="addFromModal($event)"
      />
    </b-modal>
    <b-modal
      v-model="isEndedModalActive"
      :width="640"
      scroll="keep"
    >
      <UbuCommonModal
        title="End campaign ?"
        @close="isEndedModalActive = false"
      >
        <template slot="info">
          <p>
            Are you sure you want to end
            <strong>
              {{ endCampaignTitle }}
            </strong>
            ? You won’t be able to undo this action.
          </p>
        </template>
        <template slot="actions">
          <UbuButton
            grow
            center
            class="m-2"
            @click="isEndedModalActive = false"
          >
            Cancel
          </UbuButton>
          <UbuButton
            grow
            center
            secondary
            class="m-2"
            @click="closeCampaign()"
          >
            End campaign
          </UbuButton>
        </template>
      </UbuCommonModal>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
// import moment from 'moment';
import CommonMixin from '../$mixins/CommonMixin';

export default {
  name: 'TheInfluenceHomePage',
  mixins: [CommonMixin],
  props: {
  },
  data() {
    return {
      isCardModalActive: false,
      isEndedModalActive: false,
      endCampaignId: null,
      endCampaignTitle: '',
      newCampaign: {
        name: '',
        color: this.convertFromColor(8),
      },
    };
  },
  computed: {
    ...mapGetters({
      currentChannelId: 'Channel/getterCurrentChannelId',
      campaigns: 'Campaign/getterCampaigns',
      channelHasCampaign: 'Channel/getterChannelsHasCampaigns',
    }),
    channelCampaigns() {
      return this.channelHasCampaign
        .filter(({ channelId }) => channelId === this.currentChannelId)
        .map(({ campaignId }) => this.campaigns[campaignId]);
    },
    liveCampaigns() {
      return this.channelCampaigns
        .filter(({ ended }) => !ended);
    },
    endedCampaigns() {
      return this.channelCampaigns
        .filter(({ ended }) => ended);
    },
  },
  methods: {
    ...mapActions({
      createCampaign: 'TheInfluence/createCampaign',
      endCampaign: 'TheInfluence/endCampaign',
    }),

    closeCampaign() {
      // need endpoint
      console.log('endCampaign', this.endCampaignId);
      return this.endCampaign({
        _id: this.endCampaignId,
        ended: true,
        name: this.endCampaignTitle,
        channel: this.currentChannelId,
      })
        .then(() => {
          this.isEndedModalActive = false;
        });
    },

    endCampaignModal({ name, campaignId }) {
      this.isEndedModalActive = !this.isEndedModalActive;
      this.endCampaignId = campaignId;
      this.endCampaignTitle = name;
    },

    addFromModal(event) {
      console.log('🚀 ~ file: TheUbuWorkspaceTags.vue ~ line 76 ~ addFromModal ~ event', event);
      const newCampaignWithChannel = {
        ...event,
        channel: this.currentChannelId,
        channelId: this.currentChannelId,
      };
      this.createCampaign(newCampaignWithChannel)
        .then((campaignId) => {
          this.isCardModalActive = false;
          this.$router.push(`/influence/${campaignId}/pipeline`);
        })
        .catch((error) => {
          this.$buefy.toast.open({
            duration: 5000,
            message: error.message,
            type: 'is-danger',
          });
        });
    },
    selectColor(event) {
      this.newCampaign.color = this.convertFromColor(event);
    },
  },
};
</script>

<style lang="scss">
.UbuInfluenceHomePage_wrapper{
  padding: 26px 36px;
  height: 100%;
  width: calc(100vw - 240px);
  overflow-y: auto;
  .UbuInfluenceHomePage_header{
    // display: flex;
    // justify-content: space-between;
    .ubu-title{
      color: var(--cloudburst);
    }
  }
  .UbuInfluenceHomePage_section{
    margin: 36px 0;
    .UbuInfluenceHomePage_section-title{
        color: var(--cloudburst);
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 10px;
      }
    .UbuInfluenceHomePage_content{
      margin: 20px 24px;
      .UbuInfluenceHomePage_content-title{
        color: var(--cloudburst);
      }
      .UbuInfluenceHomePage_content-description{
          color: var(--darkgrey);
          margin: 4px 0;
      }
      .UbuInfluenceHomePage_content-date{
          color: var(--darkgrey);
      }
    }
  }
  .UbuInfluenceHomePage_cards{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 20px 0;
    .UbuInfluenceHomePage_list {
      width: 100%;
      .UbuInfluenceHomePage_row {
        display: flex;
        justify-content: space-between;
        margin-top: 1rem;
        margin-bottom: 1rem;
        .UbuInfluenceHomePage_user {
          display: flex;
          .UbuInfluenceHomePage_picture {
            font-size: 25px;
          }
        }
      }
  }
    .UbuInfluenceHomePage_card{
      border: 1px solid var(--mercury);
      border-radius: 12px;
      width: 290px;
      height: 210px;
      flex-shrink: 0;
      margin-right: 16px;
      .UbuInfluenceHomePage_card-header{
        color: var(--darkgrey);
        border-bottom: 1px solid var(--mercury);
        padding: 16px;
      }
      .UbuInfluenceHomePage_card-thumbnail{
        height: 154px;
        width: 100%;
        background-size: cover;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
      }
    }
  }
}
</style>
