<template>
  <div id="UbuContactPanelOrders">
    <div class="panel-header">
      <h3 class="ubu-header1 panel-title">Affiliation</h3>
      <UbuIcon
        v-if="hasShopifyShop"
        name="add"
        clickable
        @click="$emit('openModalAddLink')"
      />
    </div>

    <template v-if="hasShopifyShop">
      <div
        v-for="(item, index) in affiliationList"
        :key="index"
      >
        <div class="affiliation-card">
          <UbuContactPanelShopifyCard
            :affiliation="item"
          />

          <b-dropdown
            aria-role="list"
            position="is-bottom-left"
          >
            <template #trigger>
              <UbuIcon
                name="more"
                clickable
              />
            </template>

            <b-dropdown-item custom>
              <b-collapse :open="false">
                <template #trigger="props">
                  <div class="dropdown-text">
                    <UbuIcon name="influence" /> Link to campaign
                    <b-icon
                      :icon="props.open ? 'menu-down' : 'menu-up'"
                    />
                  </div>
                </template>
                <div
                  v-if="activeCampaignList.length"
                  class="card-content"
                >
                  <div
                    v-for="camp in activeCampaignList"
                    :key="camp.campaignId"
                    class="content"
                    @click="$emit('assignCampaignToLink',
                                  { campaignId: camp.campaignId, code: item })"
                  >
                    <div class="collapse-item">
                      <span :style="`color: ${camp.color}`">
                        <UbuIcon
                          name="influence"
                          filled
                          :style="`color: ${camp.color}`"
                        /> {{ camp.name }}
                      </span>
                      <UbuIcon
                        v-if="camp.campaignId === item.campaignId"
                        name="checkmark"
                        :style="`color: ${camp.color}`"
                      />
                    </div>
                  </div>
                </div>
                <div
                  v-else
                  class="card-content"
                >
                  <span class="ubu-info">This contact is not into a campaign</span>
                </div>
              </b-collapse>
            </b-dropdown-item>

            <b-dropdown-item @click="$emit('openModalCustomLinkUtm', item)">
              <span>
                <UbuIcon name="settings" /> Add utm to link
              </span>
            </b-dropdown-item>

            <b-dropdown-item>
              <a
                :href="`${prefixUrl}${item.link}`"
                target="_blank"
                class="action-redirect"
              >
                <UbuIcon name="externalLink" /> View in shopify
              </a>
            </b-dropdown-item>

            <b-dropdown-item
              class="text-geraldine"
              @click="removeContactToLink(item)"
            >
              <UbuIcon name="trash" /> Delete
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="ubu-divider" />
      </div>
    </template>

    <div v-else>
      <p class="panel-order-info ubu-info">Oops, it seems your shopify is not connected...</p>

      <b-button
        label="Connect to shopify"
        tag="router-link"
        to="/workspace/connection"
        class="is-rounded mt-4"
        expanded
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'UbuContactPanelOrders',
  props: {
    affiliationList: {
      type: Array,
      default: () => [],
    },
    activeCampaignList: {
      type: Array,
      default: () => [],
    },
    hasShopifyShop: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      prefixUrl: 'https://',
    };
  },
  methods: {
    removeContactToLink(event) {
      this.$buefy.dialog.confirm({
        title: 'Deleting affiliation',
        message: 'Are you sure you want to <b>delete</b> this affiliation? This action cannot be undone.',
        confirmText: 'Delete affiliation',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.$emit('removeContactToLink', event),
      });
    },
  },
};
</script>

<style lang="scss">
#UbuContactPanelOrders {
  & .panel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    & .panel-title {
      margin: 10px 0;
    }
  }
  & .panel-order-info {
    text-align: center;
  }
  & .affiliation-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    & .collapse-content {
    border: 1px solid var(--mercury);
    border-radius: 8px;
    max-height: 200px;
    overflow-y: auto;
      & .collapse-item {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
      }
    }
    & .dropdown-text {
      color: var(--cloudburst);
    }
    & .dropdown-content {
      background-color: var(--peachcream);
      & .action-redirect {
        color: var(--cloudburst);
      }
    }
  }
}
</style>
