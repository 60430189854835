<template>
  <div
    id="UbuFilterSelector"
    v-click-outside="() => showModal = false"
  >
    <button
      :class="['filterSelector-button ubu-text1', {'active': active}]"
      @click="showModal = !showModal"
    >
      {{ buttonTitle }}
    </button>
    <div
      v-if="showModal"
      class="filterSelector-modal"
    >
      <span class="ubu-text1 filterSelector-title">{{ modalTitle }}</span>

      <div
        v-if="filter.type === 'checkbox'"
        class="filterSelector-container checkbox-selector"
      >
        <b-field
          v-for="(item, index) in checkboxList"
          :key="index"
        >
          <b-checkbox
            v-model="checkboxGroup"
            :native-value="item"
          >
            {{ item }}
          </b-checkbox>
        </b-field>
      </div>

      <div
        v-if="filter.type === 'number'"
        class="filterSelector-container number-input-selector"
      >
        <b-field
          label="Min"
          custom-class="is-small"
        >
          <b-numberinput
            v-model="localRange.min"
            size="is-small"
            controls-alignment="right"
            controls-position="compact"
            controls-rounded
            min="0"
            expanded
            :max="localRange.max"
          />
        </b-field>
        <b-field
          label="Max"
          custom-class="is-small"
        >
          <b-numberinput
            v-model="localRange.max"
            size="is-small"
            controls-alignment="right"
            controls-position="compact"
            controls-rounded
            expanded
            :min="localRange.min"
            max="100"
          />
        </b-field>
      </div>

      <div
        v-if="filter.type === 'range'"
        class="filterSelector-container"
      >
        <b-slider
          v-model="localRange"
          :min="range[0]"
          :max="range[1]"
          :tooltip="false"
        >
          <b-slider-tick
            v-for="(tick, index) in ticks"
            :key="index"
            :value="index"
          >
            {{ tick.text }}
          </b-slider-tick>
        </b-slider>
      </div>

      <div
        v-if="filter.type === 'radio'"
        class="filterSelector-container radio-selector"
      >
        <b-field
          v-for="(item, index) in radioList"
          :key="index"
        >
          <b-radio
            v-model="radio"
            :native-value="item"
          >
            {{ item }}
          </b-radio>
        </b-field>
      </div>

      <div class="filterSelector-modal-button-group">
        <b-button
          class="is-light is-rounded"
          expanded
          @click="reset()"
        >
          Reset
        </b-button>
        <b-button
          class="is-geraldine is-rounded"
          expanded
          @click="applyFilters()"
        >
          Apply
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'UbuCRMFilterGenerator',
  props: {
    buttonTitle: {
      type: String,
      default: '',
    },
    modalTitle: {
      type: String,
      default: '',
    },
    checkboxList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showModal: false,
      checkboxGroup: [],
    };
  },
  computed: {
    active() {
      return this.showModal
      || this.checkboxGroup.length;
    },
  },
  methods: {
    reset() {
      this.checkboxGroup = [];
      this.showModal = false;
      this.$emit('setCheckFilter', { checked: this.checkboxGroup });
    },
    applyFilters() {
      this.showModal = false;
      this.$emit('setCheckFilter', { checked: this.checkboxGroup });
    },
  },
};
</script>
