<template>
  <div
    v-if="!isError"
    :class="[ 'UbuCommonPostCard', {'isPost': !isStory, 'isStory': isStory, }]"
  >
    <div
      v-if="loading || !isImageLoaded"
      class="UbuCommonPostCard_skeleton"
    >
      <b-skeleton
        :rounded="false"
        :active="loading || !isImageLoaded"
        height="320px"
      />
    </div>
    <div
      v-if="media"
      class="UbuCommonPostCard_card"
      @click="$emit('show')"
    >
      <div
        class="UbuCommonPostCard_gradient"
      >
        <b-image
          v-if="media.mediaType === 'IMAGE' || media.mediaType === 'CAROUSEL_ALBUM'"
          :class="[ 'UbuCommonPostCard_image', {'showImage': isImageLoaded }]"
          :src="media.mediaUrl"
          :ratio="isStory ? '180by320' : '1by1'"
          responsive
          lazy
          @load="onImgLoad()"
          @error="isError = true"
        />
        <video
          v-else
          ref="video"
          muted
          @loadeddata="onImgLoad()"
        >
          <source
            :src="media.mediaUrl"
            :type="'video/mp4'"
            @error="isError = true;"
          >
          Sorry, your browser doesn't support embedded videos.
        </video>
      </div>
      <div class="UbuCommonPostCard_infos">
        <p class="ubu-text3">{{ media.date }}</p>
        <div class="UbuCommonPostCard_stats ubu-subtitle">
          <div
            v-if="formatedLikeCount"
            class="UbuCommonPostCard_statsBlock"
          >
            <i class="ubu-icons ubu-icons-heart-outlined" />
            <span>{{ formatedLikeCount }}</span>
          </div>
          <div
            v-if="formatedCommentCount"
            class="UbuCommonPostCard_statsBlock"
          >
            <i class="ubu-icons ubu-icons-comments-outlined" />
            <span>{{ formatedCommentCount }}</span>
          </div>
          <div
            v-if="formatedSavedCount"
            class="UbuCommonPostCard_statsBlock"
          >
            <i class="ubu-icons ubu-icons-bookmark-outlined" />
            <span>{{ formatedSavedCount }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { shortenNumber } from '../../$utils/formate';

export default {
  name: 'UbuCommonPostCard',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    isStory: {
      type: Boolean,
      default: false,
    },
    media: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isImageLoaded: false,
      isError: false,
    };
  },
  computed: {
    formatedLikeCount() {
      if (!this.media.likeCount || this.media.likeCount === 0) return null;
      return shortenNumber(this.media.likeCount);
    },
    formatedCommentCount() {
      if (!this.media.commentCount || this.media.commentCount === 0) return null;
      return shortenNumber(this.media.commentCount);
    },
    formatedSavedCount() {
      if (!this.media.savedCount || this.media.savedCount === 0) return null;
      return shortenNumber(this.media.savedCount);
    },
  },
  methods: {
    onImgLoad() {
      this.isImageLoaded = true;
    },
  },
};
</script>
