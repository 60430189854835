<template>
  <div id="UbuCRMFilters">
    <div
      class="button-group"
    >
      <UbuSimpleInput
        v-model="localSearchValue"
        class="list-button"
        placeholder="Search..."
        searchbar
      >
        <UbuIcon
          slot="placeholderIcon"
          name="search"
        />
      </UbuSimpleInput>
      <!-- <UbuButton
        class="list-button"
        :icon="{ name: 'filter' }"
        :active="showFilters"
        :disabled="!hasFiltersAvailable"
        @click="showFilters = !showFilters, showSettings = false"
      /> -->
    </div>
    <!-- <div
      v-if="isShowFiltersAvailable && hasFiltersAvailable"
      class="list-filters"
    >
      <h1 class="ubu-header1">Filters</h1>
      <div class="button-group">
        <div
          v-for="filter in filterListSortedByOrdinal"
          :key="filter.ordinal"
        >
          <UbuNumberInputRangeSelector
            v-if="filter.type === 'number'"
            :button-title="filter.buttonTitle"
            :modal-title="filter.modalTitle"
            :range="filter.range"
            @setNumberInputFilter="$emit('setNumberInputFilter', { name: filter.name, ...$event })"
          />
          <UbuRangeSelector
            v-if="filter.type === 'range'"
            :button-title="filter.buttonTitle"
            :modal-title="filter.modalTitle"
            :range="filter.range"
            :ticks="filter.ticks"
            @setRangeFilter="$emit('setRangeFilter', { name: filter.name, ...$event })"
          />
          <UbuCheckboxSelector
            v-if="filter.type === 'checkbox'"
            :button-title="filter.buttonTitle"
            :modal-title="filter.modalTitle"
            :checkbox-list="filter.value"
            :default-option="filter.defaultOption ? filter.defaultOption : {}"
            @setCheckFilter="$emit('setCheckFilter', { name: filter.name, ...$event})"
          />

          <UbuRadioSelector
            v-if="filter.type === 'radio'"
            :button-title="filter.buttonTitle"
            :modal-title="filter.modalTitle"
            :radio-list="filter.value"
            @setRadioFilter="$emit('setRadioFilter', { name: filter.name, ...$event})"
          />
        </div>
      </div>
    </div> -->
    <br v-if="isShowFiltersAvailable && hasFiltersAvailable">
    <UbuCRMViewSettings
      v-if="isShowSettingsAvailable"
      :custom-c-r-m-columns="customCRMColumns"
      :selected-custom="selectedCustom"
      @openCreateView="$emit('openCreateView', $event)"
      @customColumns="$emit('customColumns', $event)"
    />

    <br v-if="isShowSettingsAvailable">
    <div
      v-if="checkedRows.length"
      class="list-filters"
    >
      <h1 class="ubu-header1">{{ checkedRows.length }} selected</h1>
      <div class="button-group">
        <b-dropdown
          aria-role="list"
          class="crm-dropdown-content mr-1"
        >
          <template #trigger="{ active }">
            <b-button
              class="is-light is-rounded"
              :icon-right="active ? 'menu-up' : 'menu-down'"
            >
              <UbuIcon
                name="influence"
                :filled="true"
              />
              Add to campaign
            </b-button>
          </template>

          <b-dropdown-item
            v-for="(campaign, index) in activeCampaignList"
            :key="index"
            aria-role="listitem"
            class="listitem"
            @click="$emit('selectCampaign', campaign)"
          >
            {{ campaign.title }} <UbuIcon name="add" />
          </b-dropdown-item>
        </b-dropdown>

        <UbuSelector
          class="mr-2"
          reduced
          :choices="contactGroups"
          :paths="{ id: 'contactGroupId', label: 'title' }"
          label-icon="contact"
          :tooltip="{
            label: `Add ${checkedRows.length} as contact${checkedRows.length !== 1 ? 's' : ''}`,
            position: 'is-bottom'
          }"
          search-placeholder="Create or search contacts..."
          create-placeholder="Create new contact:"
          @select="$emit('assignContactsToContactGroup', {
            threads: checkedRows, contactGroup: $event })"
          @create="$emit('createContactGroupAndAssignMany', { threads: checkedRows, ...$event})"
        />

        <b-tooltip
          :label="enrichProfilesTooltipLabel"
          position="is-bottom"
        >
          <b-button
            class="is-light is-rounded mr-2"
            :loading="isEnrichProfilesLoading"
            :disabled="isEnrinchProfilesDisabled"
            @click="$emit('enrichProfiles', $event)"
          >
            Enrich profiles
          </b-button>
        </b-tooltip>

        <b-button
          v-if="list"
          class="mr-2"
          rounded
          @click="$emit('removeUserFromList')"
        >
          <UbuIcon
            name="trash"
            :filled="true"
          />
          Remove from list
        </b-button>

        <UbuButton
          class="list-button"
          primary
          :icon="{ name: 'failure', filled: true }"
          @click="$emit('clearCheckedRow', $event)"
        >
          Cancel
        </UbuButton>
      </div>
    </div>
    <br v-if="checkedRows.length">
    <div class="button-group filter-list">
      <div
        v-for="filter in primaryFilterList"
        :key="filter.ordinal"
      >
        <UbuNumberInputRangeSelector
          v-if="filter.type === 'number'"
          :button-title="filter.buttonTitle"
          :modal-title="filter.modalTitle"
          :range="filter.range"
          @setNumberInputFilter="$emit('setNumberInputFilter', { name: filter.name, ...$event })"
        />
        <UbuRangeSelector
          v-if="filter.type === 'range'"
          :button-title="filter.buttonTitle"
          :modal-title="filter.modalTitle"
          :range="filter.range"
          :ticks="filter.ticks"
          @setRangeFilter="$emit('setRangeFilter', { name: filter.name, ...$event })"
        />
        <UbuCheckboxSelector
          v-if="filter.type === 'checkbox'"
          :button-title="filter.buttonTitle"
          :modal-title="filter.modalTitle"
          :checkbox-list="filter.value"
          :default-option="filter.defaultOption ? filter.defaultOption : {}"
          @setCheckFilter="$emit('setCheckFilter', { name: filter.name, ...$event})"
        />

        <UbuRadioSelector
          v-if="filter.type === 'radio'"
          :button-title="filter.buttonTitle"
          :modal-title="filter.modalTitle"
          :radio-list="filter.value"
          @setRadioFilter="$emit('setRadioFilter', { name: filter.name, ...$event})"
        />
      </div>
      <b-button
        v-if="!showMoreFilters"
        label="Show advanced filters"
        class="is-geraldine is-rounded"
        @click="showMoreFilters = true"
      />
      <template v-if="showMoreFilters">
        <div
          v-for="filter in secondaryFilterList"
          :key="filter.ordinal"
        >
          <UbuNumberInputRangeSelector
            v-if="filter.type === 'number'"
            :button-title="filter.buttonTitle"
            :modal-title="filter.modalTitle"
            :range="filter.range"
            @setNumberInputFilter="$emit('setNumberInputFilter', { name: filter.name, ...$event })"
          />
          <UbuRangeSelector
            v-if="filter.type === 'range'"
            :button-title="filter.buttonTitle"
            :modal-title="filter.modalTitle"
            :range="filter.range"
            :ticks="filter.ticks"
            @setRangeFilter="$emit('setRangeFilter', { name: filter.name, ...$event })"
          />
          <UbuCheckboxSelector
            v-if="filter.type === 'checkbox'"
            :button-title="filter.buttonTitle"
            :modal-title="filter.modalTitle"
            :checkbox-list="filter.value"
            :default-option="filter.defaultOption ? filter.defaultOption : {}"
            @setCheckFilter="$emit('setCheckFilter', { name: filter.name, ...$event})"
          />
          <UbuRadioSelector
            v-if="filter.type === 'radio'"
            :button-title="filter.buttonTitle"
            :modal-title="filter.modalTitle"
            :radio-list="filter.value"
            @setRadioFilter="$emit('setRadioFilter', { name: filter.name, ...$event})"
          />
        </div>
        <b-button
          v-if="showMoreFilters"
          label="Hide advanced filters"
          class="is-geraldine is-rounded"
          @click="showMoreFilters = false"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { filtersAvailable } from '../$utils/filterManagement';

export default {
  name: 'UbuCRMFilters',
  model: {
    prop: 'searchValue',
  },
  props: {
    searchValue: {
      type: String,
      required: true,
    },
    checkBoxFilters: {
      type: Array,
      default: () => [],
    },
    rangeFilters: {
      type: Array,
      default: () => [],
    },
    numberInputRangeFilters: {
      type: Array,
      default: () => [],
    },
    radioFilters: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    checkedRows: {
      type: Array,
      default: () => [],
    },
    activeCampaignList: {
      type: Array,
      default: () => [],
    },
    filterListSortedByOrdinal: {
      type: Array,
      default: () => [],
    },
    list: {
      type: Object,
      default: () => {},
    },
    isEnrichProfilesLoading: {
      type: Boolean,
      default: false,
    },
    isEnrinchProfilesDisabled: {
      type: Boolean,
      default: false,
    },
    customCRMColumns: {
      type: Array,
      default: () => [],
    },
    selectedCustom: {
      type: String,
      default: null,
    },
    enrichProfilesTooltipLabel: {
      type: String,
      default: '',
    },
    contactGroups: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showFilters: false,
      showSettings: false,
      showMoreFilters: false,
    };
  },
  computed: {
    localSearchValue: {
      get() { return this.searchValue; },
      set(value) { this.$emit('input', value); },
    },
    isShowFiltersAvailable() {
      return this.showFilters && !this.showSettings;
    },
    isShowSettingsAvailable() {
      return this.showSettings && !this.showFilters;
    },
    hasFiltersAvailable() {
      return !!(this.numberInputRangeFiltersToDisplay.length
      || this.rangeFiltersToDisplay.length || this.checkBoxFiltersToDisplay.length);
    },
    numberInputRangeFiltersToDisplay() {
      return filtersAvailable(this.numberInputRangeFilters, this.columns);
    },
    rangeFiltersToDisplay() {
      return filtersAvailable(this.rangeFilters, this.columns);
    },
    checkBoxFiltersToDisplay() {
      return filtersAvailable(this.checkBoxFilters, this.columns);
    },
    primaryFilterList() {
      return this.filterListSortedByOrdinal.filter(({ primary }) => primary);
    },
    secondaryFilterList() {
      return this.filterListSortedByOrdinal.filter(({ primary }) => !primary);
    },
  },
};
</script>

<style lang="scss">
.crm-dropdown-content {
  // @extend ubuDropdown;
  & .dropdown-content {
    max-height: 300px;
    overflow-y: auto;
  }
}
</style>
