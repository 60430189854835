<template>
  <section id="TheMentionsCustomLists">
    <header class="multibar">
      <h1 class="ubu-title start">
        Hashtags
      </h1>
      <UbuButton
        class="end"
        :icon="{ name: 'add', filled: true }"
        :disabled="!isFbChannelActive"
        :tooltip="{
          label: !isFbChannelActive ? 'You need to connect your Facebook Channel first' : '',
          position: 'is-left',
        }"
        secondary
        @click="isModalAddHashtagActive = !isModalAddHashtagActive"
      >
        New Hashtag
      </UbuButton>
    </header>
    <div class="listButtons">
      <UbuButton
        v-for="(hashtag, index) in hashtags"
        :key="index"
        :active="hashtag.name === currentHashtag.name"
        simple
        @click="fetchOneHashtag(hashtag.name)"
      >
        #{{ hashtag.name }}
      </UbuButton>
    </div>
    <div class="flex mb-2">
      <UbuButton
        :active="!tableView"
        simple
        class="mr-2"
        @click="tableView = false"
      >
        Medias
      </UbuButton>
      <UbuButton
        :active="tableView"
        simple
        @click="tableView = true"
      >
        Influencers view
      </UbuButton>
    </div>
    <UbuMentionsHashtagsFilters
      v-model="searchValue"
      :show-tik-tok="showTikTok"
      :show-insta="showInsta"
      :checked-rows="checkedRows"
      :campaign-list="campaignList"
      :check-box-filters="checkBoxFilters"
      :contact-groups="Object.values(contactGroups)"
      @toggleTikTok="showTikTok = !showTikTok"
      @toggleInsta="showInsta = !showInsta"
      @setRangeFilter="setRangeFilter($event)"
      @setEngagementFilter="setEngagementFilter($event)"
      @setPostEngagementFilter="setPostEngagementFilter($event)"
      @setCheckFilter="setCheckFilter($event)"
      @clearCheckedRow="checkedRows = []"
      @selectCampaign="addContactsIntoCampaign($event)"
      @assignContactsToContactGroup="assignContactsToContactGroup($event)"
      @createContactGroupAndAssignMany="createContactGroupAndAssignMany($event)"
    />
    <div class="flex mt-4">
      <UbuButton
        v-if="filteredMedias.length && !tableView"
        class="mr-2"
        secondary
        :loading="mediasDownloading"
        @click="downloadMedias({ medias: filteredMedias })"
      >
        Download all
      </UbuButton>
      <download-csv
        v-if="isFbChannelActive && tableView"
        :data="filteredInfluencers"
        :fields="csvFields"
        :name="`export-influencers-#${currentHashtag.name}.csv`"
      >
        <UbuButton
          class="UbuWorkspaceCustoms_button"
          secondary
        >
          Export Table
        </UbuButton>
      </download-csv>
    </div>
    <UbuMentionsHashtagsTable
      v-if="isFbChannelActive && tableView"
      :list-filtered="filteredInfluencers"
      :checked-rows="checkedRows"
      :loading="loading"
      :thread-groups-list="Object.values(threadGroups)"
      :contact-groups-list="Object.values(contactGroups)"
      :campaign-list="campaignList.filter(({ ended }) => !ended)"
      :is-generate-report-loading="isGenerateReportLoading"
      :is-statistics-loading="isStatisticsLoading"
      @checkRows="checkRows($event)"
      @addCustom="addCustom($event)"
      @removeCustom="removeCustom($event)"
      @generateReport="generateReport($event)"
      @updateContact="updateContact($event)"
      @createThreadGroupAndAssign="createThreadGroupAndAssign($event)"
      @createContactGroupAndAssign="createContactGroupAndAssign($event)"
      @openNewMessage="openNewMessage($event)"
      @showHashtag="showHashtag($event)"
      @fetchTiktokUser="fetchTiktokUser($event)"
    />
    <b-modal
      v-model="isModalAddHashtagActive"
      :width="640"
      scroll="keep"
    >
      <UbuMentionsModalAddHashtag
        :is-card-modal-active="isModalAddHashtagActive"
        @close="isModalAddHashtagActive = false"
        @addFromModal="addHashtag($event)"
      />
    </b-modal>
    <div
      v-if="!isFbChannelActive"
      class="dashboard-error-content"
    >
      <p>We couldn't load your hashtags, please check if your Facebook Channel is connected</p>
      <b-button
        to="/workspace/connection"
        class="dashboard-error-button ubuButton is-primary"
        tag="router-link"
      >
        Connection
      </b-button>
    </div>
    <div
      v-if="isFbChannelActive && loading && !tableView"
      v-masonry
      transition-duration=".25s"
      item-selector=".masonry-item"
      class="masonry-container"
      horizontal-order="true"
      fit-width="true"
    >
      <div
        v-for="index in 30"
        :key="index"
        v-masonry-tile
        class="masonry-item"
      >
        <UbuMentionsHashtagsMediaCard
          is-loading
        />
      </div>
    </div>
    <div
      v-else-if="isFbChannelActive && !tableView"
      v-masonry
      transition-duration=".25s"
      item-selector=".masonry-item"
      class="masonry-container"
      horizontal-order="true"
      fit-width="true"
    >
      <div
        v-for="media in currentMedias"
        :key="media.id"
        v-masonry-tile
        class="masonry-item"
      >
        <UbuMentionsHashtagsMediaCard
          :media="media"
        />
      </div>
    </div>
    <b-pagination
      v-if="isFbChannelActive && !tableView"
      v-model="currentPage"
      class="TheMentionsGallery_pagination"
      :per-page="mediasPerPage"
      :total="filteredMedias.length"
      :range-before="5"
      :range-after="5"
    />
    <div class="flex mb-2">
      <UbuButton
        v-if="isFbChannelActive"
        secondary
        @click="endHashtag(currentHashtag.name)"
      >
        End hashtag
      </UbuButton>
    </div>
    <b-modal
      v-model="showInfluencerHashtagMedia"
      :width="220"
      scroll="clip"
      @close="influencerHashtagMedia = null"
    >
      <div>
        <UbuMentionsHashtagsMediaCard
          :media="influencerHashtagMedia"
        />
      </div>
    </b-modal>
    <b-modal
      v-model="isMessageModalActive"
      :width="640"
      scroll="keep"
    >
      <UbuCommonNewMessageModal
        @close="isMessageModalActive = false"
        @sendNewMessage="sendNewMessage({user: userNewMessage, message: $event});"
      />
    </b-modal>
    <b-modal
      v-model="isEndedModalActive"
      :width="640"
      scroll="keep"
    >
      <UbuCommonModal
        title="Contact(s) added to campaign"
        @close="isEndedModalActive = false"
      >
        <template slot="info">
          <p>
            {{ contactAddedToCampaign }} have been added to campaign
          </p>
          <p>
            {{ contactAlreadyInCampaign }} were already in this campaign
          </p>
        </template>
        <template slot="actions">
          <UbuButton
            grow
            center
            secondary
            class="m-2"
            @click="isEndedModalActive = false"
          >
            Close
          </UbuButton>
        </template>
      </UbuCommonModal>
    </b-modal>
    <b-modal
      v-model="isModalEditUsernameTiktok"
      :width="640"
      scroll="keep"
    >
      <UbuContactPanelModalTiktokUsername
        @close="isModalEditUsernameTiktok = false"
        @addFromModal="updateTiktokUsername($event)"
      />
    </b-modal>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { snackSuccess } from '@/addons/$catalog/snackbar';
import MentionsMixin from './$mixins/MentionsMixin';
import {
  getTitleListWithoutDuplicate,
  getListWithoutDuplicate,
} from '../crm/$utils/filterManagement';

export default {
  name: 'TheMentionsCustomLists',
  mixins: [MentionsMixin],
  data() {
    return {
      searchValue: '',
      isModalAddHashtagActive: false,
      loading: true,
      searchHashtag: '',
      currentHashtag: {},
      influencerHashtagMedia: null,
      showInfluencerHashtagMedia: false,
      currentPage: 1,
      mediasPerPage: 30,
      followerRange: [],
      likeRange: [],
      commentRange: [],
      postRange: [],
      currentLabelCheckList: [],
      currentLanguageCheckList: [],
      engagementRange: {},
      postEngagementRange: {},
      showTikTok: true,
      showInsta: true,
      mediasDownloading: false,
      checkedRows: [],
      tableView: false,
      isEndedModalActive: false,
      contactAddedToCampaign: 0,
      contactAlreadyInCampaign: 0,
      isGenerateReportLoading: null,
      isMessageModalActive: false,
      userNewMessage: null,
      csvFields: ['username', 'platform', 'followerCount', 'views', 'engagement', 'averagePostEngagement', 'averagePostLikes', 'averagePostComments', 'averageReach', 'language', 'locations', 'nbPosts', 'lastMentionOrTagPost'],
      medias: [
        {
          mediaUrl: 'https://picsum.photos/seed/azefdg/1080/1080',
          source: 'instagram',
        },
        {
          mediaUrl: 'https://picsum.photos/seed/dsfdf/1080/1350',
          source: 'instagram',
        },
        {
          mediaUrl: 'https://picsum.photos/seed/sdgfdhg/1080/1080',
          source: 'tiktok',
        },
        {
          mediaUrl: 'https://picsum.photos/seed/qsdfgf/1080/1080',
          source: 'instagram',
        },
        {
          mediaUrl: 'https://picsum.photos/seed/zerfg/1080/1920',
          source: 'tiktok',
        },
        {
          mediaUrl: 'https://picsum.photos/seed/wxdfgy/1080/1350',
          source: 'instagram',
        },
        {
          mediaUrl: 'https://picsum.photos/seed/zedfb/1080/1080',
          source: 'tiktok',
        },
        {
          mediaUrl: 'https://picsum.photos/seed/zert/1080/1920',
          source: 'instagram',
        },
        {
          mediaUrl: 'https://picsum.photos/seed/qdsfgh/1080/1080',
          source: 'tiktok',
        },
        {
          mediaUrl: 'https://picsum.photos/seed/wsdrt/1080/1920',
          source: 'instagram',
        },
        {
          mediaUrl: 'https://picsum.photos/seed/zesrtfgh/1080/1080',
          source: 'tiktok',
        },
        {
          mediaUrl: 'https://picsum.photos/seed/azzsdxcefdg/1080/1920',
          source: 'instagram',
        },
        {
          mediaUrl: 'https://picsum.photos/seed/azedrfg/1080/1350',
          source: 'instagram',
        },
        {
          mediaUrl: 'https://picsum.photos/seed/cvbn/1080/1920',
          source: 'tiktok',
        },
        {
          mediaUrl: 'https://picsum.photos/seed/qsdfsd/1080/1920',
          source: 'tiktok',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      fetchIsDone: 'Sequence/getterFetchDone',
      isDriveActive: 'Channel/getterIsDriveConnected',
      campaignActivitiesDetails: 'TheInfluence/getterCampaignActivitiesDetails',
      currentChannelId: 'Channel/getterCurrentChannelId',
      hashtags: 'TheMentions/getterHashtagsArray',
      threadGroups: 'ThreadGroup/getterThreadGroups',
      contactGroups: 'ContactGroup/getterContactGroups',
      influencers: 'TheMentions/getterCurrentInfluencers',
      getterFbChannel: 'Channel/getterFbChannel',
    }),
    filteredMedias() {
      let result = this.currentHashtag.medias || [];
      console.log('🚀 ~ file: TheMentionsCustomLists.vue ~ line 369 ~ filteredMedias ~ result', result);
      const influencersUsernames = this.filteredInfluencers.map((user) => user.username);
      result = result.filter((media) => influencersUsernames.includes(media.username));
      if (!this.showInsta) {
        result = result.filter((media) => media.source !== 'instagram');
      }
      if (!this.showTikTok) {
        result = result.filter((media) => media.source !== 'tiktok');
      }
      if (this.searchValue) {
        const mySearch = this.searchValue.toLowerCase();
        result = result.reduce((acc, media) => {
          const { username, caption, id } = media;
          if (username && username.toLowerCase().includes(mySearch)) {
            if (!acc.find((r) => r.id === id)) acc.push(media);
          }
          if (caption && caption.toLowerCase().includes(mySearch)) {
            if (!acc.find((r) => r.id === id)) acc.push(media);
          }
          return acc;
        }, []);
      }
      if (this.followerRange.length === 2) {
        const [min, max] = this.followerRange;
        result = result.filter((media) => media.followerCount >= min && media.followerCount <= max);
      }
      if (this.likeRange.length === 2) {
        const [min, max] = this.likeRange;
        result = result.filter((media) => media.likeCount >= min && media.likeCount <= max);
      }
      if (this.commentRange.length === 2) {
        const [min, max] = this.commentRange;
        result = result.filter((media) => media.commentCount >= min && media.commentCount <= max);
      }
      if (this.engagementRange.min) {
        const { min, max } = this.engagementRange;
        result = result.filter(
          (media) => media.engagement >= min && media.engagement <= max,
        );
      }
      return result;
    },
    filteredInfluencers() {
      let result = this.influencers || [];
      console.log('🚀 ~ file: TheMentionsCustomLists.vue ~ line 369 ~ filteredMedias ~ INFLUENCE', result);
      if (!this.showInsta) {
        result = result.filter((user) => user.source !== 'instagram');
      }
      if (!this.showTikTok) {
        result = result.filter((user) => user.source !== 'tiktok');
      }
      if (this.searchValue) {
        const mySearch = this.searchValue.toLowerCase();
        result = result.filter((user) => user.username.toLowerCase().includes(mySearch));
      }
      if (this.currentLabelCheckList.length) {
        result = result
          .filter(({ contactGroup }) => {
            const labels = contactGroup.map(({ title }) => title);
            return labels.some((c) => this.currentLabelCheckList.includes(c));
          });
      }
      if (this.followerRange.length === 2) {
        const [min, max] = this.followerRange;
        result = result.filter((user) => user.followerCount >= min && user.followerCount <= max);
      }

      if (this.likeRange.length === 2) {
        const [min, max] = this.likeRange;
        result = result.filter(
          (user) => user.averagePostLikes >= min && user.averagePostLikes <= max,
        );
      }
      if (this.commentRange.length === 2) {
        const [min, max] = this.commentRange;
        result = result.filter(
          (user) => user.averagePostComments >= min && user.averagePostComments <= max,
        );
      }
      if (this.postRange.length === 2) {
        const [min, max] = this.postRange;
        result = result.filter((user) => user.nbPosts >= min && user.nbPosts <= max);
      }
      if (this.engagementRange.min) {
        const { min, max } = this.engagementRange;
        result = result.filter(
          (user) => user.engagement >= min && user.engagement <= max,
        );
      }
      if (this.postEngagementRange.min) {
        const { min, max } = this.postEngagementRange;
        result = result.filter(
          (user) => user.averagePostEngagement >= min && user.averagePostEngagement <= max,
        );
      }
      if (this.currentLanguageCheckList.length) {
        result = result
          .filter(({ language }) => (language
            ? this.currentLanguageCheckList.includes(language) : null));
      }
      return result;
    },
    currentMedias() {
      const start = (this.currentPage - 1) * this.mediasPerPage;
      const end = this.currentPage * this.mediasPerPage;
      return this.filteredMedias.slice(start, end);
    },
    labelList() {
      return getTitleListWithoutDuplicate(this.influencers, 'contactGroup');
    },
    languageList() {
      return getListWithoutDuplicate(this.influencers, 'language');
    },
    checkBoxFilters() {
      return [
        {
          name: 'contactGroup',
          buttonTitle: 'Labels',
          modalTitle: 'Labels',
          value: this.labelList,
        },
        {
          name: 'language',
          buttonTitle: 'Language',
          modalTitle: 'Language',
          value: this.languageList,
        },
      ];
    },
    isFbChannelActive() {
      return this.getterFbChannel && this.getterFbChannel.connected;
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    fetchIsDone() {
      this.fetchHashtags();
    },
  },
  mounted() {
    if (this.fetchIsDone) this.fetchHashtags();
  },
  methods: {
    ...mapActions({
      _assignThreadToThreadGroup: 'TheThreadHeader/assignThreadToThreadGroup',
      _removeThreadFromThreadGroup: 'TheThreadHeader/removeThreadFromThreadGroup',
      _createThreadGroupAndAssign: 'TheThreadHeader/createThreadGroupAndAssign',
      _assignContactToContactGroup: 'TheThreadHeader/assignContactToContactGroup',
      _removeContactFromContactGroup: 'TheThreadHeader/removeContactFromContactGroup',
      _createContactGroupAndAssign: 'TheThreadHeader/createContactGroupAndAssign',
      _generateReport: 'TheCRM/generateReport',
      fetchAllHashtags: 'TheMentions/fetchHashtags',
      fetchHashtag: 'TheMentions/fetchHashtag',
      startHashtag: 'TheMentions/startHashtag',
      stopHashtag: 'TheMentions/stopHashtag',
      dlMedias: 'TheMentions/downloadMedias',
      setUnscheduled: 'TheInfluence/setUnscheduled',
      deleteActivity: 'TheInfluence/deleteActivity',
      updateContact: 'TheContactPanel/updateContact',
      sendMessage: 'TheInbox/sendMessage',
    }),
    addHashtag(payload) {
      this.startHashtag(payload).then(() => {
        this.isModalAddHashtagActive = false;
      })
        .catch((error) => {
          this.isModalAddHashtagActive = false;
          this.$buefy.toast.open({
            duration: 5000,
            message: error.message,
            type: 'is-danger',
          });
        });
    },
    endHashtag(name) {
      this.stopHashtag(name).then(({ error }) => {
        if (!error) {
          this.$buefy.snackbar.open({
            ...snackSuccess,
            message: `hashtag ${name} was ended`,
          });
        }
      });
    },
    fetchOneHashtag(name) {
      this.currentHashtag = {};
      this.loading = true;
      this.currentPage = 1;
      this.fetchHashtag({ name }).then(() => {
        this.loading = false;
        const hashtagData = this.hashtags.find((hashtag) => hashtag.name === name) || {};
        this.currentHashtag = hashtagData.hashtag || {};
      });
    },
    randomImage() {
      const sizes = ['1080/1080', '1080/1080', '1080/1350', '1080/1920'];
      const seeds = ['sfqsdhf', 'dskjfh', 'dskfhsdjkfg', 'dsfjhdslk'];
      const randomSize = sizes[Math.floor(Math.random() * sizes.length)];
      const randomSeed = seeds[Math.floor(Math.random() * sizes.length)];
      return `https://picsum.photos/seed/${randomSeed}/${randomSize}`;
    },
    fetchHashtags() {
      if (!this.isFbChannelActive) return;
      this.loading = true;
      this.fetchAllHashtags().then(() => {
        this.loading = false;
        if (this.hashtags[0]) this.fetchOneHashtag(this.hashtags[0].name);
      });
    },
    setEngagementFilter(range) {
      this.engagementRange = range;
    },
    setPostEngagementFilter(range) {
      this.postEngagementRange = range;
    },
    checkRows(event) {
      this.checkedRows = [...event];
    },
    downloadMedias({ medias }) {
      this.mediasDownloading = true;
      const snackbar = this.$buefy.snackbar.open({
        message: 'Your medias are downloading',
        type: 'is-success',
        position: 'is-bottom-right',
        indefinite: true,
      });
      this.dlMedias({ medias, folderName: 'test' }).then(() => {
        this.mediasDownloading = false;
        snackbar.close();
      }).catch(() => {
        snackbar.close();
        this.mediasDownloading = false;
      });
    },
    addContactsIntoCampaign(event) {
      const { campaignId } = event;
      this.contactAddedToCampaign = 0;
      this.contactAlreadyInCampaign = 0;

      const activities = this.checkedRows.reduce((acc, row) => {
        const contactAlreadyInThisCampaign = row.activities
          .find((a) => a.campaignId === campaignId);

        if (contactAlreadyInThisCampaign) { this.contactAlreadyInCampaign += 1; return acc; }
        this.contactAddedToCampaign += 1;
        acc.push({
          pk: row.userPk,
          username: row.username,
          status: 'unscheduled',
          channel: this.currentChannelId,
          campaignId,
          campaign: campaignId,
        });
        return acc;
      }, []);

      // Create activities
      this.setUnscheduled({
        campaignId,
        channelId: this.currentChannelId,
        activities,
      })
        .then(() => {
          this.checkedRows = [];
          this.isEndedModalActive = true;
        });
    },
    setRangeFilter(event) {
      const { name, rangeValues } = event;
      if (name === 'followerRange') this.followerRange = rangeValues;
      if (name === 'likeRange') this.likeRange = rangeValues;
      if (name === 'commentRange') this.commentRange = rangeValues;
      if (name === 'postRange') this.postRange = rangeValues;
    },
    setCheckFilter(event) {
      const { name, checked } = event;
      if (name === 'contactGroup') this.currentLabelCheckList = checked;
      if (name === 'language') this.currentLanguageCheckList = checked;
    },
    showHashtag(username) {
      this.influencerHashtagMedia = this.currentHashtag.medias.find(
        (hashtag) => hashtag.username === username,
      );
      if (this.influencerHashtagMedia) this.showInfluencerHashtagMedia = true;
    },
  },
};
</script>
