<template>
  <div id="UbuWorkspaceKeywords">
    <div
      v-for="(keyword, index) in keywords"
      :key="`keywords-${index}`"
      class="UbuWorkspaceKeywords_keyword"
    >
      <span class="ubu-text2">{{ keyword }}</span>
      <UbuIcon
        class="UbuWorkspaceKeywords_keywordCross"
        name="cross"
        clickable
        @click="removeKeyword(index)"
      />
    </div>
    <b-modal
      v-model="isModalActive"
      :width="640"
      scroll="keep"
    >
      <UbuWorkspaceModalAddKeyword
        :keywords="keywords"
        @close="isModalActive = false"
        @addKeywords="addKeywords($event)"
      />
    </b-modal>
    <UbuButton
      simple
      :icon="{ name: 'add', outline: true }"
      @click="isModalActive = true"
    />
  </div>
</template>

<script>
export default {
  name: 'UbuWorkspaceKeywords',
  props: {
    keywords: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isModalActive: false,
    };
  },
  methods: {
    addKeywords(event) {
      this.$emit('addKeywords', event);
      this.isModalActive = false;
    },
    removeKeyword(indexKeyword) {
      const updatedKeywords = [...this.keywords];
      updatedKeywords.splice(indexKeyword, 1);
      this.addKeywords(updatedKeywords);
    },
  },
};
</script>
