<template>
  <div
    id="app"
    v-shortkey.once="['ctrl', 't']"
    @shortkey="switchTheme()"
  >
    <router-view
      v-if="isFetched"
      @openModalAddLink="openModalAddLink($event)"
    />

    <b-modal
      v-model="showModal"
      v-bind="modal && modal.modalProps"
      :width="modal && modal.modalProps && modal.modalProps.width || 600"
      :component="modal.cmp"
      :props="modal.props"
    />

    <b-modal
      v-model="isCardModalOpen"
      :width="640"
      scroll="keep"
    >
      <UbuContactPanelModalAddShopifyLink
        :active-campaign-list="activeCampaigns"
        :shopify-shop-list="getterShopifyShop"
        :contact-id="currentContactId"
        :is-loading="addLinkLoading"
        :utm-parameter-list="getterShopifyUtmList"
        @addLink="addLinkToContact($event)"
        @close="closeModalShopifyLink()"
        @refreshDiscountCode="refreshDiscountCode($event)"
        @fetchUtm="fetchUtm($event)"
      />
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import { initializeSocket } from './websocket';
import ThemeMixin from './addons/$mixins/ThemeMixin';

const {
  VUE_APP_BUILD_DATE: appBuildDate,
  VUE_APP_VERSION: appVersion,
} = process.env;

console.log(`App version ${appVersion} builded at ${moment(appBuildDate).format('YYYY-MM-DD HH:mm:ss')}`);

export default {
  name: 'App',
  mixins: [ThemeMixin],
  data() {
    return {
      isLoading: false,
      isCardModalOpen: false,
      currentContactId: null,
      addLinkLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      modal: 'Modal/getterModal',
      authenticate: 'Authenticate/getterAuthenticate',
      getterInviteToken: 'Authenticate/getterInviteToken',
      getterShopifyShop: 'TheContactPanel/getterShopifyShop',
      getterCampaigns: 'TheContactPanel/getterCampaigns',
      getterShopifyUtmList: 'TheWorkspace/getterShopifyUtmList',
    }),
    activeCampaigns() {
      return this.getterCampaigns.filter(({ ended }) => !ended);
    },
    allDiscountCode() {
      return this.getterShopifyShop.reduce((acc, row) => {
        const { discountCode } = row;
        if (discountCode) acc.push(...discountCode);

        return acc;
      }, []);
    },
    showModal: {
      get() { return !!this.modal && !!this.modal.cmp; },
      set(show) { if (!show) { this.setModal({}); } },
    },
    isFetched() {
      if (this.authenticate && this.authenticate.email) {
        window.intercomSettings = {
          app_id: 'vkrekt62',
          name: this.authenticate.firstName || this.authenticate.email || this.getRndString(20),
          email: this.authenticate.email || this.getRndString(20),
          user_id: this.authenticate.ubuUserId,
        };
        window.launchIntercom();
      }
      if (this.$route.meta.auth) {
        if (this.isLoading) {
          if (this.authenticate && this.authenticate.email) {
            return true;
          }
          this.redirect();
          return true;
        }
        if (this.authenticate && this.authenticate.email) {
          return true;
        }
        return false;
      }
      return true;
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = (to.meta && to.meta.title) || to.name || 'Ubu';
      },
    },
  },
  beforeMount() {
    this.bootstrap()
      .then((response) => {
        this.isLoading = true;
        if (response) {
          const socket = initializeSocket();
          this.UP_socketInstance(socket);
        }
        // window.intercomSettings = {
        //   app_id: 'vkrekt62',
        //   name: this.authenticate.firstName || this.authenticate.email || this.getRndString(20),
        //   email: this.authenticate.email || this.getRndString(20),
        //   user_id: this.authenticate.ubuUserId,
        // };
        // window.launchIntercom();
        if (response) return this.fetchAll();
        // if (response && this.$route.meta.requireFacebookData) return this.fetchComments();
        return undefined;
      })
      .then(() => {
        this.isLoading = false;
        if (this.$route.query.loginFacebookSuccess) {
          this.$buefy.snackbar.open({
            message: 'Your account is correctly connected to facebook',
            type: 'is-success',
            position: 'is-top',
            duration: 5000,
          });
          this.$store.dispatch('Media/fetchMediasByChannel');
          this.$store.dispatch('Media/fetchFbOrganic');
        }
      })
      .catch((e) => {
        console.error('Error in APP Bootstraping catch ==> ', e);
        this.isLoading = false;
        this.$buefy.snackbar.open({
          message: e.message,
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 5000,
        });
      });
  },
  methods: {
    ...mapActions({
      bootstrap: 'Sequence/bootstrap',
      UP_socketInstance: 'Socket/UP_socketInstance',
      fetchInitial: 'Sequence/fetchInitial',
      fetchAll: 'Sequence/fetchAll',
      fetchComments: 'Sequence/fetchComments',
      setModal: 'Modal/setModal',
      assignDiscountCodeToContact: 'Shopify/DiscountCode/assignDiscountCodeToContact',
      assignReferralLinkToContact: 'Shopify/ReferralLink/assignReferralLinkToContact',
      _refreshDiscountCode: 'Shopify/DiscountCode/refreshDiscountCode',
      reportUtm: 'TheWorkspace/reportUtm',

    }),
    fetchUtm({ shopifyShopSsid }) {
      this.reportUtm(shopifyShopSsid);
    },
    redirect() {
      this.$router.replace('/auth/login');
    },
    getRndString(len) {
      const result = [];
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      for (let i = 0; i < len; i += 1) {
        result.push(characters.charAt(Math.floor(Math.random()
          * charactersLength)));
      }
      return result.join('');
    },
    openModalAddLink(event) {
      this.isCardModalOpen = !this.isCardModalOpen;
      this.currentContactId = event;
    },
    closeModalShopifyLink() {
      this.isCardModalOpen = false;
      this.currentContactId = null;
    },
    addLinkToContact(event) {
      this.addLinkLoading = true;
      const {
        type, shopifyShopSsid, campaignId, code,
      } = event;

      const newLink = {
        shopifyShopSsid,
        contactpk: this.currentContactId,
        campaignId,
      };

      // check to update this shit (dont send campaignId key if empty, optionnal key)
      if (!campaignId) { delete newLink.campaignId; }

      if (type === 'referral') {
        this.assignReferralLinkToContact({
          ...newLink,
          referralCode: code,
          createdOn: this.$moment(),
        })
          .then(() => {
            this.addLinkLoading = false;
            this.isCardModalOpen = false;
          });
      }
      if (type === 'discount') {
        const discountCodeToAssign = this.allDiscountCode
          .find(({ discountCodeDcid }) => discountCodeDcid === code);

        this.assignDiscountCodeToContact({
          ...newLink,
          discountCodeId: code,
          discountCode: discountCodeToAssign,
        })
          .then(() => {
            this.addLinkLoading = false;
            this.isCardModalOpen = false;
          });
      }
    },
    refreshDiscountCode(event) {
      const { shopifyShopSsid } = event;
      this._refreshDiscountCode({ shopifyShopSsid });
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/colors';
@import '@/styles/common';
@import '@/styles/fonts';
@import '@/styles/ellipsis';
@import '@/styles/forms';
@import '@/styles/utils';
@import '@/styles/ui';
@import '@/styles/variables';
@import '@/assets/ubu-icons/style';
@import '@/styles/buefy';

html {
  background-color: var(--soapstone) !important;
}

html,
body,
#app {
  height: 100vh;
  overflow: auto;
  width: 100%;
  padding: 0;
  margin: 0;
  color: var(--cloudburst);
}

#app {
  overflow-y: auto !important;
  background-color: var(--soapstone);
}
::-webkit-scrollbar {
    /* width: 5px; */
    width: 5px;
    margin-left: -5px;
    height: 8px;
}
::-webkit-scrollbar-track {
    background: transparent;
    /* width: 5px; */
    width: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #4e567426;
  border-radius: 50px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #4e5674;
}
</style>
