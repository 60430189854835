<template>
  <div id="UbuCommonMentionsCarouselListItem">
    <b-image
      class="UbuMentionsCarouselListItem_image"
      :src="item.thumbnailUrl"
      ratio="3by4"
      responsive
      lazy
    />
    <div
      v-if="item.index !== item.active"
      class="UbuMentionsCarouselListItem_overlay"
    />
    <div class="UbuMentionsCarouselListItem_infos">
      <span class="ubu-text1">@{{ item.username }}</span>
      <span class="ubu-text2">{{ item.date }}</span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'UbuCommonMentionsCarouselListItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>
