<template>
  <a
    :href="`https://instagram.com/${username}`"
    target="_blank"
  >@{{ username }}&nbsp;<UbuIcon name="externalLink" />&nbsp;</a>
</template>

<script>
export default {
  name: 'UbuInstaName',
  props: {
    username: {
      type: String,
      default: '',
    },
  },
};
</script>
