/* eslint-disable max-len */
import moment from 'moment';
import { SnackbarProgrammatic as Snackbar } from 'buefy';

export default {
  namespaced: true,

  state: {
    bootstrap: false,
    fetchInitial: false,
    fetchCampaigns: false,
    fetchLists: false,
    fetchMedias: false,
    fetchAds: null,
    fetchInbox: false,
    fetchShopify: false,
    fetchThreads: false,
    fetchThreadsDb: false,
    fetchThreadsDBLabeled: false,
    fetchThreadsDBTaggued: false,
    fetchThreadsDBClosed: false,
    fetchContacts: false,
    fetchActions: false,
    fetchDone: false,
    fbConnexionNeeded: false,
    igConnexionNeeded: false,
  },

  getters: {
    getterFetchInitial: (state) => state.fetchInitial,
    getterFetchCampaigns: (state) => state.fetchCampaigns,
    getterFetchLists: (state) => state.fetchLists,
    getterFetchMedias: (state) => state.fetchMedias,
    getterFetchAds: (state) => state.fetchAds,
    getterFetchInbox: (state) => state.fetchInbox,
    getterFetchShopify: (state) => state.fetchShopify,
    getterFetchThreads: (state) => state.fetchThreads,
    getterFetchThreadsDb: (state) => state.fetchThreadsDb,
    getterFetchThreadsDBLabeled: (state) => state.fetchThreadsDBLabeled,
    getterFetchThreadsDBClosed: (state) => state.fetchThreadsDBClosed,
    getterFetchContacts: (state) => state.fetchContacts,
    getterFetchActions: (state) => state.fetchActions,
    getterFetchDone: (state) => state.fetchDone,
    getterIsFbConnectionNeeded: (state) => state.fbConnexionNeeded,
    getterIsIgConnectionNeeded: (state) => state.igConnexionNeeded,
  },

  mutations: {
    SET_BOOTSTRAP(state, payload) { state.bootstrap = payload; },
    SET_INITIAL(state, isLoaded) { state.fetchInitial = isLoaded; },
    SET_CAMPAIGNS(state, isLoaded) { state.fetchCampaigns = isLoaded; },
    SET_LISTS(state, isLoaded) { state.fetchLists = isLoaded; },
    SET_MEDIAS(state, isLoaded) { state.fetchMedias = isLoaded; },
    SET_ADS(state, isLoaded) { state.fetchAds = isLoaded; },
    SET_INBOX(state, isLoaded) { state.fetchInbox = isLoaded; },
    SET_CONTACTS(state, isLoaded) { state.fetchContacts = isLoaded; },
    SET_SHOPIFY(state, isLoaded) { state.fetchShopify = isLoaded; },
    SET_ACTIONS(state, isLoaded) { state.fetchActions = isLoaded; },
    SET_DONE(state, isLoaded) { state.fetchDone = isLoaded; },
    SET_FETCH_THREAD(state, isLoaded) { state.fetchThreads = isLoaded; },
    SET_FETCH_THREAD_DB(state, isLoaded) { state.fetchThreadsDb = isLoaded; },
    SET_FETCH_THREAD_DB_LABELED(state, isLoaded) { state.fetchThreadsDBLabeled = isLoaded; },
    SET_FETCH_THREAD_DB_TAGGUED(state, isLoaded) { state.fetchThreadsDBTaggued = isLoaded; },
    SET_FETCH_THREAD_DB_CLOSED(state, isLoaded) { state.fetchThreadsDBClosed = isLoaded; },
    SET_FB_CONNEXION_NEEDED(state, isLoaded) { state.fbConnexionNeeded = isLoaded; },
    SET_IG_CONNEXION_NEEDED(state, isLoaded) { state.igConnexionNeeded = isLoaded; },
    RESET_STORES(state) {
      state.bootstrap = false;
      state.fetchInitial = false;
      state.fetchCampaigns = false;
      state.fetchLists = false;
      state.fetchMedias = false;
      state.fetchAds = false;
      state.fetchInbox = false;
      state.fetchShopify = false;
      state.fetchThreads = false;
      state.fetchThreadsDb = false;
      state.fetchThreadsDBLabeled = false;
      state.fetchThreadsDBClosed = false;
      state.fetchContacts = false;
      state.fetchActions = false;
      state.fetchDone = false;
      state.fbConnexionNeeded = false;
      state.igConnexionNeeded = false;
    },
  },

  actions: {
    bootstrap() {
      console.log(`----> bootstrap <---- ${moment().format('HH:mm:ss')}`);
      return Promise.resolve()
        .then(() => this.dispatch('Authenticate/fetchAuthenticate'));
      // .then(() => this.dispatch('Channel/fetchChannels'));
    },
    fetchAll() {
      console.log(`----> fetchAll <---- ${moment().format('HH:mm:ss')}`);
      if (process.env.VUE_APP_FACEBOOK_OFFICIEL_TEST === 'true') {
        return this.dispatch('Sequence/fetchOfficial');
      }

      return Promise.resolve()
        .then(() => this.dispatch('Sequence/fetchInitial'))
        .then(() => {
          this.dispatch('Sequence/fetchComments');
          return Promise.all([
            this.dispatch('Sequence/fetchInbox'),
            this.dispatch('Sequence/fetchCampaigns'),
            // this.dispatch('Sequence/fetchContacts'),
            // this.dispatch('Sequence/fetchComments'),
            this.dispatch('Sequence/fetchLists'),
            // this.dispatch('Sequence/fetchShopify'),
          ]);
        })
        .then(() => this.dispatch('Sequence/fetchContacts'))
        .then(() => this.dispatch('Sequence/fetchDone'))
        .then(() => this.dispatch('Sequence/fetchShopify'));
    },
    fetchInitial({ commit }) {
      console.log(`----> fetchInitial <---- ${moment().format('HH:mm:ss')}`);
      return Promise.resolve()
        .then(() => this.dispatch('Channel/fetchChannels'))
        .then(() => Promise.all([
          this.dispatch('Channel/isFacebookConnected'),
          this.dispatch('CustomTag/fetchCustomsByChannel'),
          // .then(() => this.dispatch('Channel/isDriveActive'))
          // this.dispatch('Channel/driveAuthUrl'),
        ]))
        // .then(() => this.dispatch('Shopify/sequenceFetchingShopify'))

        .then(() => commit('SET_INITIAL', true));
    },
    fetchOfficial({ commit }) {
      console.log(`----> fetchOfficial <---- ${moment().format('HH:mm:ss')}`);
      return Promise.resolve()
        .then(() => this.dispatch('Channel/fetchFbChannels'))
        .then(() => this.dispatch('Channel/isFacebookConnected'))
        .then(() => this.dispatch('Channel/driveAuthUrl'))
        .then(() => this.dispatch('Sequence/fetchComments'))
        .then(() => this.dispatch('Sequence/fetchDone'))
        .then(() => commit('SET_INITIAL', true));
    },
    fetchCampaigns({ commit }) {
      console.log(`----> fetchCampaigns <---- ${moment().format('HH:mm:ss')}`);
      return Promise.resolve()
        .then(() => this.dispatch('Campaign/fetchCampaigns'))
        .then(() => this.dispatch('Activity/fetchActivitiesByCampaignsByChannel'))
        .then(() => commit('SET_CAMPAIGNS', true));
    },
    fetchLists({ commit }) {
      console.log(`----> fetchLists <---- ${moment().format('HH:mm:ss')}`);
      return Promise.resolve()
        .then(() => this.dispatch('List/fetchLists'))
        .then(() => this.dispatch('List/fetchCustomLists'))
        .then(() => commit('SET_LISTS', true));
    },
    fetchComments({ commit }) {
      console.log(`----> fetchMedias <---- ${moment().format('HH:mm:ss')}`);

      // commit('SET_ADS', false);
      // this.dispatch('Media/fetchAllAds')
      //   .then(({ success, result, reason }) => {
      //     if (success) {
      //       commit('SET_ADS', true);
      //     } else {
      //       commit('SET_ADS', reason);
      //     }
      //   })
      //   .catch((error) => {
      //     console.log('Error while fetching ads', error);
      //     commit('SET_ADS', false);
      //   });
      // this.dispatch('Media/fetchDynamics')
      //   .then((response) => {
      //     console.log('Fetching dynamics', response)
      //   });
      commit('SET_FB_CONNEXION_NEEDED', null);
      commit('SET_IG_CONNEXION_NEEDED', null);
      commit('SET_ADS', null);
      const ads = Promise.allSettled([
        
        this.dispatch('Media/fetchAllAds'),
        // this.dispatch('Media/fetchDynamics'),
        // this.dispatch('Media/fetchMediasFbDBByChannel'),
        // this.dispatch('Media/fetchMediasDBByChannel'),
      ])
        .then(([{ value: adsResponse }]) => {
          if (adsResponse.success === false) {
            commit('SET_ADS', false);
          } else {
            commit('SET_ADS', true);
          }
        })
        .catch(() => commit('SET_ADS', false));

      return Promise.allSettled([
        this.dispatch('Media/fetchMediasByChannel'),
        this.dispatch('Media/fetchFbOrganic'),
        this.dispatch('Media/fetchMentionnedMediaDbByChannel'),
      ])
        .then(([{ value: ig }, { value: fb }]) => {
          if (fb === 'connexion needed') commit('SET_FB_CONNEXION_NEEDED', true);
          else commit('SET_FB_CONNEXION_NEEDED', false);
          if (ig === 'connexion needed') commit('SET_IG_CONNEXION_NEEDED', true);
          else commit('SET_IG_CONNEXION_NEEDED', false);
        })
        .then(() => commit('SET_MEDIAS', true))
        .catch((e) => {
          commit('SET_FB_CONNEXION_NEEDED', false);
          commit('SET_IG_CONNEXION_NEEDED', false);
          commit('SET_MEDIAS', false);
        });
      // .then(() => commit('SET_DONE', true))
      // .then(() => this.dispatch('Sequence/fetchDone'));
    },
    hardFetchComments({ commit }) {
      const loader = '<img class="rotating" src="/img/loader.svg" />';
      const snack = Snackbar.open({
        message: `<div class="inlineLoader">${loader} We are fetching your data from Facebook & Instagram. It might take some minutes.</div>`,
        type: 'is-success',
        position: 'is-bottom-right',
        indefinite: true,
        actionText: null,
      });
      return Promise.all([
        this.dispatch('Media/fetchMediasByChannel').catch(() => Promise.resolve),
        this.dispatch('Media/fetchFbOrganic').catch(() => Promise.resolve),
        this.dispatch('Media/fetchAllAds').catch(() => Promise.resolve),
      ]).then(() => {
        snack.close();
        commit('SET_MEDIAS', true);
      }).catch(() => {
        snack.close();
      });
    },

    fetchInbox({ commit }) {
      console.log(`----> fetchInbox <---- ${moment().format('HH:mm:ss')}`);
      return this.dispatch('Thread/fetchThreadsDbByChannel')
        .then(() => commit('SET_INBOX', true))
        .then(() => {
          let pendings = Promise.resolve();
          if (process.env.VUE_APP_USE_THREADS_AND_PENDING === 'true') {
            pendings = Promise.all([
              this.dispatch('Thread/fetchThreadsByChannel'),
              this.dispatch('Thread/fetchThreadsPendingByChannel'),
            ]).then(() => {
              commit('SET_FETCH_THREAD', true);
            });
          }
          const closed = this.dispatch('Thread/fetchThreadsDbClosedByChannel')
            .then(() => commit('SET_FETCH_THREAD_DB_CLOSED'));
          const labeled = this.dispatch('Thread/fetchThreadsDBLabeledByChannel')
            .then(() => commit('SET_FETCH_THREAD_DB_LABELED', true));
          const taggued = this.dispatch('Thread/fetchThreadsDBTagguedByChannel');
          return Promise.all([closed, labeled, taggued, pendings]);
        });
    },

    fetchContacts({ commit }) {
      console.log(`----> fetchContacts <---- ${moment().format('HH:mm:ss')}`);
      const p = Promise.resolve()
        // .then(() => this.dispatch('Contact/fetchThreadsContactsDbByChannel'))
        .then(() => this.dispatch('Contact/fetchMissingActivitiesContacts'))
        .then(() => commit('SET_CONTACTS', true));
      this.dispatch('Thread/fetchThreadsInfluenceDbByChannel');
      return p;
    },
    fetchShopify({ commit }) {
      console.log(`----> fetchShopify <---- ${moment().format('HH:mm:ss')}`);
      return Promise.resolve()
        .then(() => this.dispatch('Shopify/sequenceFetchingShopify'))
        .then(() => commit('SET_SHOPIFY', true));
    },
    fetchActions({ commit }) {
      console.log(`----> fetchActions <---- ${moment().format('HH:mm:ss')}`);
      return Promise.resolve()
        .then(() => this.dispatch('TagPost/fetchTagsPostByChannel'))
        .then(() => this.dispatch('Mention/fetchMentionsByChannel'))
        // .then(() => this.dispatch('Shopify/sequenceFetchingShopify'))
        .then(() => commit('SET_ACTIONS', true));
    },
    fetchDone({ commit }) {
      console.log(`----> fetchDone <---- ${moment().format('HH:mm:ss')}`);
      this.dispatch('Channel/driveAuthUrl')
        .then(this.dispatch('Channel/isDriveActive'));
      commit('SET_DONE', true);
    },
    cleanAll({ commit }) {
      commit('Sequence/RESET_STORES', null, { root: true });
      commit('Activity/RESET_STORES', null, { root: true });
      commit('Campaign/RESET_STORES', null, { root: true });
      commit('List/RESET_STORES', null, { root: true });
      commit('Contact/RESET_STORES', null, { root: true });
      commit('ContactGroup/RESET_STORES', null, { root: true });
      commit('Comment/RESET_STORES', null, { root: true });
      commit('CustomTag/RESET_STORES', null, { root: true });
      commit('Gossip/RESET_STORES', null, { root: true });
      commit('Media/RESET_STORES', null, { root: true });
      commit('Mention/RESET_STORES', null, { root: true });
      commit('Message/RESET_STORES', null, { root: true });
      commit('Note/RESET_STORES', null, { root: true });
      commit('TagPost/RESET_STORES', null, { root: true });
      commit('Template/RESET_STORES', null, { root: true });
      commit('Thread/RESET_STORES', null, { root: true });
      commit('ThreadGroup/RESET_STORES', null, { root: true });
      commit('UbuUser/RESET_STORES', null, { root: true });
      commit('Channel/RESET_STORES', null, { root: true });

      commit('TheComment/RESET_STORES', null, { root: true });
      commit('TheWorkspace/RESET_STORES', null, { root: true });
    },
  },
};
