<template>
  <UbuCommonMessageCard
    :is-me="isMe"
    :date="date"
    :username="username"
    is-note
  >
    <template v-slot:content>
      <div>
        {{ text }}
      </div>
    </template>
  </UbuCommonMessageCard>
</template>

<script>
export default {
  name: 'UbuCommonNote',
  props: {
    text: {
      type: String,
      required: true,
    },
    username: {
      type: String,
      default: '',
    },
    isMe: {
      type: Boolean,
      default: false,
    },
    date: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
// .UbuNote_wrapper {
//   background-color: var(--note);
//   border-radius: 24px;
//   padding: 12px 16px;
//   display: inline-block;
//   text-align: center;
//   justify-content: center;
//   align-self: center;
//   width: auto;
//   .UbuNote_text{
//     display: flex;
//     justify-content: center;
//     color: var(--cola);
//   }
//   .UbuNote_timestamp {
//       color: var(--cola);
//   }
// }
</style>
