<template>
  <div id="UbuStatisticCard">
    <b-tooltip
      v-if="tooltipLabel"
      class="infos"
      :label="tooltipLabel"
      size="is-large"
      position="is-bottom"
      multilined
    >
      <UbuIcon name="info" />
    </b-tooltip>
    <div class="UbuStatisticCard_title ubu-subtext1">{{ title }}</div>
    <div
      v-if="!isLoading"
      class="UbuStatisticCard_stat"
    >
      {{ content }}
    </div>
    <b-skeleton
      :active="isLoading"
      height="36px"
      width="40%"
    />
  </div>
</template>

<script>
export default {
  name: 'UbuStatisticCard',
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
    tooltipLabel: {
      type: String,
      default: null,
    },
  },
};
</script>
