<template>
  <div id="TheAccount">
    <TheSidebar :menu-items="ubuMenuItems" />
    <section class="TheAccountWrapper">
      <div class="TheAccount_title fbetween">
        <h1 class="ubu-header1">Account</h1>
        <UbuButton
          secondary
          center
          @click="disconnect"
        >
          Logout
        </UbuButton>
      </div>
      <div class="TheAccount_row">
        <UbuWorkspaceUserTemplate
          v-if="localMe"
          :user="localMe"
        />

        <div class="flex">
          <UbuButton
            class="a-self-center mr-1"
            @click="isCardModalEditNameActive = !isCardModalEditNameActive"
          >
            Edit name
          </UbuButton>
          <UbuButton
            class="a-self-center mr-1"
            @click="isCardModalChangeEmailActive = !isCardModalChangeEmailActive"
          >
            Change Email
          </UbuButton>
          <UbuButton
            class="a-self-center ml-1"
            @click="isCardModalEditPasswordActive = !isCardModalEditPasswordActive"
          >
            Change password
          </UbuButton>

          <b-modal
            v-model="isCardModalEditNameActive"
            :width="640"
            scroll="keep"
          >
            <UbuWorkspaceModalEditName
              :is-card-modal-active="isCardModalEditNameActive"
              :user="myName"
              @close="isCardModalEditNameActive = false"
              @editFromModal="editNameFromModal($event)"
            />
          </b-modal>
          <b-modal
            v-model="isCardModalChangeEmailActive"
            :width="640"
            scroll="keep"
          >
            <UbuWorkspaceModalConfirmation
              :is-card-modal-active="isCardModalChangeEmailActive"
              title="Change email"
              first-placeholder="Email..."
              scnd-placeholder="Confirm email..."
              @confirmAndEgual="editEmailModal($event)"
            />
          </b-modal>
          <b-modal
            v-model="isCardModalEditPasswordActive"
            :width="640"
            scroll="keep"
          >
            <UbuWorkspaceModalEditPassword
              @close="isCardModalEditPasswordActive = false"
              @editFromModal="editPasswordFromModal($event)"
            />
          </b-modal>
          <!-- <b-modal
            v-model="isCardModalInstaloginActive"
            scroll="keep"
          >
            <div class="modal_wrapper" v-html="renderInstaloginHtml" />
          </b-modal> -->
        </div>
      </div>
      <div class="TheAccount_divider" />
      <div class="TheAccount_title fbetween mt-6">
        <h1 class="ubu-header1">My Channels</h1>
        <div class="flex">
          <UbuButton
            class="mr-1"
            :icon="{ name: 'add', filled: true }"
            :href="instaLoginRoute"
          >
            Add / update channel
          </UbuButton>
          <!-- <UbuButton
            class="mr-1"
            :icon="{ name: 'add', filled: true }"
            href="/add-facebook-channel.html"
          >
            Add Facebook channel
          </UbuButton> -->
        </div>
      </div>
      <div class="TheAccount_list">
        <div
          v-for="channel in channels"
          :key="channel.channelId"
        >
          <div class="TheAccount_row">
            <UbuWorkspaceAccountChannelTemplate
              :username="channel.username"
            />

            <div class="flex">
              <UbuButton
                class="a-self-center"
                color="2"
                @click="disconnectChannel(channel)"
              >
                Disconnect
              </UbuButton>
            </div>
          </div>
          <div class="TheAccount_divider" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
// import { getUbuUserInitials } from '@/addons/$utils/ubuUsers';
import routes from '../../../conf/routes';

export default {
  name: 'TheAccount',
  data() {
    return {
      ubuMenuItems: [
        {
          text: 'What\'s new',
          icon: 'home',
          href: '/home',
          disabled: process.env.VUE_APP_FACEBOOK_OFFICIEL_TEST === 'true',
        },
        {
          text: 'Workspace',
          icon: 'settings',
          href: '/workspace',
        },
        {
          text: 'Account',
          icon: 'account',
          href: '/account',
        },
      ],
      isCardModalEditNameActive: false,
      isCardModalChangeEmailActive: false,
      isCardModalEditPasswordActive: false,
      // isCardModalInstaloginActive: false,
      newUser: {
        firstName: '',
        lastName: '',
      },
      newEmail: {
        email: '',
        email_confirm: '',
      },
      routes,
      // renderInstaloginHtml: null,
    };
  },
  computed: {
    ...mapGetters({
      channels: 'Channel/getterChannels',
      me: 'Authenticate/getterCurrentUbuUser',
    }),
    instaLoginRoute() {
      if (process.env.VUE_APP_FACEBOOK_OFFICIEL_TEST === 'true') {
        return '/workspace/connection';
      }
      return `${this.routes.api.url}/api/instalogin`;
    },
    myName() {
      if (this.me) {
        const { firstName, lastName } = this.me;
        if (!firstName && !lastName) return { firstName: '', lastName: '' };
        return { firstName, lastName };
      }
      return { firstname: null, lastname: null };
    },
    localMe() {
      return ({
        ...this.me,
      });
    },
  },
  methods: {
    ...mapActions({
      addChannel: 'TheWorkspace/addChannel',
      removeChannel: 'TheWorkspace/removeChannel',
      logout: 'Authenticate/logout',
      editUser: 'TheWorkspace/editUser',
      editPassword: 'Authenticate/editPassword',
      // getInstaloginHtml: 'TheWorkspace/getInstaloginHtml',
    }),

    editEmailModal($event) {
      const updatedUser = {
        ...this.me,
        data: '',
        email: $event,
      };
      this.editUser({ ...updatedUser })
        .then(() => {
          this.isCardModalChangeEmailActive = false;
        });
    },

    editNameFromModal(event) {
      const updatedUser = {
        ...this.me,
        data: '',
        firstName: event.firstName,
        lastName: event.lastName,
      };
      this.editUser({ ...updatedUser })
        .then(() => {
          this.isCardModalEditNameActive = false;
          this.newUser = { firstName: '', lastName: '' };
        });
    },

    editPasswordFromModal(password) {
      console.log('🚀 ~ file: TheAccount.vue ~ line 222 ~ editPasswordFromModal ~ password', password);
      this.editPassword(password)
        .then(() => {
          this.isCardModalEditPasswordActive = false;
        }).then(() => {
          this.isLoading = false;
          this.$buefy.toast.open({
            duration: 5000,
            message: 'Password successfully changed !',
            type: 'is-success',
          });
        })
        .catch((error) => {
          this.isLoading = false;
          this.$buefy.toast.open({
            duration: 5000,
            message: error.message,
            type: 'is-danger',
          });
        });
    },

    disconnect() {
      this.logout()
        .then(() => {
          this.$router.replace('/auth/login');
        });
    },

    disconnectChannel(channel) {
      const { channelId } = channel;

      this.removeChannel(channelId);
    },
  },
};
</script>

<style lang="scss">
@import 'Workspace';
</style>
