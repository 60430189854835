/* eslint-disable max-len */
import moment from 'moment';
import internals from '../../dataStores/stores/internals';

import { getThreadName } from '../../$utils/threadUtilities';
import { messageFormater } from '../../$utils/formate';

import { arrayMapper } from '../../$utils/prototypes';
import CommonProvider from '../providers/common.provider';

export default {
  namespaced: true,

  state: {},

  getters: {},

  actions: {
    closeThread({ commit }, { thread }) {
      console.log('🚀 ~ file: TheThreadPanel.store.js ~ line 20 ~ closeThread ~ thread', thread);
      const { threadId, channelId } = thread;
      const threadName = getThreadName(thread);
      return CommonProvider
        .setThreadClosed({ payload: { channelId, threadId, closed: true } })
        .then(({ response, error }) => {
          if (response) {
            commit('Thread/CLOSE_THREAD', { threadId, channelId, isClosed: true }, { root: true });
            return { threadName };
          }
          return { error, threadName };
        }).catch((error) => ({ error, threadName }));
    },

    readAndClose({ commit }, { thread }) {
      const { threadId, channelId } = thread;
      const threadName = getThreadName(thread);

      return Promise.resolve()
        .then(() => {
          commit('Thread/READ_AND_CLOSE_THREAD', {
            threadId,
            channelId,
            isClosed: true,
            timestamp: moment().format(),
          }, { root: true });
          return { threadName };
        })
        .then(() => CommonProvider.setThreadReadAndClose({ payload: { channelId, threadId } }))
        .catch((error) => {
          console.log('🚀 ~ file: TheThreadPanel.store.js ~ line 44 ~ readAndClose ~ error', error);
          return ({ error });
        });
    },

    doneThread({ commit }, { thread, threadGroup }) {
      const { threadId, channelId } = thread;
      const threadName = getThreadName(thread);
      const { threadGroupId, title } = threadGroup;
      return CommonProvider.setThreadDone({
        payload: {
          channelId,
          threadGroupId,
          threadId,
        },
      })
        .then((response) => {
          const timestamp = (response && response.payload) ? response.payload.timestamp : moment().format();
          commit('Thread/DONE_THREAD', { threadId, threadGroupId, timestamp }, { root: true });
          return ({ threadName, title });
        })
        .catch((error) => ({ threadName, title, error }));
    },

    openThread({ commit }, { thread }) {
      const { threadId, channelId } = thread;
      const threadName = getThreadName(thread);

      return CommonProvider
        .setThreadClosed({ payload: { channelId, threadId, closed: false } })
        .then(({ response, error }) => {
          console.log('Resp', response, error);
          if (response) {
            commit('Thread/CLOSE_THREAD', { threadId, channelId, isClosed: false }, { root: true });
            return { threadName };
          }
          return { threadName, error };
        })
        .catch((error) => ({ threadName, error }));
    },

    syncThread({ commit }, {
      channelId,
      threadId,
      messages,
      contactId,
    }) {
      const [lastMessage] = messages.sort((a, b) => Number(a.timestamp) - Number(b.timestamp));
      return CommonProvider
        .syncItemsWithLastPermanent({
          channelId,
          threadId,
          itemId: lastMessage && lastMessage.item_id ? lastMessage.item_id : null,
          count: 10,
        })
        .then(({ response, error }) => {
          if (error) {
            return;
          }
          const items = response.map((item) => ({
            ...item,
            // ...tryParseJson(item.item),
            messageId: item.item_id,
            contactId,
            threadId,
          }));

          const mappedItems = arrayMapper(items, 'messageId');
          commit('Message/SET_MESSAGES', mappedItems, { root: true });
          commit('Thread/ADD_MESSAGES_TO_THREAD', { threadId, messageIds: items.map(({ messageId }) => messageId) }, { root: true });
        });
    },

    sendImage({ commit, rootGetters }, {
      thread: { threadId, channelId },
      contactId,
      image,
      errorMessageId,
    }) {
      const { 'Authenticate/getterCurrentUbuUserId': userId } = rootGetters;

      return internals.sendImage(channelId, threadId, image.fd)
        .then((response) => {
          if (response.error && !errorMessageId) {
            const messageId = `error-${Date.now() * 1000}`;
            const newMessage = {
              [messageId]: {
                item_type: 'error',
                event: 'resendMedia',
                hide_in_thread: false,
                itemId: 'messageItemId-error',
                text: image.name,
                threadId,
                fd: image.fd,
                timestamp: `${Date.now() * 1000}`,
                user_id: userId,
                messageId,
                isMe: true,
                uniType: 'isError',
              },
            };
            commit('Message/SET_MESSAGES', newMessage, { root: true });
            commit('Thread/ADD_MESSAGES_TO_THREAD', { threadId, messageIds: [messageId] }, { root: true });
            return { error: response.error };
          }

          if (!response.error && errorMessageId) commit('Thread/REMOVE_MESSAGE_TO_THREAD', { threadId, messageId: errorMessageId }, { root: true });

          const newMessage = {
            [response.item_id]: {
              ...messageFormater({
                ...response,
                contactId,
                threadId: response.thread_id,
                messageId: response.item_id,
              }),
            },
          };
          // commit('Message/SET_MESSAGES', newMessage, { root: true });
          // commit('Thread/SET_THREADS_HAS_MESSAGES', [{ threadId: response.thread_id, messageId: response.item_id }], { root: true });
          commit('Message/SET_MESSAGES', newMessage, { root: true });
          commit('Thread/ADD_MESSAGES_TO_THREAD', { threadId, messageIds: [response.item_id] }, { root: true });
          return { response };
        }).catch((error) => {
          console.error('Catching error', error);
          if (!errorMessageId) {
            const messageId = `error-${Date.now() * 1000}`;
            const newMessage = {
              [messageId]: {
                item_type: 'error',
                event: 'resendMedia',
                hide_in_thread: false,
                itemId: 'messageItemId-error',
                text: image.name,
                threadId,
                fd: image.fd,
                timestamp: `${Date.now() * 1000}`,
                user_id: userId,
                messageId,
                isMe: true,
                uniType: 'isError',
              },
            };
            commit('Message/SET_MESSAGES', newMessage, { root: true });
            commit('Thread/ADD_MESSAGES_TO_THREAD', { threadId, messageIds: [messageId] }, { root: true });
          }
          return { error };
        });
    },

    sendMessage({ commit, rootGetters }, {
      threadId,
      contactId,
      message,
      errorMessageId,
    }) {
      const {
        'Channel/getterCurrentChannelId': channelId,
        'Authenticate/getterCurrentUbuUserId': userId,
        'Thread/getterThreads': threads,
      } = rootGetters;

      return CommonProvider
        .sendMessage({ channelId, contactId, message })
        .then(({ response, error }) => {
          if (error && !errorMessageId) {
            const messageId = `error-${Date.now() * 1000}`;
            const newMessage = {
              [messageId]: {
                contactId,
                item_type: 'error',
                event: 'resendText',
                hide_in_thread: false,
                itemId: 'messageItemId-error',
                text: message,
                threadId,
                timestamp: `${Date.now() * 1000}`,
                user_id: userId,
                messageId,
                receiver: contactId,
                isMe: true,
                uniType: 'isError',
              },
            };
            commit('Message/SET_MESSAGES', newMessage, { root: true });
            commit('Thread/ADD_MESSAGES_TO_THREAD', { threadId, messageIds: [messageId] }, { root: true });
            return { error };
          }

          if (response && errorMessageId) commit('Thread/REMOVE_MESSAGE_TO_THREAD', { threadId, messageId: errorMessageId }, { root: true });

          const newMessage = {
            [response.item_id]: {
              ...response,
              contactId,
              threadId: response.thread_id,
              messageId: response.item_id,
              receiver: contactId,
              isMe: true,
              uniType: 'isText',
            },
          };

          /**
           * Re-enable this when we ll be a real message application
           */
          const t = threads[threadId];
          const lastPerm = t && t.last_permanent_item;
          const lastTts = lastPerm.lastTts || (lastPerm && +(lastPerm.timestamp));
          commit('Thread/UPDATE_LAST_PERMANENT_ON_THREAD', {
            threadId,
            newDatas: {
              ...response,
              contactId,
              lastTts,
              threadId: response.thread_id,
              messageId: response.item_id,
              receiver: contactId,
              isMe: true,
              uniType: 'isText',
            },
          }, { root: true });
          commit('Message/SET_MESSAGES', newMessage, { root: true });
          commit('Thread/ADD_MESSAGES_TO_THREAD', { threadId, messageIds: [response.item_id] }, { root: true });
          return { response };
        })
        .catch((error) => {
          console.error('Catching error', error);
          if (!errorMessageId) {
            const messageId = `error-${Date.now() * 1000}`;
            const newMessage = {
              [messageId]: {
                contactId,
                item_type: 'error',
                hide_in_thread: false,
                itemId: 'messageItemId-error',
                text: message,
                threadId,
                timestamp: `${Date.now() * 1000}`,
                user_id: userId,
                messageId,
                receiver: contactId,
                isMe: true,
                uniType: 'isError',
              },
            };
            commit('Message/SET_MESSAGES', newMessage, { root: true });
            commit('Thread/ADD_MESSAGES_TO_THREAD', { threadId, messageIds: [messageId] }, { root: true });
          }
          return { error };
        });
    },

    sendNote({ commit, rootGetters }, { threadId, text }) {
      const { 'Channel/getterCurrentChannelId': channelId } = rootGetters;

      const timestamp = Date.now();
      return CommonProvider
        .sendNote({
          channelId,
          threadId,
          text,
          timestamp,
        })
        .then(({ response, error }) => {
          if (error) {
            return;
          }

          const newNote = {
            [response._id]: {
              ...response,
              threadId,
              channelId,
              noteId: response._id,
            },
          };
          commit('Note/SET_NOTES', newNote, { root: true });

          commit('Thread/ADD_NOTES_TO_THREAD', { threadId, noteIds: [response._id] }, { root: true });
        })
        .catch((error) => {
          console.warn('🚀 ~ file: TheInfluence.store.js ~ line 372 ~ sendMessage ~ error', error);
        });
    },

    deleteMessage({ commit, rootGetters }, { threadId, messageId }) {
      const {
        'Channel/getterCurrentChannelId': channelId,
      } = rootGetters;

      return CommonProvider
        .deleteMessage({ channelId, threadId, messageId })
        .then(() => {
          commit('Thread/REMOVE_MESSAGE_TO_THREAD', { threadId, messageId }, { root: true });
          commit('Message/REMOVE_MESSAGE', messageId, { root: true });
        })
        .catch((error) => {
          console.warn('🚀 ~ file: TheThreadPanel.store.js ~ line 268 ~ deleteMessage ~ error', error);
        });
    },
    translateTextarea(_, {
      channelId, text, target,
    }) {
      const messages = [{
        text,
      }];
      return CommonProvider
        .translateText({ channelId, messages, target })
        .then(({ response: { translations }, error }) => {
          if (error) return { error };

          return { translations };
        });
    },
    translateCommentText({ commit }, {
      channelId, target, message,
    }) {
      const messages = [{
        id: message.commentId,
        text: message.text,
      }];
      return CommonProvider
        .translateText({ channelId, messages, target })
        .then(({ response: { translations }, error }) => {
          if (error) return { error };

          const translatedMessage = {
            [message.commentId]: {
              ...message,
              text: translations.shift().text,
            },
          };
          commit('Comment/SET_COMMENTS', translatedMessage, { root: true });
          return { translations };
        });
    },
    translateMessageText({ commit }, {
      channelId, target, message,
    }) {
      if (message.uniType === 'isText') {
        const messages = [{
          id: message.commentId,
          text: message.text,
        }];
        return CommonProvider
          .translateText({ channelId, messages, target })
          .then(({ response: { translations }, error }) => {
            if (error) return { error };

            const translatedMessage = {
              [message.messageId]: {
                ...message,
                text: translations.shift().text,
              },
            };
            commit('Message/SET_MESSAGES', translatedMessage, { root: true });
            return { translations };
          });
      }
      return undefined;
    },
    translateThreadCommentText({ commit }, {
      channelId, target, messagesList,
    }) {
      const messages = messagesList.map((m) => ({
        id: m.commentId,
        text: m.text,
      }));
      return CommonProvider
        .translateText({ channelId, messages, target })
        .then(({ response: { translations }, error }) => {
          if (error) return { error };

          messagesList.forEach((m) => {
            const translatedMessage = {
              [m.commentId]: {
                ...m,
                text: translations.find(({ id }) => id === m.commentId).text,
              },
            };
            commit('Comment/SET_COMMENTS', translatedMessage, { root: true });
          });
          return { translations };
        });
    },
    translateThreadMessageText({ commit }, {
      channelId, target, messagesList,
    }) {
      const messagesTextOnly = messagesList.reduce((acc, row) => {
        if (row.uniType === 'isText') acc.push(row);
        return acc;
      }, []);

      const messages = messagesTextOnly.map((m) => ({
        id: m.messageId,
        text: m.text,
      }));

      return CommonProvider
        .translateText({ channelId, messages, target })
        .then(({ response: { translations }, error }) => {
          if (error) return { error };

          messagesTextOnly.forEach((m) => {
            const translatedMessage = {
              [m.messageId]: {
                ...m,
                text: translations.find(({ id }) => id === m.messageId).text,
              },
            };
            commit('Message/SET_MESSAGES', translatedMessage, { root: true });
          });
          return { translations };
        });
    },
  },
};
