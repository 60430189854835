import {
  arrayMapper,
} from '@/addons/$utils/prototypes';
import {
  updateDatasIfExists,
  updateObjectRelationIfExists,
  removeKeyToMap,
  addNewKeysToMap,
} from '../$utils/dataMapper';

/* eslint-disable max-len */
export default {
  namespaced: true,

  state: {
    ubuUsers: {},
    assignments: {},
    ubuUsersHasThreads: {},
  },

  getters: {
    getterUbuUsers: (state) => state.ubuUsers,
    getterAssignments: (state) => state.assignments,
    getterUbuUserById: (state) => (id) => state.ubuUsers[id],
    getterUbuUsersHasThreads: (state) => state.ubuUsersHasThreads,
    getterCurrentUbuUsers: (state, _getters, _rootState, rootGetters) => Object.values(state.ubuUsers)
      .filter((ubuUser) => ubuUser.channelIds
        .find((channelId) => rootGetters['Channel/getterCurrentChannelId'] === channelId)),
  },

  actions: {},
  mutations: {
    SET_UBUUSERS(state, newDatas) { state.ubuUsers = updateDatasIfExists({ newDatas, actualDatas: state.ubuUsers, key: 'ubuUserId' }); },
    REMOVE_UBUUSER(state, ubuUserId) { delete state.ubuUsers[ubuUserId]; },

    SET_UBUUSERS_HAS_THREADS(state, newDatas) { state.ubuUsersHasThreads = updateObjectRelationIfExists({ newDatas, actualDatas: state.ubuUsersHasThreads }); },
    ADD_THREADS_TO_UBUUSER(state, { threadIds, ubuUserId }) { state.ubuUsersHasThreads = addNewKeysToMap({ map: state.ubuUsersHasThreads, key: ubuUserId, values: threadIds }); },
    REMOVE_THREAD_TO_UBUUSER(state, { threadId, ubuUserId }) { removeKeyToMap({ map: state.ubuUsersHasThreads, key: ubuUserId, value: threadId }); },

    SET_ASSIGNMENTS(state, newDatas) { state.assignments = updateDatasIfExists({ newDatas, actualDatas: state.assignments, key: 'ubuUserId' }); },

    RESET_STORES(state) {
      // state.ubuUsers = {};
      state.assignments = {};
      state.ubuUsersHasThreads = {};
    },
  },
};
