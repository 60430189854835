import { use, registerComponent } from '../$utils/plugins';

// const TheContactPanel = () => import(/* webpackChunkName: "addonContactPanel" */ './TheContactPanel');
import TheContactPanel from './TheContactPanel';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, TheContactPanel);
  },
};

use(Plugin);

export default Plugin;

export {
  TheContactPanel,
};
