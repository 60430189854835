/* eslint-disable max-len */
/* eslint-disable camelcase */
// import { stampToDatetime } from '../../$utils/dates';

import { getUbuUserNameOrEmail } from '@/addons/$utils/ubuUsers';

export default {
  computed: {
    // isMe() { return !(!this.contact || (this.item.user_id === this.contact.pk)); },
    currentItemType() { return this.item.item_type || 'UNSUPPORTED'; },
    positionSide() {
      if (this.currentItemType === 'ubu_note' || this.item.noteId) return 'center';
      // return this.isMe ? 'end' : 'start';
      return this.item.isMe ? 'end' : 'start';
    },
    userName() {
      const { user, ubuUser, noteId } = this.item;
      if (user) return user;
      if (ubuUser) return getUbuUserNameOrEmail(ubuUser);
      return noteId ? '' : 'Instagram';
    },
    isNote() { return this.currentItemType === 'ubu_note' || this.item.noteId; },
    hasLink() { return !!(this.item.text.match(/((?:http|https):\/\/)?([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?/ig)); },
    hasHtml() { return !!(this.item.text.match(/(<([^>]+)>)/ig, '') && this.item.text.match(/(<([^>]+)>)/ig, '').length); },
    isStoryExpired() {
      const shouldExpireAt = this.$moment(this.item.when).add(1, 'day');
      return this.$moment().isAfter(shouldExpireAt);
    },
  },
  methods: {
    // isMediaVideoExpired(visualMedia) {
    //   const { url_expire_at_secs } = visualMedia;
    //   if (!url_expire_at_secs) return false;

    //   const d = this.$moment(stampToDatetime(url_expire_at_secs));

    //   return d.isBefore(this.$moment());
    // },
    textLinksArray(link) {
      const { text } = link;
      if (!text) return [{ value: '', isLink: false }];
      return this.strTolinkArray(text);
    },
    cleanHtml(str) {
      const regex = /(<([^>]+)>)/ig;
      return str.replace(regex, '');
    },
    strTolinkArray(str) {
      const regex = /((?:http|https):\/\/)?([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?/ig;
      const links = str.match(regex);
      if (!links) return [{ value: str, isLink: false }];
      let indexLink = 0;
      const linkArray = str.replace(regex, '$link').split(/(?=\$link)|(?<=\$link)/g).map((currentStr) => {
        let object = { value: currentStr, isLink: false };
        if (currentStr === '$link') {
          const currentLink = links[indexLink] || null;
          if (!currentLink) return object;
          const url = currentLink.replace(regex, (fullUrl, protocol, domain, params) => `${protocol || 'https://'}${domain}${params || ''}`);
          const value = currentLink.replace(regex, (fullUrl, protocol, domain, params) => `${domain}${params || ''}`);
          object = { value, url, isLink: true };
          indexLink += 1;
        }
        return object;
      });
      return linkArray;
    },
    linkUrl(link) {
      const regex = /((?:http|https):\/\/)?([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?/ig;

      const { link_context } = link;
      if (!link_context) return '';

      const { link_url } = link_context;
      if (!link_url) return '';

      return link_url.replace(regex, (fullUrl, protocol, domain) => `${protocol || 'https://'}${domain}`);
    },

    getCarouselUrl(newMedia) {
      const {
        carouselList,
        hasVideo,
        hasBucket,
      } = newMedia;

      if (carouselList && carouselList.length) {
        return carouselList.map((media) => ({
          imageUrl: this.getImageUrlFromNewMedia(media),
          videoUrl: media.hasVideo ? this.getVideoUrlFromNewMedia(media) : undefined,
          hasVideo: media.hasVideo,
          hasBucket: media.hasBucket,
        }));
      }
      if (hasVideo) {
        return [
          {
            imageUrl: this.getImageUrlFromNewMedia(newMedia),
            videoUrl: this.getVideoUrlFromNewMedia(newMedia),
            hasVideo,
            hasBucket,
          },
        ];
      }
      return [
        {
          imageUrl: this.getImageUrlFromNewMedia(newMedia),
          videoUrl: undefined,
          hasVideo,
          hasBucket,
        },
      ];
    },
    getImageUrlFromNewMedia(newMedia) {
      const {
        imageUrl,
        hasBucket,
        hasVideo,
        id,
      } = newMedia;

      if (hasBucket && id) {
        return hasVideo ? `${id}/thumbnail` : `${id}`;
      }
      return imageUrl;
    },
    getVideoUrlFromNewMedia(newMedia) {
      const { videoUrl, hasBucket, id } = newMedia;
      if (hasBucket) {
        return String(id);
      }
      return videoUrl;
    },
    getAudioUrlFromNewMedia(newMedia) {
      const { audioUrl } = newMedia;

      return `https://proxy.getubu.com/${encodeURIComponent(audioUrl)}`;
    },
    getDownloadUrl(item) {
      const { id, hasVideo } = item.newMedia;
      const date = this.$moment(item.when).format('YYYY-MM-DD');
      const username = item.isMe ? '' : `${this.contact.username}-`;
      const fileName = `${username}${item.item_type}-${date}.${hasVideo ? 'mp4' : 'jpg'}`;
      return `https://io.getubu.com/mediaDownload/${id}/${fileName}`;
    },
    commentImageUrl(item) {
      const { profile_pic_url } = item;
      if (!profile_pic_url) return '';

      return profile_pic_url;
    },
  },
};
