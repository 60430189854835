export default {
  data() {
    return {
      searchValue: '',
      showFilters: false,
      numberInputRangeFilters: [
        {
          id: 1000,
          ordinal: 4,
          primary: true,
          type: 'number',
          name: 'engagement',
          buttonTitle: 'Engagement',
          modalTitle: 'Engagement rate',
          range: { min: 0, max: 100 },
        },
        {
          id: 1001,
          ordinal: 3,
          primary: true,
          type: 'number',
          name: 'followerCount',
          buttonTitle: 'Followers',
          modalTitle: 'Followers',
          range: { min: 0, max: Infinity },
        },
        {
          id: 1002,
          ordinal: 6,
          type: 'number',
          name: 'shopifyLinksCount',
          buttonTitle: 'Affiliation & coupons',
          modalTitle: 'Affiliation & coupons',
          range: { min: 0, max: Infinity },
        },
        {
          id: 1003,
          ordinal: 7,
          type: 'number',
          name: 'shopifyAmountOrders',
          buttonTitle: 'Total revenues',
          modalTitle: 'Total revenues',
          range: { min: 0, max: Infinity },
        },
        {
          id: 1004,
          ordinal: 8,
          type: 'number',
          name: 'shopifyTotalOrders',
          buttonTitle: 'Total orders',
          modalTitle: 'Total orders',
          range: { min: 0, max: Infinity },
        },
        {
          id: 1005,
          ordinal: 100,
          primary: true,
          type: 'number',
          name: 'age',
          buttonTitle: 'Age',
          modalTitle: 'Age',
          range: { min: 0, max: Infinity },
        },
      ],
      rangeFilters: [
        {
          id: 300,
          ordinal: 13,
          type: 'range',
          name: 'storyMentionCount',
          buttonTitle: 'Story mention',
          modalTitle: 'Story mention',
          range: [0, 6],
          ticks: [
            { text: '0', value: 0 },
            { text: '1', value: 1 },
            { text: '2', value: 2 },
            { text: '3', value: 3 },
            { text: '4', value: 4 },
            { text: '5', value: 5 },
            { text: 'No limit', value: Infinity },
          ],
        },
        {
          id: 400,
          ordinal: 14,
          type: 'range',
          name: 'postMentionCount',
          buttonTitle: 'Post mention',
          modalTitle: 'Post mention',
          range: [0, 6],
          ticks: [
            { text: '0', value: 0 },
            { text: '1', value: 1 },
            { text: '2', value: 2 },
            { text: '3', value: 3 },
            { text: '4', value: 4 },
            { text: '5', value: 5 },
            { text: 'No limit', value: Infinity },
          ],
        },
        {
          id: 500,
          ordinal: 20,
          type: 'range',
          name: 'lastMentionOrTagPost',
          buttonTitle: 'Last mention',
          modalTitle: 'Last mention',
          range: [0, 6],
          ticks: [
            { text: 'never', value: null },
            { text: 'now', value: this.$moment().valueOf() },
            { text: '5d', value: this.$moment().subtract({ days: 5 }).valueOf() },
            { text: '15d', value: this.$moment().subtract({ days: 15 }).valueOf() },
            { text: '30d', value: this.$moment().subtract({ days: 30 }).valueOf() },
            { text: '60d', value: this.$moment().subtract({ days: 60 }).valueOf() },
            { text: 'No limit', value: 0 },
          ],
        },
        {
          id: 600,
          ordinal: 21,
          type: 'range',
          name: 'lastAddedCampaign',
          buttonTitle: 'Last campaign add',
          modalTitle: 'Last campaign add',
          range: [0, 5],
          ticks: [
            { text: 'now', value: this.$moment().valueOf() },
            { text: '5d', value: this.$moment().subtract({ days: 5 }).valueOf() },
            { text: '15d', value: this.$moment().subtract({ days: 15 }).valueOf() },
            { text: '30d', value: this.$moment().subtract({ days: 30 }).valueOf() },
            { text: '60d', value: this.$moment().subtract({ days: 60 }).valueOf() },
            { text: 'No limit', value: 0 },
          ],
        },
        {
          id: 700,
          ordinal: 22,
          type: 'range',
          name: 'lastMessageTS',
          buttonTitle: 'Last message',
          modalTitle: 'Last message',
          range: [0, 6],
          ticks: [
            { text: 'never', value: null },
            { text: 'now', value: this.$moment().valueOf() },
            { text: '5d', value: this.$moment().subtract({ days: 5 }).valueOf() },
            { text: '15d', value: this.$moment().subtract({ days: 15 }).valueOf() },
            { text: '30d', value: this.$moment().subtract({ days: 30 }).valueOf() },
            { text: '60d', value: this.$moment().subtract({ days: 60 }).valueOf() },
            { text: 'No limit', value: 0 },
          ],
        },
      ],
      radioFilters: [
        {
          ordinal: 23,
          type: 'radio',
          name: 'tiktokUsername',
          buttonTitle: 'Tiktok username',
          modalTitle: 'Have tiktok username',
          value: ['default', 'yes', 'no'],
        },
        {
          ordinal: 24,
          type: 'radio',
          name: 'growth',
          buttonTitle: 'Growth',
          modalTitle: 'Growth',
          value: ['default', 'positive', 'negative'],
        },
      ],
      currentGrowthRadio: 'default',
      currentHaveTiktokUsernameRadio: 'default',
      currentFollowersRange: [],
      currentEngagementRange: [],
      currentStoryMentionRange: [],
      currentPostMentionRange: [],
      currentAgeRange: [],
      currentActiveCampaignsCheckList: [],
      currentPastCampaignsCheckList: [],
      currentAgeAudienceCheckList: [],
      currentLocationAudienceCheckList: [],
      currentLanguageAudienceCheckList: [],
      currentLanguageCheckList: [],
      currentGenderAudienceCheckList: [],
      currentLastMentionRange: [],
      currentLastCampaignAddRange: [],
      currentLastMessageRange: [],
      currentTagCheckList: [],
      currentLabelCheckList: [],
      currentCategoryEnumCheckList: [],
      currentCategoryCheckList: [],
      currentLocationCheckList: [],
      currentShopifyLinksCountRange: [],
      currentShopifyAmountOrdersRange: [],
      currentShopifyTotalOrdersRange: [],
      currentFollowsCheckList: [],
      currentGenderCheckList: [],

      isDefaultActiveCampaignSelected: false,
      isDefaultPastCampaignSelected: false,
      isDefaultListSelected: false,
    };
  },
  computed: {
    listFiltered() {
      // return list after all filter
      let myListFiltered = this.localContacts.length && this.localContacts
        .filter(({ username }) => username.toLowerCase().includes(this.searchValue.toLowerCase()))
        .reduce((acc, user) => {
          if (this.list && (this.list.userPks.includes(user.pk)
          || this.list.usernames.includes(user.username))) acc.push(user);
          else if (!this.list) acc.push(user);
          return acc;
        }, []);

      if (this.currentFollowersRange.length) {
        const [min, max] = this.currentFollowersRange;
        myListFiltered = myListFiltered
          .filter(({ followerCount }) => followerCount >= min && followerCount <= max);
      }
      if (this.currentEngagementRange.length) {
        const [min, max] = this.currentEngagementRange;
        myListFiltered = myListFiltered
          .filter(({ engagement }) => engagement >= min && engagement <= max);
      }
      if (this.currentAgeRange.length) {
        const [min, max] = this.currentAgeRange;
        myListFiltered = myListFiltered
          .filter(({ age }) => age >= min && age <= max);
      }
      if (this.currentStoryMentionRange.length) {
        const [min, max] = this.currentStoryMentionRange;
        myListFiltered = myListFiltered
          .filter(({ storyMentionCount }) => storyMentionCount >= min && storyMentionCount <= max);
      }
      if (this.currentPostMentionRange.length) {
        const [min, max] = this.currentPostMentionRange;
        myListFiltered = myListFiltered
          .filter(({ postMentionCount }) => postMentionCount >= min && postMentionCount <= max);
      }
      // 3 possibilities, 1° get only !lastMentionOrTagPost 2° get !lastMentionOrTagPost && max range 3° get min & max range
      if (this.currentLastMentionRange.length) {
        const [min, max] = this.currentLastMentionRange;
        myListFiltered = myListFiltered
          .filter(({ lastMentionOrTagPost }) => {
            if (min === null && max === null) {
              return !lastMentionOrTagPost;
            }
            if (min === null && max !== null) {
              return lastMentionOrTagPost >= max || !lastMentionOrTagPost;
            }
            return (lastMentionOrTagPost >= max
          && lastMentionOrTagPost <= min) && lastMentionOrTagPost;
          });
      }
      if (this.currentLastCampaignAddRange.length) {
        const [min, max] = this.currentLastCampaignAddRange;
        myListFiltered = myListFiltered
          .filter(({ lastAddedCampaign }) => (lastAddedCampaign >= max
          && lastAddedCampaign <= min) && lastAddedCampaign);
      }
      if (this.currentLastMessageRange.length) {
        const [min, max] = this.currentLastMessageRange;
        myListFiltered = myListFiltered
          .filter(({ lastMessageTS }) => {
            if (min === null && max === null) {
              return !lastMessageTS;
            }
            if (min === null && max !== null) {
              return lastMessageTS >= max || !lastMessageTS;
            }
            return (lastMessageTS >= max
          && lastMessageTS <= min) && lastMessageTS;
          });
      }
      if (this.currentActiveCampaignsCheckList.length || this.isDefaultActiveCampaignSelected) {
        // if default option, return contact without activeCampaign assigned
        myListFiltered = myListFiltered
          .filter(({ activeCampaign }) => {
            if (this.isDefaultActiveCampaignSelected && !activeCampaign.length) return true;

            const campaigns = activeCampaign.map(({ title }) => title);
            return campaigns.some((c) => this.currentActiveCampaignsCheckList.includes(c));
          });
      }
      if (this.currentPastCampaignsCheckList.length || this.isDefaultPastCampaignSelected) {
        myListFiltered = myListFiltered
          .filter(({ pastCampaign }) => {
            if (this.isDefaultPastCampaignSelected && !pastCampaign.length) return true;

            const campaigns = pastCampaign.map(({ title }) => title);
            return campaigns.some((c) => this.currentPastCampaignsCheckList.includes(c));
          });
      }
      if (this.currentTagCheckList.length) {
        myListFiltered = myListFiltered
          .filter(({ threadGroup }) => {
            const campaigns = threadGroup.map(({ title }) => title);
            return campaigns.some((c) => this.currentTagCheckList.includes(c));
          });
      }
      if (this.currentLabelCheckList.length) {
        myListFiltered = myListFiltered
          .filter(({ contactGroup }) => {
            const campaigns = contactGroup.map(({ title }) => title);
            return campaigns.some((c) => this.currentLabelCheckList.includes(c));
          });
      }
      if (this.currentAgeAudienceCheckList.length) {
        myListFiltered = myListFiltered
          .filter(({ ageAudience }) => (ageAudience
            ? this.currentAgeAudienceCheckList.includes(ageAudience.name) : null));
      }
      if (this.currentGenderCheckList.length) {
        myListFiltered = myListFiltered
          .filter(({ gender }) => (gender
            ? this.currentGenderCheckList.includes(gender) : null));
      }
      if (this.currentLocationAudienceCheckList.length) {
        myListFiltered = myListFiltered
          .filter(({ locationAudience }) => (locationAudience
            ? this.currentLocationAudienceCheckList.includes(locationAudience) : null));
      }
      if (this.currentLanguageCheckList.length) {
        myListFiltered = myListFiltered
          .filter(({ language }) => (language
            ? this.currentLanguageCheckList.includes(language) : null));
      }
      if (this.currentLanguageAudienceCheckList.length) {
        myListFiltered = myListFiltered
          .filter(({ languageAudience }) => (languageAudience
            ? this.currentLanguageAudienceCheckList.includes(languageAudience) : null));
      }
      if (this.currentGenderAudienceCheckList.length) {
        myListFiltered = myListFiltered
          .filter(({ genderAudience }) => (genderAudience
            ? this.currentGenderAudienceCheckList.includes(genderAudience) : null));
      }
      if (this.currentCategoryEnumCheckList.length) {
        myListFiltered = myListFiltered
          .filter(({ type }) => (type
            ? this.currentCategoryEnumCheckList.includes(type) : null));
      }
      if (this.currentCategoryCheckList.length) {
        myListFiltered = myListFiltered
          .filter(({ category }) => (category
            ? this.currentCategoryCheckList.includes(category) : null));
      }
      if (this.currentLocationCheckList.length) {
        myListFiltered = myListFiltered
          .filter(({ location }) => (location
            ? this.currentLocationCheckList.includes(location) : null));
      }
      if (this.currentFollowsCheckList.length || this.isDefaultListSelected) {
        myListFiltered = myListFiltered
          .filter(({ follows }) => {
            if (this.isDefaultListSelected && !follows.length) return true;

            return this.currentFollowsCheckList.some((username) => follows.includes(username));
          });
      }
      if (this.currentHaveTiktokUsernameRadio !== 'default') {
        const radio = this.currentHaveTiktokUsernameRadio;
        myListFiltered = radio === 'yes'
          ? myListFiltered.filter(({ tiktokUsername }) => tiktokUsername)
          : myListFiltered.filter(({ tiktokUsername }) => !tiktokUsername);
      }
      if (this.currentGrowthRadio !== 'default') {
        const radio = this.currentGrowthRadio;
        myListFiltered = radio === 'positive'
          ? myListFiltered.filter(({ growth }) => growth > 0)
          : myListFiltered.filter(({ growth }) => growth < 0);
      }
      if (this.currentShopifyLinksCountRange.length) {
        const [min, max] = this.currentShopifyLinksCountRange;
        myListFiltered = myListFiltered
          .filter(({ shopifyLinksCount }) => shopifyLinksCount >= min && shopifyLinksCount <= max);
      }
      if (this.currentShopifyAmountOrdersRange.length) {
        const [min, max] = this.currentShopifyAmountOrdersRange;
        myListFiltered = myListFiltered
          .filter(({ shopifyAmountOrders }) => shopifyAmountOrders >= min
          && shopifyAmountOrders <= max);
      }
      if (this.currentShopifyTotalOrdersRange.length) {
        const [min, max] = this.currentShopifyTotalOrdersRange;
        myListFiltered = myListFiltered
          .filter(({ shopifyTotalOrders }) => shopifyTotalOrders >= min
          && shopifyTotalOrders <= max);
      }

      return myListFiltered || [];
    },
    filterListSortedByOrdinal() {
      return this.checkBoxFilters
        .concat(this.numberInputRangeFilters)
        .concat(this.radioFilters)
        .concat(this.rangeFilters)
        .sort((a, b) => a.ordinal - b.ordinal);
    },
    checkBoxFilters() {
      return [
        {
          ordinal: 16,
          type: 'checkbox',
          name: 'location',
          buttonTitle: 'Location',
          modalTitle: 'Location',
          value: this.locationList,
        },
        {
          ordinal: 9,
          type: 'checkbox',
          name: 'threadGroup',
          buttonTitle: 'Tags',
          modalTitle: 'Tags',
          value: this.tagList,
        },
        {
          ordinal: 10,
          type: 'checkbox',
          name: 'contactGroup',
          buttonTitle: 'Labels',
          modalTitle: 'Labels',
          value: this.labelList,
        },
        {
          ordinal: 11,
          type: 'checkbox',
          name: 'activeCampaign',
          buttonTitle: 'Active campaigns',
          modalTitle: 'Active campaigns',
          defaultOption: { name: 'No active campaign' },
          value: this.activeCampaignList,
        },
        {
          ordinal: 12,
          type: 'checkbox',
          name: 'pastCampaign',
          buttonTitle: 'Past campaigns',
          modalTitle: 'Past campaigns',
          defaultOption: { name: 'No past campaign' },
          value: this.pastCampaignList,
        },
        {
          ordinal: 5,
          primary: true,
          type: 'checkbox',
          name: 'language',
          buttonTitle: 'Language',
          modalTitle: 'Language',
          value: this.languageList,
        },
        {
          ordinal: 15,
          type: 'checkbox',
          name: 'ageAudience',
          buttonTitle: 'Age audience',
          modalTitle: 'Age audience',
          value: this.ageAudienceList,
        },
        {
          ordinal: 17,
          type: 'checkbox',
          name: 'locationAudience',
          buttonTitle: 'Location audience',
          modalTitle: 'Location audience',
          value: this.locationAudienceList,
        },
        {
          ordinal: 18,
          type: 'checkbox',
          name: 'languageAudience',
          buttonTitle: 'Language audience',
          modalTitle: 'Language audience',
          value: this.languageAudienceList,
        },
        {
          ordinal: 19,
          type: 'checkbox',
          name: 'genderAudience',
          buttonTitle: 'Gender audience',
          modalTitle: 'Gender audience',
          value: this.genderAudienceList,
        },
        {
          ordinal: 1,
          primary: true,
          type: 'checkbox',
          name: 'categoryEnum',
          buttonTitle: 'Type',
          modalTitle: 'Type',
          value: this.categoryEnumList,
        },
        {
          ordinal: 101,
          primary: true,
          type: 'checkbox',
          name: 'gender',
          buttonTitle: 'Gender',
          modalTitle: 'Gender',
          value: this.genderList,
        },
        {
          ordinal: 2,
          primary: true,
          type: 'checkbox',
          name: 'category',
          buttonTitle: 'Category',
          modalTitle: 'Category',
          value: this.categoryList,
        },
        {
          ordinal: 25,
          type: 'checkbox',
          name: 'follows',
          buttonTitle: 'Follows',
          modalTitle: 'Follows',
          defaultOption: { name: 'No list' },
          value: this.lists.map((list) => list.name),
        },
      ];
    },
  },
  methods: {
    setRangeFilter(event) {
      const { name, rangeValues } = event;
      // if (name === 'followerCount') this.currentFollowersRange = rangeValues;
      if (name === 'storyMentionCount') this.currentStoryMentionRange = rangeValues;
      if (name === 'postMentionCount') this.currentPostMentionRange = rangeValues;
      if (name === 'lastMentionOrTagPost') this.currentLastMentionRange = rangeValues;
      if (name === 'lastAddedCampaign') this.currentLastCampaignAddRange = rangeValues;
      if (name === 'lastMessageTS') this.currentLastMessageRange = rangeValues;
    },
    setCheckFilter(event) {
      const { name, checked, option } = event;
      if (name === 'threadGroup') this.currentTagCheckList = checked;
      if (name === 'contactGroup') this.currentLabelCheckList = checked;
      if (name === 'activeCampaign') {
        if (option || !option) {
          this.isDefaultActiveCampaignSelected = option;
        }
        this.currentActiveCampaignsCheckList = checked;
      }
      if (name === 'pastCampaign') {
        if (option || !option) {
          this.isDefaultPastCampaignSelected = option;
        }
        this.currentPastCampaignsCheckList = checked;
      }
      if (name === 'ageAudience') this.currentAgeAudienceCheckList = checked;
      if (name === 'locationAudience') this.currentLocationAudienceCheckList = checked;
      if (name === 'languageAudience') this.currentLanguageAudienceCheckList = checked;
      if (name === 'language') this.currentLanguageCheckList = checked;
      if (name === 'genderAudience') this.currentGenderAudienceCheckList = checked;
      if (name === 'categoryEnum') this.currentCategoryEnumCheckList = checked;
      if (name === 'category') this.currentCategoryCheckList = checked;
      if (name === 'location') this.currentLocationCheckList = checked;
      if (name === 'follows') {
        if (option || !option) {
          this.isDefaultListSelected = option;
        }
        this.currentFollowsCheckList = checked;
      }
      if (name === 'gender') this.currentGenderCheckList = checked;
    },
    setNumberInputFilter(event) {
      const { name, min, max } = event;
      if (name === 'engagement') this.currentEngagementRange = [min, max];
      if (name === 'shopifyLinksCount') this.currentShopifyLinksCountRange = [min, max];
      if (name === 'shopifyAmountOrders') this.currentShopifyAmountOrdersRange = [min, max];
      if (name === 'shopifyTotalOrders') this.currentShopifyTotalOrdersRange = [min, max];
      if (name === 'followerCount') this.currentFollowersRange = [min, max];
      if (name === 'age') this.currentAgeRange = [min, max];
    },
    setRadioFilter(event) {
      const { name, radioSelected } = event;
      if (name === 'tiktokUsername') this.currentHaveTiktokUsernameRadio = radioSelected;
      if (name === 'growth') this.currentGrowthRadio = radioSelected;
    },
  },
};
