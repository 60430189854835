const customData = {
  'First Name': null,
  'Last Name': null,
  'Instagram Account': null,
  'Lead Status': null,
  'LES PRIVES': null,
  PRODUCTSENT: null,
  COMMUNAUTE: null,
  'Contact owner': null,
  COMMENTS: null,
  'YT - Subscribers count (in K)': null,
  EVENT: null,
  '702PARTY': null,
  IMAGE: null,
  PROFESSION: null,
  Email: null,
  VIP: null,
  '702LAUNCH': null,
};

const jsUcfirst = (string) => string.charAt(0).toUpperCase() + string.slice(1);

const parseCustomData = (data) => ({
  ...customData,
  ...data,
});

export {
  jsUcfirst,
  parseCustomData,
};
