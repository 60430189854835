<template>
  <div
    class="pipelineTable multibar column"
  >
    <div class="pipelineTable_header multibar start">
      <p class="pipelineTable_title start ubu-header4">
        Add Leads
      </p>
    </div>

    <div class="start">
      <b-tabs
        v-model="activeTab"
        type="is-toggle-rounded"
        expanded
      >
        <b-tab-item label="Usernames">
          <UbuInfluenceLeadsAddLeadsBulk
            v-if="activeTab === 0"
            @sendUsernames="$emit('sendUsernames', $event)"
          />
        </b-tab-item>
        <b-tab-item label="Contacts">
          <UbuInfluenceLeadsAddLeadsContacts
            v-if="activeTab === 1"
            :contact-list="contactList"
            :leads="leads"
            :all-campaign-contact="allCampaignContact"
            :contacts-groups="contactsGroups"
            @addContacts="$emit('addContacts', $event)"
          />
        </b-tab-item>
        <b-tab-item label="Tags">
          <UbuInfluenceLeadsAddLeadsTags
            v-if="activeTab === 2"
            :contact-list="threadsList"
            :leads="leads"
            :all-campaign-contact="allCampaignContact"
            :thread-groups="threadGroups"
            @addContacts="$emit('addContacts', $event)"
          />
        </b-tab-item>
        <b-tab-item label="Campaigns">
          <UbuInfluenceLeadsAddLeadsCampaigns
            v-if="activeTab === 3"
            :contact-list="campaignList"
            :leads="leads"
            :all-campaign-contact="allCampaignContact"
            :campaign-groups="campaignGroups"
            @addContacts="$emit('addContacts', $event)"
          />
        </b-tab-item>
      </b-tabs>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UbuInfluenceLeadsAddLeads',
  props: {
    contactList: {
      type: Array,
      required: true,
    },
    threadsList: {
      type: Array,
      required: true,
    },
    campaignList: {
      type: Array,
      required: true,
    },
    leads: {
      type: Array,
      required: true,
    },
    allCampaignContact: {
      type: Array,
      required: true,
    },
    contactsGroups: {
      type: Array,
      required: true,
    },
    threadGroups: {
      type: Array,
      required: true,
    },
    campaignGroups: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      activeTab: 0,
    };
  },
  computed: {

  },
};
</script>

<style>

</style>
