import { arrayMapper } from '../../$utils/prototypes';

const updateDatasIfExists = ({
  newDatas, actualDatas, key, template,
}) => {
  const currentDatas = Object.values(actualDatas)
    .map((currentData) => ({
      ...currentData,
      ...newDatas[currentData[key]],
    }));
  const alreadyExisting = currentDatas.map((c) => c[key]);
  let toBeAdded = Object.values(newDatas)
    .filter((c) => !alreadyExisting.includes(c[key]));
  if (template) { toBeAdded = toBeAdded.map((row) => ({ ...template, ...row })); }
  const datasToMap = [...new Set([...currentDatas, ...toBeAdded])];

  return arrayMapper(datasToMap, key);
};

const updateObjectRelationIfExists = ({ actualDatas, newDatas }) => {
  const actualEntries = Object.entries(actualDatas);

  if (actualEntries.length === 0) return newDatas;
  const currentDatas = [...Object.keys(actualDatas), ...Object.keys(newDatas)]
    .reduce((acc, key) => {
      const newDataValues = newDatas[key] || [];
      const actualValues = actualDatas[key] || [];
      acc[key] = [...new Set([...actualValues, ...newDataValues])];
      return acc;
    }, {});
  // console.log('🚀 ~ file: dataMapper.js ~ line 19 ~ updateObjectRelationIfExists ~ currentDatas', currentDatas);
  return currentDatas;
};
// const updateRelationsIfExists = (actualDatas, newDatas) => [...actualDatas, ...newDatas]
//   .reduce((acc, row) => {
//     const [key1, key2] = Object.keys(row);
//     if (!acc.find((r) => r[key1] === row[key1] && r[key2] === row[key2])) { acc.push(row); }
//     return acc;
//   }, []);

const addNewKeysToMap = ({ map, key, values }) => {
  const myMap = { ...map };
  const exists = map[key];

  if (exists) {
    myMap[key] = [...new Set([...map[key], ...values])];
    return myMap;
  }

  myMap[key] = values;
  return myMap;
};
const removeKeyToMap = ({ map, key, value }) => {
  const myMap = { ...map };
  const array = myMap[key];
  array.splice(array.findIndex((id) => id === value), 1);
  return myMap;
};

const updateRelationsIfExists = (actualDatas, newDatas) => actualDatas
  .filter((row) => {
    const [key1, key2] = Object.keys(row);
    return !newDatas.find((r) => r[key1] === row[key1] && r[key2] === row[key2]);
  })
  .concat(newDatas);

const removeRelationsIfExists = (actualDatas, keyed) => {
  const [key1, key2] = Object.keys(keyed);
  // const cond = (r) => !(r[key1] === keyed[key1] && r[key2] === keyed[key2]);
  return actualDatas.filter((r) => !(r[key1] === keyed[key1] && r[key2] === keyed[key2]));
};

const removeManyRelationsIfExists = (actualDatas, relations) => actualDatas
  .filter((r) => !relations
    .find((keyed) => {
      const [key1, key2] = Object.keys(keyed);
      return (r[key1] === keyed[key1] && r[key2] === keyed[key2]);
    }));

const mapList = ({ list, key, value }) => list
  .reduce((acc, row) => {
    if (!acc[row[key]]) {
      acc[row[key]] = [row[value]];
      return acc;
    }
    acc[row[key]].push(row[value]);
    return acc;
  }, {});

export {
  updateDatasIfExists,
  updateRelationsIfExists,
  removeRelationsIfExists,
  removeManyRelationsIfExists,
  updateObjectRelationIfExists,
  addNewKeysToMap,
  removeKeyToMap,
  mapList,
};
