/* eslint-disable no-multi-spaces */
// const TheInbox = () => import(/* webpackChunkName: "addonInbox" */ './TheInbox');
import TheInbox from './TheInbox';
import meta from './routesMeta';

const routes = [
  {
    path: '/in',
    redirect: '/in/inbox',
  },
  {
    path: '/in/inbox',
    name: 'in.inbox',
    component: TheInbox,
    meta: {
      title: 'Inbox',
      auth: true,
      requireData: true,
      ...meta.inbox,
    },
  },
  {
    path: '/in/importants',
    name: 'in.importants',
    component: TheInbox,
    meta: {
      title: 'Inbox importants',
      auth: true,
      requireData: true,
      ...meta.importants,
    },
  },
  {
    path: '/in/mentions',
    name: 'in.mentions',
    component: TheInbox,
    meta: {
      title: 'Inbox mentions',
      auth: true,
      requireData: true,
      ...meta.mentions,
    },
  },
  {
    path: '/in/opportunities',
    name: 'in.opportunities',
    component: TheInbox,
    meta: {
      title: 'Inbox opportunities',
      auth: true,
      requireData: true,
      ...meta.opportunities,
    },
  },
  {
    path: '/in/influence',
    name: 'in.influence',
    component: TheInbox,
    meta: {
      title: 'Inbox influence',
      auth: true,
      requireData: true,
      ...meta.influence,
    },
  },
  {
    path: '/in/assignment',
    name: 'in.assignment',
    component: TheInbox,
    meta: {
      title: 'Inbox assignment',
      auth: true,
      requireData: true,
      ...meta.assignment,
    },
  },
  {
    path: '/in/all',
    name: 'in.all',
    component: TheInbox,
    meta: {
      title: 'Inbox all',
      auth: true,
      requireData: true,
      ...meta.assignment,
    },
  },
  {
    path: '/in/tag/:value',
    name: 'in.tag',
    component: TheInbox,
    meta: {
      auth: true,
      requireData: true,
      ...meta.tag,
    },
  },
  {
    path: '/in/folder/:value',
    name: 'in.folder',
    component: TheInbox,
    meta: {
      auth: true,
      requireData: true,
      ...meta.folder,
    },
  },
];

export default routes;
