import internals from './internals';
import { responseMapper } from '../../$utils/prototypes';
import {
  updateDatasIfExists,
  mapList,
} from '../$utils/dataMapper';

/* eslint-disable max-len */
export default {
  namespaced: true,

  state: {
    lists: {},
  },

  getters: {
    getterLists: (state) => state.lists,
  },

  mutations: {
    SET_LISTS(state, newDatas) { state.lists = updateDatasIfExists({ newDatas, actualDatas: state.lists, key: 'listId' }); },

    RESET_STORES(state) {
      state.lists = {};
    },
  },

  actions: {
    fetchLists({ commit }) {
      return Promise.resolve()
        .then(() => Promise.all(internals.channelsIds.map((channelId) => internals.fetchListsByChannel(channelId))))
        .then((fetchListsByChannel) => {
          // console.log('🚀 ~ file: List.store.js ~ line 35 ~ .then ~ fetchListsByChannel', fetchListsByChannel);
          const listsByChannels = fetchListsByChannel
            .map((row) => (
              row.error
                ? row
                : row.response
                  .map((list) => ({ ...list, channelId: row.channelId, listId: list._id }))
            ));

          const fetchedLists = responseMapper(listsByChannels, 'listId');
          commit('SET_LISTS', fetchedLists);

          const channelsHasLists = mapList({ list: Object.values(fetchedLists).map(({ channelId, listId }) => ({ channelId, listId })), key: 'channelId', value: 'listId' });
          // console.log('🚀 ~ file: List.store.js ~ line 47 ~ .then ~ channelsHasLists', channelsHasLists);
          commit('Channel/SET_CHANNELS_HAS_LISTS', channelsHasLists, { root: true });
          internals.channelsHasLists = Object.values(fetchedLists).map(({ channelId, listId }) => ({ channelId, listId }));
        });
    },
    fetchCustomLists({ commit }) {
      return Promise.resolve()
        .then(() => Promise.all(internals.channelsIds.map((channelId) => internals.fetchCustomListsByChannel(channelId))))
        .then((fetchListsByChannel) => {
          console.log('🚀 ~ file: List.store.js ~ line 55 ~ .then ~ fetchListsByChannel', fetchListsByChannel);

          const listsByChannels = fetchListsByChannel
            .map((row) => (
              row.error
                ? row
                : row.response
                  .map((list) => ({
                    ...list, channelId: row.channelId, listId: list._id, isCustomList: true,
                  }))
            ));

          const fetchedLists = responseMapper(listsByChannels, 'listId');
          commit('SET_LISTS', fetchedLists);

          const channelsHasLists = mapList({ list: Object.values(fetchedLists).map(({ channelId, listId }) => ({ channelId, listId })), key: 'channelId', value: 'listId' });
          // console.log('🚀 ~ file: List.store.js ~ line 47 ~ .then ~ channelsHasLists', channelsHasLists);
          commit('Channel/SET_CHANNELS_HAS_LISTS', channelsHasLists, { root: true });
          internals.channelsHasLists = Object.values(fetchedLists).map(({ channelId, listId }) => ({ channelId, listId }));
        });
    },
  },

};
