<template>
  <div id="UbuCommonMentionCardLarge">
    <div
      v-if="media"
      class="UbuMentionsMediaCardLarge_card"
    >
      <div class="UbuMentionsMediaCardLarge_infos">
        <div class="falign-center">
          <UbuInstaImg
            class="UbuMentionsMediaCardLarge_infos-avatar"
            has-bucket
            bucket-folder="profile_pic"
            :insta-src="media.userPk"
            fallback="/img/no_profile_pict.svg"
          />
          <div class="fcol">
            <div class="flex ubu-text2">
              <a
                class="username ubu-text2"
                @click.prevent="openInsta()"
              >
                {{ media.username }}
              </a>
              <span class="time">{{ displaybleDate }}</span>
            </div>
            <span class="ubu-subtext1">{{ nbFollowers }} followers</span>
            <span
              v-if="media.product_type == 'post'"
              class="ubu-subtext1"
            >{{ engagement }}</span>
            <div class="UbuMentionsMediaCardLarge_infos-stat">
              <span class="ubu-subtext1">~{{ estimatedReach }} </span>
              <b-icon
                icon="eye"
                size="is-small"
              />
            </div>
          </div>
        </div>
        <a
          :href="media.webContentLink"
          target="_blank"
          class="UbuMentionsMediaCardLarge_downloadIcon"
        >
          <UbuIcon
            name="download"
          />
        </a>
      </div>
      <div class="UbuMentionsMediaCardLarge_bottom">
        <UbuButton
          icon="compose"
          :tooltip="{ label: 'New message', position: 'is-left' }"
          secondary
          @click="$emit('openNewMessage', {pk: media.userPk, username: media.username})"
        />
      </div>
      <b-image
        v-if="isImage"
        class="UbuMentionsMediaCardLarge_media"
        :src="media.webViewLink"
        lazy
      />
      <video
        v-else
        ref="video"
        controls
        muted
        class="UbuMentionsMediaCardLarge_media"
      >
        <source
          :src="media.webViewLink"
          :type="'video/mp4'"
          @error="videoHasError = true;"
        >
        Sorry, your browser doesn't support embedded videos.
      </video>
    </div>
    <div
      v-if="media.product_type == 'post'"
      class="UbuMentionsMediaCardLarge_caption ubu-text2"
    >
      <div class="flex UbuMentionsMediaCardLarge_likes">
        <div class="falign-center interaction">
          <span style="color: #FF62A4">{{ nbLikes }}</span>
          <UbuIcon
            class="interaction-icon"
            name="heart"
            filled
            color="12"
          />
        </div>
        |
        <div class="falign-center interaction">
          <span style="color: #3CA6F2">{{ nbComments }}</span>
          <UbuIcon
            class="interaction-icon"
            name="replies"
            filled
            color="8"
          />
        </div>
      </div>
      <p>
        {{ media.caption }}
      </p>
    </div>
  </div>
</template>

<script>
import { shortenNumber } from '../../$utils/formate';
import { timeToNow } from '../../$utils/dates';

export default {
  name: 'UbuCommonMentionCardLarge',
  props: {
    media: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      videoHasError: false,
    };
  },
  computed: {
    estimatedReach() {
      if (this.media.instaUser && this.media.followers) {
        const engagement = (this.media.instaUser.engagement < 5
          ? 5 : this.media.instaUser.engagement) / 100 || 0.05;
        const estimatedReach = engagement * this.media.followers * 5;
        return shortenNumber(estimatedReach);
      }
      return '';
    },
    engagement() {
      if (!this.media.followers) return '';
      const likeCount = this.media.like_count || 0;
      const commentsCount = this.media.comments_count || 0;
      const engagement = ((likeCount + commentsCount) / this.media.followers) * 100;
      if (engagement === 0) return '';
      return `${engagement.toFixed(2)}% engagement`;
    },
    displaybleDate() {
      return timeToNow(this.media.timestamp);
    },
    nbFollowers() {
      if (this.media.instaUser && this.media.followers) {
        if (this.media.followers === 0) return '~';
        return shortenNumber(this.media.followers);
      }
      return '~';
    },
    nbLikes() {
      return shortenNumber(this.media.like_count || 0);
    },
    nbComments() {
      return shortenNumber(this.media.comments_count || 0);
    },
    isImage() {
      return this.media.isImage || this.videoHasError;
    },
  },
  methods: {
    openInsta() {
      window.open(`https://instagram.com/${this.media.username}`);
    },
  },
};
</script>
