<template>
  <div
    id="UbuFilterSelector"
    v-click-outside="() => showModal = false"
  >
    <button
      :class="['filterSelector-button ubu-text1', {'active': active}]"
      @click="showModal = !showModal"
    >
      {{ buttonTitle }}
    </button>
    <div
      v-if="showModal"
      class="filterSelector-modal"
    >
      <span class="ubu-text1 filterSelector-title">{{ modalTitle }}</span>
      <div class="filterSelector-container">
        <b-slider
          v-model="localRange"
          :min="range[0]"
          :max="range[1]"
          :tooltip="false"
        >
          <b-slider-tick
            v-for="(tick, index) in ticks"
            :key="index"
            :value="index"
          >
            {{ tick.text }}
          </b-slider-tick>
        </b-slider>
      </div>

      <div class="filterSelector-modal-button-group">
        <UbuButton
          grow
          center
          class="filterSelector-modal-button"
          @click="reset()"
        >
          Reset
        </UbuButton>
        <UbuButton
          grow
          center
          secondary
          class="filterSelector-modal-button"
          @click="applyFilters()"
        >
          Apply filter
        </UbuButton>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'UbuRangeSelector',
  props: {
    buttonTitle: {
      type: String,
      default: '',
    },
    modalTitle: {
      type: String,
      default: '',
    },
    ticks: {
      type: Array,
      default: () => [],
    },
    range: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showModal: false,
      localRange: [...this.range],
    };
  },
  computed: {
    buttonText() {
      if (this.localRange[0] === this.range[0] && this.localRange[1] === this.range[1]) {
        return this.buttonTitle;
      }
      if (this.localRange[0] === this.localRange[1]) {
        return this.ticks[this.localRange[0]].text;
      }
      return `${this.ticks[this.localRange[0]].text} - ${this.ticks[this.localRange[1]].text}`;
    },
    active() {
      return this.showModal
      || (this.localRange[0] !== this.range[0] || this.localRange[1] !== this.range[1]);
    },
  },
  methods: {
    reset() {
      this.localRange = this.range;
      this.showModal = false;
      const rangeValues = [
        this.ticks[this.localRange[0]].value, this.ticks[this.localRange[1]].value,
      ];
      this.$emit('setRangeFilter', { rangeValues });
    },
    applyFilters() {
      this.showModal = false;
      const rangeValues = [
        this.ticks[this.localRange[0]].value, this.ticks[this.localRange[1]].value,
      ];
      this.$emit('setRangeFilter', { rangeValues });
    },
  },
};
</script>
