<template>
  <UbuCommonMessageCard
    :is-me="isMe"
    :date="date"
    :is-story-expired="isStoryExpired"
    :is-story="isStory"
    :is-media="isMedia"
    @turnOnMedia="turnOnMedia()"
    @deleteMessage="$emit('deleteMessage')"
  >
    <template v-slot:image>
      <div
        v-if="hasBucket || !isStoryExpired"
        class="ubuStoryExpiredPlaceholder"
      >
        <UbuCommonVideo
          v-if="hasVideo && hasBucket"
          :video-url="videoUrl"
          :image-url="imageUrl"
          :has-bucket="hasBucket"
          @turnOnMedia="turnOnMedia()"
        />
        <UbuCommonImage
          v-else-if="imageUrl"
          :url="imageUrl"
          :has-bucket="hasBucket"
        />
        <div
          v-else
          class="ubuStoryExpiredPlaceholder"
        >
          <span>Expired</span>
        </div>
      </div>
      <div
        v-else
        class="ubuStoryExpiredPlaceholder"
      >
        <span>Expired</span>
      </div>
    </template>
    <template v-slot:content>
      <div class="card-content-text">
        <p class="ubuSubtitle">
          <template v-if="isMe">
            You mentioned
            <UbuInstaName
              class="UbuCommonUsername"
              :username="username"
            />in your story
          </template>
          <template v-else>
            <UbuInstaName
              class="UbuCommonUsername"
              :username="username"
            />mentioned you in their story
          </template>
        </p>
      </div>
    </template>
  </UbuCommonMessageCard>
</template>

<script>
import MessageCardMixin from '../$mixins/MessageCardMixin';

export default {
  name: 'UbuCommonStoryMention',
  mixins: [MessageCardMixin],
};
</script>
