const catalog = {
  // Account stats
  final_followers: {
    name: 'Total followers',
    ordinal: 1,
    isExtended: true,
    type: 'account',
  },
  followers_difference: {
    name: 'Net followers growth (New-Lost)',
    ordinal: 2,
    isExtended: true,
    type: 'account',
  },
  new_followers: {
    name: 'New followers',
    ordinal: 3,
    isExtended: true,
    type: 'account',
  },
  mean_engagement: {
    name: 'Engagement rate',
    ordinal: 4,
    isExtended: true,
    type: 'account',
    hasPourcent: true,
  },
  impressions: {
    name: 'Prints',
    ordinal: 5,
    type: 'account',
  },
  reach: {
    name: 'Reach',
    ordinal: 6,
    type: 'account',
  },
  profile_views: {
    name: 'Profile visits',
    ordinal: 7,
    type: 'account',
  },
  website_clicks: {
    name: 'Website clicks',
    ordinal: 8,
    type: 'account',
  },
  email_contacts: {
    name: 'Email contacts',
    ordinal: 9,
    type: 'account',
  },
  phone_call_clicks: {
    name: 'Call clicks',
    ordinal: 10,
    type: 'account',
  },
  text_message_clicks: {
    name: 'Message clicks',
    ordinal: 11,
    type: 'account',
  },
  get_directions_clicks: {
    name: 'Itinerary clicks',
    ordinal: 12,
    type: 'account',
  },
  // Inbox stats
  messages_received: {
    name: 'Received messages',
    ordinal: 21,
    type: 'inbox',
  },
  comments_recevied: {
    name: 'Received comments',
    ordinal: 22,
    type: 'inbox',
  },
  mentions_stories: {
    name: 'Received story mentions',
    ordinal: 23,
    type: 'inbox',
  },
  reactions_stories: {
    name: 'Received story reactions',
    ordinal: 24,
    type: 'inbox',
  },
  messages_sent: {
    name: 'Sent messages',
    ordinal: 25,
    type: 'inbox',
  },
  taggued_posts: {
    name: 'Tagged posts',
    ordinal: 26,
    type: 'inbox',
  },
  taggued_threads: {
    name: 'Tagged and assigned messages',
    ordinal: 27,
    isExtended: true,
    type: 'inbox',
  },

  // Inbox/Shopify
  totalRevenuesFromStore: {
    name: 'Total revenues from store',
    ordinal: 260,
    type: 'inbox/shopify',
    isExtended: true,
    hasDollar: true,
  },
  totalOrdersFromStore: {
    name: 'Total orders from store',
    ordinal: 261,
    type: 'inbox/shopify',
    isExtended: true,
  },
  totalRevenuesFromInstagram: {
    name: 'Total revenues from Instagram',
    ordinal: 262,
    type: 'inbox/shopify',
    isExtended: true,
    hasDollar: true,
    icon: 'instagram',
  },
  totalOrdersFromInstagram: {
    name: 'Total orders from Instagram',
    ordinal: 263,
    type: 'inbox/shopify',
    isExtended: true,
    icon: 'instagram',
  },
  totalRevenuesFromLinks: {
    name: 'Total revenues from affiliates / coupons',
    ordinal: 264,
    type: 'inbox/shopify',
    isExtended: true,
    hasDollar: true,
    svg: '/img/ubu-logo.svg',
  },
  totalOrdersFromLinks: {
    name: 'Total orders from affiliates / coupons',
    ordinal: 265,
    type: 'inbox/shopify',
    isExtended: true,
    svg: '/img/ubu-logo.svg',
  },

  // influence
  influencersReach: {
    name: 'Influencers reach',
    ordinal: 30,
    type: 'influence',
  },
  mentionsNumbers: {
    name: 'Mentions',
    ordinal: 31,
    type: 'influence',
  },
  influencersPostCount: {
    name: 'Active influencers ',
    ordinal: 32,
    type: 'influence',
    tooltipInfo: 'Influencers who mentionned you during campaign',
  },
  success: {
    name: 'Success',
    ordinal: 33,
    // isExtended: true,
    type: 'influence',
  },

  // Influence/Shopify
  totalOrderByReferralLinks: {
    name: 'Total orders from campaign referral links',
    ordinal: 40,
    type: 'influence/shopify',
    isExtended: true,
  },
  totalOrderByDiscountCodes: {
    name: 'Total orders from campaign discount codes',
    ordinal: 41,
    type: 'influence/shopify',
    isExtended: true,
  },
  totalRevenuesByReferralLinks: {
    name: 'Total revenues from campaign referral links',
    ordinal: 42,
    type: 'influence/shopify',
    isExtended: true,
  },
  totalRevenuesByDiscountCodes: {
    name: 'Total revenues from campaign discount codes',
    ordinal: 43,
    type: 'influence/shopify',
    isExtended: true,
  },
};

export {
  // eslint-disable-next-line import/prefer-default-export
  catalog,
};
