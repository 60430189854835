/* eslint-disable no-multi-spaces */
// const TheComment = () => import('./TheComment');
import TheComment from './TheComment';

export default [
  {
    path: '/comment',
    redirect: '/comment/all',
  },
  {
    path: '/comment/:filter',
    name: 'Comment',
    component: TheComment,
    meta: {
      auth: true,
      // requireData: true,
      requireFacebookData: true,
    },
  },
];
