<template>
  <div class="workspace-modal">
    <div class="modal-header">
      <p class="modal-header-title ubu-title">Create contact label</p>

      <UbuIcon
        name="cross"
        clickable
        @click="$emit('close', $event)"
      />
    </div>
    <div class="modal-content">
      <div class="modal-content-dropdown-parent">
        <UbuIcon
          class="modal-content-icon mr-2"
          :name="iconName"
          :color="newCustom.color"
          filled
          clickable
          @click="dropdownOpened = !dropdownOpened"
        />
        <div
          v-if="dropdownOpened"
          class="modal-content-dropdown"
        >
          <UbuColorPicker
            :max-per-row="3"
            :icon="iconName"
            @selectColor="$emit('selectColor', $event), dropdownOpened = !dropdownOpened"
          />
        </div>
      </div>
      <div class="modal-content-input-wrapper">
        <UbuSimpleInput
          v-model="newCustom.title"
          class="modal-content-input pl-3"
          :focus="true"
          placeholder="Name..."
        />
      </div>
    </div>
    <div class="modal-footer">
      <UbuButton
        grow
        center
        secondary
        @click="$emit('addFromModal', newCustom)"
      >
        Save
      </UbuButton>
    </div>
  </div>
</template>

<script>

export default {
  name: 'UbuWorkspaceModalAddFolder',
  props: {
    newCustom: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dropdownOpened: false,
    };
  },
  computed: {
    iconName() {
      return this.newCustom.type === 'label' ? 'contact' : this.newCustom.type;
    },
  },
};
</script>
