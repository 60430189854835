const formate = (i) => ({
  _id: i._id || '',
  pk: i.pk,
  account_type: i.account_type || 0,
  biography: i.biography || '',
  follower_count: i.follower_count || 0,
  following_count: i.following_count || 0,
  following_tag_count: i.following_tag_count || 0,
  full_name: i.full_name || '',
  is_business: i.is_business || false,
  is_potential_business: i.is_potential_business || false,
  is_private: i.is_private || false,
  is_verified: i.is_verified || false,
  mutual_followers_count: i.mutual_followers_count || 0,
  profile_pic_id: i.profile_pic_id || '',
  profile_pic_url: i.profile_pic_url || '',
  username: i.username || '',
  averageTag_comment: i.averageTag_comment || '',
  averageTag_like: i.averageTag_like || 0,
  average_comment: i.average_comment || '',
  average_like: i.average_like || 0,
  engagement: i.engagement || '',
  engagementTag: i.engagementTag || '',
  public_email: i.public_email || '',
  public_phone_number: i.public_phone_number || '',
  channelId: i.channelId || '',
  contactId: i.contactId || i.pk,
  contactPanelNotes: i.notes || [],
  emails: i.emails || [],
  phones: i.phones || [],
  websites: i.websites || [],
  ...i,
});

export default formate;
