<template>
  <div
    id="UbuContactPanelModalTiktokUsername"
    class="workspace-modal"
  >
    <div class="modal-header">
      <p class="modal-header-title ubu-title">TikTok settings</p>

      <UbuIcon
        name="cross"
        clickable
        @click="$emit('close', $event)"
      />
    </div>
    <div class="UbuModal_note">
      <h2>NOTE:</h2>
      <p class="ubu-text1">{{ noteText }}</p>
    </div>
    <div
      class="modal-content"
      style="display: block"
    >
      <b-field
        label="Usernames to track:"
        :type="errorMessageUsernames ? 'is-danger' : null"
        :message="errorMessageUsernames"
        style="width: 100%"
      >
        <b-taginput
          v-model="usernames"
          placeholder="Add a username"
          :confirm-keys="[' ',',','Tab','Enter']"
          expanded
        />
      </b-field>
      <b-field
        label="Keywords to track:"
        :type="errorMessage ? 'is-danger' : null"
        :message="errorMessage"
        style="width: 100%"
      >
        <b-taginput
          v-model="keywords"
          placeholder="Add a keyword"
          :confirm-keys="[' ',',','Tab','Enter']"
          expanded
        />
      </b-field>
    </div>
    <div class="modal-footer mt-4">
      <UbuButton
        class="mr-2"
        center
        grow
        @click="$emit('close', $event)"
      >
        Cancel
      </UbuButton>
      <UbuButton
        center
        grow
        secondary
        :disabled="!canSave"
        @click="addFromModal()"
      >
        Save
      </UbuButton>
    </div>
  </div>
</template>

<script>

export default {
  name: 'UbuInfluenceModalCreateTikTokOrder',
  props: {
  },
  data() {
    return {
      keywords: [],
      usernames: [],
      noteText: 'Please write the mentions or hashtags you want to track and on which usernames you want to track them. Separate each value by a space, those characters are not authorized: @#',
    };
  },
  computed: {
    errorMessage() {
      if (!this.keywords.length) return '';
      return !this.keywords.find((k) => k.match('.*[?@#].*')) ? '' : "Don't use those characters: @#";
    },
    errorMessageUsernames() {
      if (!this.usernames.length) return '';
      return !this.usernames.find((k) => k.match('.*[?@].*')) ? '' : "Don't use this character: @";
    },
    canSave() {
      if (!this.keywords.length || !this.usernames.length || this.keywords.find((k) => k.match('.*[?@#].*')) || this.usernames.find((k) => k.match('.*[?@#].*'))) return false;
      return true;
    },
  },
  methods: {
    addFromModal() {
      this.$emit('addFromModal', { keywords: this.keywords, usernames: this.usernames });
    },
  },
};
</script>
