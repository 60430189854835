import moment from 'moment';
import query from '../../dataStores/stores/query';
import { arrayMapper } from '../../$utils/prototypes';
import { threadIsUnread } from '../../$utils/threadUtilities';
import { mentionsFormater, findLastMentionOrTagPost } from '../../mentions/$utils/mentions';
import InfluenceProvider from '../providers/Influence.provider';
import MentionsProvider from '../../mentions/providers/Mentions.provider';
import { mapActivitiesFromCampaign } from '../../crm/$utils/filterManagement';
import {
  mapList,
} from '../../dataStores/$utils/dataMapper';

export default {
  namespaced: true,

  state: {
    currentChannel: {},
    campaignActivitiesDetails: {},
    currentThreadId: '',
    currentThread: {},
    currentCampaignId: '',
    currentMentions: [],
    currentInfluencers: [],
  },

  mutations: {
    UPDATE_CURRENT_THREAD_ID: (state, threadId) => { state.currentThreadId = threadId; },
    UPDATE_CURRENT_THREAD: (state, thread) => { state.currentThread = thread; },
    UPDATE_CURRENT_CAMPAIGN_ID: (state, campaignId) => { state.currentCampaignId = campaignId; },
    SET_MENTIONS(state, mentions) { state.currentMentions = mentions; },
    SET_INFLUENCERS(state, influencers) { state.currentInfluencers = influencers; },
  },

  getters: {
    getterCurrentThreadId: (state) => state.currentThreadId,
    getterCurrentThread: (state) => state.currentThread,
    getterCurrentCampaign: (state) => state.currentCampaignId,
    getterCampaignActivitiesDetails: (_state, _getters, _rootState, rootGetters) => {
      const { 'Sequence/getterFetchDone': fetchDone } = rootGetters;

      if (!fetchDone) return { campaigns: [] };

      const {
        'Channel/getterCurrentChannelId': channelId,
        'Channel/getterChannels': channels,
        'Channel/getterChannelsHasCampaignsBis': channelsHasCampaignsBis,
        'Channel/getterChannelsHasCustoms': channelsHasCustoms,
        'Campaign/getterCampaigns': campaigns,
        'Campaign/getterCampaignsHasActivitiesBis': campaignsHasActivitiesBis,
        'Activity/getterActivities': activities,
        'Contact/getterContacts': contacts,
        'Contact/getterContactsHasContactGroupsBis': contactsHasContactGroupBis,
        'Contact/getterContactsHasThreadsBis': contactsHasThreadsBis,
        'CustomTag/getterCustomTags': customTag,
        'ContactGroup/getterContactGroups': contactGroups,
        'Thread/getterThreads': threads,
        'Campaign/getterCampaignsHasDiscountCodesBis': campaignsHasDiscountCodesBis,
        'Campaign/getterCampaignsHasReferralLinksBis': campaignsHasReferralLinksBis,
        'Shopify/DiscountCode/getterDiscountCode': discountCode,
        'Shopify/ReferralLink/getterReferralLink': referralLink,
        'Shopify/DiscountCode/getterDiscountCodeHasOrderBis': discountCodeHasOrderBis,
        'Shopify/ReferralLink/getterReferralLinkHasOrderBis': referralLinkHasOrderBis,
        'Shopify/Order/getterOrders': order,
      } = rootGetters;

      const response = {
        ...channels[channelId],
        customs: query.find({
          model: 'customs',
          relationTable: channelsHasCustoms,
          dataTable: customTag,
          fromId: { key: 'channelId', value: channelId },
          toId: { key: 'customTagId' },
        }),
        campaigns: query.findObjectLite({
          model: 'campaigns',
          relationTable: channelsHasCampaignsBis,
          dataTable: campaigns,
          fromId: channelId,
        })
          .map((campaign) => ({
            ...campaign,
            createdOn: moment(campaign.created).valueOf(),
            activities: query.findObjectLite({
              model: 'activities',
              relationTable: campaignsHasActivitiesBis,
              dataTable: activities,
              fromId: campaign.campaignId,
            })
              .map((activity) => ({
                thread: query.findObjectLite({
                  model: 'activities',
                  relationTable: contactsHasThreadsBis,
                  dataTable: threads,
                  fromId: activity.contactId,
                })
                  .reduce((acc, thread) => {
                    if (!thread) return acc;

                    // eslint-disable-next-line no-param-reassign
                    acc = {
                      ...thread,
                      campaignId: campaign.campaignId,
                      isUnread: threadIsUnread(thread),
                    };
                    return acc;
                  }, {}),
                activityDetail: { ...activity, addedOn: moment(activity.created).valueOf() },
                contactDetail: contacts[activity.contactId],
                contactGroup: query.findObjectLite({
                  model: 'contactGroups',
                  relationTable: contactsHasContactGroupBis,
                  dataTable: contactGroups,
                  fromId: activity.contactId,
                }),
              }))
              .filter(({ contactDetail }) => contactDetail && contactDetail.username),
            discountCodes: query.findObjectLite({
              model: 'discountCode',
              relationTable: campaignsHasDiscountCodesBis,
              dataTable: discountCode,
              fromId: campaign.campaignId,
            })
              .map((dc) => ({
                ...dc,
                order: query.findObjectLite({
                  model: 'order',
                  relationTable: discountCodeHasOrderBis,
                  dataTable: order,
                  fromId: dc.discountCodeDcid,
                }),
              })),
            referralLinks: query.findObjectLite({
              model: 'referralLink',
              relationTable: campaignsHasReferralLinksBis,
              dataTable: referralLink,
              fromId: campaign.campaignId,
            })
              .map((rl) => ({
                ...rl,
                order: query.findObjectLite({
                  model: 'order',
                  relationTable: referralLinkHasOrderBis,
                  dataTable: order,
                  fromId: rl.referralLinkRlid,
                }),
              })),
          })),
      };

      const { campaigns: _campaigns, ...rest } = response;
      const myCampaigns = _campaigns.reduce((acc, campaign) => {
        let campaignHasUnread = 0;
        campaign.activities.forEach((activity) => {
          if (activity.thread.isUnread && !activity.activityDetail.ended) {
            campaignHasUnread += 1;
          }
        });

        acc.push({ ...campaign, campaignHasUnread });

        return acc;
      }, []);
      // console.log('🚀 ~ file: TheInfluence.store.js ~ line 106 ~ myCampaigns', myCampaigns);
      return { ...rest, campaigns: myCampaigns };
    },

    getterChannelContacts: (_state, _getters, _rootState, rootGetters) => {
      const { 'Sequence/getterFetchDone': fetchDone } = rootGetters;

      if (!fetchDone) return { contacts: [] };

      const {
        'Channel/getterCurrentChannelId': channelId,
        'Channel/getterChannels': channels,
        'Channel/getterChannelsHasContactsBis': channelsHasContactsBis,
        'Activity/getterActivities': activities,
        'Campaign/getterCampaigns': campaigns,
        'Contact/getterContacts': contacts,
        'Contact/getterContactsHasActivitiesBis': contactsHasActivitiesBis,
        'Contact/getterContactsHasContactGroupsBis': contactsHasContactGroupBis,
        'Contact/getterContactsHasThreadsBis': contactsHasThreadsBis,
        'ContactGroup/getterContactGroups': contactGroups,
        'Thread/getterThreadsHasThreadGroupsBis': threadsHasThreadGroupBis,
        'ThreadGroup/getterThreadGroups': threadGroups,
      } = rootGetters;

      const response = {
        ...channels[channelId],
        contacts: query.findObjectLite({
          model: 'contacts',
          relationTable: channelsHasContactsBis,
          dataTable: contacts,
          fromId: channelId,
        })
          // .filter((c) => c && c.username)
          .map((contact) => ({
            ...contact,
            contactGroup: query.findObjectLite({
              model: 'contactGroups',
              relationTable: contactsHasContactGroupBis,
              dataTable: contactGroups,
              fromId: contact.contactId,
            }),

            threadGroups: (contactsHasThreadsBis[contact.contactId] || [])
              .flatMap((threadId) => query.findObjectLite({
                model: 'threadGroups',
                relationTable: threadsHasThreadGroupBis,
                dataTable: threadGroups,
                fromId: threadId,
              })),

            activities: query.findObjectLite({
              model: 'activities',
              relationTable: contactsHasActivitiesBis,
              dataTable: activities,
              fromId: contact.contactId,
            })
              .map((activity) => ({
                ...activity,
                campaignDetail: campaigns[activity.campaignId],
              })),
          })),
      };
      return response;
    },

    getterCampaignThreads: (_state, _getters, _rootState, rootGetters) => {
      const { 'Sequence/getterFetchDone': fetchDone } = rootGetters;

      if (!fetchDone) return { threads: [] };

      const {
        'TheInfluence/getterCurrentCampaign': currentCampaignId,
        'Activity/getterActivities': activities,
        'Campaign/getterCampaignsHasContactsBis': campaignsHasContactBis,
        'Contact/getterContacts': contacts,
        'Contact/getterContactsHasActivitiesBis': contactsHasActivitiesBis,
        'Contact/getterContactsHasContactGroupsBis': contactsHasContactGroupBis,
        'Contact/getterContactsHasThreadsBis': contactsHasThreadsBis,
        'ContactGroup/getterContactGroups': contactGroups,
        'Thread/getterThreads': threads,
        'Thread/getterThreadsHasThreadGroupsBis': threadsHasThreadGroupBis,
        'ThreadGroup/getterThreadGroups': threadGroups,
      } = rootGetters;

      const response = {
        threads: query.findObjectLite({
          model: 'contacts',
          relationTable: campaignsHasContactBis,
          dataTable: contacts,
          fromId: currentCampaignId,
        })
          .filter((c) => c)
          .reduce((acc, { contactId }) => {
            if (!contactsHasThreadsBis[contactId]) return acc;
            const [threadId] = contactsHasThreadsBis[contactId];

            if (threads[threadId]) acc.push(threads[threadId]);
            return acc;
          }, [])
          .map((thread) => ({
            ...thread,
            isUnread: threadIsUnread(thread),
            contact: contacts[thread.contactId],
            contactGroups: query.findObjectLite({
              model: 'contactGroups',
              relationTable: contactsHasContactGroupBis,
              dataTable: contactGroups,
              fromId: thread.contactId,
            }),
            activity: query.findObjectLite({
              model: 'contacts',
              relationTable: contactsHasActivitiesBis,
              dataTable: activities,
              fromId: thread.contactId,
            })
              .reduce((acc, activity) => {
                if (activity.campaignId === currentCampaignId) {
                  // eslint-disable-next-line no-param-reassign
                  acc = { ...activity, addedOn: moment(activity.created).valueOf() };
                }
                return acc;
              }, {}),
            threadGroups: query.findObjectLite({
              model: 'threadGroups',
              relationTable: threadsHasThreadGroupBis,
              dataTable: threadGroups,
              fromId: thread.threadId,
            }),
            mentions: contacts[thread.contactId].mentionCount || [],
          })),
      };

      return response;
    },
    getterCurrentThreadsDatas: (_state, _getters, _rootState, rootGetters) => {
      const { 'Sequence/getterFetchDone': fetchDone } = rootGetters;

      const currentThreadId = _getters.getterCurrentThreadId;

      if (!fetchDone || !currentThreadId) return undefined;

      const {
        'Message/getterMessages': messages,
        'Note/getterNotes': notes,
        'Thread/getterThreadsHasMessagesBis': threadsHasMessagesBis,
        'Thread/getterThreadsHasNotesBis': threadsHasNotesBis,
        'UbuUser/getterUbuUsers': ubuUser,
      } = rootGetters;

      const { getterCampaignThreads } = _getters;

      const thread = getterCampaignThreads.threads
        .find(({ threadId }) => threadId === currentThreadId);

      if (!thread) return undefined;

      const response = {
        ...thread,
        messages: query.findObjectLite({
          model: 'messages',
          relationTable: threadsHasMessagesBis,
          dataTable: messages,
          fromId: thread.threadId,
        })
          .map((message) => ({
            ...message,
            ubuUser: ubuUser[message.ubu_user],
          })),
        notes: query.findObjectLite({
          model: 'notes',
          relationTable: threadsHasNotesBis,
          dataTable: notes,
          fromId: thread.threadId,
        })
          .map((note) => {
            const myNote = { ...note };
            // @TODO to be removed on backend fix
            if (!myNote.text) {
              // console.log('🚀 ~ file: TheInfluence.store.js ~ line 227 ~ .map ~ myNote', myNote);
              myNote.text = 'BUG';
            }
            if (myNote.ubu_user) {
              myNote.ubuUser = ubuUser[myNote.ubu_user];
            }
            if (myNote.text && myNote.text[0] === '{') {
              const { text } = JSON.parse(myNote.text);
              myNote.text = text;
            }
            return myNote;
          }),
      };

      return response;
    },
    getterCurrentCampaignActivities: (_state, _getters, _rootState, rootGetters) => {
      const { 'Sequence/getterFetchDone': fetchDone } = rootGetters;

      if (!fetchDone || !_getters.getterCurrentCampaign) return { activities: [] };

      const {
        'Campaign/getterCampaigns': campaigns,
        'Campaign/getterCampaignsHasActivitiesBis': campaignsHasActivitiesBis,
        'Activity/getterActivities': activities,
      } = rootGetters;

      const campaign = campaigns[_getters.getterCurrentCampaign];

      const response = {

        ...campaign,
        activities: query.findObjectLite({
          model: 'activities',
          relationTable: campaignsHasActivitiesBis,
          dataTable: activities,
          fromId: campaign.campaignId,
        }),
      };

      return response;
    },
    getterCurrentMentions: (state) => state.currentMentions,
    getterCurrentInfluencers: (state, _getters, _rootState, rootGetters) => {
      const { 'Sequence/getterFetchDone': fetchDone } = rootGetters;
      if (!fetchDone) return [];
      const {
        'Contact/getterContactsHasContactGroupsBis': contactsHasContactGroupBis,
        'ContactGroup/getterContactGroups': contactGroups,
        'Activity/getterActivities': activities,
        'Campaign/getterCampaigns': campaigns,
        'Contact/getterContactsHasActivitiesBis': contactsHasActivitiesBis,
        'Channel/getterCurrentChannelId': channelId,
        'Channel/getterChannelsHasContactsBis': channelsHasContactsBis,
        'Contact/getterContacts': contacts,
        'Contact/getterContactsHasThreadsBis': contactsHasThreadsBis,
        'Thread/getterThreadsHasThreadGroupsBis': threadsHasThreadGroupBis,
        'ThreadGroup/getterThreadGroups': threadGroups,
        'Thread/getterThreads': threads,
      } = rootGetters;

      const allContacts = query.findObjectLite({
        model: 'contacts',
        relationTable: channelsHasContactsBis,
        dataTable: contacts,
        fromId: channelId,
      })
        .map((contact) => ({
          ...contact,
          thread: query.findObjectLite({
            model: 'activities',
            relationTable: contactsHasThreadsBis,
            dataTable: threads,
            fromId: contact.contactId,
          })
            .reduce((acc, thread) => (thread || {}), {}),
          mentions: contact.mentionCount || [],
          tagsPosts: contact.tagCount || [],
          contactGroup: query.findObjectLite({
            model: 'contactGroups',
            relationTable: contactsHasContactGroupBis,
            dataTable: contactGroups,
            fromId: contact.contactId,
          }),

          threadGroup: (contactsHasThreadsBis[contact.contactId] || [])
            .flatMap((threadId) => query.findObjectLite({
              model: 'threadGroups',
              relationTable: threadsHasThreadGroupBis,
              dataTable: threadGroups,
              fromId: threadId,
            })),

          activities: query.findObjectLite({
            model: 'activities',
            relationTable: contactsHasActivitiesBis,
            dataTable: activities,
            fromId: contact.contactId,
          })
            .map((activity) => ({
              ...activity,
              campaignDetail: campaigns[activity.campaignId],
            })),
        }));

      const influencers = allContacts.filter(
        (contact) => contact.activities.map((a) => a.campaignId).includes(state.currentCampaignId),
      );

      return influencers.map((influencer) => ({
        ...state.currentInfluencers.find((contact) => contact.userPk === influencer.contactId),
        ...influencer,
      })).map((influencer) => ({
        ...influencer,
        contactGroup: influencer.contactGroup || [],
        contactGroups: influencer.contactGroup || [],
        threadGroup: influencer.threadGroup || [],
        activities: influencer.activities || [],
        emails: influencer.emails || [],
        phones: influencer.phones || [],
        websites: influencer.websites || [],
        lastMentionOrTagPost: findLastMentionOrTagPost(influencer),
        posted: influencer.mention ? 'Yes' : 'No',
        status: influencer?.activities.find(
          ({ campaignId }) => campaignId === state.currentCampaignId,
        )?.status,
        followerCount: influencer.followerCount || influencer.follower_count,
      })).map((influencer) => ({
        ...influencer,
        activeCampaign: mapActivitiesFromCampaign(influencer, false),
      })).filter((a) => a.engagement > 0.3 || a.lastMentionOrTagPost
      || a.contactGroup.length || a.threadGroup.length || a.activities.length);
    },
  },

  actions: {
    UP_currentCampaignId({ commit }, campaignId) {
      commit('UPDATE_CURRENT_CAMPAIGN_ID', campaignId);
    },
    UP_currentThreadId({ commit }, threadId) {
      // console.log('🚀 ~ file: TheInfluence.store.js ~ line 260 ~ UP_currentThreadId ~ threadId', threadId);
      commit('UPDATE_CURRENT_THREAD_ID', threadId);
    },
    UP_currentThread({ commit }, thread) {
      commit('UPDATE_CURRENT_THREAD_ID', thread.threadId || '');
      commit('TheInbox/$UPDATE_CURRENT_THREAD_ID', thread.threadId || '', { root: true });
      commit('UPDATE_CURRENT_THREAD', thread);
    },

    getContactByUsername(_, { splited, channelId }) {
      // console.log('🚀 ~ file: TheInfluence.store.js ~ line 222 ~ getContactByUsername ~ channelId', channelId);
      // console.log('🚀 ~ file: TheInfluence.store.js ~ line 222 ~ getContactByUsername ~ splited', splited);
      return Promise.all(splited.map((username) => InfluenceProvider
        .getContactByUsername({ payload: { username, channelId } })
        .then((response) => ({ ...response, username }))));
    },

    addContactsFromScraping({ commit }, contactList) {
      const contactListMapped = arrayMapper(contactList, 'contactId');
      // console.log('🚀 ~ file: Contact.store.js ~ line 37 ~ .then ~ contactListMapped', contactListMapped);
      commit('Contact/SET_CONTACTS', contactListMapped, { root: true });

      const { cid, contactIds } = Object
        .values(contactListMapped)
        .reduce((acc, { channelId, contactId }) => {
          acc.cid = channelId;
          acc.contactIds.push(contactId);
          return acc;
        }, { cid: '', contactIds: [] });
      // console.log('🚀 ~ file: Contact.store.js ~ line 42 ~ .then ~ channelsHasContacts', channelsHasContacts);
      commit('Channel/ADD_CONTACT_TO_CHANNEL', { channelId: cid, contactIds }, { root: true });
      return contactList;
    },

    createCampaign({ commit }, campaign) {
      // console.log('🚀 ~ file: TheInfluence.store.js ~ line 270 ~ updateCampaign ~ campaign', campaign);
      return InfluenceProvider
        .createCampaign(campaign)
        .then(({ error, response }) => {
          console.log('🚀 ~ file: TheInfluence.store.js ~ line 249 ~ .then ~ error', error);
          // console.log('🚀 ~ file: TheInfluence.store.js ~ line 275 ~ .setStatus ~ response', response);
          if (error) throw new Error('Unable to create a campaign');
          if (response) {
            // Gestion des réponses
            const newCampaign = {
              ...response,
              channelId: response.channel,
              campaignId: response._id,
            };
            commit('Campaign/SET_CAMPAIGNS', { [newCampaign.campaignId]: newCampaign }, { root: true });
            commit('Channel/ADD_CAMPAIGNS_TO_CHANNEL', { campaignIds: [newCampaign.campaignId], channelId: newCampaign.channelId }, { root: true });
            return newCampaign.campaignId;
          }
          return true;
        });
    },

    updateCampaign({ commit }, campaign) {
      return InfluenceProvider
        .updateCampaign(campaign)
        .then(({ error, response }) => {
          if (error) {
            console.warn('🚀 ~ file: TheInfluence.store.js ~ line 249 ~ .then ~ error', error);
            // Gestion des erreurs
          }
          if (response) {
            // Gestion des réponses
            commit('Campaign/SET_CAMPAIGNS', { [campaign.campaignId]: response }, { root: true });
          }
        })
        .catch((error) => {
          console.warn('🚀 ~ file: TheInfluence.store.js ~ line 284 ~ setStatus ~ error', error);
        });
    },

    endCampaign({ commit }, campaign) {
      return InfluenceProvider
        .endCampaign(campaign)
        .then(({ error, response }) => {
          if (error) {
            console.warn('🚀 ~ file: TheInfluence.store.js ~ line 249 ~ .then ~ error', error);
            // Gestion des erreurs
          }
          if (response) {
            // Gestion des réponses
            commit('Campaign/SET_CAMPAIGNS', { [campaign._id]: response }, { root: true });
          }
        })
        .catch((error) => {
          console.warn('🚀 ~ file: TheInfluence.store.js ~ line 284 ~ setStatus ~ error', error);
        });
    },

    setStatus({ commit }, activity) {
      return InfluenceProvider
        .setActivity(activity)
        .then(({ error, response }) => {
          if (error) {
            console.warn('🚀 ~ file: TheInfluence.store.js ~ line 305 ~ .then ~ error', error);
            // Gestion des erreurs
          }
          if (response) {
            // Gestion des réponses
            commit('Activity/SET_ACTIVITIES', { [activity.activityId]: activity }, { root: true });
          }
        })
        .catch((error) => {
          console.warn('🚀 ~ file: TheInfluence.store.js ~ line 284 ~ setStatus ~ error', error);
        });
    },

    setManyStatus({ commit }, { campaignId, channelId, activities }) {
      return InfluenceProvider
        .setActivities({ channelId, activities })
        .then(({ error, response }) => {
          if (error) {
            // Gestion des erreurs
            console.warn('🚀 ~ file: TheInfluence.store.js ~ line 259 ~ .setDelivereds ~ error', error);
            return { error };
          }
          const newActivities = response.reduce((acc, activity) => {
            acc[activity._id] = {
              ...activity,
              activityId: activity._id,
              contactId: activity.pk,
              channelId: activity.channel,
              campaignId,
            };
            return acc;
          }, {});

          commit('Activity/SET_ACTIVITIES', newActivities, { root: true });

          return { response };
        })
        .catch((error) => {
          console.log('🚀 ~ file: TheInfluence.store.js ~ line 518 ~ setDelivereds ~ error', error);
        });
    },

    setDelivereds({ commit }, { campaignId, channelId, activities }) {
      return InfluenceProvider
        .setActivities({ channelId, activities })
        .then(({ error, response }) => {
          if (error) {
            // Gestion des erreurs
            console.warn('🚀 ~ file: TheInfluence.store.js ~ line 259 ~ .setDelivereds ~ error', error);
            return { error };
          }
          const newActivities = response.reduce((acc, activity) => {
            acc[activity._id] = {
              ...activity,
              activityId: activity._id,
              contactId: activity.pk,
              channelId: activity.channel,
              campaignId,
            };
            return acc;
          }, {});

          commit('Activity/SET_ACTIVITIES', newActivities, { root: true });
          commit('Campaign/ADD_ACTIVITIES_TO_CAMPAIGN', { campaignId, activityIds: response.map((a) => (a._id)) }, { root: true });
          commit('Contact/SET_CONTACTS_HAS_ACTIVITIES_BIS', mapList({ list: response, key: 'pk', value: '_id' }), { root: true });

          return { response };
        })
        .catch((error) => {
          console.log('🚀 ~ file: TheInfluence.store.js ~ line 518 ~ setDelivereds ~ error', error);
        });
    },

    setUnscheduled({ commit }, { campaignId, channelId, activities }) {
      console.log('🚀 ~ file: TheInfluence.store.js ~ line 487 ~ setUnscheduled ~ activities', activities);
      return InfluenceProvider
        .setActivities({ channelId, activities })
        .then(({ error, response }) => {
          if (error) {
            // Gestion des erreurs
            console.warn('🚀 ~ file: TheInfluence.store.js ~ line 259 ~ .then ~ error', error);
            return { error };
          }
          const newActivities = response.reduce((acc, activity) => {
            acc[activity._id] = {
              ...activity,
              activityId: activity._id,
              contactId: activity.pk,
              channelId: activity.channel,
              campaignId,
            };
            return acc;
          }, {});

          const contactsHasCampaignsBis = response
            .reduce((acc, row) => {
              const { pk } = row;
              if (!acc[pk]) {
                acc[pk] = [campaignId];
                return acc;
              }
              acc[pk].push(campaignId);
              return acc;
            }, {});

          commit('Activity/SET_ACTIVITIES', newActivities, { root: true });
          commit('Campaign/ADD_ACTIVITIES_TO_CAMPAIGN', { campaignId, activityIds: response.map((a) => (a._id)) }, { root: true });
          commit('Contact/SET_CONTACTS_HAS_ACTIVITIES_BIS', mapList({ list: response, key: 'pk', value: '_id' }), { root: true });
          commit('Contact/SET_CONTACTS_HAS_CAMPAIGNS_BIS', contactsHasCampaignsBis, { root: true });

          return { response };
        })
        .catch((error) => {
          console.log('🚀 ~ file: TheInfluence.store.js ~ line 518 ~ setUnscheduledBulk ~ error', error);
        });
    },

    sendReviewMessage({ commit }, payload) {
      const {
        targets,
        message,
        channelId,
        campaignId,
      } = payload;

      const activityIds = targets.map(({ activityId }) => activityId);

      return InfluenceProvider
        .sendMessageByHook({
          channelId,
          message,
          activityIds,
          campaignId,
        })
        .then(({ response: responses, error }) => {
          if (error) {
            console.warn('🚀 ~ file: TheInfluence.store.js ~ line 683 ~ .then ~ error', error);
            return { error };
          }
          responses.forEach((response) => {
            if (response) {
              // Gestion des réponses
              commit('Activity/SET_ACTIVITIES', {
                [response._id]: {
                  ...response,
                  data: JSON.parse(response.data),
                  activityId: response._id,
                  contactId: response.pk,
                  channelId: response.channel,
                  campaignId: response.campaign,
                },
              }, { root: true });
            }
          });
          return { response: responses };
        })
        .catch((error) => {
          console.warn('🚀 ~ file: TheInfluence.store.js ~ line 298 ~ sendReviewMessage ~ error', error);
        });
    },

    deleteActivity({ commit }, payload) {
      const {
        activityId,
        channelId,
        contactId,
        campaignId,
      } = payload;

      return InfluenceProvider
        .deleteActivity({ channelId, activityId })
        .then(({ response, error }) => {
          if (error) return { error };
          commit('Campaign/REMOVE_ACTIVITY_TO_CAMPAIGN', { campaignId, activityId }, { root: true });
          commit('Contact/REMOVE_ACTIVITY_TO_CONTACT', { contactId, activityId }, { root: true });
          commit('Activity/REMOVE_ACTIVITY', activityId, { root: true });
          return { response };
        })
        .catch((error) => {
          console.warn('🚀 ~ file: TheInfluence.store.js ~ line 298 ~ deleteActivity ~ error', error);
        });
    },

    fetchMentions({ commit, rootGetters }, campaign) {
      const {
        'Channel/getterCurrentChannelId': channelId,
      } = rootGetters;
      const { name } = campaign;
      let globalStats;
      const payload = {
        withUsers: true,
        campaign_name: name,
      };
      const start = campaign.created ? moment(campaign.created) : moment();
      const end = campaign.ended ? moment(campaign.ended) : moment();
      const payloadStat = {
        minDate: start.toDate(),
        maxDate: end.toDate(),
      };
      const nbDaysCampaign = end.diff(start, 'days');
      let influencers = [];
      return Promise.resolve()
        .then(() => MentionsProvider.fetchAnalytics({ channelId, payload: payloadStat }))
        .then(({ response }) => {
          globalStats = response;
        })
        .then(() => InfluenceProvider.fetchMentions({ channelId, payload }))
        .then(({ response, error }) => {
          console.log('🚀 ~ file: TheInfluence.store.js ~ line 613 ~ .then ~ response', response);
          if (error) throw new Error('Unable to fetch mentions');

          const medias = [...mentionsFormater(response)];

          const nbDaysMentions = medias.reduce((acc, media) => {
            if (!acc.includes(media.date)) acc.push(media.date);
            return acc;
          }, []).length;

          const globalNewFollowers = (nbDaysMentions * globalStats?.new_followers) / nbDaysCampaign;

          influencers = medias.reduce((acc, media) => {
            const found = acc.find((m) => m.userPk === media.userPk);
            if (!found) {
              acc.push({
                userPosts: media.userPosts,
                userStories: media.userStories,
                userPk: media.userPk,
                username: media.username,
                engagement: media.engagement,
                language: media.language || '',
                followerCount: media.followers,
                storyMentionCount: media.userStories.length,
                nbDaysWithStories: media.userStories.reduce((a, m) => {
                  if (!a.includes(m.date)) a.push(m.date);
                  return a;
                }, []).length,
                postMentionCount: media.userPosts.length,
                mentionsUser: [...found?.mentionsUser || [], media],
                mention: true,
              });
            } else {
              found.mentionsUser.push(media);
            }
            return acc;
          }, []).map((user) => ({
            ...user,
            averagePostEngagement: (user.userPosts.reduce((sum, media) => {
              if (!media.followers) return sum;
              const likeCount = media.like_count || 0;
              const commentsCount = media.comments_count || 0;
              const engagement = (likeCount + commentsCount) / media.followers;
              return sum + engagement;
            }, 0) / user.postMentionCount) * 100,
            engagementCleaned: (user.engagement < 0.5) || !user.engagement ? 1 : user.engagement,
          })).map((user) => ({
            ...user,
            averageReach: (user.engagementCleaned / 100) * user.followerCount * 4,
            impressions: (user.engagementCleaned / 100) * user.followerCount
             * 5 * user.mentionsUser.length,
          }));

          const globalMentionsImpressions = influencers.reduce((sum, user) => {
            if (!user.impressions) return sum;
            return sum + user.impressions;
          }, 0);

          influencers = influencers.map((user) => ({
            ...user,
            newFollowers: Math.round(
              (user.impressions * globalNewFollowers) / globalMentionsImpressions,
            ),
          }));
          commit('SET_MENTIONS', medias);
          return influencers.map((influencer) => influencer.userPk);
        })
        .then((pks) => this.dispatch('Contact/fetchMissingListContacts', { pks }))
        .then(() => commit('SET_INFLUENCERS', influencers))
        .catch((error) => {
          console.log('🚀 ~ file: TheInfluence.store.js ~ line 617 ~ fetchMentions ~ error', error);
          throw new Error('Unable to fetch mentions');
        });
    },

    fetchAnalytics({ rootGetters }, { dateRange }) {
      const {
        'Channel/getterCurrentChannelId': channelId,
      } = rootGetters;
      // date range = today/ended campaign to created campaign
      const payload = {
        minDate: moment(dateRange[0]).format(),
        maxDate: moment(dateRange[1]).format(),
      };

      return MentionsProvider
        .fetchAnalytics({ channelId, payload })
        .then((response) => response);
    },

    fetchTikTokMediasOrders({ rootGetters }, campaignId) {
      const {
        'Channel/getterCurrentChannelId': channelId,
      } = rootGetters;

      return Promise.resolve()
        .then(() => InfluenceProvider.getTikTokMediasOrdersByCampaign({ channelId, campaignId }))
        .then(({ response, error }) => {
          if (error) throw new Error('Unable to fetch TikTok medias orders');
          console.log('🚀 ~ file: TheInfluence.store.js ~ line 887 ~ .then ~ response', response);
          if (response) {
            const orders = response.map((order) => ({
              medias: order.lists.reduce((acc, segment) => {
                if (segment.medias) return [...acc, ...segment.medias];
                return acc;
              }, []),
              users: order.lists.reduce((acc, segment) => {
                if (segment.user) return [...acc, ...segment.user];
                return acc;
              }, []),
            }));

            const users = orders.reduce((acc, segment) => {
              if (segment.users) return [...acc, ...segment.users];
              return acc;
            }, []);

            const medias = orders.reduce((acc, segment) => {
              if (segment.medias) return [...acc, ...segment.medias];
              return acc;
            }, []).map((m) => ({
              id: m.id,
              caption: m.desc,
              mediaUrl: `https://io.getubu.com/tiktokMedia/${m.id}`,
              mediaType: 'VIDEO',
              username: m.author.uniqueId,
              userPk: m.author.id,
              likeCount: m.stats.diggCount,
              commentCount: m.stats.commentCount,
              followerCount: m.authorStats.followerCount,
              profilePicUrl: m.author.avatarThumb,
              timestamp: m.createTime * 1000,
              date: moment(m.createTime * 1000).format('YYYY-MM-DD'),
              views: m.stats.playCount,
              reach: m.stats.playCount,
              source: 'tiktok',
              platform: 'TikTok',
              skeletonHeight: (m.video.height / m.video.width) * 220,
              user: users.find((user) => m.author.id === user.id),
            })).map((m) => ({
              ...m,
              postEngagement: ((m.likeCount + m.commentCount) / m.views) * 100,
              interactions: m.likeCount + m.commentCount,
            }));

            const influencers = medias.reduce((acc, media) => {
              const found = acc.find((m) => m.userPk === media.userPk);
              if (!found) {
                acc.push({
                  userPk: media.userPk,
                  username: media.username,
                  followerCount: media.followerCount,
                  platform: media.platform,
                  source: media.source,
                  views: media.views,
                  locations: [...found?.locations || [], media.location],
                  medias: [...found?.medias || [], media],
                  language: media.language || '',
                  engagement: media.user?.engagement,
                  videoCount: media.user?.videoCount,
                  age: media.user?.age,
                });
              } else {
                found.medias.push(media);
                found.locations = [...new Set([...found.locations, media.location])];
                found.views += media.views;
              }
              return acc;
            }, []).map((user) => ({
              ...user,
              nbPosts: user.medias.length,
              views: user.medias.reduce(
                (sum, media) => sum + media.views, 0,
              ),
              averagePostEngagement: user.medias.reduce(
                (sum, media) => sum + media.postEngagement, 0,
              ) / user.medias.length,
              averagePostLikes: Math.floor(user.medias.reduce(
                (sum, media) => sum + media.likeCount, 0,
              ) / user.medias.length),
              averagePostComments: Math.floor(user.medias.reduce(
                (sum, media) => sum + media.commentCount, 0,
              ) / user.medias.length),
              averageReach: Math.floor(user.medias.reduce(
                (sum, media) => sum + media.reach, 0,
              ) / user.medias.length),
            }));
            return {
              medias,
              influencers,
            };
          }
          throw new Error('Unable to fetch TikTok medias orders');
        });
    },

    addTikTokMediasOrder({ rootGetters }, { campaign, keywords, usernames }) {
      const {
        'Channel/getterCurrentChannelId': channelId,
      } = rootGetters;

      const payload = usernames.map((name) => ({
        name,
        keywords,
        campaign,
      }));

      return Promise.resolve()
        .then(() => InfluenceProvider.addTikTokMediasOrder({ channelId, payload }))
        .then(({ response, error }) => {
          if (error) throw new Error('Unable to create a TikTok order');
          if (response) return undefined;
          return undefined;
        });
    },
  },

};
