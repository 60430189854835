import builder from '../../$utils/axiosMedia';

export default class MediaProvider {
  static getMedias({ payload: { threadId } }) {
    // console.log('🚀 ~ file: global.provider.js ~ line 95 ~ ChannelProvider ~ getDisplayablesFromContact ~ threadId', threadId);

    return builder({
      method: 'get',
      url: `/private/${threadId}/`,
    });
  }

  static isDriveActive({ payload: { channelId } }) {
    // console.log('🚀 ~ file: media.provider.js ~ line 14 ~ MediaProvider ~ isDriveActive ~ channelId', channelId);
    return builder({
      method: 'get',
      url: `/channel/${channelId}/isDriveActive`,
    });
  }

  static getDriveAuthUrl({ payload: { channelId } }) {
    // console.log('🚀 ~ file: media.provider.js ~ line 23 ~ MediaProvider ~ getDriveAuthUrl ~ channelId', channelId);
    return builder({
      method: 'get',
      url: `/channel/${channelId}/oauth_url`,
    });
  }
}
