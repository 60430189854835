<template>
  <div
    id="UbuCommentThreadCard"
    :class="['ubuThreadCard', {'active': isThreadSelected}]"
  >
    <div
      class="card-avatar"
      @click="$emit('onBulkSelect', $event)"
    >
      <div
        :class="{
          'card-avatar-img': true,
          'is-square': comment.isPost,
        }"
      >
        <img
          v-if="isBulkSelected"
          src="@/assets/pictos/is_ok.svg"
        >
        <template v-else>
          <UbuMedia
            v-if="commentPictureUrl"
            :key="commentPictureUrl"
            style="width: 100%"
            :media-url="commentPictureUrl"
            :media-type="commentPictureUrl && comment.from && comment.from.isVideo ? 'VIDEO' : 'IMAGE'"
            ratio="1by1"
            src-fallback="https://upload.wikimedia.org/wikipedia/commons/5/59/Empty.png"
          />
          <div
            v-else
            class="card-avatar-unfoundable"
          >
            <UbuIcon
              name="placeholder"
              filled
            />
          </div>
        </template>
      </div>
    </div>

    <div
      class="card-text-box"
      @click="$emit('onSelectCard', $event)"
    >
      <div
        v-if="userName"
        class="ubu-text1"
      >
        {{ userName }}
      </div>
      <div
        v-else
        :style="'opacity: .4'"
        class="ubu-text1"
      >
        Missing name*
      </div>
      <div class="ubu-text2">
        {{ comment.text }}
      </div>
    </div>

    <div class="card-items-wrapper">
      <span class="card-items-date">{{ getParsedDate }}</span>

      <div class="card-items-box">
        <!-- <div class="card-items">
          <span style="color: #FF62A4">{{ comment.like_count }}</span>
          <UbuIcon
            class="card-items-icon"
            name="heart"
            filled
            color="12"
          />
        </div> -->
        <div class="card-items">
          <span style="color: #3CA6F2">{{ comment.children ? comment.children.length : 0 }}</span>
          <UbuIcon
            class="card-items-icon"
            name="replies"
            filled
            color="8"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { timeToNow } from '../../$utils/dates';
import UbuMedia from '../../ui/lib/UbuMedia.vue';

export default {
    name: "UbuCommentThreadCard",
    props: {
        comment: {
            type: Object,
            required: true,
        },
        isThreadSelected: {
            type: Boolean,
            default: false,
        },
        isBulkSelected: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        getParsedDate() { return timeToNow(this.comment.createdAt); },
        commentPictureUrl() {
            return (this.comment.from && this.comment.from.profilePicture) || null;
        },
        userName() {
            return ((this.comment.from && (this.comment.from.fullName || this.comment.from.username)) || null);
        },
    },
    components: { UbuMedia }
};
</script>
