import { SnackbarProgrammatic as Snackbar } from 'buefy';
import { yaerApi } from '@/addons/$providers';
import { instagramCommentMapper, facebookCommentMapper } from '@/addons/$utils/mappers/comment';
import { facebookPostMapper } from '@/addons/$utils/mappers/post';
import { snackError } from '../../$catalog/snackbar';
import { arrayMapper } from '../../$utils/prototypes';
import { updateDatasIfExists, updateRelationsIfExists } from '../$utils/dataMapper';
import internals from './internals';

/* eslint-disable max-len */
export default {
  namespaced: true,

  state: {
    comments: {},
    commentsHasCommentsParents: [],
  },

  getters: {
    getterComments: (state) => state.comments,
    getterCommentsHasCommentsParents: (state) => state.commentsHasCommentsParents,
  },

  actions: {
    fetchCommentsDBByChannel({ commit, rootGetters }, media) {
      // console.log('Media source', media, media.source, yaerApi);
      const { 'Channel/getterFbChannel': fbChannel } = rootGetters;
      // console.log('Fb channel');
      return Promise.resolve()
        .then(() => yaerApi.api.comments[media.source]({ payload: { channelId: media.channelId, graphId: media.graphId } }))
        .then(({ result }) => {
          // console.log('Result fetching is', result);
          const comments = result
            .map((item) => (media.source === 'facebook' ? facebookCommentMapper(item) : instagramCommentMapper(item)))
            .map(({ correspondent, ...c }) => ({
              ...c, mediaFBId: c.graphId,
            }));
          const openParentCommentCount = comments.reduce((acc, row) => {
            // eslint-disable-next-line no-param-reassign
            if (!row.parentId && !row.closed) acc += 1;
            return acc;
          }, 0);

          commit('Media/UPDATE_MEDIA_OPEN_COUNT', { mediaFBId: media.mediaFBId, newCount: openParentCommentCount }, { root: true });
          // console.log('🚀 ~ file: Comment.store.js ~ line 34 ~ openParentCommentCount ~ openParentCommentCount', openParentCommentCount);

          // console.log('🚀 ~ file: Media.store.js ~ line 31 ~ .then ~ comments', comments);

          const mappedComments = arrayMapper(comments, 'commentId');
          // console.log('🚀 ~ file: Media.store.js ~ line 34 ~ .then ~ mappedComments', mappedComments);

          commit('SET_COMMENTS', mappedComments);

          const mediasFBHasComments = Object.values(mappedComments).map(({ commentId }) => ({ commentId, mediaFBId: media.mediaFBId }));
          commit('Media/SET_MEDIASFB_HAS_COMMENTS', mediasFBHasComments, { root: true });

          const commentsHasCommentsParents = Object.values(mappedComments).map(({ commentId, parentId }) => ({ commentId, parentId })).filter(({ parentId }) => parentId);
          // console.log('🚀 ~ file: Media.store.js ~ line 86 ~ .then ~ commentsHasCommentsParents', commentsHasCommentsParents);
          commit('SET_COMMENTS_HAS_COMMENTS_PARENTS', commentsHasCommentsParents);
        });
    },
    fetchCommentsInstaByChannel({ commit }, { channelId, mediaFBId }) {
      return Promise.resolve()
        .then(() => internals.fetchCommentsInsta({ payload: { channelId, mediaFBId } }))
        .then((responses) => {
          // console.log('🚀 ~ file: Comment.store.js ~ line 49 ~ .then ~ responses', responses);

          const { response, error } = responses;
          if (error) {
            return;
          }
          const comments = response.map(({ correspondent, ...c }) => ({
            ...c, commentId: c.id, parentId: c.parent_id, mediaFBId: c.media_id,
          }))
            .map(instagramCommentMapper);

          // console.log('🚀 ~ file: Media.store.js ~ line 31 ~ .then ~ comments', comments);

          const mappedComments = arrayMapper(comments, 'commentId');
          // console.log('🚀 ~ file: Media.store.js ~ line 34 ~ .then ~ mappedComments', mappedComments);

          commit('SET_COMMENTS', mappedComments);

          const mediasFBHasComments = Object.values(mappedComments).map(({ commentId }) => ({ commentId, mediaFBId }));
          commit('Media/SET_MEDIASFB_HAS_COMMENTS', mediasFBHasComments, { root: true });

          const commentsHasCommentsParents = Object.values(mappedComments).map(({ commentId, parentId }) => ({ commentId, parentId })).filter(({ parentId }) => parentId);
          // console.log('🚀 ~ file: Media.store.js ~ line 86 ~ .then ~ commentsHasCommentsParents', commentsHasCommentsParents);
          commit('SET_COMMENTS_HAS_COMMENTS_PARENTS', commentsHasCommentsParents);
        });
    },
    fetchCommentsFacebookByChannel({ commit }, { channelId, mediaFBId }) {
      return Promise.resolve()
        .then(() => yaerApi.api.comments.refreshFacebook({ payload: { channelId, graphId: mediaFBId } }))
        .then(({ success, result }) => {
          if (!success) {
            return;
          }
          const { media, comments } = result;
          const _comments = comments.map((c) => ({
            ...c, commentId: c.id, parentId: c.parent_id, mediaFBId: c.media_id,
          }))
            .map(facebookCommentMapper);

          const post = facebookPostMapper({ ...media, channelId, mediaFBId: media.id });
          console.log('Media new is', post);
          commit('Media/SET_MEDIAS_FB', { [post.graphId]: post }, { root: true });
          const mappedComments = arrayMapper(_comments, 'commentId');
          commit('SET_COMMENTS', mappedComments);

          const mediasFBHasComments = Object.values(mappedComments).map(({ commentId }) => ({ commentId, mediaFBId }));
          commit('Media/SET_MEDIASFB_HAS_COMMENTS', mediasFBHasComments, { root: true });

          const commentsHasCommentsParents = Object.values(mappedComments).map(({ commentId, parentId }) => ({ commentId, parentId })).filter(({ parentId }) => parentId);
          // console.log('🚀 ~ file: Media.store.js ~ line 86 ~ .then ~ commentsHasCommentsParents', commentsHasCommentsParents);
          commit('SET_COMMENTS_HAS_COMMENTS_PARENTS', commentsHasCommentsParents);
        });
    },
    SOCKET_newComment({ commit }, { comments }) {
      // console.log('🚀 ~ file: Comment.store.js ~ line 86 ~ SOCKET_newComment ~ comments', comments);
      const comments2 = comments
        .map(({ correspondent, ...c }) => ({
          ...c, commentId: c.id, parentId: c.parent_id, mediaFBId: c.media_id,
        }))
        .map(instagramCommentMapper);
      console.log('Socket comment engagement', comments2);
      commit('SET_COMMENTS', comments2);
      const mediasFBHasComments = Object.values(comments2).map(({ commentId, mediaFBId }) => ({ commentId, mediaFBId }));
      commit('Media/SET_MEDIASFB_HAS_COMMENTS', mediasFBHasComments, { root: true });
      commit('Media/INC_OPENED_COUNT', mediasFBHasComments.map(({ mediaFBId }) => mediaFBId), { root: true });
    },
    SOCKET_newCommentFb({ commit }, { comments }) {
      // console.log('🚀 ~ file: Comment.store.js ~ line 86 ~ SOCKET_newComment ~ comments', comments);
      const comments2 = comments.map(({ correspondent, ...c }) => ({
        ...c, commentId: c.id, parentId: c.parent_id, mediaFBId: c.media_id,
      }))
        .map(facebookCommentMapper);
      console.log('Socket comment handling', comments2);
      commit('SET_COMMENTS', comments2);
      const mediasFBHasComments = Object.values(comments2).map(({ commentId, mediaFBId }) => ({ commentId, mediaFBId }));
      commit('Media/SET_MEDIASFB_HAS_COMMENTS', mediasFBHasComments, { root: true });
      commit('Media/INC_OPENED_COUNT', mediasFBHasComments.map(({ mediaFBId }) => mediaFBId), { root: true });
    },
    SOCKET_deleteComment({ commit }, { commentId }) {
      // console.log('🚀 ~ file: Comment.store.js ~ line 95 ~ SOCKET_deleteComment ~ commentId', commentId);
      commit('UNSET_COMMENTS', commentId);
      commit('Media/UNSET_MEDIASFB_HAS_COMMENTS', commentId, { root: true });
    },
    SOCKET_sendCommentError({ commit, rootGetters }, { commentId, parentId }) {
      const {
        'Comment/getterComments': comments,
      } = rootGetters;
      const commentList = Object.values(comments);
      const commentError = commentList.find((c) => String(c.commentId) === String(commentId));

      const updatedComment = {
        [commentId]: {
          ...commentError,
          isError: true,
          isPending: false,
          // item_type: 'error',
        },
      };

      if (commentError) {
        const parentComment = commentList.find((c) => c.commentId === parentId);
        const updateParentComment = {
          [parentComment.commentId]: {
            ...parentComment,
            closed: false,
          },
        };
        commit('SET_COMMENTS', { ...updatedComment, ...updateParentComment });

        commentList.push(updatedComment, updateParentComment);

        // api take 3sec to update open/close state
        setTimeout(() => {
          const openParentCommentCount = commentList.reduce((acc, row) => {
            // eslint-disable-next-line no-param-reassign
            if (!row.parentId && !row.closed) acc += 1;
            return acc;
          }, 0);

          commit('Media/UPDATE_MEDIA_OPEN_COUNT', { mediaFBId: parentComment.mediaFBId, newCount: openParentCommentCount }, { root: true });
        }, 3500);
      }
      Snackbar.open({
        ...snackError,
        actionText: null,
        duration: 5000,
        message: 'The comment you tried to answer is deleted or you account is not well connected (try on another comment to know)',
      });
    },
    SOCKET_toggleCloseComments({ commit }, { commentIds, closed, media }) {
      commit('CLOSE_COMMENTS', { commentIds, closed });
      const { id, openParentCommentsCount } = media;
      commit('Media/UPDATE_MEDIA_OPEN_COUNT', { mediaFBId: id, newCount: openParentCommentsCount }, { root: true });
    },
  },
  mutations: {
    SET_COMMENTS(state, newDatas) { state.comments = updateDatasIfExists({ newDatas, actualDatas: state.comments, key: 'commentId' }); },
    UNSET_COMMENTS(state, commentId) { delete state.comments[commentId]; },
    SET_COMMENTS_HAS_COMMENTS_PARENTS(state, newDatas) { state.commentsHasCommentsParents = updateRelationsIfExists(state.commentsHasCommentsParents, newDatas); },
    CLOSE_COMMENTS(state, { commentIds, closed }) {
      state.comments = {
        ...state.comments,
        ...commentIds.reduce((acc, id) => {
          acc[id] = {
            ...state.comments[id],
            closed,
          };
          return acc;
        }, {}),
      };
    },
    CLOSE_ALL_COMMENTS(state, { mediaId }) {
      Object.values(state.comments)
        .filter((comment) => {
          return comment.postId === mediaId;
        })
        .map((comment) => {
          return { ...comment, closed: true };
        }).forEach((comment) => {
          state.comments[comment.commentId] = comment;
        });
    },
    RESET_STORES(state) {
      state.comments = {};
      state.commentsHasCommentsParents = [];
    },
  },
};
