var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"UbuCRMTable"}},[_c('b-table',{staticClass:"list-table",attrs:{"data":_vm.listFiltered,"default-sort":['username', 'asc'],"loading":_vm.loading,"paginated":"","sticky-header":true,"per-page":"30","pagination-rounded":"","hoverable":"","pagination-size":"is-small","checked-rows":_vm.checkedRows,"checkable":"","is-row-checkable":function (row) { return row.source === 'instagram'; },"opened-detailed":_vm.openedDetails,"detailed":true,"show-detail-icon":true,"has-detailed-visible":function (row) { return row.source === 'instagram'; },"detail-key":"userPk"},on:{"check":function($event){return _vm.$emit('checkRows', $event)},"check-all":function($event){return _vm.$emit('checkRows', $event)}},scopedSlots:_vm._u([{key:"detail",fn:function(ref){
var row = ref.row;
return [_c('UbuCRMTableDetail',{attrs:{"row":row,"thread-groups-list":_vm.threadGroupsList,"contact-groups-list":_vm.contactGroupsList,"campaign-list":_vm.campaignList,"is-generate-report-loading":_vm.isGenerateReportLoading,"is-statistics-loading":_vm.isStatisticsLoading,"show-new-message-button":""},on:{"addCustom":function($event){return _vm.$emit('addCustom', $event)},"removeCustom":function($event){return _vm.$emit('removeCustom', $event)},"generateReport":function($event){return _vm.$emit('generateReport', row)},"updateContact":function($event){return _vm.$emit('updateContact', $event)},"createThreadGroupAndAssign":function($event){return _vm.$emit('createThreadGroupAndAssign', Object.assign({}, {thread: row.thread}, $event, {username: row.username}))},"createContactGroupAndAssign":function($event){return _vm.$emit('createContactGroupAndAssign',Object.assign({}, {thread: {
            contactId: row.contactId, channelId: row.channelId
          }}, $event, {username: row.username}))},"openNewMessage":function($event){return _vm.$emit('openNewMessage', $event)},"fetchTiktokUser":function($event){return _vm.$emit('fetchTiktokUser', $event)},"openModalEditUsernameTiktok":function($event){return _vm.$emit('openModalEditUsernameTiktok', $event)}}})]}}])},[_c('b-table-column',{attrs:{"label":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('UbuButton',{attrs:{"secondary":"","center":""},on:{"click":function($event){return _vm.$emit('showHashtag', row.username)}}},[_vm._v(" show ")])]}}])}),_c('b-table-column',{attrs:{"field":"username","label":"Username","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('div',{staticClass:"username-table"},[_vm._v(" "+_vm._s(row.username)+" ")])]}}])}),_c('b-table-column',{attrs:{"field":"platform","label":"Platform","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var platform = ref.row.platform;
return [_c('span',[_vm._v(_vm._s(platform || '-'))])]}}])}),_c('b-table-column',{attrs:{"field":"followerCount","label":"Followers","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var followerCount = ref.row.followerCount;
return [(followerCount)?_c('span',[_vm._v(_vm._s(followerCount.toLocaleString('en'))+" ")]):_c('span',[_vm._v("-")])]}}])}),_c('b-table-column',{attrs:{"field":"views","label":"Views","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var views = ref.row.views;
return [(views)?_c('span',[_vm._v(_vm._s(views.toLocaleString('en')))]):_c('span',[_vm._v("-")])]}}])}),_c('b-table-column',{attrs:{"field":"contactGroup","label":"Labels","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var contactGroup = ref.row.contactGroup;
return [(contactGroup.length)?_c('UbuCRMTableCustomCell',{attrs:{"groups":contactGroup,"icon-name":"contact"}}):_c('span',[_vm._v(" - ")])]}}])}),_c('b-table-column',{attrs:{"field":"engagement","label":"Engagement","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var engagement = ref.row.engagement;
return [(engagement && Number(engagement) >= 0.5)?_c('span',[_vm._v(" "+_vm._s(Number(engagement).toFixed(2))+"% ")]):_c('span',[_vm._v("-")])]}}])}),_c('b-table-column',{attrs:{"field":"averagePostEngagement","label":"Average Post Engagement","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var averagePostEngagement = ref.row.averagePostEngagement;
return [(averagePostEngagement)?_c('span',[_vm._v(" "+_vm._s(Number(averagePostEngagement).toFixed(2))+"% ")]):_c('span',[_vm._v("-")])]}}])}),_c('b-table-column',{attrs:{"field":"averagePostLikes","label":"Average Post Likes","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var averagePostLikes = ref.row.averagePostLikes;
return [(averagePostLikes)?_c('span',[_vm._v(" "+_vm._s(averagePostLikes.toLocaleString('en'))+" ")]):_c('span',[_vm._v("-")])]}}])}),_c('b-table-column',{attrs:{"field":"averagePostComments","label":"Average Post Comments","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var averagePostComments = ref.row.averagePostComments;
return [(averagePostComments)?_c('span',[_vm._v(" "+_vm._s(averagePostComments.toLocaleString('en'))+" ")]):_c('span',[_vm._v("-")])]}}])}),_c('b-table-column',{attrs:{"field":"averageReach","label":"Average Reach","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var averageReach = ref.row.averageReach;
return [(averageReach)?_c('span',[_vm._v(" "+_vm._s(averageReach.toLocaleString('en'))+" ")]):_c('span',[_vm._v("-")])]}}])}),_c('b-table-column',{attrs:{"field":"language","label":"Language","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var language = ref.row.language;
return [_vm._v(" "+_vm._s(language ? language : '-')+" ")]}}])}),_c('b-table-column',{attrs:{"field":"locations","label":"Locations","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var locations = ref.row.locations;
return [(locations[0])?_c('div',{staticClass:"ellipsis",staticStyle:{"max-width":"200px"}},_vm._l((locations),function(location,index){return _c('span',{key:index},[_vm._v(_vm._s(location))])}),0):_c('span',[_vm._v("-")])]}}])}),_c('b-table-column',{attrs:{"field":"nbPosts","label":"Number of posts with this hashtag","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var nbPosts = ref.row.nbPosts;
return [(nbPosts)?_c('span',[_vm._v(_vm._s(nbPosts.toLocaleString('en')))]):_c('span',[_vm._v("-")])]}}])}),_c('b-table-column',{attrs:{"field":"lastMentionOrTagPost","label":"Last Mention","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var lastMentionOrTagPost = ref.row.lastMentionOrTagPost;
return [_vm._v(" "+_vm._s(_vm.parseTimestamp(lastMentionOrTagPost) || '-')+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }