export default {
  namespaced: true,

  state: {
    socketInstance: null,
    messages: [],
  },

  getters: {
    getterSocketInstance: (state) => state.socketInstance,
    getterMessages: (state) => state.messages,
  },

  actions: {
    UP_socketInstance({ commit }, socket) {
      // console.log('🚀 ~ file: TheInfluence.store.js ~ line 260 ~ UP_socketInstance ~ threadId', threadId);
      commit('SET_SOCKET_INSTANCE', socket);
    },
    newMessage({ commit }, message) {
      console.log('🚀 ~ file: Socket.store.js ~ line 19 ~ newMessage ~ message', message);
      commit('SET_NEW_MESSAGE', message);
    },
  },
  mutations: {
    SET_SOCKET_INSTANCE: (state, socket) => { state.socketInstance = socket; },
    SET_NEW_MESSAGE: (state, message) => { state.messages.push(message); },
  },
};
