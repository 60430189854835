<template>
  <div
    v-if="mediaType === 'VIDEO'"
    style="max-width: 100%; overflow: hidden;"
  >
    <video
      ref="video"
      autoplay
      loop
      muted
      style="max-height: 100%; max-width: 100%"
      :poster="$attrs['src-fallback']"
    >
      <source
        @error="onVideoError"
        :src="mediaUrl"
        :type="'video/mp4'"
      >
      <img :src="$attrs['src-fallback']">
    </video>
  </div>
  <b-image
    v-else
    :src="mediaUrl"
    v-bind="$attrs"
    @error="$emit('error', $event)"
  />
  <!-- <UbuCommonVideo /> -->
</template>

<script>
export default {
  name: 'UbuMedia',
  props: {
    mediaUrl: {
      type: String,
      default: null,
    },
    mediaType: {
      type: String,
      default: null,
    },
  },
  methods: {
    onVideoError($event) {
      console.log('Error video');
      this.$emit('error', $event);
    },
  },
};
</script>

<style>

</style>