<template>
  <UbuCommonMessageCard
    :is-me="isMe"
    :date="date"
    @deleteMessage="$emit('deleteMessage')"
  >
    <template v-slot:content>
      <UbuContactCard
        :contact="{
          profile_pic_url: profilePicUrl,
          full_name: fullName,
          username: username,
        }"
      />
    </template>
  </UbuCommonMessageCard>
</template>

<script>
export default {
  name: 'UbuCommonProfileShare',
  props: {
    date: {
      type: String,
      required: true,
    },
    isMe: {
      type: Boolean,
      default: false,
    },
    username: {
      type: String,
      required: true,
    },
    profilePicUrl: {
      type: String,
      required: true,
    },
    fullName: {
      type: String,
      default: 'Undefined name',
    },
  },
};
</script>
