/* eslint-disable max-len */
import query from '../../dataStores/stores/query';
import CRMProvider from '../providers/crm.provider';
import { arrayMapper } from '../../$utils/prototypes';

export default {
  namespaced: true,

  state: {
    customCRMColumns: [],
  },

  getters: {
    getterCustomCRMColumns: (state) => state.customCRMColumns,
    getterChannelContacts: (_state, _getters, _rootState, rootGetters) => {
      const { 'Sequence/getterFetchDone': fetchDone } = rootGetters;

      if (!fetchDone) return { contacts: [] };

      const {
        'Channel/getterCurrentChannelId': channelId,
        'Channel/getterChannels': channels,
        'Channel/getterChannelsHasContactsBis': channelsHasContactsBis,
        'Activity/getterActivities': activities,
        'Campaign/getterCampaigns': campaigns,
        'Contact/getterContacts': contacts,
        'Contact/getterContactsHasActivitiesBis': contactsHasActivitiesBis,
        'Contact/getterContactsHasContactGroupsBis': contactsHasContactGroupBis,
        'Contact/getterContactsHasThreadsBis': contactsHasThreadsBis,
        'Contact/getterContactsHasCampaignsBis': contactsHasCampaignsBis,
        'ContactGroup/getterContactGroups': contactGroups,
        'Thread/getterThreadsHasThreadGroupsBis': threadsHasThreadGroupBis,
        'ThreadGroup/getterThreadGroups': threadGroups,
        'Thread/getterThreads': threads,

        'Contact/getterContactsHasDiscountCodesBis': contactsHasDiscountCodesBis,
        'Contact/getterContactsHasReferralLinksBis': contactsHasReferralLinksBis,
        'Shopify/DiscountCode/getterDiscountCode': discountCode,
        'Shopify/ReferralLink/getterReferralLink': referralLink,
        'Shopify/DiscountCode/getterDiscountCodeHasOrderBis': discountCodeHasOrderBis,
        'Shopify/ReferralLink/getterReferralLinkHasOrderBis': referralLinkHasOrderBis,
        'Shopify/Order/getterOrders': order,
      } = rootGetters;

      const response = {
        ...channels[channelId], // check if is it usefull or not
        contacts: query.findObjectLite({
          model: 'contacts',
          relationTable: channelsHasContactsBis,
          dataTable: contacts,
          fromId: channelId,
        })
          .map((contact) => ({
            ...contact,
            thread: query.findObjectLite({
              model: 'activities',
              relationTable: contactsHasThreadsBis,
              dataTable: threads,
              fromId: contact.contactId,
            })
              .reduce((acc, thread) => (thread || {}), {}),
            mentions: contact.mentionCount || [],
            tagsPosts: contact.tagCount || [],
            contactGroup: query.findObjectLite({
              model: 'contactGroups',
              relationTable: contactsHasContactGroupBis,
              dataTable: contactGroups,
              fromId: contact.contactId,
            }),
            campaigns: query.findObjectLite({
              model: 'campaign',
              relationTable: contactsHasCampaignsBis,
              dataTable: campaigns,
              fromId: contact.contactId,
            }),
            threadGroup: (contactsHasThreadsBis[contact.contactId] || [])
              .flatMap((threadId) => query.findObjectLite({
                model: 'threadGroups',
                relationTable: threadsHasThreadGroupBis,
                dataTable: threadGroups,
                fromId: threadId,
              })),

            activities: query.findObjectLite({
              model: 'activities',
              relationTable: contactsHasActivitiesBis,
              dataTable: activities,
              fromId: contact.contactId,
            })
              .map((activity) => ({
                ...activity,
                campaignDetail: campaigns[activity.campaignId],
              })),
            discountCodes: query.findObjectLite({
              model: 'discountCode',
              relationTable: contactsHasDiscountCodesBis,
              dataTable: discountCode,
              fromId: contact.contactId,
            })
              .map((dc) => ({
                ...dc,
                order: query.findObjectLite({
                  model: 'order',
                  relationTable: discountCodeHasOrderBis,
                  dataTable: order,
                  fromId: dc.discountCodeDcid,
                }),
                campaign: dc.campaignId ? campaigns[dc.campaignId] : null,
              })),
            referralLinks: query.findObjectLite({
              model: 'referralLink',
              relationTable: contactsHasReferralLinksBis,
              dataTable: referralLink,
              fromId: contact.contactId,
            })
              .map((rl) => ({
                ...rl,
                order: query.findObjectLite({
                  model: 'order',
                  relationTable: referralLinkHasOrderBis,
                  dataTable: order,
                  fromId: rl.referralLinkRlid,
                }),
                campaign: rl.campaignId ? campaigns[rl.campaignId] : null,
              })),
          })),
      };

      return response;
    },
    getterLists: (_state, _getters, _rootState, rootGetters) => {
      const { 'Sequence/getterFetchDone': fetchDone } = rootGetters;

      if (!fetchDone) return [];

      const {
        'Channel/getterCurrentChannelId': channelId,
        'Channel/getterChannelsHasLists': getterChannelsHasLists,
        'List/getterLists': lists,
      } = rootGetters;

      const response = query.findObjectLite({
        model: 'lists',
        relationTable: getterChannelsHasLists,
        dataTable: lists,
        fromId: channelId,
      }).map((list) => ({
        ...list,
        userPks: list.lists.reduce((acc, segment) => {
          if (segment.pks) return [...acc, ...segment.pks];
          return acc;
        }, []),
        usernames: list.lists.reduce((acc, segment) => {
          if (segment.usernames) return [...acc, ...segment.usernames];
          return acc;
        }, []),
      }));
      console.log('🚀 ~ file: TheCRM.store.js ~ line 158 ~ response', response);

      return response;
    },
  },
  actions: {
    searchUser(_, { channelId, username }) {
      return CRMProvider
        .searchUser({ channelId, username })
        .then(({ response }) => {
          if (response && response.users) return response.users;
          return [];
        });
    },
    removeUserFromList({ commit, rootGetters }, { list, pks }) {
      const {
        'Channel/getterCurrentChannelId': channelId,
      } = rootGetters;

      return CRMProvider
        .removeUserFromList({ channelId, listId: list.listId, pks })
        .then(({ error, response }) => {
          if (error) throw new Error('Unable to removeUserFromList');
          if (response) {
            console.log('🚀 ~ file: TheCRM.store.js ~ line 130 ~ .then ~ response', response);
            const updatedSegments = list.lists.map((segment) => ({ ...segment, pks: segment.pks.filter((pk) => !pks.includes(pk)) }));
            const updatedList = { ...list, lists: updatedSegments };
            commit('List/SET_LISTS', { [list.listId]: updatedList }, { root: true });
          }
          return true;
        });
    },
    removeUserFromCustomList({ commit, rootGetters }, { list, usernames }) {
      const {
        'Channel/getterCurrentChannelId': channelId,
      } = rootGetters;

      return CRMProvider
        .removeUserFromCustomList({ channelId, listId: list.listId, usernames })
        .then(({ error, response }) => {
          if (error) throw new Error('Unable to removeUserFromList');
          if (response) {
            console.log('🚀 ~ file: TheCRM.store.js ~ line 130 ~ .then ~ response', response);
            const updatedSegments = list.lists.map((segment) => ({ ...segment, usernames: segment.usernames.filter((username) => !usernames.includes(username)) }));
            const updatedList = { ...list, lists: updatedSegments };
            commit('List/SET_LISTS', { [list.listId]: updatedList }, { root: true });
          }
          return true;
        });
    },
    createList({ commit, rootGetters }, list) {
      const {
        'Channel/getterCurrentChannelId': channelId,
      } = rootGetters;

      return CRMProvider
        .createList(list)
        .then(({ error, response }) => {
          if (error && error.message === 'order credits expired') throw new Error('In order to create additionnal lists, please contact us on intercom');
          else if (error) throw new Error('An error occured');
          if (response) {
            console.log('🚀 ~ file: TheCRM.store.js ~ line 195 ~ .then ~ response', response);
            const newList = { ...response, channelId, listId: response._id };
            commit('List/SET_LISTS', { [newList.listId]: newList }, { root: true });
            commit('Channel/ADD_LISTS_TO_CHANNEL', { channelId, listIds: [newList.listId] }, { root: true });
          }
          return true;
        });
    },
    endList({ rootGetters }, listId) {
      const {
        'Channel/getterCurrentChannelId': channelId,
      } = rootGetters;

      return CRMProvider
        .endList({ channelId, listId })
        .then(({ error, response }) => {
          if (error) return error;
          if (response) {
            return response;
          }
          return true;
        });
    },
    generateReport({ commit }, { username, channelId }) {
      return CRMProvider
        .generateReport({ username, channelId })
        .then(({ response, error }) => {
          console.log('🚀 ~ file: TheCRM.store.js ~ line 197 ~ .then ~ response', response);
          if (error) return { error };
          const updatedContact = {
            [response.user_id]: {
              ...response,
              contactId: response.user_id,
              audience: response.audience,
              fk_public_url: response.public_url,
            },
          };
          commit('Contact/SET_CONTACTS', updatedContact, { root: true });

          return { response };
        });
    },
    enrichProfiles({ commit }, { channelId, usernames }) {
      return CRMProvider
        .enrichProfiles({ channelId, usernames })
        .then(({ response, error }) => {
          if (error) return { error };
          console.log('🚀 ~ file: TheCRM.store.js ~ line 193 ~ .then ~ response', response);
          const contactList = response.map((row) => ({
            ...row,
            contactId: row.user_id,
            audience: row.audience,
            fk_public_url: row.public_url,
          }));
          const mappedContactList = arrayMapper(contactList, 'contactId');

          commit('Contact/SET_CONTACTS', mappedContactList, { root: true });
          return { response };
        });
    },
    saveCustomSetting({ commit }, settings) {
      console.log('🚀 ~ file: TheCRM.store.js ~ line 211 ~ saveCustomSetting ~ settings', settings);
      commit('SET_CUSTOM_CRM_COLUMNS', settings);
    },
    fetchUsersMentions({ rootGetters }) {
      const {
        'Channel/getterCurrentChannelId': channelId,
      } = rootGetters;

      let pks = [];
      let usernames = [];

      return Promise.resolve()
        .then(() => CRMProvider.getUsersMentions({ channelId }))
        .then(({ error, response }) => {
          console.log('🚀 ~ file: TheCRM.store.js ~ line 268 ~ .then ~ response', response);
          if (error) return error;
          if (response) {
            if (response.pks) pks = [...response.pks];
            if (response.usernames) usernames = [...response.usernames];
          }
          return true;
        }).then(() => CRMProvider.getUsersPosts({ channelId }))
        .then(({ error, response }) => {
          console.log('🚀 ~ file: TheCRM.store.js ~ line 268 ~ .then ~ response', response);
          if (error) return error;
          if (response) {
            if (response.pks) pks = [...pks, ...response.pks];
            if (response.usernames) usernames = [...usernames, ...response.usernames];
          }
          return true;
        })
        .then(() => this.dispatch('Contact/fetchMissingListContacts', { pks, keepOnlyBigContacts: true }))
        .then(() => this.dispatch('Contact/fetchMissingListContactsByUsernames', { usernames, keepOnlyBigContacts: true }));
    },
  },
  mutations: {
    SET_CUSTOM_CRM_COLUMNS: (state, customCRMColumns) => { state.customCRMColumns.push(customCRMColumns); },
  },
};
