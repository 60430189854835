<template>
  <div class="UbuLogin_wrapper">
    <div class="UbuLogin_overlay" />
    <div
      v-if="isResetPasswordView"
      class="UbuLogin_view"
    >
      <UbuLogo />
      <router-view />
    </div>
    <div
      v-else
      class="UbuLogin_view"
    >
      <UbuLogo />
      <div class="ubuContainer py-3">
        <UbuTabLink
          :to="token.length ? `/auth/login/${token}` : '/auth/login'"
        >
          Login
        </UbuTabLink>
        <UbuTabLink :to="token.length ? `/auth/signup/${token}` : '/auth/signup'">
          Signup
        </UbuTabLink>
      </div>
      <router-view />
      <div
        v-if="loadingMessage"
        class="UbuLogin_loader"
      >
        <UbuLoader />
        <div class="ubu-header2">{{ loadingMessage }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TheAuthenticate',
  data() {
    return {
      loadingMessage: '',
    };
  },
  computed: {
    ...mapGetters({
      authenticate: 'Authenticate/getterAuthenticate',
    }),
    isResetPasswordView() {
      return this.$route.meta.isResetPasswordView || false;
    },
    token() {
      return this.$route.params.token ? this.$route.params.token : '';
    },
  },
};
</script>

<style lang="scss" scoped>
.theme--dark {
  .UbuLogin_wrapper {
    .UbuLogin_overlay {
      background-image: url('/img/login-background-dark.png');
    }
  }
}

.UbuLogin_wrapper {
  display: flex;
  flex-direction: row;
  height: 100%;

  & .ubuContainer {
    & > * {
      margin-right: 1rem;
    }
  }

  .UbuLogin_overlay {
    background-image: url('/img/login-background.png');
    background-size: cover;
    height: 100%;
    flex-grow: 1;
    &::after {
      content: ' ';
      display: flex;
      height: 100%;
      background: var(--overlay);
      opacity: .7;
    }
  }

  .UbuLogin_view {
    padding: 36px;
    width: 620px;
    box-shadow: 0px 1px 20px var(--box-shadow);
    background-color: var(--soapstone);
    position: relative;

    .UbuLogin_loader {
      background-color: var(--soapstone);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }

}
</style>
