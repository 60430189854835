<template>
  <section
    id="TheInfluenceMentions"
  >
    <div class="TheInfluenceMentions_card">
      <div class="cardHeader ubu-header1">
        <h1>Mentions</h1>
        <span>{{ mentions.length }}</span>
      </div>
      <UbuMentionsFilters
        v-model="search"
        class="TheMentionsGallery_filters"
        :disabled="loading"
        :is-loading="isLoading"
        :table-view="tableView"
        :show-stories="showStories"
        :show-posts="showPosts"
        :show-tik-tok="showTikTok"
        :check-box-filters="checkBoxFilters"
        :checked-rows="checkedRows"
        :campaign-list="campaignList"
        :contact-groups="Object.values(contactGroups)"
        hide-date-filter
        @setRangeFilter="setRangeFilter($event)"
        @setEngagementFilter="setEngagementFilter($event)"
        @setCheckFilter="setCheckFilter($event)"
        @toggleTableView="setTableView($event)"
        @toggleStories="setStories($event)"
        @togglePosts="setPosts($event)"
        @toggleTikTok="setTikTok($event)"
        @clearCheckedRow="checkedRows = []"
        @selectCampaign="addContactsIntoCampaign($event)"
        @assignContactsToContactGroup="assignContactsToContactGroup($event)"
        @createContactGroupAndAssignMany="createContactGroupAndAssignMany($event)"
        @openTikTokSettings="isModalCreateTikTokOrder = true"
      />
      <div class="filters">
        <div
          v-if="!showTikTok"
          class="grid"
        >
          <UbuStatisticCard
            title="Number of mentions"
            :content="nbMentions"
            :is-loading="loading"
          />
          <UbuStatisticCard
            v-if="showPosts || tableView"
            title="Number of interactions"
            :content="numberOfInteractions"
            :is-loading="loading"
            tooltip-label="The total count of likes comments and save"
          />
          <UbuStatisticCard
            title="Estimated reach"
            :content="estimatedReach"
            :is-loading="loading"
            tooltip-label="The total count of estimated reach based on
            engagement and number of followers of people mentioning you"
          />
          <UbuStatisticCard
            v-if="(showPosts || tableView) && averagePostEngagement"
            title="Average Post Engagement"
            :content="averagePostEngagement"
            :is-loading="loading"
            tooltip-label="The average engagement of all the posts mentioning you"
          />
          <UbuStatisticCard
            title="Followers Earned"
            :content="followersEarned"
            :is-loading="loading"
            tooltip-label="
            The number of followers earned during this period thanks to those mentions"
          />
        </div>
      </div>
      <div class="flex mb-4">
        <UbuButton
          v-if="filteredMentions.length && !tableView && !showTikTok"
          class="UbuWorkspaceCustoms_button"
          secondary
          :loading="mentionsDownloading"
          @click="downloadMentions({ mentions: filteredMentions })"
        >
          Download all
        </UbuButton>
      </div>
      <UbuCommonMentionsCarousel
        v-if="showGallery"
        v-model="indexMedia"
        :items="currentMentions"
        @close="showGallery = false; influencerMentions = []"
        @openNewMessage="openNewMessage($event)"
      />
      <UbuInfluenceMentionsTikTok
        v-if="showTikTok"
        :tiktok-order="tiktokOrder"
        :is-tiktok-loading="isTiktokLoading"
        @openTikTokSettings="isModalCreateTikTokOrder = true"
      />
      <UbuInfluenceMentionsTable
        v-if="tableView"
        :list-filtered="filteredInfluencers"
        :checked-rows="checkedRows"
        :thread-groups-list="Object.values(threadGroups)"
        :contact-groups-list="Object.values(contactGroups)"
        :campaign-list="campaignList.filter(({ ended }) => !ended)"
        :is-generate-report-loading="isGenerateReportLoading"
        :is-statistics-loading="isStatisticsLoading"
        @showCarousel="showInfluencerMentions($event)"
        @checkRows="checkRows($event)"
        @addCustom="addCustom($event)"
        @removeCustom="removeCustom($event)"
        @generateReport="generateReport($event)"
        @updateContact="updateContact($event)"
        @createThreadGroupAndAssign="createThreadGroupAndAssign($event)"
        @createContactGroupAndAssign="createContactGroupAndAssign($event)"
        @openNewMessage="openNewMessage($event)"
        @fetchTiktokUser="fetchTiktokUser($event)"
        @openModalEditUsernameTiktok="showModalEditUsernameTiktok($event)"
      />
      <div
        v-if="loading && !tableView && !showTikTok"
        class="grid"
      >
        <UbuCommonMentionCard
          v-for="index in 10"
          :key="index"
          is-loading
        />
      </div>
      <div
        v-else-if="!tableView && !showTikTok"
        class="grid"
      >
        <UbuCommonMentionCard
          v-for="media in currentMentions"
          :key="media.id"
          :media="media"
          @show="showFullScreen(currentMentions.indexOf(media))"
        />
      </div>
      <div
        v-if="!loading && !showTikTok && (!isDriveActive || filteredMentions.length === 0)"
        class="noResult"
      >
        <p v-if="isDriveActive && filteredMentions.length === 0">
          Oops, no mentions were found for those filters 📡
        </p>
        <!-- <div v-if="!isDriveActive">
          <p>We couldn't load your mentions, please check if your Google Drive is connected</p>
          <b-button
            to="/workspace/connection"
            class="noResult_button ubuButton is-primary"
            tag="router-link"
          >
            Connection
          </b-button>
        </div> -->
      </div>
      <b-pagination
        v-if="!tableView && !showTikTok"
        v-model="currentPage"
        class="mt-3"
        :per-page="mentionsPerPage"
        :total="filteredMentions.length"
        :range-before="5"
        :range-after="5"
      />
      <div class="flex">
        <download-csv
          v-if="tableView"
          :data="filteredInfluencers"
          :fields="csvFields"
          :name="`export-influencers-${campaign.name}.csv`"
        >
          <UbuButton
            class="UbuWorkspaceCustoms_button"
            secondary
          >
            Export Table
          </UbuButton>
        </download-csv>
      </div>
    </div>
    <b-modal
      v-model="isMessageModalActive"
      :width="640"
      scroll="keep"
    >
      <UbuCommonNewMessageModal
        @close="isMessageModalActive = false"
        @sendNewMessage="sendNewMessage({user: userNewMessage, message: $event});"
      />
    </b-modal>
    <b-modal
      v-model="isEndedModalActive"
      :width="640"
      scroll="keep"
    >
      <UbuCommonModal
        title="Contact(s) added to campaign"
        @close="isEndedModalActive = false"
      >
        <template slot="info">
          <p>
            {{ contactAddedToCampaign }} have been added to campaign
          </p>
          <p>
            {{ contactAlreadyInCampaign }} were already in this campaign
          </p>
        </template>
        <template slot="actions">
          <UbuButton
            grow
            center
            secondary
            class="m-2"
            @click="isEndedModalActive = false"
          >
            Close
          </UbuButton>
        </template>
      </UbuCommonModal>
    </b-modal>
    <b-modal
      v-model="isModalEditUsernameTiktok"
      :width="640"
      scroll="keep"
    >
      <UbuContactPanelModalTiktokUsername
        @close="isModalEditUsernameTiktok = false"
        @addFromModal="updateTiktokUsername($event)"
      />
    </b-modal>
    <b-modal
      v-model="isModalCreateTikTokOrder"
      :width="640"
      scroll="keep"
    >
      <UbuInfluenceModalCreateTikTokOrder
        @close="isModalCreateTikTokOrder = false"
        @addFromModal="createTikTokOrder($event)"
      />
    </b-modal>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { shortenNumber } from '../$utils/formate';
import MentionsMixin from '../mentions/$mixins/MentionsMixin';
import {
  getTitleListWithoutDuplicate,
  getListWithoutDuplicate,
} from '../crm/$utils/filterManagement';
import { snackSuccess, snackError } from '../$catalog/snackbar';

export default {
  name: 'TheInfluenceMentions',
  mixins: [MentionsMixin],
  data() {
    return {
      showGallery: false,
      indexMedia: 0,
      isLoading: true,
      hasError: false,
      followerRange: [],
      postRange: [],
      averageReachRange: [],
      followersEarnedRange: [],
      currentLabelCheckList: [],
      currentLanguageCheckList: [],
      currentMentionCheckList: [],
      currentStatusCheckList: [],
      engagementRange: {},
      search: '',
      showStories: true,
      showPosts: false,
      tableView: false,
      showTikTok: false,
      currentPage: 1,
      mentionsPerPage: 50,
      mentionsDownloading: false,
      influencerMentions: [],
      checkedRows: [],
      contactAddedToCampaign: 0,
      contactAlreadyInCampaign: 0,
      isEndedModalActive: false,
      isGenerateReportLoading: null,
      isMessageModalActive: false,
      isModalCreateTikTokOrder: false,
      userNewMessage: null,
      tiktokOrder: {},
      isTiktokLoading: false,
      csvFields: ['username', 'followerCount', 'engagement', 'lastMentionOrTagPost', 'storyMentionCount', 'postMentionCount', 'mention', 'status', 'averagePostEngagement', 'averageReach', 'language', 'newFollowers'],
    };
  },
  computed: {
    ...mapGetters({
      campaignActivitiesDetails: 'TheInfluence/getterCampaignActivitiesDetails',
      fetchIsDone: 'Sequence/getterFetchDone',
      isDriveActive: 'Channel/getterIsDriveConnected',
      currentChannel: 'Channel/getterCurrentChannel',
      currentChannelId: 'Channel/getterCurrentChannelId',
      mentions: 'TheInfluence/getterCurrentMentions',
      influencers: 'TheInfluence/getterCurrentInfluencers',
      threadGroups: 'ThreadGroup/getterThreadGroups',
      contactGroups: 'ContactGroup/getterContactGroups',
    }),
    campaign() {
      return this.campaignActivitiesDetails.campaigns
        .find((campaign) => campaign.campaignId === this.$route.params.campaignId);
    },
    loading() {
      return this.isLoading || !this.fetchIsDone;
    },
    filteredInfluencers() {
      let result = this.influencers;
      if (this.search) {
        const mySearch = this.search.toLowerCase();
        result = result.filter(
          (influencer) => influencer.username.toLowerCase().includes(mySearch),
        );
      }
      if (this.followerRange.length === 2) {
        const [min, max] = this.followerRange;
        result = result.filter(
          (influencer) => influencer.followerCount >= min && influencer.followerCount <= max,
        );
      }
      if (this.averageReachRange.length === 2) {
        const [min, max] = this.averageReachRange;
        result = result.filter((user) => user.averageReach >= min && user.averageReach <= max);
      }
      if (this.followersEarnedRange.length === 2) {
        const [min, max] = this.followersEarnedRange;
        result = result.filter((user) => user.newFollowers >= min && user.newFollowers <= max);
      }
      if (this.engagementRange.min) {
        const { min, max } = this.engagementRange;
        result = result.filter(
          (user) => user.engagement >= min && user.engagement <= max,
        );
      }
      if (this.currentLabelCheckList.length) {
        result = result
          .filter(({ contactGroup }) => {
            const labels = contactGroup.map(({ title }) => title);
            return labels.some((c) => this.currentLabelCheckList.includes(c));
          });
      }
      if (this.currentLanguageCheckList.length) {
        result = result
          .filter(({ language }) => (language
            ? this.currentLanguageCheckList.includes(language) : null));
      }
      if (this.currentMentionCheckList.length) {
        result = result
          .filter(({ posted }) => (posted
            ? this.currentMentionCheckList.includes(posted) : null));
      }
      if (this.currentStatusCheckList.length) {
        result = result
          .filter(({ status }) => (status
            ? this.currentStatusCheckList.includes(status) : null));
      }
      return result;
    },
    filteredMentions() {
      let result = this.influencerMentions.length ? this.influencerMentions : this.mentions;
      if (this.search) {
        const mySearch = this.search.toLowerCase();
        result = result.reduce((acc, media) => {
          const { username, caption, id } = media;
          if (username && username.toLowerCase().includes(mySearch)) {
            if (!acc.find((r) => r.id === id)) acc.push(media);
          }
          if (caption && caption.toLowerCase().includes(mySearch)) {
            if (!acc.find((r) => r.id === id)) acc.push(media);
          }
          return acc;
        }, []);
      }
      const influencersUsernames = this.filteredInfluencers.map((user) => user.username);
      result = result.filter((media) => influencersUsernames.includes(media.username));
      if (this.followerRange.length === 2) {
        const [min, max] = this.followerRange;
        result = result.filter((media) => media.followers >= min && media.followers <= max);
      }
      if (!this.tableView && (!this.showStories || !this.showPosts)) {
        const typeToKeep = this.showStories ? 'story' : 'post';
        result = result.filter((media) => media.product_type === typeToKeep);
      }
      return result;
    },
    currentMentions() {
      const start = (this.currentPage - 1) * this.mentionsPerPage;
      const end = this.currentPage * this.mentionsPerPage;
      return this.filteredMentions.slice(start, end);
    },
    estimatedReach() {
      let reach = 0;
      if (this.tableView) {
        reach = this.filteredInfluencers.reduce(
          (acc, influencer) => acc + (influencer.averageReach || 0), 0,
        );
      } else {
        this.filteredMentions.forEach((mention) => {
          if (mention.instaUser && mention.instaUser.follower_count) {
            const engagement = mention.instaUser.engagement / 100 || 0.05;
            reach += engagement * mention.instaUser.follower_count * 5;
          }
        });
      }
      return shortenNumber(reach);
    },
    averagePostEngagement() {
      let engagement = 0;
      let nbUsersWithPosts = 0;
      this.filteredInfluencers.forEach((user) => {
        engagement += user.averagePostEngagement || 0;
        if (user.postMentionCount > 0) nbUsersWithPosts += 1;
      });
      if (engagement) engagement /= nbUsersWithPosts;
      if (engagement === 0) return null;
      return `${Math.round(engagement * 100) / 100}%`;
    },
    numberOfInteractions() {
      let interactions = 0;
      this.filteredMentions.forEach((mention) => {
        interactions += mention.like_count || 0;
        interactions += mention.comments_count || 0;
      });
      return shortenNumber(interactions);
    },
    nbMentions() {
      return shortenNumber(this.filteredMentions.length);
    },
    followersEarned() {
      return shortenNumber(this.filteredInfluencers.reduce(
        (sum, user) => sum + (user.newFollowers || 0), 0,
      ) || 0);
    },
    labelList() {
      return getTitleListWithoutDuplicate(this.influencers, 'contactGroup');
    },
    languageList() {
      return getListWithoutDuplicate(this.influencers, 'language');
    },
    statusList() {
      return getListWithoutDuplicate(this.influencers, 'status');
    },
    checkBoxFilters() {
      return [
        {
          name: 'contactGroup',
          buttonTitle: 'Labels',
          modalTitle: 'Labels',
          value: this.labelList,
        },
        {
          name: 'language',
          buttonTitle: 'Language',
          modalTitle: 'Language',
          value: this.languageList,
        },
        {
          name: 'posted',
          buttonTitle: 'Posted',
          modalTitle: 'Posted',
          value: ['Yes', 'No'],
        },
        {
          name: 'status',
          buttonTitle: 'Status',
          modalTitle: 'Status',
          value: this.statusList,
        },
      ];
    },
  },
  watch: {
    fetchIsDone: {
      handler(isDone) {
        if (isDone) this.fetchMentions();
      },
    },
  },
  mounted() {
    this.type = this.$route.meta.type;
    if (this.fetchIsDone) this.fetchMentions();
  },
  methods: {
    ...mapActions({
      _assignThreadToThreadGroup: 'TheThreadHeader/assignThreadToThreadGroup',
      _removeThreadFromThreadGroup: 'TheThreadHeader/removeThreadFromThreadGroup',
      _createThreadGroupAndAssign: 'TheThreadHeader/createThreadGroupAndAssign',
      _assignContactToContactGroup: 'TheThreadHeader/assignContactToContactGroup',
      _removeContactFromContactGroup: 'TheThreadHeader/removeContactFromContactGroup',
      _createContactGroupAndAssign: 'TheThreadHeader/createContactGroupAndAssign',
      _generateReport: 'TheCRM/generateReport',
      loadMentions: 'TheInfluence/fetchMentions',
      fetchTikTokMediasOrders: 'TheInfluence/fetchTikTokMediasOrders',
      addTikTokMediasOrder: 'TheInfluence/addTikTokMediasOrder',
      dlMentions: 'TheMentions/downloadMentions',
      setUnscheduled: 'TheInfluence/setUnscheduled',
      deleteActivity: 'TheInfluence/deleteActivity',
      updateContact: 'TheContactPanel/updateContact',
      sendMessage: 'TheInbox/sendMessage',
    }),
    fetchMentions() {
      this.isLoading = true;
      this.loadMentions(this.campaign)
        .then(() => {
          this.isLoading = false;
          this.hasError = false;
        })
        .catch(() => {
          this.isLoading = false;
          this.hasError = true;
        });
    },
    showFullScreen(index) {
      this.indexMedia = index;
      this.showGallery = true;
    },
    showInfluencerMentions(mentions) {
      this.influencerMentions = mentions;
      this.showFullScreen(0);
    },
    downloadMentions({ mentions }) {
      const { username } = this.currentChannel;
      this.mentionsDownloading = true;
      this.dlMentions({ mentions, folderName: `Mentions ${username} - ${this.campaign.name}` })
        .then(() => {
          this.mentionsDownloading = false;
          this.$buefy.snackbar.open({
            message: 'You will soon receive your mentions by email',
            type: 'is-success',
            position: 'is-bottom-right',
            indefinite: true,
          });
        }).catch(() => {
          this.mentionsDownloading = false;
        });
    },
    checkRows(event) {
      this.checkedRows = [...event];
    },
    addContactsIntoCampaign(event) {
      const { campaignId } = event;
      this.contactAddedToCampaign = 0;
      this.contactAlreadyInCampaign = 0;

      const activities = this.checkedRows.reduce((acc, row) => {
        const contactAlreadyInThisCampaign = row.activities
          .find((a) => a.campaignId === campaignId);

        if (contactAlreadyInThisCampaign) { this.contactAlreadyInCampaign += 1; return acc; }
        this.contactAddedToCampaign += 1;
        acc.push({
          pk: row.contactId,
          username: row.username,
          status: 'unscheduled',
          channel: this.currentChannelId,
          campaignId,
          campaign: campaignId,
        });
        return acc;
      }, []);

      // Create activities
      this.setUnscheduled({
        campaignId,
        channelId: this.currentChannelId,
        activities,
      })
        .then(() => {
          this.checkedRows = [];
          this.isEndedModalActive = true;
        });
    },
    setTableView(event) {
      this.tableView = event;
      if (this.tableView) {
        this.showStories = true;
        this.showPosts = true;
      }
      this.showTikTok = false;
    },
    setStories() {
      this.showStories = true;
      this.showPosts = false;
      this.tableView = false;
      this.showTikTok = false;
    },
    setPosts() {
      this.showStories = false;
      this.showPosts = true;
      this.tableView = false;
      this.showTikTok = false;
    },
    setTikTok() {
      this.showStories = false;
      this.showPosts = false;
      this.tableView = false;
      this.showTikTok = true;
      this.isTiktokLoading = true;
      this.fetchTikTokMediasOrders(this.campaign.campaignId)
        .then((tiktokOrder) => {
          this.isTiktokLoading = false;
          this.tiktokOrder = tiktokOrder;
        });
    },
    setRangeFilter(event) {
      console.log('🚀 ~ file: TheInfluenceMentions.vue ~ line 408 ~ setRangeFilter ~ event', event);
      const { name, rangeValues } = event;
      if (name === 'followerRange') this.followerRange = rangeValues;
      if (name === 'postRange') this.postRange = rangeValues;
      if (name === 'averageReachRange') this.averageReachRange = rangeValues;
      if (name === 'followersEarnedRange') this.followersEarnedRange = rangeValues;
    },
    setCheckFilter(event) {
      const { name, checked } = event;
      if (name === 'contactGroup') this.currentLabelCheckList = checked;
      if (name === 'language') this.currentLanguageCheckList = checked;
      if (name === 'posted') this.currentMentionCheckList = checked;
      if (name === 'status') this.currentStatusCheckList = checked;
    },
    setEngagementFilter(range) {
      this.engagementRange = range;
    },
    createTikTokOrder(event) {
      this.addTikTokMediasOrder({ ...event, campaign: this.campaign.campaignId })
        .then(() => {
          this.isTiktokLoading = true;
          this.fetchTikTokMediasOrders(this.campaign.campaignId)
            .then((tiktokOrder) => {
              this.tiktokOrder = tiktokOrder;
              this.isTiktokLoading = false;
            });
          this.isModalCreateTikTokOrder = false;
          this.$buefy.snackbar.open({
            ...snackSuccess,
            message: 'Changes saved, your TikTok influencers table will be updated soon (we refresh every 6 hours)',
          });
        })
        .catch((error) => {
          this.isModalCreateTikTokOrder = false;
          this.$buefy.snackbar.open({
            ...snackError,
            message: error.message,
          });
        });
    },
  },
};
</script>
