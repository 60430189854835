var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"TheInbox"}},[_c('TheSidebar',{attrs:{"menu-items":_vm.menu},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isReduced = ref.isReduced;
return (_vm.fetchInboxIsDone)?_c('div',{},[_c('div',{staticClass:"ubu-divider",style:(!isReduced ? 'opacity: 0;' : '')}),(_vm.tagMenu)?_c('UbuSidebarCustoms',{staticStyle:{"margin":"12px 0"},attrs:{"is-reduced":isReduced,"type":"tag","items":_vm.tagMenu,"icon":"tag","new-placeholder":"+ new tag"}}):_vm._e(),_c('div',{staticClass:"ubu-divider",style:(!isReduced ? 'opacity: 0;' : '')}),(_vm.folderMenu)?_c('UbuSidebarCustoms',{staticStyle:{"margin":"12px 0"},attrs:{"is-reduced":isReduced,"type":"folder","items":_vm.folderMenu,"icon":"contact","new-placeholder":"+ new contact folder"}}):_vm._e()],1):_vm._e()}}],null,true)}),(_vm.fetchInboxIsDone)?_c('div',{staticClass:"TheInbox_wrapper"},[_c('UbuThreadList',{key:((_vm.$route.name) + "/" + (_vm.$route.params.value || _vm.show)),attrs:{"threads":_vm.visibleThreads,"selected-thread-id":_vm.selectedThread ? _vm.selectedThread.threadId : null,"selected-thread":_vm.selectedThread,"template-list":_vm.templateList,"sort":_vm.sort,"filter":_vm.filter,"bulks":_vm.bulks,"sort-options":_vm.sortOptions,"filter-options":_vm.filterOptions,"filter-box-is-open":_vm.filterBoxIsOpen,"user-fetched":_vm.userFetched,"is-fetching":_vm.isFetching},on:{"update:sort":function($event){_vm.sort=$event},"update:filter":function($event){_vm.filter=$event},"update:bulks":function($event){_vm.bulks=$event},"update:filterBoxIsOpen":function($event){_vm.filterBoxIsOpen=$event},"update:filter-box-is-open":function($event){_vm.filterBoxIsOpen=$event},"onSelectThread":function($event){return _vm.selectThread($event)},"onToggleFilterPanel":function($event){return _vm.resetToDefaultFilter($event)},"createThread":function($event){return _vm.createThread($event)},"closeModal":function($event){return _vm.closeModal($event)}},scopedSlots:_vm._u([{key:"bulkActions",fn:function(ref){
var bulks = ref.bulks;
return _c('div',{staticClass:"TheInbox_bulkActions"},[_c('UbuSelector',{attrs:{"reduced":"","values":_vm.threadGroupItems(_vm.getIntersection(bulks, 'threadGroups')),"choices":_vm.threadGroupItems(_vm.threadGroupsWithThreads),"paths":{ id: 'threadGroupId', label: 'title' },"label-icon":"tag","tooltip":{
            label: ("Tag " + (bulks.length) + " conversation" + (bulks.length !== 1 ? 's' : '')),
            position: 'is-bottom'
          },"search-placeholder":"Create or search tags...","create-placeholder":"Create new tag:"},on:{"select":function($event){return _vm.assignThreadsToThreadGroup({ threads: bulks, threadGroup: $event })},"remove":function($event){return _vm.removeThreadsFromThreadGroup({ threads: bulks, threadGroup: $event })},"create":function($event){return _vm.createThreadGroupAndAssignMany(Object.assign({}, {threads: bulks}, $event))}}}),_c('UbuSelector',{attrs:{"reduced":"","values":_vm.contactGroupItems(_vm.getIntersection(bulks, 'contactGroups')),"choices":_vm.contactGroupItems(_vm.contactGroupsWithThreads),"paths":{ id: 'contactGroupId', label: 'title' },"label-icon":"contact","tooltip":{
            label: ("Add " + (bulks.length) + " as contact" + (bulks.length !== 1 ? 's' : '')),
            position: 'is-bottom'
          },"search-placeholder":"Create or search contacts...","create-placeholder":"Create new contact:"},on:{"select":function($event){return _vm.assignContactsToContactGroup({ threads: bulks, contactGroup: $event })},"remove":function($event){return _vm.removeContactsFromContactGroup({ threads: bulks, contactGroup: $event })},"create":function($event){return _vm.createContactGroupAndAssignMany(Object.assign({}, {threads: bulks}, $event))}}}),_c('UbuSelector',_vm._b({attrs:{"reduced":"","choices":_vm.campaignItems(_vm.campaigns),"values":_vm.campaignItems(_vm.getIntersection(bulks, 'campaigns'))},on:{"select":function($event){return _vm.assignContactsToCampaign({ threads: bulks, campaign: $event })}}},'UbuSelector',_vm.campaignSelector.props,false)),(_vm.currentThreadGroup)?_c('UbuButton',{attrs:{"grow":"","center":"","color":"2","tooltip":{
            label: ("Close and remove '" + (_vm.currentThreadGroup.title) + "'\n            for " + (bulks.length) + " thread" + (bulks.length !== 1 ? 's' : '')),
            position: 'is-bottom'
          },"icon":{ name: 'success', filled: true }},on:{"click":function($event){return _vm.doneManyThreads({ threads: bulks, threadGroup: _vm.currentThreadGroup })}}}):(_vm.allThreadsAreOpen(bulks))?_c('UbuButton',{attrs:{"color":"5","center":"","grow":"","tooltip":{
            label: ("Open " + (bulks.length) + " selected thread" + (bulks.length !== 1 ? 's' : '')),
            position: 'is-bottom'
          },"icon":{ name: 'messages', filled: true }},on:{"click":function($event){return _vm.openManyThreads({ threads: bulks })}}}):_c('UbuButton',{attrs:{"color":"2","center":"","grow":"","tooltip":{
            label: ("Close " + (bulks.length) + " selected thread" + (bulks.length !== 1 ? 's' : '')),
            position: 'is-bottom'
          },"icon":{ name: 'success', filled: true }},on:{"click":function($event){return _vm.closeManyThreads({ threads: bulks })}}})],1)}}],null,false,3501389928),model:{value:(_vm.usernameSearch),callback:function ($$v) {_vm.usernameSearch=$$v},expression:"usernameSearch"}},[_c('template',{attrs:{"name":"threadListHeader"},slot:"header"},[(_vm.currentThreadGroup || _vm.currentContactGroup)?_c('UbuTHCustomHeader',{attrs:{"item":_vm.currentThreadGroup || _vm.currentContactGroup,"icon":_vm.currentThreadGroup ? 'tag' : 'contact'}}):_c('UbuTHShowClosed',{attrs:{"value":_vm.show,"closed-availables":true},on:{"input":function (evt) { _vm.selectedThreadIndex = 0; _vm.show = evt; }}})],1)],2),(!!_vm.selectedThread)?_c('TheThreadPanel',{key:_vm.selectedThread.threadId,attrs:{"selected-thread":_vm.selectedThread,"current-thread-group":_vm.currentThreadGroup},on:{"selectNext":function($event){return _vm.selectNextThread()},"openModalAddLink":function($event){return _vm.$emit('openModalAddLink', $event)}}}):_vm._e(),(_vm.fetchInboxIsDone && !!_vm.selectedThread && _vm.selectedThread.contactId)?_c('TheContactPanel',{key:_vm.selectedThread.contactId,attrs:{"contact-id":_vm.selectedThread.contactId},on:{"onContactDataLoaded":function($event){return _vm.checkLastPermanent({ thread: _vm.selectedThread })},"openModalAddLink":function($event){return _vm.$emit('openModalAddLink', $event)}}}):_vm._e()],1):_c('div',{staticClass:"TheInbox_empty"},[_c('UbuLoader')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }