/* eslint-disable max-len */
import ShopifyShop from './ShopifyShop.store';
import DiscountCode from './DiscountCode.store';
import ReferralLink from './ReferralLink.store';
import Order from './Order.store';

export default {
  namespaced: true,
  state: {
  },
  mutations: {
  },
  getters: {
  },
  actions: {
    sequenceFetchingShopify() {
      const shopifyShopList = [];
      return Promise.resolve()
        .then(() => this.dispatch('Shopify/ShopifyShop/fetchShopifyShop'))
        .then((shopList) => {
          if (!shopList) return;
          shopifyShopList.push(...shopList);
        })
        .then(() => Promise.all(shopifyShopList.map(({ shopifyShopSsid }) => this.dispatch('Shopify/DiscountCode/sequence', { shopifyShopSsid }))))
        .then(() => Promise.all(shopifyShopList.map(({ shopifyShopSsid }) => this.dispatch('Shopify/ReferralLink/sequence', { shopifyShopSsid }))))
        .then(() => Promise.all(shopifyShopList.map(({ shopifyShopSsid }) => this.dispatch('Shopify/Order/fetchShopifyOrders', { shopifyShopSsid }))));
    },
  },
  modules: {
    ShopifyShop,
    DiscountCode,
    ReferralLink,
    Order,
  },
};
