import UbuReconnectChannel from '@/components/UbuModals/UbuReconnectChannel';
import UbuChannelOverloaded from '@/components/UbuModals/UbuChannelOverloaded';
import UbuReconnectFacebook from '@/components/UbuModals/UbuReconnectFacebook';

const handleLoginError = ({ dispatch }) => dispatch('Modal/setModal', {
  cmp: UbuReconnectChannel,
  props: {
    kind: 'reconnect',
  },
  modalProps: {
    customClass: 'modal-overflow-visible',
    // canCancel: false,
  },
}, { root: true });

const handleChallengeError = ({ dispatch }) => dispatch('Modal/setModal', {
  cmp: UbuReconnectChannel,
  props: {
    kind: 'challenge',
  },
  modalProps: {
    customClass: 'modal-overflow-visible',
    // canCancel: false,
  },
}, { root: true });

const handle2FAError = ({ dispatch }) => dispatch('Modal/setModal', {
  cmp: UbuReconnectChannel,
  props: {
    kind: '2fa',
  },
  modalProps: {
    customClass: 'modal-overflow-visible',
    // canCancel: false,
  },
}, { root: true });

const handleWaitError = ({ dispatch }) => dispatch('Modal/setModal', {
  cmp: UbuChannelOverloaded,
  modalProps: {
    customClass: 'modal-overflow-visible',
    // canCancel: false,
  },
}, { root: true });

export const handleReconnectFacebook = ({ dispatch }) => dispatch('Modal/setModal', {
  cmp: UbuReconnectFacebook,
  modalProps: {
    customClass: 'modal-overflow-visible',
    // canCancel: false,
  },
}, { root: true });

export default class ErrorHandler {
  static generic(context, response) {
    if (!response.error) {
      return response;
    }
    switch (response.error.reason) {
      case 'login error':
      case 'login required':
        return handleLoginError(context, response.error).then(() => response);
      case 'challenge needed': return handleChallengeError(context, response.error).then(() => response);
      case '2FA needed': return handle2FAError(context, response.error).then(() => response);
      case 'instagram please wait': return handleWaitError(context, response.error).then(() => response);
      // case 'connexion needed': return handleReconnectFacebook(context, response.error).then(() => response);
      default: break;
    }
    return response;
  }
}
