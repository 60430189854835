<template>
  <section id="TheWorkspaceConnection">
    <div class="workspace-view-header">
      <h1 class="ubu-header1">Connections</h1>
    </div>

    <UbuWorkspaceConnectionCard
      v-for="item in connectionItemList"
      :key="item.title"
      :item="item"
      :submenu="item.submenu || undefined"
      @openModal="openModal($event)"
    />

    <b-modal
      v-model="isCardModalActive"
      :width="640"
      scroll="keep"
      :can-cancel="['x']"
    >
      <UbuWorkspaceConnectModal
        v-if="currentModal"
        :modal-type="currentModal"
        :is-connexion-loading="isConnexionLoading"
        @close="closeModal()"
        @connectShopify="connectShopify($event)"
      />
    </b-modal>
  </section>
</template>

<script>
/* eslint-disable vue/no-async-in-computed-properties */
import { mapGetters, mapActions } from 'vuex';
import { SnackbarProgrammatic as Snackbar } from 'buefy';
import { snackSuccess, snackError } from '../$catalog/snackbar';

export default {
  name: 'TheWorkspaceConnection',
  data() {
    return {
      isCardModalActive: false,
      currentModal: null,
      isConnexionLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      users: 'UbuUser/getterUbuUsers',
      fetchIsDone: 'Sequence/getterFetchInitial',
      isDriveActive: 'Channel/getterIsDriveConnected',
      oauthUrl: 'Channel/getterDriveAuthUrl',
      getterFbChannel: 'Channel/getterFbChannel',
      ownedAdAccounts: 'TheWorkspace/ownedAdAccounts',
      getterShopifyShop: 'TheWorkspace/getterShopifyShop',

    }),
    connectionItemList() {
      const fbConnection = {
        title: 'Facebook',
        text: 'Manage comments from both Facebook & Instagram, get analytics related to your inbox.',
        iconPath: '/img/facebook-logo-connection.svg',
        linkRedirect: '/add-facebook-channel.html',
        isActive: this.getterFbChannel && this.getterFbChannel.connected,
        submenu: this.getterFbChannel && this.getterFbChannel.connected && {
          choices: this.ownedAdAccounts && this.ownedAdAccounts.map((o) => (
            { key: o.id, label: `${o.name} <${o.id}> (${o.ads_volume} active ads)` }
          )),
          help: this.getterFbChannel.adAccountId
            ? 'If you can\'t see your ads, ensure that you gave all required permissions'
            : 'If you can\'t find the correct ad account in this list, please connect the facebook account having the right business permissions',
          value: this.getterFbChannel.adAccountId,
          title: 'Ad account',
          onChoose: this.fetchAdsThenRedirect,
        },
      };
      if (process.env.VUE_APP_FACEBOOK_OFFICIEL_TEST === 'true') {
        return [fbConnection];
      }
      const items = [
        fbConnection,
        {
          title: 'Google Drive',
          text: 'Save all medias you\'re mentioned in forever and get analytics reports',
          iconPath: '/img/google-drive-logo-connection.svg',
          linkRedirect: this.oauthUrl,
          isActive: this.isDriveActive,
        }, {
          title: 'Shopify',
          text: 'Send discount & referral codes, link them to specific users and campaigns, track revenues.',
          iconPath: '/img/Shopify.svg',
          modal: 'shopifyModal',
          connectedShop: this.getterShopifyShop,
          isActive: this.getterShopifyShop.length > 0,
        },
      ];
      return items;
    },
  },
  watch: {
    getterFbChannel: {
      immediate: true,
      handler(fbData) {
        if (fbData && fbData._id) {
          this.getFbAdAccounts({ fbId: fbData._id });
          // .then(() => {
          //   if (this.ownedAdAccounts.length === 0) {
          //     this.$buefy.snackbar.open({
          //       message: "We can't find your Ad Accounts, please ensure that is the owner of the business account who logged in, and all permissions are given",
          //     });
          //   }
          // });
        }
      },
    },
  },
  methods: {
    ...mapActions({
      hardFetchComments: 'Sequence/hardFetchComments',
      getFbAdAccounts: 'TheWorkspace/getFbAdAccounts',
      setAdAccountId: 'TheWorkspace/setAdAccountId',
      connectToShopify: 'Shopify/ShopifyShop/connectToShopify',
    }),
    fetchAdsThenRedirect(adAccountId) {
      const loader = '<img class="rotating" src="/img/loader.svg" />';
      const snack = Snackbar.open({
        message: `<div class="inlineLoader">${loader} We are fetching your data 
        from Facebook & Instagram. It might take some minutes.</div>`,
        type: 'is-success',
        position: 'is-bottom-right',
        indefinite: true,
        actionText: null,
      });
      this.setAdAccountId({
        fbId: this.getterFbChannel._id,
        adAccountId,
      }).then(() => this.$store.dispatch('Sequence/fetchComments'))
        .then(() => {
          snack.close();
        })
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        .then(() => this.$router.push('/comment/all'))
        .catch(() => snack.close());
    },
    openModal(event) {
      this.currentModal = event.modal;
      this.isCardModalActive = true;
    },
    closeModal() {
      this.currentModal = null;
      this.isCardModalActive = false;
    },
    connectShopify(event) {
      this.isConnexionLoading = true;

      const {
        urlWebsite, apiKey, urlStore, password, exempleUrl, sharedSecret,
      } = event;

      const reconstructedExempleUrl = `https://${apiKey}:${password}@${urlStore}`;
      const indexLastExempleUrlWordToKeep = exempleUrl.indexOf('/admin');
      const insertedExempleUrl = exempleUrl.substring(0, indexLastExempleUrlWordToKeep);

      const splitedUrl = exempleUrl.split('/');

      const apiVersion = splitedUrl[splitedUrl.length - 2];

      if (reconstructedExempleUrl === insertedExempleUrl) {
        this.connectToShopify({
          urlWebsite, apiVersion, urlStore, password, sharedSecret,
        })
          .then(({ success }) => {
            if (success) {
              this.$buefy.snackbar.open({
                ...snackSuccess,
                message: `Successfuly connected to ${urlStore}`,
              });
              this.closeModal();
            } else {
              this.$buefy.snackbar.open({
                ...snackError,
                message: 'Error, connexion failed',
                onAction: () => { this.connectShopify(event); },
              });
            }
            this.isConnexionLoading = false;
          });
      } else {
        this.$buefy.snackbar.open({
          ...snackError,
          message: 'Error, the value of a field seems bad',
          actionText: null,
        });
      }
    },
  },
};
</script>
