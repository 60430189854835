/* eslint-disable object-curly-newline */
/* eslint-disable import/prefer-default-export */
import builder from '../engine';

const shopifyShop = {
  report: ({ payload, httpQuery }) => builder({ url: `/api/channel/${payload.channelId}/shopify/shopifyShop`, method: 'GET', payload, httpQuery, target: 'yaerApi' }),
  post: ({ payload, httpQuery }) => builder({ url: `/api/channel/${payload.channelId}/shopify/shopifyShop`, method: 'POST', payload, httpQuery, target: 'yaerApi' }),
};

const shopifyDiscountCode = {
  report: ({ payload, httpQuery }) => builder({ url: `/api/channel/${payload.channelId}/shopify/${payload.shopifyShopSsid}/getDiscountCodes`, method: 'GET', payload, httpQuery, target: 'yaerApi' }),
  refresh: ({ payload, httpQuery }) => builder({ url: `/api/channel/${payload.channelId}/shopify/${payload.shopifyShopSsid}/refreshDiscountCodes`, method: 'GET', payload, httpQuery, target: 'yaerApi' }),
  post: ({ payload, httpQuery }) => builder({ url: `/api/channel/${payload.channelId}/shopify/${payload.shopifyShopSsid}/discountCode`, method: 'POST', payload, httpQuery, target: 'yaerApi' }),
};

const shopifyReferralLink = {
  report: ({ payload, httpQuery }) => builder({ url: `/api/channel/${payload.channelId}/shopify/${payload.shopifyShopSsid}/sponsoredLink`, method: 'GET', payload, httpQuery, target: 'yaerApi' }),
  post: ({ payload, httpQuery }) => builder({ url: `/api/channel/${payload.channelId}/shopify/${payload.shopifyShopSsid}/sponsoredLink`, method: 'POST', payload, httpQuery, target: 'yaerApi' }),
};

const adAccount = {
  report: ({ payload, httpQuery }) => builder({ url: `/api/getFacebookAdAccounts/${payload.fbId}`, method: 'GET', payload, httpQuery, target: 'yaerApi' }),
  set: ({ payload, httpQuery }) => builder({ url: `/api/setActiveAdAccountId/${payload.fbId}/${payload.adAccountId}`, method: 'POST', payload, httpQuery, target: 'yaerApi' }),
};

const posts = {
  instagramMentions: ({ payload, httpQuery }) => builder({ url: `/api/channel/${payload.channelId}/mentionedMediasDb`, method: 'GET', payload, httpQuery, target: 'yaerApi' }),
  instagram: ({ payload, httpQuery }) => builder({ url: `/api/channel/${payload.channelId}/mediasDB`, method: 'GET', payload, httpQuery, target: 'yaerApi' }),
  facebook: ({ payload, httpQuery }) => builder({ url: `/api/channel/${payload.channelId}/mediasFbDB`, method: 'GET', payload, httpQuery, target: 'yaerApi' }),
  fetchFbOrganic: ({ payload, httpQuery }) => builder({ url: `/api/channel/${payload.channelId}/fetchFbOrganic`, method: 'GET', payload, httpQuery, target: 'yaerApi' }),
  fetchAllAds: ({ payload, httpQuery }) => builder({ url: `/api/channel/${payload.channelId}/fetchAllAds`, method: 'GET', payload, httpQuery, target: 'yaerApi' }),
  fetchDynamics: ({ payload, httpQuery }) => builder({ url: `/api/channel/${payload.channelId}/fetchDynamics`, method: 'GET', payload, httpQuery, target: 'yaerApi' }),
};

const comments = {
  refreshFacebook: ({ payload, httpQuery }) => builder({ url: `/api/channel/${payload.channelId}/commentsLimitedFb/${payload.graphId}`, method: 'GET', payload, httpQuery, target: 'yaerApi' }),
  instagram: ({ payload, httpQuery }) => builder({ url: `/api/channel/${payload.channelId}/commentsDB/${payload.graphId}`, method: 'GET', payload, httpQuery, target: 'yaerApi' }),
  facebook: ({ payload, httpQuery }) => builder({ url: `/api/channel/${payload.channelId}/commentsFbDB/${payload.graphId}`, method: 'GET', payload, httpQuery, target: 'yaerApi' }),
  delete: ({ payload }) => builder({ url: `/api/channel/${payload.channelId}/deleteCommentFb/${payload.commentId}`, method: 'GET', payload, target: 'yaerApi' }),
};

const shopifyOrders = {
  report: ({ payload, httpQuery }) => builder({ url: `/api/channel/${payload.channelId}/shopify/${payload.shopifyShopSsid}/orders`, method: 'GET', payload, httpQuery, target: 'yaerApi' }),
  reportTotal: ({ payload, httpQuery }) => builder({ url: `/api/channel/${payload.channelId}/shopify/${payload.shopifyShopSsid}/orderTotal`, method: 'GET', payload, httpQuery, target: 'yaerApi' }),

  discountCode: {
    // report: ({ payload, httpQuery }) => builder({ url: `/api/channel/${payload.channelId}/shopify/${payload.shopifyShopSsid}/orders/discountCode/${payload.code}`, method: 'GET', payload, httpQuery, target: 'yaerApi' }),
    report: ({ payload, httpQuery }) => builder({ url: `/api/channel/${payload.channelId}/shopify/${payload.shopifyShopSsid}/orders/discountCode/`, method: 'GET', payload, httpQuery, target: 'yaerApi' }),
  },
  referralLink: {
    report: ({ payload, httpQuery }) => builder({ url: `/api/channel/${payload.channelId}/shopify/${payload.shopifyShopSsid}/orders/referralCode/${payload.code}`, method: 'GET', payload, httpQuery, target: 'yaerApi' }),
  },
  email: {
    report: ({ payload, httpQuery }) => builder({ url: `/api/channel/${payload.channelId}/shopify/${payload.shopifyShopSsid}/orders/email/${payload.email}`, method: 'GET', payload, httpQuery, target: 'yaerApi' }),
  },
  source: {
    report: ({ payload, httpQuery }) => builder({ url: `/api/channel/${payload.channelId}/shopify/${payload.shopifyShopSsid}/orders/source/${payload.source}`, method: 'GET', payload, httpQuery, target: 'yaerApi' }),
  },
};

const shopifyUtm = {
  report: ({ payload, httpQuery }) => builder({ url: `/api/channel/${payload.channelId}/shopify/${payload.shopifyShopSsid}/utmParameters`, method: 'GET', payload, httpQuery, target: 'yaerApi' }),
  post: ({ payload, httpQuery }) => builder({ url: `/api/channel/${payload.channelId}/shopify/${payload.shopifyShopSsid}/utmParameter`, method: 'POST', payload, httpQuery, target: 'yaerApi' }),
  delete: ({ payload, httpQuery }) => builder({ url: `/api/channel/${payload.channelId}/shopify/${payload.shopifyShopSsid}/utmParameter/${payload.utmId}`, method: 'DELETE', payload, httpQuery, target: 'yaerApi' }),
};

const contact = {
  fetchByUsername: ({ payload, httpQuery }) => builder({
    url: `/api/channel/${payload.channelId}/instaExactUserName/${payload.username}`,
    method: 'GET',
    payload,
    httpQuery,
    target: 'yaerApi',
  }),
};

export {
  shopifyShop,
  shopifyDiscountCode,
  shopifyReferralLink,
  adAccount,
  posts,
  comments,
  shopifyOrders,
  contact,
  shopifyUtm,
};
