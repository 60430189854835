<template>
  <div
    v-click-outside="() => isDropdownOpened = false"
    class="UbuAnalyticsDatepicker"
  >
    <p class="ubu-subtext1 UbuAnalyticsDatepicker_label">Compare with:</p>
    <div
      :class="['UbuAnalyticsDatepicker_button', {isOpened: isDropdownOpened}]"
      @click="isDropdownOpened = !isDropdownOpened"
    >
      <span class="ubu-text3">{{ currentDisplayableDate }}</span>
      <i class="ubu-icons ubu-icons-expand-outlined" />
    </div>
    <div
      v-if="isDropdownOpened"
      class="UbuAnalyticsDatepicker_dropDown"
    >
      <b-datepicker
        v-model="currentDaterange"
        inline
        range
        @input="createCustomDate($event)"
      />
      <div class="dateOptionList">
        <div
          v-for="(item, index) in dateOptions"
          :key="index"
          :class="['dateOption', 'ubu-text3',
                   {selected: item.subtype === dateOptions[currentDateIndex].subtype}]"
          @click="changeDate(item, index)"
        >
          {{ item.displayName }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'UbuAnalyticsDatepicker',
  props: {
  },
  data() {
    return {
      date: null,
      currentDateIndex: 0,
      currentDaterange: [moment().subtract({ days: 6 }).toDate(), moment().toDate()],
      customDateRange: [],
      isDropdownOpened: false,
    };
  },
  computed: {
    dateOptions() {
      return [
        {
          type: 'simple',
          subtype: 'currentWeek',
          displayName: 'Last 7 days',
          dateRange: [moment().subtract({ days: 6 }).toDate(), moment().toDate()],
        },
        {
          type: 'simple',
          subtype: 'previousWeek',
          displayName: 'Previous week',
          dateRange: [moment().day(-6).toDate(), moment().day(0).toDate()],
        },
        {
          type: 'simple',
          subtype: 'currentMonth',
          displayName: 'Last 30 days',
          dateRange: [moment().subtract({ month: 1 }).toDate(), moment().toDate()],
        },
        {
          type: 'simple',
          subtype: 'previousMonth',
          displayName: 'Previous month',
          dateRange: [moment().subtract({ month: 1 }).startOf('month').toDate(), moment().subtract({ month: 1 }).endOf('month').toDate()],
        },
        {
          type: 'custom',
          subtype: 'custom',
          displayName: 'Custom date range',
          dateRange: this.customDateRange,
        },
      ];
    },
    currentDisplayableDate() {
      if (this.dateOptions[this.currentDateIndex].type === 'custom') return this.customDateRangeString;
      return this.dateOptions[this.currentDateIndex].displayName;
    },
    customDateRangeString() {
      if (!this.customDateRange.length) return 'Custom date range';
      const [minDate, maxDate] = this.customDateRange;
      if (moment(minDate).isSame(maxDate, 'day')) return moment(minDate).format('YYYY-MM-DD');
      return `${moment(minDate).format('YYYY-MM-DD')} - ${moment(maxDate).format('YYYY-MM-DD')}`;
    },
  },
  methods: {
    changeDate(dateOption, index) {
      this.currentDateIndex = index;
      this.currentDaterange = dateOption.dateRange;
      this.$emit('changeDate', { value: this.currentDaterange, type: dateOption.type, subtype: dateOption.subtype });
    },
    createCustomDate(dateRange) {
      this.currentDateIndex = 4;
      this.currentDaterange = dateRange;
      this.customDateRange = dateRange;
      this.$emit('changeDate', { value: dateRange, type: 'custom' });
    },
  },
};
</script>
