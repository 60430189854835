<template>
  <b-button @click="logout()">
    logout
  </b-button>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'TheAuthenticateLogout',
  methods: {
    ...mapActions({
      logout: 'Authenticate/logout',
    }),
  },
};
</script>
