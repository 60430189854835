<template>
  <div class="flex">
    <audio controls>
      <source
        :src="url"
        type="audio/mpeg"
      >
    </audio>
  </div>
</template>
<script>

export default {
  name: 'UbuCommonAudio',
  props: {
    url: {
      type: String,
      required: true,
    },
  },
};
</script>
