<template>
  <div
    v-click-outside="e => open = false"
    :class="['UbuSimpleDropdown_wrapper', {'UbuSimpleDropdown_label': label}]"
  >
    <label>
      <span
        v-if="label"
      >
        {{ label }}
      </span>
      &nbsp;
      <b-tooltip
        v-if="help"
        multilined
        :label="help"
        position="is-bottom"
      >
        <UbuIcon name="info" />
      </b-tooltip>
    </label>
    <UbuButton
      class="UbuSimpleDropdown_trigger"
      :active="open"
      v-bind="$attrs"
      @click="open = !open"
    >
      <div class="UbuSimpleDropdown_triggerText ellipsis">
        <span
          class="ellipsis"
        >
          {{ selectedValue && selectedValue.label || defaultLabel }}
        </span>
        <UbuIcon
          :class="[
            {'UbuSimpleDropdown_exp': open},
            'UbuSimpleDropdown_expOpen'
          ]"
          name="expand"
        />
      </div>
      <div
        slot="dropdown"
        class="UbuSimpleDropdown_options"
      >
        <div
          v-if="clearable && value"
          style="font-weight: 300"
          :class="[
            'ellipsis',
            'UbuSimpleDropdown_option',
          ]"
          @click="() => { $emit('input', null); open = false }"
        >
          <UbuIcon name="cross" /> Clear {{ value }}
        </div>
        <div
          v-if="!options.length && !value"
          class="UbuSimpleDropdown_option UbuSimpleDropdown_unselectable">
          {{ noValuesLabel }}
        </div>
        <div
          v-for="opt in options"
          :key="opt.key"
          :class="[
            'ellipsis',
            'UbuSimpleDropdown_option',
            { 'UbuSimpleDropdown_optionSelected': opt === selectedValue }
          ]"
          @click="() => { $emit('input', opt.key); open = false }"
        >
          {{ opt.label }}
        </div>
      </div>
    </UbuButton>
  </div>
</template>
<script>
export default {
  name: 'UbuSimpleDropdown',
  props: {
    label: {
      type: String,
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: null,
      default: null,
    },
    defaultLabel: {
      type: String,
      default: 'Select a value',
    },
    noValuesLabel: {
      type: String,
      default: 'No selectable values',
    },
    help: {
      type: String,
      default: null,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      open: false,
    };
  },
  computed: {
    selectedValue() {
      const val = this.options.find((o) => o.key === this.value);
      return val;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/utils';

.UbuSimpleDropdown_wrapper {
  position: relative;

  &.UbuSimpleDropdown_label {
      padding: 8px;
      border: 1px solid var(--mercury);
      box-sizing: border-box;
      border-radius: 12px;
  }

  label {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 0.75rem;
    color: var(--darkgrey60);
    margin-bottom: 8px;
  }

  .UbuSimpleDropdown_dropdown {
      @extend .ubu-dropdown;
  }

  .UbuSimpleDropdown_triggerText {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    align-items: center;
  }

  .UbuSimpleDropdown_exp {
    transition: transform .3s linear;
    &.UbuSimpleDropdown_expOpen {
        transform: rotate(180deg);
    }
  }

  .UbuSimpleDropdown_options {
    .UbuSimpleDropdown_option {
      font-size: 0.85rem;
      font-weight: 600;
      padding: 4px 12px;
      border-radius: 12px;

      &:not(:last-child) {
        margin-bottom: 4px;
      }

      &:hover:not(.UbuSimpleDropdown_optionSelected):not(.UbuSimpleDropdown_unselectable) {
        color: var(--darkgrey);
        background-color: var(--mercury60);
      }

      &.UbuSimpleDropdown_optionSelected {
        color: var(--soapstone);
        background-color: var(--geraldine);
      }
    }
  }
}
</style>
