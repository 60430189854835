<template>
  <div>
    <h3 class="ubu-welcome py-6">
      Hello, {{ target }} 😍 !<br>
    </h3>
    <form class="UbuLoginForm">
      <div class="UbuLoginForm_row">
        <UbuTextField
          v-model="form.firstName"
          label="First name"
          placeholder="Chloe"
        />
        <UbuTextField
          v-model="form.lastName"
          label="Last name"
          placeholder="Diaz"
        />
      </div>
      <UbuTextField
        v-model="form.email"
        label="Email"
        :placeholder="placeholder | lowercase"
      />
      <UbuTextField
        v-model="form.password"
        label="Password"
        type="password"
        placeholder="************"
      />
      <UbuButton
        grow
        center
        big
        type="submit"
        secondary
        @click="sign()"
      >
        Sign up
      </UbuButton>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'TheAuthenticateSignupToken',
  data() {
    return {
      form: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
      },
    };
  },
  computed: {
    target() {
      if (this.form.firstName) return this.$options.filters.capitalize(this.form.firstName);
      return 'Friend';
    },
    placeholder() {
      return `${this.form.firstName || 'name'}${this.form.lastName ? `.${this.form.lastName}` : ''}@company.com`;
    },
    allFieldFilled() {
      const {
        firstName, lastName, email, password,
      } = this.form;
      return !!(firstName.trim().length
       && lastName.trim().length && email.trim().length && password.trim().length);
    },
  },
  mounted() {
    return Promise.resolve()
      .then(() => this.UP_inviteToken(this.$route.params.token))
      .then(() => {
        if (this.$route.params.token) {
          this.canUseToken().then(({ error }) => {
            if (error) {
              this.$buefy.toast.open({
                duration: 5000,
                message: 'Token expired, please contact your admin',
                type: 'is-danger',
              });
              this.$router.push('/auth/signup');
            }
          });
        }
      });
  },
  methods: {
    ...mapActions({
      signup: 'Authenticate/signup',
      UP_inviteToken: 'Authenticate/UP_inviteToken',
      canUseToken: 'Authenticate/canUseToken',
    }),
    sign() {
      // console.log('🚀 ~ file: UbuLoginBox.vue ~ line 63 ~ log ~ this.form', this.form);

      if (this.allFieldFilled) {
        this.signup(this.form)
          .then(() => {
            this.$buefy.toast.open({
              duration: 5000,
              message: 'User successfully created !',
              type: 'is-success',
            });
          })
          .then(() => this.$router.push(`/auth/login/${this.$route.params.token}`))
          .catch((error) => {
            this.$buefy.toast.open({
              duration: 5000,
              message: error.message,
              type: 'is-danger',
            });
          });
      } else {
        this.$buefy.toast.open({
          duration: 5000,
          message: 'All fields are required',
          type: 'is-error',
        });
      }
    },
  },
};
</script>

<style lang="scss">
  @import '@/styles/forms';
</style>
