<template>
  <b-dropdown
    id="UbuStatusDropdown"
    v-model="localCurrentStatus"
    aria-role="list"
  >
    <template #trigger>
      <b-button class="is-rounded is-light">
        <UbuIcon
          size="16px"
          :name="statusIcon"
          outlined
        />
        <span>
          {{ localCurrentStatus }}
        </span>
      </b-button>
    </template>

    <b-dropdown-item aria-role="listitem">
      <b-button
        class="is-rounded is-light"
        expanded
      >
        <UbuIcon
          size="16px"
          :name="statusIcon"
          outlined
        />
        <span>
          {{ localCurrentStatus }}
        </span>
      </b-button>
    </b-dropdown-item>
    <template v-for="(status, index) in statusList">
      <b-dropdown-item
        v-if="status.name !== localCurrentStatus"
        :key="index"
        aria-role="listitem"
        :value="status.name"
      >
        <div class="multibar row-vcentered spaced-right">
          <UbuIcon
            size="16px"
            :name="status.icon"
            outlined
            class="start"
          />
          <div class="start multibar row-vcentered">
            <span class="start">
              {{ status.name }}
            </span>
          </div>
        </div>
      </b-dropdown-item>
    </template>
  </b-dropdown>
</template>

<script>
export default {
  name: 'UbuStatusDropdown',
  props: {
    currentStatus: {
      type: String,
      required: true,
    },
    statusList: {
      type: Array,
      required: true,
    },
  },
  computed: {
    localCurrentStatus: {
      get() { return this.currentStatus; },
      set(newValue) { this.$emit('changeStatus', newValue); },
    },
    statusIcon() {
      const status = this.statusList.find(({ name }) => name === this.localCurrentStatus);
      return status ? status.icon : 'send';
    },
  },
};
</script>

<style lang="scss">
#UbuStatusDropdown {
  & .dropdown-menu {
    min-width: 100%;
    border-radius: 63px;

    & .dropdown-content {
      background-color: var(--soapstone);
    }
  }
  & .dropdown-item {
    padding: 0.375rem 1rem;
    &.is-active {
      color: var(--campaign-color);
      background-color: var(--campaign-color-lighten);
    }
    &:hover {
      background-color: var(--peachcream);
    }
  }
  & .button {
    font-weight: 600;
    color: var(--campaign-color);
    background-color: var(--campaign-color-lighten);
  }
}
</style>
