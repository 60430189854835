<template>
  <div class="UbuContactDataRow_wrapper">
    <div
      v-if="isLoading"
      class="icon is-small mr-4"
    >
      <b-skeleton
        height="20"
        width="20"
      />
    </div>
    <b-skeleton
      v-if="isLoading"
      height="20"
    />
    <span
      v-if="!isLoading && emoji"
      class="UbuContactDataRow_icon"
    >{{ emoji }}</span>
    <slot v-if="type == 'input' && !isLoading" />
    <span
      v-if="type == 'text' && !isLoading"
      class="ubu-text3"
    >
      {{ hideText || !text ? '-' : text }}
    </span>
    <b-icon
      v-if="canCopy"
      icon="content-copy"
      size="is-small"
      class="ml-2"
      style="cursor: pointer;"
      @click.native="copy()"
    />
    <img
      v-if="type === 'instaLink'"
      class="UbuContactDataRow_icon"
      src="/img/instagram-logo.svg"
    >
    <img
      v-if="type === 'tiktokLink'"
      class="UbuContactDataRow_icon"
      src="/img/tiktok-logo.svg"
    >
    <a
      v-if="type === 'instaLink'"
      :href="`https://instagram.com/${username}`"
      target="_blank"
      class="ubu-text3 link"
    >@{{ username }}&nbsp;<UbuIcon name="externalLink" />&nbsp;</a>
    <a
      v-if="type === 'tiktokLink'"
      :href="`https://www.tiktok.com/@${username}`"
      target="_blank"
      class="ubu-text3 link"
    >@{{ username }}&nbsp;<UbuIcon name="externalLink" />&nbsp;</a>
    <b-tooltip
      v-if="tooltip"
      v-bind="tooltip"
    >
      <UbuIcon name="info" />
    </b-tooltip>
  </div>
</template>
<script>
import { snackSuccess, snackError } from '../../$catalog/snackbar';

export default {
  name: 'UbuContactDataRow',
  props: {
    emoji: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'text',
    },
    value: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    username: {
      type: String,
      default: '',
    },
    hideText: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: Object,
      default: () => {},
    },
    canCopy: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    copy() {
      navigator.clipboard.writeText(this.text);
      this.$buefy.snackbar.open({
        ...snackSuccess,
        message: `"${this.text}" was copied to clipboard`,
      });
    },
  },
};
</script>
