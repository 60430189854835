<template>
  <div
    :class="['card ubuCardMessage', {
      'is-me': isMe,
      'is-story': isStory,
      'is-media': isMedia,
      'is-note': isNote,
      'is-text': isText,
      'is-error': isError,
      'is-hover': showAction,
    }]"
  >
    <div
      v-if="isMe"
      class="card-header"
    >
      <div class="card-header-icon">
        <UbuIcon
          clickable
          name="trash"
          size="16px"
          @click="$emit('deleteMessage')"
        />
      </div>
    </div>
    <div
      v-if="!isMe && isText"
      v-click-outside="() => showAction = false"
      class="card-header"
    >
      <b-dropdown
        class="card-header-icon"
        aria-role="list"
        position="is-top-right"
        @active-change="showAction = $event"
      >
        <template #trigger>
          <b-icon
            icon="dots-horizontal"
            size="is-small"
          />
        </template>

        <b-dropdown-item
          class="translate-action"
          custom
        >
          <span>
            <UbuIcon
              name="translate"
            /> Translate to: </span>

          <b-tooltip
            label="Translate"
          >
            <UbuIcon name="info" />
          </b-tooltip>
        </b-dropdown-item>

        <b-dropdown-item
          v-for="lang in languageList"
          :key="lang.value"
          :value="lang.value"
          @click="selectLanguage(lang.value)"
        >
          <div class="language-content">
            <b-image
              class="language-flag"
              :src="lang.icon"
            />
            {{ lang.label }}
          </div>
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <div
      :class="['card-image', { 'is-expired': isStoryExpired }]"
      @click="$emit('turnOnMedia')"
    >
      <a
        v-if="!isMe && downloadUrl"
        class="card-download-icon"
        :href="downloadUrl"
        @click.stop
      >
        <UbuIcon
          name="download"
        />
      </a>
      <slot name="image" />
    </div>
    <div class="card-content">
      <slot name="content" />
    </div>

    <div class="card-footer">
      <div
        v-if="!isError"
        class="card-footer-signature"
      >
        <span v-if="(isMe || isNote) && username">{{ username }} - </span>
        <span>{{ date }}</span>
      </div>
      <div
        v-else
        class="card-footer-signature multibar row-vcentered"
      >
        <span>Error while sending</span>
        <UbuIcon
          clickable
          name="refresh"
          size="14px"
          @click="$emit('resend')"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UbuCommonMessageCard',
  props: {
    date: {
      type: String,
      required: true,
    },
    isMe: {
      type: Boolean,
      default: false,
    },
    isStory: {
      type: Boolean,
      default: false,
    },
    isMedia: {
      type: Boolean,
      default: false,
    },
    isNote: {
      type: Boolean,
      default: false,
    },
    isText: {
      type: Boolean,
      default: false,
    },
    isStoryExpired: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    username: {
      type: String,
      default: '',
    },
    emojiList: {
      type: Array,
      default: () => ([]),
    },
    downloadUrl: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showAction: false,
      // languageList: ['en', 'fr', 'es', 'de'],
      languageList: [
        { label: 'English', value: 'en', icon: '/img/UK.svg' },
        { label: 'French', value: 'fr', icon: '/img/France.svg' },
        { label: 'Spanish', value: 'es', icon: '/img/Spain.svg' },
        { label: 'German', value: 'de', icon: '/img/Germany.svg' },
      ],
    };
  },
  methods: {
    selectLanguage(lang) {
      this.$emit('translateMessage', { target: lang });
      this.showAction = false;
    },
  },
};
</script>

<style lang="scss">
.message-action {
  position: absolute;
  width: 180px;
  padding: 16px;
  background-color: var(--soapstone);
  border: 1px solid var(--mercury);
  border-radius: 12px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  margin-top: -100px;
  margin-left: 35px;
  z-index: 99;
  & .message-translate {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid var(--darkgrey);
    border-bottom: 1px solid var(--darkgrey);
  }
}
</style>
