<template>
  <div
    id="UbuContactPanelHeader"
    class="UbuContactPanelHeader_wrapper"
  >
    <div class="UbuContactPanelHeader_panels">
      <div
        class="UbuContactPanelHeader_avatar"
        @click="$emit('change', emptyPanel)"
      >
        <UbuAvatar
          :key="contact.contactId"
          class="UbuContactPanelHeader_avatarImage"
          :size="panel.key ? '24px' : '80px'"
          :img-url="contact.has_bucket_media ? String(contact.contactId) : contact.profile_pic_url"
          :has-bucket="contact.has_bucket_media || false"
          :bucket-folder="'profile_pic'"
        />
      </div>
      <UbuIconButton
        v-for="(p, index) in panels"
        :key="index"
        hoverable
        :icon="p.icon"
        :is-active="p.key === panel.key"
        @click="$emit('change', p)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'UbuContactPanelHeader',
  model: {
    prop: 'panel',
    event: 'change',
  },
  props: {
    contact: {
      type: Object,
      default: () => ({}),
    },
    panel: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      panels: [
        {
          icon: 'note',
          key: 'notes',
          title: 'Notes',
        },
        {
          icon: 'mentions',
          key: 'mentions',
          title: 'Mentions',
        },
        {
          icon: 'messages',
          key: 'messages',
          title: 'Messages',
        },
        {
          icon: 'shop',
          key: 'orders',
          title: 'Affiliation',
        },
      ],
      emptyPanel: {
        icon: null,
        key: null,
        title: null,
      },
    };
  },
};
</script>

<style lang="scss">
#UbuContactPanelHeader {
  & .UbuContactPanelHeader_panels {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    & .UbuContactPanelHeader_avatar {
      align-self: center;
      & .UbuContactPanelHeader_avatarImage {
        transition: 0.1s all ease-out;
        cursor: pointer;
      }
    }
  }
}
</style>
