<template>
  <div class="UbuBaseModal_wrapper">
    <slot>
      <div class="UbuBaseModal_title ubu-title">
        {{ title }}
      </div>
      <div class="UbuBaseModal_info ubu-info">
        <slot name="info" />
      </div>
      <div
        v-if="pic"
        class="UbuBaseModal_img"
      >
        <img :src="getImageUrl(pic)">
      </div>
      <div class="UbuBaseModal_buttons">
        <slot name="actions" />
      </div>
    </slot>
  </div>
</template>

<script>

export default {
  props: {
    title: {
      type: String,
      default: 'Titre par defaut',
    },
    pic: {
      type: String,
      default: null,
    },
  },
  methods: {
    getImageUrl(img) {
      if (img) return require(`@/assets/images-modal/${img}`);
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
    .UbuBaseModal_wrapper {
        padding: 36px;
        border-radius: 24px;
        background-color: var(--soapstone);

        .UbuBaseModal_title {
            width: auto;
            margin-bottom: 26px;
        }

        .UbuBaseModal_img {
            display: flex;
            justify-content: center;
            margin-bottom: 26px;

            img {
                border-radius: 8px;
                max-width: 530px;
                max-height: 350px;
            }
        }

        .UbuBaseModal_info {
            margin-bottom: 26px;
        }

        .UbuBaseModal_buttons {
            display: flex;
            justify-content: space-between;
        }
    }
</style>
