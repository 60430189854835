<template>
  <form id="UbuTextarea">
    <VEmojiPicker
      v-if="showEmojiPicker"
      v-click-outside="() => showEmojiPicker = false"
      :dark="false"
      class="UbuMessageTextarea_emojiPicker"
      @select="selectEmoji($event)"
    />
    <b-field>
      <b-input
        ref="input"
        v-model.trim="localTemplateText"
        type="textarea"
        class="ubuTextarea"
        placeholder="Type a custom message here..."
        has-counter
        maxlength="1000"
        @blur="getCaretPosition($event)"
      />
    </b-field>
    <div
      v-if="showControls"
      class="UbuTextarea_controls multibar row-vcentered"
    >
      <UbuIcon
        clickable
        size="16px"
        name="emojis"
        class="start"
        @click="showEmojiPicker = !showEmojiPicker"
      />

      <div
        v-if="templateList.length"
        class="UbuTextarea_dropdownParent"
      >
        <UbuIcon
          v-click-outside="e => dropdownOpened = false"
          class="a-self-center"
          name="templates"
          :filled="false"
          clickable
          @click="dropdownOpened = !dropdownOpened"
        />
        <div
          v-if="dropdownOpened"
          class="UbuTextarea_dropdown"
        >
          <div
            v-for="t in templateList"
            :key="t._id"
          >
            <div
              class="ubu-label cursor-pointer ellipsis"
              @click="putTemplate(t)"
            >
              /{{ t.title }}
            </div>
          </div>
        </div>
      </div>
      <b-button
        class="is-rounded"
        size="is-small"
        @click="selectName()"
      >
        { name }
      </b-button>
    </div>
  </form>
</template>

<script>
import { VEmojiPicker } from 'v-emoji-picker';

export default {
  name: 'UbuTemplateTextarea',
  components: {
    VEmojiPicker,
  },
  model: {
    prop: 'templateText',
  },
  props: {
    showControls: {
      type: Boolean,
      default: false,
    },
    templateText: {
      type: String,
      required: true,
    },
    templateList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showEmojiPicker: false,
      dropdownOpened: false,
      cursor: 0,
    };
  },
  computed: {
    localTemplateText: {
      get() { return this.templateText; },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    getCaretPosition(event) {
      this.cursor = event.target.selectionStart;
    },
    selectEmoji(emoji) {
      const templateArray = this.localTemplateText.split('');
      templateArray.splice(this.cursor, 0, emoji.data);
      this.localTemplateText = templateArray.join('');
      this.$refs.input.focus();
      this.showEmojiPicker = false;
    },
    selectName() {
      const templateArray = this.localTemplateText.split('');
      templateArray.splice(this.cursor, 0, '{name}');
      this.localTemplateText = templateArray.join('');
      this.$refs.input.focus();
    },
    putTemplate(t) {
      const templateArray = this.localTemplateText.split('');
      templateArray.splice(this.cursor, 0, t.data);
      this.localTemplateText = templateArray.join('');
      this.$refs.input.focus();
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/common';
#UbuTextarea {
  position: relative;
  border: 1px solid var(--mercury60);
  background-color: var(--mercury20);
  border-radius: 8px;
  .ubuTextarea{
    & textarea{
      height: 140px;
      margin-bottom: 10px;
      background-color: transparent;
    }
    .help.counter {
      float: right;
      margin-right: 20px;
      margin-bottom: 10px;
    }
  }
  .field:not(:last-child) {
    margin-bottom: 20px;
  }
  & .UbuTextarea_controls{
    position: absolute;
    bottom: 0;
    padding: 0px 20px 20px 20px;
    & > * {
      margin-right: 10px;
    }
  }
  & .UbuMessageTextarea_emojiPicker {
    &.emoji-picker {
      --ep-color-bg: var(--seashellpeach) !important;
      --ep-color-sbg: var(--mercury60) !important;
      --ep-color-border: var(--mercury60) !important;
      --ep-color-text: var(--cloudburst) !important;
      --ep-color-active: var(--geraldine) !important;
      #InputSearch > div.container-search > input[type=text] {
        border-radius: 20px !important;
        outline: none;
      }
    }
    position: absolute;
    bottom: -240px;
    left: 40px;
    z-index: 20;
  }
  .UbuTextarea_dropdownParent {
    position: relative;

    .UbuTextarea_dropdown {
      z-index: 100;
      position: absolute;
      bottom: calc(100% + 6px);
      background-color: var(--soapstone);
      box-shadow: 0px 0px 10px var(--box-shadow);
      border-radius: 12px;
      padding: 12px;
      width: max-content;
      max-width: 200px;
    }
  }
}
.ubuTextarea textarea {
  border: none;
  box-shadow: none;
  resize: none;
}
</style>
