<template>
  <div
    id="UbuCommonMessage"
    :key="item.itemId || item.noteId"
    :class="positionSide"
  >
    <UbuCommonText
      v-if="item.uniType === 'isText' || item.uniType === 'isError'"
      :text-array="strTolinkArray(item.text)"
      :is-me="item.isMe"
      :date="item.when.format('HH:mm')"
      :username="userName"
      :is-error="item.uniType === 'isError'"
      @resend="$emit('resend', { text: item.text, errorMessageId: item.messageId })"
      @deleteMessage="$emit('deleteMessage', item.messageId)"
      @translateMessage="$emit('translateMessage', $event)"
    />

    <UbuCommonLink
      v-else-if="item.uniType === 'isLink'"
      :is-me="item.isMe"
      :date="item.when.format('HH:mm')"
      :text-array="textLinksArray(item.link)"
      :link="item.link"
      @deleteMessage="$emit('deleteMessage', item.messageId)"
    />

    <UbuCommonNote
      v-else-if="isNote"
      :is-me="false"
      :date="item.when.format('HH:mm')"
      :text="cleanHtml(item.text)"
      :username="userName"
      @deleteMessage="$emit('deleteMessage', item.messageId)"
    />

    <UbuCommonStoryMention
      v-else-if="item.uniType === 'isStoryMention'"
      :is-me="item.isMe"
      :date="item.when.format('HH:mm')"
      :username="contact.username"
      :is-story-expired="isStoryExpired"
      :image-url="getImageUrlFromNewMedia(item.newMedia)"
      :video-url="getVideoUrlFromNewMedia(item.newMedia)"
      :has-video="item.newMedia.hasVideo"
      :has-bucket="item.newMedia.hasBucket"
      @deleteMessage="$emit('deleteMessage', item.messageId)"
    />

    <UbuCommonStoryShare
      v-else-if="item.uniType === 'isStoryShare'"
      :is-me="item.isMe"
      :date="item.when.format('HH:mm')"
      :username="item.newDetails.username"
      :is-story-expired="isStoryExpired"
      :image-url="getImageUrlFromNewMedia(item.newMedia)"
      :video-url="getVideoUrlFromNewMedia(item.newMedia)"
      :has-video="item.newMedia.hasVideo"
      :has-bucket="item.newMedia.hasBucket"
      @deleteMessage="$emit('deleteMessage', item.messageId)"
    />

    <UbuCommonStoryReply
      v-else-if="item.uniType === 'isStoryReply'"
      :is-me="item.isMe"
      :date="item.when.format('HH:mm')"
      :text="item.text"
      :username="contact.username"
      :is-story-expired="isStoryExpired"
      :image-url="getImageUrlFromNewMedia(item.newMedia)"
      :video-url="getVideoUrlFromNewMedia(item.newMedia)"
      :has-video="item.newMedia.hasVideo"
      :has-bucket="item.newMedia.hasBucket"
      @deleteMessage="$emit('deleteMessage', item.messageId)"
    />

    <UbuCommonStoryReact
      v-else-if="item.uniType === 'isStoryReact'"
      :is-me="item.isMe"
      :date="item.when.format('HH:mm')"
      :is-story-expired="isStoryExpired"
      :react-emoji="item.text"
      :image-url="getImageUrlFromNewMedia(item.newMedia)"
      :video-url="getVideoUrlFromNewMedia(item.newMedia)"
      :has-video="item.newMedia.hasVideo"
      :has-bucket="item.newMedia.hasBucket"
      @deleteMessage="$emit('deleteMessage', item.messageId)"
    />

    <UbuCommonPostShare
      v-else-if="item.uniType === 'isPostShare'"
      :is-me="item.isMe"
      :date="item.when.format('HH:mm')"
      :username="item.newDetails.username"
      :text="item.newDetails.text"
      :carousel-media-img-url="getCarouselUrl(item.newMedia)"
      :has-bucket="item.newMedia.hasBucket"
      @deleteMessage="$emit('deleteMessage', item.messageId)"
    />

    <UbuCommonMediaForImage
      v-else-if="item.uniType === 'isMediaImage'"
      :is-me="item.isMe"
      :date="item.when.format('HH:mm')"
      :url="getImageUrlFromNewMedia(item.newMedia)"
      :has-bucket="item.newMedia.hasBucket"
      :download-url="getDownloadUrl(item)"
      @deleteMessage="$emit('deleteMessage', item.messageId)"
    />

    <UbuCommonMediaForVideo
      v-else-if="item.uniType === 'isMediaVideo'"
      :is-me="item.isMe"
      :date="item.when.format('HH:mm')"
      :image-url="getImageUrlFromNewMedia(item.newMedia)"
      :video-url="getVideoUrlFromNewMedia(item.newMedia)"
      :has-bucket="item.newMedia.hasBucket"
      :download-url="getDownloadUrl(item)"
      @deleteMessage="$emit('deleteMessage', item.messageId)"
    />

    <UbuCommonFelixShare
      v-else-if="item.uniType === 'isFelixShare'"
      :is-me="item.isMe"
      :date="item.when.format('HH:mm')"
      :username="item.newDetails.username"
      :image-url="getImageUrlFromNewMedia(item.newMedia)"
      :video-url="getVideoUrlFromNewMedia(item.newMedia)"
      :has-bucket="item.newMedia.hasBucket"
      @deleteMessage="$emit('deleteMessage', item.messageId)"
    />

    <UbuCommonClip
      v-else-if="item.uniType === 'isClip'"
      :is-me="item.isMe"
      :date="item.when.format('HH:mm')"
      :username="item.newDetails.username"
      :text="item.newDetails.text"
      :image-url="getImageUrlFromNewMedia(item.newMedia)"
      :video-url="getVideoUrlFromNewMedia(item.newMedia)"
      :has-video="item.newMedia.hasVideo"
      :has-bucket="item.newMedia.hasBucket"
      @deleteMessage="$emit('deleteMessage', item.messageId)"
    />

    <UbuCommonGif
      v-else-if="item.uniType === 'isAnimatedMedia'"
      :is-me="item.isMe"
      :date="item.when.format('HH:mm')"
      :url="getImageUrlFromNewMedia(item.newMedia)"
      @deleteMessage="$emit('deleteMessage', item.messageId)"
    />

    <UbuCommonProductShare
      v-else-if="item.uniType === 'isProductShare'"
      :is-me="item.isMe"
      :date="item.when.format('HH:mm')"
      :title="item.newDetails.name"
      :text="item.newDetails.description"
      :link-url="item.newDetails.link"
      :image-url="getImageUrlFromNewMedia(item.newMedia)"
      @deleteMessage="$emit('deleteMessage', item.messageId)"
    />

    <UbuCommonProfileShare
      v-else-if="item.uniType === 'isProfileShare'"
      :is-me="item.isMe"
      :date="item.when.format('HH:mm')"
      :username="item.newDetails.username"
      :profile-pic-url="item.newDetails.profilePicUrl"
      :full-name="item.newDetails.fullName"
      @deleteMessage="$emit('deleteMessage', item.messageId)"
    />

    <UbuCommonMediaForAudio
      v-else-if="item.uniType === 'isVoiceMedia'"
      :is-me="item.isMe"
      :date="item.when.format('HH:mm')"
      :url="getAudioUrlFromNewMedia(item.newMedia)"
      @deleteMessage="$emit('deleteMessage', item.messageId)"
    />

    <UbuCommonActionLog
      v-else-if="item.uniType === 'isActionLog'"
      :is-me="item.isMe"
      :date="item.when.format('HH:mm')"
      :username="contact.username"
      :item="item"
      @deleteMessage="$emit('deleteMessage', item.messageId)"
    />

    <UbuCommonComment
      v-else-if="item.uniType === 'isComment'"
      :is-me="item.isMe"
      :date="item.when.format('HH:mm')"
      :text="item.text"
      :username="item.username"
      :is-error="item.isError"
      :url="commentImageUrl(item)"
      @clickToReply="$emit('clickToReply', $event)"
      @resend="$emit('resendComment', item)"
      @deleteMessage="$emit('deleteComment', item.commentId)"
      @translateMessage="$emit('translateMessage', $event)"
    />

    <UbuCommonUnsupportedType
      v-else
      :date="item.when.format('HH:mm')"
      :username="contact.username"
      :item-type="currentItemType"
      :is-me="item.isMe"
      :thread-id="item.threadId"
      @deleteMessage="$emit('deleteMessage', item.messageId)"
    />
  </div>
</template>

<script>
import MessageMixin from '../$mixins/MessageMixin';

export default {
  name: 'UbuCommonMessage',
  mixins: [MessageMixin],
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    contact: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
  },
};
</script>

<style lang="scss">
@import '../message';
#UbuCommonMessage {
  margin: 4px 16px;
  // position: relative;
  // .UbuCommonMessage_delete{
  //   display: none;
  //   position: absolute;
  //   top: -10px;
  //   right: -10px;
  //   width: 27px;
  //   height: 27px;
  //   justify-content: center;
  //   align-items: center;
  //   background: var(--givry);
  //   border: 3px solid var(--seashellpeach);
  //   border-radius: 50%;
  //   z-index: 5;
  //   box-sizing: content-box;
  // }
  // &:hover{
  //   .UbuCommonMessage_delete{
  //     display: flex;
  //   }
  // }
}
// .ubuMessage-margin {
//   margin: 4px 16px;
// }

// .ubuCommonLink {
//   word-break: break-word;
//   padding: 16px;
//   color: var(--cola);
// }

// .ubuCommonLink_link {
//   color: var(--cola);
//   text-decoration: none;
//   font-weight: bold;
// }

// .mediaExpired {
//   position: relative;
//   &.expirationBox {
//     color: white;
//     display: flex;
//     position: absolute;
//     flex-direction: column;
//     top: 0;
//     justify-content: center;
//     align-items: center;
//     height: 100%;
//     width: 100%;
//   }
// }

// .ubuCommonMedia_wrapper {
//   cursor: pointer;
//   overflow: hidden;
//   img, video {
//     display: block;
//     width: 100%;
//     &:focus {
//       outline: none;
//     }
//   }
//   &.ubuAudio_wrapper {
//       min-width: 200px;
//       &audio {
//         width: 100%;
//       }
//   }
// }
</style>
