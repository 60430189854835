// import { updateDatasIfExists } from '../$utils/dataMapper';
/* eslint-disable max-len */
export default {
  namespaced: true,

  state: {
    ubuUsersDoing: {
      // [ubuUserId]: {
      //   ubuUserId: '',
      //   threadId: '',
      //   firstName: '',
      //   lastName: '',
      //   email: '',
      //   doing: '',
      // },
    },
  },

  getters: {
    getterUbuUsers: (state) => state.ubuUsersDoing,
    getterUbuUserById: (state) => (id) => state.ubuUsers[id],
  },

  actions: {
    SOCKET_doing({ commit }, payload) {
      // console.log('🚀 ~ file: State.store.js ~ line 26 ~ SOCKET_doing ~ payload', payload);
      commit('SET_UBUUSER_DOING', payload);
    },
  },
  mutations: {
    SET_UBUUSERS_DOING(state, newDatas) { state.ubuUsersDoing = newDatas; },
    SET_UBUUSER_DOING(state, { ubuUserId, threadId, doing }) { state.ubuUsersDoing[ubuUserId] = { ...state.ubuUsersDoing[ubuUserId], threadId, doing }; },

    RESET_STORES(state) {
      state.ubuUsersDoing = {};
    },
  },
};
