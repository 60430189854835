<template>
  <div>
    <div
      v-if="loading"
      class="UbuInstaUserCard"
    >
      <figure class="avatar">
        <b-skeleton
          circle
          height="40px"
          width="40px"
        />
      </figure>
      <div class="media-content">
        <div class="content">
          <p>
            <b-skeleton
              active
              width="200px"
            />
            <b-skeleton
              active
              width="150px"
            />
          </p>
        </div>
      </div>
    </div>
    <div
      v-else
      :class="['UbuInstaUserCard', {'selected' : selected}, {'disabled' : disabled}]"
      @click="handleClick($event)"
    >
      <div
        v-if="disabled && disabledText"
        class="disabledText ubu-text1"
      >
        {{ disabledText }}
      </div>
      <UbuInstaImg
        class="avatar"
        :insta-src="instaUser.profile_pic_url"
        rounded
        fallback="/img/no_profile_pict.svg"
      />
      <div class="infos">
        <div class="infosTop ubu-text1">
          <span>{{ instaUser.username }}</span>
          <b-icon
            v-if="instaUser.is_verified"
            class="icon"
            icon="check-decagram"
            size="is-small"
          />
        </div>
        <span class="name ubu-subtext1">{{ instaUser.full_name }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'UbuInstaUserCard',
  props: {
    instaUser: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledText: {
      type: String,
      default: '',
    },
  },
  methods: {
    handleClick($event) {
      if (!this.disabled) this.$emit('click', $event);
    },
  },
};
</script>
