import { updateDatasIfExists } from '../$utils/dataMapper';
/* eslint-disable max-len */
export default {
  namespaced: true,

  state: {
    notes: {},
  },

  getters: {
    getterNotes: (state) => state.notes,
  },

  actions: {
    SOCKET_newNote({ commit }, payload) {
      // console.log('🚀 ~ file: Note.store.js ~ line 16 ~ SOCKET_newNote ~ payload', payload);
      const { threadId, noteId } = payload;
      commit('SET_NOTES', { [noteId]: { ...payload } });
      // commit('Thread/SET_THREADS_HAS_NOTES', [{ threadId, noteId }], { root: true });
      commit('Thread/ADD_NOTES_TO_THREAD', { threadId, noteIds: [noteId] }, { root: true });
    },
  },
  mutations: {
    SET_NOTES(state, newDatas) { state.notes = updateDatasIfExists({ newDatas, actualDatas: state.notes, key: 'noteId' }); },
    RESET_STORES(state) {
      state.notes = {};
    },
  },
};
