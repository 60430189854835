<template>
  <UbuButton
    id="UbuSelectorTrigger"
    :active="active"
    :color="values.length === 1 ? values[0][colorProp] : null"
    v-bind="$attrs"
  >
    <template slot="icon">
      <UbuIcon
        v-if="!values.length"
        filled
        :name="defaultLabelIcon"
      />
      <UbuIcon
        v-for="(value, index) in values"
        :key="`selected-${index}`"
        v-bind="typeof value[iconProp] === 'object' ? value[iconProp] : {
          name: value[iconProp],
          filled: true,
          color: values.length > 1 ? value[colorProp] : null
        }"
      />
    </template>
    <template v-if="values.length === 1 && !reduced">
      <span class="ellipsis">{{ values[0][labelProp] }}</span>
    </template>
  </UbuButton>
</template>

<script>
export default {
  name: 'UbuSelectorLabel',
  props: {
    defaultLabelIcon: {
      type: String,
      default: 'influence',
    },
    iconProp: {
      type: String,
      default: 'icon',
    },
    labelProp: {
      type: String,
      default: 'label',
    },
    colorProp: {
      type: String,
      default: 'color',
    },
    values: {
      type: Array,
      default: () => ([]),
    },
    active: {
      type: null,
      default: false,
    },
    reduced: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
#UbuSelectorTrigger {
  max-width: 100%;
  height: 34px;
  border-radius: 24px;
  border: none;
}
</style>
