<template>
  <div id="UbuWorkspaceCustom">
    <div class="UbuWorkspaceCustoms_tag">
      <div class="UbuWorkspaceCustoms_editTag">
        <div class="UbuWorkspaceCustoms_customs">
          <div class="UbuWorkspaceCustoms_dropdownParent">
            <UbuIcon
              class="a-self-center mr-2"
              :name="iconName"
              filled
              :color="custom.color"
              @click="dropdownOpened = !dropdownOpened"
            />
          </div>
          <div class="ubu-label">
            {{ custom.title }}
          </div>
        </div>
        <div
          v-if="hasKeywords"
          class="ubu-subtext1 UbuWorkspaceCustoms_infoText"
        >
          Auto-tag with keywords:
        </div>
        <UbuWorkspaceKeywords
          v-if="hasKeywords"
          :keywords="keywords"
          @addKeywords="addKeywords($event)"
        />
        <div
          v-if="keywords.length > 0"
          class="UbuWorkspaceCustoms_autoclose ubu-text3"
        >
          <b-switch
            :value="isAutoclose"
            @input="setAutoclose($event)"
          >
            Auto close when tag is added automatically
          </b-switch>
        </div>
        <div
          v-if="custom.type === 'tag'"
          class="flex ubu-subtext1 UbuWorkspaceCustoms_infoText"
        >
          <div>Auto-flush </div>
          <b-tooltip
            label="Close and remove tag x days after the last message"
            size="is-large"
            position="is-right"
            multilined
          >
            <UbuIcon name="info" />
          </b-tooltip>
          <div> :</div>
        </div>
        <div
          v-if="custom.type === 'tag'"
          class="flex"
        >
          <b-switch
            :value="localNbDaysFlush > 0"
            @input="toggleAutoflush()"
          />
          <b-numberinput
            v-if="localNbDaysFlush > 0"
            v-model="localNbDaysFlush"
            class="UbuWorkspaceCustoms_numberInput"
            controls-alignment="right"
            type="is-light"
            size="is-small"
            :min="0"
            :max="30"
          />
          <UbuButton
            v-if="localNbDaysFlush > 0"
            class="UbuWorkspaceCustoms_button"
            secondary
            @click="setAutoflush()"
          >
            Save
          </UbuButton>
        </div>
      </div>
      <div class="UbuWorkspaceCustoms_buttons">
        <UbuButton
          v-if="isUpdatable"
          class="UbuWorkspaceCustoms_button"
          @click="isCardModalActive = !isCardModalActive"
        >
          Edit
        </UbuButton>
        <UbuButton
          v-if="isUpdatable"
          class="UbuWorkspaceCustoms_button"
          color="2"
          @click="$emit('deleteCustom', custom)"
        >
          Delete
        </UbuButton>
      </div>
      <b-modal
        v-model="isCardModalActive"
        :width="640"
        scroll="keep"
      >
        <UbuWorkspaceModalEditTag
          v-if="custom.type === 'tag'"
          :is-card-modal-active="isCardModalActive"
          :custom="custom"
          @close="isCardModalActive = false"
          @editFromModal="editFromModal($event)"
        />
        <UbuWorkspaceModalEditFolder
          v-if="custom.type === 'label'"
          :is-card-modal-active="isCardModalActive"
          :custom="custom"
          @close="isCardModalActive = false"
          @editFromModal="editFromModal($event)"
        />
      </b-modal>
    </div>
    <div class="UbuWorkspaceCustoms_divider" />
  </div>
</template>
<script>

export default {
  name: 'UbuWorkspaceCustoms',
  props: {
    custom: {
      type: Object,
      default: () => {},
    },
    hasKeywords: Boolean,
    isUpdatable: Boolean,
  },
  data() {
    return {
      dropdownOpened: false,
      isCardModalActive: false,
      newNbDaysFlush: null,
    };
  },
  computed: {
    iconName() {
      return this.custom.type === 'label' ? 'contact' : this.custom.type;
    },
    keywords() {
      const { data } = this.custom;
      if (!data) return [];
      const { keywords } = data;
      return keywords || [];
    },
    isAutoclose() {
      const { data } = this.custom;
      if (!data) return false;
      const { autoclose } = data;
      return autoclose || false;
    },
    nbDaysFlush() {
      const { data } = this.custom;
      if (!data) return 0;
      const { autoflush } = data;
      return autoflush || 0;
    },
    localNbDaysFlush: {
      get() {
        if (this.newNbDaysFlush == null) return this.nbDaysFlush;
        return this.newNbDaysFlush;
      },
      set(value) {
        this.newNbDaysFlush = value;
        if (this.newNbDaysFlush === 0) this.setAutoflush();
      },
    },
  },
  methods: {
    addKeywords(event) {
      const tagWithKeywords = {
        ...this.custom,
        data: { ...this.custom.data, keywords: event },
      };
      this.$emit('updateTagWithData', tagWithKeywords);
    },
    setAutoclose(event) {
      const tagWithData = {
        ...this.custom,
        data: { ...this.custom.data, autoclose: event },
      };
      this.$emit('updateTagWithData', tagWithData);
    },
    toggleAutoflush() {
      if (this.localNbDaysFlush > 0) this.localNbDaysFlush = 0;
      else {
        this.localNbDaysFlush = 1;
        this.setAutoflush();
      }
    },
    setAutoflush() {
      const tagWithData = {
        ...this.custom,
        data: { ...this.custom.data, autoflush: this.localNbDaysFlush },
      };
      this.$emit('updateTagWithData', tagWithData);
    },
    editFromModal($event) {
      this.$emit('editFromModal', $event);
      this.isCardModalActive = false;
    },
  },
};
</script>
