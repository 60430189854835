/* eslint-disable no-multi-spaces */
// const TheAccount = () => import(/* webpackChunkName: "addonWorkspace" */'./TheAccount');
// const TheHome = () => import(/* webpackChunkName: "addonWorkspace" */'./TheHome');
// const TheWorkspace = () => import(/* webpackChunkName: "addonWorkspace" */'./TheWorkspace');
// const TheWorkspaceConnection = () => import(/* webpackChunkName: "addonWorkspace" */'./TheWorkspaceConnection');
// const TheWorkspaceFolders = () => import(/* webpackChunkName: "addonWorkspace" */'./TheWorkspaceFolders');
// const TheWorkspaceTags = () => import(/* webpackChunkName: "addonWorkspace" */'./TheWorkspaceTags');
// const TheWorkspaceTeam = () => import(/* webpackChunkName: "addonWorkspace" */'./TheWorkspaceTeam');
// const TheWorkspaceTemplates = () => import(/* webpackChunkName: "addonWorkspace" */'./TheWorkspaceTemplates');
// const TheWorkspaceTheme = () => import(/* webpackChunkName: "addonWorkspace" */'./TheWorkspaceTheme');

import TheAccount  from './TheAccount';
import TheHome  from './TheHome';
import TheWorkspace  from './TheWorkspace';
import TheWorkspaceConnection  from './TheWorkspaceConnection';
import TheWorkspaceFolders  from './TheWorkspaceFolders';
import TheWorkspaceTags  from './TheWorkspaceTags';
import TheWorkspaceTeam  from './TheWorkspaceTeam';
import TheWorkspaceTemplates  from './TheWorkspaceTemplates';
import TheWorkspaceTheme  from './TheWorkspaceTheme';
import TheWorkspaceShopify  from './TheWorkspaceShopify';

export default [
  {
    path: '/home',
    name: 'Home',
    component: TheHome,
    meta: {
      auth: true,
      requireData: true,
    },
  },
  {
    path: '/account',
    name: 'Account',
    component: TheAccount,
    meta: {
      auth: true,
      requireData: true,
      overrideActiveIcon: 'home',
    },
  },
  {
    path: '/workspace',
    name: 'Workspace',
    component: TheWorkspace,
    redirect: '/workspace/team',
    meta: {
      auth: true,
      requireData: true,
      overrideActiveIcon: 'home',
    },
    children: [
      {
      // alias: '',
        path: '/workspace/team',
        name: 'Workspace Team',
        component: TheWorkspaceTeam,
        meta: {
          auth: true,
          requireData: true,
        },
      },
      {
        path: '/workspace/tags',
        name: 'Workspace Tags',
        component: TheWorkspaceTags,
        meta: {
          auth: true,
          requireData: true,
        },
      },
      {
        path: '/workspace/folders',
        name: 'Workspace Folders',
        component: TheWorkspaceFolders,
        meta: {
          auth: true,
          requireData: true,
        },
      },
      {
        path: '/workspace/shopify',
        name: 'Workspace Shopify',
        component: TheWorkspaceShopify,
        meta: {
          auth: true,
          requireData: true,
        },
      },
      {
        path: '/workspace/templates',
        name: 'Workspace Templates',
        component: TheWorkspaceTemplates,
        meta: {
          auth: true,
          requireData: true,
        },
      },
      {
        path: '/workspace/theme',
        name: 'Workspace Theme',
        component: TheWorkspaceTheme,
        meta: {
          auth: true,
          requireData: true,
        },
      },
      {
        path: '/workspace/connection',
        name: 'Workspace Conection',
        component: TheWorkspaceConnection,
        meta: {
          auth: true,
          requireData: true,
        },
      },
    ],
  },
];
