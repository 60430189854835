<template>
  <div
    id="UbuContactPanelModalTiktokUsername"
    class="workspace-modal"
  >
    <div class="modal-header">
      <p class="modal-header-title ubu-title">Add/Update TikTok username</p>

      <UbuIcon
        name="cross"
        clickable
        @click="closeModal()"
      />
    </div>
    <div class="UbuModal_note">
      <h2>NOTE:</h2>
      <p class="ubu-text1">{{ noteText }}</p>
    </div>
    <b-image
      src="/img/example-username-tiktok.jpg"
      alt="Example username TikTok in url"
      class="exampleImage mb-3"
    />
    <div class="modal-content">
      <UbuTextField
        v-model="username"
        class="nameInput ubu-text3"
        label="Tiktok username"
        placeholder="Tiktok username..."
        :error="errorName"
      />
    </div>
    <div class="modal-footer mt-4">
      <UbuButton
        class="mr-2"
        center
        grow
        @click="closeModal()"
      >
        Cancel
      </UbuButton>
      <UbuButton
        center
        grow
        secondary
        :disabled="!canSave"
        @click="addFromModal()"
      >
        Save
      </UbuButton>
    </div>
  </div>
</template>

<script>

export default {
  name: 'UbuContactPanelModalTiktokUsername',
  props: {
  },
  data() {
    return {
      username: '',
      noteText: 'Please write the username as it is written in the url of the TikTok profile, like on the example bellow 👇',
    };
  },
  computed: {
    errorName() {
      if (!this.username) return '';
      return !this.username.match('.*[?@].*') ? '' : "Don't use those characters: @?";
    },
    canSave() {
      if (!this.username || this.username.match('.*[?@].*')) return false;
      return true;
    },
  },
  methods: {
    addFromModal() {
      if (this.canSave) this.$emit('addFromModal', { username: this.username });
    },
    closeModal() {
      this.$emit('close');
    },
  },
};
</script>
