<template>
  <div id="UbuThreadList">
    <div v-if="!bulks.length" class="UbuThreads_header">
      <slot name="header" />
      <div class="UbuThreads_genericHeader">
        <UbuSimpleInput
          v-model="search"
          placeholder="Search..."
          searchbar
        >
          <UbuIcon
            slot="placeholderIcon"
            name="search"
          />
        </UbuSimpleInput>
        <UbuButton
          icon="filter"
          :active="filterBoxIsOpen"
          :tooltip="{ label: 'Filter', position: 'is-bottom' }"
          @click="$emit('update:filterBoxIsOpen', !filterBoxIsOpen)"
        />
        <UbuButton
          icon="bulk"
          :tooltip="{ label: 'Bulk', position: 'is-bottom' }"
          @click="openBulkPanel"
        />
        <UbuButton
          icon="compose"
          :tooltip="{ label: 'New message', position: 'is-bottom' }"
          @click="isCardModalActive = !isCardModalActive"
        />
      </div>
      <b-modal
        v-model="isCardModalActive"
        :width="640"
        scroll="keep"
      >
        <UbuInboxModalCreateThread
          v-model="localUsernameValue"
          :user-fetched="userFetched"
          :is-fetching="isFetching"
          :template-list="templateList"
          @createThread="createThread($event)"
          @close="closeModal()"
        />
      </b-modal>
      <div
        v-if="filterBoxIsOpen"
        class="UbuThreads_filters"
      >
        <UbuSimpleDropdown
          label="Only show"
          :options="filterOptions"
          :value="filter"
          @input="$emit('update:filter', $event)"
        />
        <UbuSimpleDropdown
          label="Sort by"
          :options="sortOptions"
          :value="sort"
          @input="$emit('update:sort', $event)"
        />
      </div>
    </div>
    <div
      v-if="bulks.length"
      class="UbuThreads_bulks"
    >
      <div class="UbuThreads_bulkHeader">
        <a
          v-if="bulks.length < filteredThreads.length"
          class="text-geraldine ubu-text1"
          @click="$emit('update:bulks', filteredThreads)"
        >Select All</a>
        <a
          v-else
          class="text-geraldine ubu-text1"
          @click="$emit('update:bulks', [])"
        >Unselect All</a>
        <a
          class="text-darkgrey60 ubu-text1"
          @click="$emit('update:bulks', [])"
        >Cancel</a>
      </div>
      <div class="UbuThreads_bulkActions">
        <slot
          name="bulkActions"
          :bulks="bulks"
        />
      </div>
    </div>
    <template v-if="filteredThreads.length">
      <UbuThreadCard
        v-if="stickyCardIs === 'top' && selectedThread"
        class="UbuThreads_stickyCard is-top"
        :is-away="selectedThread.isAway"
        :bro-selected="!!bulks.length"
        :contact="selectedThread.contact"
        :source="selectedThread.source"
        :thread-groups="selectedThread.threadGroups"
        :contact-groups="selectedThread.contactGroups"
        :assignments="selectedThread.assignments"
        :unread="selectedThread.isUnread"
        :last-message="selectedThread.last_permanent_item"
        :selected="true"
        :bulk-selected="isSelectedBulk(selectedThread.threadId)"
        @onSelect="scrollToThreadId(selectedThread.threadId)"
        @onBulkSelect="() => toggleBulk(selectedThread)"
      />
      <RecycleScroller
        ref="scroller"
        :item-size="72"
        :items="filteredThreads"
        key-field="threadId"
        class="UbuThreads_list ubu-scrollbar"
        :emit-update="true"
        @update="updateVisibleIndex"
      >
        <template
          v-slot="{ item, index }"
        >
          <UbuThreadCard
            :is-away="item.isAway"
            :bro-selected="!!bulks.length"
            :contact="item.contact"
            :source="item.source"
            :thread-groups="item.threadGroups"
            :contact-groups="item.contactGroups"
            :assignments="item.assignments"
            :unread="item.isUnread"
            :last-message="item.last_permanent_item"
            :selected="selectedThread && selectedThread.threadId === item.threadId"
            :bulk-selected="isSelectedBulk(item.threadId)"
            @onSelect="onSelectThreadCard({ thread: item, index })"
            @onBulkSelect="toggleBulk(item)"
            @onBadgeClick="onReadThread(item)"
          />
        </template>
      </RecycleScroller>
      <UbuThreadCard
        v-if="stickyCardIs === 'bottom' && selectedThread"
        class="UbuThreads_stickyCard is-bottom"
        :is-away="selectedThread.isAway"
        :bro-selected="!!bulks.length"
        :contact="selectedThread.contact"
        :source="selectedThread.source"
        :thread-groups="selectedThread.threadGroups"
        :contact-groups="selectedThread.contactGroups"
        :assignments="selectedThread.assignments"
        :unread="selectedThread.isUnread"
        :last-message="selectedThread.last_permanent_item"
        :selected="true"
        :bulk-selected="isSelectedBulk(selectedThread.threadId)"
        @onSelect="scrollToThreadId(selectedThread.threadId)"
        @onBulkSelect="() => toggleBulk(selectedThread)"
      />
    </template>

    <div
      v-else-if="isSearching"
      class="UbuThreads_emptyList ubu-subtext1"
    >
      <article
        v-for="index in 10"
        :key="index"
        class="threadCardSkeleton multibar row-vcentered"
      >
        <div class="start">
          <b-skeleton
            circle
            width="40px"
            height="40px"
          />
        </div>
        <div class="center">
          <div class="multibar row-vcentered">
            <b-skeleton
              active
              width="200px"
            />
            <b-skeleton
              active
              width="20px"
            />
          </div>
          <div class="multibar row-vcentered">
            <b-skeleton
              active
              width="200px"
            />
            <b-skeleton
              active
              width="20px"
            />
          </div>
        </div>
      </article>
    </div>
    <div
      v-else-if="results.length"
      class="UbuThreads_list"
    >
      <UbuCommonSearchCard
        v-for="(res, index) in results"
        id="UbuThreadCard"
        :key="index"
        class="UbuThreads_searchCard"
        :contact="res"
        @onSelect="selectThreadOnSearch(res.threadId)"
      />
    </div>
    <div
      v-else
      class="UbuThreads_emptyList ubu-subtext1"
    >
      No messages here
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { debounce } from '../../$utils/helpers';
import { mostRelevantThreadRouterLink } from '../routesMeta';

export default {
  name: 'UbuThreadList',
  model: {
    prop: 'usernameSearch',
  },
  props: {
    threads: {
      type: Array,
      default: () => [],
    },
    selectedThread: {
      type: Object,
      default: () => {},
    },
    sort: {
      type: String,
      default: 'asc:timestamp',
    },
    filter: {
      type: String,
      default: 'all',
    },
    filterOptions: {
      type: Array,
      default: () => ([]),
    },
    sortOptions: {
      type: Array,
      default: () => ([]),
    },
    filterBoxIsOpen: {
      type: Boolean,
      default: false,
    },
    isFetching: {
      type: Boolean,
      default: false,
    },
    bulks: {
      type: Array,
      default: () => [],
    },
    userFetched: {
      type: Array,
      default: () => [],
    },
    usernameSearch: {
      type: String,
      default: '',
    },
    templateList: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    search: '',
    debouncedSearch: '',
    results: [],
    isSearching: false,
    stickyCardIs: null,
    isCardModalActive: false,
  }),
  computed: {
    sortedThreads() {
      return this.threads.filter(({ threadId }) => threadId);
    },
    filteredThreads() {
      if (!this.debouncedSearch) return this.sortedThreads;
      const filtered = this.sortedThreads.filter((t) => {
        // if (String(t.threadId) === String(this.selectedThreadId)) { return true; }
        if (!t || !t.contact) return false;
        // eslint-disable-next-line camelcase
        const { full_name, username } = t.contact;
        const lastItem = t.last_permanent_item;
        const isMatching = this.match(full_name)
          || this.match(username)
          || (lastItem ? this.match(lastItem.text) : false);
        return isMatching;
      });

      if (this.debouncedSearch.length > 3 && !filtered.length) {
        this.searchBase(this.debouncedSearch);
      }

      return filtered;
    },
    selectedThreadIndex() {
      if (this.selectedThread) {
        return this.filteredThreads
          .findIndex((thread) => this.selectedThread.threadId === thread.threadId);
      }
      return -1;
    },
    localUsernameValue: {
      get() { return this.usernameSearch; },
      set(value) { this.$emit('input', value); },
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    search: debounce(function (newVal) {
      this.debouncedSearch = newVal;
    }, 1000),
  },
  methods: {
    isSelectedBulk(id) {
      return this.bulks.findIndex((b) => b.threadId === id) > -1;
    },
    ...mapActions({
      searchThreadsItems: 'TheThreadList/searchThreadsItems',
      readThread: 'TheThreadList/readThread',
      fetchOnThreadDbByChannel: 'Thread/fetchOnThreadDbByChannel',
    }),
    createThread(event) {
      this.$emit('createThread', event);
      this.isCardModalActive = false;
    },
    onReadThread(thread) {
      this.readThread(thread);
    },
    updateVisibleIndex(start, end) {
      if (start > this.selectedThreadIndex && this.selectedThreadIndex > -1) {
        this.stickyCardIs = 'top';
      } else if (end < this.selectedThreadIndex) {
        this.stickyCardIs = 'bottom';
      } else {
        this.stickyCardIs = null;
      }
    },
    scrollToThreadId(threadId) {
      const { scroller } = this.$refs;
      const idx = this.filteredThreads.findIndex((thread) => threadId === thread.threadId);
      scroller.scrollToItem(idx);
    },
    selectThreadOnSearch(threadId) {
      this.debouncedSearch = '';
      this.search = '';
      this.fetchOnThreadDbByChannel(threadId)
        .then(() => {
          this.results = [];
          this.$emit('onSelectThread', { thread: { threadId } });
        });
    },
    searchBase(search) {
      this.isSearching = true;
      return this.searchThreadsItems(search)
        .then((results) => {
          console.log('🚀 ~ file: UbuThreadList.vue ~ line 201 ~ .then ~ results', results);
          // this.debouncedSearch = '';
          this.results = results
            .map((result) => ({ ...result.correspondent, threadId: result.thread_id }));
          this.isSearching = false;
        });
    },
    match(text) {
      if (!text) return false;
      return text.toLowerCase().includes(this.search.toLowerCase());
    },
    openBulkPanel() {
      if (this.selectedThread) this.toggleBulk(this.selectedThread);
      else if (this.filteredThreads.length) {
        this.toggleBulk(this.filteredThreads[0]);
      }
    },
    toggleBulk(thread) {
      const idx = this.bulks.findIndex((b) => b.threadId === thread.threadId);
      if (idx > -1) this.$emit('update:bulks', [...this.bulks.filter((item, _idx) => idx !== _idx)]);
      else this.$emit('update:bulks', [thread, ...this.bulks]);
    },
    onSelectThreadCard({ thread, index }) {
      if (!thread.isAway) this.$emit('onSelectThread', { thread, index });
      else {
        const route = mostRelevantThreadRouterLink(thread, thread.is);
        this.$router.push(route);
      }
    },
    closeModal() {
      this.isCardModalActive = false;
      this.$emit('closeModal');
    },
  },
};
</script>
