import { use, registerComponent } from '../../$utils/plugins';

// const UbuContactCard = () => import(/* webpackChunkName: "addonUi" */ './UbuContactCard');
// const UbuStatusDropdown = () => import(/* webpackChunkName: "addonUi" */ './UbuStatusDropdown');
// const UbuEnhancer = () => import(/* webpackChunkName: "addonUi" */ './UbuEnhancer');
// const UbuSimpleInput = () => import(/* webpackChunkName: "addonUi" */ './UbuSimpleInput');
// const UbuTemplateCard = () => import(/* webpackChunkName: "addonUi" */ './UbuTemplateCard');
// const UbuTemplateTextarea = () => import(/* webpackChunkName: "addonUi" */ './UbuTemplateTextarea');

import UbuAvatar from './UbuAvatar';
import UbuBadge from './UbuBadge';
import UbuButton from './UbuButton';
// import UbuChip from './UbuChip';
import UbuColorPicker from './UbuColorPicker';
import UbuContactName from './UbuContactName';
import UbuDropdownSelector from './UbuDropdownSelector';
import UbuSelector from './UbuSelector';
import UbuSelectorLabel from './UbuSelectorLabel';
import UbuEllipsis from './UbuEllipsis';
import UbuIcon from './UbuIcon';
import UbuIconButton from './UbuIconButton';
import UbuInstaImg from './UbuInstaImg';
import UbuInstaName from './UbuInstaName';
import UbuLoader from './UbuLoader';
import UbuLogo from './UbuLogo';
import UbuMenuButton from './UbuMenuButton';
import UbuNote from './UbuNote';
import UbuRadio from './UbuRadio';
import UbuSimpleDropdown from './UbuSimpleDropdown';
import UbuTabLink from './UbuTabLink';
import UbuTextField from './UbuTextField';
import UbuTitleContent from './UbuTitleContent';
import UbuUserPicture from './UbuUserPicture';
import UbuContactCard from './UbuContactCard';
import UbuStatusDropdown from './UbuStatusDropdown';
import UbuEnhancer from './UbuEnhancer';
import UbuSimpleInput from './UbuSimpleInput';
import UbuTemplateCard from './UbuTemplateCard';
import UbuTemplateTextarea from './UbuTemplateTextarea';
import UbuFollowersSelector from './UbuFollowersSelector';
import UbuStatisticCard from './UbuStatisticCard';
import UbuDatePicker from './UbuDatePicker';
import UbuInstaUserCard from './UbuInstaUserCard';
import UbuRangeSelector from './UbuRangeSelector';
import UbuCheckboxSelector from './UbuCheckboxSelector';
import UbuNumberInputRangeSelector from './UbuNumberInputRangeSelector';
import UbuRadioSelector from './UbuRadioSelector';
import UbuFacebookName from './UbuFacebookName';
import UbuUndefinedUser from './UbuUndefinedUser';
import UbuMedia from './UbuMedia';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, UbuAvatar);
    registerComponent(Vue, UbuBadge);
    registerComponent(Vue, UbuButton);
    // registerComponent(Vue, UbuChip);
    registerComponent(Vue, UbuColorPicker);
    registerComponent(Vue, UbuContactName);
    registerComponent(Vue, UbuDropdownSelector);
    registerComponent(Vue, UbuSelector);
    registerComponent(Vue, UbuSelectorLabel);
    registerComponent(Vue, UbuEllipsis);
    registerComponent(Vue, UbuIcon);
    registerComponent(Vue, UbuIconButton);
    registerComponent(Vue, UbuInstaImg);
    registerComponent(Vue, UbuInstaName);
    registerComponent(Vue, UbuLoader);
    registerComponent(Vue, UbuLogo);
    registerComponent(Vue, UbuMenuButton);
    registerComponent(Vue, UbuNote);
    registerComponent(Vue, UbuRadio);
    registerComponent(Vue, UbuSimpleDropdown);
    registerComponent(Vue, UbuTabLink);
    registerComponent(Vue, UbuTextField);
    registerComponent(Vue, UbuTitleContent);
    registerComponent(Vue, UbuUserPicture);
    registerComponent(Vue, UbuContactCard);
    registerComponent(Vue, UbuStatusDropdown);
    registerComponent(Vue, UbuEnhancer);
    registerComponent(Vue, UbuSimpleInput);
    registerComponent(Vue, UbuTemplateCard);
    registerComponent(Vue, UbuTemplateTextarea);
    registerComponent(Vue, UbuFollowersSelector);
    registerComponent(Vue, UbuStatisticCard);
    registerComponent(Vue, UbuDatePicker);
    registerComponent(Vue, UbuInstaUserCard);
    registerComponent(Vue, UbuRangeSelector);
    registerComponent(Vue, UbuCheckboxSelector);
    registerComponent(Vue, UbuNumberInputRangeSelector);
    registerComponent(Vue, UbuRadioSelector);
    registerComponent(Vue, UbuFacebookName);
    registerComponent(Vue, UbuUndefinedUser);
    registerComponent(Vue, UbuMedia);
  },
};

use(Plugin);

export default Plugin;

export {
  UbuAvatar,
  UbuBadge,
  UbuButton,
  // UbuChip,
  UbuColorPicker,
  UbuContactName,
  UbuDropdownSelector,
  UbuSelector,
  UbuSelectorLabel,
  UbuEllipsis,
  UbuIcon,
  UbuIconButton,
  UbuInstaImg,
  UbuInstaName,
  UbuLoader,
  UbuLogo,
  UbuMenuButton,
  UbuNote,
  UbuRadio,
  UbuSimpleDropdown,
  UbuTabLink,
  UbuTextField,
  UbuTitleContent,
  UbuUserPicture,
  UbuContactCard,
  UbuStatusDropdown,
  UbuEnhancer,
  UbuSimpleInput,
  UbuTemplateCard,
  UbuTemplateTextarea,
  UbuFollowersSelector,
  UbuStatisticCard,
  UbuDatePicker,
  UbuInstaUserCard,
  UbuRangeSelector,
  UbuCheckboxSelector,
  UbuNumberInputRangeSelector,
  UbuRadioSelector,
  UbuFacebookName,
  UbuUndefinedUser,
};
