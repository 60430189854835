<template>
  <div
    id="UbuTHCustomHeader"
    :class="['ubu-header3', `text-color-${item.color}`]"
  >
    <UbuIcon
      size="20px"
      :name="icon"
      filled
    />
    <h2 class="ellipsis">
      {{ item.title }}
    </h2>
  </div>
</template>
<script>
export default {
  name: 'UbuTHCustomHeader',
  props: {
    icon: {
      type: String,
      default: () => 'tag',
    },
    item: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
