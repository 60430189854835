<template>
  <div>
    <b-image
      v-if="hasBucket"
      :placeholder="fallback"
      :src-fallback="fallback"
      :src="bucketUrl"
      lazy
      v-bind="$attrs"
      v-on="$listeners"
    />
    <b-image
      v-else
      :placeholder="fallback"
      :src-fallback="fallback"
      :src="proxyUrl"
      lazy
      v-bind="$attrs"
      v-on="$listeners"
    />
  </div>
</template>
<script>
export default {
  name: 'UbuInstaImg',
  props: {
    hasBucket: {
      type: Boolean,
      default: false,
    },
    bucketFolder: {
      type: String,
      default: 'media',
    },
    instaSrc: {
      type: [String, Number],
      default: null,
    },
    fallback: {
      type: String,
      default: '/img/expired_image.png',
    },
  },
  computed: {
    proxyUrl() {
      if (!this.instaSrc) {
        return this.fallback;
      }
      const encodedOldUrl = encodeURIComponent(this.instaSrc);
      // @TODO check encodedValidity
      return `https://proxy.getubu.com/${encodedOldUrl}`;
    },
    bucketUrl() {
      if (!this.instaSrc) {
        return this.fallback;
      }
      // @TODO check encodedValidity
      return `https://io.getubu.com/${this.bucketFolder}/${this.instaSrc}`;
    },
  },
};
</script>
