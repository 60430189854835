import {
  updateDatasIfExists,
  updateRelationsIfExists,
  // removeManyRelationsIfExists,
  updateObjectRelationIfExists,
  addNewKeysToMap,
  removeKeyToMap,
} from '../$utils/dataMapper';

import { arrayMapper } from '../../$utils/prototypes';
import GlobalProvider from '../providers/global.provider';
import { yaerApi } from '@/addons/$providers';
import internals from './internals';

/* eslint-disable max-len */
export default {
  namespaced: true,

  state: {
    contacts: {},
    contactsHasMessagesBis: {},
    contactsHasNotesBis: {},
    contactsHasThreadsBis: {},
    contactsHasContactGroupBis: {},
    contactsHasActivitiesBis: {},
    contactsHasActivities: [],
    contactsHasTags: [],
    contactsHasMentions: [],
    contactsHasContactGroup: [],
    contactsHasMessages: [],
    contactsHasNotes: [],
    contactsHasGossips: [],
    contactsHasCampaignsBis: {},
    contactsHasReferralLinksBis: {},
    contactsHasDiscountCodesBis: {},
  },

  getters: {
    getterContacts: (state) => state.contacts,
    getterContactsHasMessagesBis: (state) => state.contactsHasMessagesBis,
    getterContactsHasNotesBis: (state) => state.contactsHasNotesBis,
    getterContactsHasThreadsBis: (state) => state.contactsHasThreadsBis,
    getterContactsHasContactGroupsBis: (state) => state.contactsHasContactGroupBis,
    getterContactsHasActivitiesBis: (state) => state.contactsHasActivitiesBis,
    getterContactsHasActivities: (state) => state.contactsHasActivities,
    getterContactsHasTags: (state) => state.contactsHasTags,
    getterContactsHasMentions: (state) => state.contactsHasMentions,
    getterContactsHasContactGroup: (state) => state.contactsHasContactGroup,
    getterContactsHasMessages: (state) => state.contactsHasMessages,
    getterContactsHasNotes: (state) => state.contactsHasNotes,
    getterContactsHasGossips: (state) => state.contactsHasGossips,
    getterContactsHasCampaignsBis: (state) => state.contactsHasCampaignsBis,
    getterContactsHasReferralLinksBis: (state) => state.contactsHasReferralLinksBis,
    getterContactsHasDiscountCodesBis: (state) => state.contactsHasDiscountCodesBis,
  },

  mutations: {
    SET_CONTACTS(state, newDatas) { state.contacts = updateDatasIfExists({ newDatas, actualDatas: state.contacts, key: 'contactId' }); },
    // SET_CONTACTS_HAS_ACTIVITIES(state, newDatas) { state.contactsHasActivities = updateRelationsIfExists(state.contactsHasActivities, newDatas); },
    SET_CONTACTS_HAS_TAGS_POST(state, newDatas) { state.contactsHasTags = updateRelationsIfExists(state.contactsHasTags, newDatas); },
    SET_CONTACTS_HAS_MENTIONS(state, newDatas) { state.contactsHasMentions = updateRelationsIfExists(state.contactsHasMentions, newDatas); },
    // SET_CONTACTS_HAS_CONTACT_GROUP(state, newDatas) { state.contactsHasContactGroup = updateRelationsIfExists(state.contactsHasContactGroup, newDatas); },
    // UNSET_CONTACTS_HAS_CONTACT_GROUP(state, newDatas) { state.contactsHasContactGroup = removeManyRelationsIfExists(state.contactsHasContactGroup, newDatas); },
    // SET_CONTACTS_HAS_MESSAGES(state, newDatas) { state.contactsHasMessages = updateRelationsIfExists(state.contactsHasMessages, newDatas); },
    // SET_CONTACTS_HAS_NOTES(state, newDatas) { state.contactsHasNotes = updateRelationsIfExists(state.contactsHasNotes, newDatas); },
    SET_CONTACTS_HAS_GOSSIPS(state, newDatas) { state.contactsHasGossips = updateRelationsIfExists(state.contactsHasGossips, newDatas); },
    // UNSET_CONTACT_GROUP_FROM_CONTACT(state, { contactIdToRemove, contactGroupIdToRemove }) { state.contactsHasContactGroup.splice(state.contactsHasContactGroup.findIndex(({ contactId, contactGroupId }) => contactId === contactIdToRemove && contactGroupId === contactGroupIdToRemove), 1); },

    SET_CONTACTS_HAS_MESSAGES_BIS(state, newDatas) { state.contactsHasMessagesBis = updateObjectRelationIfExists({ newDatas, actualDatas: state.contactsHasMessagesBis }); },
    SET_CONTACTS_HAS_NOTES_BIS(state, newDatas) { state.contactsHasNotesBis = updateObjectRelationIfExists({ newDatas, actualDatas: state.contactsHasNotesBis }); },
    SET_CONTACT_HAS_THREADS_BIS(state, newDatas) { state.contactsHasThreadsBis = updateObjectRelationIfExists({ newDatas, actualDatas: state.contactsHasThreadsBis }); },

    SET_CONTACTS_HAS_CAMPAIGNS_BIS(state, newDatas) { state.contactsHasCampaignsBis = updateObjectRelationIfExists({ newDatas, actualDatas: state.contactsHasCampaignsBis }); },

    SET_CONTACTS_HAS_REFERRAL_LINKS_BIS(state, newDatas) { state.contactsHasReferralLinksBis = updateObjectRelationIfExists({ newDatas, actualDatas: state.contactsHasReferralLinksBis }); },
    REMOVE_REFERRAL_LINK_TO_CONTACT(state, { contactId, referralLinkRlid }) { state.contactsHasReferralLinksBis = removeKeyToMap({ map: state.contactsHasReferralLinksBis, key: contactId, value: referralLinkRlid }); },
    SET_CONTACTS_HAS_DISCOUNT_CODES_BIS(state, newDatas) { state.contactsHasDiscountCodesBis = updateObjectRelationIfExists({ newDatas, actualDatas: state.contactsHasDiscountCodesBis }); },
    REMOVE_DISCOUNT_CODE_TO_CONTACT(state, { contactId, discountCodeDcid }) { state.contactsHasDiscountCodesBis = removeKeyToMap({ map: state.contactsHasDiscountCodesBis, key: contactId, value: discountCodeDcid }); },

    // ContactGroup
    SET_CONTACT_HAS_CONTACT_GROUPS_BIS(state, newDatas) { state.contactsHasContactGroupBis = updateObjectRelationIfExists({ newDatas, actualDatas: state.contactsHasContactGroupBis }); },
    ADD_CONTACT_GROUP_TO_CONTACT(state, { contactId, contactGroupIds }) { state.contactsHasContactGroupBis = addNewKeysToMap({ map: state.contactsHasContactGroupBis, key: contactId, values: contactGroupIds }); },
    REMOVE_CONTACT_GROUP_TO_CONTACT(state, { contactId, contactGroupId }) { state.contactsHasContactGroupBis = removeKeyToMap({ map: state.contactsHasContactGroupBis, key: contactId, value: contactGroupId }); },

    // Activities
    SET_CONTACTS_HAS_ACTIVITIES_BIS(state, newDatas) { state.contactsHasActivitiesBis = updateObjectRelationIfExists({ newDatas, actualDatas: state.contactsHasActivitiesBis }); },
    ADD_ACTIVITIES_TO_CONTACT(state, { activityIds, contactId }) { state.contactsHasActivitiesBis = addNewKeysToMap({ map: state.contactsHasActivitiesBis, key: contactId, values: activityIds }); },
    REMOVE_ACTIVITY_TO_CONTACT(state, { contactId, activityId }) { state.contactsHasActivitiesBis = removeKeyToMap({ map: state.contactsHasActivitiesBis, key: contactId, value: activityId }); },

    RESET_STORES(state) {
      state.contacts = {};
      state.contactsHasMessagesBis = {};
      state.contactsHasNotesBis = {};
      state.contactsHasThreadsBis = {};
      state.contactsHasContactGroupBis = {};
      state.contactsHasActivitiesBis = {};
      state.contactsHasActivities = [];
      state.contactsHasTags = [];
      state.contactsHasMentions = [];
      state.contactsHasContactGroup = [];
      state.contactsHasMessages = [];
      state.contactsHasNotes = [];
      state.contactsHasGossips = [];

      state.contactsHasCampaignsBis = {};
      state.contactsHasReferralLinksBis = {};
      state.contactsHasDiscountCodesBis = {};
    },
  },

  actions: {
    // fetchThreadsContactsDbByChannel({ commit }) {
    //   return Promise.resolve()
    //     .then(() => Promise.all(internals.channelsIds.map((channelId) => internals.fetchThreadsContactsDbByChannel(channelId))))
    //     .then((responses) => {
    //       const [validResponses] = responses
    //         .filter(({ response }) => response)
    //         .reduce((acc, { response }) => { acc.push(response); return acc; }, []);
    //       // console.log('🚀 ~ file: Thread.store.js ~ line 13 ~ mapThreads ~ validResponses', validResponses);
    //       const {
    //         mappedContactList,
    //         channelsHasContacts,
    //         threadsHasContacts,
    //       } = validResponses;

    //       commit('SET_CONTACTS', mappedContactList);
    //       commit('Channel/SET_CHANNELS_HAS_CONTACTS', channelsHasContacts, { root: true });
    //       commit('Thread/SET_THREADS_HAS_CONTACTS', threadsHasContacts, { root: true });
    //     });
    // },
    fetchMissingActivitiesContacts({ rootGetters, dispatch }) {
      const {
        'Activity/getterActivities': activities,
        'Contact/getterContacts': contacts,
      } = rootGetters;

      const storedContacts = Object.values(contacts).map(({ contactId }) => contactId);
      // console.log('🚀 ~ file: Contact.store.js ~ line 117 ~ fetchMissingContacts ~ storedContacts', storedContacts);
      const activitiesContacts = Object.values(activities).map(({ contactId }) => contactId).filter((contactId) => !storedContacts.includes(contactId));
      // console.log('🚀 ~ file: Contact.store.js ~ line 140 ~ fetchMissingActivitiesContacts ~ activitiesContacts', activitiesContacts);
      // console.log('🚀 ~ file: Contact.store.js ~ line 117 ~ fetchMissingContacts ~ activitiesContacts', activitiesContacts);
      const noDuplicate = [...new Set([...activitiesContacts])];
      // console.log('🚀 ~ file: Contact.store.js ~ line 121 ~ fetchMissingContacts ~ noDuplicate', noDuplicate);

      return dispatch('fetchMissingContacts', { pksToFetch: noDuplicate });
    },
    fetchMissingListContacts({ rootGetters, dispatch }, { pks, keepOnlyBigContacts }) {
      // console.log('🚀 ~ file: Contact.store.js ~ line 130 ~ fetchMissingListContacts ~ pks', pks);
      const {
        'Contact/getterContacts': contacts,
      } = rootGetters;

      const storedContacts = Object.values(contacts).map(({ contactId }) => contactId);

      const pksToFetch = pks.filter((pk) => !storedContacts.includes(Number(pk)));
      // console.log('🚀 ~ file: Contact.store.js ~ line 139 ~ fetchMissingListContacts ~ pksToFetch', pksToFetch);

      if (pksToFetch.length) return dispatch('fetchMissingContacts', { pksToFetch, keepOnlyBigContacts });
      return true;
    },
    fetchMissingListContactsByUsernames({ rootGetters, dispatch }, { usernames, keepOnlyBigContacts }) {
      // console.log('🚀 ~ file: Contact.store.js ~ line 161 ~ fetchMissingListContactsByUsernames ~ usernames', usernames);
      const {
        'Contact/getterContacts': contacts,
      } = rootGetters;

      const storedContacts = Object.values(contacts).map(({ username }) => username);

      const usernamesToFetch = usernames.filter((username) => !storedContacts.includes(username));
      // console.log('🚀 ~ file: Contact.store.js ~ line 169 ~ fetchMissingListContactsByUsernames ~ usernamesToFetch', usernamesToFetch);

      if (usernamesToFetch.length) return dispatch('fetchMissingContacts', { usernamesToFetch, keepOnlyBigContacts });
      return true;
    },
    fetchMissingContacts({ commit }, { pksToFetch, usernamesToFetch, keepOnlyBigContacts }) {
      const [channelId] = internals.channelsIds;
      return Promise.resolve()
        .then(() => GlobalProvider.fetchContactsDb({ payload: { channelId, pks: pksToFetch, usernames: usernamesToFetch } }))
        .then(({ response, error }) => {
          // console.log('🚀 ~ file: Contact.store.js ~ line 149 ~ .then ~ response', response);
          if (error) return;
          // console.log('🚀 ~ file: Contact.store.js ~ line 128 ~ .then ~ response', response);
          if (response) {
            let contactList = response.map((row) => ({ ...row, channelId, contactId: row.pk }));
            // console.log('🚀 ~ file: Contact.store.js ~ line 184 ~ .then ~ contactList', contactList);
            if (keepOnlyBigContacts) contactList = contactList.filter((contact) => contact.follower_count >= 5000);
            // console.log('🚀 ~ file: Contact.store.js ~ line 184 ~ .then ~ contactList', contactList);
            const mappedContactList = arrayMapper(contactList, 'contactId');

            commit('SET_CONTACTS', mappedContactList);
            commit('Channel/SET_CHANNELS_HAS_CONTACTS_BIS', { [channelId]: contactList.map(({ pk }) => pk) }, { root: true });
          }
        });
    },
    fetchContactsByUsernames({ rootGetters, commit }, usernames) {
      const {
        'Channel/getterCurrentChannelId': channelId,
      } = rootGetters;

      return Promise.all(
        usernames
          .map((username) => yaerApi.api.contact.fetchByUsername({ payload: { channelId, username } })),
      ).then((responses) => {
        const responsesMapped = responses.reduce((acc, response) => {
          acc[(response.success && 'success') || 'failures'].push(response.result);
          return acc;
        }, { success: [], failures: [] });
        return responsesMapped;
      }).then(({ success, failures }) => {
        const contactListMapped = arrayMapper(success, 'contactId');
        // console.log('🚀 ~ file: Contact.store.js ~ line 37 ~ .then ~ contactListMapped', contactListMapped);
        commit('SET_CONTACTS', contactListMapped);
        const contactIds = Object.keys(contactListMapped);
        commit('Channel/ADD_CONTACT_TO_CHANNEL', { channelId, contactIds }, { root: true });
        return { success, failures };
      });
    },
    fetchTiktokUser({ rootGetters, commit }, { username, contactId }) {
      const {
        'Channel/getterCurrentChannelId': channelId,
        'Contact/getterContacts': contacts,
      } = rootGetters;
      return Promise.resolve()
        .then(() => GlobalProvider.fetchTiktokUser({ channelId, username }))
        .then(({ response }) => {
          if (response) {
            const contact = Object.values(contacts).find((c) => c.contactId === contactId);
            if (!contact) return;
            const updatedContact = {
              [contactId]: {
                ...contact,
                tiktokUser: response.constructor === Object ? response : null,
              },
            };
            commit('Contact/SET_CONTACTS', updatedContact, { root: true });
          }
        });
    },
    updateTiktokUsername({ rootGetters, commit }, { username, contactId }) {
      const {
        'Channel/getterCurrentChannelId': channelId,
        'Contact/getterContacts': contacts,
      } = rootGetters;
      return Promise.resolve()
        .then(() => GlobalProvider.updateTiktokUsername({ channelId, contactId, username }))
        .then(({ response, error }) => {
          if (error) return { error };
          const contact = Object.values(contacts).find((c) => c.contactId === contactId);
          if (!contact) return error;
          const updatedContact = {
            [contactId]: {
              ...contact,
              tiktokUser: null,
              tiktok_username: username,
            },
          };
          commit('Contact/SET_CONTACTS', updatedContact, { root: true });
          return { response };
        });
    },
    SOCKET_userChanged({ commit }, { user }) {
      console.log('🚀 ~ file: Contact.store.js ~ line 162 ~ SOCKET_userChanged ~ user', user);
      const enrichedContact = {
        [user.contactId]: {
          ...user,
        },
      };
      commit('SET_CONTACTS', enrichedContact);
    },
  },

};
