<template>
  <div id="UbuWorkspaceTemplate">
    <div class="UbuWorkspaceTemplate_title ubu-header3">
      {{ template.title }}
      <div class="UbuWorkspaceTemplate_buttons">
        <UbuButton
          class="UbuWorkspaceTemplate_button"
          @click="isCardModalActive = !isCardModalActive"
        >
          Edit
        </UbuButton>
        <UbuButton
          class="UbuWorkspaceTemplate_button"
          color="2"
          @click="$emit('clickDelete', template)"
        >
          Delete
        </UbuButton>
      </div>
      <b-modal
        v-model="isCardModalActive"
        :width="640"
        scroll="keep"
      >
        <UbuWorkspaceModalEditTemplate
          :is-card-modal-active="isCardModalActive"
          :template="template"
          :template-list="templateList"
          :current-title="template.title"
          @close="isCardModalActive = false"
          @editFromModal="editFromModal($event)"
        />
      </b-modal>
    </div>
    <div class="UbuWorkspaceTemplate_textArea ubu-text3">
      {{ template.data }}
    </div>
    <div class="UbuWorkspaceTemplate_divider" />
  </div>
</template>

<script>

export default {
  name: 'UbuWorkspaceTemplate',
  props: {
    templateList: {
      type: Array,
      default: () => [],
    },
    template: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isCardModalActive: false,
    };
  },
  methods: {
    editFromModal($event) {
      this.$emit('editFromModal', $event);
      this.isCardModalActive = false;
    },
  },
};
</script>
