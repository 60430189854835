var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{class:['UbuIcon_wrapper', {
    'cursor-pointer': _vm.clickable,
  }],on:{"click":function (evt) { return _vm.clickable ? _vm.$emit('click', evt) : function () {}; }}},[(_vm.type === 'ubu')?_c('i',{class:[
      ("ubu-icons ubu-icons-" + _vm.name + "-" + (_vm.filled ? 'filled' : 'outlined')),
      _vm.color ? ("text-color-" + _vm.color) : ''
    ],style:([("font-weight: " + (_vm.strong ? '800' : '300')), {
      'font-size': typeof _vm.size === 'string' ? _vm.size : (_vm.size + "px")
    }])}):(_vm.type === 'char')?_c('span',{class:[_vm.color ? ("text-color-" + _vm.color) : ''],style:([("font-weight: " + (_vm.strong ? '800' : '300')), {
      'font-size': typeof _vm.size === 'string' ? _vm.size : (_vm.size + "px")
    }])},[_vm._v(" "+_vm._s(_vm.name)+" ")]):(_vm.type === 'picto')?_c('img',{staticStyle:{"border-radius":"25%"},attrs:{"src":_vm.name}}):(_vm.type === 'user')?_c('UbuUserPicture',{attrs:{"size":_vm.size,"initial-name":_vm.name,"color":_vm.color}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }