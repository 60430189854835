<template>
  <b-tooltip
    class="is-flex UbuMenuButton_tooltip"
    v-bind="tooltip"
    :active="!!tooltip && !!tooltip.label"
  >
    <template v-slot:content>
      <UbuBadge
        v-if="badge"
        class="UbuMenuButton_tooltip-badge"
        :color="active ? 'givry' : 'narvalo'"
        :value="badge"
        :important="important"
      />
    </template>
    <component
      :is="disabled ? 'div' : 'router-link'"
      :class="[
        'UbuMenuButton_wrapper',
        { UbuMenuButton_disabled: disabled },
        { UbuMenuButton_isReduced: isReduced },
      ]"
      v-bind="$attrs"
    >
      <UbuIcon
        :class="['UbuMenuButton_icon']"
        v-bind="typeof icon === 'object' ? icon : { name: icon }"
      />
      <div
        v-if="!isReduced"
        class="UbuMenuButton_content ubu-text1 ellipsis"
      >
        <slot />
      </div>
      <div
        v-if="!isReduced"
        class="UbuMenuButton_append"
      >
        <UbuBadge
          v-if="badge || important"
          :color="active ? 'givry' : 'narvalo'"
          :value="badge"
          :important="important"
        />
      </div>
      <span
        v-if="!active && isReduced && badge && important"
        class="UbuMenuButton_important"
      />
      <span
        v-if="infoText"
        class="ubu-text2 infoText"
      >{{ infoText }}</span>
    </component>
  </b-tooltip>
</template>

<script>
export default {
  name: 'UbuMenuButton',
  props: {
    active: Boolean,
    icon: {
      type: [Object, String],
      default: null,
    },
    color: {
      type: [String, Number],
      default: null,
    },
    badge: {
      type: [Number, String],
      default: null,
    },
    important: Boolean,
    disabled: Boolean,
    isReduced: Boolean,
    tooltip: {
      type: Object,
      default: () => {},
    },
    infoText: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.UbuMenuButton_wrapper {
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 14px;
  color: var(--cloudburst);
  border-radius: 12px;
  text-transform: none;
  height: 34px;
  text-decoration: none;
  cursor: pointer;
  &.UbuMenuButton_disabled {
    opacity: 0.5;
  }
  &.UbuMenuButton_isReduced {
    justify-content: center;
  }
  &.router-link-active {
    background-color: var(--narvalo);
  }

  &:hover {
    &:not(.router-link-exact-active):not(.UbuMenuButton_disabled) {
      background-color: var(--narvalo60);
    }
  }

  .UbuMenuButton_icon {
    font-size: 20px;
  }

  .UbuMenuButton_important {
    background-color: var(--color2);
    width: 6px;
    height: 6px;
    position: absolute;
    right: 6px;
    border-radius: 50%;
  }

  .UbuMenuButton_content {
    display: flex;
    flex-grow: 1;
    margin-left: 8px;
  }

  .UbuMenuButton_append {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    flex-grow: 1;
    > * {
      margin-right: 6px;
    }
  }
  .infoText{
    color: var(--geraldine);
  }
}
</style>
