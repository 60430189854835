<template>
  <div v-if="tradFr || tradEn || tradDe || tradEs">
    <b-dropdown-item
      class="translate-action before"
      custom
    >
      <span>
        <UbuIcon
          name="translate"
        />
        Translate to:
      </span>
    </b-dropdown-item>

    <b-dropdown-item
      v-for="lang in languageList"
      v-show="lang.display"
      :key="lang.value"
      :value="lang.value"
      @click="$emit('translateMessage', { target: lang.value })"
    >
      <div class="language-content">
        <b-image
          class="language-flag"
          :src="lang.icon"
        />
        {{ lang.label }}
      </div>
    </b-dropdown-item>
  </div>
</template>

<script>
export default {
  name: 'UbuCommonTranslationDropdownItem',
  props: {
    tradFr: {
      type: Boolean,
      default: false,
    },
    tradEn: {
      type: Boolean,
      default: false,
    },
    tradDe: {
      type: Boolean,
      default: false,
    },
    tradEs: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      languageList: [
        {
          label: 'English', value: 'en', icon: '/img/UK.svg', display: this.tradEn,
        },
        {
          label: 'French', value: 'fr', icon: '/img/France.svg', display: this.tradFr,
        },
        {
          label: 'Spanish', value: 'es', icon: '/img/Spain.svg', display: this.tradEs,
        },
        {
          label: 'German', value: 'de', icon: '/img/Germany.svg', display: this.tradDe,
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>

</style>
