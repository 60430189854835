<template>
  <div class="UbuContactComment_wrapper">
    <div class="UbuContactComment_header">
      <span class="UbuContactComment_date ubu-subtext1">{{
        moment(gossip.timestamp)
      }}</span>
    </div>
    <span class="UbuContactComment_text ubu-text3">{{ gossip.text }}</span>
  </div>
</template>
<script>
import moment from 'moment';

export default {
  name: 'UbuContactComment',
  props: {
    gossip: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    moment(timestamp) {
      return moment(timestamp).format('YYYY-MM-DD');
    },
  },
};
</script>
<style lang="scss" scoped>
.UbuContactComment_wrapper {
  width: 100%;
  border-radius: 24px;
  border: 1px solid var(--mercury);
  padding: 16px;
  .UbuContactComment_header {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }
  .UbuContactComment_text {
    color: var(--cola);
  }
}
</style>
