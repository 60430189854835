<template>
  <div id="UbuCRMTable">
    <b-table
      class="list-table"
      :data="listFiltered"
      :default-sort="['username', 'asc']"
      :loading="loading"
      paginated
      :sticky-header="true"
      per-page="30"
      pagination-rounded
      hoverable
      pagination-size="is-small"
      :checked-rows="checkedRows"
      checkable
      :is-row-checkable="(row) => row.source === 'instagram'"
      :opened-detailed="openedDetails"
      :detailed="true"
      :show-detail-icon="true"
      :has-detailed-visible="(row) => row.source === 'instagram'"
      detail-key="userPk"
      @check="$emit('checkRows', $event)"
      @check-all="$emit('checkRows', $event)"
    >
      <b-table-column
        v-slot="{ row }"
        label=""
      >
        <UbuButton
          secondary
          center
          @click="$emit('showHashtag', row.username)"
        >
          show
        </UbuButton>
      </b-table-column>
      <b-table-column
        v-slot="{ row }"
        field="username"
        label="Username"
        sortable
      >
        <div class="username-table">
          {{ row.username }}
        </div>
      </b-table-column>
      <b-table-column
        v-slot="{ row: { platform } }"
        field="platform"
        label="Platform"
        sortable
      >
        <span>{{ platform || '-' }}</span>
      </b-table-column>
      <b-table-column
        v-slot="{ row: { followerCount } }"
        field="followerCount"
        label="Followers"
        sortable
      >
        <span v-if="followerCount">{{ followerCount.toLocaleString('en') }} </span>
        <span v-else>-</span>
      </b-table-column>
      <b-table-column
        v-slot="{ row: { views } }"
        field="views"
        label="Views"
        sortable
      >
        <span v-if="views">{{ views.toLocaleString('en') }}</span>
        <span v-else>-</span>
      </b-table-column>
      <b-table-column
        field="contactGroup"
        label="Labels"
        sortable
      >
        <template v-slot="{ row: { contactGroup } }">
          <UbuCRMTableCustomCell
            v-if="contactGroup.length"
            :groups="contactGroup"
            icon-name="contact"
          />
          <span v-else>
            -
          </span>
        </template>
      </b-table-column>
      <b-table-column
        v-slot="{ row: { engagement } }"
        field="engagement"
        label="Engagement"
        sortable
      >
        <span v-if="engagement && Number(engagement) >= 0.5">
          {{ Number(engagement).toFixed(2) }}%
        </span>
        <span v-else>-</span>
      </b-table-column>
      <b-table-column
        v-slot="{ row: { averagePostEngagement } }"
        field="averagePostEngagement"
        label="Average Post Engagement"
        sortable
      >
        <span v-if="averagePostEngagement">
          {{ Number(averagePostEngagement).toFixed(2) }}%
        </span>
        <span v-else>-</span>
      </b-table-column>
      <b-table-column
        v-slot="{ row: { averagePostLikes } }"
        field="averagePostLikes"
        label="Average Post Likes"
        sortable
      >
        <span v-if="averagePostLikes">
          {{ averagePostLikes.toLocaleString('en') }}
        </span>
        <span v-else>-</span>
      </b-table-column>
      <b-table-column
        v-slot="{ row: { averagePostComments } }"
        field="averagePostComments"
        label="Average Post Comments"
        sortable
      >
        <span v-if="averagePostComments">
          {{ averagePostComments.toLocaleString('en') }}
        </span>
        <span v-else>-</span>
      </b-table-column>
      <b-table-column
        v-slot="{ row: { averageReach } }"
        field="averageReach"
        label="Average Reach"
        sortable
      >
        <span v-if="averageReach">
          {{ averageReach.toLocaleString('en') }}
        </span>
        <span v-else>-</span>
      </b-table-column>
      <b-table-column
        v-slot="{ row: { language } }"
        field="language"
        label="Language"
        sortable
      >
        {{ language ? language : '-' }}
      </b-table-column>
      <b-table-column
        v-slot="{ row: { locations } }"
        field="locations"
        label="Locations"
        sortable
      >
        <div
          v-if="locations[0]"
          style="max-width: 200px;"
          class="ellipsis"
        >
          <span
            v-for="(location, index) in locations"
            :key="index"
          >{{ location }}</span>
        </div>
        <span v-else>-</span>
      </b-table-column>
      <b-table-column
        v-slot="{ row: { nbPosts } }"
        field="nbPosts"
        label="Number of posts with this hashtag"
        sortable
      >
        <span v-if="nbPosts">{{ nbPosts.toLocaleString('en') }}</span>
        <span v-else>-</span>
      </b-table-column>
      <b-table-column
        v-slot="{ row: { lastMentionOrTagPost } }"
        field="lastMentionOrTagPost"
        label="Last Mention"
        sortable
      >
        {{ parseTimestamp(lastMentionOrTagPost) || '-' }}
      </b-table-column>
      <template #detail="{ row }">
        <UbuCRMTableDetail
          :row="row"
          :thread-groups-list="threadGroupsList"
          :contact-groups-list="contactGroupsList"
          :campaign-list="campaignList"
          :is-generate-report-loading="isGenerateReportLoading"
          :is-statistics-loading="isStatisticsLoading"
          show-new-message-button
          @addCustom="$emit('addCustom', $event)"
          @removeCustom="$emit('removeCustom', $event)"
          @generateReport="$emit('generateReport', row)"
          @updateContact="$emit('updateContact', $event)"
          @createThreadGroupAndAssign="$emit('createThreadGroupAndAssign', {
            thread: row.thread, ...$event, username: row.username })"
          @createContactGroupAndAssign="$emit('createContactGroupAndAssign',{
            thread: {
              contactId: row.contactId, channelId: row.channelId
            }, ...$event, username: row.username })"
          @openNewMessage="$emit('openNewMessage', $event)"
          @fetchTiktokUser="$emit('fetchTiktokUser', $event)"
          @openModalEditUsernameTiktok="$emit('openModalEditUsernameTiktok', $event)"
        />
      </template>
    </b-table>
  </div>
</template>

<script>
import { timeToNow } from '../../$utils/dates';

export default {
  name: 'UbuMentionsHashtagsTable',
  props: {
    listFiltered: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isGenerateReportLoading: {
      type: String,
      default: null,
    },
    checkedRows: {
      type: Array,
      default: () => [],
    },
    threadGroupsList: {
      type: Array,
      default: () => [],
    },
    contactGroupsList: {
      type: Array,
      default: () => [],
    },
    campaignList: {
      type: Array,
      default: () => [],
    },
    isStatisticsLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      openedDetails: [],
      activeTab: 0,
    };
  },
  methods: {
    parseTimestamp(value) {
      return value ? timeToNow(value) : null;
    },
  },
};
</script>
