import TheLeads from './TheLeads';

export default [
  {
    path: '/list',
    name: 'Leads',
    component: TheLeads,
    meta: {
      auth: true,
      requireData: true,
    },
  },
];
