import builder from '../../$utils/axiosMedia';

export default class ShopifyProvider {
  static getShopifyShop({ channelId }) {
    return builder({
      method: 'get',
      url: `/channel/${channelId}/shopifyShop`,
    });
  }

  static getShopifyDiscountCodes({ channelId }) {
    return builder({
      method: 'get',
      url: `/channel/${channelId}/getShopifyDiscountCodes/612cbc2b92c8f935be3ac76c`,
    });
  }
}
