import { use, registerComponent } from '../$utils/plugins';

// const TheSidebar = () => import('./TheSidebar');
import TheSidebar from './TheSidebar';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, TheSidebar);
  },
};

use(Plugin);

export default Plugin;

export {
  TheSidebar,
};
