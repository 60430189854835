/* eslint-disable func-names */
const debounce = function (fn, delay) {
  let timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    // eslint-disable-next-line prefer-rest-params
    const args = arguments;
    const that = this;

    timeoutID = setTimeout(() => {
      fn.apply(that, args);
    }, delay);
  };
};

const errorHandler = (message) => (res) => {
  if (res.error) throw new Error(message);
  return res;
};

export {
  debounce,
  errorHandler,
};
