const snackError = {
  type: 'is-danger',
  position: 'is-top',
  queue: false,
  actionText: 'Retry',
};
const snackWarning = {
  type: 'is-warning',
  position: 'is-top',
  queue: false,
  actionText: 'OK',
};
const snackSuccess = {
  type: 'is-success',
  position: 'is-bottom-right',
  queue: false,
};

export {
  snackSuccess,
  snackError,
  snackWarning,
};
