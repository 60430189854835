import { getThreadName } from '@/addons/$utils/threadUtilities';
import CommonProvider from '../providers/common.provider';

export default {
  namespaced: true,

  state: {
  },

  getters: {
  },

  actions: {
    assignThreadToThreadGroup({ commit }, { threadGroup, thread }) {
      const { threadId, channelId } = thread;
      const { threadGroupId, title } = threadGroup;
      const threadName = getThreadName(thread);
      return CommonProvider
        .assignSubjectToCustom({
          payload: {
            channelId,
            customTagId: threadGroupId,
            subject: threadId,
          },
        })
        .then(({ response, error }) => {
          if (response) {
            commit('Thread/ADD_THREAD_GROUP_TO_THREAD', { threadId, threadGroupIds: [threadGroupId] }, { root: true });
            commit('ThreadGroup/ADD_THREAD_TO_THREAD_GROUP', { threadIds: [threadId], threadGroupId }, { root: true });
            return { threadName, title };
          }
          return { error, threadName, title };
        }).catch((error) => ({ error, threadName, title }));
    },
    removeThreadFromThreadGroup({ commit }, { threadGroup, thread }) {
      const { threadId, channelId } = thread;
      const { threadGroupId, title } = threadGroup;
      const threadName = getThreadName(thread);
      return CommonProvider
        .removeSubjectFromCustom({
          payload: {
            channelId,
            customTagId: threadGroupId,
            subject: threadId,
          },
        })
        .then(({ response, error }) => {
          if (response) {
            /**
             * using UNSET_THREAD_GROUP_FROM_THREAD, mostly fitting with websocket
             */
            commit('Thread/REMOVE_THREAD_GROUP_TO_THREAD', { threadId, threadGroupId }, { root: true });
            commit('ThreadGroup/REMOVE_THREAD_TO_THREAD_GROUP', { threadId, threadGroupId }, { root: true });
            return { threadName, title };
          }
          return { error, title, threadName };
        })
        .catch((error) => ({ error, title, threadName }));
    },
    createThreadGroupAndAssign({ dispatch }, { thread, title, color }) {
      const { threadId, channelId } = thread;
      const threadName = getThreadName(thread);
      return CommonProvider
        .createNewCustomAndAssign({
          payload: {
            channelId, color, title, subject: threadId, type: 'tag',
          },
        })
        .then(({ response, error }) => {
          if (response) {
            return dispatch('CustomTag/fetchCustomsByChannel', null, { root: true })
              .then(() => ({ threadName, title }));
          }
          return { error, title, threadName };
        })
        .catch((error) => ({ error, title, threadName }));
    },
    assignContactToContactGroup({ commit }, { contactGroup, thread }) {
      const contactId = String(thread.contactId);
      const { channelId } = thread;
      const { contactGroupId, title } = contactGroup;
      const threadName = getThreadName(thread);
      return CommonProvider
        .assignSubjectToCustom({
          payload: {
            channelId,
            customTagId: contactGroup.customTagId,
            subject: contactId,
          },
        })
        .then(({ response, error }) => {
          if (response) {
            // commit('Contact/SET_CONTACTS_HAS_CONTACT_GROUP', [{ contactId, contactGroupId }], { root: true });
            commit('Contact/ADD_CONTACT_GROUP_TO_CONTACT', { contactId, contactGroupIds: [contactGroupId] }, { root: true });
            commit('ContactGroup/ADD_CONTACT_TO_CONTACT_GROUP', { contactIds: [contactId], contactGroupId }, { root: true });
            return { title, threadName };
          }
          return { error, title, threadName };
        }).catch((error) => ({ error, title, threadName }));
    },
    removeContactFromContactGroup({ commit }, { contactGroup, thread }) {
      const contactId = String(thread.contactId);
      const { channelId } = thread;
      const { contactGroupId, title } = contactGroup;
      const threadName = getThreadName(thread);
      return CommonProvider
        .removeSubjectFromCustom({
          payload: {
            channelId,
            customTagId: contactGroup.customTagId,
            subject: contactId,
          },
        })
        .then(({ response, error }) => {
          if (response) {
            // commit('Contact/UNSET_CONTACTS_HAS_CONTACT_GROUP', [{ contactId, contactGroupId }], { root: true });
            commit('Contact/REMOVE_CONTACT_GROUP_TO_CONTACT', { contactId, contactGroupId }, { root: true });
            commit('ContactGroup/REMOVE_CONTACT_TO_CONTACT_GROUP', { contactId, contactGroupId }, { root: true });
            return { title, threadName };
          }
          return { error, title, threadName };
        });
    },
    createContactGroupAndAssign({ dispatch }, { thread, title, color }) {
      const contactId = String(thread.contactId);
      const { channelId } = thread;
      const threadName = getThreadName(thread);
      return CommonProvider
        .createNewCustomAndAssign({
          payload: {
            channelId, color, title, subject: contactId, type: 'label',
          },
        })
        .then(({ response, error }) => {
          if (response) {
            return dispatch('CustomTag/fetchCustomsByChannel', null, { root: true })
              .then(() => ({ threadName, title }));
          }
          return { error, title, threadName };
        })
        .catch((error) => ({ error, title, threadName }));
    },

    assignThreadToUbuUser({ commit, rootGetters }, { thread, ubuUser }) {
      const { 'UbuUser/getterAssignments': assignements } = rootGetters;
      const { channelId, threadId } = thread;
      const { ubuUserId, name } = ubuUser;
      const threadName = getThreadName(thread);
      const assignment = assignements[ubuUserId];
      if (!assignment) {
        return CommonProvider.createNewCustomAndAssign({
          payload: {
            channelId, title: ubuUserId, subject: threadId, type: 'assignment',
          },
        }).then(({ response, error }) => {
          if (!error) {
            commit('UbuUser/SET_ASSIGNMENTS', [{
              customTagId: response._id,
              ubuUserId: response.title,
              ...response,
            }], { root: true });
            commit('Thread/ADD_UBUUSERS_TO_THREAD', { ubuUserIds: [ubuUserId], threadId }, { root: true });
            commit('UbuUser/ADD_THREADS_TO_UBUUSER', { ubuUserId, threadIds: [threadId] }, { root: true });
          }
        }).catch((error) => ({ error, name, threadName }));
      }
      return CommonProvider.assignSubjectToCustom({
        payload: {
          channelId,
          customTagId: assignment.customTagId,
          subject: threadId,
        },
      }).then(({ error }) => {
        if (!error) {
          commit('Thread/ADD_UBUUSERS_TO_THREAD', { ubuUserIds: [ubuUserId], threadId }, { root: true });
          commit('UbuUser/ADD_THREADS_TO_UBUUSER', { ubuUserId, threadIds: [threadId] }, { root: true });
          return { error, name, threadName };
        }
      }).catch((error) => ({ error, name, threadName }));
    },

    removeThreadFromUbuUser({ commit, rootGetters }, { thread, ubuUser }) {
      const { channelId, threadId } = thread;
      const { ubuUserId, name } = ubuUser;
      const threadName = getThreadName(thread);
      const { 'UbuUser/getterAssignments': assignements } = rootGetters;
      const assignment = assignements[ubuUserId];
      return CommonProvider
        .removeSubjectFromCustom({
          payload: {
            channelId,
            customTagId: assignment.customTagId,
            subject: threadId,
          },
        })
        .then(({ error }) => {
          if (!error) {
            // commit('Contact/UNSET_CONTACTS_HAS_CONTACT_GROUP', [{ contactId, contactGroupId }], { root: true });
            commit('Thread/REMOVE_UBUUSER_TO_THREAD', { ubuUserId, threadId }, { root: true });
            commit('UbuUser/REMOVE_THREAD_TO_UBUUSER', { ubuUserId, threadId }, { root: true });
          }
          return { error, name, threadName };
        }).catch((error) => ({ error, name, threadName }));
    },
  },
};
