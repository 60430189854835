const _state = () => ({
  theme: localStorage.getItem('ubuTheme') || 'auto',
});

export default {
  namespaced: true,
  state: _state,
  getters: {
    getterTheme: (state) => state.theme,
  },
  actions: {
    setTheme({ commit }, theme) {
      if (['classic', 'dark', 'auto'].includes(theme)) {
        commit('SET_THEME', theme);
        localStorage.setItem('ubuTheme', theme);
      }
    },
  },
  mutations: {
    SET_THEME(state, theme) { state.theme = theme; },
  },
};
