<template>
  <div
    class="UbuInfluenceModalCreateCampaign_wrapper"
  >
    <div class="UbuInfluenceModalCreateCampaign_header">
      <p class="UbuInfluenceModalCreateCampaign_title ubu-title">New campaign</p>

      <UbuIcon
        name="cross"
        clickable
        @click="$emit('close', $event)"
      />
    </div>
    <section class="UbuInfluenceModalCreateCampaign_body">
      <div class="UbuInfluenceModalCreateCampaign_formWrapper">
        <div class="UbuInfluenceModalCreateCampaign_dropdownParent">
          <UbuIcon
            class="UbuInfluenceModalCreateCampaign_editableIcon mr-2"
            name="influence"
            :color="convertColor(newCampaign.color)"
            filled
            clickable
            @click="dropdownOpened = !dropdownOpened"
          />
          <div
            v-if="dropdownOpened"
            class="UbuInfluenceModalCreateCampaign_dropdown"
          >
            <UbuColorPicker
              :max-per-row="3"
              icon="influence"
              @selectColor="$emit('selectColor', $event), dropdownOpened = !dropdownOpened"
            />
          </div>
        </div>
        <div class="UbuInfluenceModalCreateCampaign_input">
          <UbuSimpleInput
            v-model="newCampaign.name"
            class="UbuInfluenceModalCreateCampaign_editable pl-3"
            :focus="true"
            placeholder="Campaign name..."
          />
        </div>
      </div>
    </section>
    <div class="UbuInfluenceModalCreateCampaign_buttons">
      <UbuButton
        grow
        center
        secondary
        @click="$emit('addFromModal', newCampaign)"
      >
        Create
      </UbuButton>
    </div>
  </div>
</template>

<script>
import CommonMixin from '../../$mixins/CommonMixin';

export default {
  name: 'UbuInfluenceModalCreateCampaign',
  mixins: [CommonMixin],
  props: {
    newCampaign: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dropdownOpened: false,
    };
  },
};
</script>

<style lang="scss" scoped>
  .UbuInfluenceModalCreateCampaign_wrapper {
    padding: 36px;
    border-radius: 24px;
    background-color: var(--soapstone);

    .UbuInfluenceModalCreateCampaign_header {
      display: flex;
      justify-content: space-between;
    }
    .UbuInfluenceModalCreateCampaign_title {
      width: auto;
      margin-bottom: 26px;
    }
    .UbuInfluenceModalCreateCampaign_body {
      // display: flex;
      // flex-direction: row;

      .UbuInfluenceModalCreateCampaign_formWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 26px;

        .UbuInfluenceModalCreateCampaign_dropdownParent {
          position: relative;
        }

        .UbuInfluenceModalCreateCampaign_dropdown{
          // z-index: 100;
          // position: absolute;
          // top: calc(100% + 3px);
          background-color: var(--soapstone);
          box-shadow: 0px 0px 10px var(--box-shadow);
          border-radius: 12px;
          padding: 12px;
          position: absolute;
          top: -8px;
          left: 100%;
          z-index: 10000;
        }

        .UbuInfluenceModalCreateCampaign_editable {
          border: 1px solid var(--mercury);
          border-radius: 8px;
        }

        .UbuInfluenceModalCreateCampaign_editableIcon {
          border: 1px solid var(--mercury);
          border-radius: 8px;
          max-width: 200px;
          padding: 9px;
        }

        .UbuInfluenceModalCreateCampaign_input {
          width: 100%;
        }

        .UbuInfluenceModalCreateCampaign_toolBox {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          font-size: 20px;
          margin: 0 24px;

          .UbuInfluenceModalCreateCampaign_tools {
            display: flex;
            flex-direction: row;
            flex-grow: 1;
            align-items: center;
            > * {
              margin-right: 16px;
            }
          }
        }
      }
    }

    .UbuInfluenceModalCreateCampaign_buttons {
      display: flex;
      justify-content: space-between;

      .UbuInfluenceModalCreateCampaign_button {
        color: var(--soapstone);
        background-color: var(--geraldine);
      }
    }
  }
</style>
