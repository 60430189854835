import { arrayMapper } from '../../$utils/prototypes';
import { updateDatasIfExists } from '../$utils/dataMapper';
import internals from './internals';

/* eslint-disable max-len */
export default {
  namespaced: true,

  state: {
    activities: {},
  },

  getters: {
    getterActivities: (state) => state.activities,
  },

  actions: {
    fetchActivitiesByCampaignsByChannel({ commit }) {
      return Promise.resolve()
        .then(() => Promise.all(internals.channelsHasCampaigns.map(({ channelId, campaignId }) => internals.fetchActivitiesByCampaignsByChannel(channelId, campaignId))))
        .then((fetchActivitiesByCampaignsByChannel) => {
          // console.log('🚀 ~ file: Activity.store.js ~ line 22 ~ .then ~ fetchActivitiesByCampaignsByChannel', fetchActivitiesByCampaignsByChannel);
          const activities = fetchActivitiesByCampaignsByChannel
            .filter(({ response }) => response)
            .flatMap(({ response, campaignId }) => response
              .map((res) => ({
                ...res,
                activityId: res._id,
                campaignId,
                contactId: res.pk,
              })));

          const mappedActivities = arrayMapper(activities, 'activityId');
          commit('SET_ACTIVITIES', mappedActivities);
          // console.log('🚀 ~ file: Activity.store.js ~ line 31 ~ .then ~ mappedActivities', mappedActivities);

          const campaignsHasActivities = Object.values(mappedActivities).map(({ activityId, campaignId }) => ({ activityId, campaignId }));
          // console.log('🚀 ~ file: Activity.store.js ~ line 35 ~ .then ~ campaignsHasActivities', campaignsHasActivities);
          // commit('Campaign/SET_CAMPAIGNS_HAS_ACTIVITIES', campaignsHasActivities, { root: true });
          // console.log('🚀 ~ file: Activity.store.js ~ line 42 ~ .then ~ campaignsHasActivities', campaignsHasActivities);
          const campaignsHasActivitiesBis = campaignsHasActivities
            .reduce((acc, row) => {
              const { activityId, campaignId } = row;
              // if (!contactGroupId || contactGroupId === 'null') return acc;
              if (!acc[campaignId]) {
                acc[campaignId] = [activityId];
                return acc;
              }
              acc[campaignId].push(activityId);
              return acc;
            }, {});

          const campaignsHasContactBis = Object.values(mappedActivities)
            .reduce((acc, row) => {
              const { contactId, campaignId } = row;
              // if (!contactGroupId || contactGroupId === 'null') return acc;
              if (!acc[campaignId]) {
                acc[campaignId] = [contactId];
                return acc;
              }
              acc[campaignId].push(contactId);
              return acc;
            }, {});
          // console.log('🚀 ~ file: Activity.store.js ~ line 63 ~ .then ~ campaignsHasContactBis', campaignsHasContactBis);
          // console.log('🚀 ~ file: CustomTag.store.js ~ line 112 ~ campaignsHasActivitiesBis ~ campaignsHasActivitiesBis', campaignsHasActivitiesBis);
          commit('Campaign/SET_CAMPAIGNS_HAS_ACTIVITIES_BIS', campaignsHasActivitiesBis, { root: true });
          commit('Campaign/SET_CAMPAIGNS_HAS_CONTACTS_BIS', campaignsHasContactBis, { root: true });

          const contactsHasCampaignsBis = Object.values(mappedActivities)
            .reduce((acc, row) => {
              const { contactId, campaignId } = row;
              // if (!contactGroupId || contactGroupId === 'null') return acc;
              if (!acc[contactId]) {
                acc[contactId] = [campaignId];
                return acc;
              }
              acc[contactId].push(campaignId);
              return acc;
            }, {});

          // console.log('🚀 ~ file: Activity.store.js ~ line 70 ~ .then ~ contactsHasCampaignsBis', contactsHasCampaignsBis);
          commit('Contact/SET_CONTACTS_HAS_CAMPAIGNS_BIS', contactsHasCampaignsBis, { root: true });

          const contactsHasActivities = Object.values(mappedActivities).map(({ activityId, contactId }) => ({ activityId, contactId }));
          // console.log('🚀 ~ file: Activity.store.js ~ line 39 ~ .then ~ contactsHasActivities', contactsHasActivities);
          // commit('Contact/SET_CONTACTS_HAS_ACTIVITIES', contactsHasActivities, { root: true });

          const contactsHasActivitiesBis = contactsHasActivities
            .reduce((acc, row) => {
              const { activityId, contactId } = row;
              // if (!contactGroupId || contactGroupId === 'null') return acc;
              if (!acc[contactId]) {
                acc[contactId] = [activityId];
                return acc;
              }
              acc[contactId].push(activityId);
              return acc;
            }, {});

          commit('Contact/SET_CONTACTS_HAS_ACTIVITIES_BIS', contactsHasActivitiesBis, { root: true });
        });
    },
  },
  mutations: {
    SET_ACTIVITIES(state, newDatas) { state.activities = updateDatasIfExists({ newDatas, actualDatas: state.activities, key: 'activityId' }); },
    REMOVE_ACTIVITY(state, activityId) { delete state.activities[activityId]; },
    RESET_STORES(state) {
      state.activities = {};
    },
  },
};
