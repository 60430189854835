const sanitizeName = (str) => {
  if (!str || str.includes('undefined') || str.includes('null')) {
    return null;
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const getUbuUserName = (ubuUser) => {
  const {
    name, fullName, firstName, lastName,
  } = ubuUser;

  const _name = sanitizeName(name);
  if (_name) return _name;

  const _fullName = sanitizeName(fullName);
  if (_fullName) return _fullName;

  const _firstName = sanitizeName(firstName);
  const _lastName = sanitizeName(lastName);
  if (_firstName && _lastName) { return `${_firstName} ${_lastName}`; }

  return _firstName || _lastName || null;
};

const getUbuUserNameOrEmail = (ubuUser) => (ubuUser
  && (getUbuUserName(ubuUser)
      || ubuUser.email
      || 'Unfoundable name')
);

const getUbuUserInitials = (ubuUser) => {
  const {
    name, firstName, lastName, email,
  } = ubuUser;
  if (firstName && firstName) {
    // eslint-disable-next-line max-len
    return `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`;
  }
  if (name) {
    return name.split(' ')
      .map((s) => s[0])
      .join('')
      .substring(0, 2)
      .toUpperCase();
  }
  if (email) return `${email.substring(0, 2).toUpperCase()}`;
  return 'N/A';
};

let randIdx = 0;
const sanitizeUbuUser = (ubuUser) => {
  const sanitized = {
    ...ubuUser,
    name: getUbuUserNameOrEmail(ubuUser),
    // eslint-disable-next-line no-plusplus
    color: `${((randIdx++) % 12) + 1}`,
  };
  return { ...sanitized, initials: getUbuUserInitials(sanitized) };
};

export {
  sanitizeUbuUser,
  getUbuUserName,
  getUbuUserNameOrEmail,
  getUbuUserInitials,
};
