<template>
  <div id="UbuCRMTable">
    <b-table
      class="list-table"
      :data="listFiltered"
      :default-sort="['username', 'asc']"
      :loading="loading"
      paginated
      :sticky-header="true"
      per-page="30"
      pagination-rounded
      hoverable
      pagination-size="is-small"
    >
      <b-table-column
        v-slot="{ row }"
        label=""
      >
        <UbuButton
          secondary
          center
          @click="$emit('showHashtag', row.username)"
        >
          show
        </UbuButton>
      </b-table-column>
      <b-table-column
        v-slot="{ row }"
        field="username"
        label="Username"
        sortable
      >
        <div class="username-table">
          {{ row.username }}
        </div>
      </b-table-column>
      <b-table-column
        v-slot="{ row: { followerCount } }"
        field="followerCount"
        label="Followers"
        sortable
      >
        <span v-if="followerCount">{{ followerCount.toLocaleString('en') }} </span>
        <span v-else>-</span>
      </b-table-column>
      <b-table-column
        v-slot="{ row: { views } }"
        field="views"
        label="Views"
        sortable
      >
        <span v-if="views">{{ views.toLocaleString('en') }}</span>
        <span v-else>-</span>
      </b-table-column>
      <b-table-column
        v-slot="{ row: { engagement } }"
        field="engagement"
        label="Engagement"
        sortable
      >
        <span v-if="engagement && Number(engagement) >= 0.5">
          {{ Number(engagement).toFixed(2) }}%
        </span>
        <span v-else>-</span>
      </b-table-column>
      <b-table-column
        v-slot="{ row: { averagePostEngagement } }"
        field="averagePostEngagement"
        label="Average Post Engagement"
        sortable
      >
        <span v-if="averagePostEngagement">
          {{ Number(averagePostEngagement).toFixed(2) }}%
        </span>
        <span v-else>-</span>
      </b-table-column>
      <b-table-column
        v-slot="{ row: { averagePostLikes } }"
        field="averagePostLikes"
        label="Average Post Likes"
        sortable
      >
        <span v-if="averagePostLikes">
          {{ averagePostLikes.toLocaleString('en') }}
        </span>
        <span v-else>-</span>
      </b-table-column>
      <b-table-column
        v-slot="{ row: { averagePostComments } }"
        field="averagePostComments"
        label="Average Post Comments"
        sortable
      >
        <span v-if="averagePostComments">
          {{ averagePostComments.toLocaleString('en') }}
        </span>
        <span v-else>-</span>
      </b-table-column>
      <b-table-column
        v-slot="{ row: { averageReach } }"
        field="averageReach"
        label="Average Reach"
        sortable
      >
        <span v-if="averageReach">
          {{ averageReach.toLocaleString('en') }}
        </span>
        <span v-else>-</span>
      </b-table-column>
      <b-table-column
        v-slot="{ row: { nbPosts } }"
        field="nbPosts"
        label="Number of posts with keywords"
        sortable
      >
        <span v-if="nbPosts">{{ nbPosts.toLocaleString('en') }}</span>
        <span v-else>-</span>
      </b-table-column>
      <b-table-column
        v-slot="{ row: { age } }"
        field="age"
        label="Age"
        sortable
      >
        <span v-if="age && age !== '0'">{{ age }}</span>
        <span v-else>-</span>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import { timeToNow } from '../../$utils/dates';

export default {
  name: 'UbuInfluenceMentionsTikTokTable',
  props: {
    listFiltered: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isGenerateReportLoading: {
      type: String,
      default: null,
    },
    checkedRows: {
      type: Array,
      default: () => [],
    },
    threadGroupsList: {
      type: Array,
      default: () => [],
    },
    contactGroupsList: {
      type: Array,
      default: () => [],
    },
    campaignList: {
      type: Array,
      default: () => [],
    },
    isStatisticsLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      openedDetails: [],
      activeTab: 0,
    };
  },
  methods: {
    parseTimestamp(value) {
      return value ? timeToNow(value) : null;
    },
  },
};
</script>
