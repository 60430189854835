import builder from '../../$utils/axios';

export default class GlobalProvider {
  static createSalt({ email }) {
    // console.log('🚀 ~ file: auth.provider.js ~ line 5 ~ GlobalProvider ~ createSalt ~ email', email);

    return builder({
      method: 'post',
      url: '/login',
      data: { email },
    });
  }

  static login({ tokens }) {
    // console.log('🚀 ~ file: auth.provider.js ~ line 5 ~ GlobalProvider ~ login ~ tokens', tokens);

    return builder({
      method: 'post',
      url: '/authentify',
      data: { tokens },
    });
  }

  static sendEmailResetPassword({ email }) {
    // console.log('🚀 ~ file: auth.provider.js ~ line 5 ~ GlobalProvider ~ login ~ tokens', tokens);

    return builder({
      method: 'post',
      url: '/forgotPass',
      data: { email },
    });
  }

  static confirmEmail({ token }) {
    // console.log('🚀 ~ file: auth.provider.js ~ line 5 ~ GlobalProvider ~ login ~ tokens', tokens);

    return builder({
      method: 'post',
      url: '/confirmEmail',
      data: { confirmToken: token },
    });
  }

  static resetPassword({ token }) {
    return builder({
      method: 'post',
      url: '/setPassword',
      data: { token },
    });
  }

  static signup(payload) {
    return builder({
      method: 'post',
      url: '/signup',
      data: payload,
    });
  }

  static postAuthenticate({ email, password }) {
    return builder({
      method: 'post',
      url: '/authenticate',
      data: { email, password },
    });
  }

  static getAuthenticate() {
    // console.log('🚀 ~ file: auth.provider.js ~ line 5 ~ GlobalProvider ~ getAuthenticate ~ tokens');

    return builder({
      method: 'get',
      url: '/authenticate',
    });
  }

  static sendToken({ inviteToken }) {
    return builder({
      method: 'post',
      url: '/use_token',
      data: { invite_token: inviteToken },
    });
  }

  static canUseToken({ inviteToken }) {
    return builder({
      method: 'post',
      url: '/can_use_token',
      data: { invite_token: inviteToken },
    });
  }

  static logout() {
    // console.log('🚀 ~ file: auth.provider.js ~ line 5 ~ GlobalProvider ~ logout ~ tokens');

    return builder({
      method: 'delete',
      url: '/authenticate',
    });
  }
}
