<template>
  <div id="UbuCommonMentionCard">
    <div
      v-if="isLoading"
      class="UbuMentionsMediaCard_skeleton"
    >
      <b-skeleton
        :rounded="false"
        :active="isLoading"
        height="200px"
      />
    </div>
    <div
      v-if="media"
      class="UbuMentionsMediaCard_card"
      @click="$emit('show')"
    >
      <div class="UbuMentionsMediaCard_actions">
        <a
          class="UbuMentionsMediaCard_downloadIcon"
          :href="media.webContentLink"
          target="_blank"
          @click.stop
        >
          <UbuIcon
            name="download"
          />
        </a>
      </div>
      <div
        class="UbuMentionsMediaCard_gradient"
      >
        <video
          v-if="thumbnailHasError"
          muted
          poster
        >
          <source
            :src="media.webViewLink"
            :type="'video/mp4'"
          >
          Sorry, your browser doesn't support embedded videos.
        </video>
        <b-image
          v-else
          :class="[ 'UbuMentionsMediaCard_image', {'showImage': isImageLoaded }]"
          :src="media.thumbnailUrl"
          ratio="3by4"
          responsive
          lazy
          @load="onImgLoad()"
          @error="thumbnailHasError = true;"
        />
      </div>
      <div class="UbuMentionsMediaCard_infos">
        <span class="ubu-text2">@{{ media.username }}</span>
        <div class="UbuMentionsMediaCard_infos-bottom ubu-subtext1">
          <span>{{ media.date }}</span>
          <span class="UbuMentionsMediaCard_circle" />
          <span class="ubu-text2">{{ media.product_type === 'story' ? 'Story' : 'Post' }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'UbuCommonMentionCard',
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    media: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isImageLoaded: false,
      thumbnailHasError: false,
    };
  },
  methods: {
    onImgLoad() {
      this.isImageLoaded = true;
    },
  },
};
</script>
