<template>
  <div id="UbuWorkspaceAccountChannelTemplate">
    <img
      class="UbuWorkspaceAccountChannelTemplate_picture mr-2"
      :src="url"
      onError="this.src='../../img/no_profile_pict.svg';"
    >
    <div class="a-self-center">
      <div class="ubu-text1">@{{ username }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UbuWorkspaceAccountChannelTemplate',
  props: {
    username: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      default: '../../img/no_profile_pict.svg',
    },
  },
  methods: {
    getUrlPic() {
      return this.channel.data.profile_pic_url;
    },
  },

};
</script>
