<template>
  <div>
    <b-field>
      <b-input v-model="contactId" />
      <b-button
        type="is-success"
        @click="loadContactData()"
      >
        load
      </b-button>
    </b-field>
    <div class="UbuContactPanel_wrapper ubu-scrollbar">
      <UbuContactPanelHeader
        v-model="panel"
        :contact="getterContactPanelData"
      />
      <div
        v-if="panel.key"
        class="ubu-header1 UbuContactPanel_title"
      >
        {{ panel.title }}
      </div>
      <UbuContactPanelInfos
        v-if="!panel.key"
        :contact="getterContactPanelData"
        :channel-id="channelId"
        @updateContact="updateContact($event)"
        @generateReport="createReport($event)"
        @hardRefresh="hardRefresh($event)"
      />
      <UbuContactPanelNotes
        v-else-if="panel.key === 'notes'"
        :notes="getterContactPanelData.manualNotes"
      />
      <UbuContactPanelMessages
        v-else-if="panel.key === 'messages'"
        :contact="getterContactPanelData"
        :messages="getterContactPanelData.messages.slice(0, 10)"
        :gossips="getterContactPanelData.gossips.slice(0, 10)"
      />
      <UbuContactPanelMentions
        v-else-if="panel.key === 'mentions'"
        :medias="getterContactPanelData.medias"
      />
      <UbuContactPanelOrders v-else-if="panel.key === 'orders'" />
    </div>

    <b-modal
      v-model="isReportModalActive"
      :width="640"
      scroll="keep"
    >
      <UbuContactPanelReport
        :username="getterContactPanelData.username"
        :report="report"
      />
    </b-modal>

    <pre>
      {{ getterContactPanelData }}
    </pre>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TheDocumentationContactPanel',
  data() {
    return {
      isReportModalActive: false,
      report: {},
      contactId: '323447967', //  173560420  323447967  225441318
      channelId: '5f16a4ac59554b04ed0af866', //  607048003ffc0a421cb4585b    5f16a4ac59554b04ed0af866
      panel: {
        icon: null,
        key: null,
        title: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      getterContactPanelData: 'TheContactPanel/getterContactPanelData',
    }),
  },
  mounted() {
    this.sequence();
  },
  methods: {
    ...mapActions({
      sequence: 'TheContactPanel/sequence',
      loadPanel: 'TheContactPanel/loadPanel',
      updateContact: 'TheContactPanel/updateContact',
      generateReport: 'TheContactPanel/generateReport',
      hardRefresh: 'TheContactPanel/hardRefresh',
      fetchMedia: 'Media/fetchMedia',
    }),
    loadContactData() {
      // console.log('🚀 ~ file: TheContactPanel.vue ~ line 33 ~ loadContactData ~ this.contactId', this.contactId);
      this.loadPanel({
        channelId: this.channelId,
        contactId: this.contactId,
      }).then(() => {
        this.fetchMedia();
      });
    },
    createReport(event) {
      this.isReportModalActive = !this.isReportModalActive;
      return this.generateReport(event).then((response) => {
        console.log(
          '🚀 ~ file: TheContactPanel.vue ~ line 104 ~ .then ~ response',
          response,
        );
        this.report = response;
      });
    },
  },
};
</script>

<style  lang="scss" scoped>
.UbuContactPanel_wrapper {
  width: 300px;
  height: 700px;
  padding: 16px;
  background-color: var(--soapstone);
  overflow-y: auto;
  border: 1px solid var(--mercury);
  .UbuContactPanel_title {
    margin: 10px 0;
  }
}
</style>
