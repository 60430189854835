import { use, registerComponent } from '../../$utils/plugins';

// const UbuCommonNote = () => import(/* webpackChunkName: "addonCommon" */ './UbuCommonNote');
// const UbuCommonStoryMention = () => import(/* webpackChunkName: "addonCommon" */ './UbuCommonStoryMention');
// const UbuCommonActionLog = () => import(/* webpackChunkName: "addonCommon" */ './UbuCommonActionLog');
// const UbuCommonStoryShare = () => import(/* webpackChunkName: "addonCommon" */ './UbuCommonStoryShare');
// const UbuCommonPostShare = () => import(/* webpackChunkName: "addonCommon" */ './UbuCommonPostShare');
// const UbuCommonMessageList = () => import(/* webpackChunkName: "addonCommon" */ './UbuCommonMessageList');
// const UbuCommonMediaForImage = () => import(/* webpackChunkName: "addonCommon" */ './UbuCommonMediaForImage');
// const UbuCommonMediaForVideo = () => import(/* webpackChunkName: "addonCommon" */ './UbuCommonMediaForVideo');
// const UbuCommonMediaForAudio = () => import(/* webpackChunkName: "addonCommon" */ './UbuCommonMediaForAudio');
// const UbuCommonMessage = () => import(/* webpackChunkName: "addonCommon" */ './UbuCommonMessage');
// const UbuCommonMessageCard = () => import(/* webpackChunkName: "addonCommon" */ './UbuCommonMessageCard');
// const UbuCommonModal = () => import(/* webpackChunkName: "addonCommon" */ './UbuCommonModal');
// const UbuCommonImage = () => import(/* webpackChunkName: "addonCommon" */ './UbuCommonImage');
// const UbuCommonVideo = () => import(/* webpackChunkName: "addonCommon" */ './UbuCommonVideo');
// const UbuCommonProductShare = () => import(/* webpackChunkName: "addonCommon" */ './UbuCommonProductShare');
// const UbuCommonText = () => import(/* webpackChunkName: "addonCommon" */ './UbuCommonText');
// const UbuCommonStoryReply = () => import(/* webpackChunkName: "addonCommon" */ './UbuCommonStoryReply');
// const UbuCommonStoryReact = () => import(/* webpackChunkName: "addonCommon" */ './UbuCommonStoryReact');
// const UbuCommonAudio = () => import(/* webpackChunkName: "addonCommon" */ './UbuCommonAudio');
// const UbuCommonLink = () => import(/* webpackChunkName: "addonCommon" */ './UbuCommonLink');
// const UbuCommonGif = () => import(/* webpackChunkName: "addonCommon" */ './UbuCommonGif');
// const UbuCommonUnsupportedType = () => import(/* webpackChunkName: "addonCommon" */ './UbuCommonUnsupportedType');
// const UbuCommonProfileShare = () => import(/* webpackChunkName: "addonCommon" */ './UbuCommonProfileShare');
// const UbuCommonFelixShare = () => import(/* webpackChunkName: "addonCommon" */ './UbuCommonFelixShare');
// const UbuCommonComment = () => import(/* webpackChunkName: "addonCommon" */ './UbuCommonComment');
// const UbuCommonClip = () => import(/* webpackChunkName: "addonCommon" */ './UbuCommonClip');
// const UbuCommonTextarea = () => import(/* webpackChunkName: "addonCommon" */ './UbuCommonTextarea');
// const UbuCommonMentionCard = () => import(/* webpackChunkName: "addonCommon" */ './UbuCommonMentionCard');
// const UbuCommonMentionsCarousel = () => import(/* webpackChunkName: "addonCommon" */ './UbuCommonMentionsCarousel');
// const UbuCommonMentionsCarouselListItem = () => import(/* webpackChunkName: "addonCommon" */ './UbuCommonMentionsCarouselListItem');
// const UbuCommonMentionCardLarge = () => import(/* webpackChunkName: "addonCommon" */ './UbuCommonMentionCardLarge');

import UbuCommonNote from './UbuCommonNote';
import UbuCommonStoryMention from './UbuCommonStoryMention';
import UbuCommonActionLog from './UbuCommonActionLog';
import UbuCommonStoryShare from './UbuCommonStoryShare';
import UbuCommonPostShare from './UbuCommonPostShare';
import UbuCommonMessageList from './UbuCommonMessageList';
import UbuCommonMediaForImage from './UbuCommonMediaForImage';
import UbuCommonMediaForVideo from './UbuCommonMediaForVideo';
import UbuCommonMediaForAudio from './UbuCommonMediaForAudio';
import UbuCommonMessage from './UbuCommonMessage';
import UbuCommonMessageCard from './UbuCommonMessageCard';
import UbuCommonModal from './UbuCommonModal';
import UbuCommonImage from './UbuCommonImage';
import UbuCommonVideo from './UbuCommonVideo';
import UbuCommonProductShare from './UbuCommonProductShare';
import UbuCommonText from './UbuCommonText';
import UbuCommonStoryReply from './UbuCommonStoryReply';
import UbuCommonStoryReact from './UbuCommonStoryReact';
import UbuCommonAudio from './UbuCommonAudio';
import UbuCommonLink from './UbuCommonLink';
import UbuCommonGif from './UbuCommonGif';
import UbuCommonUnsupportedType from './UbuCommonUnsupportedType';
import UbuCommonProfileShare from './UbuCommonProfileShare';
import UbuCommonFelixShare from './UbuCommonFelixShare';
import UbuCommonComment from './UbuCommonComment';
import UbuCommonClip from './UbuCommonClip';
import UbuCommonTextarea from './UbuCommonTextarea';
import UbuCommonMentionCard from './UbuCommonMentionCard';
import UbuCommonMentionsCarousel from './UbuCommonMentionsCarousel';
import UbuCommonMentionsCarouselListItem from './UbuCommonMentionsCarouselListItem';
import UbuCommonMentionCardLarge from './UbuCommonMentionCardLarge';
import UbuCommonAnalyticsCell from './UbuCommonAnalyticsCell';
import UbuCommonSearchCard from './UbuCommonSearchCard';
import UbuCommonCommentRecursive from './UbuCommonCommentRecursive';
import UbuCommonCommentActions from './UbuCommonCommentActions';
import UbuCommonCommentFooter from './UbuCommonCommentFooter';
import UbuCommonCommentInfosUser from './UbuCommonCommentInfosUser';
import UbuCommonCommentBody from './UbuCommonCommentBody';
import UbuCommonTranslationDropdownItem from './UbuCommonTranslationDropdownItem';
import UbuCommonNewMessageModal from './UbuCommonNewMessageModal';
import UbuCommonAnalyticsCard from './UbuCommonAnalyticsCard';
import UbuCommonPostCard from './UbuCommonPostCard';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, UbuCommonNote);
    registerComponent(Vue, UbuCommonStoryMention);
    registerComponent(Vue, UbuCommonActionLog);
    registerComponent(Vue, UbuCommonStoryShare);
    // registerComponent(Vue, UbuCommonMessageWrapper);
    registerComponent(Vue, UbuCommonPostShare);
    // registerComponent(Vue, UbuCommonMessageError);
    registerComponent(Vue, UbuCommonMessageList);
    registerComponent(Vue, UbuCommonMediaForImage);
    registerComponent(Vue, UbuCommonMediaForVideo);
    registerComponent(Vue, UbuCommonMediaForAudio);
    registerComponent(Vue, UbuCommonMessage);
    registerComponent(Vue, UbuCommonMessageCard);
    registerComponent(Vue, UbuCommonModal);
    registerComponent(Vue, UbuCommonImage);
    registerComponent(Vue, UbuCommonVideo);
    registerComponent(Vue, UbuCommonProductShare);
    registerComponent(Vue, UbuCommonText);
    registerComponent(Vue, UbuCommonStoryReply);
    registerComponent(Vue, UbuCommonStoryReact);
    registerComponent(Vue, UbuCommonAudio);
    registerComponent(Vue, UbuCommonLink);
    registerComponent(Vue, UbuCommonGif);
    registerComponent(Vue, UbuCommonUnsupportedType);
    registerComponent(Vue, UbuCommonProfileShare);
    registerComponent(Vue, UbuCommonFelixShare);
    registerComponent(Vue, UbuCommonComment);
    registerComponent(Vue, UbuCommonClip);
    registerComponent(Vue, UbuCommonTextarea);
    registerComponent(Vue, UbuCommonMentionCard);
    registerComponent(Vue, UbuCommonMentionsCarousel);
    registerComponent(Vue, UbuCommonMentionsCarouselListItem);
    registerComponent(Vue, UbuCommonMentionCardLarge);
    registerComponent(Vue, UbuCommonAnalyticsCell);
    registerComponent(Vue, UbuCommonSearchCard);
    registerComponent(Vue, UbuCommonCommentRecursive);
    registerComponent(Vue, UbuCommonCommentActions);
    registerComponent(Vue, UbuCommonCommentFooter);
    registerComponent(Vue, UbuCommonCommentInfosUser);
    registerComponent(Vue, UbuCommonCommentBody);
    registerComponent(Vue, UbuCommonTranslationDropdownItem);
    registerComponent(Vue, UbuCommonNewMessageModal);
    registerComponent(Vue, UbuCommonAnalyticsCard);
    registerComponent(Vue, UbuCommonPostCard);
  },
};

use(Plugin);

export default Plugin;

export {
  UbuCommonNote,
  UbuCommonStoryMention,
  UbuCommonActionLog,
  UbuCommonStoryShare,
  // UbuCommonMessageWrapper,
  UbuCommonPostShare,
  // UbuCommonMessageError,
  UbuCommonMessageList,
  UbuCommonMediaForImage,
  UbuCommonMediaForVideo,
  UbuCommonMediaForAudio,
  UbuCommonMessage,
  UbuCommonMessageCard,
  UbuCommonModal,
  UbuCommonImage,
  UbuCommonVideo,
  UbuCommonProductShare,
  UbuCommonText,
  UbuCommonStoryReply,
  UbuCommonStoryReact,
  UbuCommonAudio,
  UbuCommonLink,
  UbuCommonGif,
  UbuCommonUnsupportedType,
  UbuCommonProfileShare,
  UbuCommonFelixShare,
  UbuCommonComment,
  UbuCommonClip,
  UbuCommonTextarea,
  UbuCommonMentionCard,
  UbuCommonMentionsCarousel,
  UbuCommonMentionsCarouselListItem,
  UbuCommonMentionCardLarge,
  UbuCommonAnalyticsCell,
  UbuCommonSearchCard,
  UbuCommonCommentRecursive,
  UbuCommonCommentActions,
  UbuCommonCommentFooter,
  UbuCommonCommentInfosUser,
  UbuCommonCommentBody,
  UbuCommonTranslationDropdownItem,
  UbuCommonNewMessageModal,
  UbuCommonAnalyticsCard,
  UbuCommonPostCard,
};
