<template>
  <section>
    <h1>TheDocumentation</h1>
    <!-- <form>
      <b-field>
        <b-input
          v-model="messageText"
        />
        <p class="controls">
          <b-button @click="sendMessage()">
            send
          </b-button>
        </p>
      </b-field>
    </form>
    <div>
      <p
        v-for="(message, index) in messages"
        :key="index"
      >
        {{ message }}
      </p>
    </div> -->
    <router-view />
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TheDocumentation',
  data() {
    return {
      messageText: '',
    };
  },
  computed: {
    ...mapGetters({
      socketInstance: 'Socket/getterSocketInstance',
      messages: 'Socket/getterMessages',
    }),
  },
  methods: {
    ...mapActions({
      UP_socketInstance: 'Socket/UP_socketInstance',
    }),
    sendMessage() {
      console.log('🚀 ~ file: TheDocumentation.vue ~ line 25 ~ socketInstance', this.socketInstance);
      console.log('🚀 ~ file: TheDocumentation.vue ~ line 42 ~ sendMessage ~ this.messageText', this.messageText);
      this.socketInstance.send(this.messageText);
    },
  },
};
</script>

<style lang="scss">
</style>
