<template>
  <div>
    <div class="UbuTextField_wrapper">
      <slot name="label">
        <label
          v-if="label"
          class="ubu-label"
          :for="labelId"
        >{{ label }}</label>
      </slot>
      <div :class="['UbuTextField_relativeInput', { 'UbuTextField_error' : error}]">
        <input
          :id="labelId"
          class="UbuTextField_input"
          :type="type === 'password' && show ? 'text' : type"
          :placeholder="placeholder"
          :value="value"
          @input="$emit('input', $event.target.value)"
        >
        <div class="UbuTextField_appendIcon">
          <slot name="append-icon">
            <UbuIcon
              v-if="value.length > 0"
              clickable
              type="ubu"
              name="cross"
              @click="() => $emit('input', '')"
            />
            <!-- <UbuIcon
              v-if="type === 'password' && value.length > 0"
              clickable
              type="fas"
              :name="!show ? 'eye' : 'eye-slack'"
              @click="() => show = !show"
            /> -->
            <a
              v-if="type === 'password' && value.length > 0"
              @click="() => show = !show"
            >
              <b-icon
                :icon="!show ? 'eye' : 'eye-off'"
              />
            </a>
          </slot>
        </div>
      </div>
      <span
        v-if="error != null"
        class="UbuTextField_textError"
      >{{ error }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'UbuTextField',
  props: {
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    value: {
      type: String,
      default: '',
    },
    clearable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    labelId() {
      return Math.random().toString(36).substring(7);
    },
  },
};
</script>
<style lang="scss" scoped>
    .UbuTextField_wrapper {
        display: flex;
        flex-direction: column;
        .UbuTextField_relativeInput {
            position: relative;

            .UbuTextField_appendIcon {
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                width: auto;
                right: 12px;
                top: 0;
                height: 100%;
                color: var(--darkgrey);
                > * { padding-left: 6px };
            }
        }
        input.UbuTextField_input {
            color: var(--cloudburst);
            width: 100%;
            border: 1px solid var(--mercury);
            border-radius: 6px;
            padding: 12px;
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            padding-right: 30px; // pour laisser l'icone de clear visible
            &::placeholder,
      &::-webkit-input-placeholder {
        color: var(--darkgrey60);
        opacity: 1;
      }
          &:focus {
              outline: none;
          }

          &:-webkit-autofill,
          &:-webkit-autofill:hover,
          &:-webkit-autofill:focus,
          &:-webkit-autofill:active,
          &:-internal-autofill-selected {
              -webkit-text-fill-color: var(--cloudburst) !important;
              -webkit-box-shadow: 0 0 0 50px var(--seashellpeach) inset !important;
          }
        }
    .UbuTextField_error{
      .UbuTextField_input{
        color: var(--error);
        border-color: var(--error);
      }
    }
    .UbuTextField_textError{
      color: var(--error);
    }
    }
</style>
