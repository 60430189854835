import builder from '../../$utils/axios';
// import builderMedia from '../../$utils/axiosMedia';

export default class WorkspaceProvider {
  static editUser({ ...data }) {
    return builder({
      method: 'post',
      url: '/user',
      data,
    });
  }

  static editUserPassword({ ...data }) {
    return builder({
      method: 'post',
      url: '/setPassword',
      data,
    });
  }

  static deleteMember({ channelId, ...data }) {
    return builder({
      method: 'post',
      url: `/channel/${channelId}/remove_member`,
      data,
    });
  }

  static addTag({ channelId, ...data }) {
    return builder({
      method: 'post',
      url: `/channel/${channelId}/customs`,
      data,
    });
  }

  static updateTag({ channelId, ...data }) {
    return builder({
      method: 'post',
      url: `/channel/${channelId}/customs`,
      data,
    });
  }

  static deleteTag({ channelId, ...data }) {
    return builder({
      method: 'post',
      url: `/channel/${channelId}/customs`,
      data,
    });
  }

  static addContactLabel({ channelId, ...data }) {
    return builder({
      method: 'post',
      url: `/channel/${channelId}/customs`,
      data,
    });
  }

  static updateContactLabel({ channelId, ...data }) {
    return builder({
      method: 'post',
      url: `/channel/${channelId}/customs`,
      data,
    });
  }

  static deleteContactLabel({ channelId, ...data }) {
    return builder({
      method: 'post',
      url: `/channel/${channelId}/customs`,
      data,
    });
  }

  static addTemplate({ channelId, ...data }) {
    return builder({
      method: 'post',
      url: `/channel/${channelId}/customs`,
      data,
    });
  }

  static updateTemplate({ channelId, ...data }) {
    return builder({
      method: 'post',
      url: `/channel/${channelId}/customs`,
      data,
    });
  }

  static deleteTemplate({ channelId, ...data }) {
    return builder({
      method: 'post',
      url: `/channel/${channelId}/customs`,
      data,
    });
  }

  static getInviteToken({ channelId }) {
    return builder({
      method: 'get',
      url: `/channel/${channelId}/invite_token`,
    });
  }

  static addChannel({ ...data }) {
    return builder({
      method: 'post',
      url: '/instalogin',
      data,
    });
  }

  static removeChannel(channelId) {
    // console.log('🚀 ~ file: Workspace.provider.js ~ line 116 ~ WorkspaceProvider ~ removeChannel ~ channelId', channelId);
    return builder({
      method: 'get',
      url: `/channel/${channelId}/delete`,
    });
  }
}
