<template>
  <UbuCommonMessageCard
    :is-me="isMe"
    :date="date"
    is-text
    @deleteMessage="$emit('deleteMessage')"
  >
    <template v-slot:content>
      <div class="card-content-text">
        <template
          v-for="(text, index) in textArray"
        >
          <span
            v-if="!text.isLink"
            :key="index"
          > {{ text.value }} </span>
          <a
            v-else
            :key="index"
            :href="text.url"
            target="_blank"
          >{{ text.value }}</a>
        </template>
      </div>
      <div
        v-if="linkPreview"
        class="card-content-link"
      >
        <a
          class="ubu-instalink-msg"
          :href="linkPreview.link_url"
          target="_blank"
        >{{ linkPreview.link_title }}</a>
        <a
          :href="linkPreview.link_url"
          target="_blank"
        >
          <img :src="linkPreview.link_image_url">
        </a>
      </div>
    </template>
  </UbuCommonMessageCard>
</template>

<script>
/* eslint-disable camelcase */

export default {
  name: 'UbuCommonLink',
  props: {
    isMe: {
      type: Boolean,
      default: false,
    },
    date: {
      type: String,
      required: true,
    },
    textArray: {
      type: Array,
      required: true,
    },
    link: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    linkPreview() {
      if (!this.link) return () => {};
      const { link_context } = this.link;
      if (!link_context) return () => {};
      const { link_image_url, link_title, link_url } = link_context;
      if (!link_image_url || !link_title || !link_url) return () => {};
      return link_context;
    },
  },
};
</script>
