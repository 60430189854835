import { use, registerComponent } from '../$utils/plugins';

// const TheThreadActionButton = () => import(/* webpackChunkName: "addonCommon" */ './TheThreadActionButton');
// const TheThreadHeader = () => import(/* webpackChunkName: "addonCommon" */ './TheThreadHeader');
// const TheThreadPanel = () => import(/* webpackChunkName: "addonCommon" */ './TheThreadPanel');

import TheThreadActionButton from './TheThreadActionButton';
import TheThreadHeader from './TheThreadHeader';
import TheThreadPanel from './TheThreadPanel';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, TheThreadActionButton);
    registerComponent(Vue, TheThreadHeader);
    registerComponent(Vue, TheThreadPanel);
  },
};

use(Plugin);

export default Plugin;

export {
  TheThreadActionButton,
  TheThreadHeader,
  TheThreadPanel,
};
