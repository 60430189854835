<template>
  <div class="UbuContactPanelMessages_wrapper">
    <div class="UbuContactPanelMessages_header">
      <UbuButton
        grow
        center
        :active="showMessages"
        simple
      >
        <!-- @click="() => (showMessages = true)" -->
        Messages
      </UbuButton>
      <UbuButton
        grow
        center
        :active="!showMessages"
        simple
        @click="() => (showMessages = false)"
      >
        Comments
      </UbuButton>
    </div>
    <div v-if="showMessages && messages.length">
      <div
        v-for="item of messages"
        :key="item.id"
        class="UbuThreadMessage_item"
      >
        <UbuMessageWrapper
          v-if="['media_share', 'text'].includes(item.item_type)"
          class="UbuThreadMessage_message"
          :date="item.date"
          :loading="item.loading"
          :contact="contact"
          :is-me="isMe(item)"
        >
          <UbuPostShare
            v-if="item.item_type === 'media_share'"
            :post="item.media_share"
            :is-me="isMe(item)"
          />
          <UbuText
            v-else-if="item.item_type === 'text'"
            :text="item.text"
          />
          <!-- <UbuMediaItem
            v-else-if="item.item_type === 'raven_media'"
            :media="item.visual_share.media"
            /> -->
        </UbuMessageWrapper>
        <UbuMedia
          v-else-if="['media', 'clip', 'raven_media'].includes(item.item_type)"
          class="UbuThreadMessage_message"
          :data="item"
        />
        <UbuStoryShare
          v-else-if="item.item_type === 'story_share'"
          class="UbuThreadMessage_message"
          :story="item.story_share"
          :data="item"
          :contact="contact"
          :date="item.date"
          :is-me="isMe(item)"
        />
        <UbuStoryMention
          v-else-if="
            (item.item_type === 'reel_share' &&
              item.reel_share.type === 'mention') ||
              item.subtype === 'mention'
          "
          class="UbuThreadMessage_message"
          :mention="item.reel_share"
          :contact="contact"
          :data="item"
          :date="item.date"
          :is-me="isMe(item)"
        />
      </div>
    </div>
    <div v-else-if="!showMessages && gossips.length">
      <div
        v-for="gossip of gossips"
        :key="gossip.id"
      >
        <UbuContactComment
          class="UbuContactPanelMessages_comment"
          :gossip="gossip"
        />
      </div>
    </div>
    <div
      v-else-if="showMessages && !messages.length"
      class="UbuContactPanelMessages_empty ubu-subtext1"
    >
      No Messages
    </div>
    <div
      v-else-if="!showMessages && !gossips.length"
      class="UbuContactPanelMessages_empty ubu-subtext1"
    >
      No Comments
    </div>
  </div>
</template>
<script>
import UbuContactComment from './UbuContactComment';

export default {
  name: 'UbuContactPanelMessages',
  components: {
    UbuContactComment,
  },
  props: {
    messages: {
      type: Array,
      default: () => ([]),
    },
    contact: {
      type: Object,
      default: () => ({}),
    },
    gossips: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      showMessages: false,
    };
  },
  methods: {
    isMe(item) {
      return !(!this.contact || item.user_id === this.contact.pk);
    },
  },
};
</script>
<style lang="scss" scoped>
.UbuContactPanelMessages_wrapper {
  display: flex;
  flex-direction: column;
  .UbuContactPanelMessages_header {
    display: flex;
    flex-grow: 1;
    > *:not(:last-child) {
      margin-right: 6px;
    }
  }
  .UbuThreadMessage_message {
    margin: 8px 0;
  }
  .UbuContactPanelMessages_comment {
    margin: 8px 0;
  }

  .UbuContactPanelMessages_empty {
    color: var(--darkgrey);
    align-self: center;
    margin: 24px;
  }
}
</style>
