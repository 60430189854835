<template>
  <div
    id="UbuFilterSelector"
    v-click-outside="() => showModal = false"
  >
    <button
      :class="['filterSelector-button ubu-text1', {'active': active}]"
      @click="showModal = !showModal"
    >
      {{ buttonTitle }}
    </button>

    <div
      v-if="showModal"
      class="filterSelector-modal"
    >
      <span class="ubu-text1 filterSelector-title">{{ modalTitle }}</span>

      <div class="filterSelector-container number-input-selector">
        <b-field
          label="Min"
          custom-class="is-small"
        >
          <b-numberinput
            v-model="localRange.min"
            size="is-small"
            controls-alignment="right"
            controls-position="compact"
            controls-rounded
            min="0"
            :controls="false"
            expanded
          />
        </b-field>

        <b-field
          label="Max"
          custom-class="is-small"
        >
          <b-numberinput
            v-model="localRange.max"
            size="is-small"
            controls-alignment="right"
            controls-position="compact"
            controls-rounded
            expanded
            :controls="false"
          />
        </b-field>
      </div>

      <div class="filterSelector-modal-button-group">
        <UbuButton
          grow
          center
          class="filterSelector-modal-button"
          @click="reset()"
        >
          Reset
        </UbuButton>
        <UbuButton
          grow
          center
          secondary
          class="FollowersSelector_UbuButton"
          @click="applyFilters()"
        >
          Apply filter
        </UbuButton>
      </div>
    </div>
  </div>
</template>

<script>
import { snackError } from '../../$catalog/snackbar';

export default {
  name: 'UbuNumberInputRangeSelector',
  props: {
    buttonTitle: {
      type: String,
      default: '',
    },
    modalTitle: {
      type: String,
      default: '',
    },
    range: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showModal: false,
      localRange: { min: this.range.min, max: this.range.max },
    };
  },
  computed: {
    active() {
      return this.showModal
       || (this.localRange.min !== this.range.min || this.localRange.max !== this.range.max);
    },
  },
  methods: {
    reset() {
      this.localRange = { min: this.range.min, max: this.range.max };
      this.showModal = false;
      this.$emit('setNumberInputFilter', this.localRange);
    },
    applyFilters() {
      if (this.localRange.min > this.localRange.max) {
        this.$buefy.snackbar.open({
          ...snackError,
          message: 'Min value must be lower than Max value',
          actionText: null,
        });
        return;
      }
      this.showModal = false;
      this.$emit('setNumberInputFilter', this.localRange);
    },
  },
};
</script>
