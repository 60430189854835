import builder from '../../$utils/axios';

export default class CrmProvider {
  static generateReport({ username, channelId }) {
    return builder({
      method: 'get',
      url: `/channel/${channelId}/FKRawReport/${username}`,
    });
  }

  static searchUser({ channelId, username }) {
    return builder({
      method: 'get',
      url: `/channel/${channelId}/instaUserName/${username}`,
    });
  }

  static fetchListUsers({ channelId, listId }) {
    return builder({
      method: 'get',
      url: `/channel/${channelId}/influence_list/${listId}`,
    });
  }

  static createList(payload) {
    return builder({
      method: 'post',
      url: `/channel/${payload.channel}/influence_list_order`,
      data: payload,
    });
  }

  static endList({ channelId, listId }) {
    return builder({
      method: 'delete',
      url: `/channel/${channelId}/influence_list_order/${listId}`,
    });
  }

  static removeUserFromList({ channelId, listId, pks }) {
    return builder({
      method: 'post',
      url: `/channel/${channelId}/hide_influence_list_line/${listId}`,
      data: {
        pks,
      },
    });
  }

  static removeUserFromCustomList({ channelId, listId, pks }) {
    return builder({
      method: 'post',
      url: `/channel/${channelId}/hide_custom_list_line/${listId}`,
      data: {
        pks,
      },
    });
  }

  static enrichProfiles({ channelId, usernames }) {
    return builder({
      method: 'post',
      url: `/channel/${channelId}/FKRawReportBulk`,
      data: JSON.stringify({ usernames }),
    });
  }

  static getUsersMentions({ channelId }) {
    return builder({
      method: 'get',
      url: `/channel/${channelId}/usersMentions`,
    });
  }

  static getUsersPosts({ channelId }) {
    return builder({
      method: 'get',
      url: `/channel/${channelId}/usersPosts`,
    });
  }
}
