<template>
  <div class="UbuMessageTextarea_wrapper">
    <div
      :class="['UbuMessageTextarea_message', {
        'UbuMessageTextarea_note': isNote,
        'UbuMessageTextarea_template': isTemplate,
      }]"
    >
      <slot />

      <UbuSimpleInput
        v-if="isTemplate"
        v-model="templateTitle"
        class="UbuMessageTextarea_templateTitle"
        placeholder="Template title..."
        @keyup="handleTitleSpace()"
      />

      <div
        v-else-if="file && !isNote && !isTemplate"
        class="pl-2 pr-2"
      >
        <div class="UbuMessageTextarea_files pb-1">
          <span
            class="tag UbuMessageTextarea_tag ml-1 mr-1"
          >
            {{ file.name }}
            <button
              class="delete is-small"
              type="button"
              @click="deleteFile()"
            />
          </span>
        </div>
        <div class="UbuMessageTextarea_divider" />
      </div>

      <textarea
        ref="input"
        v-model="localNewMessage"
        v-focus
        v-autogrow
        class="UbuMessageTextarea_input ubu-scrollbar"
        rows="1"
        maxlength="1000"
        :placeholder="placeholder"
        @blur="getCaretPosition()"
        @keyup.space="insertTemplate"
        @keydown.enter.exact.prevent="sendMessage"
        @keydown="keyPressHandler($event)"
      />

      <div>
        <VEmojiPicker
          v-if="showEmojiPicker"
          v-click-outside="() => showEmojiPicker = false"
          :dark="$root.isDark"
          class="UbuMessageTextarea_emojiPicker"
          @select="selectEmoji($event)"
        />
      </div>

      <div
        v-if="!isTemplate && !isNote"
        class="UbuMessageTextarea_toolBox fbetween"
      >
        <div class="UbuMessageTextarea_tools">
          <b-tooltip
            label="Add an emoji"
            position="is-top"
          >
            <UbuIcon
              clickable
              name="emojis"
              :tooltip="{ label: 'Emoji', position: 'is-top' }"
              @click="showEmojiPicker = !showEmojiPicker"
            />
          </b-tooltip>
          <b-tooltip
            label="Add a private note"
            position="is-top"
          >
            <UbuIcon
              clickable
              name="note"
              :tooltip="{ label: 'Note', position: 'is-top' }"
              @click="onClickNote()"
            />
          </b-tooltip>
          <div class="UbuMessageTextarea_dropdownParent">
            <b-tooltip
              label="Add a template"
              position="is-top"
            >
              <UbuIcon
                v-click-outside="e => dropdownOpened = false"
                class="a-self-center"
                name="templates"
                :filled="false"
                :tooltip="{ label: 'Templates', position: 'is-top' }"
                clickable
                @click="dropdownOpened = !dropdownOpened"
              />
            </b-tooltip>
            <div
              v-if="dropdownOpened"
              class="UbuMessageTextarea_dropdown"
            >
              <div class="UbuMessageTextarea_dropdownList">
                <div
                  v-for="(t, index) in templateList"
                  :key="index"
                >
                  <div
                    class="ubu-label cursor-pointer ellipsis"
                    @click="putTemplate(t)"
                  >
                    /{{ t.title }}
                  </div>
                </div>
              </div>
              <UbuButton
                class="UbuMessageTextarea_createTemplate ubu-label cursor-pointer mt-2"
                @click="onClickTemplate()"
              >
                Create template
              </UbuButton>
            </div>
          </div>

          <b-upload
            v-model="file"
            :multiple="false"
            accept="image/png, image/jpeg, image/svg"
          >
            <b-tooltip
              label="Attach a file"
              position="is-top"
            >
              <UbuIcon
                clickable
                name="attach"
                :tooltip="{ label: 'Attachments', position: 'is-top' }"
                style="font-size: 20px;"
              />
            </b-tooltip>
          </b-upload>

          <b-dropdown
            v-if="hasShopifyShop"
            class="dropdown-shopify"
            position="is-top-right"
          >
            <template #trigger>
              <b-tooltip
                label="Affiliation"
                position="is-top"
              >
                <UbuIcon
                  clickable
                  name="shop"
                />
              </b-tooltip>
            </template>

            <b-dropdown-item custom>
              <div class="dropdown-item-title">
                <span>Affiliation: </span>

                <b-tooltip label="Add affiliation to contact">
                  <UbuIcon name="info" />
                </b-tooltip>
              </div>
            </b-dropdown-item>

            <b-dropdown-item separator />

            <b-dropdown-item
              v-for="affiliation in affiliationList"
              :key="affiliation.id"
              @click="selectAffiliation(affiliation)"
            >
              <div class="">
                {{ affiliation.title }} ({{ affiliation.type }})
              </div>
            </b-dropdown-item>

            <b-dropdown-item
              @click="$emit('openModalAddLink', $event)"
            >
              <div class="text-geraldine">
                <UbuIcon name="add" /> Add link
              </div>
            </b-dropdown-item>
          </b-dropdown>

          <b-dropdown
            aria-role="list"
            position="is-top-right"
          >
            <template #trigger>
              <UbuIcon
                clickable
                name="translate"
              />
            </template>

            <b-dropdown-item
              class="translate-action"
              custom
            >
              <span>
                <UbuIcon
                  name="translate"
                /> Translate to: </span>
            </b-dropdown-item>

            <b-dropdown-item
              v-for="lang in languageList"
              :key="lang.value"
              :value="lang.value"
              @click="selectLanguage(lang.value)"
            >
              <div class="language-content">
                <b-image
                  class="language-flag"
                  :src="lang.icon"
                />
                {{ lang.label }}
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </div>

        <UbuButton
          v-if="showSendAndClose"
          class="ubu-f-end UbuButton_flatHover mr-1"
          :icon="{ name: 'send', filled: true }"
          color="2"
          :loading="buttonLoading"
          :tooltip="{
            label: isMac ? 'Cmd + Enter' : 'Ctrl + Enter',
            position: 'is-top',
            delay: 500
          }"
          @click="sendMessage({close: true})"
        >
          Send & Close
        </UbuButton>

        <UbuButton
          v-else-if="showSendAndDone"
          class="ubu-f-end UbuButton_flatHover mr-1"
          :icon="{ name: 'send', filled: true }"
          color="2"
          :loading="buttonLoading"
          :tooltip="{
            label: isMac ? 'Cmd + Enter' : 'Ctrl + Enter',
            position: 'is-top',
            delay: 500
          }"
          @click="sendMessage({done: true})"
        >
          Send & Done
        </UbuButton>

        <UbuButton
          v-if="localNewMessage.length || file"
          class="ubu-f-end UbuButton_flatHover"
          :icon="{ name: 'send', filled: true }"
          color="7"
          :loading="buttonLoading"
          @click="sendMessage({})"
        >
          Send
        </UbuButton>

        <template v-else>
          <UbuButton
            v-if="isDone(thread, currentThreadGroup)"
            class="ubu-f-end UbuButton_flatHover"
            color="2"
            :icon="{ name: 'success', filled: true }"
            :reactions="['🥰', '🙈', '✨', '😇', '❤️']"
            :loading="buttonLoading"
            :tooltip="{
              label: isMac ? 'Cmd + Enter' : 'Ctrl + Enter',
              position: 'is-left',
              delay: 500
            }"
            :reactions-tooltip="isMac ? 'Send emoji & done (Cmd+b)' :
              'Send emoji & close (Ctrl+b)'"
            @click="$emit('doneThread', { thread: thread, threadGroup: currentThreadGroup })"
            @react="sendMessage({done: true, reaction: $event})"
          >
            Done
          </UbuButton>
          <template v-else-if="!thread.closed">
            <UbuButton
              v-if="thread.threadGroups.length || !thread.isUnread"
              class="ubu-f-end UbuButton_flatHover"
              color="2"
              :icon="{ name: 'success', filled: true }"
              :reactions="['🥰', '🙈', '✨', '😇', '❤️']"
              :loading="buttonLoading"
              :tooltip="{
                label: isMac ? 'Cmd + Enter' : 'Ctrl + Enter',
                position: 'is-left',
                delay: 500
              }"
              :reactions-tooltip="isMac ? 'Send emoji & close (Cmd+b)' :
                'Send emoji & close (Ctrl+b)'"
              @click="$emit('closeThread', { thread })"
              @react="sendMessage({close: true, reaction: $event})"
            >
              Close
            </UbuButton>
            <UbuButton
              v-else
              class="ubu-f-end UbuButton_flatHover"
              color="2"
              :icon="{ name: 'success', filled: true }"
              :reactions="['🥰', '🙈', '✨', '😇', '❤️']"
              :loading="buttonLoading"
              :tooltip="{
                label: isMac ? 'Cmd + Enter' : 'Ctrl + Enter',
                position: 'is-left',
                delay: 500
              }"
              :reactions-tooltip="isMac ? 'Send emoji & close (Cmd+b)' :
                'Send emoji & close (Ctrl+b)'"
              @click="$emit('readAndClose', { thread })"
              @react="sendMessage({close: true, reaction: $event})"
            >
              Close
            </UbuButton>
          </template>
          <UbuButton
            v-else
            color="5"
            class="ubu-f-end UbuButton_flatHover"
            :icon="{ name: 'messages', filled: true }"
            :loading="buttonLoading"
            :tooltip="{
              label: isMac ? 'Cmd + Enter' : 'Ctrl + Enter',
              position: 'is-top',
              delay: 500
            }"
            @click="$emit('openThread', { thread })"
          >
            Open
          </UbuButton>
        </template>
      </div>
      <div
        v-else-if="isTemplate"
        class="UbuMessageTextarea_toolBox space-between"
      >
        <UbuIcon
          clickable
          name="emojis"
          class="a-self-center mr-1"
          :tooltip="{ label: 'Emoji', position: 'is-top' }"
          @click="showEmojiPicker = !showEmojiPicker"
        />
        <div class="flex">
          <UbuButton
            template
            class="mr-1"
            @click="changeToMessage()"
          >
            Cancel
          </UbuButton>
          <UbuButton
            template
            class="ml-1 mr-1"
            @click="saveTemplate()"
          >
            Save
          </UbuButton>
          <UbuButton
            template
            class="ml-1"
            @click="saveAndSendTemplate()"
          >
            Save & Send
          </UbuButton>
        </div>
      </div>
      <div
        v-else-if="isNote"
        class="UbuMessageTextarea_toolBox ubu-f-end"
      >
        <UbuButton
          note
          class="mr-1"
          @click="changeToMessage()"
        >
          Cancel
        </UbuButton>
        <UbuButton
          note
          class="ml-1"
          @click="sendMessage({})"
        >
          Post note
        </UbuButton>
      </div>
    </div>
  </div>
</template>
<script>
import { VEmojiPicker } from 'v-emoji-picker';
import { TextareaAutogrowDirective } from 'vue-textarea-autogrow-directive';
import { getThreadName } from '../../$utils/threadUtilities';
import TemplateMixin from '../$mixins/TemplateMixin';

export default {
  name: 'UbuCommonTextarea',
  components: {
    VEmojiPicker,
  },
  directives: {
    autogrow: TextareaAutogrowDirective,
  },
  mixins: [TemplateMixin],
  model: {
    prop: 'newMessage',
  },
  props: {
    thread: {
      type: Object,
      default: () => {},
    },
    currentThreadGroup: {
      type: Object,
      default: () => {},
    },
    templateList: {
      type: Array,
      default: () => [],
    },
    buttonLoading: {
      type: Boolean,
      default: false,
    },
    newMessage: {
      type: String,
      default: '',
    },
    affiliationList: {
      type: Array,
      default: () => [],
    },
    hasShopifyShop: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    file: null,
    showEmojiPicker: false,
    isNote: false,
    isTemplate: false,
    cursor: 0,
    templateTitle: '',
    placeholder: 'Type a message...',
    isClosedThread: true,
    dropdownOpened: false,
    // searchedAffiliation: '',
    languageList: [
      { label: 'English', value: 'en', icon: '/img/UK.svg' },
      { label: 'French', value: 'fr', icon: '/img/France.svg' },
      { label: 'Spanish', value: 'es', icon: '/img/Spain.svg' },
      { label: 'German', value: 'de', icon: '/img/Germany.svg' },
    ],
  }),
  computed: {
    localNewMessage: {
      get() { return this.newMessage; },
      set(value) { this.$emit('input', value); },
    },
    fullname() {
      return getThreadName(this.thread);
    },
    showSendAndClose() {
      const isDone = this.isDone(this.thread, this.currentThreadGroup);
      return (this.localNewMessage.length || this.file) && !this.thread.closed && !isDone;
    },
    showSendAndDone() {
      const isDone = this.isDone(this.thread, this.currentThreadGroup);
      return (this.localNewMessage.length || this.file) && isDone;
    },
    isMac() {
      return navigator.platform.toUpperCase().indexOf('MAC') >= 0;
    },
  },
  beforeDestroy() {
    const { threadId } = this.thread;
    if (typeof (localStorage) !== 'undefined' && threadId) {
      const value = this.localNewMessage.trim();
      const localValue = localStorage.getItem(`draft:${threadId}`);
      if (value !== localValue) {
        if (localValue && !value.length) {
          localStorage.removeItem(`draft:${threadId}`);
        } else if (value.length) {
          localStorage.setItem(`draft:${threadId}`, value);
          this.$buefy.snackbar.open({
            message: 'Message saved as draft',
            type: 'is-success',
            position: 'is-bottom-right',
            queue: false,
          });
        }
      }
    }
  },
  mounted() {
    const { threadId } = this.thread;
    if (typeof (localStorage) !== 'undefined' && threadId) {
      const value = localStorage.getItem(`draft:${threadId}`);
      if (value) this.localNewMessage = value;
    }
    this.resizeParent();
  },
  methods: {
    isDone(thread, threadGroup) {
      return threadGroup && !!thread.threadGroups
        .find((tg) => String(tg.threadGroupId) === String(threadGroup.threadGroupId));
    },

    deleteFile() {
      this.file = null;
    },

    // onInput(event) {
    //   this.localNewMessage = event.target.value;
    //   this.resizeParent();
    // },

    resizeParent() {
      setTimeout(() => this.$emit('autoResizeFooter'), 0);
    },

    insertTemplate(event) {
      const { value } = event.target;
      /**
       * @todo Optimiser ça, ça me semble très lourd quand même
       * Je met un reg.test pour éviter des events inutiles;
       */
      Object.values(this.templateList).forEach((template) => {
        const re = new RegExp(`(?<=\\s||^)(\\/${template.title})\\b`);
        if (re.test(value)) {
          const templateContent = template.data.replace(/{name}/gi, this.fullname);
          this.simulateEventTextarea(value.replace(re, templateContent));
        }
      });
    },

    simulateEventTextarea(text) {
      const evt = document.createEvent('Events');
      evt.initEvent('input', true, true);
      evt.data = text;
      this.$refs.input.value = text;
      this.$refs.input.dispatchEvent(evt);
      this.resizeParent();
    },

    changeToMessage() {
      this.isTemplate = false;
      this.isNote = false;
      this.placeholder = 'Type a message...';
      this.resizeParent();
    },
    onClickNote() {
      this.isTemplate = false;
      this.isNote = !this.isNote;
      this.placeholder = !this.isNote ? 'Type a message...' : 'Type a note...';
    },
    onClickTemplate() {
      return this.$router.push({ path: '/workspace/templates' });
      // this.isNote = false;
      // this.isTemplate = !this.isTemplate;
      // this.placeholder = !this.isTemplate ? 'Type a message...' : 'Template content...';
      // if (this.isTemplate) this.dropdownOpened = false;
      // setTimeout(() => {
      //   this.resizeParent();
      // }, 0);
    },

    putTemplate(t) {
      const templateArray = this.localNewMessage.split('');
      const finalData = t.data.replace(/{name}/gi, this.fullname);
      templateArray.splice(this.cursor, 0, finalData);
      this.simulateEventTextarea(templateArray.join(''));
      this.$refs.input.focus();
    },

    saveTemplate() {
      if (this.checkTemplate(undefined, this.localNewMessage)) {
        this.$emit('saveTemplate', { title: this.templateTitle, data: this.localNewMessage, type: 'template' });
        // this.localNewMessage = '';
        this.templateTitle = '';
        this.changeToMessage();
      }
    },
    saveAndSendTemplate() {
      if (this.checkTemplate(undefined, this.localNewMessage)) {
        this.$emit('send', this.localNewMessage);
        this.saveTemplate();
      }
    },

    getCaretPosition() {
      this.cursor = this.$refs.input.selectionStart;
    },
    selectEmoji(emoji) {
      const templateArray = this.localNewMessage.split('');
      templateArray.splice(this.cursor, 0, emoji.data);
      this.simulateEventTextarea(templateArray.join(''));
      this.$refs.input.focus();
      this.$refs.input.selectionStart = this.cursor + emoji.data.length;
      this.$refs.input.selectionEnd = this.cursor + emoji.data.length;
      this.showEmojiPicker = false;
    },
    sendMessage({ close = false, done = false, reaction }) {
      // if ((close || done) && !this.allowClose) return ;
      this.localNewMessage = this.localNewMessage.trim();
      if (close && reaction) this.$emit('sendAndClose', reaction);
      if (done && reaction) this.$emit('sendAndDone', reaction);
      if (!this.localNewMessage.length && !this.file) return this.simulateEventTextarea('');
      if (this.isNote) this.$emit('note', this.localNewMessage.trim());
      else if (this.file && close) this.$emit('imageWithTextAndClose', { image: this.file, text: this.localNewMessage });
      else if (this.file && done) this.$emit('imageWithTextAndDone', { image: this.file, text: this.localNewMessage });
      else if (this.file) this.$emit('imageWithText', { image: this.file, text: this.localNewMessage });
      else if (close) this.$emit('sendAndClose', this.localNewMessage);
      else if (done) this.$emit('sendAndDone', this.localNewMessage);
      else this.$emit('send', this.localNewMessage);
      this.simulateEventTextarea('');
      this.file = null;
      return undefined;
    },
    randomEmoji() {
      const emojis = ['🥰', '🙈', '✨', '😇', '❤️'];
      return emojis[Math.floor(Math.random() * emojis.length)];
    },
    keyPressHandler(event) {
      const ctrlPressed = this.isMac ? event.metaKey : event.ctrlKey;
      const ctrlEnterPressed = ctrlPressed && event.keyCode === 13;
      const ctrlBPressed = ctrlPressed && event.keyCode === 66;
      const isDone = this.isDone(this.thread, this.currentThreadGroup);
      if (ctrlBPressed && isDone) {
        return this.sendMessage({ done: true, reaction: this.randomEmoji() });
      }
      if (ctrlBPressed && !this.thread.closed) {
        return this.sendMessage({ close: true, reaction: this.randomEmoji() });
      }
      if (!ctrlEnterPressed) return undefined;
      const emptyMessage = !this.localNewMessage.length && !this.file;
      if (this.isTemplate || this.isNote) return undefined;
      if (this.showSendAndClose) return this.sendMessage({ close: true });
      if (this.showSendAndDone) return this.sendMessage({ done: true });
      if (!emptyMessage) return undefined;

      if (this.isDone(this.thread, this.currentThreadGroup)) {
        this.$emit('doneThread', { thread: this.thread, threadGroup: this.currentThreadGroup });
        return undefined;
      }
      if (this.thread.closed) {
        this.$emit('openThread', { thread: this.thread });
        return undefined;
      }
      const isUnread = !this.thread.threadGroups.length && this.thread.isUnread;
      if (isUnread) this.$emit('readAndClose', { thread: this.thread });
      else this.$emit('closeThread', { thread: this.thread });

      return undefined;
    },
    selectLanguage(lang) {
      this.$emit('translateTextarea', { target: lang });
    },
    selectAffiliation(affiliation) {
      if (affiliation.type === 'referral') this.localNewMessage += affiliation.link;
      if (affiliation.type === 'discount') this.localNewMessage += affiliation.title;
    },
  },
};
</script>
<style lang="scss">
.UbuMessageTextarea_wrapper {
  margin: 12px;

  .UbuMessageTextarea_files {
    display: flex;
    overflow: auto;
    flex-wrap: nowrap;

    .UbuMessageTextarea_tag {
      background-color: var(--givry);
      color: var(--cloudburst);
      font-weight: 600;
    }
  }

  .UbuMessageTextarea_divider {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      border-top: 1px solid var(--mercury);
    }

  .UbuMessageTextarea_message {
    display: flex;
    flex-direction: column;
    padding: 16px 0px;
    position: relative;
    z-index: 20;

    background: var(--soapstone);
    box-shadow: 0px 1px 6px var(--box-shadow);
    border-radius: 18px;
    height: auto;

    .UbuMessageTextarea_templateTitle {
      padding: 0 21px;
      width: 100%;
      margin-bottom: 6px;
    }

    .UbuMessageTextarea_input {
      font-weight: 500;
      font-size: 1rem;
      height: 24px;
      padding: 12px 24px;
      color: var(--cloudburst);
      width: 100%;
      resize: none;
      max-height: 140px;
      overflow: auto;
      &:focus {
        outline: none;
      }
      &::placeholder{
        color: var(--darkgrey60);
      }
      margin-bottom: 12px;
    }

    &.UbuMessageTextarea_note {
      background-color: var(--note);

      .UbuMessageTextarea_input {
        color: var(--cola);
      }
    }

    &.UbuMessageTextarea_template {
      background-color: var(--template);
    }
  }

  .UbuMessageTextarea_toolBox {
    display: flex;
    flex-direction: row;
    font-size: 20px;
    margin: 0 24px;

    &.space-between {
      justify-content: space-between;
    }

    .UbuMessageTextarea_tools {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      align-items: center;
      > * {
        margin-right: 16px;
      }

      .UbuMessageTextarea_dropdownParent {
        display: inline-flex;
        position: relative;

        .UbuMessageTextarea_dropdown {
          z-index: 100;
          position: absolute;
          bottom: calc(100% + 6px);
          background-color: var(--soapstone);
          box-shadow: 0px 0px 10px var(--box-shadow);
          border-radius: 12px;
          padding: 12px;
          width: max-content;
          max-width: 200px;
          .UbuMessageTextarea_dropdownList {
            max-height: 300px;
            overflow: auto;
          }
        }
      }
    }

    .UbuMessageTextarea_createTemplate {
      display: flex;
      justify-content: center;
    }
  }
  & .dropdown-item, .dropdown .dropdown-menu .has-link a {
    color: var(--darkgrey);
  }

  & .dropdown-shopify {
    & .dropdown-content {
      background-color: var(--peachcream);
    }
  }

  & a.dropdown-item, .dropdown .dropdown-menu .has-link a, button.dropdown-item {
    padding-right: 0.5rem;
  }
  & a.dropdown-item.is-active, .dropdown .dropdown-menu .has-link a.is-active, button.dropdown-item.is-active {
    background-color: var(--givry);
    // color: #fff;
  }
  & a.dropdown-item:hover, .dropdown .dropdown-menu .has-link a:hover, button.dropdown-item:hover {
    background-color: var(--peachcream);
  }
  & .dropdown-item-title {
    display: flex;
    justify-content: space-between;
  }
}
.UbuMessageTextarea_emojiPicker {
  &.emoji-picker {
    --ep-color-bg: var(--seashellpeach) !important;
    --ep-color-sbg: var(--mercury60) !important;
    --ep-color-border: var(--mercury60) !important;
    --ep-color-text: var(--cloudburst) !important;
    --ep-color-active: var(--geraldine) !important;
    #InputSearch > div.container-search > input[type=text] {
      border-radius: 20px !important;
      outline: none;
    }
  }
  position: absolute;
  bottom: 50px;
  left: 10px;
}
</style>
