import { use, registerComponent } from '../../$utils/plugins';

// const UbuThreadCard = () => import(/* webpackChunkName: "addonInbox" */ './UbuThreadCard');
// const UbuThreadList = () => import(/* webpackChunkName: "addonInbox" */ './UbuThreadList');
// const UbuTHShowClosed = () => import(/* webpackChunkName: "addonInbox" */ './UbuTHShowClosed');
// const UbuTHCustomHeader = () => import(/* webpackChunkName: "addonInbox" */ './UbuTHCustomHeader');

import UbuThreadCard from './UbuThreadCard';
import UbuThreadList from './UbuThreadList';
import UbuTHShowClosed from './UbuTHShowClosed';
import UbuTHCustomHeader from './UbuTHCustomHeader';
import UbuInboxModalCreateThread from './UbuInboxModalCreateThread';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, UbuThreadCard);
    registerComponent(Vue, UbuThreadList);
    registerComponent(Vue, UbuTHShowClosed);
    registerComponent(Vue, UbuTHCustomHeader);
    registerComponent(Vue, UbuInboxModalCreateThread);
  },
};

use(Plugin);

export default Plugin;

export {
  UbuThreadCard,
  UbuThreadList,
  UbuTHShowClosed,
  UbuTHCustomHeader,
  UbuInboxModalCreateThread,
};
