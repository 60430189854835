<template>
  <div class="workspace-modal">
    <div class="modal-header">
      <p class="modal-header-title ubu-title">Edit template</p>

      <UbuIcon
        name="cross"
        clickable
        @click="$emit('close', $event)"
      />
    </div>
    <div class="modal-content">
      <div class="modal-content-input-wrapper">
        <UbuSimpleInput
          v-model="templateTitle"
          class="modal-content-input pl-3"
          :focus="true"
          placeholder="Title..."
          @keyup="handleTitleSpace()"
        />
        <UbuTemplateTextarea
          v-model="template.data"
          :template-list="templateList"
          show-controls
          @focusout="canUseTemplateData(template.data)"
        />
      </div>
    </div>
    <div class="modal-footer">
      <UbuButton
        grow
        center
        secondary
        @click="editFormModal()"
      >
        Save
      </UbuButton>
    </div>
  </div>
</template>

<script>
import VModelMixin from '../../$mixins/VModelMixin';
import TemplateMixin from '../../common/$mixins/TemplateMixin';

export default {
  name: 'UbuWorkspaceModalEditTemplate',
  mixins: [VModelMixin, TemplateMixin],
  model: {
    prop: 'template',
  },
  props: {
    templateList: {
      type: Array,
      default: () => [],
    },
    template: {
      type: Object,
      required: true,
    },
    currentTitle: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dropdownOpened: false,
      templateTitle: this.template.title,
    };
  },
  methods: {
    editFormModal() {
      if (this.checkTemplateData(this.template.data)) {
        this.$emit('editFromModal', { ...this.template, new_title: this.templateTitle });
      }
    },
  },
};
</script>
