import { use, registerComponent } from '../../$utils/plugins';

import UbuLeadsTable from './UbuLeadsTable';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, UbuLeadsTable);
  },
};

use(Plugin);

export default Plugin;

export {
  UbuLeadsTable,
};
