<template>
  <section
    v-if="fetchDone"
    id="UbuInfluenceLeadsDrawers"
    class="flex"
  >
    <section id="UbuInfluenceLeadsAddLeads">
      <UbuInfluenceLeadsAddLeads
        :contact-list="contacts"
        :threads-list="threadContacts"
        :campaign-list="campaignContacts"
        :leads="leads"
        :all-campaign-contact="allCampaignContact"
        :contacts-groups="contactsGroups"
        :thread-groups="threadGroups"
        :campaign-groups="campaignGroups"
        @sendUsernames="sendUsernames($event)"
        @addContacts="addContacts($event)"
      />

      <b-modal
        id="UbuInfluenceLeadsImporterModal"
        v-model="isCardModalActive"
        :width="640"
        scroll="keep"
      >
        <UbuInfluenceLeadsImporterModalList
          class="is-color5"
          :list="bulkSuccess"
          :title="'Successfully added'"
          :is-research-ended="isResearchEnded"
        />
        <UbuInfluenceLeadsImporterModalList
          class="is-color8"
          :list="bulkAlready"
          :title="'Already in campaign'"
          :is-research-ended="true"
        />
        <UbuInfluenceLeadsImporterModalList
          class="is-color2"
          :list="bulkFail"
          :title="'Not found'"
          :is-research-ended="isResearchEnded"
        />
        <UbuButton
          class="mutibar column end"
          center
          @click="isCardModalActive = !isCardModalActive"
        >
          Okay
        </UbuButton>
      </b-modal>
    </section>
    <section
      v-if="selectedsRows.length === 0"
      id="UbuInfluenceLeadsWaitingReview"
    >
      <UbuInfluenceLeadsWaitingReview
        :leads="unscheduleds"
        :checked-rows.sync="checkedRows"
        @reviewAll="reviewAll($event)"
        @reviewSelecteds="reviewSelecteds($event)"
        @reviewOne="reviewOne($event)"
        @updateCheckeds="updateCheckeds($event)"
        @removeOneLead="removeOneLead($event)"
      />
    </section>
    <section
      v-else
      id="UbuInfluenceLeadsComposeMessage"
    >
      <UbuInfluenceLeadsComposeMessage
        :leads="selectedsRows"
        :campaign-detail="campaign"
        :templates="templates"
        @removeSelected="removeSelected()"
        @reviewMessage="reviewMessage($event)"
        @setDelivereds="createDelivereds($event)"
        @saveNewTemplate="addCampaignTemplate($event)"
        @deleteCampaignTemplate="deleteCampaignTemplate($event)"
      />
    </section>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import contactTemplate from '../dataStores/template/contact.template';
import CommonMixin from '../$mixins/CommonMixin';
import BulkImportMixin from '../$mixins/BulkImportMixin';
import { snackSuccess, snackError } from '../$catalog/snackbar';

export default {
  name: 'TheInfluenceLeads',
  mixins: [CommonMixin, BulkImportMixin],
  data() {
    return {
      isCardModalActive: false,
      isResearchEnded: false,
      checkedRows: [],
      selectedsRows: [],
      leads: [],
      bulkSuccess: [],
      bulkFail: [],
      bulkAlready: [],
    };
  },
  computed: {
    ...mapGetters({
      getterChannelContacts: 'TheInfluence/getterChannelContacts',
      campaignActivitiesDetails: 'TheInfluence/getterCampaignActivitiesDetails',
      fetchDone: 'Sequence/getterFetchDone',
      templates: 'TheWorkspace/getterTemplates',
    }),
    channelId() {
      return this.campaignActivitiesDetails._id || '';
    },
    campaign() {
      return this.campaignActivitiesDetails.campaigns
        .find((campaign) => campaign.campaignId === this.$route.params.campaignId);
    },
    activities() {
      return this.campaign ? this.campaign.activities : [];
    },
    allCampaignContact() {
      return this.activities
        .filter(({ contactDetail }) => contactDetail)
        .map(({
          activityDetail: {
            created: when, status, activityId, ended,
          },
          contactDetail: {
            username,
            // eslint-disable-next-line camelcase
            full_name,
            // eslint-disable-next-line camelcase
            profile_pic_url,
            contactId,
            // eslint-disable-next-line camelcase
            has_bucket_media,
            engagement,
            // eslint-disable-next-line camelcase
            follower_count,
          },
          mentions,
        }) => ({
          activityId,
          username,
          full_name,
          profile_pic_url,
          when,
          status,
          mentions,
          contactId,
          ended,
          has_bucket_media,
          engagement,
          follower_count,
        }));
    },
    unscheduleds() {
      return this.allCampaignContact.filter((c) => c.status === 'unscheduled');
    },
    contacts() {
      return this.getterChannelContacts.contacts || [];
    },
    threads() {
      return this.getterChannelContacts.contacts || [];
    },
    contactsGroups() {
      return this.campaignActivitiesDetails.customs.filter(({ type }) => type === 'label');
    },
    threadGroups() {
      return this.campaignActivitiesDetails.customs.filter(({ type }) => type === 'tag');
    },
    campaignGroups() {
      return this.campaignActivitiesDetails.campaigns
        .map(({ name: title, color }) => ({ title, color: this.convertColor(color) }));
    },
    campaignContacts() {
      const data = this.campaignActivitiesDetails.campaigns
        .flatMap(({ name: title, activities }) => activities
          .map(({ contactDetail, activityDetail: { status } }) => ({
            ...contactDetail,
            title,
            status,
          })))
        .reduce((acc, contact) => {
          if (!acc[contact.contactId]) {
            acc[contact.contactId] = {
              ...contact,
              status: contact.status,
              campaignGroup: [{ title: contact.title }],
            };
            return acc;
          }
          acc[contact.contactId] = {
            ...acc[contact.contactId],
            status: contact.status,
            campaignGroup: [...acc[contact.contactId].campaignGroup, { title: contact.title }],
          };
          return acc;
        }, {});

      return Object.values(data);
    },
    threadContacts() {
      return this.threads.map((thread) => {
        const {
          threadGroups,
          // eslint-disable-next-line camelcase
          thread_title,
          threadId,
          ...contact
        } = thread;
        return ({
          ...contact,
          thread_title,
          threadId,
          threadGroupList: threadGroups.map((th) => th.title),
        });
      });
    },
  },
  methods: {
    ...mapActions({
      setUnscheduled: 'TheInfluence/setUnscheduled',
      setDelivereds: 'TheInfluence/setDelivereds',
      getContactByUsername: 'TheInfluence/getContactByUsername',
      addContactsFromScraping: 'TheInfluence/addContactsFromScraping',
      sendReviewMessage: 'TheInfluence/sendReviewMessage',
      deleteActivity: 'TheInfluence/deleteActivity',
      updateCampaign: 'TheInfluence/updateCampaign',
    }),
    deleteCampaignTemplate(template) {
      const { activities, messages, ...rest } = this.campaign;
      const newMessages = messages;
      newMessages.splice(newMessages.indexOf(template), 1);
      const myCampaign = {
        ...rest,
        messages: newMessages,
      };
      this.updateCampaign(myCampaign);
    },
    removeSelected() {
      // console.log('🚀 ~ file: TheInfluenceLeads.vue ~ line 336 ~ removeSelected ~ event');
      this.selectedsRows = [];
    },
    reviewAll(event) {
      // console.log('🚀 ~ file: TheInfluenceLeads.vue ~ line 336 ~ reviewAll ~ event', event);
      this.selectedsRows = event;
    },
    reviewSelecteds() {
      // console.log('🚀 ~ file: TheInfluenceLeads.vue ~ line 336 ~ reviewSelecteds ~ event', event);
      this.selectedsRows = this.checkedRows;
    },
    reviewOne(event) {
      // console.log('🚀 ~ file: TheInfluenceLeads.vue ~ line 336 ~ reviewOne ~ event', event);
      this.selectedsRows = [event];
    },
    updateCheckeds(event) {
      this.checkedRows = event;
    },
    addCampaignTemplate(newTemplateText) {
      const { activities, messages, ...rest } = this.campaign;
      const myCampaign = {
        ...rest,
        messages: [...messages, newTemplateText],
      };
      this.updateCampaign(myCampaign);
    },
    sendUsernames(event) {
      const splited = this
        .convertListOfNamesIntoArrayOfNames(event)
        .reduce((acc, username) => {
          if (this.allCampaignContact.map(({ username: u }) => u).includes(username)) {
            acc.alreadyInCampaign.push({ username });
            return acc;
          }
          acc.toSearch.push(username);
          return acc;
        }, { toSearch: [], alreadyInCampaign: [] });

      this.isCardModalActive = true;
      this.isResearchEnded = false;
      this.bulkAlready = splited.alreadyInCampaign;
      this.getContactByUsername({
        splited: splited.toSearch,
        channelId: this.channelId,
      })
        .then((responses) => {
          this.bulkFail = responses.filter((r) => r.error);
          this.bulkSuccess = responses
            .filter((r) => r.response)
            .map(({ response }) => contactTemplate({ ...response, channelId: this.channelId }));
          console.log('🚀 ~ file: TheInfluenceLeads.vue ~ line 224 ~ .then ~ bulkSuccess', this.bulkSuccess);
          this.isResearchEnded = true;
        })
        // All successfully founded contact are added to front data stores
        .then(() => this.addContactsFromScraping(this.bulkSuccess))
        .then(() => this.addContacts(this.bulkSuccess));
    },
    addContacts(contacts) {
      const activities = contacts.map((contact) => ({
        pk: contact.contactId,
        username: contact.username,
        status: 'unscheduled',
        channel: this.channelId,
        campaignId: this.$route.params.campaignId,
        campaign: this.$route.params.campaignId,
      }));

      // Create activities
      this.setUnscheduled({
        campaignId: this.$route.params.campaignId,
        channelId: this.channelId,
        activities,
      });

      // Disallow contact to be selected again in panel add leads
      this.leads.push(contacts);
    },
    createDelivereds(event) {
      const { targets: contacts } = event;
      const activities = contacts.map((contact) => ({
        pk: contact.contactId,
        username: contact.username,
        status: 'delivered',
        channel: this.channelId,
        campaignId: this.$route.params.campaignId,
        campaign: this.$route.params.campaignId,
        _id: contact.activityId,
      }));

      // Create activities
      this.setDelivereds({
        campaignId: this.$route.params.campaignId,
        channelId: this.channelId,
        activities,
      })
        .then(({ response }) => {
          this.selectedsRows = [];
          if (response) {
            this.$buefy.snackbar.open({
              message: 'New contacts added to campaign',
              ...snackSuccess,
            });
          } else {
            this.$buefy.snackbar.open({
              message: 'Something go wrong when adding contacts to campaign',
              ...snackError,
            });
          }
        });
    },
    reviewMessage(event) {
      console.log('🚀 ~ file: TheInfluenceLeads.vue ~ line 311 ~ reviewMessage ~ event', event);
      this.sendReviewMessage({
        ...event,
        channelId: this.channelId,
        campaignId: this.$route.params.campaignId,
      })
        .then(({ response }) => {
          this.selectedsRows = [];
          if (response) {
            this.$buefy.snackbar.open({
              message: 'New messages scheduleds',
              ...snackSuccess,
            });
          } else {
            this.$buefy.snackbar.open({
              message: 'Something go wrong with scheduling',
              ...snackError,
            });
          }
        });
    },
    removeOneLead(event) {
      console.log('🚀 ~ file: TheInfluenceLeads.vue ~ line 371 ~ removeOneLead ~ event', event);

      // Permanently remove activity
      this.deleteActivity({
        campaignId: this.$route.params.campaignId,
        activityId: event.activityId,
        contactId: event.contactId,
        channelId: this.channelId,
      });

      // Allow contact to be selected again in panel add leads
      this.leads.splice(this.leads.findIndex((user) => user.username === event), 1);
    },
  },
};
</script>

<style lang="scss">
#UbuInfluenceLeadsDrawers {
  & > * {
    width: 50%;
    & > *:not(.modal) {
      height: calc(100vh - 95px);
    }
  }
}
#UbuInfluenceLeadsAddLeads {

  & .tabs.is-toggle.is-toggle-rounded li {

    & a {
      border-radius: 290486px;
      margin: 0 10px;
      font-style: normal;
      font-weight: 600;
      font-size: 0.85rem;
      line-height: 20px;
      border: none;
    }
    &.is-active a {
      color: var(--soapstone) !important;
      background-color: var(--campaign-color);
    }
    &:not(.is-active) a {
      border: none;
      color: var(--campaign-color);
    }
  }
  & > *:not(.modal) {
    & .card-header {
      background: var(--soapstone);
      & .buttons {
        padding: 16px;
      }
    }
    & .ubuContactList {
      max-height: 950px;
      overflow-y: auto;
    }
    & .ubuContactList > div {
      padding: 10px 16px;
    }
  }
  & .b-table {
    & .table-wrapper {
      @media (max-height: 800px) {
        max-height: 250px;
      }
      overflow-y: auto;
    }
  }
}
#UbuInfluenceLeadsImporterModal {
  border: 1px solid var(--mercury);
  & .modal-content {
    border-radius: 16px;
    width: 500px;
    background-color: var(--seashellpeach);
  }
  & .ubuCard {
    margin: 16px 24px;
    // height: 80px;
    & .card-content {
      max-height: 580px;
      // width: 500px;
      overflow-y: auto;
    }
  }
}
#UbuInfluenceLeadsDrawers {
  height: calc(100vh - 75px);
  max-height: calc(100vh - 75px);
  background-color: var(--seashellpeach);
  overflow-x: auto;
  // width: calc(100vw - 24px);
  // width: 100vw;
  padding: 8px 8px;
  justify-content: space-evenly;
  & .b-table {
    background: var(--soapstone);
    & .table {
    background: var(--soapstone);
  }
  }
  & #UbuInfluencePipelineTablesDrawer.isOpen {
    & .pipelineTable {
      & .pipelineTable_content {
        overflow-y: auto;
        max-height: 100%;
      }
    }
  }
}
</style>
