<template>
  <div class="UbuCommonMessageError_wrapper">
    <slot />
    <div class="UbuCommonMessageError_resend">
      <div class="UbuCommonMessageError_text ubu-subtext1">
        Error while sending.
      </div>
      <UbuIcon
        clickable
        class="UbuCommonMessageError_action a-self-center"
        name="assignation"
        color="2"
        @click="sendAgain()"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'UbuCommonMessageError',
  props: {
    text: {
      type: String,
      default: '',
    },
    item: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    sendAgain() {
      let eventToSend = {
        text: this.item.text,
        errorMessageId: this.item.messageId,
      };

      if (this.item.event === 'resendMedia') {
        eventToSend = {
          name: this.item.text,
          fd: this.item.fd,
          errorMessageId: this.item.messageId,
        };
      }

      this.$emit(this.item.event, eventToSend);
    },
  },
};
</script>

<style lang="scss">
.UbuCommonMessageError_wrapper {
  & .UbuCommonMessageError_resend {
    display: flex;
    justify-content: flex-end;
    margin-right: 16px;
  }

  & .UbuCommonMessageError_text {
    margin-right: 10px;
    color: var(--error);
  }
}
</style>
