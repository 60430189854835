var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.comment)?_c('li',{class:{
    'list-unstyle': true,
    'comment_child': _vm.nesting > 0,
    'comment_parent': _vm.nesting === 0,
    'isError': _vm.isError,
    'isPending': _vm.isPending,
  }},[_c('div',{staticClass:"conversation"},[_c('div',{class:'conversation__row' +
          [_vm.comment.isMe ? ' isMe' : ''] +
          [_vm.hover ? ' hover' : ''],on:{"mouseover":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false}}},[(_vm.isError)?_c('UbuIcon',{staticClass:"isError__btn",staticStyle:{"color":"var(--error)"},attrs:{"name":"refresh","clickable":""},on:{"click":function($event){return _vm.$emit('resend', _vm.comment)}}}):_vm._e(),_c('div',{class:'comment' +
            [!_vm.comment.isMe ? ' isNotMe' : '']},[_c('div',{class:'bulle' +
              [_vm.comment.isMe ? ' is-me' : ' is-not-me'] +
              [_vm.forward ? ' forward' : ''] +
              [_vm.isFocus ? ' focus' : ''],on:{"click":_vm.handleClick}},[_c('UbuCommonCommentBody',{attrs:{"comment-id":_vm.comment.commentId,"comment":_vm.comment.text,"mentions":_vm.comment.mentions}}),(_vm.comment.attachments && _vm.comment.attachments.length)?_c('div',{staticClass:"multibar mb-2"},[(_vm.comment.attachments[0].url)?_c('UbuButton',_vm._b({staticClass:"start",attrs:{"type":"button","secondary":""}},'UbuButton',{ href: _vm.comment.attachments[0].url, target: '_blank' },false),[_vm._v(" Click here to see the attachment ")]):_vm._e()],1):_vm._e(),_c('UbuCommonCommentFooter',{attrs:{"img-is-square":_vm.comment.isPost,"is-pending":_vm.isPending,"from":_vm.comment.from,"reactions":_vm.comment.reactions,"published-at":_vm.comment.createdAt,"reaction-count":_vm.comment.reactionCount,"options":_vm.options}})],1),(_vm.isConversation)?_c('button',{staticClass:"show",on:{"click":_vm.makeConversation}},[_vm._v(" "+_vm._s(_vm.open ? 'Hide' : _vm.showMore(_vm.comment))+" ")]):_vm._e()]),(!_vm.isError)?_c('UbuCommonCommentActions',{attrs:{"options":_vm.options},on:{"clickToReply":function($event){return _vm.$emit('clickToReply', $event)},"delete":function($event){return _vm.$emit('delete', _vm.comment)},"markAsRead":function($event){return _vm.$emit('markAsRead', $event)},"reportComment":function($event){return _vm.$emit('reportComment', $event)},"translateMessage":function($event){return _vm.$emit('translateMessage', $event)}}}):_vm._e()],1),(_vm.isConversation && _vm.open)?_c('ul',{class:'comment' +
          [_vm.open ? ' active': '']},_vm._l((_vm.childs),function(child,i){return _c('UbuCommonCommentRecursive',{key:i,staticClass:"item",attrs:{"comment":child,"nesting":_vm.nextNesting,"level-nesting":_vm.levelNesting,"show-all":_vm.showAll,"options":_vm.options,"is-error":_vm.isError,"is-pending":_vm.isPending},on:{"makeConversation":function($event){return _vm.$emit('makeConversation', $event)},"clickToReply":function($event){return _vm.$emit('clickToReply', $event)},"delete":function($event){return _vm.$emit('delete', $event)},"markAsRead":function($event){return _vm.$emit('markAsRead', $event)},"reportComment":function($event){return _vm.$emit('reportComment', $event)},"resend":function($event){return _vm.$emit('resend', $event)},"translateMessage":function($event){return _vm.$emit('translateMessage', $event)}}})}),1):_vm._e()])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }