<template>
  <div
    id="UbuInboxModalCreateThread"
    class="workspace-modal"
  >
    <div class="modal-header">
      <p class="modal-header-title ubu-title">New message</p>

      <UbuIcon
        name="cross"
        clickable
        @click="$emit('close', $event)"
      />
    </div>

    <template v-if="!selectedUser">
      <UbuSimpleInput
        v-model="localUsernameValue"
        placeholder="Search a user..."
        searchbar
      >
        <UbuIcon
          slot="placeholderIcon"
          name="search"
        />
      </UbuSimpleInput>
      <div class="usersSearchResult">
        <template v-if="isFetching && localUsernameValue.length">
          <UbuInstaUserCard
            v-for="index in 5"
            :key="index"
            loading
          />
        </template>

        <template v-if="noUserFounded">
          <span class="noUser-text">Oops, user not found... </span>
        </template>

        <template v-else>
          <UbuInstaUserCard
            v-for="(user, index) in userFetched"
            :key="index"
            :insta-user="user"
            :selected="selectedUser === user"
            :disabled="user.listAlreadyExist"
            disabled-text="This list already exist"
            @click="selectUser(user)"
          />
        </template>
      </div>
    </template>
    <template v-else>
      <div class="card-selectedUser-container">
        <UbuInstaUserCard
          class="card-selectedUser"
          :insta-user="selectedUser"
        />
        <b-button
          class="is-transparent"
          @click="selectedUser = null"
        >
          <UbuIcon name="cross" />
        </b-button>
      </div>
      <UbuTemplateTextarea
        v-model="templateText"
        class="mt-4"
        :show-controls="true"
        :template-list="templateList"
      />
    </template>
    <div class="modal-footer mt-4 buttons is-right">
      <b-button
        label="Cancel"
        class="is-light is-rounded"
        @click="$emit('close', $event)"
      />
      <b-button
        v-if="selectedUser"
        label="Send message"
        :disabled="!templateText.length"
        class="is-geraldine is-rounded"
        @click="$emit('createThread', { user: selectedUser, message: templateText})"
      />
    </div>
  </div>
</template>

<script>
import VModelMixin from '../../$mixins/VModelMixin';

export default {
  name: 'UbuInboxModalCreateThread',
  mixins: [VModelMixin],
  model: {
    prop: 'usernameSearch',
  },
  props: {
    userFetched: {
      type: Array,
      default: () => [],
    },
    usernameSearch: {
      type: String,
      default: '',
    },
    isFetching: {
      type: Boolean,
      default: false,
    },
    templateList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedUser: null,
      templateText: '',
    };
  },
  computed: {
    localUsernameValue: {
      get() { return this.usernameSearch; },
      set(value) { this.$emit('input', value); },
    },
    noUserFounded() {
      return !this.isFetching && this.localUsernameValue.length && !this.userFetched.length;
    },
  },
  methods: {
    selectUser(event) {
      this.selectedUser = event;
    },
  },
};
</script>
