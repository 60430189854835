<template>
  <section id="TheWorkspaceShopify">
    <div v-if="!getterFetchShopify">
      <p>We are fetching data, please wait a minute</p>
    </div>
    <template v-else>
      <div
        class="workspace-view-header"
      >
        <div class="worspace-header-start">
          <h1 class="ubu-header1">Shopify links</h1>

          <template v-if="getterShopifyShop.length">
            <b-field class="ml-4">
              <b-select
                v-model="shopifyId"
                placeholder="Select a store"
                size="is-small"
                rounded
              >
                <option
                  v-for="store in getterShopifyShop"
                  :key="store.shopifyShopSsid"
                  :value="store.shopifyShopSsid"
                >
                  {{ store.shopExtrefId || null }}
                </option>
              </b-select>
            </b-field>

            <UbuDatePicker
              v-model="dateRange"
              :is-analytics="true"
              :has-footer="true"
              :active="!!dateRange.length"
              @changeDate="changeRange(dateRange)"
              @resetDatePicker="dateRange = []"
            />
          </template>
        </div>
      </div>

      <template v-if="getterShopifyShop.length">
        <b-field>
          <b-input
            v-model="searchValue"
            placeholder="Search from link or influencer..."
            type="search"
            icon="magnify"
            rounded
          />
        </b-field>

        <div v-if="shopifyId">
          <b-table
            :data="affiliationListFiltered2"
            paginated
            per-page="20"
            default-sort="createdOn"
            default-sort-direction="desc"
            pagination-rounded
            pagination-size="is-small"
            :opened-detailed="openedDetails"
            :detailed="true"
            :show-detail-icon="true"
            detail-key="_id"
          >
            <b-table-column
              v-slot="{ row }"
              field="link"
              label="Link"
              sortable
            >
              <span class="text-geraldine">{{ row.link }}</span>
            </b-table-column>

            <b-table-column
              v-slot="{ row }"
              field="type"
              label="Type"
              sortable
            >
              {{ row.type }}
            </b-table-column>

            <b-table-column
              v-slot="{ row }"
              field="order"
              label="Revenues"
              sortable
            >
              {{ calculateTotalAmount(row.order) }} $
            </b-table-column>

            <b-table-column
              v-slot="{ row }"
              field="order"
              label="Orders"
              sortable
            >
              {{ row.order.length }}
            </b-table-column>

            <b-table-column
              v-slot="{ row }"
              field="contactId"
              label="Influencer"
              sortable
            >
              {{ row.contact ? row.contact.username : '-' }}
            </b-table-column>

            <b-table-column
              v-slot="{ row }"
              field="campaignId"
              label="Campaign"
              sortable
            >
              <b-tooltip
                v-if="row.campaign"
                :label="row.campaign.name"
              >
                <UbuIcon
                  name="influence"
                  filled
                  :style="`color: ${row.campaign.color}`"
                />
              </b-tooltip>
              <span v-else>-</span>
            </b-table-column>

            <b-table-column
              v-slot="{ row }"
              field="createdOn"
              label="Created on"
              sortable
            >
              {{ $moment(row.createdOn).format('YYYY-MM-DD') }}
            </b-table-column>

            <template #detail="{ row }">
              <b-dropdown
                max-height="250"
                scrollable
              >
                <template #trigger>
                  <b-button
                    label="Link to campaign"
                    class="is-light is-rounded"
                    :disabled="isLinkCampaignLoading"
                  />
                </template>

                <b-dropdown-item
                  v-for="campaign in activeCampaigns"
                  :key="campaign.campaignId"
                  @click="assignCampaignToLink({ campaignId: campaign.campaignId, code: row })"
                >
                  <p
                    :style="`color: ${campaign.color}`"
                    class="dropdown-campaign-text"
                  >
                    <span>
                      <UbuIcon
                        name="influence"
                        filled
                      /> {{ campaign.name }}
                    </span>
                    <UbuIcon
                      v-if="campaign.campaignId === row.campaignId"
                      name="checkmark"
                    />
                  </p>
                </b-dropdown-item>
              </b-dropdown>

              <b-button
                class="is-geraldine is-rounded ml-4"
                :disabled="!row.contactId"
                @click="confirmationToRemoveContactToLink(row)"
              >
                <UbuIcon name="trash" /> Remove influencer
              </b-button>
            </template>
          </b-table>

          <div class="dashboard-box pt-4">
            <h1 class="ubu-header1">Create utm parameters to link</h1>

            <b-field
              class="mt-4"
              label="Utm source"
              expanded
            >
              <b-input
                v-model="newUtm.source"
                placeholder="Utm source..."
                expanded
                rounded
              />
            </b-field>

            <b-field
              label="Utm medium"
              expanded
            >
              <b-input
                v-model="newUtm.medium"
                placeholder="Utm medium..."
                expanded
                rounded
              />
            </b-field>

            <b-field
              label="Utm campaign"
              expanded
            >
              <b-input
                v-model="newUtm.campaign"
                placeholder="Utm campaign..."
                expanded
                rounded
              />
            </b-field>
            <div class="buttons">
              <b-button
                class="is-geraldine is-rounded"
                label="Save"
                :disabled="!canSendNewUtm"
                @click="createNewUtm(newUtm)"
              />
            </div>
          </div>

          <div
            v-if="revenuesByLink.length || ordersByLink.length"
            class="dashboard-box pt-4"
          >
            <h1 class="ubu-header1">Details by link</h1>
            <div class="dashboard-row">
              <UbuCommonAnalyticsCell
                v-if="revenuesByLink.length"
                :grouped="revenuesByLink"
                :is-extended="true"
              />
              <UbuCommonAnalyticsCell
                v-if="ordersByLink.length"
                :grouped="ordersByLink"
                :is-extended="true"
              />
            </div>
          </div>
        </div>
      </template>

      <div v-else>
        <p>Please connect your Shopify account</p>
        <div class="buttons pt-4">
          <b-button
            label="Connect to Shopify"
            class="is-light is-rounded"
            tag="router-link"
            to="/workspace/connection"
          />
        </div>
      </div>
    </template>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { snackSuccess } from '../$catalog/snackbar';

export default {
  name: 'TheWorkspaceShopify',
  data() {
    return {
      shopifyId: null,
      openedDetails: [],
      isLinkCampaignLoading: false,
      searchValue: '',
      dateRange: [],
      newUtm: {
        source: null,
        medium: null,
        campaign: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      fetchIsDone: 'Sequence/getterFetchShopify',
      getterFetchShopify: 'Sequence/getterFetchShopify',
      getterShopifyShop: 'TheContactPanel/getterShopifyShop',
      getterCampaigns: 'TheContactPanel/getterCampaigns',
    }),
    canSendNewUtm() {
      const { source, medium, campaign } = this.newUtm;
      return !!source || !!medium || !!campaign;
    },
    affiliationList() {
      return this.currentShop.discountCode.concat(this.currentShop.referralLink)
        .sort((a, b) => this.$moment(b.createdOn) - this.$moment(a.createdOn));
    },
    activeCampaigns() {
      return this.getterCampaigns.filter(({ ended }) => !ended);
    },
    currentShop() {
      return this.getterShopifyShop
        .find(({ shopifyShopSsid }) => shopifyShopSsid === this.shopifyId);
    },
    affiliationListFiltered() {
      return this.searchValue
        ? this.affiliationList.filter(({ link, contact }) => {
          if (contact && contact.username) {
            return contact.username.includes(this.searchValue) || link.includes(this.searchValue);
          }
          return link.includes(this.searchValue);
        })
        : this.affiliationList;
    },
    affiliationListFiltered2() {
      return this.affiliationListFiltered.filter(({ createdOn }) => {
        if (this.dateRange.length) {
          const [min, max] = this.dateRange;
          return this.$moment(createdOn).format('YYYY-MM-DD') >= this.$moment(min).format('YYYY-MM-DD')
           && this.$moment(createdOn).format('YYYY-MM-DD') <= this.$moment(max).format('YYYY-MM-DD');
        }
        return true;
      });
    },
    ordersByLink() {
      return this.affiliationList.reduce((acc, { link, order }) => {
        if (order.length) {
          if (this.dateRange.length) {
            const [min, max] = this.dateRange;
            const orderBetweenRange = order.filter(({ createdOn }) => this.$moment(createdOn).format() >= this.$moment(min).format('YYYY-MM-DD') && this.$moment(createdOn).format() <= this.$moment(max).format('YYYY-MM-DD'));
            if (orderBetweenRange.length) acc.push({ name: link, value: orderBetweenRange.length });
          } else acc.push({ name: link, value: order.length });
        }
        return acc;
      }, []);
    },
    revenuesByLink() {
      return this.affiliationList.reduce((acc, { link, order }) => {
        if (order.length) {
          if (this.dateRange.length) {
            const [min, max] = this.dateRange;
            const orderBetweenRange = order.filter(({ createdOn }) => this.$moment(createdOn).format() >= this.$moment(min).format('YYYY-MM-DD') && this.$moment(createdOn).format() <= this.$moment(max).format('YYYY-MM-DD'));
            if (orderBetweenRange.length) {
              acc.push({
                name: link,
                value: orderBetweenRange
                  .reduce((ac, { amountUsd }) => Math.round(ac + (amountUsd / 100)), 0),
              });
            }
          } else {
            acc.push({
              name: link,
              value: order.reduce((ac, { amountUsd }) => Math.round(ac + (amountUsd / 100)), 0),
            });
          }
        }
        return acc;
      }, []);
    },
  },
  watch: {
    fetchIsDone() {
      if (this.getterShopifyShop && this.getterShopifyShop.length) {
        this.selectFirstShop();
      }
    },
  },
  mounted() {
    if (this.getterShopifyShop && this.getterShopifyShop.length) {
      this.selectFirstShop();
    }
  },
  methods: {
    ...mapActions({
      createUtm: 'TheWorkspace/createUtm',
      assignDiscountCodeToCampaign: 'Shopify/DiscountCode/assignDiscountCodeToCampaign',
      removeDiscountCodeToContact: 'Shopify/DiscountCode/removeDiscountCodeToContact',
      assignReferralLinkToCampaign: 'Shopify/ReferralLink/assignReferralLinkToCampaign',
      removeReferralLinkToContact: 'Shopify/ReferralLink/removeReferralLinkToContact',
    }),
    selectFirstShop() {
      this.shopifyId = this.getterShopifyShop.length
        ? this.getterShopifyShop[0].shopifyShopSsid : null;
    },
    calculateTotalAmount(orderList) {
      if (!orderList.length) return 0;
      return orderList.reduce((acc, row) => {
        const { amountUsd } = row;
        // eslint-disable-next-line
        return acc += Math.round((amountUsd / 100));
      }, 0);
    },
    assignCampaignToLink(event) {
      const { campaignId: newCampaignId, code: { shopifyShopSsid, type, ...rest } } = event;

      // if campaignId emited already assigned to this link, we remove relation
      const campaignIsAlreadyAssignToLink = rest.campaignId === newCampaignId;
      const campaignIdToReturn = campaignIsAlreadyAssignToLink ? null : newCampaignId;

      if (type === 'discount') {
        this.isLinkCampaignLoading = true;
        this.assignDiscountCodeToCampaign({
          discountCode: rest,
          campaignId: campaignIdToReturn,
          shopifyShopSsid,
        })
          .then(() => { this.isLinkCampaignLoading = false; });
      }
      if (type === 'referral') {
        this.isLinkCampaignLoading = true;
        this.assignReferralLinkToCampaign({
          referralLink: rest,
          campaignId: campaignIdToReturn,
          shopifyShopSsid,
        })
          .then(() => { this.isLinkCampaignLoading = false; });
      }
    },
    confirmationToRemoveContactToLink(event) {
      this.$buefy.dialog.confirm({
        title: 'Deleting affiliation',
        message: 'Are you sure you want to <b>delete</b> this affiliation? This action cannot be undone.',
        confirmText: 'Delete affiliation',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.removeContactToLink(event),
      });
    },
    removeContactToLink(event) {
      const {
        type, shopifyShopSsid, ...rest
      } = event;

      if (type === 'discount') {
        this.removeDiscountCodeToContact({ discountCode: rest, shopifyShopSsid });
      }
      if (type === 'referral') {
        this.removeReferralLinkToContact({ referralLink: rest, shopifyShopSsid });
      }
    },
    changeRange(range) {
      if (range[1] === range[0]) {
        // eslint-disable-next-line no-param-reassign
        range[0] = this.$moment(range[1]).subtract({ days: 1 }).toDate();
      }
    },
    createNewUtm(newUtm) {
      const { source, medium, campaign } = newUtm;
      const newUtms = [];

      if (source) newUtms.push({ type: 'source', value: source, shopifyShopSsid: this.shopifyId });
      if (medium) newUtms.push({ type: 'medium', value: medium, shopifyShopSsid: this.shopifyId });
      if (campaign) newUtms.push({ type: 'campaign', value: campaign, shopifyShopSsid: this.shopifyId });

      if (newUtms.length) {
        this.createUtm({ shopifyShopSsid: this.shopifyId, newUtms })
          .then(() => {
            this.$buefy.snackbar.open({
              message: 'Utm parameters was registered.',
              ...snackSuccess,
            });

            this.newUtm = {
              source: null,
              medium: null,
              campaign: null,
            };
          });
      }
    },
  },
};
</script>

<style lang="scss">
#TheWorkspaceShopify {
  & .workspace-view-header {
    & .worspace-header-start {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
  & .table {
    background-color: transparent;
    color: var(--cloudburst);
    & th, & td {
      vertical-align: middle;
      white-space: nowrap;
      height: 52px;
    }
    & tr.detail {
      box-shadow: none;
      background-color: var(--soapstone);
    }
    & .icon {
      color: var(--cloudburst);
    }
    & .dropdown-campaign-text {
      display: flex;
      justify-content: space-between;
    }
    & .th-wrap {
      color: var(--darkgrey);
    }
  }
  & .dropdown-content {
    background-color: var(--peachcream);
  }
}
</style>
