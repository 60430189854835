import builder from '../../$utils/axios';

export default class InfluenceProvider {
  static fetchMentions({ channelId, payload }) {
    return builder({
      method: 'post',
      url: `/channel/${channelId}/campaign_files`,
      data: payload,
    });
  }

  static createCampaign(payload) {
    return builder({
      method: 'post',
      url: `/channel/${payload.channel}/campaign`,
      data: {
        campaign: payload,
      },
    });
  }

  static updateCampaign(payload) {
    return builder({
      method: 'post',
      url: `/channel/${payload.channel}/campaign`,
      data: {
        campaign: payload,
      },
    });
  }

  static endCampaign(payload) {
    return builder({
      method: 'post',
      url: `/channel/${payload.channel}/campaign`,
      data: {
        campaign: payload,
      },
    });
  }

  static setActivity(payload) {
    return builder({
      method: 'post',
      url: `/channel/${payload.channel}/activity`,
      data: {
        activity: { ...payload, campaign: payload.campaignId },
      },
    });
  }

  static setActivities({ channelId, activities }) {
    return builder({
      method: 'post',
      url: `/channel/${channelId}/activities`,
      data: {
        activities,
      },
    });
  }

  static sendMessageByHook(payload) {
    const {
      campaignId, channelId, message, activityIds,
    } = payload;

    return builder({
      method: 'post',
      url: `/channel/${channelId}/sendHooks/${campaignId}`,
      data: { message, activityIds },
    });
  }

  static deleteActivity(payload) {
    const { activityId, channelId } = payload;

    return builder({
      method: 'get',
      url: `/channel/${channelId}/deleteActivity/${activityId}`,
    });
  }

  static getContactByUsername({
    payload: {
      channelId,
      username,
    },
  }) {
    return builder({
      method: 'get',
      url: `/channel/${channelId}/instaExactUserName/${username}`,
    });
  }

  static getTikTokMediasOrdersByCampaign({ channelId, campaignId }) {
    return builder({
      method: 'get',
      url: `/channel/${channelId}/ttMedias_list_orders/${campaignId}`,
    });
  }

  static addTikTokMediasOrder({ channelId, payload }) {
    return builder({
      method: 'post',
      url: `/channel/${channelId}/ttMedias_list_order`,
      data: payload,
    });
  }
}
