<template>
  <div id="TheThreadActionButton">
    <!-- <UbuButton
      v-click-outside="e => dropdownOpened = false"
      class="a-self-center"
      :icon="{ name: 'more' }"
      :filled="false"
      clickable
      @click="dropdownOpened = !dropdownOpened"
    />
    <div
      v-if="dropdownOpened"
      class="UbuThreadHeader_dropdown"
    >
      <div
        v-if="thread.isUnread"
        class="UbuTHCustomerHeader_item cursor-pointer ellipsis"
        @click="setThreadRead($event)"
      >
        Read
      </div>
      <div
        v-if="!thread.closed"
        class="UbuTHCustomerHeader_item cursor-pointer ellipsis"
        @click="setThreadClosed(thread)"
      >
        Close
      </div>
      <div
        v-if="thread.closed"
        class="UbuTHCustomerHeader_item cursor-pointer ellipsis"
        @click="setThreadOpen(thread)"
      >
        Open
      </div>
      <div
        v-if="(!!thread.ubu_muted) === true"
        class="UbuTHCustomerHeader_item cursor-pointer ellipsis"
        @click="setThreadUnmute(thread)"
      >
        Unmute
      </div>
      <div
        v-if="(!!thread.ubu_muted) === false"
        class="UbuTHCustomerHeader_item cursor-pointer ellipsis"
        @click="setThreadMute(thread)"
      >
        Mute
      </div>
      <div
        class="UbuTHCustomerHeader_item cursor-pointer ellipsis"
        @click="$emit('translateThread', $event)"
      >
        Translate thread
      </div>
    </div> -->
    <b-dropdown
      aria-role="list"
      position="is-bottom-left"
    >
      <template #trigger>
        <UbuButton
          class="a-self-center"
          :icon="{ name: 'more' }"
          :filled="false"
        />
      </template>

      <b-dropdown-item
        v-if="thread.isUnread"
        class="UbuTHCustomerHeader_item cursor-pointer ellipsis"
        @click="setThreadRead($event)"
      >
        Read
      </b-dropdown-item>
      <b-dropdown-item
        v-if="!thread.closed"
        class="UbuTHCustomerHeader_item cursor-pointer ellipsis"
        @click="setThreadClosed(thread)"
      >
        Close
      </b-dropdown-item>
      <b-dropdown-item
        v-if="thread.closed"
        class="UbuTHCustomerHeader_item cursor-pointer ellipsis"
        @click="setThreadOpen(thread)"
      >
        Open
      </b-dropdown-item>
      <b-dropdown-item
        v-if="(!!thread.ubu_muted) === false"
        class="UbuTHCustomerHeader_item cursor-pointer ellipsis"
        @click="setThreadMute(thread)"
      >
        Mute
      </b-dropdown-item>
      <b-dropdown-item
        v-if="(!!thread.ubu_muted) === true"
        class="UbuTHCustomerHeader_item cursor-pointer ellipsis"
        @click="setThreadUnmute(thread)"
      >
        Unmute
      </b-dropdown-item>

      <b-dropdown-item class="translate-action">
        <span>
          <UbuIcon
            name="translate"
          /> Translate to: </span>

        <!-- <b-tooltip label="Translate">
          <UbuIcon name="info" />
        </b-tooltip> -->
      </b-dropdown-item>

      <b-dropdown-item
        v-for="lang in languageList"
        :key="lang.value"
        :value="lang.value"
        :focusable="false"
        @click="selectLanguage(lang.value)"
      >
        <div class="language-content">
          <b-image
            class="language-flag"
            :src="lang.icon"
          />
          {{ lang.label }}
        </div>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'TheThreadActionButton',
  props: {
    thread: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    dropdownOpened: false,
    languageList: [
      { label: 'English', value: 'en', icon: '/img/UK.svg' },
      { label: 'French', value: 'fr', icon: '/img/France.svg' },
      { label: 'Spanish', value: 'es', icon: '/img/Spain.svg' },
      { label: 'German', value: 'de', icon: '/img/Germany.svg' },
    ],
  }),
  methods: {
    ...mapActions({
      approvePending: 'TheThreadActionButton/approvePending',
      declinePending: 'TheThreadActionButton/declinePending',
      closeThread: 'TheThreadActionButton/closeThread',
      openThread: 'TheThreadActionButton/openThread',
      readThread: 'TheThreadActionButton/readThread',
      unmuteThread: 'TheThreadActionButton/unmuteThread',
      muteThread: 'TheThreadActionButton/muteThread',
    }),
    setThreadRead() {
      this.readThread(this.thread);
    },
    setThreadClosed(selectedThread) {
      return Promise.resolve()
        .then(() => this.closeThread(selectedThread))
        .then(({ response }) => {
          if (response) {
            this.$buefy.snackbar.open({
              message: 'Conversation was closed',
              type: 'is-success',
              position: 'is-bottom-right',
              queue: false,
            });
          } else {
            this.$buefy.snackbar.open({
              message: 'Error while closing conversation',
              type: 'is-warning',
              position: 'is-top',
              queue: false,
              actionText: 'Retry',
              onAction: () => this.setThreadClosed(selectedThread),
            });
          }
        });
    },
    setThreadOpen(selectedThread) {
      return Promise.resolve()
        .then(() => this.openThread(selectedThread))
        .then(({ response }) => {
          if (response) {
            this.$buefy.snackbar.open({
              message: 'Conversation was opened',
              type: 'is-success',
              position: 'is-bottom-right',
              queue: false,
            });
          } else {
            this.$buefy.snackbar.open({
              message: 'Error while opening conversation',
              type: 'is-warning',
              position: 'is-top',
              queue: false,
              actionText: 'Retry',
              onAction: () => this.setThreadOpen(selectedThread),
            });
          }
        });
    },
    setThreadUnmute(thread) {
      this.unmuteThread(thread);
    },
    setThreadMute(thread) {
      this.muteThread(thread);
    },
    selectLanguage(lang) {
      this.$emit('translateThread', { target: lang });
    },
  },
};
</script>

<style lang="scss">
#TheThreadActionButton {
  display: inline-flex;
  position: relative;

  & .UbuThreadHeader_dropdown {
    z-index: 100;
    position: absolute;
    top: calc(100% + 6px);
    right: 0;
    background-color: var(--soapstone);
    box-shadow: 0px 0px 10px var(--box-shadow);
    border-radius: 12px;
    padding: 4px 12px;
    width: max-content;
    max-width: 200px;
  }

  .UbuTHCustomerHeader_item {
    font-size: 0.85rem;
    font-weight: 600;
    margin: 8px 0;
  }

  & .UbuThreadHeader_divider {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    border-top: 1px solid var(--mercury);
  }
  & .dropdown-item, .dropdown .dropdown-menu .has-link a {
    color: var(--darkgrey);
    // padding: 0.375rem 0.3rem;
  }
  & .dropdown-content {
    border-radius: 8px;
  }
  & a.dropdown-item, .dropdown .dropdown-menu .has-link a, button.dropdown-item {
    padding-right: 0.5rem;
  }
  & a.dropdown-item.is-active, .dropdown .dropdown-menu .has-link a.is-active, button.dropdown-item.is-active {
    background-color: var(--givry);
    // color: #fff;
  }
  & a.dropdown-item:hover, .dropdown .dropdown-menu .has-link a:hover, button.dropdown-item:hover {
    background-color: var(--peachcream);
  }
}
</style>
