import builder from '../../$utils/axios';

export default class LeadsProvider {
  static searchUser({ channelId, username }) {
    console.log('🚀 ~ file: leads.provider.js ~ line 5 ~ LeadsProvider ~ searchUser ~ username', username);
    return builder({
      method: 'get',
      url: `/channel/${channelId}/instaUserName/${username}`,
    });
  }

  static createList(payload) {
    return builder({
      method: 'post',
      url: `/channel/${payload.channel}/influence_list_order`,
      data: payload,
    });
  }
}
