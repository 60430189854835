<template>
  <div
    id="UbuInfluenceLeadsAddLeadsTags"
    class="card ubuCard"
  >
    <div class="card-header multibar column">
      <p class="ubu-card-title">
        Add from your contacts.
      </p>
      <b-field>
        <b-input
          v-model="username"
          placeholder="Search for a contact"
          icon="magnify"
          class="ubuInput"
        />
      </b-field>
      <div class="ubuButtonDrawer">
        <b-button
          v-for="(custom, index) in threadGroups"
          :key="index"
          :class="`is-rounded is-light bg-color-${custom.color}` "
          @click="selectGroup(custom.title)"
        >
          <UbuIcon
            size="16px"
            name="tag"
            filled
            :color="custom.color"
          />
          <span>
            {{ custom.title }}
          </span>
        </b-button>
      </div>
    </div>
    <div class="multibar m-2">
      <b-button
        class="ubuInfluenceButton is-rounded end"
        :disabled="checkedContacts.length === 0"
        @click="$emit('addContacts', checkedContacts); checkedContacts = [];"
      >
        Add contacts {{ checkedContacts.length ? `(${checkedContacts.length})` : '' }}
      </b-button>
    </div>
    <div class="card-content ubuContactList">
      <b-table
        :data="filteredContactList"
        class="table-no-head"
        :paginated="true"
        pagination-rounded
        pagination-size="is-small"
        :per-page="7"
        checkable
        :checked-rows.sync="checkedContacts"
        :is-row-checkable="(row) =>
          !isAlreadySelected(row.username) && !isAlreadyInCampaign(row.username)"
      >
        <b-table-column field="username">
          <template v-slot="{row: contact}">
            <UbuContactCard
              :contact="contact"
              class=""
            />
          </template>
        </b-table-column>
        <b-table-column field="details">
          <template v-slot="{row: contact}">
            <div class="multibar">
              <b-taglist
                attached
                class="end"
              >
                <b-tooltip
                  v-if="contact.engagement"
                  label="Engagement"
                  position="is-right"
                >
                  <b-tag type="is-light">{{ contact.engagement }} %</b-tag>
                </b-tooltip>
                <b-tooltip
                  label="Follower count"
                  position="is-right"
                >
                  <b-tag type="is-success">{{ contact.follower_count }}</b-tag>
                </b-tooltip>
              </b-taglist>
            </div>
          </template>
        </b-table-column>
        <b-table-column
          field="actions"
          numeric
        >
          <template v-slot="{row: contact}">
            <b-button
              v-if="!isAlreadySelected(contact.username) && !isAlreadyInCampaign(contact.username)"
              class="end ubuInfluenceButton is-rounded"
              @click="$emit('addContacts', [contact])"
            >
              <UbuIcon
                size="16px"
                name="add"
                filled
              />
              <span>
                Add
              </span>
            </b-button>
            <b-button
              v-if="isAlreadyInCampaign(contact.username)"
              class="end is-rounded is-success ubuInfluenceButton"
              disabled
            >
              <UbuIcon
                size="16px"
                name="success"
                filled
              />
              <span>
                Added
              </span>
            </b-button>
          </template>
        </b-table-column>
      </b-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UbuInfluenceLeadsAddLeadsTags',
  props: {
    contactList: {
      type: Array,
      required: true,
    },
    leads: {
      type: Array,
      required: true,
    },
    allCampaignContact: {
      type: Array,
      required: true,
    },
    threadGroups: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      username: '',
      selectedGroup: '',
      checkedContacts: [],
    };
  },
  computed: {
    filteredContactList() {
      return this.username.length
        ? this.threadGroupfilteredContactList
          .filter(({ username, full_name: fn }) => username.toLowerCase().includes(this.username)
          || (fn && fn.toLowerCase().includes(this.username)))
        : this.threadGroupfilteredContactList;
    },
    threadGroupfilteredContactList() {
      return this.selectedGroup.length
        ? this.onlyGroupedThreads
          .filter(({ threadGroupList }) => {
            const threadGroupMap = threadGroupList.map((title) => title);
            return threadGroupMap.includes(this.selectedGroup);
          })
        : this.onlyGroupedThreads;
    },
    onlyGroupedThreads() {
      return this.contactList
        .filter(({ threadGroupList }) => threadGroupList.length);
    },
    allCampaignContactList() {
      return this.allCampaignContact.map(({ username }) => username);
    },
  },
  methods: {
    isAlreadySelected(username) {
      return this.leads.length
        ? [...this.leads].map((lead) => lead.username).includes(username)
        : false;
    },
    isAlreadyInCampaign(username) {
      return this.allCampaignContactList.includes(username);
    },
    selectGroup(title) {
      this.selectedGroup = title;
    },
  },
};
</script>

<style lang="scss">
  .ubuInput {
    margin: 0 8px;
  }
</style>
