<template>
  <div class="card ubuCard">
    <div :class="['card-header mutibar column', { 'expandIsOpen': !showSuccess }]">
      <p class="ubu-card-title multibar">
        <span class="start">
          {{ list.length }} {{ title }}
        </span>
        <b-button
          class="end is-transparent"
          :loading="!isResearchEnded"
          @click="showSuccess = !showSuccess"
        >
          <UbuIcon
            v-if="showSuccess"
            size="16px"
            name="expand"
            outlined
            class="expandIsOpen"
          />
          <UbuIcon
            v-else
            size="16px"
            name="expand"
            outlined
          />
        </b-button>
      </p>
      <p
        v-if="title === 'Not found' && showSuccess"
        class="card-header-subtitle multibar"
      >
        <span>
          You can try to add them again after closing this window
        </span>
      </p>
    </div>
    <div
      v-if="showSuccess"
      class="card-content"
    >
      <p
        v-for="(contact, index) in list"
        :key="index"
        class="no-margin"
      >
        {{ contact.username }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UbuInfluenceLeadsImporterModalList',
  props: {
    list: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    isResearchEnded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showSuccess: false,
    };
  },
};
</script>

<style lang="scss">
.card-header.expandIsOpen {
  border-radius: 12px;
}
.ubu-card-title {
  & .expandIsOpen {
    transform: rotateX(180deg);
  }
}
</style>
