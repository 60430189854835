import { mapActions } from 'vuex';
import { snackSuccess, snackError } from '../../$catalog/snackbar';
import { convertColor } from '../../$utils/formate';

export default {
  data() {
    return {
      isModalEditUsernameTiktok: false,
      currentEditTiktokContactId: null,
      isStatisticsLoading: false,
    };
  },
  computed: {
    campaignList() {
      return this.campaignActivitiesDetails.campaigns.map((campaign) => {
        const {
          name: title, color, campaignId, campaignHasUnread, ended, createdOn,
        } = campaign;
        return ({
          title, color: convertColor(color), campaignId, campaignHasUnread, ended, createdOn,
        });
      });
    },
  },
  methods: {
    ...mapActions({
      _assignContactsToContactGroup: 'TheThreadList/assignContactsToContactGroup',
      _createContactGroupAndAssignMany: 'TheThreadList/createContactGroupAndAssignMany',
      _fetchTiktokUser: 'Contact/fetchTiktokUser',
      _updateTiktokUsername: 'Contact/updateTiktokUsername',
    }),
    addCustom(event) {
      // console.log('🚀 ~ file: TheCRM.vue ~ line 375 ~ addCustom ~ event', event);
      if (event.type === 'contactGroup') {
        return this.assignContactToContactGroup({
          contactGroup: event.custom,
          thread: { contactId: event.row.contactId, channelId: event.row.channelId },
          username: event.row.username,
        });
      }
      if (event.type === 'threadGroup') {
        return this.assignThreadToThreadGroup({
          threadGroup: event.custom,
          thread: { threadId: event.row.threadId, channelId: event.row.channelId },
          username: event.row.username,
        });
      }
      if (event.type === 'campaign') {
        const data = {
          channelId: event.row.channelId,
          campaignId: event.custom.campaignId,
          activities: [{
            pk: event.row.contactId,
            username: event.row.username,
            status: 'unscheduled',
            channel: this.channelId,
            campaignId: event.custom.campaignId,
            campaign: event.custom.campaignId,
          }],
        };
        return this.setUnscheduled(data)
          .then(({ error }) => {
            if (error) {
              this.$buefy.snackbar.open({
                ...snackError,
                message: `Error while assigning "${event.row.username}" to campaign`,
                onAction: () => this.setUnscheduled(data),
              });
            } else {
              this.$buefy.snackbar.open({
                ...snackSuccess,
                message: `"${event.row.username}" has been assigned to campaign`,
              });
            }
          });
      }
      return undefined;
    },
    removeCustom(event) {
      // console.log('🚀 ~ file: TheCRM.vue ~ line 379 ~ removeCustom ~ event', event);
      if (event.type === 'contactGroup') {
        return this.removeContactFromContactGroup({
          contactGroup: event.custom,
          thread: { contactId: event.row.contactId, channelId: event.row.channelId },
          username: event.row.username,
        });
      }
      if (event.type === 'threadGroup') {
        return this.removeThreadFromThreadGroup({
          threadGroup: event.custom,
          thread: { threadId: event.row.threadId, channelId: event.row.channelId },
          username: event.row.username,
        });
      }
      if (event.type === 'campaign') {
        const activity = {
          activityId: event.custom.activityId,
          channelId: event.row.channelId,
          contactId: event.row.contactId,
          campaignId: event.custom.campaignId,
        };
        return this.deleteActivity(activity)
          .then(({ error }) => {
            if (error) {
              this.$buefy.snackbar.open({
                ...snackError,
                message: `Error while removing "${event.row.username}" from campaign`,
                onAction: () => this.deleteActivity(activity),
              });
            } else {
              this.$buefy.snackbar.open({
                ...snackSuccess,
                message: `"${event.row.username}" has been removed from campaign`,
              });
            }
          });
      }
      return undefined;
    },
    assignContactToContactGroup($event) {
      // console.log('🚀 ~ file: TheCRM.vue ~ line 581 ~ assignContactToContactGroup ~ $event', $event);
      this._assignContactToContactGroup($event)
        .then(({ title, error }) => {
          if (error) {
            this.$buefy.snackbar.open({
              ...snackError,
              message: `Error while assigning "${$event.username}" to contact folder "${title}"`,
              onAction: () => this.assignContactToContactGroup($event),
            });
          } else {
            this.$buefy.snackbar.open({
              ...snackSuccess,
              message: `"${$event.username}" has been assigned to contact folder "
              ${title}"`,
            });
          }
        });
    },
    removeContactFromContactGroup($event) {
      this._removeContactFromContactGroup($event)
        .then(({ title, error }) => {
          if (error) {
            this.$buefy.snackbar.open({
              ...snackError,
              message: `Error while removing "${$event.username}" from contact folder "${title}"`,
              onAction: () => this.removeThreadFromThreadGroup($event),
            });
          } else {
            this.$buefy.snackbar.open({
              ...snackSuccess,
              message: `"${$event.username}" has been removed from contact folder "
              ${title}"`,
            });
          }
        });
    },
    assignThreadToThreadGroup($event) {
      this._assignThreadToThreadGroup($event)
        .then(({ title, error }) => {
          if (error) {
            this.$buefy.snackbar.open({
              ...snackError,
              message: `Error while assigning "${$event.username}" to tag "${title}"`,
              onAction: () => this.assignThreadToThreadGroup($event),
            });
          } else {
            this.$buefy.snackbar.open({
              ...snackSuccess,
              message: `"${$event.username}" has been assigned to tag "${title}"`,
            });
          }
        });
    },
    removeThreadFromThreadGroup($event) {
      this._removeThreadFromThreadGroup($event)
        .then(({ title, error }) => {
          if (error) {
            this.$buefy.snackbar.open({
              ...snackError,
              message: `Error while removing "${$event.username}" from tag "${title}"`,
              onAction: () => this.removeThreadFromThreadGroup($event),
            });
          } else {
            this.$buefy.snackbar.open({
              ...snackSuccess,
              message: `"${$event.username}" has been removed from tag "${title}"`,
            });
          }
        });
    },
    createThreadGroupAndAssign($event) {
      this._createThreadGroupAndAssign($event)
        .then(({ title, error }) => {
          if (error) {
            this.$buefy.snackbar.open({
              ...snackError,
              message: `Error while creating tag "${title}",
              "${$event.username}" is not assigned`,
              onAction: () => this.createThreadGroupAndAssign($event),
            });
          } else {
            this.$buefy.snackbar.open({
              ...snackSuccess,
              message: `"${$event.username}" has been assigned to newly created tag "${title}"`,
            });
          }
        });
    },
    createContactGroupAndAssign($event) {
      this._createContactGroupAndAssign($event)
        .then(({ title, error }) => {
          if (error) {
            this.$buefy.snackbar.open({
              ...snackError,
              message: `Error while creating contact folder "${title}",
              "${$event.username}" is not assigned`,
              onAction: () => this.createThreadGroupAndAssign($event),
            });
          } else {
            this.$buefy.snackbar.open({
              ...snackSuccess,
              message: `"${$event.username}" has been assigned to
              newly created contact folder "${title}"`,
            });
          }
        });
    },
    generateReport(event) {
      // to report, contact must have more than 2.5k followers & !private account
      if (event.followerCount > 2500 && !event.is_private) {
        this.isGenerateReportLoading = event.username;
        this._generateReport(event)
          .then(({ response, error }) => {
            if (error) {
              this.$buefy.snackbar.open({
                ...snackError,
                message: `Error while loading ${event.username} report`,
                onAction: () => this.generateReport(event),
              });
              this.isGenerateReportLoading = null;
              return;
            }
            this.$buefy.snackbar.open({
              ...snackSuccess,
              type: 'is-link',
              indefinite: true,
              message: `Click to open ${event.username} report`,
              onAction: () => window
                .open(response.public_url, `${event.username} generated report`),
            });
            this.isGenerateReportLoading = null;
          });
      } else {
        this.$buefy.snackbar.open({
          ...snackError,
          actionText: null,
          message: 'Error contact must have more than 1500 followers & not be private account',
        });
      }
    },
    addContactsIntoCampaign(event) {
      const { campaignId } = event;
      this.contactAddedToCampaign = 0;
      this.contactAlreadyInCampaign = 0;

      const activities = this.checkedRows.reduce((acc, row) => {
        const contactAlreadyInThisCampaign = row.activities
          .find((a) => a.campaignId === campaignId);

        if (contactAlreadyInThisCampaign) { this.contactAlreadyInCampaign += 1; return acc; }
        this.contactAddedToCampaign += 1;
        acc.push({
          pk: row.userPk,
          username: row.username,
          status: 'unscheduled',
          channel: this.currentChannelId,
          campaignId,
          campaign: campaignId,
        });
        return acc;
      }, []);

      // Create activities
      this.setUnscheduled({
        campaignId,
        channelId: this.currentChannelId,
        activities,
      })
        .then(() => {
          this.checkedRows = [];
          this.isEndedModalActive = true;
        });
    },
    openNewMessage(event) {
      this.userNewMessage = event;
      this.isMessageModalActive = true;
    },
    sendNewMessage(event) {
      this.isMessageModalActive = false;
      const { user: { pk, username }, message } = event;
      const messageParsed = message.replace('{name}', username);

      const snackToWait = this.$buefy.snackbar.open({
        ...snackSuccess,
        message: 'Sending message...',
      });

      this.sendMessage({
        channelId: this.currentChannelId,
        contactId: pk,
        message: messageParsed,
      })
        .then(({ error }) => {
          snackToWait.close();
          if (error) {
            this.$buefy.snackbar.open({
              ...snackError,
              message: 'Error while sending',
              actionText: 'Retry',
              onAction: () => this.sendNewMessage(event),
            });
          } else {
            this.$buefy.snackbar.open({
              ...snackSuccess,
              message: 'Message was sent successfully',
            });
            this.usernameSearch = '';
          }
        });
    },
    assignContactsToContactGroup($event) {
      this._assignContactsToContactGroup($event)
        .then(({ error, count, title }) => {
          this.checkedRows = [];
          if (error) {
            this.$buefy.snackbar.open({
              ...snackError,
              message: `Error while assigning "${count}" contacts to folder "${title}"`,
              onAction: () => this.assignContactsToContactGroup($event),
            });
          } else {
            this.$buefy.snackbar.open({
              ...snackSuccess,
              message: `${count} contacts has been assigned to "${title}"`,
            });
          }
        });
    },
    createContactGroupAndAssignMany($event) {
      this._createContactGroupAndAssignMany($event)
        .then(({ error, count, title }) => {
          this.checkedRows = [];
          if (error) {
            this.$buefy.snackbar.open({
              ...snackError,
              message: `Error while creating contact folder "${title}"`,
              onAction: () => this.createContactGroupAndAssignMany($event),
            });
          } else {
            this.$buefy.snackbar.open({
              ...snackSuccess,
              message: `Folder "${title}" has been created and ${count} contacts has been assigned`,
            });
          }
        });
    },
    fetchTiktokUser(event) {
      this.isStatisticsLoading = true;
      this._fetchTiktokUser(event).then(() => { this.isStatisticsLoading = false; });
    },
    showModalEditUsernameTiktok({ contactId }) {
      this.isModalEditUsernameTiktok = true;
      this.currentEditTiktokContactId = contactId;
    },
    updateTiktokUsername({ username }) {
      this._updateTiktokUsername({ username, contactId: this.currentEditTiktokContactId })
        .then(({ error }) => {
          this.isModalEditUsernameTiktok = false;
          if (error) {
            this.$buefy.snackbar.open({
              ...snackError,
              message: 'Error while setting/updating TikTok username',
            });
          } else {
            this.fetchTiktokUser({ username, contactId: this.currentEditTiktokContactId });
            this.$buefy.snackbar.open({
              ...snackSuccess,
              message: `The TikTok username has been set to @${username}. 
              Thank you, the statistics will be updated soon.`,
            });
          }
        });
    },
  },
};
