<template>
  <section
    id="TheThreadPanel"
    class="multibar column"
  >
    <UbuCommentThreadHeader
      v-if="isPanelComment"
      :source="selectedReply && selectedReply.source"
      :from="selectedReply && selectedReply.from"
      :is-post="selectedReply && selectedReply.isPost"
      @rateThread="$emit('rateThread', $event)"
      @translateThread="translateThread($event)"
    />
    <TheThreadHeader
      v-else
      :thread="selectedThread"
      @translateThread="translateThread($event)"
    />
    <div id="TheThreadPanelCore">
      <UbuCommonMessageList
        class="UbuInbox_messageBox"
        :items="threadItems"
        :is-panel-comment="isPanelComment"
        :contact="selectedThread ? selectedThread.contact : null"
        :sync-thread-is-loading="syncThreadIsLoading"
        :message-box-height="selectedReply && selectedReply.commentId ? 195 : messageBoxHeight"
        :selected-reply="selectedReply"
        @syncThread="methodSyncThread(selectedThread)"
        @resendMedia="resendMedia($event)"
        @deleteMessage="deleteMessage({
          threadId: selectedThread.threadId,
          messageId: $event,
        })"
        @delete="$emit('delete', $event)"
        @resend="$emit('resend', $event)"
        @clickToReply="$emit('clickToReply', $event)"
        @translateMessage="translateMessage($event)"
      />
      <div
        class="UbuThreadMessage_bottomBox"
      >
        <UbuCommentTextarea
          v-if="isPanelComment && selectedReply"
          v-model="newComment"
          :prefix="undefined/*selectedReply && `${selectedReply.source === 'instagram' ? '@' : ''}${selectedReply.from.fullName}`*/"
          :selected-reply="selectedReply"
          :is-closed-thread="isClosedThread"
          :template-list="Object.values(templateList)"
          :button-loading="commentsButtonLoading"
          :allow-close="!selectedReply.isPost"
          @sendComment="sendComment($event)"
          @sendCommentAndClose="sendCommentAndClose($event)"
          @openCloseThread="$emit('openCloseThread', $event)"
          @saveTemplate="$emit('saveTemplate', $event)"
          @cancelReply="$emit('cancelReply', $event)"
          @translateTextarea="translateTextarea($event)"
        />
        <UbuCommonTextarea
          v-else-if="!isPanelComment"
          ref="messageBox"
          :key="selectedThread.threadId"
          v-model="newMessage"
          :thread="selectedThread"
          :current-thread-group="currentThreadGroup"
          :template-list="Object.values(templateList)"
          :button-loading="buttonLoading"
          :affiliation-list="affiliationListToCurrentContact"
          :has-shopify-shop="hasShopifyShop"
          @autoResizeFooter="computeHeight($event)"
          @send="sendTextMessage($event)"
          @sendAndClose="sendMessageAndCloseThread({ text: $event })"
          @sendAndDone="sendMessageAndDoneThread({ text: $event })"
          @note="sendNote({ threadId: selectedThread.threadId, text: $event })"
          @saveTemplate="addTemplate({ ...$event, channelId: currentChannelId })"
          @doneThread="doneThread($event)"
          @closeThread="closeThread($event)"
          @readAndClose="readAndClose($event)"
          @openThread="openThread($event)"
          @imageWithText="sendTextWithImage(selectedThread, $event)"
          @imageWithTextAndClose="sendTextWithImageAndCloseThread(selectedThread, $event)"
          @imageWithTextAndDone="sendTextWithImageAndDoneThread(selectedThread, $event)"
          @translateTextarea="translateTextarea($event)"
          @openModalAddLink="$emit('openModalAddLink', selectedThread.contactId)"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { snackError } from '../$catalog/snackbar';
import { fileToBlob } from '../$utils/formate';
import { errorHandler } from '../$utils/helpers';
import TheThreadPanelMixin from './$mixins/TheThreadPanelMixin';

export default {
  name: 'TheThreadPanel',
  mixins: [TheThreadPanelMixin],
  props: {
    selectedThread: {
      type: Object,
      required: true,
    },
    currentThreadGroup: {
      type: Object,
      default: () => ({}),
    },
    statusList: {
      type: Array,
      default: () => ([]),
    },
    isPanelComment: {
      type: Boolean,
      default: false,
    },
    isClosedThread: {
      type: Boolean,
      default: false,
    },
    selectedReply: {
      type: Object,
      default: () => ({}),
    },
    contact: {
      type: Object,
      default: () => ({}),
    },
    commentsButtonLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      syncThreadIsLoading: false,
      messageBoxHeight: 100,
      buttonLoading: false,
      newComment: '',
      newMessage: '',
    };
  },
  computed: {
    ...mapGetters({
      currentChannelId: 'Channel/getterCurrentChannelId',
      templateList: 'TheWorkspace/getterTemplates',
      getterShopifyShop: 'TheContactPanel/getterShopifyShop',
    }),
    hasShopifyShop() {
      return !!this.getterShopifyShop.length;
    },
    currentContactId() {
      return this.selectedThread.contactId;
    },
    allDiscountCode() {
      return this.getterShopifyShop.reduce((acc, row) => {
        const { discountCode } = row;
        if (discountCode) acc.push(...discountCode);

        return acc;
      }, []);
    },
    allReferralLink() {
      return this.getterShopifyShop.reduce((acc, row) => {
        const { referralLink } = row;
        if (referralLink) acc.push(...referralLink);

        return acc;
      }, []);
    },
    affiliationList() {
      return this.allDiscountCode.concat(this.allReferralLink);
    },
    affiliationListToCurrentContact() {
      return this.allDiscountCode.concat(this.allReferralLink)
        .filter(({ contactId }) => contactId === this.currentContactId);
    },
  },
  mounted() {
    this.computeHeight();
  },
  methods: {
    ...mapActions({
      /* eslint-disable key-spacing */
      syncThread:    'TheThreadPanel/syncThread',
      sendImage:     'TheThreadPanel/sendImage',
      sendMessage:   'TheThreadPanel/sendMessage',
      sendNote:      'TheThreadPanel/sendNote',
      deleteMessage: 'TheThreadPanel/deleteMessage',
      _doneThread:    'TheThreadPanel/doneThread',
      _closeThread:   'TheThreadPanel/closeThread',
      _readAndClose:  'TheThreadPanel/readAndClose',
      _openThread:    'TheThreadPanel/openThread',
      addTemplate:   'TheWorkspace/addTemplate',
      translateCommentText: 'TheThreadPanel/translateCommentText',
      translateMessageText: 'TheThreadPanel/translateMessageText',
      _translateTextarea: 'TheThreadPanel/translateTextarea',
      translateThreadCommentText: 'TheThreadPanel/translateThreadCommentText',
      translateThreadMessageText: 'TheThreadPanel/translateThreadMessageText',
      /* eslint-enable key-spacing */
    }),
    computeHeight() {
      if (this.$refs.messageBox) this.messageBoxHeight = this.$refs.messageBox.$el.clientHeight;
    },
    methodSyncThread(event) {
      this.syncThreadIsLoading = true;
      return this.syncThread(event)
        .then(() => { this.syncThreadIsLoading = false; });
    },
    sendTextMessage(message) {
      this.buttonLoading = true;
      this.sendMessage({
        threadId: this.selectedThread.threadId,
        contactId: this.selectedThread.contactId,
        message,
      }).then(() => {
        this.buttonLoading = false;
        this.newMessage = '';
      });
    },
    resendMedia($event) {
      this.sendImage({
        thread: this.selectedThread,
        contactId: this.selectedThread.contactId,
        image: {
          fd: $event.fd,
          name: $event.name,
        },
        errorMessageId: $event.errorMessageId,
      });
    },
    sendTextWithImage(thread, { image, text }) {
      this.buttonLoading = true;
      fileToBlob(image)
        .then((blob) => {
          const fd = new FormData();
          fd.append('file', blob);
          this.sendImage({
            thread,
            contactId: thread.contactId,
            image: {
              fd,
              name: image.name,
            },
          });
          if (text.trim()) {
            this.sendMessage({
              threadId: this.selectedThread.threadId,
              contactId: this.selectedThread.contactId,
              message: text,
            });
          }
          this.buttonLoading = false;
          this.newMessage = '';
        });
    },
    sendTextWithImageAndCloseThread(thread, { image, text }) {
      this.buttonLoading = true;
      fileToBlob(image)
        .then((blob) => {
          const fd = new FormData();
          fd.append('file', blob);
          return this.sendImage({
            thread,
            contactId: thread.contactId,
            image: {
              fd,
              name: image.name,
            },
          });
        })
        .then(errorHandler('unable to send image'))
        .then(() => {
          if (!text.trim()) return undefined;
          return this.sendMessage({
            threadId: this.selectedThread.threadId,
            contactId: this.selectedThread.contactId,
            message: text,
          });
        })
        .then(errorHandler('unable to send message'))
        .then(() => this.closeThread({ thread: this.selectedThread }))
        .then(errorHandler('unable to close thread'))
        .then(() => {
          this.buttonLoading = false;
          this.newMessage = '';
        })
        .catch((error) => {
          this.buttonLoading = false;
          this.$buefy.snackbar.open({
            ...snackError,
            message: `Error on Send & Close "${error.message}"`,
            onAction: () => this.sendTextWithImageAndCloseThread(thread, { image, text }),
          });
        });
    },
    sendTextWithImageAndDoneThread(thread, { image, text }) {
      this.buttonLoading = true;
      fileToBlob(image)
        .then((blob) => {
          const fd = new FormData();
          fd.append('file', blob);
          return this.sendImage({
            thread,
            contactId: thread.contactId,
            image: {
              fd,
              name: image.name,
            },
          });
        })
        .then(errorHandler('unable to send image'))
        .then(() => {
          if (!text.trim()) return undefined;
          return this.sendMessage({
            threadId: this.selectedThread.threadId,
            contactId: this.selectedThread.contactId,
            message: text,
          });
        })
        .then(errorHandler('unable to send message'))
        .then(() => this.doneThread({
          thread: this.selectedThread,
          threadGroup: this.currentThreadGroup,
        }))
        .then(errorHandler('unable to done thread'))
        .then(() => {
          this.buttonLoading = false;
          this.newMessage = '';
        })
        .catch((error) => {
          this.buttonLoading = false;
          this.$buefy.snackbar.open({
            ...snackError,
            message: `Error on Send & Done "${error.message}"`,
            onAction: () => this.sendTextWithImageAndDoneThread(thread, { image, text }),
          });
        });
    },
    sendMessageAndCloseThread({ text }) {
      if (!text.trim()) return;
      this.buttonLoading = true;
      this.sendMessage({
        threadId: this.selectedThread.threadId,
        contactId: this.selectedThread.contactId,
        message: text,
      }).then(errorHandler('unable to send message'))
        .then(() => this.closeThread({ thread: this.selectedThread }))
        .then(() => {
          this.buttonLoading = false;
          this.newMessage = '';
        })
        .catch((error) => {
          this.buttonLoading = false;
          this.$buefy.snackbar.open({
            ...snackError,
            message: `Error on Send & Close "${error.message}"`,
            onAction: () => this.sendMessageAndCloseThread({ text }),
          });
        });
    },
    sendMessageAndDoneThread({ text }) {
      if (!text.trim()) return;
      this.buttonLoading = true;
      this.sendMessage({
        threadId: this.selectedThread.threadId,
        contactId: this.selectedThread.contactId,
        message: text,
      })
        .then(errorHandler('unable to send message'))
        .then(() => this.doneThread({
          thread: this.selectedThread,
          threadGroup: this.currentThreadGroup,
        }))
        .then(() => {
          this.buttonLoading = false;
          this.newMessage = '';
        })
        .catch((error) => {
          this.buttonLoading = false;
          this.$buefy.snackbar.open({
            ...snackError,
            message: `Error on Send & Done "${error.message}"`,
            onAction: () => this.sendMessageAndDoneThread({ text }),
          });
        });
    },
    doneThread($event) {
      this.buttonLoading = true;
      this._doneThread($event)
        .then(errorHandler('Error while making thread done'))
        .then(() => { this.$emit('selectNext', true); })
        .then(() => { this.buttonLoading = false; })
        .catch((error) => {
          this.buttonLoading = false;
          this.$buefy.snackbar.open({
            ...snackError,
            message: error.message,
            onAction: () => this.doneThread($event),
          });
        });
    },
    closeThread($event) {
      this.buttonLoading = true;
      this._closeThread($event)
        .then(({ error, threadName }) => {
          this.buttonLoading = false;
          console.log('🚀 ~ file: TheThreadPanel.vue ~ line 209 ~ .then ~ error', error);
          if (error) {
            this.$buefy.snackbar.open({
              ...snackError,
              message: `Error while closing thread "${threadName}"`,
              onAction: () => this.closeThread($event),
            });
          } else {
            this.$emit('selectNext', true);
          }
        });
    },
    readAndClose($event) {
      this.buttonLoading = true;
      this._readAndClose($event)
        .then(({ error, threadName }) => {
          this.buttonLoading = false;
          console.log('🚀 ~ file: TheThreadPanel.vue ~ line 228 ~ .then ~ error', error);
          if (error) {
            this.$buefy.snackbar.open({
              ...snackError,
              message: `Error while reading and closing thread "${threadName}"`,
              onAction: () => this.readAndClose($event),
            });
          } else {
            this.$emit('selectNext', true);
          }
        });
    },
    openThread($event) {
      this.buttonLoading = true;
      this._openThread($event)
        .then(({ error, threadName }) => {
          this.buttonLoading = false;
          if (error) {
            this.$buefy.snackbar.open({
              ...snackError,
              message: `Error while opening thread "${threadName}"`,
              onAction: () => this.openThread($event),
            });
          }
        });
    },
    sendComment(event) {
      this.$emit('sendComment', event);
      this.newComment = '';
    },
    sendCommentAndClose(event) {
      this.$emit('sendCommentAndCloseThread', event);
      this.newComment = '';
    },
    translateMessage(event) {
      if (this.isPanelComment) {
        this.translateCommentText({
          channelId: this.currentChannelId,
          target: event.target,
          message: event.item,
        });
        return;
      }
      this.translateMessageText({
        channelId: this.currentChannelId,
        target: event.target,
        message: event.item,
      });
    },
    translateTextarea(event) {
      if (this.isPanelComment && this.newComment.trim().length) {
        this._translateTextarea({
          channelId: this.currentChannelId, text: this.newComment, ...event,
        })
          .then(({ translations }) => {
            const [trad] = translations;
            this.newComment = trad.text;
          });
      }
      if (this.newMessage.trim().length) {
        this._translateTextarea({
          channelId: this.currentChannelId, text: this.newMessage, ...event,
        })
          .then(({ translations }) => {
            const [trad] = translations;
            this.newMessage = trad.text;
          });
      }
    },
    translateThread(event) {
      const { messages } = this.selectedThread;
      if (this.isPanelComment) {
        this.translateThreadCommentText({
          channelId: this.currentChannelId,
          messagesList: messages,
          ...event,
        });
        return;
      }
      this.translateThreadMessageText({
        channelId: this.currentChannelId,
        messagesList: messages,
        ...event,
      });
    },
  },
};
</script>

<style lang="scss">
#TheThreadPanel {
  height: 100%;
  max-height: 100vh;
  & #TheThreadPanelCore {
    position: relative;
    min-height: calc(100% - 60px);
    max-height: calc(100% - 60px);
    & .UbuInbox_messageBox {
      // height: calc(100% - 190px);
      height: 100%;
    }
    & .UbuThreadMessage_bottomBox {
      display: flex;
      width: 100%;
      flex-direction: column;
      z-index: 20;
      position: absolute;
      bottom: 0;
      // padding: 12px;
      margin: 0px;
    }
  }
}
</style>
