<template>
  <section id="TheWorkspaceTemplates">
    <div class="workspace-view-header">
      <div class="flex">
        <h1 class="ubu-header1">Templates</h1>
        <b-tooltip
          class="ml-2 a-self-center"
          :label="`Templates are saved messages
            that you can use to answer recurring questions or common messages.
            You can quickly load a template by typing /yourTemplateTitle in a text message area.`"
          size="is-medium"
          position="is-bottom"
          multilined
        >
          <UbuIcon name="info" />
        </b-tooltip>
      </div>
      <UbuButton
        :icon="{ name: 'add', filled: true }"
        @click="isCardModalActive = !isCardModalActive"
      >
        Add Template
      </UbuButton>
      <b-modal
        v-model="isCardModalActive"
        :width="640"
        scroll="keep"
      >
        <UbuWorkspaceModalAddTemplate
          :is-card-modal-active="isCardModalActive"
          :template-list="templateList"
          @close="isCardModalActive = false"
          @addFromModal="addFromModal($event)"
        />
      </b-modal>
    </div>
    <UbuWorkspaceTemplate
      v-for="template in templateList"
      :key="template.templateId"
      :template="template"
      :template-list="templateList"
      @editFromModal="editFromModal($event)"
      @clickDelete="removeTemplate($event)"
    />
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { snackSuccess, snackError } from '../$catalog/snackbar';

export default {
  name: 'TheWorkspaceTemplates',
  data() {
    return {
      isCardModalActive: false,
    };
  },
  computed: {
    ...mapGetters({
      templateList: 'TheWorkspace/getterTemplates',
      currentChannelId: 'Channel/getterCurrentChannelId',
    }),
  },
  methods: {
    ...mapActions({
      addTemplate: 'TheWorkspace/addTemplate',
      updateTemplate: 'TheWorkspace/updateTemplate',
      deleteTemplate: 'TheWorkspace/deleteTemplate',
    }),

    addFromModal($event) {
      const myTemplate = {
        ...$event,
        type: 'template',
      };

      this.isCardModalActive = false;
      this.addTemplate({ ...myTemplate, channelId: this.currentChannelId })
        .then(({ response }) => {
          if (response) {
            this.$buefy.snackbar.open({
              message: 'Template was added',
              ...snackSuccess,
            });
          } else {
            this.$buefy.snackbar.open({
              message: 'Template was not added',
              ...snackError,
            });
          }
        });
    },

    editFromModal($event) {
      const myTemplate = {
        ...$event,
        type: 'template',
      };
      this.updateTemplate({ ...myTemplate, channelId: this.currentChannelId })
        .then(({ response }) => {
          if (response) {
            this.$buefy.snackbar.open({
              message: 'Template was edited',
              ...snackSuccess,
            });
          } else {
            this.$buefy.snackbar.open({
              message: 'Template was not edited',
              ...snackError,
            });
          }
        });
    },

    removeTemplate(event) {
      const deletedTemplate = {
        ...event,
        type: 'template',
        deleted: true,
      };
      this.deleteTemplate({ ...deletedTemplate, channelId: this.currentChannelId })
        .then(({ response }) => {
          if (response) {
            this.$buefy.snackbar.open({
              message: 'Template was deleted',
              ...snackSuccess,
            });
          } else {
            this.$buefy.snackbar.open({
              message: 'Template was not deleted',
              ...snackError,
            });
          }
        });
    },
  },

  canCreateTemplate(title, data) {
    let allowed = true;

    Object.values(this.templates).forEach((value) => {
      if (value.title === title || !title) allowed = false;
    });
    if (title.split(' ').length !== 1) allowed = false;
    if (!data.trim()) allowed = false;
    return allowed;
  },
};
</script>
