import moment from 'moment';
import store from '@/store';
import routes from '../conf/routes';

export const initializeSocket = () => {
  let ws;
  try {
    ws = new WebSocket(routes.socket.url);
  } catch (e) {
    console.warn(e);
  }

  ws.onopen = function onOpen() {
    // console.log('Socket connected', event);
    console.log(`Socket connected ${moment().format('HH:mm:ss')}`);
  };

  ws.onmessage = function onMessage(event) {
    // console.log('On socket message', event);

    const { type, payload } = JSON.parse(event.data);
    if (type === 'newThread') {
      // console.log('🚀 ~ file: websocket.js ~ line 25 ~ onMessage ~ payload', payload);
      store.dispatch('Thread/SOCKET_newThread', payload);
    }
    if (type === 'state') {
      // console.log('🚀 ~ file: websocket.js ~ line 25 ~ onMessage ~ payload', payload);
      store.dispatch('State/SOCKET_doing', payload);
    }
    if (type === 'custom') {
      // console.log('🚀 ~ file: websocket.js ~ line 25 ~ onMessage ~ payload', payload);
      store.dispatch('CustomTag/SOCKET_setCustom', payload);
    }
    if (type === 'customSubject') {
      // console.log('🚀 ~ file: websocket.js ~ line 25 ~ onMessage ~ payload', payload);
      store.dispatch('CustomTag/SOCKET_customSubject', payload);
    }
    if (type === 'setClosed') {
      // console.log('🚀 ~ file: websocket.js ~ line 25 ~ onMessage ~ payload', payload);
      store.dispatch('Thread/SOCKET_toggleCloseThread', payload);
    }
    if (type === 'closeComments') {
      console.log('🚀 ~ file: websocket.js ~ CLOSE COMMENTS ~ payload', payload);
      store.dispatch('Comment/SOCKET_toggleCloseComments', payload);
    }
    if (type === 'deleteItem') {
      // console.log('🚀 ~ file: websocket.js ~ line 25 ~ onMessage ~ payload', payload);
      // store.dispatch('Thread/SOCKET_newThread', payload);
    }
    if (type === 'note') {
      // console.log('🚀 ~ file: websocket.js ~ line 25 ~ onMessage ~ payload', payload);
      store.dispatch('Note/SOCKET_newNote', payload);
    }
    if (type === 'seen') {
      // console.log('🚀 ~ file: websocket.js ~ line 25 ~ onMessage ~ payload', payload);
      // store.dispatch('Thread/SOCKET_newThread', payload);
    }
    if (type === 'isDriveConnected') {
      // console.log('🚀 ~ file: websocket.js ~ line 51 ~ onMessage ~ payload', payload);
      store.dispatch('Channel/SOCKET_isDriveConnected', payload);
    }
    if (type === 'newComment') {
      // console.log('🚀 ~ file: websocket.js ~ line 25 ~ onMessage ~ payload', payload);
      store.dispatch('Comment/SOCKET_newComment', payload);
    }
    if (type === 'newCommentFb') {
      // console.log('🚀 ~ file: websocket.js ~ line 25 ~ onMessage ~ payload', payload);
      store.dispatch('Comment/SOCKET_newCommentFb', payload);
    }
    if (type === 'deleteComment') {
      // console.log('🚀 ~ file: websocket.js ~ line 25 ~ onMessage ~ payload', payload);
      store.dispatch('Comment/SOCKET_deleteComment', payload);
    }
    if (type === 'sendCommentError') {
      // console.log('🚀 ~ file: websocket.js ~ line 25 ~ onMessage ~ payload', payload);
      store.dispatch('Comment/SOCKET_sendCommentError', payload);
    }
    if (type === 'userChanged') {
      console.log('🚀 ~ file: websocket.js ~ line 25 ~ onMessage ~ payload', payload);
      store.dispatch('Contact/SOCKET_userChanged', payload);
    }
  };
  ws.onerror = function onError(event) {
    console.warn('🚀 ~ file: websocket.js ~ line 34 ~ onError ~ event', event);
  };
  ws.onclose = () => {
    // console.log('🚀 ~ file: websocket.js ~ line 37 ~ initializeSocket ~ event', event);
    console.log(`Socket closed ${moment().format('HH:mm:ss')}`);
    ws = null;
    setTimeout(() => { initializeSocket(); }, 7000);
  };
  return ws;
};

export default {};
