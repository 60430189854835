<template>
  <form id="UbuInfluenceSettingsCampaignForm">
    <b-field grouped>
      <b-field>
        <b-dropdown
          class="ubuDropdown is-row"
          aria-role="list"
        >
          <template #trigger>
            <b-button
              class="ubuButton"
              type="is-light"
            >
              <UbuIcon
                filled
                name="influence"
                :style="{
                  color: `var(--color${convertColor(campaignDetail.color)})`,
                }"
              />
            </b-button>
          </template>

          <b-dropdown-item
            v-for="n in 12"
            :key="`color-${n}`"
            aria-role="listitem"
            @click="$update('color', convertFromColor(n))"
          >
            <UbuIcon
              filled
              clickable
              name="influence"
              :style="{
                color: `var(--color${n})`,
              }"
            />
          </b-dropdown-item>
        </b-dropdown>
      </b-field>
      <b-field expanded>
        <b-input
          v-model="campaignDetail.name"
          class="ubuInput"
          placeholder="campaign name"
          expanded
          @input="$update('name', $event)"
        />
      </b-field>
      <div
        v-if="hasChanges"
        class="controls"
      >
        <b-button
          class="is-rounded is-light"
          @click="$emit('updateCampaignDetail', campaignDetail)"
        >
          <span>
            Save
          </span>
        </b-button>
      </div>
    </b-field>
  </form>
</template>

<script>
import CommonMixin from '../../$mixins/CommonMixin';
import VModelMixin from '../../$mixins/VModelMixin';

export default {
  name: 'UbuInfluenceSettingsCampaignForm',
  mixins: [CommonMixin, VModelMixin],
  model: {
    prop: 'campaignDetail',
  },
  props: {
    campaignDetail: {
      type: Object,
      required: true,
    },
    hasChanges: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="scss">
#UbuInfluenceSettingsCampaignForm {
  .dropdown.is-row {
    & .dropdown-content {
      display: flex;
      background-color: var(--soapstone);
      border: 1px solid var(--narvalo);
      border-radius: 15px;
      padding-right: 0.5rem;
      padding-left: 0.5rem;

      & .dropdown-item {
        border-radius: 15px;
        &:hover {
          background-color: var(--peachcream);
        }
      }
    }
  }
}
</style>
