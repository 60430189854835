<template>
  <UbuCommonMessageCard
    :is-me="isMe"
    :date="date"
    is-media
    @deleteMessage="$emit('deleteMessage')"
  >
    <template v-slot:image>
      <UbuCommonVideo
        :has-bucket="hasBucket"
        :video-url="videoUrl"
        :image-url="`${videoUrl}/thumbnail`"
        :mime-type="mimeType"
        :is-expired="isExpired"
      />
    </template>

    <template
      v-if="username"
      v-slot:content
    >
      <div class="card-content-text">
        <UbuInstaName
          class="ubu-instalink-msg"
          :username="username"
        />
      </div>
    </template>
  </UbuCommonMessageCard>
</template>
<script>

export default {
  name: 'UbuCommonFelixShare',
  props: {
    isMe: {
      type: Boolean,
      default: false,
    },
    date: {
      type: String,
      required: true,
    },
    username: {
      type: String,
      default: '',
    },
    videoUrl: {
      type: String,
      default: '',
    },
    imageUrl: {
      type: String,
      default: '',
    },
    mimeType: {
      type: String,
      default: 'video/mp4',
    },
    hasBucket: {
      type: Boolean,
      default: false,
    },
    isExpired: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
