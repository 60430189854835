<template>
  <div class="UbuColorPicker_wrapper">
    <template v-for="n in 12">
      <UbuIcon
        :key="`color-${n}`"
        :class="['UbuColorPicker_color', `UbuColorPicker_color${n}`]"
        filled
        clickable
        :name="icon"
        :style="{
          color: `var(--color${n})`,
        }"
        @click="$emit('selectColor', n)"
      />
    </template>
  </div>
</template>
<script>
export default {
  name: 'UbuColorPicker',
  props: {
    icon: {
      type: String,
      default: '',
    },
  },
};
</script>
<style lang="scss">
  .UbuColorPicker_wrapper {
    font-size: 18px;
    width: 108px;
    padding: 6px;
    display: flex;
    flex-wrap: wrap;
    @for $i from 1 through 12 {
      & .UbuColorPicker_color#{$i} {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        &:hover {
          border: 2px solid var(--color#{$i});
          border-radius: 50%;
        }
      }
    }
    > * {
        padding: 0 3px;
    }
  }
</style>
