<template>
  <div class="UbuContactPanelReport_wrapper">
    <div
      v-if="!isReportLoading"
      class="UbuContactPanelReport_content"
    >
      <span
        v-if="report && report.error"
        class="ubu-text1"
      >
        Sorry, we were unable to generate a report of @{{ username }}
      </span>
      <div
        v-else
        class="UbuContactPanelReport_content"
      >
        <span class="ubu-text1">
          Here is the report of @{{ username }}, click on the button to download
          it
        </span>
        <UbuButton
          class="UbuContactPanelReport_button"
          center
          type="button"
          secondary
          :href="report && report.response.Url"
        >
          Download report
        </UbuButton>
      </div>
    </div>
    <div
      v-else
      class="UbuContactPanelReport_content"
    >
      <UbuLoader />
      <span class="ubu-text1">Generating report of @{{ username }}</span>
    </div>
  </div>
</template>
<script>
/**
 * @todo Delete this file if we really decide to don't use a modal for the generate report
*/
export default {
  name: 'UbuContactPanelReport',
  props: {
    username: {
      type: String,
      required: true,
    },
    isReportLoading: {
      type: Boolean,
      required: true,
    },
    report: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
<style lang="scss" scoped>
.UbuContactPanelReport_wrapper {
  height: 400px;
  background-color: var(--soapstone);
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .UbuContactPanelReport_content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .UbuContactPanelReport_button {
      margin: 10px;
    }
  }
}
</style>
