<template>
  <b-tabs
    id="CRMTableDetail"
    v-model="activeTab"
    :animated="false"
    type="is-boxed"
    size="is-small"
    position="is-left"
  >
    <b-tab-item>
      <template #header>
        Details
      </template>
      <div class="flex">
        <UbuContactCard
          :contact="row"
        />
      </div>

      <br>
      <p class="ubu-label">Bio:</p>
      <p
        v-if="row.biography"
        class="subtext is-3 text-darkgrey"
      >
        {{ row.biography }}
      </p>

      <br>

      <p class="ubu-label">Stats:</p>
      <div class="stat-content">
        <p class="subtext is-3 text-darkgrey stat-text">
          <span>😍</span>
          <span>{{ row.follower_count.toLocaleString('en') || 0 }} followers</span>
        </p>

        <p class="subtext is-3 text-darkgrey stat-text">
          <span>🎯</span>
          <span v-if="row.engagement && Number(row.engagement) >= 0.3">
            {{ Math.round(row.engagement * 100) / 100 }}% engagement
          </span>
          <span v-else>
            -
          </span>
        </p>

        <p class="subtext is-3 text-darkgrey stat-text">
          <span>💬</span>
          <span v-if="row.average_comment && !isRefreshAskable">
            {{ row.average_comment }} comments/post
          </span>
          <span v-else>-</span>
        </p>
      </div>

      <div class="flex report-buttons">
        <UbuButton
          v-if="showNewMessageButton"
          icon="compose"
          :tooltip="{ label: 'New message', position: 'is-bottom' }"
          class="mr-2"
          @click="$emit('openNewMessage', {pk: row.contactId, username: row.username})"
        />
        <b-button
          tag="a"
          :disabled="!row.fk_public_url"
          class="button is-rounded is-geraldine"
          :href="row.fk_public_url"
          target="_blank"
        >
          Open report
        </b-button>
        <b-tooltip
          label="Generate audit (>5000 followers & not private)"
          position="is-right"
        >
          <b-button
            :disabled="!isReportAuthorized"
            :loading="isGenerateReportLoading === row.username"
            class="is-rounded is-geraldine"
            @click="$emit('generateReport', $event)"
          >
            {{ row.audience ? 'Regenerate report' : 'Generate report' }}
          </b-button>
        </b-tooltip>
        <b-button
          v-if="isRefreshAskable"
          :loading="isStatisticsLoading"
          class="is-rounded is-geraldine"
          @click="hardRefresh()"
        >
          Refresh statistics
        </b-button>

        <b-tooltip
          class=""
          label="Loading to generate report can take +/- 30s"
          position="is-right"
          multilined
        >
          <UbuIcon name="info" />
        </b-tooltip>

        <b-tooltip
          v-if="!isTiktoker && showTiktokTab"
          :label="tooltipTikTokAddUsernameButton"
          position="is-bottom"
          multilined
        >
          <b-button
            class="is-rounded is-geraldine"
            @click="$emit('openModalEditUsernameTiktok', { contactId: row.contactId })"
          >
            Add TikTok username
          </b-button>
        </b-tooltip>
      </div>

      <p class="ubu-label">Informations:</p>
      <div class="info-content">
        <div class="subtext is-3 info-text">
          <span>💌</span>
          <input
            v-model="localEmail"
            class="input-info"
            placeholder="Email..."
            @blur="updateUserEmail($event)"
          >
        </div>
        <div class="subtext is-3 info-text">
          <span>☎️</span>
          <input
            v-model="localPhone"
            class="input-info"
            placeholder="Phone..."
            @blur="updateUserPhone($event)"
          >
        </div>
        <div class="subtext is-3 info-text">
          <span>🚀</span>
          <input
            v-model="localWebsite"
            class="input-info"
            placeholder="Website..."
            @blur="updateUserWebsite($event)"
          >
        </div>
      </div>
    </b-tab-item>

    <b-tab-item
      v-if="isTiktoker && showTiktokTab"
    >
      <template #header>
        TikTok
      </template>
      <UbuContactDataCategory
        class="UbuContactDataCategory"
        style="max-width: 250px;"
        title="Statistics"
      >
        <UbuContactDataRow
          type="tiktokLink"
          :username="row.tiktok_username"
          :tooltip="{
            label: tooltipTikTokLink,
            position: 'is-bottom',
            multilined: true,
          }"
        />
        <UbuContactDataRow
          emoji="💘"
          :is-loading="isStatisticsLoading"
          :text="tiktokFollowerCount"
        />
        <UbuContactDataRow
          emoji="🎯"
          :is-loading="isStatisticsLoading"
          :text="tiktokEngagement"
        />
        <UbuContactDataRow
          emoji="❤️"
          :is-loading="isStatisticsLoading"
          :text="tiktokLikes"
        />
        <UbuContactDataRow
          emoji="📹"
          :is-loading="isStatisticsLoading"
          :text="tiktokVideoCount"
        />
        <UbuContactDataRow
          emoji="👁️"
          :is-loading="isStatisticsLoading"
          :text="tiktokAverageViews"
        />
        <UbuContactDataRow
          emoji="💬"
          :is-loading="isStatisticsLoading"
          :text="tiktokAverageComments"
        />
        <UbuContactDataRow
          emoji="📡"
          :is-loading="isStatisticsLoading"
          :text="tiktokAverageShares"
        />
        <b-tooltip
          class="tooltip-generateReport"
          :label="tooltipTikTokChangeUsernameButton"
          position="is-bottom"
          multilined
        >
          <b-button
            expanded
            class="is-rounded is-light"
            @click="$emit('openModalEditUsernameTiktok', { contactId: row.contactId })"
          >
            Change TikTok username
          </b-button>
        </b-tooltip>
      </UbuContactDataCategory>
    </b-tab-item>

    <b-tab-item>
      <template #header>
        Tags
      </template>
      <div v-if="!row.thread || !row.thread.threadId">
        <p class="subtext">
          You don't have thread with this user, start a conversation !
        </p>
      </div>
      <template v-else>
        <div class="columns">
          <div class="column is-4">
            <div class="buttons">
              <b-button
                v-for="item in sortListByActiveCustomFirst(
                  threadGroupsList, 'threadGroup', 'customTagId')"
                :key="item.customTagId"
                :active="isCustomIntoContact(item, 'threadGroup')"
                :class="['is-rounded is-custom mr-3 mb-3', `button-color-${item.color}`]"
                @click="customManagement(item, 'threadGroup')"
              >
                <UbuIcon
                  name="tag"
                  filled
                  size="14px"
                />
                {{ item.title }}
              </b-button>
            </div>
          </div>
        </div>

        <div class="action-button-container">
          <b-button
            class="is-rounded is-light"
            @click="isTagModalActive = !isTagModalActive"
          >
            <UbuIcon
              name="add"
              filled
              size="14px"
            />
            Create and assign tag
          </b-button>

          <b-modal
            v-model="isTagModalActive"
            :width="640"
            scroll="keep"
          >
            <UbuWorkspaceModalAddTag
              :is-card-modal-active="isTagModalActive"
              :new-custom="newTag"
              @close="isTagModalActive = false"
              @selectColor="selectTagColor($event)"
              @addFromModal="createThreadGroupAndAssign($event)"
            />
          </b-modal>
        </div>
      </template>
    </b-tab-item>

    <b-tab-item>
      <template #header>
        Contact Labels
      </template>
      <div class="columns">
        <div class="column is-4">
          <div class="buttons">
            <b-button
              v-for="item in sortListByActiveCustomFirst(
                contactGroupsList, 'contactGroup', 'contactGroupId')"
              :key="item._id"
              :active="isCustomIntoContact(item, 'contactGroup')"
              :class="['is-rounded is-custom mr-3 mb-3', `button-color-${item.color}`]"
              @click="customManagement(item, 'contactGroup')"
            >
              <UbuIcon
                name="contact"
                filled
                size="14px"
              />
              {{ item.title }}
            </b-button>
          </div>
        </div>
      </div>
      <div class="action-button-container">
        <b-button
          class="is-rounded is-light"
          @click="isLabelModalActive = !isLabelModalActive"
        >
          <UbuIcon
            name="add"
            filled
            size="14px"
          />
          Create and assign label
        </b-button>

        <b-modal
          v-model="isLabelModalActive"
          :width="640"
          scroll="keep"
        >
          <UbuWorkspaceModalAddFolder
            :is-card-modal-active="isLabelModalActive"
            :new-custom="newLabel"
            @close="isLabelModalActive = false"
            @selectColor="selectLabelColor($event)"
            @addFromModal="createContactGroupAndAssign($event)"
          />
        </b-modal>
      </div>
    </b-tab-item>

    <b-tab-item>
      <template #header>
        Campaigns
      </template>
      <div class="columns">
        <div class="column is-4">
          <div class="buttons">
            <b-button
              v-for="item in sortListByActiveCustomFirst(
                activeCampaignList, 'activeCampaign', 'campaignId')"
              :key="item.campaignId"
              :active="isCustomIntoContact(item, 'campaign')"
              :class="['is-rounded is-custom mr-3 mb-3', `button-color-${item.color}`]"
              @click="customManagement(item, 'campaign')"
            >
              <UbuIcon
                name="influence"
                filled
                size="14px"
              />
              {{ item.title }}
            </b-button>
          </div>
        </div>
      </div>
    </b-tab-item>

    <b-tab-item>
      <template #header>
        Affiliation
      </template>
      <div v-if="hasShopifyShop">
        <b-button
          class="is-light is-rounded"
          @click="$emit('openModalAddLink', currentContactId)"
        >
          <UbuIcon name="add" />
          Add link
        </b-button>

        <div class="ubu-divider mt-4" />
      </div>

      <div v-if="affiliationListToCurrentContact.length">
        <div
          v-for="item in affiliationListToCurrentContact"
          :key="item._id"
        >
          <div class="shopify-card column is-4">
            <UbuContactPanelShopifyCard
              :affiliation="item"
            />

            <b-dropdown
              aria-role="list"
              position="is-bottom-left"
            >
              <template #trigger>
                <b-button
                  label="Manage affiliation"
                  class="is-light is-rounded"
                />
              </template>

              <b-dropdown-item custom>
                <b-collapse :open="false">
                  <template #trigger="props">
                    <div class="dropdown-text">
                      <UbuIcon name="influence" /> Link to campaign
                      <b-icon
                        :icon="props.open ? 'menu-down' : 'menu-up'"
                      />
                    </div>
                  </template>
                  <div
                    v-if="activeCampaignList.length"
                    class="card-content"
                  >
                    <div
                      v-for="camp in activeCampaignList"
                      :key="camp.campaignId"
                      class="content"
                      @click="$emit('assignCampaignToLink',
                                    { campaignId: camp.campaignId, code: item })"
                    >
                      <div class="collapse-item">
                        <span :class="`text-color-${camp.color}`">
                          <UbuIcon
                            name="influence"
                            filled
                            :color="camp.color"
                          /> {{ camp.title }}
                        </span>
                        <UbuIcon
                          v-if="camp.campaignId === item.campaignId"
                          name="checkmark"
                          :color="camp.color"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    v-else
                    class="card-content"
                  >
                    <span class="ubu-info">This contact is not into a campaign</span>
                  </div>
                </b-collapse>
              </b-dropdown-item>

              <b-dropdown-item>
                <a
                  :href="`${prefixUrl}${item.link}`"
                  target="_blank"
                  class="action-redirect"
                >
                  <UbuIcon name="externalLink" /> View in shopify
                </a>
              </b-dropdown-item>

              <b-dropdown-item
                class="text-geraldine"
                @click="removeContactToLink(item)"
              >
                <UbuIcon name="trash" /> Delete
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <div class="ubu-divider" />
        </div>
      </div>

      <div v-else>
        <p class="subtext mt-4">
          You don't have link with this user, create one first !
        </p>
      </div>
    </b-tab-item>

    <b-tab-item v-if="row.isHubspot">
      <template #header>
        Custom
      </template>
      <div v-if="row.data">
        <p
          v-for="([key, value]) in Object.entries(parseCustomData(tryParseJson(row.data)))"
          :key="key"
          class="ubu-label pb-2"
        >
          {{ jsUcfirst(key.toLowerCase()) }}: {{ value }}
        </p>
      </div>
      <div v-else>
        <p class="ubu-label pb-2">
          No custom data available for this contact
        </p>
      </div>
    </b-tab-item>
  </b-tabs>
</template>

<script>
import { tryParseJson } from '../../$utils/formate';
import { parseCustomData, jsUcfirst } from '../$utils/parseCustomData';

export default {
  name: 'UbuCRMTableDetail',
  props: {
    row: {
      type: Object,
      default: () => {},
    },
    threadGroupsList: {
      type: Array,
      default: () => [],
    },
    contactGroupsList: {
      type: Array,
      default: () => [],
    },
    activeCampaignList: {
      type: Array,
      default: () => [],
    },
    isGenerateReportLoading: {
      type: String,
      default: null,
    },
    allReferralConcatDiscount: {
      type: Array,
      default: () => [],
    },
    hasShopifyShop: {
      type: Boolean,
      default: false,
    },
    templateList: {
      type: Array,
      default: () => [],
    },
    showNewMessageButton: {
      type: Boolean,
      default: false,
    },
    isStatisticsLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tryParseJson,
      parseCustomData,
      jsUcfirst,
      activeTab: 0,
      isTagModalActive: false,
      isLabelModalActive: false,
      newTag: {
        title: '',
        type: 'tag',
        color: 1,
      },
      newLabel: {
        title: '',
        type: 'label',
        color: 1,
      },
      emailValue: null,
      phoneValue: null,
      websiteValue: null,
      messageText: '',
      prefixUrl: 'https://',
      tooltipTikTokLink: 'If there is any mistake you can change the username by clicking on the button below.',
      tooltipTikTokChangeUsernameButton: 'This account was found by an algorithm, if this is the wrong TikTok account you can change the username by clicking here.',
      tooltipTikTokAddUsernameButton: "At the moment, we didn't find any TikTok account for this user. You can click here to add one manually",
    };
  },
  computed: {
    isReportAuthorized() {
      return !this.row.is_private && this.row.follower_count > 5000;
    },
    showInformation() {
      const { emails, phones, websites } = this.row;
      return emails || phones || websites;
    },
    localEmail: {
      get() { return this.row.emails[0]; },
      set(newValue) { this.emailValue = newValue; },
    },
    localPhone: {
      get() { return this.row.phones[0]; },
      set(newValue) { this.phoneValue = newValue; },
    },
    localWebsite: {
      get() { return this.row.websites[0]; },
      set(newValue) { this.websiteValue = newValue; },
    },
    currentContactId() {
      return this.row.contactId;
    },
    affiliationListToCurrentContact() {
      if (!this.row.discountCodes) return [];
      return this.row.discountCodes.concat(this.row.referralLinks)
        .sort((a, b) => this.$moment(b.createdOn) - this.$moment(a.createdOn));
    },
    isRefreshAskable() {
      const engagement = this.row.engagement || 0;
      return engagement < 0.3 && this.row?.follower_count > 5000 && !this.row.is_private;
    },
    isTiktoker() {
      const username = this.row.tiktok_username;
      if (username && username !== 'NA') return true;
      return false;
    },
    showTiktokTab() {
      if (this.row?.is_private || this.row?.follower_count < 5000) return false;
      return true;
    },
    tiktokFollowerCount() {
      const followerCount = this.row?.tiktokUser?.followerCount;
      if (!followerCount) return null;
      return `${followerCount.toLocaleString('en') || 0} followers`;
    },
    tiktokEngagement() {
      const engagement = this.row?.tiktokUser?.engagement;
      if (!engagement) return null;
      return `${Math.round(engagement * 100) / 100}% engagement`;
    },
    tiktokLikes() {
      const likes = this.row?.tiktokUser?.heartCount;
      if (!likes) return null;
      return `${likes.toLocaleString('en') || 0} likes`;
    },
    tiktokAverageComments() {
      const averageComment = this.row?.tiktokUser?.average_comment;
      if (!averageComment) return null;
      return `${averageComment.toLocaleString('en') || 0} average comments`;
    },
    tiktokAverageViews() {
      const averageView = this.row?.tiktokUser?.average_view;
      if (!averageView) return null;
      return `${averageView.toLocaleString('en') || 0} average views`;
    },
    tiktokAverageShares() {
      const averageShare = this.row?.tiktokUser?.average_share;
      if (!averageShare) return null;
      return `${averageShare.toLocaleString('en') || 0} average share`;
    },
    tiktokVideoCount() {
      const videoCount = this.row?.tiktokUser?.videoCount;
      if (!videoCount) return null;
      return `${videoCount.toLocaleString('en') || 0} videos`;
    },
  },
  watch: {
    activeTab(value) {
      if (value === 1) {
        this.fetchTiktokIfMissing();
      }
    },
  },
  methods: {
    selectTagColor(event) {
      this.newTag.color = event;
    },
    selectLabelColor(event) {
      this.newLabel.color = event;
    },
    createThreadGroupAndAssign(event) {
      this.isTagModalActive = false;
      this.$emit('createThreadGroupAndAssign', event);
      this.newTag = {
        title: '',
        type: 'tag',
        color: 1,
      };
    },
    createContactGroupAndAssign(event) {
      this.isLabelModalActive = false;
      this.$emit('createContactGroupAndAssign', event);
      this.newLabel = {
        title: '',
        type: 'label',
        color: 1,
      };
    },
    updateUserEmail(event) {
      const myContact = {
        ...this.row,
        emails: [event.target.value],
      };

      if (event.target.value !== this.row.emails[0]) {
        this.$emit('updateContact', {
          channelId: this.row.channelId,
          contact: myContact,
        });
      }
    },
    updateUserPhone(event) {
      const myContact = {
        ...this.row,
        phones: [event.target.value],
      };

      if (event.target.value !== this.row.phones[0]) {
        this.$emit('updateContact', {
          channelId: this.row.channelId,
          contact: myContact,
        });
      }
    },
    updateUserWebsite(event) {
      const myContact = {
        ...this.row,
        websites: [event.target.value],
      };

      if (event.target.value !== this.row.websites[0]) {
        this.$emit('updateContact', {
          channelId: this.row.channelId,
          contact: myContact,
        });
      }
    },
    customManagement(item, type) {
      // check if custom is into contact or not
      // then emit addCustom or removeCustom
      if (type === 'contactGroup' && this.row.contactGroup
        .find(({ contactGroupId }) => contactGroupId === item.contactGroupId)) {
        this.$emit('removeCustom', { custom: item, row: this.row, type });
      }
      if (type === 'contactGroup' && !this.row.contactGroup
        .find(({ contactGroupId }) => contactGroupId === item.contactGroupId)) {
        this.$emit('addCustom', { custom: item, row: this.row, type });
      }
      if (type === 'threadGroup' && this.row.threadGroup
        .find(({ customTagId }) => customTagId === item.customTagId)) {
        this.$emit('removeCustom', { custom: item, row: this.row, type });
      }
      if (type === 'threadGroup' && !this.row.threadGroup
        .find(({ customTagId }) => customTagId === item.customTagId)) {
        this.$emit('addCustom', { custom: item, row: this.row, type });
      }
      if (type === 'campaign' && this.row.activeCampaign
        .find(({ campaignId }) => campaignId === item.campaignId)) {
        this.$emit('removeCustom', { custom: item, row: this.row, type });
      }
      if (type === 'campaign' && !this.row.activeCampaign
        .find(({ campaignId }) => campaignId === item.campaignId)) {
        this.$emit('addCustom', { custom: item, row: this.row, type });
      }
    },
    isCustomIntoContact(item, type) {
      if (type === 'threadGroup') {
        return this.row.threadGroup
          ? !!this.row.threadGroup.find(({ customTagId }) => customTagId === item.customTagId)
          : false;
      }
      if (type === 'contactGroup') {
        return this.row.contactGroup
          ? !!this.row.contactGroup
            .find(({ contactGroupId }) => contactGroupId === item.contactGroupId)
          : false;
      }
      if (type === 'campaign') {
        return this.row.activeCampaign
          ? !!this.row.activeCampaign
            .find(({ campaignId }) => campaignId === item.campaignId)
          : false;
      }

      return false;
    },
    sortListByActiveCustomFirst(list, customKey, customKeyId) {
      if (!this.row[customKey]) return [];
      const listWithoutDuplicate = list.reduce((acc, r) => {
        if (!this.row[customKey]
          .find((i) => i[customKeyId] === r[customKeyId])) {
          acc.push(r);
        }
        return acc;
      }, []);
      return this.row[customKey].concat(listWithoutDuplicate);
    },
    removeContactToLink(event) {
      this.$buefy.dialog.confirm({
        title: 'Deleting affiliation',
        message: 'Are you sure you want to <b>delete</b> this affiliation? This action cannot be undone.',
        confirmText: 'Delete affiliation',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.$emit('removeContactToLink', event),
      });
    },
    hardRefresh() {
      this.$emit('hardRefresh', {
        channelId: this.row.channelId,
        contactId: this.row.contactId,
      });
    },
    fetchTiktokIfMissing() {
      if (!this.row.tiktokUser && this.row.tiktok_username) this.$emit('fetchTiktokUser', { username: this.row.tiktok_username, contactId: this.row.contactId });
    },
  },

  // updateUserDetails() {
  //   const myContact = {
  //     ...this.row,
  //     emails: [...this.row.emails],
  //     phones: [...this.row.phones],
  //     websites: [...this.row.websites],
  //   };

  //   myContact.emails[0] = this.emailValue !== null ? this.emailValue : myContact.emails[0];
  //   myContact.phones[0] = this.phoneValue !== null ? this.phoneValue : myContact.phones[0];
  //   myContact.websites[0] = this.websiteValue !== null
  //     ? this.websiteValue : myContact.websites[0];

  //   if (this.emailValue !== null || this.phoneValue !== null || this.websiteValue !== null) {
  //     this.$emit('updateContact', {
  //       channelId: this.row.channelId,
  //       contact: myContact,
  //     });

  //     this.emailValue = null;
  //     this.phoneValue = null;
  //     this.websiteValue = null;
  //   }
  // },
};
</script>
