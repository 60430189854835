<template>
  <div
    v-if="isConnectedToFb"
    id="TheMentionsAnalyticsAccount"
  >
    <UbuAnalyticsDatepicker
      class="mb-5"
      @changeDate="fetchAll($event)"
    />

    <div
      class="flex mb-4"
    >
      <UbuButton
        class="UbuWorkspaceCustoms_button"
        secondary
        :loading="analyticsDownloading"
        @click="downloadAnalytics()"
      >
        Download Analytics
      </UbuButton>
    </div>
    <b-message
      v-if="messageNotConnected"
      type="is-danger"
    >
      <p class="ubu-text3">
        {{ messageNotConnected }}
      </p>
      <b-button
        v-if="showConnectButton"
        to="/workspace/connection"
        tag="router-link"
        type="is-danger"
        class="mt-2"
      >
        Connect
      </b-button>
    </b-message>

    <template v-if="reducedAnalyticsData">
      <div class="analyticsGroup">
        <h1 class="ubu-header2">Overview</h1>
        <div class="analyticsCardGroup">
          <UbuCommonAnalyticsCard
            v-for="(item, index) in reducedAnalyticsData.overview"
            :key="index"
            :value="item.value"
            :last-periodvalue="item.lastPeriodvalue"
            :title="item.name"
            :icon="item.icon"
            :color="item.color"
            :size="item.size"
            :loading="item.loading"
            :is-percent="item.isPercent"
            :growth-percent="item.growthPercent"
          />
          <UbuAnalyticsLoadingGraph v-if="isLoadingChart" />
          <UbuAnalyticsGraph
            v-else
            chart-type="lineSegment"
            chart-id="followers-chart"
            :labels="graphData.dates"
            :data="graphData.followers"
            title="Follower growth"
            is-followers
          />
          <UbuAnalyticsLoadingGraph v-if="isLoadingChart" />
          <UbuAnalyticsGraph
            v-else
            chart-type="lineSegment"
            chart-id="engagement-chart"
            :labels="graphData.dates"
            :data="graphData.engagements"
            title="Engagement rate"
          />
        </div>
      </div>

      <div class="analyticsGroup">
        <h1 class="ubu-header2">Followers</h1>
        <div class="analyticsCardGroup">
          <UbuCommonAnalyticsCard
            v-for="(item, index) in reducedAnalyticsData.followers"
            :key="index"
            :value="item.value"
            :last-periodvalue="item.lastPeriodvalue"
            :title="item.name"
            :icon="item.icon"
            :color="item.color"
            :size="item.size"
            :loading="item.loading"
            :is-percent="item.isPercent"
            :growth-percent="item.growthPercent"
          />
        </div>
      </div>

      <div class="analyticsGroup">
        <h1 class="ubu-header2">Engagement</h1>
        <div class="analyticsCardGroup">
          <UbuCommonAnalyticsCard
            v-for="(item, index) in reducedAnalyticsData.engagement"
            :key="index"
            :value="item.value"
            :last-periodvalue="item.lastPeriodvalue"
            :title="item.name"
            :icon="item.icon"
            :color="item.color"
            :size="item.size"
            :loading="item.loading"
            :is-percent="item.isPercent"
            :growth-percent="item.growthPercent"
          />
        </div>
      </div>

      <div
        v-if="isLoading || (reducedAnalyticsData.posts && reducedAnalyticsData.posts.length)"
        class="analyticsGroup"
      >
        <h1 class="ubu-header2">Posts</h1>
        <div
          v-if="isLoading"
          class="analyticsPostsGroup"
        >
          <UbuCommonPostCard
            v-for="index in 4"
            :key="index"
            loading
          />
        </div>
        <div
          v-else
          class="analyticsPostsGroup"
        >
          <UbuCommonPostCard
            v-for="(item, index) in reducedAnalyticsData.posts"
            :key="index"
            :media="item"
            @show="showPost(item)"
          />
        </div>
      </div>

      <div
        v-if="isLoading || (reducedAnalyticsData.stories && reducedAnalyticsData.stories.length)"
        class="analyticsGroup"
      >
        <h1 class="ubu-header2">Stories</h1>
        <div
          v-if="isLoading"
          class="analyticsPostsGroup"
        >
          <UbuCommonPostCard
            v-for="index in 5"
            :key="index"
            loading
            is-story
          />
        </div>
        <div
          v-else
          class="analyticsPostsGroup"
        >
          <UbuCommonPostCard
            v-for="(item, index) in reducedAnalyticsData.stories"
            :key="index"
            :media="item"
            is-story
            @show="showPost(item)"
          />
        </div>
      </div>

      <div class="analyticsGroup">
        <h1 class="ubu-header2">Visibility</h1>
        <div class="analyticsCardGroup">
          <UbuCommonAnalyticsCard
            v-for="(item, index) in reducedAnalyticsData.visibility"
            :key="index"
            :value="item.value"
            :last-periodvalue="item.lastPeriodvalue"
            :title="item.name"
            :icon="item.icon"
            :color="item.color"
            :size="item.size"
            :loading="item.loading"
            :is-percent="item.isPercent"
            :growth-percent="item.growthPercent"
          />
        </div>
      </div>

      <div class="analyticsGroup">
        <h1 class="ubu-header2">Call to actions</h1>
        <div class="analyticsCardGroup">
          <UbuCommonAnalyticsCard
            v-for="(item, index) in reducedAnalyticsData.callToAction"
            :key="index"
            :value="item.value"
            :last-periodvalue="item.lastPeriodvalue"
            :title="item.name"
            :icon="item.icon"
            :color="item.color"
            :size="item.size"
            :loading="item.loading"
            :is-percent="item.isPercent"
            :growth-percent="item.growthPercent"
          />
        </div>
      </div>
    </template>

    <b-modal
      v-model="isAnalyticsPostModalOpen"
      :width="840"
      scroll="keep"
    >
      <UbuAnalyticsPostModal
        :media="currentAnalyticsPost"
      />
    </b-modal>
  </div>

  <div
    v-else
    class="dashboard-error-content"
  >
    <p>We couldn't load your analytics, please check if your Facebook Channel is connected</p>
    <b-button
      to="/workspace/connection"
      class="dashboard-error-button ubuButton is-primary"
      tag="router-link"
    >
      Connection
    </b-button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { analyticsCatalog } from './$catalog/analytics';
import { formateMedias } from './$utils/analytics';

export default {
  name: 'TheMentionsAnalyticsAccount',
  data() {
    return {
      selectedRange: { type: 'simple', subtype: 'currentWeek', value: [this.$moment().subtract({ days: 6 }).toDate(), this.$moment().toDate()] },
      isLoading: true,
      analyticsAvailableSince: null,
      dayArray: null,
      firstPartGraphData: null,
      isLoadingChart: true,
      reducedAnalyticsData: null,
      graphData: [],
      currentAnalyticsPost: {},
      isAnalyticsPostModalOpen: false,
      analyticsDownloading: false,
    };
  },
  computed: {
    ...mapGetters({
      fetchIsDone: 'Sequence/getterFetchDone',
      getterFbChannel: 'Channel/getterFbChannel',
    }),
    isConnectedToFb() {
      return this.getterFbChannel && this.getterFbChannel.connected;
    },
    formatAnalyticsAvailableSinceToDate() {
      return this.analyticsAvailableSince
        ? this.$moment(this.analyticsAvailableSince).format('YYYY-MM-DD')
        : null;
    },
    messageNotConnected() {
      if (!this.dayArray) return null;
      const nbDaysConnected = this.dayArray.length;
      const [min, max] = this.selectedRange.value;
      const nbDaysPeriod = this.$moment(max).diff(this.$moment(min), 'days') + 1;
      const nbDaysMissing = nbDaysPeriod - nbDaysConnected;
      if (!nbDaysMissing) return null;
      if (!nbDaysConnected) return 'You were not connected during this period';
      return `${nbDaysMissing} day${nbDaysMissing > 1 ? 's' : ''} missing on this period (${nbDaysPeriod} days)`;
    },
    showConnectButton() {
      const [min, max] = this.selectedRange.value;
      return this.$moment(max).isSame(this.$moment(), 'day');
    },
  },
  watch: {
    fetchIsDone(isDone) {
      if (isDone && this.isConnectedToFb) {
        this.fetchAnalytics(this.selectedRange);
        this.fetchGraphAnalytics(this.selectedRange);
      }
    },
  },
  mounted() {
    this.fetchLoadingCatalog();
    if (this.fetchIsDone && this.isConnectedToFb) {
      this.fetchAnalytics(this.selectedRange);
      this.fetchGraphAnalytics(this.selectedRange);
    }
  },
  methods: {
    ...mapActions({
      loadAnalytics: 'TheMentions/fetchAccountAnalyticsFirst',
      loadGraphAnalytics: 'TheMentions/fetchGraphAnalytics',
      downloadAnalyticsXslsx: 'TheMentions/downloadAnalyticsXslsx',
    }),
    fetchAll(range) {
      this.selectedRange = range;
      this.fetchAnalytics(range);
      this.fetchGraphAnalytics(range);
    },
    fetchAnalytics(range) {
      this.fetchLoadingCatalog();
      this.isLoading = true;
      this.dayArray = null;
      this.selectedRange = range;

      this.loadAnalytics(range)
        .then((response) => {
          const {
            firstRangeDate,
            secondRangeDate,
            firstRangeDate: { dayArray },
          } = response;

          if (dayArray) {
            this.dayArray = dayArray;
          }

          this.reducedAnalyticsData = Object.entries(firstRangeDate.data)
            .reduce((acc, row) => {
              const [key, value] = row;

              if (key === 'medias' && value.length) {
                acc.medias.push(...value);
              }
              const valueSecondRange = secondRangeDate.data[key];

              if (analyticsCatalog[key] && analyticsCatalog[key].type === 'account') {
                const isOverview = analyticsCatalog[key].subtype.find(({ type: t }) => t === 'overview');
                const isFollowers = analyticsCatalog[key].subtype.find(({ type: t }) => t === 'followers');
                const isEngagement = analyticsCatalog[key].subtype.find(({ type: t }) => t === 'engagement');
                const isVisibility = analyticsCatalog[key].subtype.find(({ type: t }) => t === 'visibility');
                const isCallToAction = analyticsCatalog[key].subtype.find(({ type: t }) => t === 'callToAction');

                if (isOverview) {
                  acc.overview.push({
                    ...analyticsCatalog[key],
                    value,
                    size: isOverview.size,
                    ordinal: isOverview.ordinal,
                  });

                  acc.overview = acc.overview.sort((a, b) => a.ordinal - b.ordinal);
                }
                if (isFollowers) {
                  acc.followers.push({
                    ...analyticsCatalog[key],
                    value,
                    lastPeriodvalue: valueSecondRange,
                    size: isFollowers.size,
                    ordinal: isFollowers.ordinal,
                    growthPercent: valueSecondRange ? ((value - valueSecondRange)
                     / valueSecondRange) * 100 : 0,
                  });

                  acc.followers = acc.followers.sort((a, b) => a.ordinal - b.ordinal);
                }
                if (isEngagement) {
                  acc.engagement.push({
                    ...analyticsCatalog[key],
                    value,
                    lastPeriodvalue: valueSecondRange,
                    size: isEngagement.size,
                    ordinal: isEngagement.ordinal,
                    growthPercent: valueSecondRange ? ((value - valueSecondRange)
                     / valueSecondRange) * 100 : 0,
                  });

                  acc.engagement = acc.engagement.sort((a, b) => a.ordinal - b.ordinal);
                }
                if (isVisibility) {
                  acc.visibility.push({
                    ...analyticsCatalog[key],
                    value,
                    lastPeriodvalue: valueSecondRange,
                    size: isVisibility.size,
                    ordinal: isVisibility.ordinal,
                    growthPercent: valueSecondRange ? ((value - valueSecondRange)
                     / valueSecondRange) * 100 : 0,
                  });

                  acc.visibility = acc.visibility.sort((a, b) => a.ordinal - b.ordinal);
                }
                if (isCallToAction) {
                  acc.callToAction.push({
                    ...analyticsCatalog[key],
                    value,
                    lastPeriodvalue: valueSecondRange,
                    size: isCallToAction.size,
                    ordinal: isCallToAction.ordinal,
                    growthPercent: valueSecondRange ? ((value - valueSecondRange)
                     / valueSecondRange) * 100 : 0,
                  });

                  acc.callToAction = acc.callToAction.sort((a, b) => a.ordinal - b.ordinal);
                }
                return acc;
              }
              return acc;
            }, {
              overview: [],
              followers: [],
              engagement: [],
              visibility: [],
              callToAction: [],
              medias: [],
              posts: [],
              stories: [],
            });

          this.reducedAnalyticsData.medias = formateMedias(
            {
              medias: this.reducedAnalyticsData.medias,
              followerCount: firstRangeDate.data.final_followers,
            },
          );

          this.reducedAnalyticsData.posts = this.reducedAnalyticsData.medias
            .filter((m) => !m.isStory);
          this.reducedAnalyticsData.stories = this.reducedAnalyticsData.medias
            .filter((m) => m.isStory);

          this.firstPartGraphData = response;
          this.isLoading = false;
        });
    },
    fetchLoadingCatalog() {
      this.reducedAnalyticsData = Object.values(analyticsCatalog).reduce((acc, stat) => {
        if (stat.type === 'account') {
          if (stat.subtype.find(({ type: t }) => t === 'overview')) {
            const currentRowSubtypeFromCatalog = stat.subtype.find(({ type: t }) => t === 'overview');

            acc.overview.push({
              ...stat,
              size: currentRowSubtypeFromCatalog.size,
              ordinal: currentRowSubtypeFromCatalog.ordinal,
              loading: true,
            });

            acc.overview = acc.overview.sort((a, b) => a.ordinal - b.ordinal);
          }
          if (stat.subtype.find(({ type: t }) => t === 'followers')) {
            const currentRowSubtypeFromCatalog = stat.subtype.find(({ type: t }) => t === 'followers');

            acc.followers.push({
              ...stat,
              size: currentRowSubtypeFromCatalog.size,
              ordinal: currentRowSubtypeFromCatalog.ordinal,
              loading: true,
            });

            acc.followers = acc.followers.sort((a, b) => a.ordinal - b.ordinal);
          }
          if (stat.subtype.find(({ type: t }) => t === 'engagement')) {
            const currentRowSubtypeFromCatalog = stat.subtype.find(({ type: t }) => t === 'engagement');

            acc.engagement.push({
              ...stat,
              size: currentRowSubtypeFromCatalog.size,
              ordinal: currentRowSubtypeFromCatalog.ordinal,
              loading: true,
            });

            acc.engagement = acc.engagement.sort((a, b) => a.ordinal - b.ordinal);
          }
          if (stat.subtype.find(({ type: t }) => t === 'visibility')) {
            const currentRowSubtypeFromCatalog = stat.subtype.find(({ type: t }) => t === 'visibility');

            acc.visibility.push({
              ...stat,
              size: currentRowSubtypeFromCatalog.size,
              ordinal: currentRowSubtypeFromCatalog.ordinal,
              loading: true,
            });

            acc.visibility = acc.visibility.sort((a, b) => a.ordinal - b.ordinal);
          }
          if (stat.subtype.find(({ type: t }) => t === 'callToAction')) {
            const currentRowSubtypeFromCatalog = stat.subtype.find(({ type: t }) => t === 'callToAction');

            acc.callToAction.push({
              ...stat,
              size: currentRowSubtypeFromCatalog.size,
              ordinal: currentRowSubtypeFromCatalog.ordinal,
              loading: true,
            });

            acc.callToAction = acc.callToAction.sort((a, b) => a.ordinal - b.ordinal);
          }
          return acc;
        }
        return acc;
      }, {
        overview: [],
        followers: [],
        engagement: [],
        visibility: [],
        callToAction: [],
      });
    },
    fetchGraphAnalytics(range) {
      this.isLoadingChart = true;

      this.loadGraphAnalytics(range)
        .then((response) => {
          const dates = response.dates.reduce((acc, row) => {
            const date = this.$moment(row).format('YYYY-MM-DD');
            acc.push(date);
            return acc;
          }, []);

          const followers = response.followers.reduce((acc, row) => {
            if (!row) {
              acc.push(null);
              return acc;
            }
            const follower = Math.round(row);
            acc.push(follower);
            return acc;
          }, []);

          this.graphData = { ...response, dates, followers };
          this.isLoadingChart = false;
        });
    },
    downloadAnalytics() {
      const [minDate, maxDate] = this.selectedRange.value;
      this.analyticsDownloading = true;
      this.$buefy.snackbar.open({
        message: 'Your analytics are downloading',
        type: 'is-success',
        position: 'is-bottom-right',
      });
      this.downloadAnalyticsXslsx({
        payload: {
          minDate: this.$moment(minDate).format('YYYY-MM-DD'),
          maxDate: this.$moment(maxDate).format('YYYY-MM-DD'),
        },
      }).then(() => {
        this.analyticsDownloading = false;
      }).catch(() => {
        this.analyticsDownloading = false;
      });
    },
    showPost(post) {
      this.currentAnalyticsPost = post;
      this.isAnalyticsPostModalOpen = true;
    },
  },
};
</script>

<style lang="scss">
#TheMentionsAnalyticsAccount {
  & .dashboard-graph {
    // position: relative;
    // & .loading-background {
    //   background-color: transparent;
    // }
    & .chart {
      position: relative;
    }
  }
}

</style>
