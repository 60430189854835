import ErrorHandler from '../../$utils/errors';
import { arrayMapper } from '../../$utils/prototypes';
import {
  updateDatasIfExists,
  updateRelationsIfExists,
  removeManyRelationsIfExists,
  updateObjectRelationIfExists,
  addNewKeysToMap,
  removeKeyToMap,
  mapList,
} from '../$utils/dataMapper';
import internals from './internals';
import noteTemplate from '../template/note.template';
import contactTemplate from '../template/contact.template';
import { tryParseJson } from '../../$utils/formate';

function mapThreads({ commit }, { responses }) {
  const [validResponses] = responses
    .filter(({ response }) => response)
    .reduce((acc, { response }) => { acc.push(response); return acc; }, []);

  if (!validResponses || !validResponses.threadList) { return undefined; }

  // console.log('🚀 ~ file: Thread.store.js ~ line 17 ~ mapThreads ~ validResponses', validResponses);
  const {
    threadList,
    channelsHasThreads,
    mappedContactList,
    channelsHasContacts,
    // threadsHasContacts,
    mappedMessageList,
    // threadsHasMessages,
    mappedNoteList,
    // threadsHasNotes,
    channelsHasThreadsBis,
    channelsHasContactsBis,
    contactsHasMessagesBis,
    contactsHasNotesBis,
    contactsHasThreadsBis,
    threadsHasContactsBis,
    threadsHasMessagesBis,
    threadsHasNotesBis,
  } = validResponses;

  commit('Thread/SET_THREADS', threadList, { root: true });
  // eslint-disable-next-line max-len
  // commit('Thread/SET_THREADS', Object.values(threadList).reduce((acc, thread) => { acc[thread.threadId] = { ...thread, closed: thread.closed || false }; return acc; }, {}), { root: true });
  commit('Channel/SET_CHANNELS_HAS_THREADS', channelsHasThreads, { root: true });
  commit('Contact/SET_CONTACTS', mappedContactList, { root: true });
  commit('Channel/SET_CHANNELS_HAS_CONTACTS', channelsHasContacts, { root: true });
  // commit('Thread/SET_THREADS_HAS_CONTACTS', threadsHasContacts, { root: true });
  commit('Message/SET_MESSAGES', mappedMessageList, { root: true });
  commit('Note/SET_NOTES', mappedNoteList, { root: true });
  commit('Channel/SET_CHANNELS_HAS_CONTACTS_BIS', channelsHasContactsBis, { root: true });
  commit('Channel/SET_CHANNELS_HAS_THREADS_BIS', channelsHasThreadsBis, { root: true });
  commit('Contact/SET_CONTACTS_HAS_MESSAGES_BIS', contactsHasMessagesBis, { root: true });
  commit('Contact/SET_CONTACTS_HAS_NOTES_BIS', contactsHasNotesBis, { root: true });
  commit('Contact/SET_CONTACT_HAS_THREADS_BIS', contactsHasThreadsBis, { root: true });
  commit('Thread/SET_THREADS_HAS_CONTACTS_BIS', threadsHasContactsBis, { root: true });
  commit('Thread/SET_THREADS_HAS_MESSAGES_BIS', threadsHasMessagesBis, { root: true });
  commit('Thread/SET_THREADS_HAS_NOTES_BIS', threadsHasNotesBis, { root: true });
  return undefined;
}

/* eslint-disable max-len */
export default {
  namespaced: true,

  state: {
    threads: {},
    threadsHasContactsBis: {},
    threadsHasMessagesBis: {},
    threadsHasThreadGroupsBis: {},
    threadsHasUbuUsers: {},
    threadsHasNotesBis: {},
    threadsHasContacts: [],
    threadsHasMessages: [],
    threadsHasNotes: [],
    threadsHasThreadGroup: [],
    threadsHasContactGroup: [],
    threadsHasMedias: [],
    closedThreadsLoaded: false,
  },

  getters: {
    getterThreads: (state) => state.threads,
    getterThreadsHasContacts: (state) => state.threadsHasContacts,
    getterThreadsHasMessages: (state) => state.threadsHasMessages,
    getterThreadsHasNotes: (state) => state.threadsHasNotes,
    getterThreadsHasThreadGroup: (state) => state.threadsHasThreadGroup,
    getterThreadsHasContactGroup: (state) => state.threadsHasContactGroup,
    getterThreadsHasUbuUsers: (state) => state.threadsHasUbuUsers,
    getterThreadsHasMedias: (state) => state.threadsHasMedias,
    getterClosedThreadsLoaded: (state) => state.closedThreadsLoaded,
    getterThreadsHasContactsBis: (state) => state.threadsHasContactsBis,
    getterThreadsHasMessagesBis: (state) => state.threadsHasMessagesBis,
    getterThreadsHasNotesBis: (state) => state.threadsHasNotesBis,
    getterThreadsHasThreadGroupsBis: (state) => state.threadsHasThreadGroupsBis,
  },

  mutations: {
    SET_THREADS(state, newDatas) {
      state.threads = updateDatasIfExists({
        newDatas, actualDatas: state.threads, key: 'threadId', template: { closed: false },
      });
    },
    // SET_THREADS_HAS_CONTACTS(state, newDatas) { state.threadsHasContacts = updateRelationsIfExists(state.threadsHasContacts, newDatas); },
    // SET_THREADS_HAS_MESSAGES(state, newDatas) { state.threadsHasMessages = updateRelationsIfExists(state.threadsHasMessages, newDatas); },

    // UNSET_THREADS_HAS_MESSAGES(state, { messageId }) { state.threadsHasMessages.splice(state.threadsHasMessages.findIndex(({ messageId: _messageId }) => _messageId === messageId), 1); },
    // SET_THREADS_HAS_NOTES(state, newDatas) { state.threadsHasNotes = updateRelationsIfExists(state.threadsHasNotes, newDatas); },
    // SET_THREADS_HAS_THREAD_GROUP(state, newDatas) { state.threadsHasThreadGroup = updateRelationsIfExists(state.threadsHasThreadGroup, newDatas); },
    SET_THREADS_HAS_MEDIAS(state, newDatas) { state.threadsHasMedias = updateRelationsIfExists(state.threadsHasMedias, newDatas); },

    SET_THREADS_HAS_CONTACTS_BIS(state, newDatas) { state.threadsHasContactsBis = updateObjectRelationIfExists({ newDatas, actualDatas: state.threadsHasContactsBis }); },

    // Messages
    SET_THREADS_HAS_MESSAGES_BIS(state, newDatas) { state.threadsHasMessagesBis = updateObjectRelationIfExists({ newDatas, actualDatas: state.threadsHasMessagesBis }); },
    ADD_MESSAGES_TO_THREAD(state, { threadId, messageIds }) { state.threadsHasMessagesBis = addNewKeysToMap({ map: state.threadsHasMessagesBis, key: threadId, values: messageIds }); },
    REMOVE_MESSAGE_TO_THREAD(state, { threadId, messageId }) { state.threadsHasMessagesBis = removeKeyToMap({ map: state.threadsHasMessagesBis, key: threadId, value: messageId }); },

    // Notes
    SET_THREADS_HAS_NOTES_BIS(state, newDatas) { state.threadsHasNotesBis = updateObjectRelationIfExists({ newDatas, actualDatas: state.threadsHasNotesBis }); },
    ADD_NOTES_TO_THREAD(state, { threadId, noteIds }) { state.threadsHasNotesBis = addNewKeysToMap({ map: state.threadsHasNotesBis, key: threadId, values: noteIds }); },

    // ThreadGroup
    SET_THREADS_HAS_THREAD_GROUPS_BIS(state, newDatas) { state.threadsHasThreadGroupsBis = updateObjectRelationIfExists({ newDatas, actualDatas: state.threadsHasThreadGroupsBis }); },
    ADD_THREAD_GROUP_TO_THREAD(state, { threadId, threadGroupIds }) { state.threadsHasThreadGroupsBis = addNewKeysToMap({ map: state.threadsHasThreadGroupsBis, key: threadId, values: threadGroupIds }); },
    REMOVE_THREAD_GROUP_TO_THREAD(state, { threadId, threadGroupId }) { state.threadsHasThreadGroupsBis = removeKeyToMap({ map: state.threadsHasThreadGroupsBis, key: threadId, value: threadGroupId }); },

    // Assignments
    SET_THREADS_HAS_UBUUSERS(state, newDatas) { state.threadsHasUbuUsers = updateObjectRelationIfExists({ newDatas, actualDatas: state.threadsHasUbuUsers }); },
    ADD_UBUUSERS_TO_THREAD(state, { threadId, ubuUserIds }) { state.threadsHasUbuUsers = addNewKeysToMap({ map: state.threadsHasUbuUsers, key: threadId, values: ubuUserIds }); },
    REMOVE_UBUUSER_TO_THREAD(state, { threadId, ubuUserId }) { removeKeyToMap({ map: state.threadsHasUbuUsers, key: threadId, value: ubuUserId }); },

    UPDATE_LAST_PERMANENT_ON_THREAD(state, { threadId, newDatas }) { state.threads[threadId] = { ...state.threads[threadId], last_permanent_item: newDatas /* lsa: Number(newDatas.timestamp) */ }; },
    REMOVE_LAST_TTS_OF_THREADS(state, { threadIds }) {
      threadIds.forEach((threadId) => {
        const thread = state.threads[threadId];
        const { last_permanent_item: lastPermanentItem } = thread;
        state.threads[threadId] = { ...thread, last_permanent_item: { ...lastPermanentItem, lastTts: null } };
      });
    },
    UNSET_THREADS_HAS_THREAD_GROUP(state, ids) { state.threadsHasThreadGroup = removeManyRelationsIfExists(state.threadsHasThreadGroup, ids); },
    UNSET_THREAD_GROUP_FROM_THREAD(state, { threadIdToRemove, threadGroupIdToRemove }) { state.threadsHasThreadGroup.splice(state.threadsHasThreadGroup.findIndex(({ threadId, threadGroupId }) => threadId === threadIdToRemove && threadGroupId === threadGroupIdToRemove), 1); },
    SET_CLOSED_THREADS_LOADED(state) { state.closedThreadsLoaded = true; },
    CLOSE_THREAD(state, { threadId, isClosed }) { state.threads[threadId].closed = isClosed; },
    CLOSE_THREADS(state, { threadIds, isClosed }) {
      threadIds.forEach((threadId) => {
        state.threads[threadId].closed = isClosed;
      });
    },
    DONE_THREAD(state, { threadId, threadGroupId, timestamp }) {
      // @TODO ajouter le reverse remove sur threadGroupsHasThreadsBis
      state.threadsHasThreadGroupsBis = removeKeyToMap({ map: state.threadsHasThreadGroupsBis, key: threadId, value: threadGroupId });
      state.threads[threadId].closed = true;
      state.threads[threadId].lsa = timestamp;
    },
    READ_THREAD(state, { threadId, timestamp }) { state.threads[threadId].lsa = timestamp; },
    READ_AND_CLOSE_THREAD(state, { threadId, timestamp, isClosed }) {
      state.threads[threadId].lsa = timestamp;
      state.threads[threadId].closed = isClosed;
    },
    MUTE_THREAD(state, { threadId, isMuted }) { state.threads[threadId].ubu_muted = isMuted; },
    RESET_STORES(state) {
      state.threads = {};
      state.threadsHasContactsBis = {};
      state.threadsHasMessagesBis = {};
      state.threadsHasThreadGroupsBis = {};
      state.threadsHasNotesBis = {};
      state.threadsHasContacts = [];
      state.threadsHasMessages = [];
      state.threadsHasNotes = [];
      state.threadsHasThreadGroup = [];
      state.threadsHasContactGroup = [];
      state.threadsHasMedias = [];
    },
  },

  actions: {
    fetchThreadsByChannel({ commit, dispatch }) {
      console.log('🚀 fetchThreadsByChannel');
      return Promise.resolve()
        .then(() => Promise.all(internals.channelsIds.map((channelId) => internals.fetchThreadsByChannel(channelId))))
        .then((responses) => Promise.all(responses.map((r) => ErrorHandler.generic({ dispatch }, r))))
        .then((responses) => mapThreads({ commit }, { responses }));
    },
    fetchThreadsPendingByChannel({ commit, dispatch }) {
      console.log('🚀 fetchThreadsPendingByChannel');
      return Promise.resolve()
        .then(() => Promise.all(internals.channelsIds.map((channelId) => internals.fetchThreadsPendingByChannel(channelId))))
        .then((responses) => Promise.all(responses.map((r) => ErrorHandler.generic({ dispatch }, r))))
        .then((responses) => mapThreads({ commit }, { responses }));
    },
    fetchThreadsDbByChannel({ commit }) {
      console.log('🚀 fetchThreadsDbByChannel');
      return Promise.resolve()
        .then(() => Promise.all(internals.channelsIds.map((channelId) => internals.fetchThreadsDbByChannel(channelId))))
        .then((responses) => mapThreads({ commit }, { responses }))
        .then(() => commit('Sequence/SET_FETCH_THREAD_DB', true, { root: true }));
    },
    fetchThreadsDBLabeledByChannel({ commit }) {
      console.log('🚀 fetchThreadsDBLabeledByChannel');
      return Promise.resolve()
        .then(() => Promise.all(internals.channelsIds.map((channelId) => internals.fetchThreadsDBLabeledByChannel(channelId))))
        .then((responses) => mapThreads({ commit }, { responses }))
        .then(() => commit('Sequence/SET_FETCH_THREAD_DB_LABELED', true, { root: true }));
    },
    fetchThreadsDBTagguedByChannel({ commit }) {
      return Promise.resolve()
        .then(() => Promise.all(internals.channelsIds.map((channelId) => internals.fetchThreadsDBTagguedByChannel(channelId))))
        .then((responses) => mapThreads({ commit }, { responses }))
        .then(() => commit('Sequence/SET_FETCH_THREAD_DB_TAGGUED', true, { root: true }));
    },
    fetchThreadsDbClosedByChannel({ commit }) {
      console.log('🚀 fetchThreadsDbClosedByChannel');
      return Promise.resolve()
        .then(() => Promise.all(internals.channelsIds.map((channelId) => internals.fetchThreadsDbClosedByChannel(channelId))))
        .then((responses) => mapThreads({ commit }, { responses }))
        .then(() => commit('SET_CLOSED_THREADS_LOADED'))
        .then(() => commit('Sequence/SET_FETCH_THREAD_DB_CLOSED', true, { root: true }));
    },
    fetchThreadsInfluenceDbByChannel({ commit }) {
      console.log('🚀 fetchThreadsInfluenceDbByChannel');
      return Promise.resolve()
        .then(() => Promise.all(internals.channelsIds.map((channelId) => internals.fetchThreadsInfluenceDbByChannel(channelId))))
        .then((responses) => mapThreads({ commit }, { responses }));
    },
    fetchOnThreadDbByChannel({ commit }, threadId) {
      console.log('🚀 fetchOnThreadDbByChannel');
      return Promise.resolve()
        .then(() => Promise.all(internals.channelsIds.map((channelId) => internals.fetchOnThreadDbByChannel(channelId, threadId))))
        .then((responses) => mapThreads({ commit }, { responses }));
    },

    fetchDisplayablesFromContact({ commit }, { channelId, contactId }) {
      return Promise.resolve()
        .then(() => internals.fetchDisplayablesFromContact(channelId, contactId))
        .then(({ response: displayables, error }) => {
          if (error) {
            console.warn('🚀 ~ file: Thread.store.js ~ line 246 ~ .then ~ error', error);
            return;
          }

          const {
            notes,
            comments,
            items,
            thread: { thread_id: threadId },
            user,
            contact: extraDataContact,
          } = displayables;

          const contact = { [user.pk]: { ...contactTemplate({ ...user, ...extraDataContact, channelId }) } };
          commit('Contact/SET_CONTACTS', contact, { root: true });

          const mappedMessageList = arrayMapper(items.map((m) => ({
            ...m,
            ...tryParseJson(m.item),
            messageId: m.item_id,
            itemId: m.item_id,
            channelId,
            contactId,
          })), 'messageId');

          commit('Message/SET_MESSAGES', mappedMessageList, { root: true });

          const threadsHasMessages = Object.values(mappedMessageList).map(({ messageId }) => ({ messageId, threadId }));
          commit('SET_THREADS_HAS_MESSAGES', threadsHasMessages);

          const contactHasMessages = Object.values(threadsHasMessages).map(({ messageId }) => ({ messageId, contactId }));
          commit('Contact/SET_CONTACTS_HAS_MESSAGES', contactHasMessages, { root: true });

          const noteList = notes.map((note) => noteTemplate(note, threadId));

          const mappedNoteList = arrayMapper(noteList, 'noteId');
          commit('Note/SET_NOTES', mappedNoteList, { root: true });

          const threadsHasNotes = Object.values(mappedNoteList).map(({ noteId }) => ({ noteId, threadId }));
          commit('SET_THREADS_HAS_NOTES', threadsHasNotes);

          const contactHasNotes = Object.values(mappedNoteList).map(({ noteId }) => ({ noteId, contactId }));
          commit('Contact/SET_CONTACTS_HAS_NOTES', contactHasNotes, { root: true });

          const gossipList = comments.map((g) => ({
            ...g, gossipId: g.id, channelId, contactId,
          }));

          const mappedGossipList = arrayMapper(gossipList, 'gossipId');
          commit('Gossip/SET_GOSSIPS', mappedGossipList, { root: true });

          const contactHasGossips = Object.values(mappedGossipList).map(({ gossipId }) => ({ gossipId, contactId }));
          commit('Contact/SET_CONTACTS_HAS_GOSSIPS', contactHasGossips, { root: true });
        });
    },
    SOCKET_newThread({ commit, rootGetters }, { channelId, threads }) {
      const {
        'TheInbox/getterCurrentThread': theInboxCurrentThread,
      } = rootGetters;

      return Promise.resolve()
        .then(() => {
          const threadsByChannelsList = threads.map((thread) => ({ ...thread, channelId, threadId: thread.thread_id }));
          const threadListUnfiltered = arrayMapper(threadsByChannelsList, 'threadId');

          const {
            contacts: contactList,
            messages: messageList,
            notes: noteList,
            ...threadList
          } = Object.entries(threadListUnfiltered).reduce((acc, [threadId, threadDatas]) => {
            const {
              correspondent,
              inviter,
              items,
              notes,
              last_permanent_item: lastPermanentItem,
              ...restThread
            } = threadDatas;
            const lastPerm = {
              ...tryParseJson(lastPermanentItem.item),
              ...lastPermanentItem,
            };

            let lastTts = null;
            if (theInboxCurrentThread && theInboxCurrentThread.threadId === threadId) {
              const oldLastPerm = theInboxCurrentThread && theInboxCurrentThread.last_permanent_item;
              lastTts = oldLastPerm && (oldLastPerm.lastTts || +(oldLastPerm.timestamp));
              console.log('Last tts from socket', theInboxCurrentThread, oldLastPerm, lastTts);
              lastPerm.lastTts = lastTts;
            }
            acc[threadId] = {
              ...restThread,
              last_permanent_item: lastPerm,
              contactId: correspondent ? correspondent.pk : null,
              threadId,
            };
            acc.contacts.push({
              ...correspondent,
              contactId: correspondent ? correspondent.pk : null,
              threadId,
            });
            items.forEach((item) => {
              acc.messages.push({
                ...item,
                ...tryParseJson(item.item),
                messageId: item.item_id,
                contactId: item.user_id,
                threadId,
              });
            });
            notes.forEach((note) => {
              acc.notes.push(noteTemplate(note, threadId));
            });
            return acc;
          }, {
            contacts: [],
            messages: [],
            notes: [],
          });

          commit('SET_THREADS', threadList);

          const channelsHasThreads = Object.values(threadListUnfiltered).map(({ threadId }) => ({ channelId, threadId }));
          commit('Channel/SET_CHANNELS_HAS_THREADS', channelsHasThreads, { root: true });

          const mappedContactList = arrayMapper(contactList, 'contactId');
          commit('Contact/SET_CONTACTS', mappedContactList, { root: true });

          const channelsHasContacts = Object.values(mappedContactList).map(({ contactId }) => ({ contactId, channelId }));
          commit('Channel/SET_CHANNELS_HAS_THREADS', channelsHasContacts, { root: true });

          const threadsHasContacts = Object.values(mappedContactList).map(({ contactId, threadId }) => ({ contactId, threadId }));
          // commit('SET_THREADS_HAS_CONTACTS', threadsHasContacts);

          const mappedMessageList = arrayMapper(messageList, 'messageId');
          commit('Message/SET_MESSAGES', mappedMessageList, { root: true });

          const threadsHasMessages = Object.values(mappedMessageList).map(({ messageId, threadId }) => ({ messageId, threadId }));
          // commit('SET_THREADS_HAS_MESSAGES', threadsHasMessages);

          const mappedNoteList = arrayMapper(noteList, 'noteId');
          commit('Note/SET_NOTES', mappedNoteList, { root: true });

          const threadsHasNotes = Object.values(mappedNoteList).map(({ noteId, threadId }) => ({ noteId, threadId }));
          // commit('SET_THREADS_HAS_NOTES', threadsHasNotes);

          // commit('Channel/SET_CHANNELS_HAS_CONTACTS_BIS', channelsHasContactsBis, { root: true });
          // commit('Contact/SET_CONTACTS_HAS_MESSAGES_BIS', contactsHasMessagesBis, { root: true });
          // commit('Contact/SET_CONTACTS_HAS_NOTES_BIS', contactsHasNotesBis, { root: true });

          commit('Channel/SET_CHANNELS_HAS_THREADS_BIS', mapList({ list: channelsHasThreads, key: 'channelId', value: 'threadId' }), { root: true });
          commit('Channel/SET_CHANNELS_HAS_CONTACTS_BIS', mapList({ list: channelsHasContacts, key: 'channelId', value: 'contactId' }), { root: true });
          commit('Contact/SET_CONTACT_HAS_THREADS_BIS', mapList({ list: threadsHasContacts, key: 'threadId', value: 'contactId' }), { root: true });
          commit('Thread/SET_THREADS_HAS_CONTACTS_BIS', mapList({ list: threadsHasContacts, key: 'contactId', value: 'threadId' }), { root: true });
          commit('Thread/SET_THREADS_HAS_MESSAGES_BIS', mapList({ list: threadsHasMessages, key: 'threadId', value: 'messageId' }), { root: true });
          commit('Thread/SET_THREADS_HAS_NOTES_BIS', mapList({ list: threadsHasNotes, key: 'threadId', value: 'noteId' }), { root: true });
        });
    },
    SOCKET_toggleCloseThread({ commit }, { threadId, closed, channelId }) {
      commit('CLOSE_THREAD', { threadId, channelId, isClosed: closed });
    },
  },
};
