var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"UbuInfluenceHomePage_wrapper"},[_c('header',{staticClass:"UbuInfluenceHomePage_header multibar"},[_c('h1',{staticClass:"ubu-title start"},[_vm._v("Campaigns")]),_c('b-button',{staticClass:"is-rounded is-light end",on:{"click":function($event){_vm.isCardModalActive = !_vm.isCardModalActive}}},[_c('UbuIcon',{attrs:{"size":"16px","name":"add","filled":""}}),_c('span',[_vm._v(" New campaign ")])],1)],1),_c('section',[_c('h2',{staticClass:"UbuInfluenceHomePage_section-title"},[_vm._v("Live campaigns 🚀")]),_c('br'),_c('b-table',{staticClass:"table-no-head ubuBTable",attrs:{"data":_vm.liveCampaigns,"paginated":"","per-page":"10","aria-next-label":"Next page","aria-previous-label":"Previous page","aria-page-label":"Page","aria-current-label":"Current page","pagination-rounded":"","pagination-size":"is-small"}},[_c('b-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var campaign = ref.row;
return [_c('div',{staticClass:"multibar"},[_c('UbuIcon',{staticClass:"UbuInfluenceHomePage_picture a-self-center mr-2 start",style:(("color: " + (campaign.color))),attrs:{"name":"influence","filled":"","size":"25px"}}),_c('div',{staticClass:"multibar column start"},[_c('div',{staticClass:"ubu-text1"},[_vm._v(_vm._s(campaign.name))]),_c('div',{staticClass:"flex ubu-subtext1 text-cloudburst60"},[_c('div',[_vm._v(_vm._s(_vm.$moment(campaign.created).format('YYYY-MM-DD')))])])])],1)]}}])}),_c('b-table-column',{attrs:{"numeric":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var campaign = ref.row;
return [_c('div',{staticClass:"multibar"},[_c('b-button',{staticClass:"button ubuButton is-rounded is-light end",attrs:{"tag":"router-link","to":("/influence/" + (campaign.campaignId))}},[_vm._v(" See campaign ")]),_c('UbuButton',{staticClass:"a-self-center m-1 end",attrs:{"color":"2"},on:{"click":function($event){return _vm.endCampaignModal(campaign)}}},[_vm._v(" End ")])],1)]}}])})],1)],1),_c('section',[_c('h2',{staticClass:"UbuInfluenceHomePage_section-title"},[_vm._v("Ended campaigns ⏳")]),_c('br'),_c('b-table',{staticClass:"table-no-head ubuBTable",attrs:{"data":_vm.endedCampaigns,"paginated":"","per-page":"5","aria-next-label":"Next page","aria-previous-label":"Previous page","aria-page-label":"Page","aria-current-label":"Current page","pagination-rounded":"","pagination-size":"is-small"}},[_c('b-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var campaign = ref.row;
return [_c('div',{staticClass:"multibar"},[_c('UbuIcon',{staticClass:"UbuInfluenceHomePage_picture a-self-center mr-2 start",style:(("color: " + (campaign.color))),attrs:{"name":"influence","filled":"","size":"25px"}}),_c('div',{staticClass:"multibar column start"},[_c('div',{staticClass:"ubu-text1"},[_vm._v(_vm._s(campaign.name))]),_c('div',{staticClass:"flex ubu-subtext1 text-cloudburst60"},[_c('div',[_vm._v(" "+_vm._s(_vm.$moment(campaign.created).format('YYYY-MM-DD'))+" - "+_vm._s(_vm.$moment(campaign.updated).format('YYYY-MM-DD'))+" ")])])])],1)]}}])}),_c('b-table-column',{attrs:{"numeric":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var campaign = ref.row;
return [_c('div',{staticClass:"multibar"},[_c('b-button',{staticClass:"button ubuButton is-rounded is-light end",attrs:{"tag":"router-link","to":("/influence/" + (campaign.campaignId))}},[_vm._v(" See campaign ")])],1)]}}])})],1)],1),_c('b-modal',{attrs:{"width":640,"scroll":"keep"},model:{value:(_vm.isCardModalActive),callback:function ($$v) {_vm.isCardModalActive=$$v},expression:"isCardModalActive"}},[_c('UbuInfluenceModalCreateCampaign',{attrs:{"is-card-modal-active":_vm.isCardModalActive,"new-campaign":_vm.newCampaign},on:{"close":function($event){_vm.isCardModalActive = false},"selectColor":function($event){return _vm.selectColor($event)},"addFromModal":function($event){return _vm.addFromModal($event)}}})],1),_c('b-modal',{attrs:{"width":640,"scroll":"keep"},model:{value:(_vm.isEndedModalActive),callback:function ($$v) {_vm.isEndedModalActive=$$v},expression:"isEndedModalActive"}},[_c('UbuCommonModal',{attrs:{"title":"End campaign ?"},on:{"close":function($event){_vm.isEndedModalActive = false}}},[_c('template',{slot:"info"},[_c('p',[_vm._v(" Are you sure you want to end "),_c('strong',[_vm._v(" "+_vm._s(_vm.endCampaignTitle)+" ")]),_vm._v(" ? You won’t be able to undo this action. ")])]),_c('template',{slot:"actions"},[_c('UbuButton',{staticClass:"m-2",attrs:{"grow":"","center":""},on:{"click":function($event){_vm.isEndedModalActive = false}}},[_vm._v(" Cancel ")]),_c('UbuButton',{staticClass:"m-2",attrs:{"grow":"","center":"","secondary":""},on:{"click":function($event){return _vm.closeCampaign()}}},[_vm._v(" End campaign ")])],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }