<template>
  <UbuCommonMessageCard
    :is-me="isMe"
    :date="date"
    is-media
    @deleteMessage="$emit('deleteMessage')"
  >
    <template v-slot:image>
      <UbuCommonVideo
        v-if="hasVideo"
        :video-url="videoUrl"
        :image-url="`${videoUrl}/thumbnail`"
        :has-bucket="hasBucket"
      />
      <UbuCommonImage
        v-else
        :url="imageUrl"
        :has-bucket="hasBucket"
      />
    </template>

    <template
      v-if="text && username"
      v-slot:content
    >
      <div class="card-content-text">
        <UbuInstaName
          class="ubu-instalink-msg"
          :username="username"
        />
        <p class="ubuTextElipsed">
          {{ text }}
        </p>
      </div>
    </template>
  </UbuCommonMessageCard>
</template>

<script>
import MessageCardMixin from '../$mixins/MessageCardMixin';

export default {
  name: 'UbuCommonClip',
  mixins: [MessageCardMixin],
  // props: {
  //   imageUrl: {
  //     type: String,
  //     required: true,
  //   },
  //   videoUrl: {
  //     type: String,
  //     required: true,
  //   },
  //   date: {
  //     type: String,
  //     required: true,
  //   },
  //   username: {
  //     type: String,
  //     required: true,
  //   },
  //   isMe: {
  //     type: Boolean,
  //     default: false,
  //   },
  //   hasBucket: {
  //     type: Boolean,
  //     default: false,
  //   },
  //   hasVideo: {
  //     type: Boolean,
  //     default: false,
  //   },
  // },
};
</script>
