<template>
  <section id="TheMentionsAnalyticsShopify">
    <!-- <b-loading
      v-model="isLoading"
      :is-full-page="true"
      :can-cancel="true"
    /> -->
    <div v-if="getterShopifyShop.length">
      <UbuAnalyticsDatepicker
        class="mb-5"
        @changeDate="setCurrentDate($event)"
      />

      <b-message
        v-if="showDateWarning"
        type="is-danger"
      >
        <p class="ubu-text3">
          We started to record shopify orders since 1st November 2021
        </p>
      </b-message>

      <div class="dashboard-box pt-4">
        <div
          v-for="shop in getterShopifyShop"
          :key="shop.shopifyShopSsid"
        >
          <h1 class="ubu-header2">{{ shop.shopExtrefId }}</h1>

          <template v-if="prepareShopifyAnalytics(shop)">
            <div class="analyticsGroup">
              <div class="analyticsCardGroup">
                <UbuCommonAnalyticsCard
                  v-for="(item, index) in prepareShopifyAnalytics(shop)"
                  :key="index"
                  :value="item.value"
                  :title="item.name"
                  :icon="item.icon"
                  :color="item.color"
                  :size="item.size"
                  :is-dollar="item.isDollar"
                  :loading="item.loading"
                />
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div v-else-if="!getterShopifyShop.length && !fetchIsDone">
      <h1 class="ubu-header2">myShopifyShop.com</h1>
      <div class="analyticsGroup">
        <div class="analyticsCardGroup">
          <UbuCommonAnalyticsCard
            v-for="(item, index) in skeletonData"
            :key="index"
            :value="item.value"
            :title="item.name"
            :icon="item.icon"
            :color="item.color"
            :size="item.size"
            :is-dollar="item.isDollar"
            :loading="item.loading"
          />
        </div>
      </div>
    </div>
    <div v-else>
      <p class="pb-4">Please connect your shopify store first</p>
      <b-button
        label="Connect your store"
        tag="router-link"
        to="/workspace/connection"
        class="is-light is-rounded"
      />
    </div>
  </section>
</template>

<script>
// import moment from 'moment';
import { mapGetters } from 'vuex';
import { analyticsCatalog } from './$catalog/analytics';

export default {
  name: 'TheMentionsAnalyticsShopify',
  data() {
    return {
      skeletonData: [],
      rangeToUse: {
        value: [this.$moment().day(-6).toDate(), this.$moment().day(0).toDate()],
        type: 'simple',
        subtype: 'currentWeek',
      },
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      fetchIsDone: 'Sequence/getterFetchDone',
      getterShopifyShop: 'TheContactPanel/getterShopifyShop',
    }),
    showDateWarning() {
      if (this.$moment(this.rangeToUse.value[0]).isBefore(this.$moment('2021-11-01'))) return true;
      return false;
    },
  },
  watch: {
    fetchIsDone(isDone) {
      if (isDone) {
        this.isLoading = false;
      }
    },
  },
  mounted() {
    if (!this.fetchIsDone) {
      this.fetchLoadingCatalog();
      this.isLoading = true;
    }
  },
  methods: {
    setCurrentDate(date) {
      this.rangeToUse = date;
    },
    prepareShopifyAnalytics(shop) {
      const shopifyData = {
        totalRevenuesFromStore: this.totalRevenuesFromStore(shop),
        totalOrdersFromStore: this.totalOrdersFromStore(shop),
        totalRevenuesFromInstagram: this.totalRevenuesFromInstagram(shop),
        totalOrdersFromInstagram: this.totalOrdersFromInstagram(shop),
        totalRevenuesFromLinks: Math.round(this.totalRevenuesFromLinks(shop)),
        totalOrdersFromLinks: this.totalOrdersFromLinks(shop),
      };
      return Object.entries(shopifyData).reduce((acc, row) => {
        const [key, value] = row;
        if (analyticsCatalog[key]) {
          acc.push(
            {
              ...analyticsCatalog[key],
              value,
            },
          );
        }
        return acc;
      }, []).sort((a, b) => a.ordinal - b.ordinal);
    },
    ordersFromInstagram(shop) {
      return shop.orders.filter(({ createdOn, orderVisit }) => {
        const fromInsta = !!(orderVisit && (orderVisit.lastVisitSource === 'Instagram' || orderVisit.firstVisitSource === 'Instagram'));
        if (fromInsta) {
          if (this.rangeToUse.type === 'custom') {
            return this.$moment(createdOn).toDate()
              >= this.$moment(this.rangeToUse.value[0]).toDate()
            && this.$moment(createdOn).toDate()
              <= this.$moment(this.rangeToUse.value[1]).toDate();
          }
          return this.$moment(createdOn).toDate() >= this.rangeToUse.value[0];
        }
        return false;
      });
    },
    totalRevenuesFromStore(shop) {
      return shop.orders.length
        ? shop.orders.filter(({ createdOn }) => {
          if (this.rangeToUse.type === 'custom') {
            return this.$moment(createdOn).toDate()
              >= this.$moment(this.rangeToUse.value[0]).toDate()
            && this.$moment(createdOn).toDate()
              <= this.$moment(this.rangeToUse.value[1]).toDate();
          }
          return this.$moment(createdOn).toDate() >= this.rangeToUse.value[0];
        })
          .reduce((acc, row) => acc + Math.round(row.amountUsd / 100), 0)
        : 0;
    },
    totalOrdersFromStore(shop) {
      return shop.orders
        .filter(({ createdOn }) => {
          if (this.rangeToUse.type === 'custom') {
            return this.$moment(createdOn).toDate()
              >= this.$moment(this.rangeToUse.value[0]).toDate()
            && this.$moment(createdOn).toDate()
              <= this.$moment(this.rangeToUse.value[1]).toDate();
          }
          return this.$moment(createdOn).toDate() >= this.rangeToUse.value[0];
        }).length;
    },
    totalRevenuesFromLinks(shop) {
      const { referralLink, discountCode } = shop;

      const refAndDiscWithOrders = referralLink.concat(discountCode)
        .filter(({ order }) => order.length);

      const onlyOrders = refAndDiscWithOrders.reduce((acc, { order }) => {
        order.forEach((o) => acc.push(o));
        return acc;
      }, []);

      const orderByRange = onlyOrders.filter(({ createdOn }) => {
        if (this.rangeToUse.type === 'custom') {
          return this.$moment(createdOn).toDate()
              >= this.$moment(this.rangeToUse.value[0]).toDate()
            && this.$moment(createdOn).toDate()
              <= this.$moment(this.rangeToUse.value[1]).toDate();
        }
        return this.$moment(createdOn).toDate() >= this.rangeToUse.value[0];
      });

      return orderByRange.reduce((ac, { amountUsd }) => ac + (amountUsd / 100), 0);
    },
    totalOrdersFromLinks(shop) {
      const { referralLink, discountCode } = shop;
      const refAndDiscWithOrders = referralLink.concat(discountCode)
        .filter(({ order }) => order.length);

      const onlyOrders = refAndDiscWithOrders.reduce((acc, { order }) => {
        order.forEach((o) => acc.push(o));
        return acc;
      }, []);

      return onlyOrders.filter(({ createdOn }) => {
        if (this.rangeToUse.type === 'custom') {
          return this.$moment(createdOn).toDate()
              >= this.$moment(this.rangeToUse.value[0]).toDate()
            && this.$moment(createdOn).toDate()
              <= this.$moment(this.rangeToUse.value[1]).toDate();
        }
        return this.$moment(createdOn).toDate() >= this.rangeToUse.value[0];
      }).length;
    },
    totalOrdersFromInstagram(shop) {
      return this.ordersFromInstagram(shop)
        .length;
    },
    totalRevenuesFromInstagram(shop) {
      return this.ordersFromInstagram(shop).length
        ? this.ordersFromInstagram(shop)
          .reduce((acc, row) => acc + Math.round(row.amountUsd / 100), 0)
        : 0;
    },
    fetchLoadingCatalog() {
      this.skeletonData = Object.values(analyticsCatalog).reduce((acc, stat) => {
        if (stat.type === 'shopify') {
          acc.push({
            ...stat,
            loading: true,
          });

          return acc;
        }
        return acc;
      }, []);
    },
  },
};
</script>
