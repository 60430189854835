<template>
  <div class="TheError_wrapper">
    <div class="TheError_overlay" />
    <div class="TheError_view">
      <UbuLogo />

      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TheError',
};
</script>

<style lang="scss">
.TheError_wrapper {
  display: flex;
  flex-direction: row;
  height: 100%;

  & .ubuContainer {
    & > * {
      margin-right: 1rem;
    }
  }

  .TheError_overlay {
    background-image: url('../../assets/images/login-background.png');
    background-size: cover;
    height: 100%;
    flex-grow: 1;
    &::after {
      content: ' ';
      display: flex;
      height: 100%;
      background: var(--overlay);
      opacity: .7;
    }
  }
  .TheError_view {
    padding: 36px;
    width: 620px;
    box-shadow: 0px 1px 20px var(--box-shadow);
    background-color: var(--soapstone);
    position: relative;
  }
}
</style>
