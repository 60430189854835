<template>
  <section class="section">
    <div id="componentRender">
      <div class="card ubuCard">
        <div class="card-header">
          <p class="ubu-card-title">
            <b-radio
              v-model="radio"
              name="name"
              native-value="custom"
            />
            Custom message
          </p>
          <div class="card-header-icon">
            <b-button class="is-rounded end">
              Save
            </b-button>
          </div>
        </div>

        <div class="card-content">
          <UbuTemplateTextarea
            v-model="templateText"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'TheDocumentationTextarea',
  data() {
    return {
      radio: '',
      templateText: '',
    };
  },
};
</script>

<style lang="scss">
@import '@/styles/common';

#componentRender {
  width: 615px;
  min-height: 200px;
}
</style>
