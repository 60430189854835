/* eslint-disable no-multi-spaces */

// const TheInfluence = () => import(/* webpackChunkName: "addonCRM" */ './TheInfluence');
// const TheInfluencePipeline = () => import(/* webpackChunkName: "addonCRM" */ './TheInfluencePipeline');
// const TheInfluenceMessages = () => import(/* webpackChunkName: "addonCRM" */ './TheInfluenceMessages');
// const TheInfluenceLeads = () => import(/* webpackChunkName: "addonCRM" */ './TheInfluenceLeads');
// const TheInfluenceSettings = () => import(/* webpackChunkName: "addonCRM" */ './TheInfluenceSettings');
// const TheInfluenceHomePage = () => import(/* webpackChunkName: "addonCRM" */ './TheInfluenceHomePage');

import TheInfluence from './TheInfluence';
import TheInfluencePipeline from './TheInfluencePipeline';
import TheInfluenceMessages from './TheInfluenceMessages';
import TheInfluenceLeads from './TheInfluenceLeads';
import TheInfluenceSettings from './TheInfluenceSettings';
import TheInfluenceHomePage from './TheInfluenceHomePage';
import TheInfluenceMentions from './TheInfluenceMentions';
import TheInfluenceAnalytics from './TheInfluenceAnalytics';
import TheInfluenceAudit from './TheInfluenceAudit';

export default [
  {
    path: '/influence',
    name: 'Influence',
    component: TheInfluence,
    meta: {
      auth: true,
      requireData: true,
    },
    children: [
      {
        path: '/influence',
        name: 'Influence Home',
        component: TheInfluenceHomePage,
        meta: {
          auth: true,
          requireData: true,
        },
      },
      {
        path: '/audit',
        name: 'Audit',
        component: TheInfluenceAudit,
        meta: {
          auth: true,
          requireData: true,
        },
      },
      {
        path: '/influence/:campaignId',
        redirect: '/influence/:campaignId/pipeline',
        meta: {
          auth: true,
          requireData: true,
        },
      },
      {
        path: '/influence/:campaignId/pipeline',
        name: 'Influence Pipeline',
        component: TheInfluencePipeline,
        meta: {
          auth: true,
          requireData: true,
        },
      },
      {
        path: '/influence/:campaignId/messages',
        name: 'Influence Messages',
        component: TheInfluenceMessages,
        meta: {
          auth: true,
          requireData: true,
        },
      },
      {
        path: '/influence/:campaignId/leads',
        name: 'Influence Leads',
        component: TheInfluenceLeads,
        meta: {
          auth: true,
          requireData: true,
        },
      },
      {
        path: '/influence/:campaignId/settings',
        name: 'Influence Settings',
        component: TheInfluenceSettings,
        meta: {
          auth: true,
          requireData: true,
        },
      },
      {
        path: '/influence/:campaignId/mentions',
        name: 'Influence Mentions',
        component: TheInfluenceMentions,
        meta: {
          auth: true,
          requireData: true,
        },
      },
      {
        path: '/influence/:campaignId/analytics',
        name: 'Influence Analytics',
        component: TheInfluenceAnalytics,
        meta: {
          auth: true,
          requireData: true,
        },
      },
    ],
  },
];
