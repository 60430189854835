// import internals from '../../dataStores/stores/internals';
import moment from 'moment';
import query from '../../dataStores/stores/query';
import ContactPanelProvider from '../providers/ContactPanel.provider';
import contactTemplate from '../../dataStores/template/contact.template';
import { stampToDatetime } from '../../$utils/dates';
import { isImage } from '../../$utils/mediaUtils';
import { tryParseJson } from '../../$utils/formate';

const internals = {
  contactId: '',
};
export default {
  namespaced: true,

  state: {
    currentChannel: {},
    currentContactId: null,
  },

  getters: {
    getterCurrentThreadId: (state, getters, rootState, rootGetters) => rootGetters['TheInbox/getterCurrentThreadId'],
    getterCurrentContactId: (state) => state.currentContactId,
    getterContactPanelData: (_state, _getters, _rootState, rootGetters) => {
      const {
        'Sequence/getterFetchInbox': fetchDone,
      } = rootGetters;
      if (!fetchDone) return {};
      const {
        'TheContactPanel/getterCurrentContactId': currentContactId,
        'Contact/getterContacts': contacts,
        // 'Contact/getterContactsHasMentions': contactsHasMentions,
        'Contact/getterContactsHasGossips': contactsHasGossips,
        // 'Mention/getterMentions': mentions,
        'Message/getterMessages': messages,
        'Gossip/getterGossips': gossips,
        'Note/getterNotes': notes,
        'Media/getterMedias': medias,
        'Thread/getterThreadsHasMedias': threadsHasMedias,
        'TheInbox/getterCurrentThreadId': threadId,
        'Thread/getterThreadsHasMessagesBis': threadsHasMessagesBis,
        'Thread/getterThreadsHasNotesBis': threadsHasNotesBis,
        'Shopify/ReferralLink/getterReferralLinkHasOrderBis': referralLinkHasOrderBis,
        'Shopify/DiscountCode/getterDiscountCodeHasOrderBis': discountCodeHasOrderBis,
        'Shopify/DiscountCode/getterDiscountCode': discountCode,
        'Shopify/ReferralLink/getterReferralLink': referralLink,
        'Shopify/Order/getterOrders': order,
        'Contact/getterContactsHasReferralLinksBis': contactsHasReferralLinksBis,
        'Contact/getterContactsHasDiscountCodesBis': contactsHasDiscountCodesBis,
        // 'Campaign/getterCampaigns': campaigns,

      } = rootGetters;

      const currentContact = { mentionCount: [], ...contacts[currentContactId] };

      const response = {
        ...currentContact,
        threadId,
        // mentions: query.find({
        //   model: 'mentions',
        //   relationTable: contactsHasMentions,
        //   dataTable: mentions,
        //   fromId: { key: 'contactId', value: currentContactId },
        //   toId: { key: 'mentionId' },
        // }),
        mentions: currentContact.mentionCount || [],
        medias: query.find({
          model: 'mentions',
          relationTable: threadsHasMedias,
          dataTable: medias,
          fromId: { key: 'threadId', value: threadId },
          toId: { key: 'mediaId' },
        }),
        messages: query.findObjectLite({
          model: 'messages',
          relationTable: threadsHasMessagesBis,
          dataTable: messages,
          fromId: threadId,
        })
          .filter((m) => m)
          .map((message) => ({
            ...message,
            date: stampToDatetime(message.timestamp),
          }))
          .sort((a, b) => a.date < b.date),
        manualNotes: query.findObjectLite({
          model: 'notes',
          relationTable: threadsHasNotesBis,
          dataTable: notes,
          fromId: threadId,
        })
          .map((note) => {
            const myNote = { ...note };
            // @TODO to be removed on backend fix
            if (!myNote.text) {
            // console.log('🚀 ~ file: TheInfluence.store.js ~ line 227 ~ .map ~ myNote', myNote);
              myNote.text = 'BUG';
            }
            // if (myNote.ubu_user) {
            //   myNote.ubuUser = ubuUser[myNote.ubu_user];
            // }
            if (myNote.text && myNote.text[0] === '{') {
              const { text } = JSON.parse(myNote.text);
              myNote.text = text;
            }
            return myNote;
          }),
        gossips: query.find({
          model: 'notes',
          relationTable: contactsHasGossips,
          dataTable: gossips,
          fromId: { key: 'contactId', value: currentContactId },
          toId: { key: 'gossipId' },
        }),
        discountCode: query.findObjectLite({
          model: 'discountCode',
          relationTable: contactsHasDiscountCodesBis,
          dataTable: discountCode,
          fromId: currentContactId,
        })
          .map((dc) => ({
            ...dc,
            order: query.findObjectLite({
              model: 'order',
              relationTable: discountCodeHasOrderBis,
              dataTable: order,
              fromId: dc.discountCodeDcid,
            }),
          })),
        referralLink: query.findObjectLite({
          model: 'referralLink',
          relationTable: contactsHasReferralLinksBis,
          dataTable: referralLink,
          fromId: currentContactId,
        })
          .map((rl) => ({
            ...rl,
            order: query.findObjectLite({
              model: 'order',
              relationTable: referralLinkHasOrderBis,
              dataTable: order,
              fromId: rl.referralLinkRlid,
            }),
          })),
        // campaigns: query.findObjectLite({
        //   model: 'campaigns',
        //   relationTable: contactsHasCampaignsBis,
        //   dataTable: campaigns,
        //   fromId: currentContactId,
        // }),
      };
      return response;
    },
    getterShopifyShop: (_state, _getters, _rootState, rootGetters) => {
      const { 'Sequence/getterFetchDone': fetchDone } = rootGetters;

      if (!fetchDone) return [];

      const {
        'Shopify/ShopifyShop/getterShopifyShop': shopifyShop,
        'Shopify/ShopifyShop/getterShopifyShopHasDiscountCodeBis': shopifyShopHasDiscountCode,
        'Shopify/DiscountCode/getterDiscountCode': discountCode,
        'Shopify/ShopifyShop/getterShopifyShopHasReferralLinkBis': shopifyShopHasReferralLink,
        'Shopify/ShopifyShop/getterShopifyShopHasOrderBis': shopifyShopHasOrder,
        'Shopify/ReferralLink/getterReferralLink': referralLink,
        'Shopify/ReferralLink/getterReferralLinkHasOrderBis': referralLinkHasOrderBis,
        'Shopify/DiscountCode/getterDiscountCodeHasOrderBis': discountCodeHasOrderBis,
        'Shopify/Order/getterOrders': order,
        'Campaign/getterCampaigns': campaigns,
        'Contact/getterContacts': contacts,
      } = rootGetters;

      return Object.keys(shopifyShop).reduce((acc, ssid) => {
        const shop = {
          ...shopifyShop[ssid],
          orders: query.findObjectLite({
            model: 'order',
            relationTable: shopifyShopHasOrder,
            dataTable: order,
            fromId: ssid,
          }),
          discountCode: query.findObjectLite({
            model: 'discountCode',
            relationTable: shopifyShopHasDiscountCode,
            dataTable: discountCode,
            fromId: ssid,
          })
            .map((dc) => ({
              ...dc,
              order: query.findObjectLite({
                model: 'order',
                relationTable: discountCodeHasOrderBis,
                dataTable: order,
                fromId: dc.discountCodeDcid,
              }),
              campaign: dc.campaignId ? campaigns[dc.campaignId] : null,
              contact: dc.contactId ? contacts[dc.contactId] : null,
            })),
          referralLink: query.findObjectLite({
            model: 'referralLink',
            relationTable: shopifyShopHasReferralLink,
            dataTable: referralLink,
            fromId: ssid,
          })
            .map((rl) => ({
              ...rl,
              order: query.findObjectLite({
                model: 'order',
                relationTable: referralLinkHasOrderBis,
                dataTable: order,
                fromId: rl.referralLinkRlid,
              }),
              campaign: rl.campaignId ? campaigns[rl.campaignId] : null,
              contact: rl.contactId ? contacts[rl.contactId] : null,
            })),
        };
        acc.push(shop);
        return acc;
      }, []);
    },
    getterCampaigns: (_state, _getters, _rootState, rootGetters) => {
      const { 'Sequence/getterFetchDone': fetchDone } = rootGetters;

      if (!fetchDone) return [];

      const {
        'Channel/getterCurrentChannelId': channelId,
        'Campaign/getterCampaigns': campaigns,
        'Channel/getterChannelsHasCampaignsBis': channelsHasCampaignsBis,
      } = rootGetters;

      const campaignListFromChannel = query.findObjectLite({
        model: 'campaigns',
        relationTable: channelsHasCampaignsBis,
        dataTable: campaigns,
        fromId: channelId,
      })
        .map((campaign) => ({
          ...campaign,
          createdOn: moment(campaign.created).valueOf(),
        }));

      return campaignListFromChannel;
    },
  },

  actions: {
    loadPanel(_, { channelId, contactId }) {
      return Promise.resolve()
        .then(() => this.dispatch('Thread/fetchDisplayablesFromContact', { channelId, contactId }));
    },
    UP_currentContactId({ commit }, { contactId }) {
      return Promise.resolve()
        .then(() => { internals.contactId = contactId; })
        .then(() => commit('SET_CURRENT_CONTACTID', contactId));
    },
    updateContact({ commit }, { channelId, contact }) {
      return ContactPanelProvider
        .updateContact({ ...contact, channelId })
        .then(() => {
          commit('Contact/SET_CONTACTS', {
            [contact.contactId]: {
              ...contact,
              contactPanelNotes: contact.notes,
            },
          }, { root: true });
        })
        .catch((error) => {
          console.warn('🚀 ~ file: TheContactPanel.store.js ~ line 97 ~ updateContact ~ error', error);
        });
    },
    generateReport(_, { channelId, username }) {
      return ContactPanelProvider
        .generateReport({ channelId, username })

        .catch((error) => {
          console.warn('🚀 ~ file: TheContactPanel.store.js ~ line 109 ~ generateReport ~ error', error);
        });
    },
    hardRefresh({ commit }, { channelId, contactId }) {
      return ContactPanelProvider
        .hardRefresh({ channelId, contactId })
        .then(({ response, error }) => {
          if (error) {
            console.warn('🚀 ~ file: TheContactPanel.store.js ~ line 123 ~ .then ~ error', error);
            return;
          }
          const user = response;
          const contact = { [user.pk]: { ...contactTemplate({ ...user, channelId }) } };
          // console.log('🚀 ~ file: Thread.store.js ~ line 263 ~ .then ~ contact', contact);
          commit('Contact/SET_CONTACTS', contact, { root: true });
        })
        .catch((error) => {
          console.log('🚀 ~ file: TheContactPanel.store.js ~ line 119 ~ hardRefresh ~ error', error);
        });
    },

    fetchMediasByUsername({ rootGetters }, { channelId, username, threadId }) {
      return Promise.resolve()
        .then(() => this.dispatch('Media/fetchMedia', threadId))
        .then(() => ContactPanelProvider.getMediasByUsername({ channelId, username }))
        .then(({ response }) => {
          const {
            'Media/getterMedias': medias,
            'Thread/getterThreadsHasMedias': threadsHasMedias,

          } = rootGetters;

          const oldMedias = query.find({
            model: 'mentions',
            relationTable: threadsHasMedias,
            dataTable: medias,
            fromId: { key: 'threadId', value: threadId },
            toId: { key: 'mediaId' },
          }).map((m) => ({
            ...m,
            createdOn: moment(m.createdOn),
            isImage: isImage(m.mediaName),
            fileName: m.mediaName,
            url: `https://media.getubu.com/private/${threadId}/${m.mediaName}`,
            downloadUrl: `https://media.getubu.com/privateDownload/${threadId}/${m.mediaName}`,
            productType: 'story',
          }));

          if (response) {
            if (response) {
              const newMedias = response.map((m) => ({
                ...m,
                driveFile: tryParseJson(m.drive_file),
                createdOn: moment(m.ts),
                isImage: m.media_type === 'IMAGE',
                fileName: m.file_name,
                url: `https://io.getubu.com/media/${m.id}`,
                downloadUrl: `https://io.getubu.com/mediaDownload/${m.id}/${m.file_name}`,
                isNewMedia: true,
                productType: m.product_type ? 'story' : 'post',
              }));
              const removeDuplicate = [...newMedias, ...oldMedias]
                .filter((v, i, a) => a.findIndex((t) => (t.id === v.id)) === i);

              const sortedMedias = removeDuplicate.sort(
                (a, b) => b.createdOn - a.createdOn,
              );

              console.log('🚀 ~ file: TheContactPanel.store.js ~ line 343 ~ .then ~ sortedMedias', sortedMedias);

              return sortedMedias;
            }
          }
          return undefined;
        });
    },
  },
  mutations: {
    SET_CURRENT_CONTACTID(state, contactId) { state.currentContactId = contactId; },
  },
};
