<template>
  <div id="TheWorkspace">
    <TheSidebar :menu-items="ubuMenuItems" />

    <div class="UbuWorkspace_submenu">
      <UbuMenuButton
        v-for="(item, index) in workspaceMenu"
        :key="index"
        class="UbuWorkspace_subItem"
        :to="item.to"
        :disabled="item.disabled"
      >
        {{ item.text }}
      </UbuMenuButton>
    </div>
    <div class="UbuWorkspace_view multibar column">
      <router-view class="start" />
      <p class="end">
        Build v{{ appVersion }}
        from {{ $moment(appBuildDate).format('YYYY-MM-DD HH:mm:ss') }}
      </p>
    </div>
  </div>
</template>
<script>
const {
  VUE_APP_BUILD_DATE: appBuildDate,
  VUE_APP_VERSION: appVersion,
} = process.env;
export default {
  name: 'TheWorkspace',
  data() {
    return {
      appBuildDate,
      appVersion,
      ubuMenuItems: [
        {
          text: 'What\'s new',
          icon: 'home',
          href: '/home',
          disabled: process.env.VUE_APP_FACEBOOK_OFFICIEL_TEST === 'true',
        },
        {
          text: 'Workspace',
          icon: 'settings',
          href: '/workspace',
        },
        {
          text: 'Account',
          icon: 'account',
          href: '/account',
        },
      ],
      workspaceMenu: [
        {
          text: 'Team',
          icon: 'team',
          to: '/workspace/team',
        },
        {
          text: 'Tags',
          icon: 'tag',
          to: '/workspace/tags',
        },
        {
          text: 'Contact labels',
          icon: 'contact',
          to: '/workspace/folders',
        },
        {
          text: 'Templates',
          icon: 'templates',
          to: '/workspace/templates',
        },
        {
          text: 'Shopify links',
          icon: 'shop',
          to: '/workspace/shopify',
        },
        {
          text: 'Connection',
          icon: 'connection',
          to: '/workspace/connection',
        },
        {
          text: 'Theme',
          icon: 'theme',
          to: '/workspace/theme',
        },
      ],
    };
  },
};
</script>

<style lang="scss">
@import 'Workspace';
@import '@/styles/utils';
</style>
