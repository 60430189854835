<template>
  <div class="UbuSidebarCustoms">
    <UbuMenuButton
      v-for="item in sortedItems"
      :key="item._id"
      class="UbuSidebarCustoms-item"
      :icon="{ name: icon, filled: true, color: item.color }"
      :to="`/in/${type}/${item._id}`"
      :active="false"
      :badge="item.count"
      :important="item.hasUnread"
      :is-reduced="isReduced"
      :tooltip="tooltip"
    >
      <span class="ellipsis">
        {{ item.title }}
      </span>
    </UbuMenuButton>
    <UbuButton
      v-if="items.length > 5"
      class="text-cloudburst60 ubu-text3"
      center
      simple
      @click="showMore = !showMore"
    >
      {{ showMore ? '- Show less' : '+ Show more' }}
    </UbuButton>
    <!-- <UbuSimpleInput
      v-if="!isReduced"
      class="UbuSidebarCustoms_input"
      :placeholder="newPlaceholder"
      value=""
    /> -->
  </div>
</template>

<script>
export default {
  name: 'UbuSidebarCustoms',
  props: {
    type: {
      type: String,
      default: 'tag',
    },
    items: {
      type: Array,
      default: () => [],
    },
    icon: {
      type: String,
      default: 'tag',
    },
    newPlaceholder: {
      type: String,
      default: '+ add tag',
    },
    isReduced: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: Object,
      default: () => {},
    },
    countFn: {
      type: Function,
      default: () => 0,
    },
  },
  data() {
    return {
      showMore: false,
    };
  },
  computed: {
    sortedItems() {
      const sortedItems = [...this.items]
        .sort((a, b) => b.count - a.count)
        .sort((a, b) => b.hasUnread - a.hasUnread);
      if (this.showMore) return sortedItems;
      return sortedItems.slice(0, 5);
    },
  },
};
</script>

<style lang="scss" scoped>
.UbuSidebarCustoms {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.UbuSidebarCustoms-item {
  margin: 4px 0;
}

.UbuSidebarCustoms_input {
  padding-left: 18px;
  padding-right: 10px;
  &:hover {
    background-color: var(--narvalo60);
  }
  border-radius: 18px;
}
</style>
