<template>
  <section
    v-if="loadActions"
    class="flex"
  >
    <TheSidebar
      :menu-items="menu"
      :menu-items-first-column="menuItemsFirstColumn"
    />
    <section
      v-if="loadActions && fetchIsDone && campaign"
      id="UbuInfluenceBoard"
      :class="`${campaignColor} ${boardMaxWidth}`"
    >
      <UbuInfluenceHeader
        :campaign="campaign"
      />
      <div
        id="UbuInfluenceBoardCore"
      >
        <router-view @openModalAddLink="$emit('openModalAddLink', $event)" />
      </div>
    </section>
    <section
      v-else
      id="UbuInfluenceBoard"
    >
      <router-view />
    </section>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CommonMixin from '../$mixins/CommonMixin';

export default {
  name: 'TheInfluence',
  mixins: [CommonMixin],
  provide: {
    moveTo: [
      { name: 'in progress', icon: 'pin', color: '' },
      { name: 'success', icon: 'success', color: '5' },
      { name: 'failure', icon: 'failure', color: '2' },
      { name: 'delivered', icon: 'send', color: '' },
      { name: 'talking', icon: 'comments', color: '' },
    ],
  },
  data() {
    return {
      loadedOnce: false,
    };
  },
  computed: {
    ...mapGetters({
      campaignActivitiesDetails: 'TheInfluence/getterCampaignActivitiesDetails',
      sidebarWidth: 'TheSidebar/getterWidth',
      currentChannelId: 'Channel/getterCurrentChannelId',
      fetchInitialIsDone: 'Sequence/getterFetchInitial',
      fetchActionIsDone: 'Sequence/getterFetchActions',
      fetchIsDone: 'Sequence/getterFetchDone',
    }),
    loadActions() {
      // if (this.fetchInitialIsDone && this.fetchIsDone && !this.fetchActionIsDone) {
      //   this.fetchActions();
      //   return true;
      // }
      return true;
    },
    campaignColor() {
      return this.campaign ? `campaign-color-${this.convertColor(this.campaign.color)}` : 'campaign-color-1';
    },
    boardMaxWidth() {
      return this.sidebarWidth === 240 ? 'board-reduced' : 'board-extended';
    },
    campaignList() {
      return this.campaignActivitiesDetails.campaigns.map((campaign) => {
        const {
          name, color, campaignId, campaignHasUnread, ended, createdOn,
        } = campaign;
        return ({
          name, color, campaignId, campaignHasUnread, ended, createdOn,
        });
      });
    },
    campaign() {
      return this.campaignActivitiesDetails.campaigns
        .find((campaign) => campaign.campaignId === this.$route.params.campaignId);
    },
    menu() {
      if (!this.fetchIsDone) return [];
      return this.campaignList
        .filter(({ ended }) => !ended)
        .sort((a, b) => b.createdOn - a.createdOn)
        .map((campaign) => ({
          icon: 'influence',
          text: campaign.name,
          href: `/influence/${campaign.campaignId}`,
          count: 0,
          hasUnread: !!campaign.campaignHasUnread,
        }));
    },
    menuItemsFirstColumn() {
      return [
        {
          icon: 'crm',
          text: 'Crm',
          href: '/crm',
        },
        {
          icon: 'settings',
          text: 'Campaigns',
          href: '/influence',
        },
        // {
        //   icon: 'overview',
        //   text: 'Audit',
        //   href: '/audit',
        // },
      ];
    },
  },
  watch: {
    // immediate: true,
    $route(to) {
      this.UP_currentCampaignId(to.params.campaignId);
    },
  },
  methods: {
    ...mapActions({
      fetchChannels: 'Channel/fetchChannels',
      fetchActions: 'Sequence/fetchActions',
      UP_currentCampaignId: 'TheInfluence/UP_currentCampaignId',
    }),
  },
};
</script>

<style lang="scss">
@import 'Influence';

#UbuInfluenceBoard {
  &.board-reduced {
    width: calc(100vw - 240px);
  }
  &.board-extended {
    width: calc(100vw - 90px);
  }
  // width: 100%;
  // min-width: 100%;
  height: 100vh;
  & #UbuInfluenceBoardSetup {
    overflow-x: auto;
    & .scroller {
      min-width: 1500px;
      display: flex;
      justify-content: flex-start;

    }
    & .ubuCard {
      width: 300px;
      min-width: 300px;
      margin: 0 8px;
    }
  }
  & #UbuInfluenceBoardCore {
    background-color: var(--seashellpeach);
    & > * {
      // max-width: 1440px;
      margin: 0 auto;
    }
  }
}
</style>
