<template>
  <span
    :class="['UbuIcon_wrapper', {
      'cursor-pointer': clickable,
    }]"
    @click="(evt) => clickable ? $emit('click', evt) : () => {}"
  >
    <i
      v-if="type === 'ubu'"
      :class="[
        `ubu-icons ubu-icons-${name}-${filled ? 'filled' : 'outlined'}`,
        color ? `text-color-${color}` : ''
      ]"
      :style="[`font-weight: ${strong ? '800' : '300'}`, {
        'font-size': typeof size === 'string' ? size : `${size}px`
      }]"
    />
    <span
      v-else-if="type === 'char'"
      :class="[color ? `text-color-${color}` : '']"
      :style="[`font-weight: ${strong ? '800' : '300'}`, {
        'font-size': typeof size === 'string' ? size : `${size}px`
      }]"
    >
      {{ name }}
    </span>
    <img
      v-else-if="type === 'picto'"
      style="border-radius: 25%"
      :src="name"
    >
    <UbuUserPicture
      v-else-if="type === 'user'"
      :size="size"
      :initial-name="name"
      :color="color"
    />
  </span>
</template>

<script>
export default {
  name: 'UbuIcon',
  props: {
    name: {
      type: String,
      default: '',
    },
    strong: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    filled: {
      type: Boolean,
      default: false,
    },
    color: {
      type: [Number, String],
      default: null,
    },
    type: {
      type: String,
      default: 'ubu',
    },
    size: {
      type: [String, Number],
      default: '',
    },
    tooltip: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss">
.UbuIcon_wrapper {
  display: inline-flex;
      &.cursor-pointer {
          cursor: pointer;
      }
      .UbuIcon {
          font-family: 'UbuIcons';
          font-style: inherit;
      }
}
</style>
