<template>
  <div class="UbuContactDataCategory_wrapper">
    <div class="multibar">
      <h2 class="ubu-header2 start">{{ title }}</h2>
      <div
        v-if="currentSource"
        class="end flex"
      >
        <div
          :class="['sourceButton', { 'selected': currentSource === 'instagram' }]"
          @click="$emit('changeSource', 'instagram')"
        >
          <img
            class="sourceImage"
            src="/img/instagram-logo.svg"
          >
        </div>
        <div
          v-if="!isTiktokHidden"
          :class="
            ['sourceButton', 'sourceButtonTiktok',
             { 'selected': currentSource === 'tiktok', 'disabled': !isTiktokAvailable }
            ]"
          @click="isTiktokAvailable ? $emit('changeSource', 'tiktok') : null"
        >
          <img
            class="sourceImage"
            src="/img/tiktok-logo.svg"
          >
          <div
            v-if="!isTiktokAvailable"
            class="tooltipAddTiktok"
          >
            <p class="ubu-text1 mb-2">{{ tooltipNoTiktok }}</p>
            <b-button
              expanded
              class="is-rounded is-geraldine"
              @click="$emit('openModalEditUsernameTiktok', $event)"
            >
              Add manually
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <slot />
  </div>
</template>
<script>
export default {
  name: 'UbuContactDataCategory',
  props: {
    title: {
      type: String,
      default: '',
    },
    currentSource: {
      type: String,
      default: null,
    },
    isTiktokAvailable: {
      type: Boolean,
      default: false,
    },
    isTiktokHidden: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tooltipNoTiktok: "At the moment, we didn't find any TikTok account for this user",
    };
  },
};
</script>
