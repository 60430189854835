import moment from 'moment';

const findLastMentionOrTagPost = (account) => {
  const { mentions, tagsPosts, mentionsUser } = account;
  const lastMention = [];
  if (mentions && mentions.length) lastMention.push(Math.max(...mentions));
  if (tagsPosts && tagsPosts.length) lastMention.push(Math.max(...tagsPosts));
  if (mentionsUser && mentionsUser.length) {
    lastMention.push(
      Math.max(...mentionsUser.map((mention) => mention.timestamp)),
    );
  }

  return lastMention.length ? Math.max(...lastMention) : null;
};

const mentionsFormater = (response) => {
  const medias = response.map((m) => ({
    id: m.media ? m.media.id : 0,
    instaUser: m.instaUser || null,
    username: m.media ? m.media.username : null,
    userPk: m.instaUser ? m.instaUser.pk : '',
    followers: m.instaUser ? m.instaUser.follower_count : 0,
    caption: m.media && m.media.caption ? m.media.caption : '',
    thumbnailUrl: m.thumbnailLink,
    thumbnailUrl2: m.thumbnailLink2,
    date: moment(m.timestamp).format('YYYY-MM-DD'),
    timestamp: moment(m.timestamp).unix(),
    like_count: m.media ? m.media.like_count : 0,
    comments_count: m.media ? m.media.comments_count : 0,
    hasThumbnail: m.hasThumbnail,
    hasMedia: m.media !== null,
    webViewLink: m.webViewLink,
    webContentLink2: m.webContentLink2,
    webViewLink2: m.webViewLink2,
    name: m.name,
    file_name: m.media ? m.media.file_name : m.name,
    isImage: m.media ? m.media.media_type !== 'VIDEO' : true,
    type: m.media ? m.media.media_type : '',
    product_type: m.type || 'story',
    engagement: m.instaUser?.engagement,
    language: m.instaUser?.language,
  })).map((m) => ({
    ...m,
    webContentLink: `https://io.getubu.com/mediaDownload/${m.id}/${m.product_type}-${m.username}-${m.date}.${m.isImage ? 'jpg' : 'mp4'}`,
  }))
    .filter((media) => media.hasThumbnail === true && media.username);

  const mediasCleanThumbnail = medias.reduce((acc, media) => {
    if (acc.find((data) => data.id === media.id) && media.isImage) {
      return acc;
    }
    if (media.hasMedia) acc.push(media);
    return acc;
  }, []);

  const mediasCleanDuplicatedVideos = mediasCleanThumbnail.reduce((acc, media) => {
    if (!acc.find((data) => data.id === media.id)) {
      acc.push(media);
    }

    return acc;
  }, []);

  return mediasCleanDuplicatedVideos.map((m) => ({
    ...m,
    userStories: m.userPk !== '' ? mediasCleanDuplicatedVideos.filter((media) => media.userPk === m.userPk && media.product_type === 'story') : [],
    userPosts: m.userPk !== '' ? mediasCleanDuplicatedVideos.filter((media) => media.userPk === m.userPk && media.product_type === 'post') : [],
  })).sort((a, b) => b.timestamp - a.timestamp);
};

export {
  // eslint-disable-next-line
  mentionsFormater,
  findLastMentionOrTagPost,
};
