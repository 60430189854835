/* eslint-disable arrow-body-style */
import moment from 'moment';
import { arrayMapper } from '../../../$utils/prototypes';
import {
  updateDatasIfExists,
  updateObjectRelationIfExists,

} from '../../$utils/dataMapper';
import { yaerApi } from '../../../$providers/index';
import { timeToNow } from '../../../$utils/dates';

/* eslint-disable max-len */
export default {
  namespaced: true,

  state: {
    discountCode: {},
    discountCodeHasOrderBis: {},
  },

  getters: {
    getterDiscountCode: (state) => state.discountCode,
    getterDiscountCodeHasOrderBis: (state) => state.discountCodeHasOrderBis,
  },

  mutations: {
    SET_DISCOUNT_CODE(state, newDatas) { state.discountCode = updateDatasIfExists({ newDatas, actualDatas: state.discountCode, key: 'discountCodeDcid' }); },
    SET_DISCOUNT_CODE_HAS_ORDER_BIS(state, newDatas) { state.discountCodeHasOrderBis = updateObjectRelationIfExists({ newDatas, actualDatas: state.discountCodeHasOrderBis }); },

    RESET_STORES(state) {
      state.discountCode = {};
      state.discountCodeHasOrderBis = {};
    },
  },

  actions: {
    sequence(_, { shopifyShopSsid }) {
      return Promise.resolve()
        // .then(() => this.dispatch('Shopify/DiscountCode/refreshDiscountCode', { shopifyShopSsid }))
        .then(() => this.dispatch('Shopify/DiscountCode/fetchShopifyDiscountCode', { shopifyShopSsid }))
        .then((codes) => this.dispatch('Shopify/DiscountCode/fetchShopifyDiscountCodeOrders', { shopifyShopSsid, codes }));
    },
    fetchShopifyDiscountCode({ commit, rootGetters }, { shopifyShopSsid }) {
      const {
        'Channel/getterCurrentChannelId': channelId,
        'Shopify/ShopifyShop/getterShopifyShop': shopifyShop,
      } = rootGetters;

      return Promise.resolve()
        .then(() => yaerApi.api.shopifyDiscountCode.report({ payload: { channelId, shopifyShopSsid } }))
        .then(({ result }) => {
          if (!result) return undefined;
          const discountCode = result.map((dc) => {
            // console.log('🚀 ~ file: DiscountCode.store.js ~ line 53 ~ discountCode ~ dc', dc.title, dc._id);
            return ({
              ...dc,
              discountCodeDcid: dc._id,
              link: `${shopifyShop[dc.shopifyShopSsid].websiteUrl}/discount/${dc.title}`,
              when: timeToNow(moment(dc.createdOn).valueOf()),
              type: 'discount',
              contactId: dc.contactpk || null,
            });
          });
          // console.log('🚀 ~ file: DiscountCode.store.js ~ line 60 ~ discountCode ~ discountCode', discountCode);
          // discountCode.forEach(({ title, discountCodeDcid }) => {
          //   console.log('🚀 ~ file: DiscountCode.store.js ~ line 67 ~ discountCode.forEach ~ title', title, discountCodeDcid);
          // });

          const mappedDiscountCode = arrayMapper(discountCode, 'discountCodeDcid');

          commit('SET_DISCOUNT_CODE', mappedDiscountCode);

          const shopifyShopHasDiscountCodeBis = discountCode
            .reduce((acc, row) => {
              const { discountCodeDcid } = row;
              if (!acc[shopifyShopSsid]) {
                acc[shopifyShopSsid] = [discountCodeDcid];
                return acc;
              }
              acc[shopifyShopSsid].push(discountCodeDcid);
              return acc;
            }, {});

          const contactHasDiscountCodesBis = discountCode
            .reduce((acc, row) => {
              const { discountCodeDcid, contactpk } = row;
              if (!contactpk) return acc;
              if (!acc[contactpk]) {
                acc[contactpk] = [discountCodeDcid];
                return acc;
              }
              acc[contactpk].push(discountCodeDcid);
              return acc;
            }, {});

          const campaignHasDiscountCodesBis = discountCode
            .reduce((acc, row) => {
              const { discountCodeDcid, campaignId } = row;
              if (!campaignId) return acc;
              if (!acc[campaignId]) {
                acc[campaignId] = [discountCodeDcid];
                return acc;
              }
              acc[campaignId].push(discountCodeDcid);
              return acc;
            }, {});

          commit('Shopify/ShopifyShop/SET_SHOPIFY_SHOP_HAS_DISCOUNT_CODE_BIS', shopifyShopHasDiscountCodeBis, { root: true });
          commit('Contact/SET_CONTACTS_HAS_DISCOUNT_CODES_BIS', contactHasDiscountCodesBis, { root: true });
          commit('Campaign/SET_CAMPAIGNS_HAS_DISCOUNT_CODES_BIS', campaignHasDiscountCodesBis, { root: true });

          return discountCode;
        });
    },
    fetchShopifyDiscountCodeOrders({ commit, rootGetters }, { shopifyShopSsid, codes }) {
      const { 'Channel/getterCurrentChannelId': channelId } = rootGetters;

      return Promise.resolve()
        // .then(() => Promise.all(
        //   codes.flatMap(({ title: code, discountCodeDcid }) => yaerApi.api.shopifyOrders.discountCode
        //     .report({ payload: { channelId, shopifyShopSsid, code } })
        //     .then(({ result }) => (result ? result.map((order) => ({
        //       ...order, channelId, orderOid: order._id, discountCodeDcid,
        //     })) : undefined))),
        // ))
        .then(() => yaerApi.api.shopifyOrders.discountCode.report({ payload: { channelId, shopifyShopSsid } }))
        .then(({ result }) => {
          // console.log('🚀 ~ file: DiscountCode.store.js ~ line 121 ~ .then ~ result', result);
          const discountOrderList = result.map(({ discountCode, ...order }) => {
            if (discountCode) {
              // console.log('🚀 ~ file: DiscountCode.store.js ~ line 123 ~ discountOrderList ~ discountCode', discountCode, discountCode.discountCode, discountCode._id);
              return ({
                ...order,
                orderOid: order._id,
                channelId,
                discountCodeDcid: discountCode,
              });
            }
            return undefined;
          }).filter((r) => r);
          // console.log('🚀 ~ file: DiscountCode.store.js ~ line 128 ~ orders ~ discountOrderList', discountOrderList);
          // const discountOrderList = response.flat().filter((r) => r);

          const mappedDiscountCodeOrders = arrayMapper(discountOrderList, 'orderOid');
          // console.log('🚀 ~ file: DiscountCode.store.js ~ line 132 ~ .then ~ mappedDiscountCodeOrders', mappedDiscountCodeOrders);

          commit('Shopify/Order/SET_ORDERS', mappedDiscountCodeOrders, { root: true });

          const discountOrdersBis = discountOrderList
            .reduce((acc, row) => {
              const { discountCodeDcid, orderOid } = row;
              if (!acc[discountCodeDcid]) {
                acc[discountCodeDcid] = [orderOid];
                return acc;
              }
              acc[discountCodeDcid].push(orderOid);
              return acc;
            }, {});
          // console.log('🚀 ~ file: DiscountCode.store.js ~ line 149 ~ .then ~ discountOrdersBis', discountOrdersBis);

          commit('SET_DISCOUNT_CODE_HAS_ORDER_BIS', discountOrdersBis);
        });
    },
    assignDiscountCodeToContact({ commit, rootGetters }, { shopifyShopSsid, discountCode, ...rest }) {
      const {
        'Channel/getterCurrentChannelId': channelId,
      } = rootGetters;

      return yaerApi.api.shopifyDiscountCode
        .post({ payload: { channelId, shopifyShopSsid, ...rest } })
        .then(({ result }) => {
          if (!result) return;

          const updatedDiscountCode = {
            [discountCode.discountCodeDcid]: {
              ...discountCode,
              shopifyShopSsid,
              contactId: rest.contactpk,
              contactpk: rest.contactpk,
              campaignId: rest.campaignId ? rest.campaignId : null,
            },
          };

          commit('SET_DISCOUNT_CODE', updatedDiscountCode);
          commit('Contact/SET_CONTACTS_HAS_DISCOUNT_CODES_BIS', { [rest.contactpk]: [discountCode.discountCodeDcid] }, { root: true });
          if (rest.campaignId) {
            commit('Campaign/SET_CAMPAIGNS_HAS_DISCOUNT_CODES_BIS', { [rest.campaignId]: [discountCode.discountCodeDcid] }, { root: true });
          }
        });
    },
    assignDiscountCodeToCampaign({ commit, rootGetters }, { shopifyShopSsid, discountCode, campaignId }) {
      const {
        'Channel/getterCurrentChannelId': channelId,
      } = rootGetters;

      const { contactpk, discountCodeDcid: discountCodeId, campaignId: oldCampaignId } = discountCode;

      return yaerApi.api.shopifyDiscountCode
        .post({
          payload: {
            channelId, shopifyShopSsid, contactpk, discountCodeId, campaignId,
          },
        })
        .then(({ result }) => {
          if (!result) return;

          const updatedDiscountCode = {
            [discountCode.discountCodeDcid]: {
              ...discountCode,
              campaignId,
            },
          };

          commit('SET_DISCOUNT_CODE', updatedDiscountCode);
          if (oldCampaignId) commit('Campaign/REMOVE_DISCOUNT_CODE_TO_CAMPAIGN', { campaignId: oldCampaignId, discountCodeDcid: discountCodeId }, { root: true });
          commit('Campaign/SET_CAMPAIGNS_HAS_DISCOUNT_CODES_BIS', { [campaignId]: [discountCode.discountCodeDcid] }, { root: true });
        });
    },
    removeDiscountCodeToContact({ commit, rootGetters }, { shopifyShopSsid, discountCode }) {
      const { 'Channel/getterCurrentChannelId': channelId } = rootGetters;
      const { discountCodeDcid: discountCodeId, campaignId, contactId } = discountCode;

      return yaerApi.api.shopifyDiscountCode
        .post({
          payload: {
            channelId, shopifyShopSsid, discountCodeId, contactpk: null, campaignId: campaignId || null,
          },
        })
        .then(({ result }) => {
          if (!result) return;

          const updatedDiscountCode = {
            [discountCodeId]: {
              ...discountCode,
              contactId: null,
              contactpk: null,
              campaignId: campaignId || null,
            },
          };

          commit('SET_DISCOUNT_CODE', updatedDiscountCode);
          commit('Contact/REMOVE_DISCOUNT_CODE_TO_CONTACT', { contactId, discountCodeDcid: discountCodeId }, { root: true });
        });
    },
    refreshDiscountCode({ commit, rootGetters }, { shopifyShopSsid }) {
      const { 'Channel/getterCurrentChannelId': channelId } = rootGetters;

      return yaerApi.api.shopifyDiscountCode.refresh({ payload: { channelId, shopifyShopSsid } })
        .then(({ result }) => {
          if (!result) return;
          const discountCode = result.map((dc) => ({
            ...dc,
            discountCodeDcid: dc._id,
            type: 'discount',
          }));

          const mappedDiscountCode = arrayMapper(discountCode, 'discountCodeDcid');

          commit('SET_DISCOUNT_CODE', mappedDiscountCode);

          const shopifyShopHasDiscountCodeBis = discountCode
            .reduce((acc, row) => {
              const { discountCodeDcid } = row;
              if (!acc[shopifyShopSsid]) {
                acc[shopifyShopSsid] = [discountCodeDcid];
                return acc;
              }
              acc[shopifyShopSsid].push(discountCodeDcid);
              return acc;
            }, {});

          commit('Shopify/ShopifyShop/SET_SHOPIFY_SHOP_HAS_DISCOUNT_CODE_BIS', shopifyShopHasDiscountCodeBis, { root: true });
        });
    },
  },

};
