<template>
  <div id="UbuCRMTable">
    <b-table
      class="list-table"
      :data="listFiltered"
      :default-sort="['username', 'asc']"
      :loading="loading"
      paginated
      :sticky-header="true"
      per-page="30"
      pagination-rounded
      hoverable
      pagination-size="is-small"
      :checked-rows="checkedRows"
      checkable
      :opened-detailed="openedDetails"
      :detailed="true"
      :show-detail-icon="true"
      detail-key="contactId"
      @check="$emit('checkRows', $event)"
      @check-all="$emit('checkRows', $event)"
    >
      <b-table-column
        v-slot="{ row }"
        label=""
      >
        <UbuButton
          secondary
          center
          @click="$emit('showCarousel', row.mentionsUser)"
        >
          show
        </UbuButton>
      </b-table-column>
      <b-table-column
        v-slot="{ row }"
        field="username"
        label="Username"
        sortable
      >
        <div class="username-table">
          {{ row.username }}
        </div>
      </b-table-column>
      <b-table-column
        field="contactGroup"
        label="Labels"
        sortable
      >
        <template v-slot="{ row: { contactGroup } }">
          <UbuCRMTableCustomCell
            v-if="contactGroup.length"
            :groups="contactGroup"
            icon-name="contact"
          />
          <span v-else>
            -
          </span>
        </template>
      </b-table-column>
      <b-table-column
        v-slot="{ row: { followerCount } }"
        field="followerCount"
        label="Followers"
        sortable
      >
        <span v-if="followerCount">{{ followerCount.toLocaleString('en') }}</span>
        <span v-else>
          -
        </span>
      </b-table-column>
      <b-table-column
        v-slot="{ row: { engagement } }"
        field="engagement"
        label="Engagement"
        sortable
      >
        <span v-if="engagement && Number(engagement) >= 0.5">
          {{ Number(engagement).toFixed(2) }}%
        </span>
        <span v-else>-</span>
      </b-table-column>
      <b-table-column
        v-slot="{ row: { lastMentionOrTagPost } }"
        field="lastMentionOrTagPost"
        label="Last Mention"
        sortable
      >
        {{ parseTimestamp(lastMentionOrTagPost) || '-' }}
      </b-table-column>
      <b-table-column
        v-slot="{ row: { storyMentionCount } }"
        field="storyMentionCount"
        label="Story Mention"
        sortable
      >
        {{ storyMentionCount ? storyMentionCount : '-' }}
      </b-table-column>
      <b-table-column
        v-slot="{ row: { postMentionCount } }"
        field="postMentionCount"
        label="Post Mention"
        sortable
      >
        {{ postMentionCount ? postMentionCount : '-' }}
      </b-table-column>
      <b-table-column
        v-slot="{ row: { posted } }"
        field="posted"
        label="Posted"
        sortable
      >
        {{ posted ? posted : '-' }}
      </b-table-column>
      <b-table-column
        v-slot="{ row: { status } }"
        field="status"
        label="Status"
        sortable
      >
        {{ status ? status : '-' }}
      </b-table-column>
      <b-table-column
        v-slot="{ row: { averagePostEngagement } }"
        field="averagePostEngagement"
        label="Average Post Engagement"
        sortable
      >
        <span v-if="averagePostEngagement">
          {{ Number(averagePostEngagement).toFixed(2) }}%
        </span>
        <span v-else>
          -
        </span>
      </b-table-column>
      <b-table-column
        v-slot="{ row: { impressions } }"
        field="impressions"
        label="Impressions"
        sortable
      >
        <span v-if="impressions">{{ Math.round(impressions).toLocaleString('en') }}</span>
        <span v-else>
          -
        </span>
      </b-table-column>
      <b-table-column
        v-slot="{ row: { averageReach } }"
        field="averageReach"
        label="Average Reach"
        sortable
      >
        <span v-if="averageReach">{{ Math.round(averageReach).toLocaleString('en') }}</span>
        <span v-else>
          -
        </span>
      </b-table-column>
      <b-table-column
        v-slot="{ row: { language } }"
        field="language"
        label="Language"
        sortable
      >
        {{ language ? language : '-' }}
      </b-table-column>
      <b-table-column
        v-slot="{ row: { newFollowers } }"
        field="newFollowers"
        label="Followers earned"
        sortable
      >
        <b-tooltip
          :label="followersEranedTooltipLabel"
          position="is-left"
          multilined
        >
          <span v-if="newFollowers">{{ Math.round(newFollowers).toLocaleString('en') }}</span>
          <span v-else>
            -
          </span>
        </b-tooltip>
      </b-table-column>

      <template #detail="{ row }">
        <UbuCRMTableDetail
          :row="row"
          :thread-groups-list="threadGroupsList"
          :contact-groups-list="contactGroupsList"
          :campaign-list="campaignList"
          :is-generate-report-loading="isGenerateReportLoading"
          :is-statistics-loading="isStatisticsLoading"
          show-new-message-button
          @addCustom="$emit('addCustom', $event)"
          @removeCustom="$emit('removeCustom', $event)"
          @generateReport="$emit('generateReport', row)"
          @updateContact="$emit('updateContact', $event)"
          @createThreadGroupAndAssign="$emit('createThreadGroupAndAssign', {
            thread: row.thread, ...$event, username: row.username })"
          @createContactGroupAndAssign="$emit('createContactGroupAndAssign',{
            thread: {
              contactId: row.contactId, channelId: row.channelId
            }, ...$event, username: row.username })"
          @openNewMessage="$emit('openNewMessage', $event)"
          @fetchTiktokUser="$emit('fetchTiktokUser', $event)"
          @openModalEditUsernameTiktok="$emit('openModalEditUsernameTiktok', $event)"
        />
      </template>
    </b-table>
  </div>
</template>

<script>
import { timeToNow } from '../../$utils/dates';

export default {
  name: 'UbuInfluenceMentionsTable',
  props: {
    listFiltered: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isGenerateReportLoading: {
      type: String,
      default: null,
    },
    checkedRows: {
      type: Array,
      default: () => [],
    },
    threadGroupsList: {
      type: Array,
      default: () => [],
    },
    contactGroupsList: {
      type: Array,
      default: () => [],
    },
    campaignList: {
      type: Array,
      default: () => [],
    },
    isStatisticsLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      openedDetails: [],
      activeTab: 0,
      followersEranedTooltipLabel: 'The calculation is based on the official instagram api data (new followers) and the estimated impressions of posts and stories.',
    };
  },
  methods: {
    parseTimestamp(value) {
      return value ? timeToNow(value) : null;
    },
  },
};
</script>
