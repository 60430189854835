<template>
  <UbuCommonMessageCard
    :is-me="isMe"
    :date="date"
    :username="username"
    is-text
    :is-error="isError"
    @resend="$emit('resend')"
    @deleteMessage="$emit('deleteMessage')"
    @translateMessage="$emit('translateMessage', $event)"
  >
    <template v-slot:content>
      <div class="card-content-text">
        <template
          v-for="(text, index) in textArray"
        >
          <span
            v-if="!text.isLink"
            :key="index"
          >{{ text.value }}</span>
          <a
            v-else
            :key="index"
            :href="text.url"
            target="_blank"
          >{{ text.value }}</a>
        </template>
      </div>
    </template>
  </UbuCommonMessageCard>
</template>

<script>

export default {
  name: 'UbuCommonText',
  props: {
    isMe: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: true,
    },
    date: {
      type: String,
      required: true,
    },
    username: {
      type: String,
      default: '',
    },
    textArray: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss">
// .UbuCommonText {
//   word-break: break-word;
//   padding: 16px;
//   color: var(--cola);
// }
// .UbuCommonText_link {
//   color: var(--cola);
//   text-decoration: none;
//   font-weight: bold;
// }
</style>
