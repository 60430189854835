/* eslint-disable max-len */
import { yaerApi } from '../../$providers';
import WorkspaceProvider from '../providers/Workspace.provider';
import query from '../../dataStores/stores/query';

export default {
  namespaced: true,

  state: {
    currentChannel: {},
    ownedAdAccounts: [],
    shopifyUtmList: [],
    campaignsAndActivities: {},
    contactsAndActivities: {},
    currentInviteToken: null,
  },

  getters: {
    ownedAdAccounts: (state) => state.ownedAdAccounts,
    getterShopifyUtmList: (state) => state.shopifyUtmList,
    channelUsers: (_state, _getters, _rootState, rootGetters) => {
      const {
        'Channel/getterCurrentChannelId': channelId,
        'Channel/getterChannelsHasUbuUsers': channelsHasUbuUsers,
        'UbuUser/getterUbuUsers': ubuUsers,
        'Authenticate/getterAuthenticate': me,
      } = rootGetters;

      const response = {
        // ...channels[channelId],
        me,
        users: query.find({
          model: 'ubuUser',
          relationTable: channelsHasUbuUsers,
          dataTable: ubuUsers,
          fromId: { key: 'channelId', value: channelId },
          toId: { key: 'ubuUserId' },
        }),
      };
      // console.log('🚀 ~ file: TheWorkspace.store.js ~ line 32 ~ response', response);

      return response;
    },

    getterTemplates: (state, getters, rootstate, rootGetters) => {
      const {
        'Channel/getterCurrentChannelId': channelId,
        'Channel/getterChannelsHasTemplates': channelsHasTemplates,
        'Template/getterTemplates': templates,
      } = rootGetters;
      // console.log('Template table', channelsHasTemplates);
      return query.find({
        relationTable: channelsHasTemplates,
        dataTable: templates,
        fromId: { key: 'channelId', value: channelId },
        toId: { key: 'templateId' },
      });
    },

    getterCurrentInviteToken: (state) => state.currentInviteToken,

    getterShopifyShop: (_state, _getters, _rootState, rootGetters) => {
      const {
        'Sequence/getterFetchDone': fetchDone,
        'Shopify/ShopifyShop/getterShopifyShop': shopifyShop,
      } = rootGetters;

      if (!fetchDone) return [];

      return Object.keys(shopifyShop).reduce((acc, ssid) => {
        const shop = {
          ...shopifyShop[ssid],
        };
        acc.push(shop);
        return acc;
      }, []);
    },

  },

  actions: {
    sequence() {
      return Promise.resolve()
        .then(() => this.dispatch('Sequence/fetchInitial'));
    },

    editUser({ commit }, user) {
      return WorkspaceProvider
        .editUser(user)
        .then(({ response }) => {
          if (response) {
            commit('Authenticate/SET_AUTHENTICATE', response, { root: true });
          }
        })
        .catch((error) => {
          console.log('🚀 ~ file: TheWorkspace.store.js ~ line 60 ~ addTag ~ error', error);
        });
    },

    deleteMember({ commit }, member) {
      const { ubuUserId } = member;
      return WorkspaceProvider
        .deleteMember(member)
        .then(({ response }) => {
          if (response) {
            // Gestion des réponses
            commit('UbuUser/REMOVE_UBUUSER', ubuUserId, { root: true });
            commit('Channel/UNSET_CHANNEL_HAS_UBUUSER', ubuUserId, { root: true });
          }
        })
        .catch((error) => {
          console.log('🚀 ~ file: TheWorkspace.store.js ~ line 60 ~ addTag ~ error', error);
        });
    },

    addTag({ commit }, tag) {
      return WorkspaceProvider
        .addTag(tag)
        .then(({ error, response }) => {
          if (error) return { error };

          const newTag = {
            [response._id]: {
              ...response,
              threadGroupId: response._id,
              channelId: response.channel,
            },
          };
          commit('CustomTag/SET_CUSTOM_TAGS', newTag, { root: true });
          commit('ThreadGroup/SET_THREAD_GROUP', newTag, { root: true });
          commit('Channel/SET_CHANNELS_HAS_THREAD_GROUPS', [{ threadGroupId: response._id, channelId: response.channel }], { root: true });
          commit('Channel/SET_CHANNELS_HAS_CUSTOM_TAGS', [{ threadGroupId: response._id, channelId: response.channel }], { root: true });

          return { response };
        })
        .catch((error) => {
          console.log('🚀 ~ file: TheWorkspace.store.js ~ line 60 ~ addTag ~ error', error);
        });
    },

    updateTag({ commit }, tag) {
      return WorkspaceProvider
        .updateTag(tag)
        .then(({ error, response }) => {
          if (error) return { error };

          const updatedTag = {
            [tag.threadGroupId]: {
              ...tag,
              title: tag.new_title,
            },
          };
          commit('CustomTag/SET_CUSTOM_TAGS', updatedTag, { root: true });
          commit('ThreadGroup/SET_THREAD_GROUP', updatedTag, { root: true });

          return { response };
        })
        .catch((error) => {
          console.log('🚀 ~ file: TheWorkspace.store.js ~ line 84 ~ updateTag ~ error', error);
        });
    },

    updateTagWithData({ commit }, tag) {
      const stringifiedTag = {
        ...tag,
        data: JSON.stringify(tag.data),
      };
      return WorkspaceProvider
        .updateTag(stringifiedTag)
        .then(({ response, error }) => {
          if (error) return { error };

          if (response) {
            const updatedTag = {
              [tag.threadGroupId]: {
                ...tag,
              },
            };
            commit('CustomTag/SET_CUSTOM_TAGS', updatedTag, { root: true });
            commit('ThreadGroup/SET_THREAD_GROUP', updatedTag, { root: true });
          }
          return { response };
        })
        .catch((error) => {
          console.log('🚀 ~ file: TheWorkspace.store.js ~ line 132 ~ updateTagWithKw ~ error', error);
          return { error };
        });
    },

    deleteTag({ commit }, tag) {
      const { threadGroupId } = tag;
      return WorkspaceProvider
        .deleteTag(tag)
        .then(({ error, response }) => {
          if (error) return { error };

          commit('CustomTag/REMOVE_CUSTOM_TAGS', threadGroupId, { root: true });
          commit('ThreadGroup/REMOVE_THREAD_GROUP', threadGroupId, { root: true });
          commit('Channel/UNSET_CHANNEL_HAS_THREAD_GROUPS', threadGroupId, { root: true });
          commit('Channel/UNSET_CHANNEL_HAS_CUSTOM_TAGS', threadGroupId, { root: true });

          return { response };
        })
        .catch((error) => {
          console.log('🚀 ~ file: TheWorkspace.store.js ~ line 84 ~ updateTag ~ error', error);
        });
    },

    addContactLabel({ commit }, contactLabel) {
      return WorkspaceProvider
        .addContactLabel(contactLabel)
        .then(({ error, response }) => {
          if (error) return { error };

          const newContactLabel = {
            [response._id]: {
              ...response,
              contactGroupId: response._id,
              channelId: response.channel,
            },
          };
          commit('ContactGroup/SET_CONTACT_GROUP', newContactLabel, { root: true });
          commit('CustomTag/SET_CUSTOM_TAGS', newContactLabel, { root: true });
          commit('Channel/SET_CHANNELS_HAS_CUSTOM_TAGS', [{ contactGroupId: response._id, channelId: response.channel }], { root: true });
          commit('Channel/SET_CHANNELS_HAS_CONTACT_GROUPS', [{ contactGroupId: response._id, channelId: response.channel }], { root: true });

          return { response };
        })
        .catch((error) => {
          console.log('🚀 ~ file: TheWorkspace.store.js ~ line 144 ~ addTag ~ error', error);
        });
    },

    updateContactLabel({ commit }, contactLabel) {
      return WorkspaceProvider
        .updateContactLabel(contactLabel)
        .then(({ error, response }) => {
          if (error) return { error };

          const updatedContactLabel = {
            [contactLabel.contactGroupId]: {
              ...contactLabel,
              title: contactLabel.new_title,
            },
          };
          commit('ContactGroup/SET_CONTACT_GROUP', updatedContactLabel, { root: true });
          commit('CustomTag/SET_CUSTOM_TAGS', updatedContactLabel, { root: true });

          return { response };
        })
        .catch((error) => {
          console.log('🚀 ~ file: TheWorkspace.store.js ~ line 167 ~ updateTag ~ error', error);
        });
    },

    deleteContactLabel({ commit }, contactLabel) {
      const { contactGroupId } = contactLabel;
      return WorkspaceProvider
        .deleteTag(contactLabel)
        .then(({ error, response }) => {
          if (error) return { error };

          commit('ContactGroup/REMOVE_CONTACT_GROUP', contactGroupId, { root: true });
          commit('CustomTag/REMOVE_CUSTOM_TAGS', contactGroupId, { root: true });
          commit('Channel/UNSET_CHANNEL_HAS_CONTACT_GROUP', contactGroupId, { root: true });
          commit('Channel/UNSET_CHANNEL_HAS_CUSTOM_TAGS', contactGroupId, { root: true });

          return { response };
        })
        .catch((error) => {
          console.log('🚀 ~ file: TheWorkspace.store.js ~ line 190 ~ updateTag ~ error', error);
        });
    },

    addTemplate({ commit }, template) {
      return WorkspaceProvider
        .addTemplate(template)
        .then(({ error, response }) => {
          if (error) return { error };

          const newTemplate = {
            [response._id]: {
              ...response,
              templateId: response._id,
              channelId: response.channel,
            },
          };
          commit('Template/SET_TEMPLATES', newTemplate, { root: true });
          commit('Channel/SET_CHANNELS_HAS_TEMPLATES', [{ templateId: response._id, channelId: response.channel }], { root: true });

          return { response };
        })
        .catch((error) => {
          console.log('🚀 ~ file: TheWorkspace.store.js ~ line 231 ~ addTag ~ error', error);
        });
    },

    updateTemplate({ commit }, template) {
      console.log('🚀 ~ file: TheWorkspace.store.js ~ line 210 ~ updateTemplate ~ template', template);
      return WorkspaceProvider
        .updateTemplate(template)
        .then(({ error, response }) => {
          if (error) return { error };

          const updatedTemplate = {
            [template.templateId]: {
              ...template,
              title: template.new_title,
            },
          };
          commit('Template/SET_TEMPLATES', updatedTemplate, { root: true });
          commit('CustomTag/SET_CUSTOM_TAGS', updatedTemplate, { root: true });

          return { response };
        })
        .catch((error) => {
          console.log('🚀 ~ file: TheWorkspace.store.js ~ line 231 ~ addTag ~ error', error);
        });
    },

    deleteTemplate({ commit }, template) {
      const { templateId } = template;
      return WorkspaceProvider
        .deleteTag(template)
        .then(({ error, response }) => {
          if (error) return { error };

          commit('Template/REMOVE_TEMPLATE', templateId, { root: true });
          commit('CustomTag/REMOVE_CUSTOM_TAGS', templateId, { root: true });
          commit('Channel/UNSET_CHANNEL_HAS_TEMPLATES', templateId, { root: true });
          commit('Channel/UNSET_CHANNEL_HAS_CUSTOM_TAGS', templateId, { root: true });

          return { response };
        })
        .catch((error) => {
          console.log('🚀 ~ file: TheWorkspace.store.js ~ line 281 ~ deleteTemplate ~ error', error);
        });
    },

    getInviteToken({ commit }, channelId) {
      return WorkspaceProvider
        .getInviteToken(channelId)
        .then(({ error, response }) => {
          console.log('🚀 ~ file: TheWorkspace.store.js ~ line 322 ~ .then ~ response', response);
          console.log('🚀 ~ file: TheWorkspace.store.js ~ line 322 ~ .then ~ error', error);
          if (response) {
            commit('SET_CURRENT_INVITE_TOKEN', response);

            // delete token after deadlines (2h = 7200000 ms)
            setTimeout(() => {
              commit('UNSET_CURRENT_INVITE_TOKEN');
            }, 7200000);
            return { response };
          }
          return { error };
        })
        .catch((error) => {
          console.log('🚀 ~ file: TheWorkspace.store.js ~ line 329 ~ deleteTemplate ~ error', error);
        });
    },

    addChannel(_, newChannel) {
      return WorkspaceProvider
        .addChannel(newChannel)
        // .then(({ error, response }) => {
        //   console.log('🚀 ~ file: TheWorkspace.store.js ~ line 345 ~ .then ~ response', response);
        //   console.log('🚀 ~ file: TheWorkspace.store.js ~ line 345 ~ .then ~ error', error);
        // })
        .catch((error) => {
          console.log('🚀 ~ file: TheWorkspace.store.js ~ line 329 ~ deleteTemplate ~ error', error);
        });
    },

    removeChannel({ commit }, channelId) {
      return WorkspaceProvider
        .removeChannel(channelId)
        .then(({ error, response }) => {
          console.log('🚀 ~ file: TheWorkspace.store.js ~ line 357 ~ .then ~ response', response);
          console.log('🚀 ~ file: TheWorkspace.store.js ~ line 345 ~ .then ~ error', error);
          commit('Channel/UNSET_CHANNEL', channelId, { root: true });
        })
        .catch((error) => {
          console.log('🚀 ~ file: TheWorkspace.store.js ~ line 329 ~ deleteTemplate ~ error', error);
        });
    },

    getFbAdAccounts({ commit }, { fbId }) {
      return yaerApi.api.adAccount.report({ payload: { fbId } })
        .then(({ result }) => {
          console.log('Result is', result);
          commit('SET_FACEBOOK_ADACCOUNTS', result || []);
        });
    },
    setAdAccountId({ commit }, { fbId, adAccountId }) {
      return yaerApi.api.adAccount.set({ payload: { fbId, adAccountId } })
        .then(() => commit('Channel/SET_AD_ACCOUNT_ID', adAccountId, { root: true }));
    },
    createUtm({ rootGetters }, { shopifyShopSsid, newUtms }) {
      const { 'Channel/getterCurrentChannelId': channelId } = rootGetters;

      return Promise.all(newUtms.map((utm) => yaerApi.api.shopifyUtm.post({ payload: { channelId, shopifyShopSsid, utm } })));
    },
    reportUtm({ commit, rootGetters }, shopifyShopSsid) {
      const { 'Channel/getterCurrentChannelId': channelId } = rootGetters;

      return yaerApi.api.shopifyUtm.report({ payload: { channelId, shopifyShopSsid } })
        .then(({ result }) => {
          if (!result) return;

          const mappedUtm = result.map((utm) => ({ ...utm, utmId: utm._id }));
          commit('SET_SHOPIFY_UTM_LIST', mappedUtm);
        });
    },
  },
  mutations: {
    SET_CURRENT_INVITE_TOKEN: (state, token) => { state.currentInviteToken = token; },
    SET_SHOPIFY_UTM_LIST: (state, newData) => { state.shopifyUtmList = newData; },
    UNSET_CURRENT_INVITE_TOKEN: (state) => { state.currentInviteToken = null; },
    SET_FACEBOOK_ADACCOUNTS: (state, adAccounts) => { state.ownedAdAccounts = adAccounts; },
    RESET_STORES(state) {
      state.currentInviteToken = null;
      state.ownedAdAccounts = [];
    },
  },
};
