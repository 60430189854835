<template>
  <UbuCommonMessageCard
    :is-me="isMe"
    :date="date"
    is-text
  >
    <template v-slot:content>
      <p v-if="!isMe">@{{ username }} shared a {{ itemType }}</p>
      <p v-else>You shared a {{ itemType }}</p>
      <p>not supported for now</p>
      <a
        class="ubu-instalink"
        :href="`https://instagram.com/direct/t/${threadId}`"
        target="_blank"
      >See in instagram&nbsp;<UbuIcon name="externalLink" /></a>
    </template>
  </UbuCommonMessageCard>
</template>

<script>
export default {
  name: 'UbuCommonUnsupportedType',
  props: {
    threadId: {
      type: String,
      default: null,
    },
    isMe: {
      type: Boolean,
      default: false,
    },
    date: {
      type: String,
      required: true,
    },
    username: {
      type: String,
      default: '',
    },
    itemType: {
      type: String,
      required: true,
    },
  },
};
</script>
