<template lang="">
  <div class="UbuContactName_wrapper">
    <div class="ellipsis ubu-subtitle">{{ fullname }}</div>
    <UbuInstaName
      class="ubu-instalink"
      :username="contact.username"
    />
  </div>
</template>
<script>
export default {
  name: 'UbuContactName',
  props: ['contact'],
  computed: {
    fullname() {
      return this.contact
                && (this.contact.full_name
                || this.contact.username
                || 'Unfoundable name');
    },
  },
};
</script>

<style lang="scss" scoped>
.UbuContactName_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
