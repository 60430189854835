<template>
  <header
    id="UbuInfluenceHeader"
    class="multibar row-vcentered"
  >
    <div
      id="UbuInfluenceHeader_titles"
      class="start multibar row-vcentered"
    >
      <UbuIcon
        size="36px"
        name="influence"
        filled
        class="start"
      />
      <div class="start">
        <h3 class="title">
          {{ campaign.name }}
        </h3>
        <h3 class="subtitle">
          {{ $moment(campaign.created).format('YYYY-MM-DD') }}
        </h3>
      </div>
    </div>

    <nav class="end">
      <router-link
        :to="`/influence/${campaign.campaignId}/pipeline`"
        class="button is-rounded"
      >
        Pipeline
      </router-link>
      <router-link
        :to="`/influence/${campaign.campaignId}/messages`"
        class="button is-rounded"
      >
        Messages
      </router-link>
      <router-link
        :to="`/influence/${campaign.campaignId}/leads`"
        class="button is-rounded"
      >
        Leads
      </router-link>
      <router-link
        :to="`/influence/${campaign.campaignId}/mentions`"
        class="button is-rounded"
      >
        Mentions
      </router-link>
      <router-link
        :to="`/influence/${campaign.campaignId}/analytics`"
        class="button is-rounded"
      >
        Analytics
      </router-link>
      <router-link
        :to="`/influence/${campaign.campaignId}/settings`"
        class="button is-rounded"
      >
        Settings
      </router-link>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'UbuInfluenceHeader',
  props: {
    campaign: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
#UbuInfluenceHeader {
  border-bottom: 1px solid var(--cloudburst10);
  height: 75px;
  & #UbuInfluenceHeader_titles {
    margin: 16px 24px;
    & .UbuIcon_wrapper {
      margin-right: 14px;
      & i {
        color: var(--campaign-color);
      }
    }
    & .title {
      color: var(--campaign-color);
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
    }
    & .subtitle {
      color: var(--campaign-color);
      font-weight: 500;
      font-size: 0.85rem;
      line-height: 17px;
    }
  }
  & nav {
    margin: 16px 24px;
    color: var(--soapstone);
    font-weight: 600;
    font-size: 0.85rem;
    line-height: 20px;
    & .button {
      margin: 0 6px;
      border: none;
      color: var(--campaign-color);
      background-color:var(--soapstone);
      height: 34px;
      font-size: 0.85rem;
      &.router-link-active {
        color: var(--soapstone);
        background-color: var(--campaign-color);
      }
    }
  }
}
</style>
