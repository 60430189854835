<template>
  <div
    id="UbuContactCard"
    class="multibar"
  >
    <!-- <UbuInstaImg
      class="UbuTheInfluence_avatar start"
      :insta-src="contact.profile_pic_url"
      :fallback="'../../img/no_profile_pict.svg'"
      rounded
    /> -->
    <!-- <UbuInstaImg
      class="UbuTheInfluence_avatar start"
      :insta-src="contact.has_bucket_media
        ? String(contact.contactId)
        : '../../img/no_profile_pict.svg'"
      :has-bucket="contact.has_bucket_media || false"
      :bucket-folder="'profile_pic'"
      :fallback="'../../img/no_profile_pict.svg'"
      rounded
    /> -->
    <b-image
      v-if="contact.has_bucket_media"
      rounded
      class="UbuTheInfluence_avatar start"
      :placeholder="'../../img/no_profile_pict.svg'"
      :src-fallback="'../../img/no_profile_pict.svg'"
      :src="bucketUrl"
      lazy
      v-bind="$attrs"
      v-on="$listeners"
    />
    <b-image
      v-else
      rounded
      class="UbuTheInfluence_avatar start"
      :placeholder="'../../img/no_profile_pict.svg'"
      :src-fallback="'../../img/no_profile_pict.svg'"
      :src="'../../img/no_profile_pict.svg'"
      lazy
      v-bind="$attrs"
      v-on="$listeners"
    />
    <div
      id="UbuContactCard_content"
    >
      <UbuContactName :contact="contact" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'UbuContactCard',
  props: {
    contact: {
      type: Object,
      required: true,
    },
  },
  computed: {
    bucketUrl() {
      // @TODO check encodedValidity
      return `https://io.getubu.com/profile_pic/${String(this.contact.contactId)}`;
    },
  },
};
</script>
