<template>
  <div>
    <h3 class="ubu-welcome py-6">404... We couldn’t find the page you’re looking for 😢</h3>
    <a
      href="/"
    >
      <UbuButton
        grow
        center
        big
        secondary
      >
        Go home
      </UbuButton>
    </a>
  </div>
</template>

<script>

export default {
  name: 'TheError404',
};
</script>
