<template>
  <div
    id="UbuAnalyticsPostModal"
  >
    <div
      :class="[ 'UbuAnalyticsPostModal_image',
                {'isPost': !media.isStory, 'isStory': media.isStory, }]"
    >
      <div @click="playPause()">
        <b-icon
          v-if="media.mediaType === 'VIDEO' && mediaLoaded"
          :icon="isVideoPlaying ? 'pause' : 'play'"
          size="is-medium"
          class="playButton"
        />
      </div>
      <b-image
        v-if="media.mediaType === 'IMAGE' || media.mediaType === 'CAROUSEL_ALBUM'"
        :src="media.mediaUrl"
        responsive
        lazy
      />
      <video
        v-else
        :ref="media.mediaUrl"
        @loadeddata="mediaLoaded = true"
      >
        <source
          :src="media.mediaUrl"
          :type="'video/mp4'"
        >
        Sorry, your browser doesn't support embedded videos.
      </video>
    </div>
    <div class="UbuAnalyticsPostModal_stats">
      <UbuCommonAnalyticsCard
        v-for="(item, index) in media.analyticsDataList"
        :key="index"
        :value="item.value"
        :title="item.name"
        :icon="item.icon"
        :color="item.color"
        :size="50"
        :is-percent="item.isPercent"
        is-small
      />
    </div>
  </div>
</template>

<script>

export default {
  name: 'UbuAnalyticsPostModal',
  props: {
    media: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      mediaLoaded: false,
      isVideoPlaying: false,
    };
  },
  computed: {
  },
  methods: {
    playPause() {
      if (this.isVideoPlaying) this.$refs[this.media.mediaUrl].pause();
      else this.$refs[this.media.mediaUrl].play();
      this.isVideoPlaying = !this.isVideoPlaying;
    },
  },
};
</script>
