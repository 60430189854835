import { use, registerComponent } from '../../$utils/plugins';

// const UbuContactPanelHeader = () => import(/* webpackChunkName: "addonContactPanel" */ './UbuContactPanelHeader');
// const UbuContactPanelInfos = () => import(/* webpackChunkName: "addonContactPanel" */ './UbuContactPanelInfos');
// const UbuContactPanelNotes = () => import(/* webpackChunkName: "addonContactPanel" */ './UbuContactPanelNotes');
// const UbuContactPanelMentions = () => import(/* webpackChunkName: "addonContactPanel" */ './UbuContactPanelMentions');
// const UbuContactPanelMessages = () => import(/* webpackChunkName: "addonContactPanel" */ './UbuContactPanelMessages');
// const UbuContactPanelOrders = () => import(/* webpackChunkName: "addonContactPanel" */ './UbuContactPanelOrders');
// const UbuContactPanelReport = () => import(/* webpackChunkName: "addonContactPanel" */ './UbuContactPanelReport');
// const UbuContactDataRow = () => import(/* webpackChunkName: "addonContactPanel" */ './UbuContactDataRow');
// const UbuContactDataCategory = () => import(/* webpackChunkName: "addonContactPanel" */ './UbuContactDataCategory');

import UbuContactPanelHeader from './UbuContactPanelHeader';
import UbuContactPanelInfos from './UbuContactPanelInfos';
import UbuContactPanelNotes from './UbuContactPanelNotes';
import UbuContactPanelMentions from './UbuContactPanelMentions';
import UbuContactPanelMessages from './UbuContactPanelMessages';
import UbuContactPanelOrders from './UbuContactPanelOrders';
import UbuContactPanelReport from './UbuContactPanelReport';
import UbuContactDataRow from './UbuContactDataRow';
import UbuContactDataCategory from './UbuContactDataCategory';
import UbuContactPanelShopifyCard from './UbuContactPanelShopifyCard';
import UbuContactPanelModalAddShopifyLink from './UbuContactPanelModalAddShopifyLink';
import UbuContactPanelModalTiktokUsername from './UbuContactPanelModalTiktokUsername';
import UbuContactPanelModalShopifyLinkUtmCustom from './UbuContactPanelModalShopifyLinkUtmCustom';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, UbuContactPanelHeader);
    registerComponent(Vue, UbuContactPanelInfos);
    registerComponent(Vue, UbuContactPanelNotes);
    registerComponent(Vue, UbuContactPanelMentions);
    registerComponent(Vue, UbuContactPanelMessages);
    registerComponent(Vue, UbuContactPanelOrders);
    registerComponent(Vue, UbuContactPanelReport);
    registerComponent(Vue, UbuContactDataRow);
    registerComponent(Vue, UbuContactDataCategory);
    registerComponent(Vue, UbuContactPanelShopifyCard);
    registerComponent(Vue, UbuContactPanelModalAddShopifyLink);
    registerComponent(Vue, UbuContactPanelModalTiktokUsername);
    registerComponent(Vue, UbuContactPanelModalShopifyLinkUtmCustom);
  },
};

use(Plugin);

export default Plugin;

export {
  UbuContactPanelHeader,
  UbuContactPanelInfos,
  UbuContactPanelNotes,
  UbuContactPanelMentions,
  UbuContactPanelMessages,
  UbuContactPanelOrders,
  UbuContactPanelReport,
  UbuContactDataRow,
  UbuContactDataCategory,
  UbuContactPanelShopifyCard,
  UbuContactPanelModalAddShopifyLink,
  UbuContactPanelModalTiktokUsername,
  UbuContactPanelModalShopifyLinkUtmCustom,
};
