<template>
  <UbuCommonMessageCard
    id="UbuCommonStoryReact"
    :is-me="isMe"
    :date="date"
    :is-story-expired="isStoryExpired"
    :is-story="isStory"
    :is-media="isMedia"
    @turnOnMedia="turnOnMedia()"
    @deleteMessage="$emit('deleteMessage')"
  >
    <template v-slot:image>
      <div
        v-if="hasBucket || !isStoryExpired"
        class="ubuStoryExpiredPlaceholder"
      >
        <UbuCommonVideo
          v-if="hasVideo && hasBucket"
          :video-url="videoUrl"
          :image-url="imageUrl"
          :has-bucket="hasBucket"
          @turnOnMedia="turnOnMedia()"
        />
        <UbuCommonImage
          v-else
          :url="imageUrl"
          :has-bucket="hasBucket"
        />
      </div>
      <div
        v-else
        class="ubuStoryExpiredPlaceholder"
      >
        <span>Expired</span>
      </div>
    </template>
    <template v-slot:content>
      <div class="card-content-text">
        <span
          v-if="isMe"
          class="ubuSubtitle"
        >
          Reacted to their story
        </span>
        <span
          v-else
          class="ubuSubtitle"
        >
          Reacted to your story
        </span>
        <div
          v-if="reactEmoji"
          class="card-content-reaction"
        >
          {{ reactEmoji }}
        </div>
      </div>
    </template>
  </UbuCommonMessageCard>
</template>

<script>
import MessageCardMixin from '../$mixins/MessageCardMixin';

export default {
  name: 'UbuCommonStoryReact',
  mixins: [MessageCardMixin],
  props: {
    reactEmoji: {
      type: String,
      default: '',
    },
  },
};
</script>
