<template>
  <div id="UbuFilterSelector">
    <button
      :class="['filterSelector-button ubu-text1', {'active': active}]"
      @click="showModal = !showModal"
    >
      {{ buttonText }}
    </button>
    <div
      v-if="showModal"
      class="filterSelector-modal"
    >
      <span class="ubu-text1 filterSelector-title">Range of followers</span>
      <div class="filterSelector-container">
        <b-slider
          v-model="range"
          :min="0"
          :max="5"
          aria-label="Fan"
          :tooltip="false"
          :step="1"
        >
          <b-slider-tick
            v-for="(tick, index) in ticks"
            :key="index"
            :value="index"
          >
            {{ tick.text }}
          </b-slider-tick>
        </b-slider>
      </div>
      <div class="filterSelector-modal-button-group">
        <UbuButton
          grow
          center
          class="filterSelector-modal-button"
          @click="reset()"
        >
          Reset
        </UbuButton>
        <UbuButton
          grow
          center
          secondary
          class="filterSelector-modal-button"
          @click="applyFilters()"
        >
          Apply filter
        </UbuButton>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'UbuFollowersSelector',
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    media: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showModal: false,
      range: [0, 5],
      ticks: [
        { text: '0', value: 0 },
        { text: '3k', value: 3000 },
        { text: '10k', value: 10000 },
        { text: '50k', value: 50000 },
        { text: '200k', value: 200000 },
        { text: 'No limit', value: Infinity },
      ],
    };
  },
  computed: {
    buttonText() {
      if (this.range[0] === 0 && this.range[1] === 5) {
        return '+ Range of followers';
      }
      if (this.range[0] === this.range[1]) {
        return this.ticks[this.range[0]].text;
      }
      return `${this.ticks[this.range[0]].text} - ${this.ticks[this.range[1]].text}`;
    },
    active() {
      return this.showModal || (this.range[0] !== 0 || this.range[1] !== 5);
    },
  },
  methods: {
    reset() {
      this.range = [0, 5];
      this.showModal = false;
      const rangeValues = [this.ticks[this.range[0]].value, this.ticks[this.range[1]].value];
      this.$emit('setFollowersFilter', rangeValues);
    },
    applyFilters() {
      this.showModal = false;
      const rangeValues = [this.ticks[this.range[0]].value, this.ticks[this.range[1]].value];
      this.$emit('setFollowersFilter', rangeValues);
    },
  },
};
</script>
