<template>
  <div class="flex">
    <UbuUserPicture
      class="mr-2"
      :initial-name="user.initials"
      :color="user.color"
    />
    <div
      v-if="user"
      class="a-self-center"
    >
      <div class="ubu-text1">{{ user.name }}</div>
      <div class="ubu-subtext1 text-cloudburst60">{{ user.email || 'N/A' }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UbuWorkspaceUserTemplate',
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
};
</script>
