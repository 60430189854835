<template>
  <div class="UbuContactPanelMentions_wrapper">
    <div class="flex mb-2">
      <UbuButton
        grow
        center
        :active="showStory"
        simple
        class="UbuContactPanelMentions_button mr-1"
        @click="showStory = true"
      >
        Stories
      </UbuButton>
      <UbuButton
        grow
        center
        :active="!showStory"
        simple
        class="UbuContactPanelMentions_button"
        @click="showStory = false"
      >
        Posts
      </UbuButton>
    </div>
    <div
      v-if="medias"
      class="UbuContactPanelMentions_list"
    >
      <UbuMentionSmall
        v-for="(media, index) in filteredMentions"
        :key="index"
        class="UbuContactPanelMentions_mention"
        :media="media"
        @showMention="showMention(media)"
      />
    </div>
    <div
      v-if="!isLoading && !medias.length"
      class="UbuContactPanelMentions_empty ubu-subtext1"
    >
      No mentions
    </div>
    <b-modal
      v-model="isModalMentionOpened"
      :width="270"
      scroll="keep"
    >
      <UbuMentionSmall
        class="UbuContactPanelMentions_mentionOpened"
        :media="currentMedia"
        :is-modal="true"
      />
    </b-modal>
  </div>
</template>
<script>
import UbuMentionSmall from './UbuMentionSmall';

export default {
  name: 'UbuContactPanelMentions',
  components: {
    UbuMentionSmall,
  },
  props: {
    medias: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isModalMentionOpened: false,
      currentMedia: {},
      showStory: true,
    };
  },
  computed: {
    filteredMentions() {
      if (this.showStory) return this.medias.filter((m) => m.productType === 'story');
      return this.medias.filter((m) => m.productType === 'post');
    },
  },
  methods: {
    showMention(media) {
      this.currentMedia = media;
      this.isModalMentionOpened = !this.isModalMentionOpened;
    },
  },
};
</script>
<style lang="scss" scoped>
.UbuContactPanelMentions_wrapper {
  .UbuContactPanelMentions_button{
    width: 50%;
  }
  .UbuContactPanelMentions_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .UbuContactPanelMentions_mention {
    width: calc((100% / 2) - 6px);
    // height: 210px;
    margin-bottom: 8px;
    cursor: pointer;
  }
  .UbuContactPanelMentions_mentionOpened {
    height: 100%;
    width: 100%;
  }

  .UbuContactPanelMentions_empty {
    display: flex;
    flex-direction: column;
    color: var(--darkgrey);
    margin: 24px;
    align-items: center;
  }
}
</style>
