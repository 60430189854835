import { use, registerComponent } from '../../$utils/plugins';

import UbuSidebarCustoms from './UbuSidebarCustoms';
import UbuSidebarChannelSelector from './UbuSidebarChannelSelector';
import UbuSidebarTopbar from './UbuSidebarTopbar';
// const UbuSidebarCustoms = () => import('./UbuSidebarCustoms');
// const UbuSidebarChannelSelector = () => import('./UbuSidebarChannelSelector');
// const UbuSidebarTopbar = () => import('./UbuSidebarTopbar');

const Plugin = {
  install(Vue) {
    registerComponent(Vue, UbuSidebarCustoms);
    registerComponent(Vue, UbuSidebarChannelSelector);
    registerComponent(Vue, UbuSidebarTopbar);
  },
};

use(Plugin);

export default Plugin;

export {
  UbuSidebarCustoms,
  UbuSidebarChannelSelector,
  UbuSidebarTopbar,
};
