<template>
  <RecycleScroller
    v-slot="{ item }"
    :item-size="72"
    :buffer="600"
    :items="fetchDone ? threadList : awaitingList"
    key-field="threadId"
    class="UbuThreads_list ubu-scrollbar"
  >
    <article
      v-if="!fetchDone"
      class="threadCardSkeleton multibar row-vcentered"
    >
      <div class="start">
        <b-skeleton
          circle
          width="40px"
          height="40px"
        />
      </div>
      <div class="center">
        <div class="multibar row-vcentered">
          <b-skeleton
            active
            width="200px"
          />
          <b-skeleton
            active
            width="20px"
          />
        </div>
        <div class="multibar row-vcentered">
          <b-skeleton
            active
            width="200px"
          />
          <b-skeleton
            active
            width="20px"
          />
        </div>
      </div>
    </article>

    <UbuInfluenceMessageThreadCard
      v-else
      :contact="item.contact"
      :last-item="item.last_permanent_item"
      :source="item.source"
      :contact-group="item.contactGroups"
      :thread-group="item.threadGroups"
      :is-unread="item.isUnread"
      :is-bulk-selected="!!bulkList.find(({ threadId }) => threadId === item.threadId)"
      :class="[{ 'is-selected': selectedThread.threadId === item.threadId}]"
      @onSelect="() => $emit('onSelectThread', item)"
      @onBulkSelect="$emit('onBulkSelect', item)"
    />
  </RecycleScroller>
</template>

<script>
const awaitingList = [];

for (let index = 0; index < 20; index += 1) {
  awaitingList.push({
    threadId: index,
  });
}

export default {
  name: 'UbuInfluenceMessageThreadList',
  props: {
    threadList: {
      type: Array,
      required: true,
    },
    selectedThread: {
      type: Object,
      required: true,
    },
    fetchDone: {
      type: Boolean,
      default: false,
    },
    bulkList: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    bulks: [],
    awaitingList,
    search: '',
    filtersOpen: false,
  }),
};
</script>

<style lang="scss">
.is-selected{
  background-color: var(--givry);
  & .UbuThreadCard_textBox {
    &:after {
      background: transparent !important;
    }
  }
}
.threadCardSkeleton {
  & .center {
    & > * {
      margin-top: 5px;
      margin-bottom: 5px;
      & :last-child {
        margin-top: 0px;
        margin-left: 5px;
      }
    }
  }
}
</style>
