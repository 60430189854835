import { stampToDatetime } from '../$utils/dates';
import { convertColor } from '../$utils/formate';

export default {
  methods: {
    convertColor(hex) {
      return convertColor(hex);
    },
    convertFromColor(hex) {
      let color;
      switch (Number(hex)) {
        case 1: color = '#4D4D4D'; break;
        case 2: color = '#FF6060'; break;
        case 3: color = '#FFA471'; break;
        case 4: color = '#FFC670'; break;
        case 5: color = '#6BCE3D'; break;
        case 6: color = '#73DA8A'; break;
        case 7: color = '#43D2C1'; break;
        case 8: color = '#3CA6F2'; break;
        case 9: color = '#6F6DE3'; break;
        case 10: color = '#B98DF0'; break;
        case 11: color = '#F882EC'; break;
        case 12: color = '#FF62A4'; break;
        default: color = '#4D4D4D'; break;
      }
      return color;
    },

    /**
     * @method mentionFilter
     * Used to map mentions into mentions with 'when' as timestamp converted
     * Remove mentions younger than campaign creation
     *
     * @param  {Array} mentionList
     * @param  {Array} campaignCreated
     * @return {Array}             mapped mention > campaign.created
     */
    mentionFilter(mentionList, addedOn) {
      const campaignCreatedAt = this.$moment(addedOn);

      return mentionList
        .map((mention) => {
          const { timestamp, ...restMention } = mention;

          return { ...restMention, when: stampToDatetime(timestamp).format() };
        })
        .filter(({ when }) => this.$moment(when).valueOf() > campaignCreatedAt.valueOf());
    },

    /**
         * @method tagPostFilter
         * Used to map tagPost into tagPost with 'when' as taken_at timestamp converted
         * Remove tagPosts younger than campaign creation
         *
         * @param  {Array} tagPostList
         * @param  {Array} campaignCreated
         * @return {Array}             mapped tagPost > campaign.created
         */
    tagPostFilter(tagPostList, addedOn) {
      const campaignCreatedAt = this.$moment(addedOn);

      return tagPostList
        .map((tagPost) => {
          // eslint-disable-next-line camelcase
          const { taken_at, ...restTagPost } = tagPost;
          return { ...restTagPost, when: stampToDatetime(taken_at).format() };
        })
        .filter(({ when }) => this.$moment(when).valueOf() > campaignCreatedAt.valueOf());
    },
  },
};
