<template>
  <div
    v-if="mentions.length > 0"
    class="comment-body"
  >
    <p>
      <template
        v-for="(item, i) in content"
      >
        <span
          v-if="!item.mention"
          :key="i"
        >
          {{ item.text }}
        </span>
        <a
          v-else
          :key="i"
          :href="item.href"
          target="blank"
          class="mention-link"
        >
          {{ item.text }}
        </a>
      </template>
    </p>
  </div>
  <div
    v-else
    class="comment-body"
  >
    {{ comment }}
  </div>
</template>

<script>
export default {
  name: 'UbuCommonCommentBody',
  props: {
    commentId: {
      type: String,
      default: null,
    },
    comment: {
      type: String,
      default: '',
    },
    mentions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      content: null,
    };
  },
  mounted() {
    this.commentFormatted();
  },
  methods: {
    commentFormatted() {
      const { comment, mentions } = this;
      const array = [];
      let index = 0;
      let i = 0;
      mentions.forEach((mention) => {
        const {
          href, length, offset,
        } = mention;
        array.push({ text: comment.substring(index, offset), mention: false });
        index = offset;
        array.push({ text: comment.substring(index, index + length), href, mention: true });
        index += length;
        i += 1;
      });
      if (mentions.length) {
        const lastMention = mentions[mentions.length - 1];
        array.push(
          {
            text: comment.substring(lastMention.offset + lastMention.length),
            mention: false,
          },
        );
      }
      this.content = array;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
