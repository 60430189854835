import { updateDatasIfExists } from '../$utils/dataMapper';
/* eslint-disable max-len */
export default {
  namespaced: true,

  state: {
    messages: {},
  },

  getters: {
    getterMessages: (state) => state.messages,
  },

  actions: {
    sendMessage() {

    },
  },
  mutations: {
    SET_MESSAGES(state, newDatas) { state.messages = updateDatasIfExists({ newDatas, actualDatas: state.messages, key: 'messageId' }); },
    REMOVE_MESSAGE(state, messageId) { delete state.messages[messageId]; },
    RESET_STORES(state) {
      state.messages = {};
    },
  },
};
