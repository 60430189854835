<template>
  <div>
    <div style="display: flex; flex-grow: 1; justify-content: center">
      <UbuSelector
        :values="values"
        :choices="items"
        :max="1"
        @select="select"
        @remove="remove"
        @create="select"
      />
      <UbuDropdownSelector
        :values="values"
        :items="items"
        label-prop="label"
        id-prop="id"
        @select="select"
        @remove="remove"
        @add="select"
      />
    </div>
    <pre>
      {{ values }}
      {{ items }}
    </pre>
  </div>
</template>

<script>
export default {
  name: 'TheDocumentationDropdownSelector',
  data() {
    return {
      values: [{
        id: 1,
        label: 'test',
        icon: 'influence',
        color: 1,
      }],
      items: [{
        id: 1,
        label: 'test',
        icon: 'influence',
        color: 1,
      }, {
        id: 2,
        label: 'yo',
        icon: 'tag',
        color: 2,
      }, {
        id: 3,
        label: 'Adrien',
        img: 'https://io.getubu.com/profile_pic/4543272141',
        color: 2,
      }],
    };
  },
  methods: {
    select(item) {
      this.values.push(item);
    },
    remove(item) {
      this.values = this.values.filter((i) => item.id !== i.id);
    },
  },
};
</script>

<style>

</style>
