<template>
  <div
    id="UbuContactPanelModalAddShopifyLink"
    class="workspace-modal"
  >
    <section>
      <div class="modal-header">
        <p class="modal-header-title ubu-title">Custom link with utm parameters</p>

        <UbuIcon
          name="cross"
          clickable
          @click="$emit('close', $event)"
        />
      </div>
    </section>

    <div>
      <div
        class="buttons"
        @click="$emit('close', $event)"
      >
        <router-link
          to="/workspace/shopify"
          class="ubu-info text-geraldine"
        >
          + Create new utm parameters
        </router-link>
      </div>

      <b-field>
        <b-select
          v-model="utmParameters.sourceCode"
          placeholder="Source utm..."
          expanded
        >
          <option
            v-for="option in sourceUtmParameters"
            :key="option.utmId"
            :value="option.value"
          >
            {{ option.value }}
          </option>
        </b-select>
      </b-field>

      <b-field>
        <b-select
          v-model="utmParameters.mediumCode"
          placeholder="Medium utm..."
          expanded
        >
          <option
            v-for="option in mediumUtmParameters"
            :key="option.utmId"
            :value="option.value"
          >
            {{ option.value }}
          </option>
        </b-select>
      </b-field>

      <b-field>
        <b-select
          v-model="utmParameters.campaignCode"
          placeholder="Campaign utm..."
          expanded
        >
          <option
            v-for="option in campaignUtmParameters"
            :key="option.utmId"
            :value="option.value"
          >
            {{ option.value }}
          </option>
        </b-select>
      </b-field>

      <b-field>
        <b-input
          disabled
          expanded
          :value="urlPreview"
        />
        <b-tooltip label="Click to copy">
          <b-button
            v-clipboard:copy="urlPreview"
            v-clipboard:success="onCopy"
            type="is-light"
            icon-right="content-copy"
          />
        </b-tooltip>
      </b-field>

      <div class="buttons">
        <b-button
          class="is-geraldine is-rounded"
          label="Close"
          @click="$emit('close', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { snackSuccess } from '../../$catalog/snackbar';

export default {
  name: 'UbuContactPanelModalShopifyLinkUtmCustom',
  props: {
    shopifyUtmList: {
      type: Array,
      default: () => [],
    },
    selectedLink: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      utmParameters: {
        sourceCode: null,
        mediumCode: null,
        campaignCode: null,
      },
    };
  },
  computed: {
    sourceUtmParameters() {
      return this.shopifyUtmList.length ? this.shopifyUtmList.filter(({ type }) => type === 'source') : [];
    },
    mediumUtmParameters() {
      return this.shopifyUtmList.length ? this.shopifyUtmList.filter(({ type }) => type === 'medium') : [];
    },
    campaignUtmParameters() {
      return this.shopifyUtmList.length ? this.shopifyUtmList.filter(({ type }) => type === 'campaign') : [];
    },
    // urlPreview() {
    //   return this.baseUrl;
    // },
    urlPreview() {
      const { link, type } = this.selectedLink;
      let url = link;

      if (type === 'referral') {
        const queries = [];

        // eslint-disable-next-line max-len
        if (this.utmParameters.sourceCode || this.utmParameters.mediumCode || this.utmParameters.campaignCode) {
          url += '&';
        }

        if (this.utmParameters.sourceCode) {
          queries.push(`utm_source=${this.utmParameters.sourceCode}`);
        }
        if (this.utmParameters.mediumCode) {
          queries.push(`utm_medium=${this.utmParameters.mediumCode}`);
        }
        if (this.utmParameters.campaignCode) {
          queries.push(`utm_campaign=${this.utmParameters.campaignCode}`);
        }

        url += queries.join('&');
      }
      if (type === 'discount') {
        // eslint-disable-next-line max-len
        if (this.utmParameters.sourceCode || this.utmParameters.mediumCode || this.utmParameters.campaignCode) {
          url += '?';

          const queries = [];

          if (this.utmParameters.sourceCode) {
            queries.push(`utm_source=${this.utmParameters.sourceCode}`);
          }
          if (this.utmParameters.mediumCode) {
            queries.push(`utm_medium=${this.utmParameters.mediumCode}`);
          }
          if (this.utmParameters.campaignCode) {
            queries.push(`utm_campaign=${this.utmParameters.campaignCode}`);
          }

          url += queries.join('&');
        }
      }
      return url;
    },
  },
  methods: {
    onCopy() {
      this.$buefy.snackbar.open({
        message: 'Copied to clipboard.',
        ...snackSuccess,
      });
    },
  },
};
</script>

<style>

</style>
