import builder from '../../$utils/axios';
import fileBuilder from '../../$utils/axiosFile';

export default class GlobalProvider {
  static fetchContactsDb({ payload: { channelId, pks, usernames } }) {
    // console.log('🚀 ~ file: Channel.provider.js ~ line 6 ~ ChannelProvider ~ getThreadsDbByChannel ~ channelId', channelId);

    return builder({
      method: 'post',
      url: `/channel/${channelId}/usersDB`,
      data: { pks, usernames },
    });
  }

  static fetchTiktokUser({ channelId, username }) {
    // console.log('🚀 ~ file: Channel.provider.js ~ line 6 ~ ChannelProvider ~ getThreadsDbByChannel ~ channelId', channelId);

    return builder({
      method: 'get',
      url: `channel/${channelId}/tiktok_username/${username}`,
    });
  }

  static updateTiktokUsername({ channelId, contactId, username }) {
    return builder({
      method: 'post',
      url: `/channel/${channelId}/user/${contactId}/tiktokUsername`,
      data: { new_username: username },
    });
  }

  static getThreadsInfluenceDbByChannel({ payload: { channelId } }) {
    // console.log('🚀 ~ file: Channel.provider.js ~ line 6 ~ ChannelProvider ~ getThreadsDbByChannel ~ channelId', channelId);

    return builder({
      method: 'get',
      url: `/channel/${channelId}/threadsInfluenceDB`,
    });
  }

  static getThreadsContactsDbByChannel({ payload: { channelId } }) {
    // console.log('🚀 ~ file: Channel.provider.js ~ line 6 ~ ChannelProvider ~ getThreadsContactsDbByChannel ~ channelId', channelId);

    return builder({
      method: 'get',
      url: `/channel/${channelId}/threadsContactsDB`,
    });
  }

  static getThreadsDbClosedByChannel({ payload: { channelId } }) {
    // console.log('🚀 ~ file: Channel.provider.js ~ line 6 ~ ChannelProvider ~ getThreadsDbClosedByChannel ~ channelId', channelId);

    return builder({
      method: 'get',
      url: `/channel/${channelId}/threadsDBClosed`,
    });
  }

  static getThreadsDBLabeledByChannel({ payload: { channelId } }) {
    // console.log('🚀 ~ file: Channel.provider.js ~ line 6 ~ ChannelProvider ~ getThreadsDbClosedByChannel ~ channelId', channelId);

    return builder({
      method: 'get',
      url: `/channel/${channelId}/threadsDBLabeled`,
    });
  }

  static getThreadsDBTagguedByChannel({ payload: { channelId } }) {
    // console.log('🚀 ~ file: Channel.provider.js ~ line 6 ~ ChannelProvider ~ getThreadsDbClosedByChannel ~ channelId', channelId);

    return builder({
      method: 'get',
      url: `/channel/${channelId}/threadsDBTaggued`,
    });
  }

  static getThreadsDbByChannel({ payload: { channelId } }) {
    // console.log('🚀 ~ file: Channel.provider.js ~ line 6 ~ ChannelProvider ~ getThreadsDbClosedByChannel ~ channelId', channelId);

    return builder({
      method: 'get',
      url: `/channel/${channelId}/threadsDB`,
    });
  }

  static getOneThreadDbByChannel({ payload: { channelId, threadId } }) {
    // console.log('🚀 ~ file: Channel.provider.js ~ line 6 ~ ChannelProvider ~ getThreadsDbClosedByChannel ~ channelId', channelId);

    return builder({
      method: 'get',
      url: `/channel/${channelId}/thread/${threadId}/getThreadDB`,
    });
  }

  static getThreadsByChannel({ payload: { channelId } }) {
    // console.log('🚀 ~ file: Channel.provider.js ~ line 6 ~ ChannelProvider ~ getThreadsDbClosedByChannel ~ channelId', channelId);

    return builder({
      method: 'get',
      url: `/channel/${channelId}/threads`,
    });
  }

  static getThreadsPendingByChannel({ payload: { channelId } }) {
    // console.log('🚀 ~ file: Channel.provider.js ~ line 6 ~ ChannelProvider ~ getThreadsDbClosedByChannel ~ channelId', channelId);

    return builder({
      method: 'get',
      url: `/channel/${channelId}/threadsPending`,
    });
  }

  static getListsByChannel({ payload: { channelId } }) {
    // console.log('🚀 ~ file: global.provider.js ~ line 88 ~ GlobalProvider ~ getListsByChannel ~ channelId', channelId);
    return builder({
      method: 'get',
      url: `/channel/${channelId}/influence_list_orders`,
    });
  }

  static getCustomListsByChannel({ payload: { channelId } }) {
    return builder({
      method: 'get',
      url: `/channel/${channelId}/custom_list_orders`,
    });
  }

  static getCampaignsByChannel({ payload: { channelId } }) {
    // console.log('🚀 ~ file: Channel.provider.js ~ line 6 ~ ChannelProvider ~ getcampaigngsByChannel ~ channelId', channelId);

    return builder({
      method: 'get',
      url: `/channel/${channelId}/campaigns`,
    });
  }

  static getActivitiesByCampaignsByChannel({ payload: { channelId, campaignId } }) {
    // console.log('🚀 ~ file: Channel.provider.js ~ line 6 ~ ChannelProvider ~ getcampaigngsByChannel ~ channelId', channelId);

    return builder({
      method: 'get',
      url: `/channel/${channelId}/activities/${campaignId}`,
    });
  }

  static getCustomsByChannel({ payload: { channelId } }) {
    // console.log('🚀 ~ file: Channel.provider.js ~ line 6 ~ ChannelProvider ~ getTagsByChannel ~ channelId', channelId);

    return builder({
      method: 'get',
      url: `/channel/${channelId}/customs`,
    });
  }

  static getTagsByChannel({ payload: { channelId } }) {
    // console.log('🚀 ~ file: Channel.provider.js ~ line 6 ~ ChannelProvider ~ getTagsByChannel ~ channelId', channelId);

    return builder({
      method: 'get',
      url: `/channel/${channelId}/userTags`,
    });
  }

  static getMentionsByChannel({ payload: { channelId } }) {
    // console.log('🚀 ~ file: Channel.provider.js ~ line 6 ~ ChannelProvider ~ getMentionsByChannel ~ channelId', channelId);

    return builder({
      method: 'get',
      url: `/channel/${channelId}/mentions`,
    });
  }

  static getChannels() {
    // console.log('🚀 ~ file: Channel.provider.js ~ line 6 ~ ChannelProvider ~ getChannels ~');

    return builder({
      method: 'get',
      url: '/channels',
    });
  }

  static getFbChannels() {
    // console.log('🚀 ~ file: Channel.provider.js ~ line 6 ~ ChannelProvider ~ getChannels ~');

    return builder({
      method: 'get',
      url: '/fbChannels',
    });
  }

  static isFacebookConnected({ payload: { username } }) {
    // console.log('🚀 ~ file: Channel.provider.js ~ line 6 ~ ChannelProvider ~ getChannels ~');

    return builder({
      method: 'get',
      url: `/isFBConnected/${username}`,
    });
  }

  static getDisplayablesFromContact({ payload: { channelId, contactId } }) {
    return builder({ method: 'get', url: `/channel/${channelId}/user/${contactId}/details` });
  }

  static getMediasDB({ payload: { channelId } }) {
    // console.log('🚀 ~ file: Channel.provider.js ~ line 6 ~ ChannelProvider ~ getThreadsDbByChannel ~ channelId', channelId);

    return builder({
      method: 'get',
      url: `/channel/${channelId}/mediasDB`,
    });
  }

  static getAuthUser() {
    // console.log('🚀 ~ file: Channel.provider.js ~ line 6 ~ ChannelProvider ~ getThreadsDbByChannel ~ channelId', channelId);

    return builder({
      method: 'get',
      url: '/user',
    });
  }

  static sendImage({ payload: { channelId, threadId, image } }) {
    return fileBuilder({
      method: 'post',
      url: `/channel/${channelId}/thread/${threadId}/uploadPhoto`,
      data: image,
    });
  }

  static sendNote({
    channelId, threadId, text, timestamp,
  }) {
    const toStringify = {
      timestamp,
      text,
    };
    const data = { note: toStringify };
    // console.log('Data to send with note', data);
    return builder({ method: 'post', url: `/channel/${channelId}/thread/${threadId}/note`, data });
  }
}
