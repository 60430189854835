<template>
  <div class="multibar column row-start ubuDropDown">
    <b-button
      v-if="fromStatus === 'scheduled'"
      class="button-change-status is-left-based"
      @click="$emit('deleteActivity')"
    >
      <UbuIcon name="trash" />
      <span>Delete</span>
    </b-button>

    <b-button
      v-if="fromStatus === 'scheduled'"
      class="button-change-status is-left-based"
      @click="$emit('showMessageSent')"
    >
      <UbuIcon name="messages" />
      <span>Show message</span>
    </b-button>

    <b-button
      v-for="(status, index) in moveTos"
      v-else
      :key="index"
      class="button-change-status is-left-based"
      @click="$emit('changeStatus', { username, toStatus: status.name, fromStatus })"
    >
      <UbuIcon
        size="16px"
        :name="status.icon"
        :color="status.color"
        outlined
      />
      <span class="ubu-text1">{{ status.name }}</span>
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'UbuInfluencePipelineButtonChangeStatus',
  inject: {
    moveTo: {
      // From TheInfluence
      default: [
        { name: 'InProgress', icon: 'send', color: '' },
        { name: 'Success', icon: 'success', color: '5' },
        { name: 'Failure', icon: 'failure', color: '2' },
      ],
    },
  },
  props: {
    username: {
      type: String,
      required: true,
    },
    fromStatus: {
      type: String,
      required: true,
    },
    moveTo: {
      type: Array,
      default() { return this.moveTo; },
    },
  },
  computed: {
    moveTos() {
      return this.moveTo.filter(({ name }) => name !== this.fromStatus);
    },
  },
};
</script>

<style lang="scss">
  .ubuDropDown {
    background-color: var(--soapstone);
    border-radius: 8px;
    border: 1px solid var(--mercury);
  }

  .button-change-status {
    background-color: transparent;
    border: none;
    &:hover {
      background-color: var(--mercury60);
    }
  }

</style>
