<template>
  <div class="comment-actions">
    <button
      v-if="options.like"
      class="comment-actions__button"
      @click="likeComment"
    >
      <i class="ubu-icons ubu-icons-heart-filled" />
    </button>
    <button
      class="comment-actions__button"
      @click="$emit('clickToReply', $event)"
    >
      <i class="ubu-icons ubu-icons-replies-filled" />
    </button>
    <b-dropdown
      v-if="options.activeGlobale"
      :triggers="['hover']"
      class="comment-actions__button-dropdown"
      position="is-top-left"
      aria-role="list"
    >
      <template #trigger>
        <button
          class="comment-actions__button"
          @click="optionComment"
        >
          <i class="ubu-icons ubu-icons-more-outlined" />
        </button>
      </template>

      <b-dropdown-item
        v-if="options.markAsRead"
        aria-role="listitem"
        @click="$emit('markAsRead', $event)"
      >
        Mark as read
      </b-dropdown-item>
      <b-dropdown-item
        v-if="options.report"
        aria-role="listitem"
        @click="$emit('reportComment', $event)"
      >
        Report
      </b-dropdown-item>
      <b-dropdown-item
        v-if="options.delete"
        aria-role="listitem"
        @click="$emit('delete', $event)"
      >
        Delete
      </b-dropdown-item>
      <UbuCommonTranslationDropdownItem
        v-if="options.translation.activeTranslate"
        :trad-fr="options.translation.traduction_fr"
        :trad-en="options.translation.traduction_en"
        :trad-de="options.translation.traduction_de"
        :trad-es="options.translation.traduction_es"
        @translateMessage="$emit('translateMessage', $event)"
      />
    </b-dropdown>
  </div>
</template>

<script>
// !!! TODO !!!
// - Action resend
export default {
  name: 'UbuCommonCommentActions',
  props: {
    options: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      open: false,
    };
  },
  methods: {
    likeComment() {
      console.log('likeComment');
    },
    replyComment() {
      console.log('replyComment');
    },
    optionComment() {
      this.open = !this.open;
    },
    resendComment() {
      console.log('resendComment');
    },
  },
};
</script>
