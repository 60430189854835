import GlobalProvider from '../providers/global.provider';
import CommentsProvider from '../providers/comments.provider';

const internals = {
  channelIds: [],
  channelsHasCampaigns: {},
  channelsHasLists: {},
  fetchChannels: () => GlobalProvider
    .getChannels()
    .then((response) => {
      if (response.error) {
        // Gestion des erreurs
      }
      return response;
    }),
  fetchFbChannel: () => GlobalProvider
    .getFbChannels()
    .then((response) => {
      if (response.error) {
        // Gestion des erreurs
      }
      return response;
    }),
  fetchMediasDB: ({ payload: { channelId } }) => CommentsProvider
    .getMediasDB({ payload: { channelId } })
    .then((response) => {
      // console.log('🚀 ~ file: internals.js ~ line 27 ~ .then ~ channelId', channelId);
      if (response.error) {
        // Gestion des erreurs
      }
      return ({ ...response, channelId });
    }),
  fetchMedias: ({ payload: { channelId } }) => CommentsProvider
    .getMedias({ payload: { channelId } })
    .then((response) => {
      // console.log('🚀 ~ file: internals.js ~ line 27 ~ .then ~ channelId', channelId);
      if (response.error) {
        // Gestion des erreurs
      }
      return ({ ...response, channelId });
    }),
  fetchComments: ({ payload: { channelId, mediaFBId } }) => CommentsProvider
    .getComments({ payload: { channelId, mediaFBId } })
    .then((response) => {
      if (response.error) {
        // Gestion des erreurs
      }
      return ({ ...response, channelId, mediaFBId });
    }),
  fetchCommentsInsta: ({ payload: { channelId, mediaFBId } }) => CommentsProvider
    .getCommentsInsta({ payload: { channelId, mediaFBId } })
    .then((response) => {
      // console.log('🚀 ~ file: internals.js ~ line 52 ~ .then ~ response', response);
      if (response.error) {
        // Gestion des erreurs
      }
      return ({ ...response, channelId, mediaFBId });
    }),
  fetchListsByChannel: (channelId) => GlobalProvider
    .getListsByChannel({ payload: { channelId } })
    .then((response) => {
      if (response.error) {
        // Gestion des erreurs
      }
      return ({ channelId, ...response });
    }),
  fetchCustomListsByChannel: (channelId) => GlobalProvider
    .getCustomListsByChannel({ payload: { channelId } })
    .then((response) => {
      if (response.error) {
        // Gestion des erreurs
      }
      return ({ channelId, ...response });
    }),
  fetchCampaignsByChannel: (channelId) => GlobalProvider
    .getCampaignsByChannel({ payload: { channelId } })
    .then((response) => {
      if (response.error) {
        // Gestion des erreurs
      }
      return ({ channelId, ...response });
    }),
  fetchActivitiesByCampaignsByChannel: (channelId, campaignId) => GlobalProvider
    .getActivitiesByCampaignsByChannel({ payload: { channelId, campaignId } })
    .then((response) => {
      if (response.error) {
        // Gestion des erreurs
      }
      return ({ channelId, campaignId, ...response });
    })
    .catch(console.log),
  fetchCustomsByChannel: (channelId) => GlobalProvider
    .getCustomsByChannel({ payload: { channelId } })
    .then((response) => {
      if (response.error) {
        // Gestion des erreurs
      }
      return ({ channelId, ...response });
    }),
  fetchTagsByChannel: (channelId) => GlobalProvider
    .getTagsByChannel({ payload: { channelId } })
    .then((response) => {
      if (response.error) {
        // Gestion des erreurs
      }
      return ({ channelId, ...response });
    }),
  fetchMentionsByChannel: (channelId) => GlobalProvider
    .getMentionsByChannel({ payload: { channelId } })
    .then((response) => {
      if (response.error) {
        // Gestion des erreurs
      }
      return ({ channelId, ...response });
    }),
  fetchThreadsInfluenceDbByChannel: (channelId) => GlobalProvider
    .getThreadsInfluenceDbByChannel({ payload: { channelId } })
    .then((response) => {
      if (response.error) {
        // Gestion des erreurs
      }
      return ({ channelId, ...response });
    }),
  fetchThreadsDbByChannel: (channelId) => GlobalProvider
    .getThreadsDbByChannel({ payload: { channelId } })
    .then((response) => {
      if (response.error) {
        // Gestion des erreurs
      }
      return ({ channelId, ...response });
    }),
  fetchOnThreadDbByChannel: (channelId, threadId) => GlobalProvider
    .getOneThreadDbByChannel({ payload: { channelId, threadId } })
    .then((response) => {
      if (response.error) {
        // Gestion des erreurs
      }
      return ({ channelId, ...response });
    }),
  fetchThreadsDBLabeledByChannel: (channelId) => GlobalProvider
    .getThreadsDBLabeledByChannel({ payload: { channelId } })
    .then((response) => {
      if (response.error) {
        // Gestion des erreurs
      }
      return ({ channelId, ...response });
    }),
  fetchThreadsDBTagguedByChannel: (channelId) => GlobalProvider
    .getThreadsDBTagguedByChannel({ payload: { channelId } })
    .then((response) => {
      if (response.error) {
        // Gestion des erreurs
      }
      return ({ channelId, ...response });
    }),
  fetchThreadsByChannel: (channelId) => GlobalProvider
    .getThreadsByChannel({ payload: { channelId } })
    .then((response) => {
      if (response.error) {
        // Gestion des erreurs
      }
      return ({ channelId, ...response });
    }),
  fetchThreadsPendingByChannel: (channelId) => GlobalProvider
    .getThreadsPendingByChannel({ payload: { channelId } })
    .then((response) => {
      if (response.error) {
        // Gestion des erreurs
      }
      return ({ channelId, ...response });
    }),
  fetchThreadsDbClosedByChannel: (channelId) => GlobalProvider
    .getThreadsDbClosedByChannel({ payload: { channelId } })
    .then((response) => {
      if (response.error) {
        // Gestion des erreurs
      }
      return ({ channelId, ...response });
    }),
  fetchThreadsContactsDbByChannel: (channelId) => GlobalProvider
    .getThreadsContactsDbByChannel({ payload: { channelId } })
    .then((response) => {
      if (response.error) {
        // Gestion des erreurs
      }
      return ({ channelId, ...response });
    }),
  // fetchMediasDB: (channelId) => GlobalProvider
  //   .getMediasDB({ payload: { channelId } })
  //   .then((response) => {
  //     if (response.error) {
  //       // Gestion des erreurs
  //     }
  //     return ({ channelId, ...response });
  //   }),
  fetchDisplayablesFromContact: (channelId, contactId) => GlobalProvider
    .getDisplayablesFromContact({ payload: { channelId, contactId } })
    .then((response) => {
      if (response.error) {
        // Gestion des erreurs
      }
      return ({ channelId, contactId, ...response });
    }),

  fetchAuthUserInfos: () => GlobalProvider
    .getAuthUser()
    .then((response) => {
      // console.log('Internals response', response);
      if (response.error) {
        // yo
        return Promise.reject(response.error);
      }
      return { ...response.response };
    }),

  setThreadClosed: (channelId, threadId, closed) => GlobalProvider
    .setThreadClosed({ payload: { channelId, threadId, closed } })
    .then(({ error }) => {
      if (error) return { error };
      return { response: true };
    }),

  sendImage: (channelId, threadId, image) => GlobalProvider
    .sendImage({ payload: { channelId, threadId, image } })
    .then(({ response, error }) => {
      if (error) return { error };
      return { ...response };
    }),

  setThreadRead: (channelId, threadId) => GlobalProvider
    .setThreadRead({ payload: { channelId, threadId } })
    .then((response) => {
      if (response.error) {
        // error
      }
      return true;
    }),

  setThreadMute: (channelId, threadId, isMuted) => GlobalProvider
    .setThreadMute({ payload: { channelId, threadId, isMuted } })
    .then((response) => {
      if (response.error) {
        // error
      }
      return true;
    }),
};

export default internals;
