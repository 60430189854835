export default {
  props: {
    imageUrl: {
      type: String,
      default: '',
    },
    videoUrl: {
      type: String,
      default: '',
    },
    isStoryExpired: {
      type: Boolean,
      default: false,
    },
    hasBucket: {
      type: Boolean,
      default: false,
    },
    hasVideo: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
    date: {
      type: String,
      required: true,
    },
    isMe: {
      type: Boolean,
      required: true,
    },
    username: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isStory: true,
      isMedia: false,
    };
  },
  methods: {
    turnOnMedia() {
      if (this.hasBucket || !this.isStoryExpired) {
        this.isStory = false;
        this.isMedia = true;
      }
    },
  },
};
