<template>
  <b-taglist
    attached
    class="unwrappable"
  >
    <b-tag
      v-if="hasIconTag"
      :class="['ubuTag', `tag-color-${tag.color}`]"
      rounded
    >
      <UbuIcon
        :name="iconTag.name"
        :size="iconTag.size"
        filled
      />
    </b-tag>
    <b-tooltip
      v-if="hasTooltip"
      :label="tag.campaignStep"
      position="is-top"
    >
      <b-tag
        :class="['ubuTag', `tag-color-${tag.color}`]"
        rounded
        ellipsis
      >
        {{ tag.title }}
      </b-tag>
      <b-tag
        v-if="hasActionTag"
        class="is-light"
        rounded
      >
        <UbuIcon
          :name="iconTag.name"
          :size="iconTag.size"
          filled
          :clickable="true"
          @click="$emit(eventName)"
        />
      </b-tag>
    </b-tooltip>
    <template v-else>
      <b-tag
        :class="['ubuTag', `tag-color-${tag.color}`]"
        rounded
        ellipsis
      >
        {{ tag.title }}
      </b-tag>
      <b-tag
        v-if="hasActionTag"
        class="is-light"
        rounded
      >
        <UbuIcon
          :name="iconTag.name"
          :size="iconTag.size"
          filled
          :clickable="true"
          @click="$emit(eventName)"
        />
      </b-tag>
    </template>
  </b-taglist>
</template>

<script>
export default {
  name: 'UbuCRMTableTag',
  props: {
    tag: {
      type: Object,
      default: () => {},
    },
    eventName: {
      type: String,
      default: 'clickOnTag',
    },
    hasActionTag: {
      type: Boolean,
      default: false,
    },
    hasIconTag: {
      type: Boolean,
      default: false,
    },
    hasTooltip: {
      type: Boolean,
      default: false,
    },
    iconTag: {
      type: Object,
      default: () => ({
        name: 'add',
        size: '12px',
      }),
    },
  },
};
</script>

<style>

</style>
