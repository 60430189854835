<template>
  <div class="UbuSimpleInput_wrapper">
    <div
      v-if="$slots.placeholderIcon "
      class="UbuSimpleInput_placeholderIcon"
    >
      <slot name="placeholderIcon" />
    </div>
    <input
      v-focus="focus"
      :value="value"
      v-bind="$attrs"
      :class="['UbuSimpleInput', {
        'UbuSimpleInput_searchBar': searchbar,
        'UbuSimpleInput_hasPlaceholderIcon': !!$slots.placeholderIcon,
      }]"
      v-on="inputListeners"
    >

    <div class="UbuSimpleInput_appendIcon">
      <slot name="append-icon">
        <UbuIcon
          v-if="value.length > 0"
          clickable
          name="cross"
          @click="() => $emit('input', '')"
        />
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UbuSimpleInput',
  props: {
    value: String,
    searchbar: Boolean,
    focus: Boolean,
  },
  computed: {
    inputListeners() {
      const vm = this;
      return {
        ...this.$listeners,
        input: (event) => {
          // console.log('Yo', event);
          vm.$emit('input', event.target.value);
        },
      };
    },
  },
};
</script>

<style lang="scss">
  // Cette règle est en ress et n'est pas chargée avant le clic...
  button, input, select, textarea {
    background-color: transparent;
    border-style: none;
  }
    .UbuSimpleInput_wrapper {
        position: relative;
        width: 100%;
        & .UbuSimpleInput {
            height: 35px;
            width: 100%;
            color: var(--cloudburst);
            font-weight: 500;
            font-size: 0.85rem;
            &::placeholder {
                color: var(--darkgrey60);
            }
            &:focus {
                outline: none;
            }
            &.UbuSimpleInput_searchBar {
                background-color: var(--mercury60);
                padding: 8.5px 10px 8.5px 10px;
                border-radius: 12px;
            }

            &.UbuSimpleInput_hasPlaceholderIcon {
                padding-left: 40px;
            }
        }

        .UbuSimpleInput_placeholderIcon {
            position: absolute;
            top: 0;
            left: 14px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            color: var(--darkgrey);
        }

        .UbuSimpleInput_appendIcon {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            width: auto;
            right: 12px;
            top: 0;
            height: 100%;
            color: var(--darkgrey);
            > * { padding-left: 6px };
        }

    }
</style>
