<template>
  <section id="TheInbox">
    <TheSidebar
      :menu-items="menu"
    >
      <div
        v-if="fetchInboxIsDone"
        slot-scope="{ isReduced }"
      >
        <div
          :style="!isReduced ? 'opacity: 0;' : ''"
          class="ubu-divider"
        />
        <UbuSidebarCustoms
          v-if="tagMenu"
          :is-reduced="isReduced"
          style="margin: 12px 0;"
          type="tag"
          :items="tagMenu"
          icon="tag"
          new-placeholder="+ new tag"
        />
        <div
          :style="!isReduced ? 'opacity: 0;' : ''"
          class="ubu-divider"
        />
        <UbuSidebarCustoms
          v-if="folderMenu"
          :is-reduced="isReduced"
          style="margin: 12px 0;"
          type="folder"
          :items="folderMenu"
          icon="contact"
          new-placeholder="+ new contact folder"
        />
      </div>
    </TheSidebar>
    <div
      v-if="fetchInboxIsDone"
      class="TheInbox_wrapper"
    >
      <UbuThreadList
        :key="`${$route.name}/${$route.params.value || show}`"
        v-model="usernameSearch"
        :threads="visibleThreads"
        :selected-thread-id="selectedThread ? selectedThread.threadId : null"
        :selected-thread="selectedThread"
        :template-list="templateList"
        :sort.sync="sort"
        :filter.sync="filter"
        :bulks.sync="bulks"
        :sort-options="sortOptions"
        :filter-options="filterOptions"
        :filter-box-is-open.sync="filterBoxIsOpen"
        :user-fetched="userFetched"
        :is-fetching="isFetching"
        @onSelectThread="selectThread($event)"
        @onToggleFilterPanel="resetToDefaultFilter($event)"
        @createThread="createThread($event)"
        @closeModal="closeModal($event)"
      >
        <template
          slot="header"
          name="threadListHeader"
        >
          <UbuTHCustomHeader
            v-if="currentThreadGroup || currentContactGroup"
            :item="currentThreadGroup || currentContactGroup"
            :icon="currentThreadGroup ? 'tag' : 'contact'"
          />
          <UbuTHShowClosed
            v-else
            :value="show"
            :closed-availables="true"
            @input="(evt) => { selectedThreadIndex = 0; show = evt; }"
          />
        </template>
        <div
          slot="bulkActions"
          slot-scope="{ bulks }"
          class="TheInbox_bulkActions"
        >
          <UbuSelector
            reduced
            :values="threadGroupItems(getIntersection(bulks, 'threadGroups'))"
            :choices="threadGroupItems(threadGroupsWithThreads)"
            :paths="{ id: 'threadGroupId', label: 'title' }"
            label-icon="tag"
            :tooltip="{
              label: `Tag ${bulks.length} conversation${bulks.length !== 1 ? 's' : ''}`,
              position: 'is-bottom'
            }"
            search-placeholder="Create or search tags..."
            create-placeholder="Create new tag:"
            @select="assignThreadsToThreadGroup({ threads: bulks, threadGroup: $event })"
            @remove="removeThreadsFromThreadGroup({ threads: bulks, threadGroup: $event })"
            @create="createThreadGroupAndAssignMany({ threads: bulks, ...$event })"
          />
          <UbuSelector
            reduced
            :values="contactGroupItems(getIntersection(bulks, 'contactGroups'))"
            :choices="contactGroupItems(contactGroupsWithThreads)"
            :paths="{ id: 'contactGroupId', label: 'title' }"
            label-icon="contact"
            :tooltip="{
              label: `Add ${bulks.length} as contact${bulks.length !== 1 ? 's' : ''}`,
              position: 'is-bottom'
            }"
            search-placeholder="Create or search contacts..."
            create-placeholder="Create new contact:"
            @select="assignContactsToContactGroup({ threads: bulks, contactGroup: $event })"
            @remove="removeContactsFromContactGroup({ threads: bulks, contactGroup: $event })"
            @create="createContactGroupAndAssignMany({ threads: bulks, ...$event})"
          />
          <UbuSelector
            reduced
            v-bind="campaignSelector.props"
            :choices="campaignItems(campaigns)"
            :values="campaignItems(getIntersection(bulks, 'campaigns'))"
            @select="assignContactsToCampaign({ threads: bulks, campaign: $event })"
          />
          <UbuButton
            v-if="currentThreadGroup"
            grow
            center
            color="2"
            :tooltip="{
              label: `Close and remove '${currentThreadGroup.title}'
              for ${bulks.length} thread${bulks.length !== 1 ? 's' : ''}`,
              position: 'is-bottom'
            }"
            :icon="{ name: 'success', filled: true }"
            @click="doneManyThreads({ threads: bulks, threadGroup: currentThreadGroup })"
          />
          <UbuButton
            v-else-if="allThreadsAreOpen(bulks)"
            color="5"
            center
            grow
            :tooltip="{
              label: `Open ${bulks.length} selected thread${bulks.length !== 1 ? 's' : ''}`,
              position: 'is-bottom'
            }"
            :icon="{ name: 'messages', filled: true }"
            @click="openManyThreads({ threads: bulks })"
          />
          <UbuButton
            v-else
            color="2"
            center
            grow
            :tooltip="{
              label: `Close ${bulks.length} selected thread${bulks.length !== 1 ? 's' : ''}`,
              position: 'is-bottom'
            }"
            :icon="{ name: 'success', filled: true }"
            @click="closeManyThreads({ threads: bulks })"
          />
        </div>
      </UbuThreadList>

      <TheThreadPanel
        v-if="!!selectedThread"
        :key="selectedThread.threadId"
        :selected-thread="selectedThread"
        :current-thread-group="currentThreadGroup"
        @selectNext="selectNextThread()"
        @openModalAddLink="$emit('openModalAddLink', $event)"
      />
      <TheContactPanel
        v-if="fetchInboxIsDone && !!selectedThread && selectedThread.contactId"
        :key="selectedThread.contactId"
        :contact-id="selectedThread.contactId"
        @onContactDataLoaded="checkLastPermanent({ thread: selectedThread })"
        @openModalAddLink="$emit('openModalAddLink', $event)"
      />
    </div>
    <div
      v-else
      class="TheInbox_empty"
    >
      <UbuLoader />
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { intersectionBy } from 'lodash';
import { threadsMeta } from '../$utils/threadUtilities';
import { navigationTooltip } from '../$catalog/tooltip';
import meta from './routesMeta';
import FilterThreadsMixin from './$mixins/FilterThreadsMixin';
import ThreadListMixin from './$mixins/ThreadListMixin';
import AddToCampaign from '@/addons/$mixins/AddToCampaign';
import { snackError, snackSuccess } from '../$catalog/snackbar';
import { debounce } from '../$utils/helpers';
// import { routeMapMeta } from './routes';

export default {
  name: 'TheInbox',
  mixins: [FilterThreadsMixin, ThreadListMixin, AddToCampaign],
  data: () => ({
    syncThreadIsLoading: false,
    selectedThreadIndex: 0,
    bulks: [],
    userFetched: [],
    usernameSearch: '',
    isFetching: false,
  }),
  computed: {
    ...mapGetters({
      selectedThread: 'TheInbox/getterCurrentThread',
      getterThreadsMap: 'TheInbox/getterThreadsMap',
      getterCurrentThreadId: 'TheInbox/getterCurrentThreadId',
      getterThreadsDatas: 'TheInbox/getterThreadsDatas',
      me: 'Authenticate/getterCurrentUbuUser',
      threadGroupsWithThreads: 'TheInbox/getterThreadGroupsWithThreads',
      contactGroupsWithThreads: 'TheInbox/getterContactGroupsWithThreads',
      fetchInboxIsDone: 'Sequence/getterFetchInbox',
      fetchIsDone: 'Sequence/getterFetchDone',
      fetchLabelled: 'Sequence/getterFetchThreadsDBLabeled',
      fetchContacts: 'Sequence/getterFetchContacts',
      currentChannelId: 'Channel/getterCurrentChannelId',
      templateList: 'TheWorkspace/getterTemplates',
    }),
    threads() {
      if (!this.fetchInboxIsDone) return [];
      if (this.$route.name === 'in.tag') { return [...this.currentThreadGroup.threads]; }
      if (this.$route.name === 'in.folder') { return [...this.currentContactGroup.threads]; }
      if (this.show === 'opened') { return [...this.boxThreads.opened]; }
      if (this.show === 'closed') { return [...this.boxThreads.closed]; }
      if (this.show === 'all') { return [...this.boxThreads.opened, ...this.boxThreads.closed]; }
      return [];
    },
    boxThreads() {
      const defaultIfNull = { opened: [], closed: [] };
      const {
        inbox, importants, assignment, opportunities, mentions, influence, all,
      } = this.getterThreadsDatas;

      if (!this.fetchInboxIsDone) { return { ...defaultIfNull }; }
      switch (this.$route.name) {
        case 'in.importants':
          return importants || { ...defaultIfNull };
        case 'in.mentions':
          return mentions || { ...defaultIfNull };
        case 'in.opportunities':
          return opportunities || { ...defaultIfNull };
        case 'in.influence':
          return influence || { ...defaultIfNull };
        case 'in.assignment':
          return assignment || { ...defaultIfNull };
        case 'in.all':
          return all || { ...defaultIfNull };
        case 'in.tag':
          return this.currentThreadGroup;
        case 'in.folder':
          return this.currentContactGroup;
        case 'in.inbox':
        default:
          return {
            opened: [...inbox.opened, ...opportunities.opened, ...importants.opened],
            closed: [...inbox.closed, ...opportunities.closed, ...importants.closed],
          };
      }
    },
    currentThreadGroup() {
      if (this.$route.name === 'in.tag' && this.$route.params.value && Array.isArray(this.threadGroupsWithThreads)) {
        return this.threadGroupsWithThreads
          .find((e) => e.threadGroupId === this.$route.params.value)
            || { threads: [], title: 'Unfoundable tag', color: 0 };
      }
      return null;
    },
    currentContactGroup() {
      if (this.$route.name === 'in.folder' && this.$route.params.value && Array.isArray(this.contactGroupsWithThreads)) {
        return this.contactGroupsWithThreads
          .find((e) => e.contactGroupId === this.$route.params.value)
            || { threads: [], title: 'Unfoundable contact', color: 0 };
      }
      return null;
    },
    menuMetas() {
      const {
        inbox, importants, opportunities, mentions, influence, assignment, all,
      } = this.getterThreadsDatas;
      if (this.fetchInboxIsDone) {
        return {
          'in.inbox': threadsMeta([...inbox.opened, ...importants.opened, ...opportunities.opened], meta.inbox),
          'in.importants': threadsMeta(importants.opened, meta.importants),
          'in.opportunities': threadsMeta(opportunities.opened, meta.opportunities),
          'in.mentions': threadsMeta(mentions.opened, meta.mentions),
          'in.influence': threadsMeta(influence.opened, meta.influence),
          'in.assignment': threadsMeta(assignment.opened, meta.assignment),
          'in.all': threadsMeta(all.opened, meta.assignment),
          'in.tag': this.threadGroupsWithThreads.reduce((acc, tgwt) => {
            acc[tgwt.threadGroupId] = {
              ...threadsMeta(tgwt.threads, meta.tag),
              pageTitle: `Tag ${tgwt.title}`,
            };
            return acc;
          }, {}),
          'in.folder': this.contactGroupsWithThreads.reduce((acc, cgwt) => {
            acc[cgwt.contactGroupId] = {
              ...threadsMeta(cgwt.threads, meta.tag),
              pageTitle: `Label ${cgwt.title}`,
            };
            return acc;
          }, {}),
        };
      }
      return {
        'in.inbox': { pageTitle: 'Loading Inbox' },
        'in.importants': { pageTitle: 'Loading importants' },
        'in.opportunities': { pageTitle: 'Loading opportunities' },
        'in.mentions': { pageTitle: 'Loading mentions' },
        'in.influence': { pageTitle: 'Loading inbox influence' },
        'in.tag': { pageTitle: 'Loading tag' },
        'in.folder': { pageTitle: 'Loading label' },
        'in.all': { pageTitle: 'Loading all' },
      };
    },

    menu() {
      if (!this.fetchInboxIsDone) return [];
      return [
        {
          icon: 'inbox',
          text: 'Inbox',
          href: '/in/inbox',
          ...this.menuMetas['in.inbox'],
          tooltip: {
            ...navigationTooltip,
            label: 'All new received untagged and unassigned  messages',
          },
        },
        {
          icon: 'star',
          text: 'Importants',
          href: '/in/importants',
          ...this.menuMetas['in.importants'],
          tooltip: {
            ...navigationTooltip,
            label: 'All new received important untagged and unassigned messages',
          },
        },
        {
          icon: 'mentions',
          text: 'Mentions',
          href: '/in/mentions',
          ...this.menuMetas['in.mentions'],
          tooltip: {
            ...navigationTooltip,
            label: 'All new received untagged and unassigned mentions',
          },
        },
        {
          icon: 'opportunities',
          text: 'Opportunities',
          href: '/in/opportunities',
          ...this.menuMetas['in.opportunities'],
          tooltip: {
            ...navigationTooltip,
            label: 'All new received untagged and unassigned messages related to collaboration opportunities',
          },
        },
        {
          icon: 'influence',
          text: 'Influence',
          href: '/in/influence',
          ...this.menuMetas['in.influence'],
          tooltip: {
            ...navigationTooltip,
            label: 'All the messages from your active influence campaigns',
          },
        },
        {
          icon: this.me ? {
            type: 'user', name: this.me.initials, color: this.me.color, size: 20,
          } : 'assignation',
          text: 'You',
          href: '/in/assignment',
          ...this.menuMetas['in.assignment'],
          tooltip: {
            ...navigationTooltip,
            label: 'All messages assigned to you',
          },
        },
        {
          icon: { name: 'messages', filled: false },
          text: 'All',
          href: '/in/all',
          ...this.menuMetas['in.all'],
          tooltip: {
            ...navigationTooltip,
            label: 'Just like your instagram feed, with the power of Ubu 💪',
          },
        },
      ];
    },
    tagMenu() {
      if (this.menuMetas && this.menuMetas['in.tag']) {
        return this.threadGroupsWithThreads.map((tgwt) => ({ ...tgwt, ...this.menuMetas['in.tag'][tgwt.threadGroupId] }));
      }
      return null;
    },
    folderMenu() {
      if (this.menuMetas && this.menuMetas['in.folder']) {
        return this.contactGroupsWithThreads.map((cgwt) => ({ ...cgwt, ...this.menuMetas['in.folder'][cgwt.contactGroupId] }));
      }
      return null;
    },
  },
  watch: {
    menuMetas: {
      handler() {
        this.setPageTitle();
      },
    },
    filteredThreads(value) {
      this.bulks = intersectionBy(value, this.bulks, 'threadId');
    },
    // eslint-disable-next-line func-names
    usernameSearch: debounce(function (newVal) {
      this.isFetching = true;
      this.userFetched = [];
      if (newVal === '') return;
      this._searchUser(
        { channelId: this.currentChannelId, username: newVal },
      )
        .then((response) => {
          this.userFetched = response;
          this.isFetching = false;
        });
    }, 700),
  },
  methods: {
    ...mapActions({
      sequence: 'TheInbox/sequence',
      $update_currentThreadId: 'TheInbox/$update_currentThreadId',
      fetchInitial: 'Sequence/fetchInitial',
      sendNote: 'TheInbox/sendNote',
      sendMessage: 'TheInbox/sendMessage',
      sendImage: 'TheInbox/sendImage',
      syncThread: 'TheInbox/syncThread',
      deleteMessage: 'TheInbox/deleteMessage',
      checkLastPermanent: 'TheInbox/checkLastPermanent',
      resetLastTts: 'TheInbox/resetLastTts',
      _searchUser: 'TheCRM/searchUser',
    }),
    createThread(event) {
      const { user: { pk, username }, message } = event;
      const messageParsed = message.replace('{name}', username);

      const snackToWait = this.$buefy.snackbar.open({
        ...snackSuccess,
        message: 'Sending message...',
      });

      this.sendMessage({
        channelId: this.currentChannelId,
        contactId: pk,
        message: messageParsed,
      })
        .then(({ error }) => {
          snackToWait.close();
          if (error) {
            this.$buefy.snackbar.open({
              ...snackError,
              message: 'Error while sending',
              actionText: 'Retry',
              onAction: () => this.createThread(event),
            });
          } else {
            this.$buefy.snackbar.open({
              ...snackSuccess,
              message: 'Message was sent successfully',
            });
            this.usernameSearch = '';
          }
        });
    },
    setPageTitle() {
      let metas = this.menuMetas[this.$route.name];
      if (metas) {
        if (this.$route.params.value) metas = metas[this.$route.params.value];
        if (metas) {
          const { count, hasUnread, pageTitle } = metas;
          const title = `${hasUnread ? '🔴 ' : ''}${count ? `(${count}) ` : ''} Inbox - ${pageTitle || ''} | Ubu`;
          document.title = title;
        }
      }
    },
    selectThread({ thread, replace }) {
      const { threadId } = thread;
      if (threadId !== this.selectedThreadId) { this.mergeQueryParams({ tId: threadId }, false, replace ? 'replace' : 'push'); }
    },
    closeModal() {
      this.usernameSearch = '';
    },
  },
};
</script>

<style lang="scss">
@import 'Inbox';

</style>
