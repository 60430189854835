<template>
  <section id="TheWorkspaceTheme">
    <h1 class="ubu-header1">Theme</h1>
    <div class="UbuWorkspace_list">
      <div
        v-for="(t) of themes"
        :key="`theme-${t.key}`"
        class="UbuWorkspaceTheme_row"
      >
        <UbuRadio
          v-model="localTheme"
          :native-value="t.key"
        />
        <img :src="`/img/${t.img}.svg`">
        <div class="UbuWorkspaceTheme_desc">
          <h2>{{ t.title }}</h2>
          <p>{{ t.description }}</p>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TheWorkspaceTheme',
  data: () => ({
    themes: [{
      key: 'classic',
      img: 'theme-light',
      title: 'Classic',
      description: 'Cute and elegant, just like you 😇',
    }, {
      key: 'dark',
      img: 'theme-dark',
      title: 'Dark',
      description: 'The dark side of the force is a pathway to many abilities... 😈',
    }, {
      key: 'auto',
      img: 'theme-auto',
      title: 'Auto',
      description: 'Let your routine guide you through your day 💪',
    }],
  }),
  computed: {
    ...mapGetters({
      theme: 'Theme/getterTheme',
    }),
    localTheme: {
      get() { return this.theme; },
      set(value) { this.UP_theme(value); },
    },
  },
  methods: {
    ...mapActions({
      UP_theme: 'Theme/setTheme',
    }),
  },
};
</script>
