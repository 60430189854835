<template>
  <UbuCommonMessageCard
    :is-me="isMe"
    :date="date"
    :download-url="downloadUrl"
    is-media
    @deleteMessage="$emit('deleteMessage')"
  >
    <template v-slot:image>
      <UbuCommonVideo
        :video-url="videoUrl"
        :image-url="imageUrl"
        :has-bucket="hasBucket"
        :mime-type="mimeType"
      />
    </template>
  </UbuCommonMessageCard>
</template>
<script>

export default {
  name: 'UbuCommonMediaForVideo',
  props: {
    isMe: {
      type: Boolean,
      default: false,
    },
    hasBucket: {
      type: Boolean,
      default: false,
    },
    date: {
      type: String,
      required: true,
    },
    videoUrl: {
      type: String,
      required: true,
    },
    imageUrl: {
      type: String,
      required: true,
    },
    mimeType: {
      type: String,
      default: 'video/mp4',
    },
    downloadUrl: {
      type: String,
      default: '',
    },
  },
};
</script>
