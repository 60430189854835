import builder from '../../$utils/axiosComments';

export default class CommentsProvider {
  static getMediasDB({ payload: { channelId } }) {
    // console.log('🚀 ~ file: comments.provider.js ~ line 5 ~ CommentsProvider ~ getMediasDB ~ channelId', channelId);

    return builder({
      method: 'get',
      url: `/channel/${channelId}/mediasDB`,
    });
  }

  static getMedias({ payload: { channelId } }) {
    // console.log('🚀 ~ file: Channel.provider.js ~ line 6 ~ ChannelProvider ~ getThreadsDbByChannel ~ channelId', channelId);

    return builder({
      method: 'get',
      url: `/channel/${channelId}/medias/null`,
    });
  }

  static getComments({ payload: { channelId, mediaFBId } }) {
    // console.log('🚀 ~ file: comments.provider.js ~ line 23 ~ CommentsProvider ~ getComments ~ mediaFBId', mediaFBId);
    // console.log('🚀 ~ file: comments.provider.js ~ line 23 ~ CommentsProvider ~ getComments ~ channelId', channelId);

    return builder({
      method: 'get',
      url: `/channel/${channelId}/commentsDB/${mediaFBId}`,
    });
  }

  static getCommentsInsta({ payload: { channelId, mediaFBId } }) {
    // console.log('🚀 ~ file: comments.provider.js ~ line 33 ~ CommentsProvider ~ getCommentsInsta ~ mediaFBId', mediaFBId);
    // console.log('🚀 ~ file: comments.provider.js ~ line 33 ~ CommentsProvider ~ getCommentsInsta ~ channelId', channelId);
    return builder({
      method: 'get',
      url: `/channel/${channelId}/commentsLimited/${mediaFBId}`,
    });
  }
}
