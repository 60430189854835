import { use, registerComponent } from '../../$utils/plugins';

// const UbuCRMTable = () => import(/* webpackChunkName: "addonCRM" */ './UbuCRMTable');
// const UbuCRMTableDetail = () => import(/* webpackChunkName: "addonCRM" */ './UbuCRMTableDetail');

import UbuCRMTable from './UbuCRMTable';
import UbuCRMTableDetail from './UbuCRMTableDetail';
import UbuCRMFilters from './UbuCRMFilters';
import UbuCRMTableTag from './UbuCRMTableTag';
import UbuCRMTableCustomCell from './UbuCRMTableCustomCell';
import UbuCRMModalAddList from './UbuCRMModalAddList';
import UbuCRMViewSettings from './UbuCRMViewSettings';
import UbuCRMViewModal from './UbuCRMViewModal';
import UbuCRMFilterGenerator from './UbuCRMFilterGenerator';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, UbuCRMTable);
    registerComponent(Vue, UbuCRMTableDetail);
    registerComponent(Vue, UbuCRMFilters);
    registerComponent(Vue, UbuCRMTableTag);
    registerComponent(Vue, UbuCRMTableCustomCell);
    registerComponent(Vue, UbuCRMModalAddList);
    registerComponent(Vue, UbuCRMViewSettings);
    registerComponent(Vue, UbuCRMViewModal);
    registerComponent(Vue, UbuCRMFilterGenerator);
  },
};

use(Plugin);

export default Plugin;

export {
  UbuCRMTable,
  UbuCRMTableDetail,
  UbuCRMFilters,
  UbuCRMTableTag,
  UbuCRMTableCustomCell,
  UbuCRMModalAddList,
  UbuCRMViewSettings,
  UbuCRMViewModal,
  UbuCRMFilterGenerator,
};
