import {
  parseAndKeepMaxOccurrence,
  getTitleListWithoutDuplicate,
  getAudienceListWithoutDuplicate,
  getListWithoutDuplicate,
  mapActivitiesFromCampaign,
  testDataLocation,
  calculateShopifyReferralAndDiscountCount,
  calculateShopifyOrderCount,
  calculateShopifyAmountOrders,
  parseGender,
  parseAge,
  parseEmail,
} from '../$utils/filterManagement';
import { snackSuccess, snackError } from '../../$catalog/snackbar';
import { tryParseJson } from '../../$utils/formate';

export default {
  computed: {
    channelId() {
      return this.campaignActivitiesDetails._id || '';
    },
    isEnrinchProfilesDisabled() {
      return this.checkedRows.length
        ? !!this.checkedRows.find((row) => row.followerCount < 5000 || row.is_private) : false;
    },
    localContacts() {
      const response = this.getter.contacts
        .map((account) => ({
          ...account,
          isHubspot: !!account.contactGroup.find(({ title }) => title === 'hubspot'),
          email: parseEmail(account),
          audience: account.audience ? tryParseJson(account.audience) : null,
          age: parseAge(account),
          gender: parseGender(account),
          ageAudience: parseAndKeepMaxOccurrence(account, 'ages'),
          genderAudience: parseAndKeepMaxOccurrence(account, 'genders'),
          locationAudience: parseAndKeepMaxOccurrence(account, 'cities'),
          languageAudience: parseAndKeepMaxOccurrence(account, 'languages'),
          language: !account.language || account.language === 'NA' ? '' : account.language,
          followerCount: Number(account.follower_count) || 0,
          engagement: Number(account.engagement) || null,
          storyMentionCount: account.mentions.length || 0,
          postMentionCount: account.tagsPosts.length || 0,
          lastMessageTS: account.lastMessageTS || null,
          tiktokUsername: account.tiktok_username && account.tiktok_username !== 'NA' ? account.tiktok_username : null,
          growth: account.growth || null,
          shopifyLinksCount: calculateShopifyReferralAndDiscountCount(account),
          shopifyAmountOrders: calculateShopifyAmountOrders(account),
          shopifyTotalOrders: calculateShopifyOrderCount(account),
          location: testDataLocation(account),
          activeCampaign: mapActivitiesFromCampaign(account, false),
          pastCampaign: mapActivitiesFromCampaign(account, true),
          lastMentionOrTagPost: this.findLastMentionOrTagPost(account),
          type: account.business_account_type || null,
          category: account.category_enum || null,
          contactGroups: account.contactGroup || [],
          follows: this.lists.reduce((acc, list) => {
            if (list.userPks.includes(account.pk)) acc.push(list.name);
            return acc;
          }, []),
          lastAddedCampaign: account.activities.length
            ? this.findMostRecentActiveCampaign(account.activities
              .filter(({ ended }) => !ended)
              .map((a) => ({ timestamp: this.$moment(a.created).valueOf() }))) : null,
        }))
        .reduce((acc, row) => {
          if (row.username) acc.push(row);
          return acc;
        }, []).map((account) => ({
          ...account,
          type: this.typeGenerator(account),
        })).filter((a) => a.engagement > 0.3 || a.lastMentionOrTagPost
        || a.contactGroup.length || a.threadGroup.length || a.activeCampaign.length
        || a.pastCampaign.length || a.lastMessageTS);

      return response;
    },
    campaignList() {
      return this.campaignActivitiesDetails.campaigns.map((campaign) => {
        const {
          name: title, color, campaignId, campaignHasUnread, ended, createdOn,
        } = campaign;
        return ({
          title, color: this.convertColor(color), campaignId, campaignHasUnread, ended, createdOn,
        });
      });
    },
    activeCampaigns() {
      return this.campaignList.filter(({ ended }) => !ended);
    },
    campaign() {
      return this.campaignActivitiesDetails.campaigns
        .find((campaign) => campaign.campaignId === this.$route.params.campaignId);
    },
    list() {
      return this.lists
        .find((list) => list.listId === this.$route.params.listId);
    },
    activeCampaignList() {
      return getTitleListWithoutDuplicate(this.localContacts, 'activeCampaign');
    },
    pastCampaignList() {
      return getTitleListWithoutDuplicate(this.localContacts, 'pastCampaign');
    },
    tagList() {
      return getTitleListWithoutDuplicate(this.localContacts, 'threadGroup');
    },
    labelList() {
      return getTitleListWithoutDuplicate(this.localContacts, 'contactGroup');
    },
    ageAudienceList() {
      return getAudienceListWithoutDuplicate(this.localContacts, 'ageAudience');
    },
    locationAudienceList() {
      return getAudienceListWithoutDuplicate(this.localContacts, 'locationAudience');
    },
    languageList() {
      return getListWithoutDuplicate(this.localContacts, 'language');
    },
    languageAudienceList() {
      return getAudienceListWithoutDuplicate(this.localContacts, 'languageAudience');
    },
    genderAudienceList() {
      return getAudienceListWithoutDuplicate(this.localContacts, 'genderAudience');
    },
    categoryEnumList() {
      return getListWithoutDuplicate(this.localContacts, 'type');
    },
    categoryList() {
      return getListWithoutDuplicate(this.localContacts, 'category');
    },
    locationList() {
      return getListWithoutDuplicate(this.localContacts, 'location');
    },
    genderList() {
      return getListWithoutDuplicate(this.localContacts, 'gender');
    },
  },
  methods: {
    createList(list) {
      this.addList({
        ...list,
        quota: list.quota / 2,
        channel: this.currentChannelId,
      }).then(() => {
        this.isModalAddListActive = false;
      })
        .catch((error) => {
          this.isModalAddListActive = false;
          this.$buefy.toast.open({
            duration: 5000,
            message: error.message,
            type: 'is-danger',
          });
        });
    },
    addCustom(event) {
      // console.log('🚀 ~ file: TheCRM.vue ~ line 375 ~ addCustom ~ event', event);
      if (event.type === 'contactGroup') {
        return this.assignContactToContactGroup({
          contactGroup: event.custom,
          thread: { contactId: event.row.contactId, channelId: event.row.channelId },
          username: event.row.username,
        });
      }
      if (event.type === 'threadGroup') {
        return this.assignThreadToThreadGroup({
          threadGroup: event.custom,
          thread: { threadId: event.row.threadId, channelId: event.row.channelId },
          username: event.row.username,
        });
      }
      if (event.type === 'campaign') {
        const data = {
          channelId: event.row.channelId,
          campaignId: event.custom.campaignId,
          activities: [{
            pk: event.row.contactId,
            username: event.row.username,
            status: 'unscheduled',
            channel: this.channelId,
            campaignId: event.custom.campaignId,
            campaign: event.custom.campaignId,
          }],
        };
        return this.setUnscheduled(data)
          .then(({ error }) => {
            if (error) {
              this.$buefy.snackbar.open({
                ...snackError,
                message: `Error while assigning "${event.row.username}" to campaign`,
                onAction: () => this.setUnscheduled(data),
              });
            } else {
              this.$buefy.snackbar.open({
                ...snackSuccess,
                message: `"${event.row.username}" has been assigned to campaign`,
              });
            }
          });
      }
      return undefined;
    },
    removeCustom(event) {
      // console.log('🚀 ~ file: TheCRM.vue ~ line 379 ~ removeCustom ~ event', event);
      if (event.type === 'contactGroup') {
        return this.removeContactFromContactGroup({
          contactGroup: event.custom,
          thread: { contactId: event.row.contactId, channelId: event.row.channelId },
          username: event.row.username,
        });
      }
      if (event.type === 'threadGroup') {
        return this.removeThreadFromThreadGroup({
          threadGroup: event.custom,
          thread: { threadId: event.row.threadId, channelId: event.row.channelId },
          username: event.row.username,
        });
      }
      if (event.type === 'campaign') {
        const activity = {
          activityId: event.custom.activityId,
          channelId: event.row.channelId,
          contactId: event.row.contactId,
          campaignId: event.custom.campaignId,
        };
        return this.deleteActivity(activity)
          .then(({ error }) => {
            if (error) {
              this.$buefy.snackbar.open({
                ...snackError,
                message: `Error while removing "${event.row.username}" from campaign`,
                onAction: () => this.deleteActivity(activity),
              });
            } else {
              this.$buefy.snackbar.open({
                ...snackSuccess,
                message: `"${event.row.username}" has been removed from campaign`,
              });
            }
          });
      }
      return undefined;
    },
    assignContactToContactGroup($event) {
      // console.log('🚀 ~ file: TheCRM.vue ~ line 581 ~ assignContactToContactGroup ~ $event', $event);
      this._assignContactToContactGroup($event)
        .then(({ title, error }) => {
          if (error) {
            this.$buefy.snackbar.open({
              ...snackError,
              message: `Error while assigning "${$event.username}" to contact folder "${title}"`,
              onAction: () => this.assignContactToContactGroup($event),
            });
          } else {
            this.$buefy.snackbar.open({
              ...snackSuccess,
              message: `"${$event.username}" has been assigned to contact folder "
              ${title}"`,
            });
          }
        });
    },
    removeContactFromContactGroup($event) {
      this._removeContactFromContactGroup($event)
        .then(({ title, error }) => {
          if (error) {
            this.$buefy.snackbar.open({
              ...snackError,
              message: `Error while removing "${$event.username}" from contact folder "${title}"`,
              onAction: () => this.removeThreadFromThreadGroup($event),
            });
          } else {
            this.$buefy.snackbar.open({
              ...snackSuccess,
              message: `"${$event.username}" has been removed from contact folder "
              ${title}"`,
            });
          }
        });
    },
    assignThreadToThreadGroup($event) {
      this._assignThreadToThreadGroup($event)
        .then(({ title, error }) => {
          if (error) {
            this.$buefy.snackbar.open({
              ...snackError,
              message: `Error while assigning "${$event.username}" to tag "${title}"`,
              onAction: () => this.assignThreadToThreadGroup($event),
            });
          } else {
            this.$buefy.snackbar.open({
              ...snackSuccess,
              message: `"${$event.username}" has been assigned to tag "${title}"`,
            });
          }
        });
    },
    removeThreadFromThreadGroup($event) {
      this._removeThreadFromThreadGroup($event)
        .then(({ title, error }) => {
          if (error) {
            this.$buefy.snackbar.open({
              ...snackError,
              message: `Error while removing "${$event.username}" from tag "${title}"`,
              onAction: () => this.removeThreadFromThreadGroup($event),
            });
          } else {
            this.$buefy.snackbar.open({
              ...snackSuccess,
              message: `"${$event.username}" has been removed from tag "${title}"`,
            });
          }
        });
    },
    addContactsIntoCampaign(event) {
      const { campaignId } = event;
      this.contactAddedToCampaign = 0;
      this.contactAlreadyInCampaign = 0;

      const activities = this.checkedRows.reduce((acc, row) => {
        const contactAlreadyInThisCampaign = row.activeCampaign
          .find((a) => a.campaignId === campaignId)
          || row.pastCampaign.find((p) => p.campaignList === campaignId);

        if (contactAlreadyInThisCampaign) { this.contactAlreadyInCampaign += 1; return acc; }
        this.contactAddedToCampaign += 1;
        acc.push({
          pk: row.contactId,
          username: row.username,
          status: 'unscheduled',
          channel: this.channelId,
          campaignId,
          campaign: campaignId,
        });
        return acc;
      }, []);

      // Create activities
      this.setUnscheduled({
        campaignId,
        channelId: this.channelId,
        activities,
      })
        .then(() => {
          this.checkedRows = [];
          this.isEndedModalActive = true;
        });
    },
    createThreadGroupAndAssign($event) {
      this._createThreadGroupAndAssign($event)
        .then(({ title, error }) => {
          if (error) {
            this.$buefy.snackbar.open({
              ...snackError,
              message: `Error while creating tag "${title}",
              "${$event.username}" is not assigned`,
              onAction: () => this.createThreadGroupAndAssign($event),
            });
          } else {
            this.$buefy.snackbar.open({
              ...snackSuccess,
              message: `"${$event.username}" has been assigned to newly created tag "${title}"`,
            });
          }
        });
    },
    createContactGroupAndAssign($event) {
      this._createContactGroupAndAssign($event)
        .then(({ title, error }) => {
          if (error) {
            this.$buefy.snackbar.open({
              ...snackError,
              message: `Error while creating contact folder "${title}",
              "${$event.username}" is not assigned`,
              onAction: () => this.createThreadGroupAndAssign($event),
            });
          } else {
            this.$buefy.snackbar.open({
              ...snackSuccess,
              message: `"${$event.username}" has been assigned to
              newly created contact folder "${title}"`,
            });
          }
        });
    },
    findLastMentionOrTagPost(account) {
      const { mentions, tagsPosts } = account;
      const lastMention = [];
      if (mentions.length) lastMention.push(Math.max(...mentions));
      if (tagsPosts.length) lastMention.push(Math.max(...tagsPosts));

      return lastMention.length ? Math.max(...lastMention) : null;
    },
    findMostRecentActiveCampaign(activeCampaigns) {
      return activeCampaigns.length ? Math.max(...activeCampaigns.reduce((acc, row) => {
        acc.push(row.timestamp);
        return acc;
      }, [])) : null;
    },
    assignContactsToContactGroup($event) {
      this._assignContactsToContactGroup($event)
        .then(({ error, count, title }) => {
          this.checkedRows = [];
          if (error) {
            this.$buefy.snackbar.open({
              ...snackError,
              message: `Error while assigning "${count}" contacts to folder "${title}"`,
              onAction: () => this.assignContactsToContactGroup($event),
            });
          } else {
            this.$buefy.snackbar.open({
              ...snackSuccess,
              message: `${count} contacts has been assigned to "${title}"`,
            });
          }
        });
    },
    createContactGroupAndAssignMany($event) {
      this._createContactGroupAndAssignMany($event)
        .then(({ error, count, title }) => {
          this.checkedRows = [];
          if (error) {
            this.$buefy.snackbar.open({
              ...snackError,
              message: `Error while creating contact folder "${title}"`,
              onAction: () => this.createContactGroupAndAssignMany($event),
            });
          } else {
            this.$buefy.snackbar.open({
              ...snackSuccess,
              message: `Folder "${title}" has been created and ${count} contacts has been assigned`,
            });
          }
        });
    },
    typeGenerator(account) {
      const typesInfluence = ['influence', 'inflence', 'influencer', 'influncer'];
      const typesBusiness = ['business'];
      if (account.type) {
        if (typesInfluence.some((type) => account.type.toLowerCase().indexOf(type) >= 0)) return 'Influence';
        if (typesBusiness.some((type) => account.type.toLowerCase().indexOf(type) >= 0)) return 'Business';
        return account.type;
      }
      return account.followerCount >= 5000 ? 'Influence' : null;
    },
  },
};
