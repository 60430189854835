<template>
  <div>
    <h3 class="ubu-welcome py-6">
      Hello, Friend !<br>
      Request a demo to get access to Ubu 😍
    </h3>
    <form class="UbuLoginForm">
      <UbuButton
        grow
        center
        big
        type="button"
        secondary
        href="https://calendly.com/ubu/start-free-trial?month"
      >
        Request Demo
      </UbuButton>
    </form>
  </div>
</template>

<script>
export default {
  name: 'TheAuthenticateSignupToken',
};
</script>

<style lang="scss" scoped>
  @import '@/styles/forms';
  .ubu-welcome {
    width: 450px;
  }
</style>
