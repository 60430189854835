<template>
  <div id="UbuCommentMediaList">
    <!-- <b-button
      class="ubuButton is-rounded is-light mediaRefresh"
      @click="$emit('refreshMedias')"
    >
      <UbuIcon
        :class="[{ 'refresh-spin' : isMediaLoading }]"
        name="refresh"
        outlined
        size="16px"
      />
    </b-button> -->

    <template v-if="isLoading">
      <div
        v-for="index in 15"
        :key="index"
        class="media-skeleton"
      >
        <b-skeleton
          height="60px"
          width="60px"
        />
      </div>
    </template>

    <template v-else>
      <template v-for="(media) in mediaListUnread">
        <UbuCommentMediaPost
          :key="media.mediaFBId"
          :media-url="media.thumbnail_url || media.media_url"
          :media-type="(media.thumbnail_url && 'IMAGE') || media.media_type"
          :source="media.source"
          :is-ad="media.isAd"
          :is-unread="true"
          :is-active="media.graphId === selectedMediaId"
          @selectMedia="$emit('selectMedia', media)"
          @mediaPicError="$emit('mediaPicError', media)"
        />
        <!-- c | {{ media.open_parent_comments_count }} -->
      </template>

      <div class="ubu-divider comments" />

      <template v-for="(media) in mediaListRead">
        <UbuCommentMediaPost
          :key="media.mediaFBId"
          :media-url="media.thumbnail_url || media.media_url"
          :media-type="(media.thumbnail_url && 'IMAGE') || media.media_type"
          :source="media.source"
          :is-ad="media.isAd"
          :is-unread="false"
          :is-active="media.graphId === selectedMediaId"
          @selectMedia="$emit('selectMedia', media)"
          @mediaPicError="$emit('mediaPicError', media)"
        />
        <!-- X | {{ media.open_parent_comments_count }} -->
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: 'UbuCommentMediaList',
  props: {
    mediaList: {
      type: Array,
      default: () => [],
    },
    selectedMediaId: {
      type: String,
      default: '',
    },
    isMediaLoading: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    mediaListUnread() {
      return this.mediaList
        .filter((m) => m.open_parent_comments_count > 0 || m.isUnread)
        .sort((a, b) => b.createdAt - a.createdAt);
    },
    mediaListRead() {
      return this.mediaList
        .filter((m) => {
          return !m.open_parent_comments_count && !m.isUnread;
        })
        .sort((a, b) => b.createdAt - a.createdAt);
    },
  },
  watch: {
    mediaList: {
      handler() {
        if (this.mediaListUnread.length && !this.selectedMediaId) this.$emit('selectMedia', this.mediaListUnread[0]);
        else if (this.mediaListRead.length && !this.selectedMediaId) this.$emit('selectMedia', this.mediaListRead[0]);
      },
      immediate: true,
    },
  },
};
</script>
