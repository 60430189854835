<template>
  <div id="UbuMentionsFilters">
    <div class="UbuMentionsFilters_searchFilters">
      <UbuSimpleInput
        v-model="localSearchValue"
        class="mr-2"
        placeholder="Search by username or caption..."
        searchbar
        :value="localSearchValue"
      >
        <UbuIcon
          slot="placeholderIcon"
          name="search"
        />
      </UbuSimpleInput>
      <UbuButton
        class="list-button"
        :icon="{ name: 'filter' }"
        :active="showFilters"
        @click="showFilters = !showFilters"
      />
    </div>
    <div
      v-if="showFilters"
      class="list-filters"
    >
      <h1 class="ubu-header1">Filters</h1>
      <div class="button-group">
        <UbuButton
          class="mr-2"
          :active="showTikTok"
          dashed
          @click="$emit('toggleTikTok', $event)"
        >
          TikTok
        </UbuButton>
        <UbuButton
          class="mr-2"
          :active="showInsta"
          dashed
          @click="$emit('toggleInsta', $event)"
        >
          Instagram
        </UbuButton>
        <UbuRangeSelector
          v-for="item in rangeFilters"
          :key="item.id"
          :button-title="item.buttonTitle"
          :modal-title="item.modalTitle"
          :range="item.range"
          :ticks="item.ticks"
          @setRangeFilter="$emit('setRangeFilter', { name: item.name, ...$event })"
        />
        <UbuNumberInputRangeSelector
          button-title="+ Engagement"
          modal-title="Engagement rate"
          :range="{ min: 0, max: 100 }"
          @setNumberInputFilter="$emit('setEngagementFilter', $event)"
        />
        <UbuNumberInputRangeSelector
          button-title="+ Post Engagement"
          modal-title="Post engagement rate"
          :range="{ min: 0, max: 100 }"
          @setNumberInputFilter="$emit('setPostEngagementFilter', $event)"
        />
        <UbuCheckboxSelector
          v-for="(item, index) in checkBoxFilters"
          :key="index"
          :button-title="item.buttonTitle"
          :modal-title="item.modalTitle"
          :checkbox-list="item.value"
          :default-option="item.defaultOption ? item.defaultOption : {}"
          @setCheckFilter="$emit('setCheckFilter', { name: item.name, ...$event})"
        />
      </div>
    </div>
    <div
      v-if="checkedRows.length"
      class="list-filters"
    >
      <h1 class="ubu-header1">{{ checkedRows.length }} selected</h1>
      <div class="button-group">
        <b-dropdown
          aria-role="list"
          class="crm-dropdown-content mr-1"
        >
          <template #trigger="{ active }">
            <b-button
              class="is-light is-rounded"
              :icon-right="active ? 'menu-up' : 'menu-down'"
            >
              <UbuIcon
                name="influence"
                :filled="true"
              />
              Add to campaign
            </b-button>
          </template>

          <b-dropdown-item
            v-for="(campaign, index) in campaignList"
            :key="index"
            aria-role="listitem"
            class="listitem"
            @click="$emit('selectCampaign', campaign)"
          >
            {{ campaign.title }} <UbuIcon name="add" />
          </b-dropdown-item>
        </b-dropdown>

        <UbuSelector
          class="mr-2"
          reduced
          :choices="contactGroups"
          :paths="{ id: 'contactGroupId', label: 'title' }"
          label-icon="contact"
          :tooltip="{
            label: `Add ${checkedRows.length} as contact${checkedRows.length !== 1 ? 's' : ''}`,
            position: 'is-bottom'
          }"
          search-placeholder="Create or search contacts..."
          create-placeholder="Create new contact:"
          @select="$emit('assignContactsToContactGroup', {
            threads: checkedRows, contactGroup: $event })"
          @create="$emit('createContactGroupAndAssignMany', { threads: checkedRows, ...$event})"
        />

        <UbuButton
          class="list-button"
          primary
          :icon="{ name: 'failure', filled: true }"
          @click="$emit('clearCheckedRow', $event)"
        >
          Cancel
        </UbuButton>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UbuMentionsHashtagsFilters',
  model: {
    prop: 'searchValue',
  },
  props: {
    searchValue: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    showTikTok: {
      type: Boolean,
      default: true,
    },
    showInsta: {
      type: Boolean,
      default: true,
    },
    checkedRows: {
      type: Array,
      default: () => [],
    },
    campaignList: {
      type: Array,
      default: () => [],
    },
    checkBoxFilters: {
      type: Array,
      default: () => [],
    },
    contactGroups: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      search: '',
      showFilters: false,
      rangeFilters: [
        {
          id: 100,
          name: 'followerRange',
          buttonTitle: '+ Followers',
          modalTitle: 'Followers',
          range: [0, 5],
          ticks: [
            { text: '0', value: 0 },
            { text: '3k', value: 3000 },
            { text: '10k', value: 10000 },
            { text: '50k', value: 50000 },
            { text: '200k', value: 200000 },
            { text: 'No limit', value: Infinity },
          ],
        },
        {
          id: 200,
          name: 'likeRange',
          buttonTitle: '+ Likes',
          modalTitle: 'Likes',
          range: [0, 5],
          ticks: [
            { text: '0', value: 0 },
            { text: '3k', value: 3000 },
            { text: '10k', value: 10000 },
            { text: '50k', value: 50000 },
            { text: '200k', value: 200000 },
            { text: 'No limit', value: Infinity },
          ],
        },
        {
          id: 300,
          name: 'commentRange',
          buttonTitle: '+ Comments',
          modalTitle: 'Comments',
          range: [0, 5],
          ticks: [
            { text: '0', value: 0 },
            { text: '3k', value: 3000 },
            { text: '10k', value: 10000 },
            { text: '50k', value: 50000 },
            { text: '200k', value: 200000 },
            { text: 'No limit', value: Infinity },
          ],
        },
        {
          id: 400,
          name: 'postRange',
          buttonTitle: '+ Number of Posts',
          modalTitle: 'Number of Posts',
          range: [0, 6],
          ticks: [
            { text: '0', value: 0 },
            { text: '1', value: 1 },
            { text: '2', value: 2 },
            { text: '3', value: 3 },
            { text: '4', value: 4 },
            { text: '5', value: 5 },
            { text: 'No limit', value: Infinity },
          ],
        },
      ],
    };
  },
  computed: {
    localSearchValue: {
      get() { return this.searchValue; },
      set(value) { this.$emit('input', value); },
    },
  },
  methods: {
  },
};
</script>
