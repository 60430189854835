<template>
  <UbuCommonMessageCard
    :is-me="isMe"
    :date="date"
    :username="username"
    is-text
    @deleteMessage="$emit('deleteMessage')"
  >
    <template v-slot:content>
      <div class="card-content-text">
        <p>
          @{{ username }} {{ item.action_log.description }}
        </p>
      </div>
    </template>
  </UbuCommonMessageCard>
</template>

<script>
export default {
  name: 'UbuCommonActionLog',
  props: {
    isMe: {
      type: Boolean,
      default: false,
    },
    date: {
      type: String,
      required: true,
    },
    username: {
      type: String,
      default: '',
    },
    item: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
