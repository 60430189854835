import moment from 'moment';
import { arrayMapper } from '../../../$utils/prototypes';
import {
  updateDatasIfExists,
  updateObjectRelationIfExists,

} from '../../$utils/dataMapper';
import { yaerApi } from '../../../$providers/index';
import { timeToNow } from '../../../$utils/dates';

/* eslint-disable max-len */
export default {
  namespaced: true,

  state: {
    referralLink: {},
    referralLinkHasOrderBis: {},
  },

  getters: {
    getterReferralLink: (state) => state.referralLink,
    getterReferralLinkHasOrderBis: (state) => state.referralLinkHasOrderBis,
  },

  mutations: {
    SET_REFERRAL_LINK(state, newDatas) { state.referralLink = updateDatasIfExists({ newDatas, actualDatas: state.referralLink, key: 'referralLinkRlid' }); },
    SET_REFERRAL_LINK_HAS_ORDER_BIS(state, newDatas) { state.referralLinkHasOrderBis = updateObjectRelationIfExists({ newDatas, actualDatas: state.referralLinkHasOrderBis }); },

    RESET_STORES(state) {
      state.referralLink = {};
      state.referralLinkHasOrderBis = {};
    },
  },

  actions: {
    sequence(_, { shopifyShopSsid }) {
      return Promise.resolve()
        .then(() => this.dispatch('Shopify/ReferralLink/fetchShopifyReferralLink', { shopifyShopSsid }))
        .then((codes) => this.dispatch('Shopify/ReferralLink/fetchShopifyReferralLinkOrders', { shopifyShopSsid, codes }));
    },
    fetchShopifyReferralLink({ commit, rootGetters }, { shopifyShopSsid }) {
      const {
        'Channel/getterCurrentChannelId': channelId,
        'Shopify/ShopifyShop/getterShopifyShop': shopifyShop,
      } = rootGetters;

      return Promise.resolve()
        .then(() => yaerApi.api.shopifyReferralLink.report({ payload: { channelId, shopifyShopSsid } }))
        .then(({ result }) => {
          if (!result) return undefined;
          const referralLink = result.map((rl) => ({
            ...rl,
            referralLinkRlid: rl._id,
            when: timeToNow(moment(rl.createdOn).valueOf()),
            link: `${shopifyShop[rl.shopifyShopSsid].websiteUrl}/?ref=${rl.referralCode}`,
            type: 'referral',
            title: rl.referralCode,
            contactId: rl.contactpk || null,
          }));

          const mappedReferralLink = arrayMapper(referralLink, 'referralLinkRlid');

          commit('SET_REFERRAL_LINK', mappedReferralLink);

          const shopifyShopHasReferralLinkBis = referralLink
            .reduce((acc, row) => {
              const { referralLinkRlid } = row;
              if (!acc[shopifyShopSsid]) {
                acc[shopifyShopSsid] = [referralLinkRlid];
                return acc;
              }
              acc[shopifyShopSsid].push(referralLinkRlid);
              return acc;
            }, {});

          const contactHasReferralLinkBis = referralLink
            .reduce((acc, row) => {
              const { referralLinkRlid, contactpk } = row;
              if (!contactpk) return acc;
              if (!acc[contactpk]) {
                acc[contactpk] = [referralLinkRlid];
                return acc;
              }
              acc[contactpk].push(referralLinkRlid);
              return acc;
            }, {});

          const campaignHasReferralLinkBis = referralLink
            .reduce((acc, row) => {
              const { referralLinkRlid, campaignId } = row;
              if (!campaignId) return acc;
              if (!acc[campaignId]) {
                acc[campaignId] = [referralLinkRlid];
                return acc;
              }
              acc[campaignId].push(referralLinkRlid);
              return acc;
            }, {});

          commit('Shopify/ShopifyShop/SET_SHOPIFY_SHOP_HAS_REFERRAL_LINK_BIS', shopifyShopHasReferralLinkBis, { root: true });
          commit('Contact/SET_CONTACTS_HAS_REFERRAL_LINKS_BIS', contactHasReferralLinkBis, { root: true });
          commit('Campaign/SET_CAMPAIGNS_HAS_REFERRAL_LINKS_BIS', campaignHasReferralLinkBis, { root: true });

          return referralLink;
        });
    },
    fetchShopifyReferralLinkOrders({ commit, rootGetters }, { shopifyShopSsid, codes }) {
      const { 'Channel/getterCurrentChannelId': channelId } = rootGetters;

      return Promise.resolve()
        .then(() => Promise.all(
          codes.flatMap(({ referralCode: code, referralLinkRlid }) => yaerApi.api.shopifyOrders.referralLink
            .report({ payload: { channelId, shopifyShopSsid, code } })
            .then(({ result }) => (result ? result.map((order) => ({
              ...order, channelId, orderOid: order._id, referralLinkRlid,
            })) : undefined))),
        ))
        .then((response) => {
          const referralLinkOrderList = response.flat().filter((r) => r);

          const mappedReferralLinkOrders = arrayMapper(referralLinkOrderList, 'referralLinkRlid');

          commit('Shopify/Order/SET_ORDERS', mappedReferralLinkOrders, { root: true });

          const referralLinkOrdersBis = referralLinkOrderList
            .reduce((acc, row) => {
              const { referralLinkRlid, orderOid } = row;
              if (!acc[referralLinkRlid]) {
                acc[referralLinkRlid] = [orderOid];
                return acc;
              }
              acc[referralLinkRlid].push(orderOid);
              return acc;
            }, {});

          commit('SET_REFERRAL_LINK_HAS_ORDER_BIS', referralLinkOrdersBis);
        });
    },
    assignReferralLinkToContact({ commit, rootGetters }, { shopifyShopSsid, ...rest }) {
      console.log('🚀 ~ file: ReferralLink.store.js ~ line 140 ~ assignReferralLinkToContact ~ rest', rest);
      const {
        'Channel/getterCurrentChannelId': channelId,
        'Shopify/ShopifyShop/getterShopifyShop': shopifyShop,
      } = rootGetters;

      return yaerApi.api.shopifyReferralLink
        .post({ payload: { channelId, shopifyShopSsid, shopifyLink: rest } })
        .then(({ result }) => {
          if (!result) return;

          const { upserted } = result;
          const { _id } = upserted[0];

          const updatedReferralLink = {
            [_id]: {
              referralLinkRlid: _id,
              referralCode: rest.referralCode,
              contactId: rest.contactpk,
              contactpk: rest.contactpk,
              shopifyShopSsid,
              campaignId: rest.campaignId ? rest.campaignId : null,
              createdOn: moment().format(),
              when: timeToNow(moment().valueOf()),
              link: `${shopifyShop[shopifyShopSsid].websiteUrl}/?ref=${rest.referralCode}`,
              type: 'referral',
              title: rest.referralCode,
            },
          };

          commit('SET_REFERRAL_LINK', updatedReferralLink);
          commit('Shopify/ShopifyShop/SET_SHOPIFY_SHOP_HAS_REFERRAL_LINK_BIS', { [shopifyShopSsid]: [_id] }, { root: true }); // ?
          commit('Contact/SET_CONTACTS_HAS_REFERRAL_LINKS_BIS', { [rest.contactpk]: [_id] }, { root: true });
          if (rest.campaignId) {
            commit('Campaign/SET_CAMPAIGNS_HAS_REFERRAL_LINKS_BIS', { [rest.campaignId]: [_id] }, { root: true });
          }
        });
    },
    assignReferralLinkToCampaign({ commit, rootGetters }, { shopifyShopSsid, referralLink, campaignId }) {
      const {
        'Channel/getterCurrentChannelId': channelId,
      } = rootGetters;

      const {
        contactpk, referralCode, referralLinkRlid, campaignId: oldCampaignId,
      } = referralLink;

      return yaerApi.api.shopifyReferralLink
        .post({
          payload: {
            channelId,
            shopifyShopSsid,
            shopifyLink: {
              contactpk, referralCode, campaignId,
            },
          },
        })
        .then(({ result }) => {
          if (!result) return;

          const updatedReferralLink = {
            [referralLinkRlid]: {
              ...referralLink,
              campaignId,
            },
          };

          commit('SET_REFERRAL_LINK', updatedReferralLink);
          if (oldCampaignId) commit('Campaign/REMOVE_REFERRAL_LINK_TO_CAMPAIGN', { campaignId: oldCampaignId, referralLinkRlid }, { root: true });
          commit('Campaign/SET_CAMPAIGNS_HAS_REFERRAL_LINKS_BIS', { [campaignId]: [referralLinkRlid] }, { root: true });
        });
    },
    removeReferralLinkToContact({ commit, rootGetters }, { shopifyShopSsid, referralLink }) {
      const { 'Channel/getterCurrentChannelId': channelId } = rootGetters;
      const {
        referralCode, campaignId, referralLinkRlid, contactId,
      } = referralLink;

      return yaerApi.api.shopifyReferralLink
        .post({ payload: { channelId, shopifyShopSsid, shopifyLink: { referralCode, contactpk: null, campaignId: campaignId || null } } })
        .then(({ result }) => {
          if (!result) return;

          const updatedReferralLink = {
            [referralLinkRlid]: {
              ...referralLink,
              contactId: null,
              contactpk: null,
              campaignId: campaignId || null,
            },
          };

          commit('SET_REFERRAL_LINK', updatedReferralLink);
          commit('Contact/REMOVE_REFERRAL_LINK_TO_CONTACT', { contactId, referralLinkRlid }, { root: true });
        });
    },
  },

};
