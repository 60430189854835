<template>
  <div
    class="UbuAvatar_wrapper"
    :style="{ width: size, height: size }"
  >
    <UbuInstaImg
      class="UbuAvatar_image"
      :insta-src="imgUrl"
      rounded
      fallback="/img/no_profile_pict.svg"
      :has-bucket="hasBucket"
      :bucket-folder="bucketFolder"
    />
  </div>
</template>
<script>
export default {
  name: 'UbuAvatar',
  props: {
    imgUrl: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '',
    },
    hasBucket: {
      type: Boolean,
      default: false,
    },
    bucketFolder: {
      type: String,
      default: '',
    },
  },
};
</script>
