const tryParseJson = (item) => {
  try { return JSON.parse(item); } catch (error) { return item; }
};

const fileToBlob = (file) => new Promise((resolve, reject) => {
  let context = null;
  const maxWidth = 100;
  const maxHeight = 100;
  const fileLoader = new FileReader();
  const canvas = document.createElement('canvas');
  const imageObj = new Image();

  canvas.id = 'hiddenCanvas';
  canvas.style.display = 'none';
  document.body.appendChild(canvas);
  context = canvas.getContext('2d');

  if (file.type.match('image.*')) {
    fileLoader.readAsDataURL(file);
  } else {
    const error = 'File is not an image';
    reject(error);
  }

  fileLoader.onload = function onload() {
    const data = this.result;
    imageObj.src = data;
  };
  fileLoader.onabort = function onabort(error) {
    reject(error);
  };
  fileLoader.onerror = function onerror(error) {
    reject(error);
  };

  imageObj.onload = function onload() {
    if (this.width === 0 || this.height === 0) {
      console.warn('Image is empty');
    } else {
      canvas.width = this.width;
      canvas.height = this.height;
      context.clearRect(0, 0, maxWidth, maxHeight);
      context.drawImage(imageObj, 0, 0, this.width, this.height, 0, 0, this.width, this.height);

      const dataURI = canvas.toDataURL('image/jpeg');
      const byteString = atob(dataURI.split(',')[1]);
      const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);

      for (let i = 0; i < byteString.length; i += 1) {
        ia[i] = byteString.charCodeAt(i);
      }

      resolve(new Blob([ab], { type: mimeString }));
    }
  };
});

const shortenNumber = (nb) => {
  // if 0 < nb < 1 round 2 number decimal
  if (nb > 0 && nb < 10) return `${Math.round(nb * 100) / 100}`;

  if (nb < 1000) {
    return `${Math.floor(nb)}`;
  }
  if (nb < 10000) {
    return `${(nb / 1000).toFixed(1)}K`;
  }
  if (nb < 1000000) {
    return `${Math.floor(nb / 1000)}K`;
  }
  if (nb < 10000000) {
    return `${(nb / 1000000).toFixed(1)}M`;
  }
  return `${Math.floor(nb / 1000000)}M`;
};

// add dot every 3 number, exemple 1000 -> 1.000
const separateThousandsByDot = (nb) => nb.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');

const separateThousandsBySpace = (nb) => nb.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

const itemHasMedia = [
  'reel_share',
  'media_share',
  'story_share',
  'felix_share',
  'voice_media',
  'product_share',
  'media',
  'clip',
  'animated_media',
];
const itemHasDetails = [
  // 'reel_share',
  'media_share',
  'story_share',
  'felix_share',
  // 'voice_media',
  'product_share',
  'profile',
  // 'media',
  'clip',
];

const getImageFromList = (imageList) => {
  const { candidates } = imageList;

  if (candidates) {
    if (candidates[0]) {
      return candidates[0].url;
    }
    return '';
  }

  return '';
};

const getVideoFromList = (videoList) => {
  if (videoList && videoList.length && videoList[0]) {
    return videoList[0].url;
  }

  return '';
};

const foundItemType = (item, newMedia) => {
  const {
    item_type: type,
    reel_share: reelShare = {},
    item_subtype: itemSubtype = '',
  } = item;

  /* eslint-disable no-multi-spaces */
  if (type === 'text')                                                                        return 'isText';
  if (type === 'error')                                                                       return 'isError';
  if (type === 'reel_share' && (reelShare.type === 'mention'  || itemSubtype === 'mention'))  return 'isStoryMention';
  if (type === 'reel_share' && (reelShare.type === 'reply'    || itemSubtype === 'reply'))    return 'isStoryReply';
  if (type === 'reel_share' && (reelShare.type === 'reaction' || itemSubtype === 'reaction')) return 'isStoryReact';
  if (type === 'media' && !newMedia.hasVideo)                                                 return 'isMediaImage';
  if (type === 'media' && newMedia.hasVideo)                                                  return 'isMediaVideo';
  if (type === 'story_share')                                                                 return 'isStoryShare';
  if (type === 'media_share')                                                                 return 'isPostShare';
  if (type === 'product_share')                                                               return 'isProductShare';
  if (type === 'felix_share')                                                                 return 'isFelixShare';
  if (type === 'voice_media')                                                                 return 'isVoiceMedia';
  if (type === 'clip')                                                                        return 'isClip';
  if (type === 'animated_media')                                                              return 'isAnimatedMedia';
  if (type === 'profile')                                                                     return 'isProfileShare';
  if (type === 'link')                                                                        return 'isLink';
  if (type === 'action_log')                                                                  return 'isActionLog';
  if (type === 'comment')                                                                     return 'isComment';
  return 'UNKNOWN';
  /* eslint-enable no-multi-spaces */
};

const mergeBoth = (detailA, detailB) => ({
  description: detailA.description || detailB.description,
  name: detailA.name || detailB.name,
  link: detailA.link || detailB.link,
  fullName: detailA.fullName || detailB.fullName,
  username: detailA.username || detailB.username,
  profilePicUrl: detailA.profilePicUrl || detailB.profilePicUrl,
  text: detailA.text || detailB.text,
});

const destructureDetail = (dataToDestructure) => {
  if (!dataToDestructure) {
    return {
      description: '',
      name: '',
      link: '',
      fullName: '',
      username: '',
      profilePicUrl: '',
      text: '',
    };
  }

  const {
    description = '',
    name = '',
    external_url: link = '',
    full_name: fullName = '',
    username = '',
    profile_pic_url: profilePicUrl = '',
    text = '',
  } = dataToDestructure;

  return {
    description,
    name,
    link,
    fullName,
    username,
    profilePicUrl,
    text,
  };
};

const foundDetailsForItemType = (item) => {
  const { item_type: type } = item;

  let details = null;

  if (itemHasDetails.includes(type)) {
    /* eslint-disable no-multi-spaces */
    if (type === 'product_share') { details = destructureDetail(item.product_share.product); }
    if (type === 'media_share')   { details = mergeBoth(destructureDetail(item.media_share.caption), destructureDetail(item.media_share.user)); }
    if (type === 'clip')          { details = mergeBoth(destructureDetail(item.clip.clip.caption), destructureDetail(item.clip.clip.caption.user)); }
    if (type === 'story_share')   { details = destructureDetail(item.story_share.media ? item.story_share.media.user : item.story_share.media); }
    if (type === 'felix_share')   { details = destructureDetail(item.felix_share.video.user); }
    if (type === 'profile')       { details = destructureDetail(item.profile); }
    /* eslint-enable no-multi-spaces */
  }
  return details || null;
};

const destructureMedia = (mediaToDestructure, hasBucket) => {
  if (!mediaToDestructure) {
    return {
      id: '',
      audioUrl: '',
      imageUrl: '',
      videoUrl: '',
      carouselList: [],
      hasBucket: false,
      hasVideo: false,
    };
  }

  const {
    id = '',
    audio_src: audioUrl = '',
    image_versions2: imageList = [],
    video_versions: videoList = [],
    carousel_media: carouselList = [],
    url = '',
  } = mediaToDestructure;

  let formatedCarouselList = [];

  if (carouselList) {
    formatedCarouselList = carouselList.map((media) => {
      const {
        id: mediaId,
        image_versions2: mediaImageList = [],
        video_versions: mediaVideoList = [],
      } = media;
      return {
        id: mediaId,
        imageUrl: getImageFromList(mediaImageList),
        videoUrl: getVideoFromList(mediaVideoList),
        hasBucket: hasBucket || false,
        hasVideo: !!(mediaVideoList && mediaVideoList.length > 0),
      };
    });
  }

  return {
    id,
    audioUrl,
    imageUrl: url || getImageFromList(imageList),
    videoUrl: getVideoFromList(videoList),
    carouselList: formatedCarouselList || [],
    hasBucket: hasBucket || false,
    hasVideo: !!(videoList && videoList.length > 0),
  };
};

const foundMediaForItemType = (item) => {
  const { item_type: type, has_bucket_media: hasBucket } = item;

  let media = null;

  if (itemHasMedia.includes(type)) {
    /* eslint-disable no-multi-spaces */
    if (type === 'reel_share')     { media = destructureMedia(item.reel_share ? item.reel_share.media : null, hasBucket); }
    if (type === 'story_share')    { media = destructureMedia(item.story_share.media, hasBucket); }
    if (type === 'media_share')    { media = destructureMedia(item.media_share, hasBucket); }
    if (type === 'clip')           { media = destructureMedia(item.clip.clip, hasBucket); }
    if (type === 'media')          { media = destructureMedia(item.media, hasBucket); }
    if (type === 'felix_share')    { media = destructureMedia(item.felix_share.video, hasBucket); }
    if (type === 'product_share')  { media = destructureMedia(item.product_share.product.main_image, hasBucket); }
    if (type === 'animated_media') { media = destructureMedia(item.animated_media.images.fixed_height, hasBucket); }
    if (type === 'voice_media')    { media = destructureMedia(item.voice_media.media.audio, hasBucket); }
    /* eslint-enable no-multi-spaces */
  }
  return media || null;
};

const messageFormater = (message) => {
  const {
    item: serializedItem,
    contactId,
    receiver,
    user_id: senderId,
    isMe,
    ...restItem
  } = message;
  // Prepare formated item
  const newMedia = foundMediaForItemType(restItem);
  const newDetails = foundDetailsForItemType(restItem);
  const uniType = foundItemType(restItem, newMedia);

  // Remove useless and verbose objects
  /* eslint-disable camelcase */
  const {
    reel_share,
    media_share,
    story_share,
    felix_share,
    product_share,
    voice_media,
    media,
    clip,
    animated_media,
    profile,
    ...cleanItem
  } = restItem;
  /* eslint-enable camelcase */

  return {
    newMedia,
    newDetails,
    uniType,
    contactId,
    receiver,
    senderId,
    userId: senderId,
    isMe: isMe || contactId !== senderId,
    ...cleanItem,
  };
};

const convertColor = (hex) => {
  let color;
  switch (hex) {
    case '#4D4D4D': color = '1'; break;
    case '#FF6060': color = '2'; break;
    case '#FFA471': color = '3'; break;
    case '#FFC670': color = '4'; break;
    case '#6BCE3D': color = '5'; break;
    case '#73DA8A': color = '6'; break;
    case '#43D2C1': color = '7'; break;
    case '#3CA6F2': color = '8'; break;
    case '#6F6DE3': color = '9'; break;
    case '#B98DF0': color = '10'; break;
    case '#F882EC': color = '11'; break;
    case '#FF62A4': color = '12'; break;
    default: color = '1'; break;
  }
  return color;
};

export {
  // eslint-disable-next-line
  tryParseJson,
  fileToBlob,
  shortenNumber,
  separateThousandsByDot,
  separateThousandsBySpace,
  messageFormater,
  destructureMedia,
  convertColor,
};
