<template>
  <section id="MentionsDashboard">
    <div class="dashboard-title">
      <h1 class="ubu-title">
        Performance
      </h1>

      <b-tooltip
        class="ml-2 a-self-center"
        :label="tooltipLabel"
        size="is-large"
        position="is-bottom"
        multilined
      >
        <UbuIcon name="info" />
      </b-tooltip>
    </div>

    <template v-if="isConnectedToFb">
      <div class="dashboard-button-group">
        <b-tooltip
          :label="tooltipToLastDay"
          position="is-bottom"
          size="is-small"
          multilined
        >
          <UbuButton
            class="dashboard-button"
            center
            :active="dateFilter.value == 1"
            :loading="isLoading"
            simple
            @click="changeDate({type: 'simple', value: 1})"
          >
            <span class="dashboard-button-text">
              Last day
            </span>
            <UbuIcon name="info" />
          </UbuButton>
        </b-tooltip>

        <b-tooltip
          :label="tooltipToLastWeek"
          position="is-bottom"
          size="is-small"
          multilined
        >
          <UbuButton
            class="dashboard-button"
            center
            :active="dateFilter.value == 7"
            :loading="isLoading"
            simple
            @click="changeDate({type: 'simple', value: 7})"
          >
            <span class="dashboard-button-text">
              Last week
            </span>
            <UbuIcon name="info" />
          </UbuButton>
        </b-tooltip>

        <b-tooltip
          :label="tooltipToLastMonth"
          position="is-bottom"
          size="is-small"
          multilined
        >
          <UbuButton
            class="dashboard-button"
            center
            :active="dateFilter.value == 30"
            :loading="isLoading"
            simple
            @click="changeDate({type: 'simple', value: 30})"
          >
            <span class="dashboard-button-text">
              Last month
            </span>
            <UbuIcon name="info" />
          </UbuButton>
        </b-tooltip>
        <UbuDatePicker
          v-model="dateRange"
          :is-analytics="true"
          :is-loading="isLoading"
          :active="dateFilter.type == 'custom'"
          :has-unselectable-days="true"
          @changeDate="changeDate({type: 'custom', value: dateRange})"
        />
      </div>

      <div class="dashboard-box">
        <h1 class="ubu-header2">Account</h1>

        <div
          v-if="isLoading || !fetchIsDone"
          class="dashboard-row"
        >
          <UbuCommonAnalyticsCell
            v-for="index in 10"
            :key="index"
            :is-loading="true"
          />
        </div>

        <div
          v-else
          class="dashboard-row"
        >
          <UbuCommonAnalyticsCell
            v-for="item in accountAnalytics"
            :key="item.ordinal"
            :name="item.name"
            :value="item.value"
            :previous-value="item.previousValue"
            :is-extended="item.isExtended"
            :is-positive="item.isPositive"
            :has-value="item.hasValue"
            :has-pourcent="item.hasPourcent"
            :is-loading="isLoading"
          />
        </div>
      </div>

      <div class="dashboard-box">
        <h1 class="ubu-header2">Inbox</h1>

        <div
          v-if="isLoading || !fetchIsDone"
          class="dashboard-row"
        >
          <UbuCommonAnalyticsCell
            v-for="index in 8"
            :key="index"
            :is-loading="true"
          />
        </div>

        <div
          v-else
          class="dashboard-row"
        >
          <UbuCommonAnalyticsCell
            v-for="item in inboxAnalytics"
            :key="item.ordinal"
            :name="item.name"
            :value="item.value"
            :previous-value="item.previousValue"
            :is-extended="item.isExtended"
            :is-positive="item.isPositive"
            :has-value="item.hasValue"
            :has-pourcent="item.hasPourcent"
            :is-loading="isLoading"
            :grouped="item.grouped ? item.grouped : []"
          />
        </div>
      </div>

      <div class="dashboard-box">
        <h1 class="ubu-header2">Shopify</h1>

        <div
          v-if="fetchIsDone && getterShopifyShop.length && currentShop"
          class="buttons"
        >
          <b-button
            v-for="shop in getterShopifyShop"
            :key="shop.shopifyShopSsid"
            :label="shop.shopExtrefId"
            :class="['is-light is-rounded mt-2',
                     { 'is-geraldine': shop.shopifyShopSsid === currentShop.shopifyShopSsid}]"
            @click="selectShop(shop.shopifyShopSsid)"
          />
        </div>

        <div
          v-if="isLoading || !fetchIsDone"
          class="dashboard-row"
        >
          <UbuCommonAnalyticsCell
            v-for="index in 6"
            :key="index"
            :is-loading="true"
            :is-extended="true"
          />
        </div>

        <div
          v-else-if="getterShopifyShop.length"
          class="dashboard-row"
        >
          <UbuCommonAnalyticsCell
            v-for="item in shopifyAnalytics"
            :key="item.ordinal"
            :name="item.name"
            :value="item.value"
            :previous-value="item.previousValue"
            :is-extended="item.isExtended"
            :is-positive="item.isPositive"
            :has-value="item.hasValue"
            :has-pourcent="item.hasPourcent"
            :is-loading="isLoading"
            :grouped="item.grouped ? item.grouped : []"
          />
          <div class="dashboard-table-container pt-4">
            <b-table
              :data="referralLinksConcatDiscountCodesFromCurrentShop"
              :loading="isLoading"
              paginated
              per-page="10"
              pagination-rounded
              pagination-size="is-small"
            >
              <b-table-column
                v-slot="{ row }"
                field="link"
                label="Link"
                sortable
              >
                <span class="text-geraldine">{{ row.link }}</span>
              </b-table-column>

              <b-table-column
                v-slot="{ row }"
                field="type"
                label="Type"
                sortable
              >
                {{ row.type }}
              </b-table-column>

              <b-table-column
                v-slot="{ row }"
                field="order"
                label="Revenues"
                sortable
              >
                {{ calculateTotalAmount(row.order) }} $
              </b-table-column>

              <b-table-column
                v-slot="{ row }"
                field="order"
                label="Orders"
                sortable
              >
                {{ row.order.length }}
              </b-table-column>

              <b-table-column
                v-slot="{ row }"
                field="contactId"
                label="Influencer"
                sortable
              >
                {{ row.contactId ? row.contact.username : '-' }}
              </b-table-column>

              <b-table-column
                v-slot="{ row }"
                field="campaignId"
                label="Campaign"
                sortable
              >
                <b-tooltip
                  v-if="row.campaign"
                  :label="row.campaign.name"
                >
                  <UbuIcon
                    name="influence"
                    filled
                    :style="`color: ${row.campaign.color}`"
                  />
                </b-tooltip>
                <span v-else>-</span>
              </b-table-column>
            </b-table>
          </div>
        </div>

        <div
          v-else
          class="dashboard-error-content"
        >
          <p>
            We couldn't load your analytics, please check if your Shopify account is connected
          </p>
        </div>
      </div>

      <div
        v-if="graphData.labels"
        class="dashboard-graph"
      >
        <h1 class="ubu-header2">
          Followers evolution
        </h1>
        <UbuAnalyticsGraph
          chart-type="stackedBar"
          chart-id="followers-evolution-chart"
          :data="graphData.netGrowth"
        />
        <h1 class="ubu-header2">
          Engagement rate
        </h1>
        <UbuAnalyticsGraph
          v-if="graphData.engagement"
          chart-type="lineSegment"
          chart-id="engagement-chart"
          :labels="graphData.labels"
          :data="graphData.engagement"
          name="Engagement rate"
        />

        <h1 class="ubu-header2">
          Reach
        </h1>
        <UbuAnalyticsGraph
          v-if="graphData.reach"
          chart-type="lineSegment"
          chart-id="reach-chart"
          :labels="graphData.labels"
          :data="graphData.reach"
          name="Reach"
        />
      </div>
    </template>

    <div
      v-else
      class="dashboard-error-content"
    >
      <p>We couldn't load your analytics, please check if your Facebook Channel is connected</p>
      <b-button
        to="/workspace/connection"
        class="dashboard-error-button ubuButton is-primary"
        tag="router-link"
      >
        Connection
      </b-button>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
// import { catalog } from '../$catalog/analytics';
// import { reduceDataToGraph } from './$utils/analytics';

export default {
  name: 'TheMentionsDashboard',
  data() {
    return {
      // catalog,
      dateFilter: { type: 'simple', value: 1 },
      isLoading: true,
      analyticsData: [],
      shopifyAnalytics: [],
      tooltipLabel: 'You need to connect your facebook account in order to access your statistics (home=>workspace=>connections)',
      graphData: {},
      dateRange: [],
      selectedShopifyShopSsid: null,
    };
  },
  computed: {
    ...mapGetters({
      fetchIsDone: 'Sequence/getterFetchDone',
      getterFbChannel: 'Channel/getterFbChannel',
      getterUbuUserById: 'UbuUser/getterUbuUserById',
      getterUbuUsers: 'UbuUser/getterUbuUsers',
      getterShopifyShop: 'TheContactPanel/getterShopifyShop',
    }),
    currentShop() {
      return this.getterShopifyShop
        .find(({ shopifyShopSsid }) => shopifyShopSsid === this.selectedShopifyShopSsid);
    },
    accountAnalytics() {
      return this.analyticsData.filter((a) => a.type === 'account').sort((a, b) => a.ordinal - b.ordinal);
    },
    inboxAnalytics() {
      return this.analyticsData.filter((a) => a.type === 'inbox').sort((a, b) => a.ordinal - b.ordinal);
    },
    isConnectedToFb() {
      return this.getterFbChannel && this.getterFbChannel.connected;
    },
    tooltipToLastDay() {
      return `From ${this.$moment().subtract({ days: 1 }).format('MMM Do YY')} to ${this.$moment().subtract({ days: 1 + 1 }).format('MMM Do YY')}`;
    },
    tooltipToLastWeek() {
      return `From ${this.$moment().subtract({ days: 1 }).format('MMM Do YY')} to ${this.$moment().subtract({ days: 7 + 1 }).format('MMM Do YY')}`;
    },
    tooltipToLastMonth() {
      return `From ${this.$moment().subtract({ days: 1 }).format('MMM Do YY')} to ${this.$moment().subtract({ days: 30 + 1 }).format('MMM Do YY')}`;
    },
    // referralLinksConcatDiscountCodes() {
    //   return this.getterShopifyShop.reduce((acc, row) => {
    //     const { referralLink, discountCode } = row;
    //     acc.push(...referralLink.concat(discountCode));
    //     return acc;
    //   }, [])
    //   // .filter(({ contactId, campaignId }) => contactId || campaignId);
    //     .sort((a, b) => this.$moment(b.createdOn) - this.$moment(a.createdOn));
    // },
    referralLinksConcatDiscountCodesFromCurrentShop() {
      const { referralLink, discountCode } = this.currentShop;
      return referralLink.concat(discountCode)
        .sort((a, b) => this.$moment(b.createdOn) - this.$moment(a.createdOn));
    },
    totalRevenuesFromStore() {
      return this.currentShop.orders.length
        ? this.currentShop.orders
          .reduce((acc, row) => acc + Math.round(row.amountUsd / 100), 0)
        : 0;
    },
    totalOrdersFromStore() {
      return this.currentShop.orders.length;
    },
    totalRevenuesFromLinks() {
      const { referralLink, discountCode } = this.currentShop;
      return referralLink.concat(discountCode).reduce((acc, row) => {
        if (row.order.length) {
          // eslint-disable-next-line no-param-reassign
          acc += row.order.reduce((ac, order) => ac + (order.amountUsd / 100), 0);
        }
        return acc;
      }, 0);
    },
    totalOrdersFromLinks() {
      const { referralLink, discountCode } = this.currentShop;
      return referralLink.concat(discountCode).reduce((acc, row) => acc + row.order.length, 0);
    },
    ordersFromInstagram() {
      return this.currentShop.orders.filter(({ orderVisit }) => {
        if (orderVisit && (orderVisit.lastVisitSource === 'Instagram' || orderVisit.firstVisitSource === 'Instagram')) {
          return true;
        }
        return false;
      });
    },
    totalOrdersFromInstagram() {
      return this.ordersFromInstagram.length;
    },
    totalRevenuesFromInstagram() {
      return this.ordersFromInstagram.length
        ? this.ordersFromInstagram.reduce((acc, row) => acc + Math.round(row.amountUsd / 100), 0)
        : 0;
    },
  },
  watch: {
    fetchIsDone: {
      handler(isDone) {
        if (isDone && this.isConnectedToFb) {
          this.fetchAnalytics();
          if (this.getterShopifyShop.length) {
            this.selectedShopifyShopSsid = this.getterShopifyShop[0].shopifyShopSsid;
            this.prepareShopifyAnalytics();
          }
        }
      },
    },
  },
  mounted() {
    if (this.fetchIsDone && this.isConnectedToFb) {
      this.fetchAnalytics();
      if (this.getterShopifyShop.length) {
        this.selectedShopifyShopSsid = this.getterShopifyShop[0].shopifyShopSsid;
        this.prepareShopifyAnalytics();
      }
    }
  },
  methods: {
    ...mapActions({
      loadAnalytics: 'TheMentions/fetchAnalytics',
    }),
    fetchAnalytics() {
      this.isLoading = true;
      this.loadAnalytics(this.dateFilter)
        .then((response) => {
          // this.analyticsData = prepareDataToAnalytics(response, this.getterUbuUsers);
          this.graphData = reduceDataToGraph(response);
          this.isLoading = false;
        });
    },
    prepareShopifyAnalytics() {
      const shopifyData = {
        totalRevenuesFromStore: this.totalRevenuesFromStore,
        totalOrdersFromStore: this.totalOrdersFromStore,
        totalRevenuesFromInstagram: this.totalRevenuesFromInstagram,
        totalOrdersFromInstagram: this.totalOrdersFromInstagram,
        totalRevenuesFromLinks: Math.round(this.totalRevenuesFromLinks),
        totalOrdersFromLinks: this.totalOrdersFromLinks,
      };
      this.shopifyAnalytics = Object.entries(shopifyData).reduce((acc, row) => {
        const [key, value] = row;
        if (this.catalog[key]) {
          acc.push(
            {
              ...this.catalog[key],
              value: typeof value === 'number' ? value.toString() : 'N/A',
              isPositive: value >= 0,
              hasValue: value !== null && (value > 0 || value < 0),
            },
          );
        }
        return acc;
      }, []).sort((a, b) => a.ordinal - b.ordinal);
    },
    changeDate(dateRange) {
      // we need 1 days min between min & max date range (when user select only 1 date)
      if (dateRange.type === 'custom' && dateRange.value[1] === dateRange.value[0]) {
        // eslint-disable-next-line no-param-reassign
        dateRange.value[0] = this.$moment(dateRange.value[1]).subtract({ days: 1 }).toDate();
      }
      this.dateFilter = dateRange;
      this.fetchAnalytics();
    },
    selectShop(shopId) {
      this.selectedShopifyShopSsid = shopId;
      this.prepareShopifyAnalytics();
    },
    calculateTotalAmount(orderList) {
      if (!orderList.length) return 0;
      return orderList.reduce((acc, row) => {
        const { amountUsd } = row;
        // eslint-disable-next-line
        return acc += (amountUsd / 100);
      }, 0);
    },
  },
};
</script>
