import { use, registerComponent } from '../../$utils/plugins';

// const UbuInfluenceHeader = () => import(/* webpackChunkName: "addonInfluence" */ './UbuInfluenceHeader');
// const UbuInfluenceLeadsAddLeads = () => import(/* webpackChunkName: "addonInfluence" */ './UbuInfluenceLeadsAddLeads');
// const UbuInfluenceLeadsAddLeadsBulk = () => import(/* webpackChunkName: "addonInfluence" */ './UbuInfluenceLeadsAddLeadsBulk');
// const UbuInfluenceLeadsAddLeadsContacts = () => import(/* webpackChunkName: "addonInfluence" */ './UbuInfluenceLeadsAddLeadsContacts');
// const UbuInfluenceLeadsAddLeadsTags = () => import(/* webpackChunkName: "addonInfluence" */ './UbuInfluenceLeadsAddLeadsTags');
// const UbuInfluenceLeadsAddLeadsCampaigns = () => import(/* webpackChunkName: "addonInfluence" */ './UbuInfluenceLeadsAddLeadsCampaigns');
// const UbuInfluenceLeadsComposeMessage = () => import(/* webpackChunkName: "addonInfluence" */ './UbuInfluenceLeadsComposeMessage');
// const UbuInfluenceLeadsImporterModalList = () => import(/* webpackChunkName: "addonInfluence" */ './UbuInfluenceLeadsImporterModalList');
// const UbuInfluenceLeadsWaitingReview = () => import(/* webpackChunkName: "addonInfluence" */ './UbuInfluenceLeadsWaitingReview');
// const UbuInfluenceMessageThreadCard = () => import(/* webpackChunkName: "addonInfluence" */ './UbuInfluenceMessageThreadCard');
// const UbuInfluenceMessageThreadList = () => import(/* webpackChunkName: "addonInfluence" */ './UbuInfluenceMessageThreadList');
// const UbuInfluencePipelineButtonChangeStatus = () => import(/* webpackChunkName: "addonInfluence" */ './UbuInfluencePipelineButtonChangeStatus');
// const UbuInfluencePipelineTableMention = () => import(/* webpackChunkName: "addonInfluence" */ './UbuInfluencePipelineTableMention');
// const UbuInfluencePipelineTableDate = () => import(/* webpackChunkName: "addonInfluence" */ './UbuInfluencePipelineTableDate');
// const UbuInfluencePipelineTableRow = () => import(/* webpackChunkName: "addonInfluence" */ './UbuInfluencePipelineTableRow');
// const UbuInfluenceSettingsCampaignForm = () => import(/* webpackChunkName: "addonInfluence" */ './UbuInfluenceSettingsCampaignForm');
// const UbuInfluenceModalCreateCampaign = () => import(/* webpackChunkName: "addonInfluence" */ './UbuInfluenceModalCreateCampaign');

import UbuInfluenceHeader from './UbuInfluenceHeader';
import UbuInfluenceLeadsAddLeads from './UbuInfluenceLeadsAddLeads';
import UbuInfluenceLeadsAddLeadsBulk from './UbuInfluenceLeadsAddLeadsBulk';
import UbuInfluenceLeadsAddLeadsContacts from './UbuInfluenceLeadsAddLeadsContacts';
import UbuInfluenceLeadsAddLeadsTags from './UbuInfluenceLeadsAddLeadsTags';
import UbuInfluenceLeadsAddLeadsCampaigns from './UbuInfluenceLeadsAddLeadsCampaigns';
import UbuInfluenceLeadsComposeMessage from './UbuInfluenceLeadsComposeMessage';
import UbuInfluenceLeadsImporterModalList from './UbuInfluenceLeadsImporterModalList';
import UbuInfluenceLeadsWaitingReview from './UbuInfluenceLeadsWaitingReview';
import UbuInfluenceMessageThreadCard from './UbuInfluenceMessageThreadCard';
import UbuInfluenceMessageThreadList from './UbuInfluenceMessageThreadList';
import UbuInfluencePipelineButtonChangeStatus from './UbuInfluencePipelineButtonChangeStatus';
import UbuInfluencePipelineTableMention from './UbuInfluencePipelineTableMention';
import UbuInfluencePipelineTableDate from './UbuInfluencePipelineTableDate';
import UbuInfluencePipelineTableRow from './UbuInfluencePipelineTableRow';
import UbuInfluenceSettingsCampaignForm from './UbuInfluenceSettingsCampaignForm';
import UbuInfluenceModalCreateCampaign from './UbuInfluenceModalCreateCampaign';
import UbuInfluenceMentionsTable from './UbuInfluenceMentionsTable';
import UbuInfluencePipelineModalMessageScheduled from './UbuInfluencePipelineModalMessageScheduled';
import UbuInfluenceModalCreateTikTokOrder from './UbuInfluenceModalCreateTikTokOrder';
import UbuInfluenceMentionsTikTok from './UbuInfluenceMentionsTikTok';
import UbuInfluenceMentionsTikTokTable from './UbuInfluenceMentionsTikTokTable';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, UbuInfluenceHeader);
    registerComponent(Vue, UbuInfluenceLeadsAddLeads);
    registerComponent(Vue, UbuInfluenceLeadsAddLeadsBulk);
    registerComponent(Vue, UbuInfluenceLeadsAddLeadsContacts);
    registerComponent(Vue, UbuInfluenceLeadsAddLeadsTags);
    registerComponent(Vue, UbuInfluenceLeadsAddLeadsCampaigns);
    registerComponent(Vue, UbuInfluenceLeadsComposeMessage);
    registerComponent(Vue, UbuInfluenceLeadsImporterModalList);
    registerComponent(Vue, UbuInfluenceLeadsWaitingReview);
    registerComponent(Vue, UbuInfluenceMessageThreadCard);
    registerComponent(Vue, UbuInfluenceMessageThreadList);
    registerComponent(Vue, UbuInfluencePipelineButtonChangeStatus);
    registerComponent(Vue, UbuInfluencePipelineTableMention);
    registerComponent(Vue, UbuInfluencePipelineTableDate);
    registerComponent(Vue, UbuInfluencePipelineTableRow);
    registerComponent(Vue, UbuInfluenceSettingsCampaignForm);
    registerComponent(Vue, UbuInfluenceModalCreateCampaign);
    registerComponent(Vue, UbuInfluenceMentionsTable);
    registerComponent(Vue, UbuInfluencePipelineModalMessageScheduled);
    registerComponent(Vue, UbuInfluenceModalCreateTikTokOrder);
    registerComponent(Vue, UbuInfluenceMentionsTikTok);
    registerComponent(Vue, UbuInfluenceMentionsTikTokTable);
  },
};

use(Plugin);

export default Plugin;

export {
  UbuInfluenceHeader,
  UbuInfluenceLeadsAddLeads,
  UbuInfluenceLeadsAddLeadsBulk,
  UbuInfluenceLeadsAddLeadsContacts,
  UbuInfluenceLeadsAddLeadsTags,
  UbuInfluenceLeadsAddLeadsCampaigns,
  UbuInfluenceLeadsComposeMessage,
  UbuInfluenceLeadsImporterModalList,
  UbuInfluenceLeadsWaitingReview,
  UbuInfluenceMessageThreadCard,
  UbuInfluenceMessageThreadList,
  UbuInfluencePipelineButtonChangeStatus,
  UbuInfluencePipelineTableMention,
  UbuInfluencePipelineTableDate,
  UbuInfluencePipelineTableRow,
  UbuInfluenceSettingsCampaignForm,
  UbuInfluenceModalCreateCampaign,
  UbuInfluenceMentionsTable,
  UbuInfluencePipelineModalMessageScheduled,
  UbuInfluenceModalCreateTikTokOrder,
  UbuInfluenceMentionsTikTok,
  UbuInfluenceMentionsTikTokTable,
};
