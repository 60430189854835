<template>
  <div class="UbuTitleContent_row">
    <div class="UbuTitleContent_editable fbetween">
      <input
        :class="['UbuTitleContent_title', 'ubu-title', 'a-self-center',
                 {
                   'UbuTitleContent_titleReadonly': readonly
                 }]"
        :readonly="readonly"
        :focus="false"
        :value="title"
        v-on="inputListeners"
      >
      <slot name="action" />
    </div>
    <div class="UbuTitleContent_data">
      <slot name="content" />
    </div>
    <slot name="footer" />
  </div>
</template>

<script>
export default {
  name: 'UbuTitleContent',
  props: {
    title: String,
    value: String,
    searchbar: Boolean,
    focus: Boolean,
    disable: Boolean,
    readonly: Boolean,
  },
  computed: {
    inputListeners() {
      const vm = this;
      return {
        ...this.$listeners,
        input: (event) => {
          vm.$emit('input', event.target.value);
        },
        focusout: (event) => {
          vm.$emit('focusout', event.target.value);
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
    .UbuTitleContent_row {

    .UbuTitleContent_editable {
      border: 1px solid var(--mercury);
      border-radius: 12px 12px 0px 0px;
      padding: 2px 16px;

            .UbuTitleContent_title {
                max-width: 200px;

                &.UbuTitleContent_titleReadonly {
                    cursor: default;
                }

                &:focus {
                    outline: none;
                }
            }

      .UbuTitleContent_btnTextColor {
        color: var(--geraldine);
      }
    }

    .UbuTitleContent_data {
      background-color: var(--mercury60);
      border: 1px solid var(--mercury60);
      border-top: none;
      border-radius: 0px 0px 12px 12px;
    }
  }
</style>
