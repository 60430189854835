<template>
  <section
    v-if="fetchDone"
    id="UbuInfluencePipelineDrawers"
  >
    <div v-if="currentCampaign && activities == 0">
      <section
        v-if="campaignDetail"
        id="UbuInfluencePipelineGetStarted"
      >
        <p class="UbuInfluencePipelineGetStarted_welcome">Welcome to your campaign !</p>
        <p class="UbuInfluencePipelineGetStarted_title">
          Before we go live, let’s add some leads !
        </p>
        <router-link
          :to="`/influence/${campaignDetail.campaignId}/leads`"
          class="button is-rounded"
        >
          Add leads
        </router-link>
        <p class="UbuInfluencePipelineGetStarted_description ubu-text3">
          *Leads are the people or account you want to reach out in your campaign
        </p>
      </section>
    </div>
    <div v-else>
      <section
        id="UbuInfluencePipelineTablesDrawer"
        :class="[{ 'isOpen': !toggleResumeOpen }]"
      >
        <UbuInfluencePipelineTableDate
          v-if="scheduleds && campaignDetail"
          :rows="scheduleds"
          :table-detail="{
            title: 'Scheduled',
            icon: 'scheduled',
            color: '1',
            status: 'scheduled',
            reverseOrder: false,
            hasDetail: true,
          }"
          :campaign-detail="campaignDetail"
          @changeStatus="changeStatus($event)"
          @deleteActivity="removeActivity($event)"
          @toggleResumeOpen="toggleResumeOpen = !toggleResumeOpen"
          @showMessageSent="showMessageSent($event)"
        />
        <UbuInfluencePipelineTableDate
          v-if="delivereds && campaignDetail"
          :rows="delivereds"
          :table-detail="{
            title: 'Delivered',
            icon: 'send',
            color: '1',
            status: 'delivered',
            reverseOrder: true,
            hasDetail: true,
          }"
          :campaign-detail="campaignDetail"
          @changeStatus="changeStatus($event)"
          @toggleResumeOpen="toggleResumeOpen = !toggleResumeOpen"
        />
        <UbuInfluencePipelineTableMention
          v-if="talkings && campaignDetail"
          :rows="talkings"
          :table-detail="{
            title: 'Talking',
            icon: 'comments',
            color: '1',
            status: 'talking',
            reverseOrder: false,
            hasDetail: true,
          }"
          :campaign-detail="campaignDetail"
          @changeStatus="changeStatus($event)"
          @toggleResumeOpen="toggleResumeOpen = !toggleResumeOpen"
        />
        <UbuInfluencePipelineTableMention
          v-if="inProgresses && campaignDetail"
          :rows="inProgresses"
          :table-detail="{
            title: 'In Progress',
            icon: 'pin',
            color: '1',
            status: 'inProgress',
            reverseOrder: false,
            hasDetail: true,
          }"
          :campaign-detail="campaignDetail"
          @changeStatus="changeStatus($event)"
          @toggleResumeOpen="toggleResumeOpen = !toggleResumeOpen"
        />
      </section>
      <section
        id="UbuInfluencePipelineResumeDrawer"
        :class="[{ 'isOpen': toggleResumeOpen }]"
      >
        <UbuInfluencePipelineTableMention
          v-if="AllColumn && campaignDetail"
          :rows="AllColumn"
          :table-detail="{
            title: 'All',
            icon: 'team',
            color: '1',
            status: '',
            reverseOrder: true,
            hasDetail: false,
          }"
          :campaign-detail="campaignDetail"
          @changeStatus="changeStatus($event)"
          @toggleResumeOpen="toggleResumeOpen = !toggleResumeOpen"
        />
        <UbuInfluencePipelineTableMention
          v-if="success && campaignDetail"
          :rows="success"
          :table-detail="{
            title: 'Success',
            icon: 'success',
            color: '5',
            status: 'success',
            reverseOrder: true,
            hasDetail: true,
          }"
          :campaign-detail="campaignDetail"
          @changeStatus="changeStatus($event)"
          @toggleResumeOpen="toggleResumeOpen = !toggleResumeOpen"
        />
        <UbuInfluencePipelineTableMention
          v-if="failures && campaignDetail"
          :rows="failures"
          :table-detail="{
            title: 'Failure',
            icon: 'failure',
            color: '2',
            status: 'failure',
            reverseOrder: true,
            hasDetail: true,
          }"
          :campaign-detail="campaignDetail"
          @changeStatus="changeStatus($event)"
          @toggleResumeOpen="toggleResumeOpen = !toggleResumeOpen"
        />
      </section>
      <b-modal
        v-model="isCardModalOpen"
        :width="640"
        scroll="keep"
      >
        <UbuInfluencePipelineModalMessageScheduled
          :message="messageToShow"
          @close="closeModal()"
        />
      </b-modal>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CommonMixin from '../$mixins/CommonMixin';

export default {
  name: 'TheInfluencePipeline',
  mixins: [CommonMixin],
  data() {
    return {
      isOpen: true,
      toggleResumeOpen: false,
      allStatus: [
        'success',
        'failure',
        'scheduled',
        'delivered',
        'talking',
        'unscheduled',
      ],
      allStatusNotAllowed: [
        'scheduled',
        'unscheduled',
      ],
      messageToShow: null,
      isCardModalOpen: false,
    };
  },

  computed: {
    ...mapGetters({
      campaignActivitiesDetails: 'TheInfluence/getterCampaignActivitiesDetails',
      fetchDone: 'Sequence/getterFetchDone',
    }),
    currentCampaign() {
      return this.campaignActivitiesDetails.campaigns
        .find((campaign) => campaign.campaignId === this.$route.params.campaignId);
    },
    campaignDetail() {
      const myCampaign = this.currentCampaign || {};
      const {
        name,
        color,
        updated,
        created,
        channelId,
        campaignId,
      } = myCampaign;

      return {
        name,
        color,
        updated,
        created,
        channelId,
        campaignId,
      };
    },
    activities() {
      return this.currentCampaign ? this.currentCampaign.activities : [];
    },
    alls() {
      return this.activities
        .filter(({ contactDetail }) => contactDetail)
        .map(({
          activityDetail: {
            created: when, status, activityId, ended = false, data, addedOn,
          },
          contactDetail: {
            /* eslint-disable camelcase */
            username,
            full_name,
            profile_pic_url,
            has_bucket_media,
            contactId,
            mentionCount = [],
            tagCount = [],
          },
          // mentions,
          // tagsPosts,
        }) => ({
          activityId,
          username,
          full_name,
          profile_pic_url,
          when,
          status,
          has_bucket_media,
          contactId: String(contactId),
          mentions: mentionCount.filter((ts) => ts > addedOn),
          tagsPosts: tagCount.filter((ts) => ts > addedOn),
          data,
          ended,
        }));
      /* eslint-enable camelcase */
    },
    success() {
      return this.alls.filter(({ status }) => status === 'success');
    },
    failures() {
      return this.alls.filter(({ status }) => status === 'failure');
    },
    scheduleds() {
      return this.alls
        .filter(({ status }) => status === 'scheduled')
        .map((row) => (row.data.scheduledSend ? { ...row, when: row.data.scheduledSend } : row));
    },
    delivereds() {
      return this.alls.filter(({ status }) => status === 'delivered');
    },
    talkings() {
      return this.alls.filter(({ status }) => status === 'talking');
    },
    inProgresses() {
      return this.alls.filter(({ status }) => !this.allStatus.includes(status));
    },
    AllColumn() {
      return this.alls.filter(({ status }) => !this.allStatusNotAllowed.includes(status));
    },
  },
  methods: {
    ...mapActions({
      setStatus: 'TheInfluence/setStatus',
      deleteActivity: 'TheInfluence/deleteActivity',
    }),

    removeActivity(event) {
      this.deleteActivity({
        activityId: event.activityId,
        contactId: event.contactId,
        campaignId: this.$route.params.campaignId,
        channelId: this.campaignActivitiesDetails._id,
      });
    },
    changeStatus(event) {
      // console.log('🚀 ~ file: TheInfluencePipeline.vue ~ line 138 ~ changeStatus ~ event', event);
      const { username, toStatus: status } = event;
      const contactDetail = this.alls.find((all) => all.username === username);
      // console.log('🚀 ~ file: TheInfluencePipeline.vue ~ line 142 ~ changeStatus ~ contactDetail', contactDetail);
      const activity = this.activities
        .find(({ activityDetail }) => activityDetail.activityId === contactDetail.activityId);
      // console.log('🚀 ~ file: TheInfluencePipeline.vue ~ line 144 ~ changeStatus ~ activity', activity);
      const thisActivity = {
        ...activity.activityDetail,
        status,
      };
      // console.log('🚀 ~ file: TheInfluencePipeline.vue ~ line 149 ~ changeStatus ~ thisActivity', thisActivity);
      this.setStatus(thisActivity);
    },
    showMessageSent(event) {
      const { data: { message } } = event;
      this.isCardModalOpen = true;
      this.messageToShow = message;
    },
    closeModal() {
      this.isCardModalOpen = false;
      this.messageToShow = null;
    },
  },
};
</script>

<style lang="scss">
.pipelineTable_title, .pipelineTable_counter {
  color: var(--table-color);
}
#UbuInfluencePipelineDrawers {
  height: calc(100vh - 75px);
  max-height: calc(100vh - 75px);
  & #UbuInfluencePipelineGetStarted{
    padding: 100px 50px 50px 200px;
    .button{
      margin: 24px 0;
    }
    .UbuInfluencePipelineGetStarted_welcome{
      color: var(--campaign-color);
      font-size: 20px;
      font-weight: 500;
    }
    .UbuInfluencePipelineGetStarted_title{
      color: var(--campaign-color);
      font-size: 24px;
      font-weight: 700;
    }
    .UbuInfluencePipelineGetStarted_description{
      color: var(--darkgrey60);
    }
  }
  & #UbuInfluencePipelineTablesDrawer {
    // padding: 0 24px;
    display: flex;
    overflow-x: auto;
    // width: calc(100vw - 24px);
    // width: 100vw;
    padding-top: 8px;
    padding-bottom: 8px;
    &.isOpen {
      height: calc(100vh - 150px);
      width: 100%;
    }
    &:not(.isOpen) {
      & .pipelineTable {
        max-height: 52px;
      }
    }

    & .ubuDropDown {
      border: 1px solid var(--mercury);
      border-radius: 12px;
      & .button{
        border-color: var(--soapstone);
        border-radius: 12px;
        background-color: var(--soapstone);
        color: var(--cloudburst);
        &:hover {
          background-color: var(--peachcream);
        }
      }
    }
  }

  & #UbuInfluencePipelineInProgressesTable {
    & .ubuDropDown {
      border: 1px solid var(--mercury);
      border-radius: 12px;
      & .button{
        border-color: var(--soapstone);
        border-radius: 12px;
        background-color: var(--soapstone);
        color: var(--cloudburst);
        &:hover {
          background-color: var(--peachcream);
        }
      }
    }
  }

  & #UbuInfluencePipelineResumeDrawer {
    // padding: 0 24px;
    display: flex;
    // overflow-x: auto;
    // width: calc(100vw - 24px);
    // width: 100vw;
    padding-top: 8px;
    padding-bottom: 8px;
    &.isOpen {
      & .pipelineTable {
        height: calc(100vh - 170px);
      }
    }
    &:not(.isOpen) {
      & .pipelineTable {
        max-height: 52px;
      }
    }
    // & .pipelineTable {
    //   &:not(.isClose) {
    //     max-height: 550px;
    //   }
    // }
  }
  // & .pipelineTable {
  //   &.isClose {
  //     max-height: 43px;
  //   }
  // }
  & #UbuInfluencePipelineTablesDrawer.isOpen {
    & .pipelineTable {
      & .pipelineTable_content {
        overflow-y: auto;
        max-height: 100%;
      }
    }
  }
}
</style>
