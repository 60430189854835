import builder from '../../$utils/axiosComments';

export default class CommentProvider {
  static postComment({ channelId, parentId, targetId, message }) {
    return builder({
      method: 'post',
      url: `/channel/${channelId}/commentReply/${parentId}`,
      data: { message },
    });
  }

  static postRootComment({ channelId, postId, message }) {
    return builder({
      method: 'post',
      url: `/channel/${channelId}/postReply/${postId}`,
      data: { message },
    });
  }


  static postCommentFb({ channelId, parentId, targetId, message }) {
    return builder({
      method: 'post',
      url: `/channel/${channelId}/commentReplyFb`,
      data: { parentId, targetId, message },
    });
  }

  static openCloseThread({
    channelId, commentId, closed, source,
  }) {
    return builder({
      method: 'get',
      url: `/channel/${channelId}/closeComment${source === 'facebook' ? 'Fb' : ''}/${commentId}/${closed}`,
    });
  }
  
  static closeManyThreads({
    channelId, closed, threadIds, source,
  }) {
    return builder({
      method: 'post',
      url: `/channel/${channelId}/closeManyComments${source === 'facebook' ? 'Fb' : ''}/${closed}`,
      data: { comment_ids: [...threadIds] },
    });
  }

  static closeAllComments({
    channelId, mediaId, source,
  }) {
    return builder({
      method: 'post',
      url: `/channel/${channelId}/closeAll${source === 'facebook' ? 'Fb' : ''}Comments/${mediaId}`,
    });
  }

  static fetchMedia({ channelId, mediaId }) {
    return builder({
      method: 'get',
      url: `/channel/${channelId}/instaMedia/${mediaId}`,
    });
  }

  static deleteComment({ commentId, channelId }) {
    return builder({
      method: 'get',
      url: `/channel/${channelId}/deleteComment/${commentId}`,
    });
  }
}
