<template>
  <div class="workspace-modal">
    <div class="modal-header">
      <p class="modal-header-title ubu-title">Change password</p>

      <UbuIcon
        name="cross"
        clickable
        @click="$emit('close', $event)"
      />
    </div>
    <div class="modal-content">
      <form
        class="modal-content-input-wrapper"
        @keyup.enter="$emit('editFromModal', password)"
      >
        <UbuTextField
          v-model="password"
          class="pl-3 mb-3"
          label="Password"
          type="password"
          placeholder="**********"
          :error="showError ? `Passwords don't match` : '' "
          @input="showError = false"
        />
        <UbuTextField
          v-model="confirmPw"
          class="pl-3"
          label="Confirm password"
          type="password"
          placeholder="**********"
          :error="showError ? `Passwords don't match` : '' "
          @input="showError = false"
        />
      </form>
    </div>

    <div class="modal-footer">
      <UbuButton
        grow
        center
        secondary
        :disabled="passwordEmpty"
        @click="setPassword()"
      >
        Save
      </UbuButton>
    </div>
  </div>
</template>

<script>

export default {
  name: 'UbuWorkspaceModalEditPassword',
  data() {
    return {
      password: '',
      confirmPw: '',
      showError: false,
    };
  },
  computed: {
    passwordMatch() {
      return this.password === this.confirmPw;
    },
    passwordEmpty() {
      return !this.password || !this.confirmPw;
    },
  },
  methods: {
    setPassword() {
      if (!this.passwordMatch && !this.passwordEmpty) this.showError = true;
      else this.$emit('editFromModal', this.password);
    },
  },
};
</script>
