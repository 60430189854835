import axios from 'axios';
import axiosResponseParser from './axiosResponseParser';
import routes from '../../../conf/routes';

const $axios = axios.create({
  baseURL: `${routes.api.url}/api`,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    haspage: 'influenceV1',
  },
  responseType: 'blob',
});

const builder = (options) => $axios.request(options)
  // .then(axiosResponseParser.parseResponse)
  // .catch(axiosResponseParser.parseError);

export default builder;
