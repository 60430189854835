/* eslint-disable max-len */
// import query from '../../dataStores/stores/query';
import LeadsProvider from '../providers/leads.provider';
import query from '../../dataStores/stores/query';

export default {
  namespaced: true,

  state: {
  },

  getters: {
    getterLists: (_state, _getters, _rootState, rootGetters) => {
      const { 'Sequence/getterFetchDone': fetchDone } = rootGetters;

      if (!fetchDone) return [];

      const {
        'Channel/getterCurrentChannelId': channelId,
        'Channel/getterChannelsHasLists': getterChannelsHasLists,
        'List/getterLists': lists,
      } = rootGetters;

      const response = query.findObjectLite({
        model: 'lists',
        relationTable: getterChannelsHasLists,
        dataTable: lists,
        fromId: channelId,
      });

      return response;
    },
  },

  actions: {
    searchUser(_, { channelId, username }) {
      return LeadsProvider
        .searchUser({ channelId, username })
        .then(({ response, error }) => {
          console.log('🚀 ~ file: TheLeads.store.js ~ line 19 ~ .then ~ error', error);
          console.log('🚀 ~ file: TheLeads.store.js ~ line 19 ~ .then ~ response', response);
          if (response && response.users) return response.users;
          return [];
        });
    },
    createList({ commit }, list) {
      return LeadsProvider
        .createList(list)
        .then(({ error, response }) => {
          console.log('🚀 ~ file: TheLeads.store.js ~ line 28 ~ .then ~ error', error);
          if (error) throw new Error('Unable to create a campaign');
          if (response) {
            console.log('🚀 ~ file: TheLeads.store.js ~ line 30 ~ .then ~ response', response);
          }
          return true;
        });
    },
  },
  mutations: {
  },
};
