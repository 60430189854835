import {
  filterThreads,
  sortThreads,
} from '@/addons/$utils/threadUtilities';

export default {
  data: () => ({
    localFilterBoxIsOpen: false,
  }),
  computed: {
    filterBoxIsOpen: {
      get() {
        return !!(this.localFilterBoxIsOpen
          || this.sort !== this.defaultSort
          || this.filter !== this.defaultFilter);
      },
      set(open) {
        this.localFilterBoxIsOpen = !!open;
        if (!open) {
          this.resetToDefaultFilter();
        }
      },
    },
    show: {
      get() {
        return this.$route.query.show || 'opened';
      },
      set(show) {
        this.mergeQueryParams({ show }, true);
      },
    },
    filter: {
      get() {
        return this.$route.query.filter || this.defaultFilter;
      },
      set(filter) {
        this.mergeQueryParams({ filter });
      },
    },
    sort: {
      get() {
        return this.$route.query.sort || this.defaultSort;
      },
      set(sort) {
        this.mergeQueryParams({ sort });
      },
    },
    defaultFilter() {
      if (this.$route.query.show === 'all'
        && this.filterOptions.find((f) => f.key === 'all')) {
        return 'all';
      }
      return this.$route.meta.defaultFilter || 'received';
    },
    defaultSort() {
      return this.$route.meta.defaultSort || 'asc:timestamp';
    },
    filterOptions() {
      return this.$route.meta.filterOptions;
    },
    sortOptions() {
      return this.$route.meta.sortOptions;
    },
    selectedThreadId() {
      return this.$route.query.tId;
    },
    filteredThreads() {
      return filterThreads(this.threads, this.filter);
    },
    sortedThreads() {
      const threads = this.filteredThreads;
      if (this.selectedThreadId) {
        const isIn = threads.find((t) => t.threadId === this.selectedThreadId);
        if (!isIn && this.getterThreadsMap) {
          const threadToAdd = this.getterThreadsMap[this.selectedThreadId];
          if (threadToAdd) { threads.push({ ...threadToAdd, isAway: true }); }
        }
      }
      return sortThreads(threads, this.sort);
    },
    visibleThreads() {
      return this.sortedThreads;
    },
  },
  methods: {
    mergeQueryParams(_query, removeThreadId = false, mode = 'push') {
      const query = {
        ...this.$route.query,
        ..._query,
      };
      if (query.filter === this.defaultFilter) { delete query.filter; }
      if (query.sort === this.defaultSort) { delete query.sort; }
      if (query.show === 'opened') { delete query.show; }
      if (removeThreadId) { delete query.tId; }
      this.$router[mode]({ query }).catch((e) => e);
    },
    resetToDefaultFilter() {
      this.mergeQueryParams({
        filter: this.defaultFilter,
        sort: this.defaultSort,
      });
    },
    selectNextThread() {
      if (this.visibleThreads) {
        const idx = this.visibleThreads.findIndex((thread) => thread.threadId === this.selectedThreadId);
        const thread = this.visibleThreads[idx + 1];
        if (thread) { this.selectThread({ thread }); }
      }
    },
    selectFirstThread() {
      if (this.visibleThreads) {
        const thread = this.visibleThreads[0];
        if (thread) { this.selectThread({ thread, replace: true }); }
      }
    },
  },
  watch: {
    fetchInboxIsDone() {
      if (!this.$route.query.tId) {
        this.selectFirstThread();
      }
    },
    $route: {
      immediate: true,
      handler(to, from) {
        if (from && (to.name !== from.name
          || to.query.show !== from.query.show)) {
          this.resetLastTts();
        }
        this.setPageTitle();
        const selectedThreadId = to.query.tId;
        if (selectedThreadId) {
          this.$update_currentThreadId({ newThread: { threadId: selectedThreadId } });
        } else {
          this.selectFirstThread();
        }
      },
    },
  },
};
