// function changeFavicon(src) {
//   const link = document.createElement('link');
//   const oldLink = document.getElementById('favicon');
//   link.id = 'favicon';
//   link.rel = 'shortcut icon';
//   link.type = 'svg+xml"';
//   link.href = src;
//   if (oldLink) {
//     document.head.removeChild(oldLink);
//   }
//   document.head.appendChild(link);
// }

export default {
  data() {
    return {
      navIsDark: window.matchMedia('(prefers-color-scheme: dark)').matches,
    };
  },

  computed: {
    isDark() {
      return this.theme === 'dark' || (this.theme === 'auto' && this.navIsDark);
    },
    theme: {
      get() {
        return this.$store.state.Theme.theme;
      },
      set(theme) {
        this.$store.dispatch('Theme/setTheme', theme);
      },
    },
  },
  mounted() {
    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', (e) => {
        this.navIsDark = e.matches;
      });
    if (this.isDark) {
      document.documentElement.classList.add('theme--dark');
    }
  },
  methods: {
    switchTheme() {
      this.theme = this.isDark ? 'classic' : 'dark';
    },
  },
  watch: {
    // navIsDark: {
    //   immediate: true,
    // handler(navIsDark) {
    //   console.log('Changing navico');
    //   if (navIsDark) {
    //     changeFavicon('/ubuLogo-dark.svg');
    //   } else {
    //     changeFavicon('/ubuLogo-light.svg');
    //   }
    // },
    // },
    isDark(isDark) {
      if (isDark) {
        document.documentElement.classList.add('theme--dark');
      } else {
        document.documentElement.classList.remove('theme--dark');
      }
    },
  },
};
