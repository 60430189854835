<template>
  <div id="UbuCommentTextarea">
    <UbuCommentTextareaHeader
      v-if="selectedReply && selectedReply.commentId"
      :text="selectedReply.text"
      :from="selectedReply.from"
      @cancelReply="$emit('cancelReply', $event)"
    />
    <div class="textarea-container">
      <span class="textarea-prefix">{{ prefix }}</span>
      <textarea
        ref="input"
        v-model="localNewComment"
        v-focus
        v-autogrow
        class="ubuTextarea-input ubu-scrollbar ubu-text-input"
        rows="1"
        placeholder="Type a message..."
        @blur="getCaretPosition()"
        @keyup.space="insertTemplate($event)"
        @keydown.enter.exact.prevent="sendByKeyPress($event)"
        @keydown="keyPressHandler($event)"
      />
    </div>

    <VEmojiPicker
      v-if="showEmojiPicker"
      v-click-outside="() => showEmojiPicker = false"
      class="ubuTextarea-emojiPicker"
      @select="selectEmoji($event)"
    />

    <div class="ubuTextarea-toolBox">
      <div class="ubuTextarea-tools">
        <b-tooltip
          label="Add an emoji"
          position="is-top"
        >
          <UbuIcon
            clickable
            name="emojis"
            @click="showEmojiPicker = !showEmojiPicker"
          />
        </b-tooltip>

        <b-tooltip label="Translate">
          <b-dropdown
            aria-role="list"
            position="is-top-right"
          >
            <template #trigger>
              <UbuIcon
                clickable
                name="translate"
              />
            </template>

            <b-dropdown-item
              class="translate-action"
              custom
            >
              <span>
                <UbuIcon
                  name="translate"
                /> Translate to: </span>
            </b-dropdown-item>

            <b-dropdown-item
              v-for="lang in languageList"
              :key="lang.value"
              :value="lang.value"
              @click="selectLanguage(lang.value)"
            >
              <div class="language-content">
                <b-image
                  class="language-flag"
                  :src="lang.icon"
                />
                {{ lang.label }}
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </b-tooltip>

        <div class="ubuTextarea-dropdownParent">
          <b-tooltip
            label="Add a template"
            position="is-top"
          >
            <UbuIcon
              v-click-outside="e => dropdownOpened = false"
              class="a-self-center"
              name="templates"
              :filled="false"
              :tooltip="{ label: 'Templates', position: 'is-top' }"
              clickable
              @click="dropdownOpened = !dropdownOpened"
            />
          </b-tooltip>
          <div
            v-if="dropdownOpened"
            class="ubuTextarea-dropdown"
          >
            <div
              v-for="(t, index) in templateList"
              :key="index"
            >
              <div
                class="ubu-label cursor-pointer ellipsis"
                @click="putTemplate(t)"
              >
                /{{ t.title }}
              </div>
            </div>
            <UbuButton
              class="UbuMessageTextarea_createTemplate ubu-label cursor-pointer mt-2"
              @click="onClickTemplate()"
            >
              Create template
            </UbuButton>
          </div>
        </div>
      </div>
      <UbuButton
        v-if="showSendAndClose"
        :disabled="!canSend"
        class="ubu-f-end UbuButton_flatHover mr-1"
        :icon="{ name: 'send', filled: true }"
        color="2"
        :tooltip="{
          label: isMac ? 'Cmd + Enter' : 'Ctrl + Enter',
          position: 'is-top',
          delay: 500
        }"
        :loading="buttonLoading"
        @click="sendCommentAndClose()"
      >
        Send & Close
      </UbuButton>
      <UbuButton
        v-if="newComment.length"
        :disabled="!canSend"
        class="ubu-f-end"
        color="7"
        :icon="{ name: 'send', filled: true }"
        :loading="buttonLoading"
        @click="sendComment()"
      >
        Send
      </UbuButton>
      <template v-else-if="allowClose">
        <UbuButton
          v-if="!isClosedThread"
          class="ubu-f-end"
          color="2"
          :icon="{ name: 'success', filled: true }"
          :reactions="['🥰', '🙈', '✨', '😇', '❤️']"
          :tooltip="{
            label: isMac ? 'Cmd + Enter' : 'Ctrl + Enter',
            position: 'is-left',
            delay: 500
          }"
          :reactions-tooltip="isMac ? 'Send emoji & close (Cmd+b)' :
            'Send emoji & close (Ctrl+b)'"
          :loading="buttonLoading"
          @click="$emit('openCloseThread', { closed: true })"
          @react="$emit('sendCommentAndClose', $event)"
        >
          Close
        </UbuButton>
        <UbuButton
          v-else
          color="5"
          class="ubu-f-end"
          :icon="{ name: 'messages', filled: true }"
          :tooltip="{
            label: isMac ? 'Cmd + Enter' : 'Ctrl + Enter',
            position: 'is-top',
            delay: 500
          }"
          :loading="buttonLoading"
          @click="$emit('openCloseThread', { closed: false })"
        >
          Open
        </UbuButton>
      </template>
    </div>
  </div>
</template>
<script>
import { VEmojiPicker } from 'v-emoji-picker';
import { TextareaAutogrowDirective } from 'vue-textarea-autogrow-directive';

export default {
  name: 'UbuCommentTextarea',
  components: {
    VEmojiPicker,
  },
  directives: {
    autogrow: TextareaAutogrowDirective,
  },
  model: {
    prop: 'newComment',
  },
  props: {
    allowClose: {
      type: Boolean,
      default: true,
    },
    prefix: {
      type: String,
      default: '',
    },
    selectedReply: {
      type: Object,
      default: null,
    },
    isClosedThread: {
      type: Boolean,
      required: true,
    },
    buttonLoading: {
      type: Boolean,
      default: false,
    },
    newComment: {
      type: String,
      default: '',
    },
    templateList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showEmojiPicker: false,
      dropdownOpened: false,
      cursor: 0,
      languageList: [
        { label: 'English', value: 'en', icon: '/img/UK.svg' },
        { label: 'French', value: 'fr', icon: '/img/France.svg' },
        { label: 'Spanish', value: 'es', icon: '/img/Spain.svg' },
        { label: 'German', value: 'de', icon: '/img/Germany.svg' },
      ],
    };
  },
  computed: {
    canSend() {
      return this.selectedReply && this.selectedReply.canComment;
    },
    localNewComment: {
      get() { return this.newComment; },
      set(value) { this.$emit('input', value); },
    },
    fullName() {
      return this.selectedReply && this.selectedReply.username ? this.selectedReply.username : '';
    },
    showSendAndClose() {
      return this.newComment.length && !this.isClosedThread && this.allowClose;
    },
    isMac() {
      return navigator.platform.toUpperCase().indexOf('MAC') >= 0;
    },
  },
  methods: {
    sendByKeyPress(event) {
      if (event.keyCode === 13 && !event.shiftKey
      && (this.localNewComment.trim().length - this.prefix.length) > 0 && this.canSend) {
        this.$emit('sendComment', `${this.localNewComment.trim()}`);
      }
    },
    sendComment() {
      if (this.canSend) {
        this.$emit('sendComment', `${this.localNewComment.trim()}`);
      }
    },
    sendCommentAndClose() {
      if (this.canSend && this.allowClose) {
        this.$emit('sendCommentAndClose', `${this.localNewComment.trim()}`);
      }
    },
    getCaretPosition() {
      this.cursor = this.$refs.input.selectionStart;
    },
    selectEmoji(emoji) {
      this.localNewComment = this.localNewComment
        .slice(0, this.cursor) + emoji.data + this.localNewComment
        .slice(this.cursor);
      this.$refs.input.focus();
      this.showEmojiPicker = false;
    },
    randomEmoji() {
      const emojis = ['🥰', '🙈', '✨', '😇', '❤️'];
      return emojis[Math.floor(Math.random() * emojis.length)];
    },
    keyPressHandler(event) {
      const ctrlPressed = this.isMac ? event.metaKey : event.ctrlKey;
      const ctrlEnterPressed = ctrlPressed && event.keyCode === 13;
      const ctrlBPressed = ctrlPressed && event.keyCode === 66;
      if (ctrlBPressed && !this.isClosedThread && this.canSend) {
        this.$emit('sendCommentAndClose', this.prefix + this.randomEmoji());
      }
      const emptyComment = !this.newComment.length;
      // if (event.keyCode === 8
      // && (this.localNewComment.length - this.prefix.length) < 1) event.preventDefault();
      if (ctrlEnterPressed && this.showSendAndClose && this.canSend) {
        this.sendCommentAndClose();
      } else if (ctrlEnterPressed && emptyComment) {
        this.$emit('openCloseThread', { closed: !this.isClosedThread });
      }
    },
    selectLanguage(lang) {
      this.$emit('translateTextarea', { target: lang });
    },
    simulateEventTextarea(text) {
      const evt = document.createEvent('Events');
      evt.initEvent('input', true, true);
      evt.data = text;
      this.$refs.input.value = text;
      this.$refs.input.dispatchEvent(evt);
      this.resizeParent();
    },
    onClickTemplate() {
      return this.$router.push({ path: '/workspace/templates' });
    },
    insertTemplate(event) {
      const { value } = event.target;
      /**
       * @todo Optimiser ça, ça me semble très lourd quand même
       * Je met un reg.test pour éviter des events inutiles;
       */
      Object.values(this.templateList).forEach((template) => {
        const re = new RegExp(`(?<=\\s||^)(\\/${template.title})\\b`);
        if (re.test(value)) {
          const templateContent = template.data.replace(/{name}/gi, this.fullName);
          this.simulateEventTextarea(value.replace(re, templateContent));
        }
      });
    },

    putTemplate(t) {
      const templateArray = this.localNewComment.split('');
      const finalData = t.data.replace(/{name}/gi, this.fullName);
      templateArray.splice(this.cursor, 0, finalData);
      this.simulateEventTextarea(templateArray.join(''));
      this.$refs.input.focus();
    },
    resizeParent() {
      setTimeout(() => this.$emit('autoResizeFooter'), 0);
    },
  },
};
</script>
