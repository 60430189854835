<template>
  <div class="comment-infos__user">
    <div
      v-if="from.profilePicture"
      :class="{
        'picture': true,
        'is-square': imgIsSquare,
      }"
      :style="`background-image: url(${from.profilePicture || ''})`"
    />
    <div
      v-else-if="!isPending"
      style="border-radius: 50%; overflow: hidden; margin-right: 20px;"
    >
      <div
        class="card-avatar-unfoundable"
      >
        <UbuIcon
          name="placeholder"
          filled
        />
      </div>
    </div>
    <UbuLoader
      v-else
      style="width: 25px; height: 25px;"
    />
    <div class="username">
      <a
        :href="!isPending ? from.link : 'javascript:void(0)'"
        target="blank"
      >
        {{ `${from.fullName}` }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UbuCommonCommentInfosUser',
  props: {
    from: {
      type: Object,
      default: () => {},
    },
    isPending: {
      type: Boolean,
      default: false,
    },
    imgIsSquare: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    fallbackSrc: null,
  }),
  computed: {
    src() {
      return this.fallbackSrc || this.from.profilePicture;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
