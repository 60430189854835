<template>
  <div
    class="UbuUserPicture_wrapper flex ubu-text2"
  >
    <div
      name="default"
      :style="`width: ${size}px; height: ${size}px; font-size: ${size * .5}px`"
      :class="['UbuUserPicture_currentViewer', `UbuUserPicture_color${color}`]"
    >
      {{ initialName }}
    </div>
    <UbuIcon
      v-if="isTyping"
      name="typing"
      :outline="true"
      class="UbuUserPicture_isTyping"
    />
  </div>
</template>
<script>
export default {
  name: 'UbuUserPicture',
  props: {
    color: {
      type: [Number, String],
      default: 1,
    },
    initialName: {
      type: String,
      default: '',
    },
    size: {
      type: Number,
      default: 39,
    },
    // user: {
    //   type: Object,
    //   default: () => {},
    // },
    isTyping: Boolean,
  },
};
</script>
<style lang="scss" scoped>
.UbuUserPicture_currentViewer {
  color: var(--soapstone);
  box-shadow: 0px 0px 3.77778px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.UbuUserPicture_isTyping {
  padding: 3px;
  margin-left: -10px;
  border-radius: 50%;
  background: var(--soapstone);
  align-self: flex-end;
}
@for $i from 1 through 12 {
  .UbuUserPicture_color#{$i} {
    color: var(--soapstone);
    background-color: var(--color#{$i});
    border: none;
  }
}
</style>
