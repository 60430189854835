const analyticsCatalog = {
  // ACCOUNT
  final_followers: {
    name: 'Total followers',
    type: 'account',
    icon: 'account',
    color: 12,
    subtype: [
      { ordinal: 1, type: 'overview', size: 25 },
      { ordinal: 1, type: 'followers', size: 32.5 },
    ],
  },
  mean_engagement_total: {
    name: 'Engagement rate',
    type: 'account',
    icon: 'love',
    color: 9,
    isPercent: true,
    subtype: [
      { ordinal: 2, type: 'overview', size: 25 },
      { ordinal: 1, type: 'engagement', size: 50 },
    ],
  },
  reach: {
    name: 'Total reach',
    type: 'account',
    icon: 'eye',
    color: 4,
    subtype: [
      { ordinal: 3, type: 'overview', size: 25 },
      { ordinal: 2, type: 'visibility', size: 25 },
    ],
  },
  website_clicks: {
    name: 'Website clicks',
    type: 'account',
    icon: 'influence',
    color: 8,
    subtype: [
      { ordinal: 3, type: 'overview', size: 25 },
      { ordinal: 2, type: 'callToAction', size: 20 },
    ],
  },
  followers_difference: {
    name: 'Followers evolution',
    type: 'account',
    icon: 'account',
    color: 12,
    subtype: [
      { ordinal: 2, type: 'followers', size: 33.33 },
    ],
  },
  new_followers: {
    name: 'New followers',
    type: 'account',
    icon: 'account',
    color: 12,
    subtype: [
      { ordinal: 2, type: 'followers', size: 33.33 },
    ],
  },
  mean_engagement: {
    name: 'Engagement rate for choosen period',
    type: 'account',
    icon: 'love',
    color: 9,
    isPercent: true,
    subtype: [
      { ordinal: 2, type: 'engagement', size: 50 },
    ],
  },
  like_count: {
    name: 'Likes',
    type: 'account',
    icon: 'love',
    color: 9,
    subtype: [
      { ordinal: 3, type: 'engagement', size: 25 },
    ],
  },
  likesPerPostAverage: {
    name: 'Avg likes per post',
    type: 'account',
    icon: 'love',
    color: 9,
    subtype: [
      { ordinal: 4, type: 'engagement', size: 25 },
    ],
  },
  comments_count: {
    name: 'Comments',
    type: 'account',
    icon: 'love',
    color: 9,
    subtype: [
      { ordinal: 5, type: 'engagement', size: 25 },
    ],
  },
  commentsPerPostAverage: {
    name: 'Avg comments per post',
    type: 'account',
    icon: 'love',
    color: 9,
    subtype: [
      { ordinal: 6, type: 'engagement', size: 25 },
    ],
  },
  impressions: {
    name: 'Total prints',
    type: 'account',
    icon: 'eye',
    color: 4,
    subtype: [
      { ordinal: 1, type: 'visibility', size: 25 },
    ],
  },
  postsReach: {
    name: 'Reach generated by your posts',
    type: 'account',
    icon: 'eye',
    color: 4,
    subtype: [
      { ordinal: 3, type: 'visibility', size: 25 },
    ],
  },
  storiesReach: {
    name: 'Reach generated by your stories',
    type: 'account',
    icon: 'eye',
    color: 4,
    subtype: [
      { ordinal: 4, type: 'visibility', size: 25 },
    ],
  },
  reachByPostAverage: {
    name: 'Avg reach per post',
    type: 'account',
    icon: 'eye',
    color: 4,
    subtype: [
      { ordinal: 5, type: 'visibility', size: 25 },
    ],
  },
  reachByStoryAverage: {
    name: 'Avg reach per story',
    type: 'account',
    icon: 'eye',
    color: 4,
    subtype: [
      { ordinal: 6, type: 'visibility', size: 25 },
    ],
  },
  profile_views: {
    name: 'Profile views',
    type: 'account',
    icon: 'eye',
    color: 4,
    subtype: [
      { ordinal: 7, type: 'visibility', size: 25 },
    ],
  },
  get_directions_clicks: {
    name: 'Get direction clicks',
    type: 'account',
    icon: 'influence',
    color: 8,
    subtype: [
      { ordinal: 2, type: 'callToAction', size: 20 },
    ],
  },
  email_contacts: {
    name: 'Email clicks',
    type: 'account',
    icon: 'influence',
    color: 8,
    subtype: [
      { ordinal: 3, type: 'callToAction', size: 20 },
    ],
  },
  phone_call_clicks: {
    name: 'Phone call clicks',
    type: 'account',
    icon: 'influence',
    color: 8,
    subtype: [
      { ordinal: 4, type: 'callToAction', size: 20 },
    ],
  },
  text_message_clicks: {
    name: 'Text message clicks',
    type: 'account',
    icon: 'influence',
    color: 8,
    subtype: [
      { ordinal: 5, type: 'callToAction', size: 20 },
    ],
  },

  // INBOX
  messages_received: {
    name: 'Received messages',
    type: 'inbox',
    icon: 'messages',
    color: 2,
    subtype: [
      { ordinal: 1, type: 'overview', size: 32.5 },
    ],
  },
  comments_recevied: {
    name: 'Received comments',
    type: 'inbox',
    icon: 'comments',
    color: 3,
    subtype: [
      { ordinal: 2, type: 'overview', size: 32.5 },
    ],
  },
  mentions_stories: {
    name: 'Received story mentions',
    type: 'inbox',
    icon: 'mentions',
    color: 9,
    subtype: [
      { ordinal: 3, type: 'overview', size: 32.5 },
    ],
  },
  messages_sent: {
    name: 'Sent messages',
    type: 'inbox',
    icon: 'messages',
    color: 2,
    subtype: [
      { ordinal: 4, type: 'overview', size: 50 },
    ],
  },
  reactions_stories: {
    name: 'Received story reactions',
    type: 'inbox',
    icon: 'mentions',
    color: 9,
    subtype: [
      { ordinal: 5, type: 'overview', size: 50 },
    ],
  },

  // SHOPIFY
  totalRevenuesFromStore: {
    name: 'Total revenues from store',
    ordinal: 1,
    type: 'shopify',
    size: 50,
    color: 5,
    icon: 'money',
    isDollar: true,
  },
  totalOrdersFromStore: {
    name: 'Total orders from store',
    ordinal: 2,
    type: 'shopify',
    size: 50,
    color: 8,
    icon: 'shop',
  },
  totalRevenuesFromInstagram: {
    name: 'Total revenues from Instagram',
    ordinal: 3,
    type: 'shopify',
    size: 50,
    color: 5,
    icon: 'money',
    isDollar: true,
  },
  totalOrdersFromInstagram: {
    name: 'Total orders from Instagram',
    ordinal: 4,
    type: 'shopify',
    size: 50,
    color: 8,
    icon: 'shop',
  },
  totalRevenuesFromLinks: {
    name: 'Total revenues from affiliates & coupons',
    ordinal: 5,
    type: 'shopify',
    size: 50,
    color: 5,
    icon: 'money',
    isDollar: true,
  },
  totalOrdersFromLinks: {
    name: 'Total orders from affiliates & coupons',
    ordinal: 6,
    type: 'shopify',
    size: 50,
    color: 8,
    icon: 'shop',
  },
};

export {
  // eslint-disable-next-line import/prefer-default-export
  analyticsCatalog,
};
