/* eslint-disable no-multi-spaces */
// const TheAuthenticate = () => import(/* webpackChunkName: "addonAuthenticate" */ './TheAuthenticate');
// const TheAuthenticateLogin = () => import(/* webpackChunkName: "addonAuthenticate" */ './TheAuthenticateLogin');
// const TheAuthenticateSignup = () => import(/* webpackChunkName: "addonAuthenticate" */ './TheAuthenticateSignup');
// const TheAuthenticateSignupToken = () => import(/* webpackChunkName: "addonAuthenticate" */ './TheAuthenticateSignupToken');
// const TheAuthenticateLogout = () => import(/* webpackChunkName: "addonAuthenticate" */ './TheAuthenticateLogout');
// const TheAuthenticateResetPassword = () => import(/* webpackChunkName: "addonAuthenticate" */ './TheAuthenticateResetPassword');

import TheAuthenticate from './TheAuthenticate';
import TheAuthenticateLogin from './TheAuthenticateLogin';
import TheAuthenticateSignup from './TheAuthenticateSignup';
import TheAuthenticateSignupToken from './TheAuthenticateSignupToken';
import TheAuthenticateLogout from './TheAuthenticateLogout';
import TheAuthenticateResetPassword from './TheAuthenticateResetPassword';

export default [
  {
    path: '/login',
    redirect: '/auth/login',
  },
  {
    path: '/adminlogin',
    redirect: '/auth/login',
  },
  {
    path: '/auth',
    name: 'Authenticate',
    redirect: '/auth/login',
    component: TheAuthenticate,
    children: [
      {
        path: '/auth/login',
        name: 'Login',
        component: TheAuthenticateLogin,
      },
      {
        path: '/auth/signup/',
        name: 'Signup',
        component: TheAuthenticateSignup,
      },
      {
        path: '/auth/signup/:token',
        name: 'Signup invited',
        component: TheAuthenticateSignupToken,
      },
      {
        path: '/auth/login/:token',
        name: 'Login invited',
        component: TheAuthenticateLogin,
      },
      {
        path: '/auth/logout',
        name: 'Logout',
        component: TheAuthenticateLogout,
      },
      {
        path: '/auth/reset-password',
        name: 'Reset Password step 1',
        component: TheAuthenticateResetPassword,
        meta: {
          isResetPasswordView: true,
        },
      },
      {
        path: '/auth/reset-password/:token',
        name: 'Reset Password step 2',
        meta: {
          isResetPasswordView: true,
        },
        component: TheAuthenticateResetPassword,
      },
    ],
  },
];
