/* eslint-disable no-multi-spaces */

// const TheMentions = () => import(/* webpackChunkName: "addonMentions" */ './TheMentions');
// const TheMentionsGallery = () => import(/* webpackChunkName: "addonMentions" */ './TheMentionsGallery');
import TheMentions from './TheMentions';
import TheMentionsGallery from './TheMentionsGallery';
// import TheMentionsDashboard from './TheMentionsDashboard';
import TheMentionsCustomLists from './TheMentionsCustomLists';
import TheMentionsAnalytics from './TheMentionsAnalytics';
import TheMentionsAnalyticsAccount from './TheMentionsAnalyticsAccount';
import TheMentionsAnalyticsShopify from './TheMentionsAnalyticsShopify';
import TheMentionsAnalyticsInbox from './TheMentionsAnalyticsInbox';

export default [
  {
    path: '/activities',
    // redirect: '/activities/dashboard',
    redirect: '/activities/analytics',
    name: 'Activities',
    component: TheMentions,
    meta: {
      auth: true,
      requireData: true,
    },
    children: [
      {
        path: '/activities/mentions',
        name: 'Activities Mentions',
        component: TheMentionsGallery,
        meta: {
          auth: true,
          requireData: true,
        },
      },
      // {
      //   path: '/activities/analytics',
      //   name: 'Activities Dashboard',
      //   component: TheMentionsDashboard,
      //   meta: {
      //     auth: true,
      //     requireData: true,
      //     type: 'dashbord',
      //   },
      // },
      {
        path: '/activities/analytics',
        redirect: '/activities/analytics/account',
        name: 'Activities Analytics',
        component: TheMentionsAnalytics,
        meta: {
          auth: true,
          requireData: true,
        },
        children: [
          {
            path: '/activities/analytics/account',
            name: 'Analytics Account',
            component: TheMentionsAnalyticsAccount,
            meta: {
              auth: true,
              requireData: true,
            },
          },
          {
            path: '/activities/analytics/inbox',
            name: 'Analytics Inbox',
            component: TheMentionsAnalyticsInbox,
            meta: {
              auth: true,
              requireData: true,
            },
          },
          {
            path: '/activities/analytics/shopify',
            name: 'Analytics Shopify',
            component: TheMentionsAnalyticsShopify,
            meta: {
              auth: true,
              requireData: true,
            },
          },
        ],
      },
      {
        path: '/activities/hashtags',
        name: 'Activities Hashtags',
        component: TheMentionsCustomLists,
        meta: {
          auth: true,
          requireData: true,
          type: 'dashbord',
        },
      },
    ],
  },
];
