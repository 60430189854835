<template>
  <div id="UbuDatePicker">
    <b-datepicker
      v-model="localDateRange"
      :mobile-native="false"
      :max-date="maxDate"
      :min-date="minDate"
      range
      nearby-selectable-month-days
      :unselectable-dates="hasUnselectableDays ? unselectableDays : null"
      @input="$emit('changeDate', localDateRange)"
    >
      <template v-slot:trigger>
        <UbuButton
          class="UbuMentionsFilters_button"
          center
          :active="active"
          :loading="isLoading"
          simple
          icon="calendar"
        >
          {{ dateString }}
          <UbuIcon
            class="UbuMentionsFilters_buttonIcon"
            name="expand"
          />
        </UbuButton>
      </template>

      <b-button
        v-if="hasFooter"
        class="is-geraldine is-rounded"
        label="Clear"
        @click="$emit('resetDatePicker')"
      />
    </b-datepicker>
  </div>
</template>
<script>
export default {
  name: 'UbuDatePicker',
  model: {
    prop: 'dateRange',
  },
  props: {
    dateRange: {
      type: Array,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
    hasUnselectableDays: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isAnalytics: {
      type: Boolean,
      default: false,
    },
    hasFooter: {
      type: Boolean,
      default: false,
    },
    minDate: {
      type: Date,
      default: null,
    },
  },
  data() {
    return {
      maxDate: this.$moment().toDate(),
    };
  },
  computed: {
    localDateRange: {
      get() { return this.dateRange; },
      set(value) { this.$emit('input', value); },
    },
    dateString() {
      if (!this.localDateRange.length) return 'Custom';
      const [minDate, maxDate] = this.localDateRange;
      if (this.$moment(minDate).isSame(maxDate, 'day')) return this.dateToString(minDate);
      return `${this.dateToString(minDate)} - ${this.dateToString(maxDate)}`;
    },
    unselectableDays() {
      return [this.$moment().toDate(), this.$moment().subtract({ days: 1 }).toDate()];
    },
  },
  methods: {
    dateToString(date) {
      if (this.isAnalytics) {
        return this.$moment(date).format('YYYY-MM-DD');
      }
      const today = this.$moment();
      const yesterday = this.$moment().subtract(1, 'day');
      if (this.$moment(date).isSame(today, 'day')) return 'Today';
      if (this.$moment(date).isSame(yesterday, 'day')) return 'Yesterday';
      return this.$moment(date).format('YYYY-MM-DD');
    },
  },
};
</script>

<style lang="scss">
#UbuDatePicker {
  & button.button {
    height: 30px;
    width: 60px;
  }
}
</style>
