<template>
  <div id="UbuCommentPanel">
    <a
      :href="urlRedirect"
      target="_blank"
    >
      <UbuMedia
        class="panel-image"
        :media-url="urlImg"
        :media-type="urlType"
        src-fallback="/img/expired_image.png"
        ratio="1by1"
      />
    </a>

    <div class="panel-icons">
      <template v-if="reactionFiltered.length > 0">
        <b-tooltip :label="allReaction">
          <div class="panel-icons-likes">
            <div
              v-for="(reaction, i) in reactionFiltered"
              :key="i"
              class="panel-icons-likes__reaction"
            >
              <span v-if="reaction.count > 0">
                {{ reaction.emoji }} {{ reaction.count }}
              </span>
            </div>
          </div>
        </b-tooltip>
        <div
          v-if="commentCount > 0"
          class="divider"
        />
      </template>
      <template v-if="commentCount > 0">
        <div class="panel-icons-replies">
          <span class="panel-icons-value">{{ commentCount }}</span>
          <UbuIcon
            name="replies"
            filled
          />
        </div>
      </template>
      <template v-if="shareCount > 0">
        <div class="divider" />
        <div class="panel-icons-registered">
          <span class="panel-icons-value">{{ shareCount }}</span>
          <UbuIcon
            name="share"
          />
        </div>
      </template>
      <template v-if="registerCount > 0">
        <div class="divider" />
        <div class="panel-icons-registered">
          <span class="panel-icons-value">{{ registerCount }}</span>
          <UbuIcon
            name="bookmark"
            filled
          />
        </div>
      </template>
    </div>
    <i
      v-if="dynamicParent"
      class="panel-date ubu-text1"
    >
      ⚠️ This is a dynamic post (beta) derived from
      <a
        style="color: var(--geraldine)"
        target="blank"
        :href="dynamicParent.permalink_url"
      >an ad</a>.
      Some data might not be relevant.
    </i>
    <h2
      v-if="title"
      class="panel-date ubu-text1"
    >
      {{ title }}
    </h2>
    <div
      v-if="when"
      class="panel-date ubu-text2"
    >
      Created on {{ when }}
    </div>
    <div class="panel-text ubu-text3">
      {{ caption }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'UbuCommentPanel',
  props: {
    when: {
      type: String,
      default: null,
    },
    likeCount: {
      type: Number,
      default: 0,
    },
    commentCount: {
      type: Number,
      default: 0,
    },
    shareCount: {
      type: Number,
      default: 0,
    },
    registerCount: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: '',
    },
    caption: {
      type: String,
      default: '',
    },
    urlImg: {
      type: String,
      default: '',
    },
    urlType: {
      type: String,
      default: '',
    },
    urlRedirect: {
      type: String,
      default: '',
    },
    reactions: {
      type: Array,
      default: () => [],
    },
    dynamicParent: {
      type: Object,
      default: null,
    },
  },
  computed: {
    allReaction() {
      const { reactions } = this;
      let text = '';

      for (let i = 0; i < reactions.length; i++) {
        if (reactions[i].count) {
          text += `${reactions[i].count} ${reactions[i].emoji} `;
        }
      }
      return text;
    },
    reactionFiltered() {
      const { reactions } = this;
      const filter = (reactions || [])
        .sort((a, b) => b.count - a.count)
        .slice(0, 3);
      return filter;
    },
  },
  beforeUpdate() {
    this.filterReaction();
  },
  methods: {
    filterReaction() {
    },
  },
};
</script>

<style lang="scss">
  .divider {
    width: 1.5px;
    height: 20px;
    background-color: var(--cola10);
    display: block;
    margin-right: 8px;
    margin-left: 8px;
  }
</style>
