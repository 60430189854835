/* eslint-disable no-multi-spaces */
const TheDocumentation = () => import(/* webpackChunkName: "addonCRM" */ './TheDocumentation');
const TheDocumentationTextarea = () => import(/* webpackChunkName: "addonCRM" */ './TheDocumentationTextarea');
const TheDocumentationContactPanel = () => import(/* webpackChunkName: "addonCRM" */ './TheDocumentationContactPanel');
const TheDocumentationCommentsData = () => import(/* webpackChunkName: "addonCRM" */ './TheDocumentationCommentsData');
const TheDocumentationCommonMessageList = () => import(/* webpackChunkName: "addonCRM" */ './TheDocumentationCommonMessageList');
const TheDocumentationInfluenceList = () => import(/* webpackChunkName: "addonCRM" */ './TheDocumentationInfluenceList');
const TheDocumentationDropdownSelector = () => import(/* webpackChunkName: "addonCRM" */ './TheDocumentationDropdownSelector');
// const TheInfluenceMessages = () => import(/* webpackChunkName: "addonCRM" */ './TheInfluenceMessages');
// const TheInfluenceLeads = () => import(/* webpackChunkName: "addonCRM" */ './TheInfluenceLeads');

export default [
  {
    path: '/documentation',
    name: 'Documentation',
    component: TheDocumentation,
    meta: {
      auth: true,
      requireData: true,
    },
    children: [
      {
        path: '/documentation/textarea',
        name: 'Documentation Textarea',
        component: TheDocumentationTextarea,
        meta: {
          auth: true,
          requireData: true,
        },
      },
      {
        path: '/documentation/contactPanel',
        name: 'Documentation ContactPanel',
        component: TheDocumentationContactPanel,
        meta: {
          auth: true,
          requireData: true,
        },
      },
      {
        path: '/documentation/commentData',
        name: 'Documentation CommentData',
        component: TheDocumentationCommentsData,
      },
      {
        path: '/documentation/commonMessageList',
        name: 'Documentation CommonMessageList',
        component: TheDocumentationCommonMessageList,
      },
      {
        path: '/documentation/list',
        name: 'Influence List',
        component: TheDocumentationInfluenceList,
      },
      // {
      //   path: '/documentation/dropdownSelector',
      //   name: 'Documentation dropdownSelector',
      //   component: TheDocumentationDropdownSelector,
      // },
      // {
      //   path: '/influence/:campaignId/messages',
      //   name: 'Influence Messages',
      //   component: TheInfluenceMessages,
      // },
      // {
      //   path: '/influence/:campaignId/leads',
      //   name: 'Influence Leads',
      //   component: TheInfluenceLeads,
      // },
    ],
  },
];
