<template>
  <b-tooltip
    style="max-width: 90%"
    :label="label"
    :position="'is-bottom'"
  >
    <div style="white-space: nowrap">
      <UbuIcon name="info" />
      Missing name*
    </div>
  </b-tooltip>
</template>

<script>
export default {
  name: 'UbuUndefinedUser',
  props: {
    label: {
      type: String,
      default: "We can't get data of this user because of Facebook data privacy policies.",
    },
  },
};
</script>
