import axios from 'axios';
import axiosResponseParser from './axiosResponseParser';
import routes from '../../../conf/routes';

const $axios = axios.create({
  baseURL: routes.media.url,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

const builder = (options) => $axios.request(options)
  .then(axiosResponseParser.parseResponse)
  .catch(axiosResponseParser.parseError);

export default builder;
