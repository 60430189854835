<template>
  <div class="workspace-modal">
    <div class="modal-header">
      <p class="modal-header-title ubu-title">Edit contact label</p>

      <UbuIcon
        name="cross"
        clickable
        @click="$emit('close', $event)"
      />
    </div>
    <div class="modal-content">
      <div class="modal-content-dropdown-parent">
        <UbuIcon
          class="modal-content-icon mr-2"
          :name="iconName"
          :color="localCustom.color"
          filled
          clickable
          @click="dropdownOpened = !dropdownOpened"
        />
        <div
          v-if="dropdownOpened"
          class="modal-content-dropdown"
        >
          <UbuColorPicker
            :max-per-row="3"
            :icon="iconName"
            @selectColor="selectColor($event), dropdownOpened = !dropdownOpened"
          />
        </div>
      </div>
      <div class="modal-content-input-wrapper">
        <UbuSimpleInput
          v-model="localCustom.new_title"
          class="modal-content-input pl-3"
          :focus="true"
          placeholder="Name..."
        />
      </div>
    </div>
    <div class="modal-footer">
      <UbuButton
        grow
        center
        secondary
        @click="$emit('editFromModal', localCustom)"
      >
        Save
      </UbuButton>
    </div>
  </div>
</template>

<script>
import VModelMixin from '../../$mixins/VModelMixin';

export default {
  name: 'UbuWorkspaceModalEditFolder',
  mixins: [VModelMixin],
  model: {
    prop: 'custom',
  },
  props: {
    custom: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dropdownOpened: false,
      localCustom: {
        ...this.custom,
        new_title: this.custom.title,
      },
    };
  },
  computed: {
    iconName() {
      return this.localCustom.type === 'label' ? 'contact' : this.localCustom.type;
    },
  },
  methods: {
    selectColor(event) {
      this.localCustom.color = event;
    },
  },
};
</script>
