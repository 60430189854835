<template>
  <div
    id="UbuCRMModalAddList"
    class="workspace-modal"
  >
    <div class="modal-header">
      <p class="modal-header-title ubu-title">Create new list</p>

      <UbuIcon
        name="cross"
        clickable
        @click="closeModal()"
      />
    </div>
    <div class="UbuCRMModalAddList_note">
      <h2>NOTE:</h2>
      <p class="ubu-text1">{{ noteText }}</p>
      <p class="ubu-text1">{{ noteSubText }}</p>
    </div>
    <UbuSimpleInput
      v-model="localUsernameValue"
      placeholder="Search a user..."
      searchbar
    >
      <UbuIcon
        slot="placeholderIcon"
        name="search"
      />
    </UbuSimpleInput>
    <div class="usersSearchResult">
      <template v-if="isSearching">
        <UbuInstaUserCard
          v-for="index in 5"
          :key="index"
          loading
        />
      </template>
      <template v-else>
        <UbuInstaUserCard
          v-for="(user, index) in usernameSearchResult"
          :key="index"
          :insta-user="user"
          :selected="selectedUser === user"
          :disabled="user.listAlreadyExist"
          disabled-text="This list already exist"
          @click="selectUser(user)"
        />
      </template>
    </div>
    <div class="modal-footer mt-4">
      <UbuButton
        class="mr-2"
        center
        grow
        @click="closeModal()"
      >
        Cancel
      </UbuButton>
      <UbuButton
        center
        grow
        secondary
        :disabled="!selectedUser.pk"
        @click="addFromModal()"
      >
        Create
      </UbuButton>
    </div>
  </div>
</template>

<script>

export default {
  name: 'UbuCRMModalAddList',
  model: {
    prop: 'usernameSearch',
  },
  props: {
    usernameSearch: {
      type: String,
      default: '',
    },
    usernameSearchResult: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedUser: {},
      quota: 2000,
      noteText: 'Indicate an instagram username to get leads from their followers added to your list. We will scrap the first 50k followers of this account, you will get between 500 and 1000 leads of influencers that follows this account and have more than 5000 followers! 😎',
      noteSubText: 'If you want us to scrap more of your target account let us know by chat or ask your account manager.',
    };
  },
  computed: {
    localUsernameValue: {
      get() { return this.usernameSearch; },
      set(value) { this.$emit('input', value); },
    },
    isSearching() {
      return !this.usernameSearchResult.length && this.localUsernameValue;
    },
  },
  methods: {
    selectUser(user) {
      if (user === this.selectedUser) this.selectedUser = {};
      else this.selectedUser = user;
    },
    addFromModal() {
      this.$emit('addFromModal', { username: this.selectedUser.username, pk: this.selectedUser.pk, quota: this.quota });
    },
    closeModal() {
      this.localUsernameValue = '';
      this.$emit('close');
    },
  },
};
</script>
