import builder from '../../$utils/axios';

export default class ContactPanelProvider {
  static updateContact({ channelId, ...data }) {
    const {
      label, emails, phones, notes, websites,
    } = data;
    return builder({
      method: 'post',
      url: `/channel/${channelId}/user/${data.pk}/setContact`,
      data: {
        label, emails, phones, notes, websites,
      },
    });
  }

  static generateReport({ channelId, username }) {
    console.log('🚀 ~ file: ContactPanel.provider.js ~ line 19 ~ ContactPanelProvider ~ generateReport ~ username', username);
    console.log('🚀 ~ file: ContactPanel.provider.js ~ line 19 ~ ContactPanelProvider ~ generateReport ~ channelId', channelId);
    return builder({
      method: 'get',
      url: `/channel/${channelId}/FKReport/${username}`,
    });
  }

  static hardRefresh({ channelId, contactId }) {
    console.log('🚀 ~ file: ContactPanel.provider.js ~ line 28 ~ ContactPanelProvider ~ hardRefresh ~ contactId', contactId);
    console.log('🚀 ~ file: ContactPanel.provider.js ~ line 28 ~ ContactPanelProvider ~ hardRefresh ~ channelId', channelId);
    return builder({
      method: 'get',
      url: `/channel/${channelId}/user/${contactId}/instaUserRefresh`,
    });
  }

  static getShopifyShop({ channelId }) {
    return builder({
      method: 'get',
      url: `/channel/${channelId}/shopifyShop`,
    });
  }

  static getShopifyDiscountCodes({ channelId }) {
    return builder({
      method: 'get',
      url: `/channel/${channelId}/getShopifyDiscountCodes/612cbc2b92c8f935be3ac76c`,
    });
  }

  static refreshShopifyDiscountCodes({ channelId, shopifyShopSsid }) {
    return builder({
      method: 'get',
      url: `/channel/${channelId}/refreshShopifyDiscountCodes/${shopifyShopSsid}`,
    });
  }

  static getShopifyLink({ channelId, shopifyShopSsid }) {
    return builder({
      method: 'get',
      url: `/channel/${channelId}/shopifyLink/${shopifyShopSsid}`,
    });
  }

  static getMediasByUsername({ channelId, username }) {
    return builder({
      method: 'get',
      url: `/channel/${channelId}/username/${username}/medias`,
    });
  }
}

// 612cbc2b92c8f935be3ac76c
