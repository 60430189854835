export default {
  api: {
    url: process.env.VUE_APP_BASE_URL_API,
  },
  media: {
    url: 'https://media.getubu.com',
  },
  socket: {
    // url: 'wss://socket.getubu.com',
    url: process.env.VUE_APP_BASE_URL_SOCKET,
  },
  io: {
    url: 'https://io.getubu.com',
  },
};
