import { updateDatasIfExists } from '../$utils/dataMapper';
/* eslint-disable max-len */
export default {
  namespaced: true,

  state: {
    gossips: {},
  },

  getters: {
    getterGossips: (state) => state.gossips,
  },

  actions: {},
  mutations: {
    SET_GOSSIPS(state, newDatas) { state.gossips = updateDatasIfExists({ newDatas, actualDatas: state.gossips, key: 'gossipId' }); },
    RESET_STORES(state) {
      state.gossips = {};
    },
  },
};
