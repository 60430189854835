var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['UbuButton',{
      'UbuButton_grow': _vm.grow,
      'UbuButton_disabled': _vm.disabled,
      'UbuButton_loading': _vm.loading
    } ]},[(_vm.reactions && _vm.showReactions)?_c('div',{staticClass:"UbuButton_reactions"},[(_vm.reactionsTooltip)?_c('div',{staticClass:"reactions_tooltip"},[_c('span',[_vm._v(_vm._s(_vm.reactionsTooltip))])]):_vm._e(),_c('div',{staticClass:"reactions_list"},_vm._l((_vm.reactions),function(emoji,index){return _c('div',{key:index,staticClass:"emoji",on:{"click":function($event){return _vm.$emit('react', emoji);}}},[_vm._v(" "+_vm._s(emoji)+" ")])}),0)]):_vm._e(),_c('b-tooltip',_vm._b({class:['is-flex', { 'UbuButton_grow': _vm.grow }],attrs:{"active":!!_vm.tooltip && !!_vm.tooltip.label}},'b-tooltip',_vm.tooltip,false),[_c('a',_vm._b({ref:"UbuButton",class:['UbuButton_wrapper', 'ellipsis', {
        'UbuButton_active': _vm.active,
        'UbuButton_primary': _vm.primary,
        'UbuButton_secondary': _vm.secondary,
        'UbuButton_big': _vm.big,
        'UbuButton_center': _vm.center,
        'UbuButton_grow': _vm.grow,
        'UbuButton_dashed': _vm.dashed,
        'UbuButton_simple': _vm.simple,
        'UbuButton_disabled': _vm.disabled,
        'UbuButton_loading' : _vm.loading,
        'UbuButton_note': _vm.note,
        'UbuButton_template': _vm.template,
        'UbuButton_light': _vm.light,
      },_vm.color ? ("UbuButton_color" + _vm.color): '', _vm.textColor ?
        ("UbuButton_text-color" + _vm.textColor): ''],on:{"click":_vm.onClick}},'a',_vm.$attrs,false),[(_vm.loading)?_c('div',[_c('UbuLoader',{staticClass:"UbuButton_icon",attrs:{"width":"25px","color-name":_vm.color ? ("color" + _vm.color) : null}})],1):(_vm.icon || _vm.$slots.icon)?_c('div',{class:['UbuButton_icon', {
          'ellipsis': !_vm.$slots.default,
        }],style:(_vm.$slots.default ? 'margin-right: 6px' : 'white-space: nowrap')},[_vm._t("icon",function(){return [_c('UbuIcon',_vm._b({},'UbuIcon',_vm._icon,false))]})],2):_vm._e(),_vm._t("default")],2),(_vm.$slots.dropdown && _vm.active)?_c('div',{staticClass:"UbuButton_dropdown"},[_vm._t("dropdown")],2):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }