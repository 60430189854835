<template>
  <div
    id="UbuInfluencePipelineInProgressesTable"
    :class="`pipelineTable multibar column ${tableColor}`"
  >
    <div
      class="pipelineTable_header multibar start"
      @click="$emit('toggleResumeOpen')"
    >
      <p class="start multibar row-vcentered">
        <UbuIcon
          size="20px"
          :name="tableDetail.icon || 'send'"
          :color="tableDetail.color"
          outlined
        />
      </p>
      <p class="pipelineTable_title start">
        {{ tableDetail.title }}
      </p>
      <p class="pipelineTable_counter end">
        {{ rows.length }}
      </p>
    </div>

    <div class="start pipelineTable_content">
      <div
        v-for="([day, array]) in activities"
        :key="day"
      >
        <div class="pipelineTable_dailySpliter multibar ubu-subtext1">
          <span>
            {{ day }}
          </span>
        </div>
        <div
          v-for="(row) in array"
          :key="row.username"
          class="pipelineTable_row start"
        >
          <UbuInfluencePipelineTableRow
            :row="row"
            :opened-detail-row="openedDetailRow"
            :has-detail="tableDetail.hasDetail"
            :has-mentions="false"
            :from-status="tableDetail.status"
            @toggleOpenedRow="toggleOpenedRow(row.username)"
            @changeStatus="$emit('changeStatus', $event)"
            @deleteActivity="$emit('deleteActivity', $event)"
            @showMessageSent="$emit('showMessageSent', $event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InfluenceMixin from '../$mixins/Influence.mixin';

export default {
  name: 'UbuInfluencePipelineTableDate',
  mixins: [InfluenceMixin],
  props: {
    rows: {
      type: Array,
      required: true,
    },
    campaignDetail: {
      type: Object,
      required: true,
    },
    tableDetail: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      openedDetailRow: '',
    };
  },
  methods: {
  },
};
</script>
