export default {
  find: ({
    // model,
    relationTable,
    dataTable,
    fromId,
    toId,
  }) => {
    const relations = relationTable
      .filter((relate) => `${relate[fromId.key]}` === `${fromId.value}`);
    const responses = relations.map((r) => dataTable[r[toId.key]]);

    // //console.log('🚀 ~ file: Influence.store.js ~ line 70 ~ relations', relations);
    // //console.log('🚀 ~ file: Influence.store.js ~ line 70 ~ campaignsAndActivities', model, campaignsAndActivities);
    // if (model === 'contactGroups') {
    //   //console.log('🚀 ~ file: Influence.store.js ~ line 70 ~ relations', relations);
    //   //console.log('🚀 ~ file: Influence.store.js ~ line 70 ~ response', model, responses);
    // }
    return responses;
  },
  findObject: ({
    // model,
    relationTable,
    dataTable,
    fromId,
    // toId,
  }) => {
    // console.log('🚀 ~ file: query.js ~ line 27 ~ fromId', fromId);
    // console.log('🚀 ~ file: query.js ~ line 27 ~ dataTable', dataTable);
    // console.log('🚀 ~ file: query.js ~ line 27 ~ relationTable', relationTable);
    const values = relationTable[fromId.value] || [];
    const responses = values.map((id) => dataTable[id]);
    // console.log('🚀 ~ file: query.js ~ line 28 ~ responses', responses);
    return responses;
  },
  findObjectLite: ({ relationTable, dataTable, fromId }) => (relationTable[fromId] || []).map((id) => dataTable[id]),
  findOne: ({
    // model,
    relationTable,
    dataTable,
    fromId,
    toId,
  }) => {
    const relations = relationTable
      .filter((relate) => `${relate[fromId.key]}` === `${fromId.value}`);
    const [response] = relations.map((r) => dataTable[r[toId.key]]);
    // if (model === 'contactGroups') {
    //   //console.log('🚀 ~ file: Influence.store.js ~ line 70 ~ relations', relations);
    //   //console.log('🚀 ~ file: Influence.store.js ~ line 70 ~ response', model, response);
    // }
    return response;
  },
  getters: (rootGetters) => {
    const {
      'Channel/getterChannels': channels,
      'Channel/getterChannelsHasCampaigns': channelsHasCampaigns,
      'Channel/getterChannelsHasContacts': channelsHasContacts,
      'Channel/getterChannelsHasCustoms': channelsHasCustoms,
      'Channel/getterChannelsHasThreads': channelsHasThreads,
      'Channel/getterChannelsHasUbuUsers': channelsHasUbuUsers,

      'Campaign/getterCampaigns': campaigns,

      'Campaign/getterCampaignsHasActivitiesData': campaignsHasActivities,

      'Activity/getterActivities': activities,

      'Contact/getterContacts': contacts,
      'Contact/getterContactsHasActivities': contactsHasActivities,
      'Contact/getterContactsHasTags': contactsHasTags,
      'Contact/getterContactsHasMentions': contactsHasMentions,
      'Contact/getterContactsHasContactGroup': contactsHasContactGroup,
      'CustomTag/getterCustomTags': customTag,
      'Contact/getterContactsHasMessages': contactsHasMessages,
      'Contact/getterContactsHasNotes': contactsHasNotes,
      'Contact/getterContactsHasGossips': contactsHasGossips,

      'ContactGroup/getterContactGroups': contactGroups,

      'TagPost/gettertagsPosts': tagsPost,

      'Mention/getterMentions': mentions,
      'Media/getterMedias': medias,

      'Message/getterMessages': messages,
      'Gossip/getterGossips': gossips,

      'Note/getterNotes': notes,

      'Thread/getterThreads': threads,
      'Thread/getterThreadsHasContacts': threadsHasContacts,
      'Thread/getterThreadsHasMessages': threadsHasMessages,
      'Thread/getterThreadsHasNotes': threadsHasNotes,
      'Thread/getterThreadsHasThreadGroup': threadsHasThreadGroup,
      'Thread/getterThreadsHasMedias': threadsHasMedias,

      'ThreadGroup/getterThreadGroups': threadGroups,
      'UbuUser/getterUbuUsers': ubuUsers,
    } = rootGetters;

    return {
      activities,
      campaigns,
      campaignsHasActivities,
      channels,
      channelsHasCampaigns,
      channelsHasContacts,
      channelsHasCustoms,
      channelsHasThreads,
      channelsHasUbuUsers,
      contacts,
      contactsHasActivities,
      contactsHasTags,
      contactsHasMentions,
      contactsHasContactGroup,
      customTag,
      contactsHasMessages,
      contactsHasNotes,
      contactsHasGossips,
      contactGroups,
      mentions,
      tagsPost,
      messages,
      gossips,
      notes,
      threads,
      threadsHasContacts,
      threadsHasMessages,
      threadsHasNotes,
      threadsHasThreadGroup,
      threadGroups,
      ubuUsers,
      medias,
      threadsHasMedias,
    };
  },
};
