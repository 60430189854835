<template>
  <section id="UbuInfluenceMessage">
    <section id="UbuInfluenceMessageLeftColumn">
      <div v-if="bulkList.length">
        <div class="bulk buttons pt-2">
          <b-button
            v-if="bulkList.length < filteredThreadList.length"
            class="is-geraldine is-rounded"
            @click="addAllToBulk()"
          >
            Select All
          </b-button>

          <b-button
            v-else
            class="is-geraldine is-rounded"
            @click="clearBulk()"
          >
            Unselect All
          </b-button>

          <b-button
            class="is-light is-rounded"
            @click="clearBulk()"
          >
            Cancel
          </b-button>
        </div>

        <div class="bulk-actions">
          <UbuSelector
            :values="threadGroupItems(getIntersection(bulkList, 'threadGroups'))"
            :choices="threadGroupItems(threadGroupsWithThreads)"
            :paths="{ id: 'threadGroupId', label: 'title' }"
            label-icon="tag"
            :tooltip="{
              label: `Tag ${bulkList.length} conversation${bulkList.length !== 1 ? 's' : ''}`,
              position: 'is-bottom'
            }"
            search-placeholder="Create or search tags..."
            create-placeholder="Create new tag:"
            @select="assignTagToBulk($event)"
            @remove="removeTagToBulk($event)"
            @create="createAndAssignTagToBulk($event)"
          />
          <UbuSelector
            :values="contactGroupItems(getIntersection(bulkList, 'contactGroups'))"
            :choices="contactGroupItems(contactGroupsWithThreads)"
            :paths="{ id: 'contactGroupId', label: 'title' }"
            label-icon="contact"
            :tooltip="{
              label: `Add ${bulkList.length} as contact${bulkList.length !== 1 ? 's' : ''}`,
              position: 'is-bottom'
            }"
            search-placeholder="Create or search contacts..."
            create-placeholder="Create new contact:"
            @select="assignLabelToBulk($event)"
            @remove="removeLabelToBulk($event)"
            @create="createAndAssignLabelToBulk($event)"
          />
          <b-dropdown>
            <template #trigger>
              <b-button
                label="Status"
                class="is-light is-rounded"
              />
            </template>

            <b-dropdown-item
              v-for="(status, index) in statusList"
              :key="index"
              @click="bulkSetStatus(status)"
            >
              <UbuIcon :name="status.icon" /> {{ status.name }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>

      <div
        v-else
        id="UbuInfluenceMessageThreadListFilters"
      >
        <b-field>
          <b-dropdown
            v-model="selectedStatus"
            aria-role="list"
            expanded
            class="ubuDropdown"
          >
            <template #trigger="{ active }">
              <b-button
                class="is-rounded is-light"
                :icon-right="active ? 'menu-up' : 'menu-down'"
                expanded
              >
                {{ selectedStatus }}
              </b-button>
            </template>

            <b-dropdown-item
              v-for="(item, index) in statusListComplete"
              :key="index"
              aria-role="listitem"
              :value="item.name"
            >
              <div class="media">
                <UbuIcon
                  size="16px"
                  :name="item.icon"
                  outlined
                  class="media-left"
                />
                <div class="media-content multibar row-vcentered">
                  <span class="start">
                    {{ item.name }}
                  </span>
                  <b-icon
                    icon="circle"
                    size="is-small"
                    class="end"
                  />
                  <span class="end">
                    {{ threadsCount(item.name) }}
                  </span>
                </div>
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </b-field>

        <b-field grouped>
          <UbuSimpleInput
            v-model="searchThread"
            placeholder="Search..."
            searchbar
            class="mr-2"
          >
            <UbuIcon
              slot="placeholderIcon"
              name="search"
            />
          </UbuSimpleInput>
          <UbuButton
            icon="bulk"
            :tooltip="{ label: 'Bulk', position: 'is-bottom' }"
            @click="selectFirstItemToBulk()"
          />
          <!-- <UbuButton
            icon="compose"
            :tooltip="{ label: 'New message', position: 'is-bottom' }"
            @click="isCardModalActive = !isCardModalActive"
          /> -->
        </b-field>
      </div>

      <!-- <b-modal
        v-model="isCardModalActive"
        :width="640"
        scroll="keep"
      >
        <UbuInboxModalCreateThread
          v-model="usernameValue"
          :user-fetched="userFetched"
          :is-fetching="isFetching"
          :template-list="templateList"
          @createThread="createThread($event)"
          @close="closeSendDmModal()"
        />
      </b-modal> -->

      <UbuInfluenceMessageThreadList
        id="UbuInfluenceMessageThreadList"
        :fetch-done="fetchDone"
        :thread-list="filteredThreadList"
        :selected-thread="getterCurrentThread"
        :bulk-list="bulkList"
        @onSelectThread="UP_currentThread($event)"
        @onBulkSelect="onBulkSelect($event)"
      />
    </section>

    <TheThreadPanel
      v-if="!!selectedThread"
      :key="selectedThread.threadId"
      :selected-thread="selectedThread"
      :status-list="statusList"
      @openModalAddLink="$emit('openModalAddLink', $event)"
    />
    <TheContactPanel
      v-if="!!selectedThread && selectedThread.contactId"
      :contact-id="selectedThread.contactId"
      @openModalAddLink="$emit('openModalAddLink', $event)"
    />
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CommonMixin from '../$mixins/CommonMixin';
import ThreadListMixin from '../inbox/$mixins/ThreadListMixin';
import { debounce } from '../$utils/helpers';
import { snackError, snackSuccess } from '../$catalog/snackbar';

export default {
  name: 'TheInfluenceMessages',
  mixins: [CommonMixin, ThreadListMixin],
  provide: {
    statusList: [
      { name: 'success', icon: 'success' },
      { name: 'failure', icon: 'failure' },
      { name: 'delivered', icon: 'send' },
      { name: 'talking', icon: 'comments' },
      { name: 'inProgress', icon: 'pin' },
    ],
  },
  data() {
    return {
      statusList: [
        { name: 'success', icon: 'success' },
        { name: 'failure', icon: 'failure' },
        { name: 'delivered', icon: 'send' },
        { name: 'talking', icon: 'comments' },
        { name: 'inProgress', icon: 'pin' },
      ],
      limitedNameStatusList: [
        'success',
        'failure',
        'delivered',
        'talking',
        'inProgress',
      ],
      statusListComplete: [
        { name: 'all messages', icon: 'messages' },
        { name: 'success', icon: 'success' },
        { name: 'failure', icon: 'failure' },
        { name: 'delivered', icon: 'send' },
        { name: 'talking', icon: 'comments' },
        { name: 'inProgress', icon: 'pin' },
        { name: 'unread', icon: 'pin' },
        { name: 'mentions', icon: 'pin' },
      ],
      selectedStatus: 'all messages',
      searchThread: '',
      bulkList: [],
      // isCardModalActive: false,
      // isFetching: false,
      // usernameValue: '',
      // userFetched: [],
    };
  },
  computed: {
    ...mapGetters({
      currentChannelId: 'Channel/getterCurrentChannelId',
      getterCurrentThreadId: 'TheInfluence/getterCurrentThreadId',
      getterCurrentThread: 'TheInfluence/getterCurrentThread',
      getterCampaignThreads: 'TheInfluence/getterCampaignThreads',
      getterCurrentThreadsDatas: 'TheInfluence/getterCurrentThreadsDatas',
      fetchDone: 'Sequence/getterFetchDone',
      threadGroupsWithThreads: 'TheInbox/getterThreadGroupsWithThreads',
      contactGroupsWithThreads: 'TheInbox/getterContactGroupsWithThreads',
      templateList: 'TheWorkspace/getterTemplates',
    }),
    threadList() {
      if (!this.getterCampaignThreads.threads) {
        return [];
      }

      return [...this.getterCampaignThreads.threads]
        // remove thread without actitivity
        // .filter(({ activity }) => activity)
        // remove thread without actitivity in this campaign
        // .filter(({ activity }) => activity.campaignId === this.$route.params.campaignId)
        .filter(({ activity }) => activity.status !== 'unscheduled' && activity.status !== 'scheduled' && !activity.ended)
        // .filter(({ activity }) => !activity.ended)
        .sort((a, b) => b.last_permanent_item.timestamp - a.last_permanent_item.timestamp)
        .sort((a, b) => a.isUnread < b.isUnread);
    },
    filteredThreadList() {
      return this.threadList
        .filter(({ activity }) => (this.selectedStatus !== 'all messages' && this.limitedNameStatusList.includes(this.selectedStatus) ? activity.status === this.selectedStatus : activity))
        .filter((row) => (this.selectedStatus === 'unread' ? row.isUnread : row))
        .filter((row) => (this.selectedStatus === 'mentions' ? row.mentions.filter((ts) => ts > row.activity.addedOn).length > 0 : row))
        .filter(({ contact }) => (this.searchThread !== '' ? contact.username.toLowerCase().includes(this.searchThread) || contact.full_name.toLowerCase().includes(this.searchThread) : contact));
    },
    selectedThread() {
      if (!this.getterCampaignThreads.threads) return {};
      return this.getterCurrentThreadsDatas || null;
    },
  },
  // watch: {
  //   // eslint-disable-next-line func-names
  //   usernameValue: debounce(function (newVal) {
  //     this.isFetching = true;
  //     this.userFetched = [];
  //     if (newVal === '') return;
  //     this._searchUser(
  //       { channelId: this.currentChannelId, username: newVal },
  //     )
  //       .then((response) => {
  //         this.userFetched = response;
  //         this.isFetching = false;
  //       });
  //   }, 700),
  // },
  mounted() {
    this.UP_currentThread({ threadId: '' });
    this.UP_currentCampaignId(this.$route.params.campaignId);
  },
  methods: {
    ...mapActions({
      UP_currentThread: 'TheInfluence/UP_currentThread',
      UP_currentCampaignId: 'TheInfluence/UP_currentCampaignId',
      setManyStatus: 'TheInfluence/setManyStatus',
      _searchUser: 'TheCRM/searchUser',
      sendMessage: 'TheInbox/sendMessage',

      _assignThreadsToThreadGroup: 'TheThreadList/assignThreadsToThreadGroup',
      _removeThreadsFromThreadGroup: 'TheThreadList/removeThreadsFromThreadGroup',
      _createThreadGroupAndAssignMany: 'TheThreadList/createThreadGroupAndAssignMany',

      _assignContactsToContactGroup: 'TheThreadList/assignContactsToContactGroup',
      _removeContactsFromContactGroup: 'TheThreadList/removeContactsFromContactGroup',
      _createContactGroupAndAssignMany: 'TheThreadList/createContactGroupAndAssignMany',
    }),
    threadsCount(status) {
      return this.threadList.filter((row) => {
        if (status === 'all messages') return row;
        if (status === 'mentions') return row.mentions.filter((ts) => ts > row.activity.addedOn).length > 0;
        if (status === 'unread') return row.isUnread;
        return (status !== 'all messages' ? row.activity.status === status : row.activity);
      }).length;
    },
    selectFirstItemToBulk() {
      if (this.selectedThread) this.onBulkSelect(this.selectedThread);
      else if (this.filteredThreadList.length) {
        this.onBulkSelect(this.filteredThreadList[0]);
      }
    },
    onBulkSelect(event) {
      const idx = this.bulkList.findIndex((b) => b.threadId === event.threadId);
      if (idx > -1) this.bulkList = [...this.bulkList.filter((item, _idx) => idx !== _idx)];
      else this.bulkList = [event, ...this.bulkList];
    },
    addAllToBulk() {
      this.bulkList = [...this.filteredThreadList];
    },
    clearBulk() {
      this.bulkList = [];
    },
    bulkSetStatus(status) {
      const { name } = status;
      const activities = this.bulkList.map(({ activity }) => ({ ...activity, status: name }));
      const threadIdsIntoBulk = this.bulkList.map(({ threadId }) => threadId);

      this.setManyStatus({
        campaignId: this.$route.params.campaignId,
        activities,
        channelId: this.currentChannelId,
      })
        .then(({ response }) => {
          if (response) {
            this.$buefy.snackbar.open({
              ...snackSuccess,
              message: `${response.length} contact(s) have been assigned to status ${name}`,
            });
            this.bulkList = [...this.threadList
              .filter(({ threadId }) => threadIdsIntoBulk.includes(threadId))];
          } else {
            this.$buefy.snackbar.open({
              ...snackError,
              message: 'Error during status change',
              onAction: () => this.bulkSetStatus(status),
            });
          }
        });
    },
    createThread(event) {
      const { user: { pk, username }, message } = event;
      const messageParsed = message.replace('{name}', username);

      const snackToWait = this.$buefy.snackbar.open({
        ...snackSuccess,
        message: 'Sending message...',
      });

      this.sendMessage({
        channelId: this.currentChannelId,
        contactId: pk,
        message: messageParsed,
      })
        .then(({ error }) => {
          snackToWait.close();
          if (error) {
            this.$buefy.snackbar.open({
              ...snackError,
              message: 'Error while sending',
              actionText: 'Retry',
              onAction: () => this.createThread(event),
            });
          } else {
            this.$buefy.snackbar.open({
              ...snackSuccess,
              message: 'Message was sent successfully',
            });
            this.closeSendDmModal();
          }
        });
    },
    // closeSendDmModal() {
    //   this.usernameValue = '';
    //   this.isCardModalActive = false;
    //   this.isFetching = false;
    // },
    assignTagToBulk(event) {
      const threadIdsIntoBulk = this.bulkList.map(({ threadId }) => threadId);
      this._assignThreadsToThreadGroup({ threads: this.bulkList, threadGroup: event })
        .then(({ error, count, title }) => {
          if (error) {
            this.$buefy.snackbar.open({
              ...snackError,
              message: `Error while assigning "${count}" threads to tag "${title}"`,
              onAction: () => this.assignTagToBulk(event),
            });
          } else {
            this.$buefy.snackbar.open({
              ...snackSuccess,
              message: `${count} threads has been assigned to tag "${title}"`,
            });
            // replace item into bulk by updated item
            this.bulkList = [...this.threadList
              .filter(({ threadId }) => threadIdsIntoBulk.includes(threadId))];
          }
        });
    },
    removeTagToBulk(event) {
      const threadIdsIntoBulk = this.bulkList.map(({ threadId }) => threadId);
      this._removeThreadsFromThreadGroup({ threads: this.bulkList, threadGroup: event })
        .then(({ error, count, title }) => {
          if (error) {
            this.$buefy.snackbar.open({
              ...snackError,
              message: `Error while removing "${count}" threads from tag "${title}"`,
              onAction: () => this.removeThreadsFromThreadGroup(event),
            });
          } else {
            this.$buefy.snackbar.open({
              ...snackSuccess,
              message: `${count} threads has been removed from tag "${title}"`,
            });
            // replace item into bulk by updated item
            this.bulkList = [...this.threadList
              .filter(({ threadId }) => threadIdsIntoBulk.includes(threadId))];
          }
        });
    },
    createAndAssignTagToBulk(event) {
      const threadIdsIntoBulk = this.bulkList.map(({ threadId }) => threadId);
      this._createThreadGroupAndAssignMany({ threads: this.bulkList, ...event })
        .then(({ error, count, title }) => {
          if (error) {
            this.$buefy.snackbar.open({
              ...snackError,
              message: `Error while creating "${title}"`,
              onAction: () => this.createThreadGroupAndAssignMany(event),
            });
          } else {
            this.$buefy.snackbar.open({
              ...snackSuccess,
              message: `"${title}" has been created and ${count} threads has been assigned`,
            });
            // replace item into bulk by updated item
            this.bulkList = [...this.threadList
              .filter(({ threadId }) => threadIdsIntoBulk.includes(threadId))];
          }
        });
    },
    assignLabelToBulk(event) {
      const threadIdsIntoBulk = this.bulkList.map(({ threadId }) => threadId);
      this._assignContactsToContactGroup({ threads: this.bulkList, contactGroup: event })
        .then(({ error, count, title }) => {
          if (error) {
            this.$buefy.snackbar.open({
              ...snackError,
              message: `Error while assigning "${count}" contacts to folder "${title}"`,
              onAction: () => this.assignContactsToContactGroup(event),
            });
          } else {
            this.$buefy.snackbar.open({
              ...snackSuccess,
              message: `${count} contacts has been assigned to "${title}"`,
            });
            // replace item into bulk by updated item
            this.bulkList = [...this.threadList
              .filter(({ threadId }) => threadIdsIntoBulk.includes(threadId))];
          }
        });
    },
    removeLabelToBulk(event) {
      const threadIdsIntoBulk = this.bulkList.map(({ threadId }) => threadId);
      this._removeContactsFromContactGroup({ threads: this.bulkList, contactGroup: event })
        .then(({ error, count, title }) => {
          if (error) {
            this.$buefy.snackbar.open({
              ...snackError,
              message: `Error while removing "${count}" contacts from folder "${title}"`,
              onAction: () => this.removeContactsFromContactGroup(event),
            });
          } else {
            this.$buefy.snackbar.open({
              ...snackSuccess,
              message: `${count} contacts has been assigned to "${title}"`,
            });
            // replace item into bulk by updated item
            this.bulkList = [...this.threadList
              .filter(({ threadId }) => threadIdsIntoBulk.includes(threadId))];
          }
        });
    },
    createAndAssignLabelToBulk(event) {
      const threadIdsIntoBulk = this.bulkList.map(({ threadId }) => threadId);
      this._createContactGroupAndAssignMany({ threads: this.bulkList, ...event })
        .then(({ error, count, title }) => {
          if (error) {
            this.$buefy.snackbar.open({
              ...snackError,
              message: `Error while creating contact folder "${title}"`,
              onAction: () => this.createContactGroupAndAssignMany(event),
            });
          } else {
            this.$buefy.snackbar.open({
              ...snackSuccess,
              message: `Folder "${title}" has been created and ${count} contacts has been assigned`,
            });
            // replace item into bulk by updated item
            this.bulkList = [...this.threadList
              .filter(({ threadId }) => threadIdsIntoBulk.includes(threadId))];
          }
        });
    },
  },
};
</script>

<style lang="scss">
#UbuInfluenceMessage {
  height: calc(100vh - 75px);
  max-height: calc(100vh - 75px);
  display: flex;
  & #UbuInfluenceMessageLeftColumn {
    background-color: var(--soapstone);
    border-right: 1px solid var(--mercury);
    width: 320px;
    max-width: 320px;
    & .bulk.buttons {
      justify-content: space-around;
    }
    & .bulk-actions {
      display: flex;
      flex-grow: 1;
      max-width: 100%;
      justify-content: space-around;

      > * {
        width: calc(33% - 10px);
      }
      > *:not(:last-child) {
        // margin-right: 12px;
      }
    }
  }
  & #TheThreadPanel {
    & .UbuThreadMessage_scroller {
      max-height: calc(100vh - 127px);
    }
  }
  & #UbuInfluenceMessageThreadListFilters {
    height: 100px;
    padding: 8px 16px;

    & .dropdown {
      & .media-left {
        color: var(--cloudburst);
      }
      & .dropdown-content {
        background-color: var(--soapstone);
      }
      & .media-content .icon {
        font-size: 4px;
      }
      & .dropdown-item:hover {
        background-color: var(--peachcream);
      }
    }

    & .ubuInput .icon {
      align-items: baseline;
    }
  }
  & #UbuInfluenceMessageThreadList {
    width: 320px;
    max-width: 320px;
    max-height: calc(100vh - 175px);
    padding: 12px 8px;
    overflow-y: auto;
    flex-grow: 1;
  }
}
</style>
