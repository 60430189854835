<template>
  <div id="UbuMentionsFilters">
    <div
      v-if="!hideDateFilter"
      class="UbuMentionsFilters_mainActions"
    >
      <UbuDatePicker
        v-model="dateRange"
        :active="dateFilter.type == 'custom'"
        :is-loading="isLoading"
        @changeDate="changeDate({type: 'custom', value: dateRange})"
      />
      <UbuButton
        class="UbuMentionsFilters_button"
        center
        :active="dateFilter.value == 7"
        :loading="isLoading"
        simple
        @click="changeDate({type: 'simple', value: 7})"
      >
        Last 7 days
      </UbuButton>
    </div>
    <div class="UbuMentionsFilters_mainActions">
      <UbuButton
        class="mr-2"
        :active="showStories && !tableView"
        simple
        @click="$emit('toggleStories', $event)"
      >
        Stories
      </UbuButton>
      <UbuButton
        class="mr-2"
        :active="showPosts && !tableView"
        simple
        @click="$emit('togglePosts', $event)"
      >
        Posts
      </UbuButton>
      <UbuButton
        :active="tableView"
        center
        simple
        class="UbuMentionsFilters_button"
        @click="$emit('toggleTableView', true)"
      >
        Influencers view
      </UbuButton>
      <UbuButton
        v-if="showTikTok !== null"
        class="mr-2"
        :active="showTikTok"
        simple
        @click="$emit('toggleTikTok', $event)"
      >
        TikTok
      </UbuButton>
    </div>
    <div class="UbuMentionsFilters_searchFilters">
      <UbuSimpleInput
        v-model="localSearchValue"
        class="mr-2"
        placeholder="Search by username or caption..."
        searchbar
        :value="localSearchValue"
      >
        <UbuIcon
          slot="placeholderIcon"
          name="search"
        />
      </UbuSimpleInput>
      <UbuButton
        v-if="showTikTok !== true"
        class="list-button"
        :icon="{ name: 'filter' }"
        :active="showFilters"
        @click="showFilters = !showFilters"
      />
      <UbuButton
        v-if="showTikTok"
        class="list-button ml-2"
        :active="showTikTok"
        light
        @click="$emit('openTikTokSettings', $event)"
      >
        Add TikTok Influencers
      </UbuButton>
    </div>
    <div
      v-if="showFilters"
      class="list-filters"
    >
      <h1 class="ubu-header1">Filters</h1>
      <div class="button-group">
        <UbuRangeSelector
          v-for="item in rangeFilters"
          :key="item.id"
          :button-title="item.buttonTitle"
          :modal-title="item.modalTitle"
          :range="item.range"
          :ticks="item.ticks"
          @setRangeFilter="$emit('setRangeFilter', { name: item.name, ...$event })"
        />
        <UbuNumberInputRangeSelector
          button-title="+ Engagement"
          modal-title="Engagement rate"
          :range="{ min: 0, max: 100 }"
          @setNumberInputFilter="$emit('setEngagementFilter', $event)"
        />
        <UbuCheckboxSelector
          v-for="(item, index) in checkBoxFilters"
          :key="index"
          :button-title="item.buttonTitle"
          :modal-title="item.modalTitle"
          :checkbox-list="item.value"
          :default-option="item.defaultOption ? item.defaultOption : {}"
          @setCheckFilter="$emit('setCheckFilter', { name: item.name, ...$event})"
        />
      </div>
    </div>
    <div
      v-if="checkedRows.length"
      class="list-filters"
    >
      <h1 class="ubu-header1">{{ checkedRows.length }} selected</h1>
      <div class="button-group">
        <b-dropdown
          aria-role="list"
          class="crm-dropdown-content mr-1"
        >
          <template #trigger="{ active }">
            <b-button
              class="is-light is-rounded"
              :icon-right="active ? 'menu-up' : 'menu-down'"
            >
              <UbuIcon
                name="influence"
                :filled="true"
              />
              Add to campaign
            </b-button>
          </template>

          <b-dropdown-item
            v-for="(campaign, index) in campaignList"
            :key="index"
            aria-role="listitem"
            class="listitem"
            @click="$emit('selectCampaign', campaign)"
          >
            {{ campaign.title }} <UbuIcon name="add" />
          </b-dropdown-item>
        </b-dropdown>

        <UbuSelector
          class="mr-2"
          reduced
          :choices="contactGroups"
          :paths="{ id: 'contactGroupId', label: 'title' }"
          label-icon="contact"
          :tooltip="{
            label: `Add ${checkedRows.length} as contact${checkedRows.length !== 1 ? 's' : ''}`,
            position: 'is-bottom'
          }"
          search-placeholder="Create or search contacts..."
          create-placeholder="Create new contact:"
          @select="$emit('assignContactsToContactGroup', {
            threads: checkedRows, contactGroup: $event })"
          @create="$emit('createContactGroupAndAssignMany', { threads: checkedRows, ...$event})"
        />

        <UbuButton
          class="list-button"
          primary
          :icon="{ name: 'failure', filled: true }"
          @click="$emit('clearCheckedRow', $event)"
        >
          Cancel
        </UbuButton>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UbuMentionsFilters',
  model: {
    prop: 'searchValue',
  },
  props: {
    searchValue: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    tableView: {
      type: Boolean,
      default: false,
    },
    showStories: {
      type: Boolean,
      default: false,
    },
    showPosts: {
      type: Boolean,
      default: false,
    },
    showTikTok: {
      type: Boolean,
      default: null,
    },
    checkBoxFilters: {
      type: Array,
      default: () => [],
    },
    checkedRows: {
      type: Array,
      default: () => [],
    },
    campaignList: {
      type: Array,
      default: () => [],
    },
    hideDateFilter: {
      type: Boolean,
      default: false,
    },
    contactGroups: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      search: '',
      dateRange: [this.$moment().toDate(), this.$moment().toDate()],
      dateFilter: { type: 'custom', value: this.dateRange },
      showFilters: false,
      rangeFilters: [
        {
          id: 100,
          name: 'followerRange',
          buttonTitle: '+ Followers',
          modalTitle: 'Followers',
          range: [0, 5],
          ticks: [
            { text: '0', value: 0 },
            { text: '3k', value: 3000 },
            { text: '10k', value: 10000 },
            { text: '50k', value: 50000 },
            { text: '200k', value: 200000 },
            { text: 'No limit', value: Infinity },
          ],
        },
        {
          id: 400,
          name: 'averageReachRange',
          buttonTitle: '+ Average Reach',
          modalTitle: 'Average Reach',
          range: [0, 5],
          ticks: [
            { text: '0', value: 0 },
            { text: '3k', value: 3000 },
            { text: '10k', value: 10000 },
            { text: '50k', value: 50000 },
            { text: '200k', value: 200000 },
            { text: 'No limit', value: Infinity },
          ],
        },
        {
          id: 500,
          name: 'followersEarnedRange',
          buttonTitle: '+ Followers Earned',
          modalTitle: 'Followers Earned',
          range: [0, 6],
          ticks: [
            { text: '0', value: 0 },
            { text: '1', value: 1 },
            { text: '5', value: 5 },
            { text: '10', value: 10 },
            { text: '50', value: 50 },
            { text: '100', value: 100 },
            { text: 'No limit', value: Infinity },
          ],
        },
      ],
    };
  },
  computed: {
    localSearchValue: {
      get() { return this.searchValue; },
      set(value) { this.$emit('input', value); },
    },
  },
  methods: {
    changeDate(dateFilter) {
      if (!this.disabled) {
        this.dateFilter = dateFilter;
        console.log('🚀 ~ file: UbuMentionsFilters.vue ~ line 70 ~ changeDate ~ dateFilter', dateFilter);
        this.$emit('changeDate', this.dateFilter);
      }
    },
  },
};
</script>
