<template>
  <div
    class="workspace-modal"
  >
    <div class="modal-header">
      <p class="modal-header-title ubu-title">New message</p>

      <UbuIcon
        name="cross"
        clickable
        @click="$emit('close', $event)"
      />
    </div>
    <template>
      <UbuTemplateTextarea
        v-model="messageText"
        class="mt-4"
        :show-controls="true"
        :template-list="templateList"
      />
    </template>
    <div class="modal-footer mt-4 buttons is-right">
      <b-button
        label="Cancel"
        class="is-light is-rounded"
        @click="$emit('close', $event)"
      />
      <b-button
        label="Send message"
        :disabled="!messageText.length"
        class="is-geraldine is-rounded"
        @click="$emit('sendNewMessage', messageText)"
      />
    </div>
  </div>
</template>

<script>

export default {
  name: 'UbuCommonNewMessageModal',
  props: {
    templateList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      messageText: '',
    };
  },
  computed: {
  },
  methods: {
  },
};
</script>
