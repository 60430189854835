var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"UbuCRMTable"}},[_c('b-table',{staticClass:"list-table",attrs:{"data":_vm.listFiltered,"checked-rows":_vm.checkedRows,"selected":_vm.selectedRow,"default-sort":['username', 'asc'],"loading":_vm.loading,"checkable":"","paginated":"","sticky-header":true,"opened-detailed":_vm.openedDetails,"detailed":true,"show-detail-icon":true,"detail-key":"contactId","per-page":"30","pagination-rounded":"","hoverable":"","pagination-size":"is-small"},on:{"check":function($event){return _vm.$emit('checkRow', $event)},"check-all":function($event){return _vm.$emit('checkAllRows', $event)},"click":function($event){return _vm.selectRow($event)}},scopedSlots:_vm._u([{key:"detail",fn:function(ref){
var row = ref.row;
return [_c('UbuCRMTableDetail',{attrs:{"row":row,"thread-groups-list":_vm.threadGroupsList,"contact-groups-list":_vm.contactGroupsList,"active-campaign-list":_vm.activeCampaignList,"is-generate-report-loading":_vm.isGenerateReportLoading,"all-referral-concat-discount":_vm.allReferralConcatDiscount,"has-shopify-shop":_vm.hasShopifyShop,"is-statistics-loading":_vm.isStatisticsLoading,"show-new-message-button":""},on:{"addCustom":function($event){return _vm.$emit('addCustom', $event)},"removeCustom":function($event){return _vm.$emit('removeCustom', $event)},"generateReport":function($event){return _vm.$emit('generateReport', row)},"updateContact":function($event){return _vm.$emit('updateContact', $event)},"createThreadGroupAndAssign":function($event){return _vm.$emit('createThreadGroupAndAssign', Object.assign({}, {thread: row.thread}, $event, {username: row.username}))},"createContactGroupAndAssign":function($event){return _vm.$emit('createContactGroupAndAssign',Object.assign({}, {thread: {
            contactId: row.contactId, channelId: row.channelId
          }}, $event, {username: row.username}))},"openModalAddLink":function($event){return _vm.$emit('openModalAddLink', $event)},"assignCampaignToLink":function($event){return _vm.$emit('assignCampaignToLink', $event)},"removeContactToLink":function($event){return _vm.$emit('removeContactToLink', $event)},"openNewMessage":function($event){return _vm.$emit('openNewMessage', $event)},"hardRefresh":function($event){return _vm.$emit('hardRefresh', $event)},"fetchTiktokUser":function($event){return _vm.$emit('fetchTiktokUser', $event)},"openModalEditUsernameTiktok":function($event){return _vm.$emit('openModalEditUsernameTiktok', $event)}}})]}},{key:"empty",fn:function(){return [(_vm.isCustomList && !_vm.loading)?_c('div',{staticClass:"emptyList"},[_c('h1',[_vm._v("It can take up to 24h to get leads into your list ⏳")]),_c('h2',[_vm._v("Then new leads will be automatically added to your list everyday!")])]):_vm._e()]},proxy:true}])},[_c('b-table-column',{attrs:{"visible":!!_vm.columns.find(function (name) { return name === 'username'; }),"field":"username","label":"Username","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('div',{staticClass:"username-table"},[_vm._v(" "+_vm._s(row.username || '-')+" ")])]}}])}),_c('b-table-column',{attrs:{"visible":!!_vm.columns.find(function (name) { return name === 'categoryName'; }),"field":"type","label":"Type","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var ref_row = ref.row;
          var type = ref_row.type;
          var followerCount = ref_row.followerCount;
return [(followerCount >= 5000)?_c('div',[_vm._v(_vm._s(type || '-'))]):_c('div',[(type)?_c('div',[_vm._v(_vm._s(type)+" ")]):_c('b-tooltip',{attrs:{"label":_vm.missingTypeTooltipLabel,"position":"is-right"}},[_c('div',[_vm._v("-")])])],1)]}}])}),_c('b-table-column',{attrs:{"visible":!!_vm.columns.find(function (name) { return name === 'category'; }),"field":"category","label":"Category","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var category = ref.row.category;
return [_c('div',[_vm._v(_vm._s(category || '-'))])]}}])}),_c('b-table-column',{attrs:{"visible":!!_vm.columns.find(function (name) { return name === 'followerCount'; }),"field":"followerCount","label":"Followers","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var followerCount = ref.row.followerCount;
return [(followerCount)?_c('span',[_vm._v(_vm._s(_vm.separateThousandsBySpace(followerCount) || '-'))]):_vm._e()]}}])}),_c('b-table-column',{attrs:{"visible":!!_vm.columns.find(function (name) { return name === 'engagement'; }),"field":"engagement","label":"Engagement","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var engagement = ref.row.engagement;
return [(engagement && Number(engagement) >= 0.5)?_c('span',[_vm._v(" "+_vm._s(Math.round(engagement * 100) / 100)+"% ")]):_c('span',[_vm._v(" - ")])]}}])}),_c('b-table-column',{attrs:{"visible":!!_vm.columns.find(function (name) { return name === 'language'; }),"field":"language","label":"Language","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var language = ref.row.language;
return [_vm._v(" "+_vm._s(language ? language : '-')+" ")]}}])}),_c('b-table-column',{attrs:{"visible":!!_vm.columns.find(function (name) { return name === 'email'; }),"field":"email","label":"Email","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var email = ref.row.email;
return [_vm._v(" "+_vm._s(email || '-')+" ")]}}])}),_c('b-table-column',{attrs:{"visible":!!_vm.columns.find(function (name) { return name === 'age'; }),"field":"age","label":"Age","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var age = ref.row.age;
return [_vm._v(" "+_vm._s(age || '-')+" ")]}}])}),_c('b-table-column',{attrs:{"visible":!!_vm.columns.find(function (name) { return name === 'gender'; }),"field":"gender","label":"Gender","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var gender = ref.row.gender;
return [_vm._v(" "+_vm._s(gender || '-')+" ")]}}])}),(_vm.hasShopifyShop)?_c('b-table-column',{attrs:{"visible":!!_vm.columns.find(function (name) { return name === 'shopifyLinksCount'; }),"field":"shopifyLinkCount","label":"Affiliations & coupons","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var shopifyLinksCount = ref.row.shopifyLinksCount;
return [_vm._v(" "+_vm._s(shopifyLinksCount || '-')+" ")]}}],null,false,141350506)}):_vm._e(),(_vm.hasShopifyShop)?_c('b-table-column',{attrs:{"visible":!!_vm.columns.find(function (name) { return name === 'shopifyAmountOrders'; }),"field":"shopifyAmountOrders","label":"Revenues generated","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var shopifyAmountOrders = ref.row.shopifyAmountOrders;
return [_vm._v(" "+_vm._s(shopifyAmountOrders > 0 ? _vm.separateThousandsBySpace(shopifyAmountOrders) : '-')+" ")]}}],null,false,3277934324)}):_vm._e(),(_vm.hasShopifyShop)?_c('b-table-column',{attrs:{"visible":!!_vm.columns.find(function (name) { return name === 'shopifyTotalOrders'; }),"field":"shopifyTotalOrders","label":"Orders generated","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var shopifyTotalOrders = ref.row.shopifyTotalOrders;
return [_vm._v(" "+_vm._s(shopifyTotalOrders || '-')+" ")]}}],null,false,1295175434)}):_vm._e(),_c('b-table-column',{attrs:{"visible":!!_vm.columns.find(function (name) { return name === 'threadGroup'; }),"field":"threadGroup","label":"Tags","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var threadGroup = ref.row.threadGroup;
return [(threadGroup.length)?_c('UbuCRMTableCustomCell',{attrs:{"groups":threadGroup,"icon-name":"tag"}}):_c('span',[_vm._v(" - ")])]}}])}),_c('b-table-column',{attrs:{"visible":!!_vm.columns.find(function (name) { return name === 'contactGroup'; }),"field":"contactGroup","label":"Labels","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var contactGroup = ref.row.contactGroup;
return [(contactGroup.length)?_c('UbuCRMTableCustomCell',{attrs:{"groups":contactGroup,"icon-name":"contact"}}):_c('span',[_vm._v(" - ")])]}}])}),_c('b-table-column',{attrs:{"visible":!!_vm.columns.find(function (name) { return name === 'activeCampaign'; }),"field":"activeCampaign","label":"Active Campaigns","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var activeCampaign = ref.row.activeCampaign;
return [(activeCampaign.length)?_c('UbuCRMTableCustomCell',{attrs:{"groups":activeCampaign,"icon-name":"influence"}}):_c('span',[_vm._v(" - ")])]}}])}),_c('b-table-column',{attrs:{"visible":!!_vm.columns.find(function (name) { return name === 'pastCampaign'; }),"field":"pastCampaign","label":"Past Campaigns","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var pastCampaign = ref.row.pastCampaign;
return [(pastCampaign.length)?_c('UbuCRMTableCustomCell',{attrs:{"groups":pastCampaign,"icon-name":"influence"}}):_c('span',[_vm._v(" - ")])]}}])}),_c('b-table-column',{attrs:{"visible":!!_vm.columns.find(function (name) { return name === 'storyMentionCount'; }),"field":"storyMentionCount","label":"Story Mention","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var storyMentionCount = ref.row.storyMentionCount;
return [_vm._v(" "+_vm._s(storyMentionCount || '-')+" ")]}}])}),_c('b-table-column',{attrs:{"visible":!!_vm.columns.find(function (name) { return name === 'postMentionCount'; }),"field":"postMentionCount","label":"Post Mention","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var postMentionCount = ref.row.postMentionCount;
return [_vm._v(" "+_vm._s(postMentionCount || '-')+" ")]}}])}),_c('b-table-column',{attrs:{"visible":!!_vm.columns.find(function (name) { return name === 'ageAudience'; }),"field":"ageAudience.name","label":"Age audience","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var ageAudience = ref.row.ageAudience;
return [_vm._v(" "+_vm._s(ageAudience ? ageAudience.name : '-')+" ")]}}])}),_c('b-table-column',{attrs:{"visible":!!_vm.columns.find(function (name) { return name === 'location'; }),"field":"location","label":"Location","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var location = ref.row.location;
return [_vm._v(" "+_vm._s(location || '-')+" ")]}}])}),_c('b-table-column',{attrs:{"visible":!!_vm.columns.find(function (name) { return name === 'locationAudience'; }),"field":"locationAudience.name","label":"Location audience","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var locationAudience = ref.row.locationAudience;
return [_vm._v(" "+_vm._s(locationAudience ? locationAudience.name : '-')+" ")]}}])}),_c('b-table-column',{attrs:{"visible":!!_vm.columns.find(function (name) { return name === 'languageAudience'; }),"field":"languageAudience.name","label":"Language audience","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var languageAudience = ref.row.languageAudience;
return [_vm._v(" "+_vm._s(languageAudience ? languageAudience.name : '-')+" ")]}}])}),_c('b-table-column',{attrs:{"visible":!!_vm.columns.find(function (name) { return name === 'genderAudience'; }),"field":"genderAudience.name","label":"Gender audience","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var genderAudience = ref.row.genderAudience;
return [_vm._v(" "+_vm._s(genderAudience ? genderAudience.name : '-')+" ")]}}])}),_c('b-table-column',{attrs:{"visible":!!_vm.columns.find(function (name) { return name === 'lastMentionOrTagPost'; }),"field":"lastMentionOrTagPost","label":"Last Mention","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var lastMentionOrTagPost = ref.row.lastMentionOrTagPost;
return [_vm._v(" "+_vm._s(_vm.parseTimestamp(lastMentionOrTagPost) || '-')+" ")]}}])}),_c('b-table-column',{attrs:{"visible":!!_vm.columns.find(function (name) { return name === 'lastAddedCampaign'; }),"field":"lastAddedCampaign","label":"Last Campaign add","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var lastAddedCampaign = ref.row.lastAddedCampaign;
return [_vm._v(" "+_vm._s(_vm.parseTimestamp(lastAddedCampaign) || '-')+" ")]}}])}),_c('b-table-column',{attrs:{"visible":!!_vm.columns.find(function (name) { return name === 'lastMessageTS'; }),"field":"lastMessageTS","label":"Last message","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var lastMessageTS = ref.row.lastMessageTS;
return [_vm._v(" "+_vm._s(_vm.parseTimestamp(lastMessageTS) || '-')+" ")]}}])}),_c('b-table-column',{attrs:{"visible":!!_vm.columns.find(function (name) { return name === 'tiktokUsername'; }),"field":"tiktokUsername","label":"Tiktok username","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var tiktokUsername = ref.row.tiktokUsername;
return [_vm._v(" "+_vm._s(tiktokUsername || '-')+" ")]}}])}),_c('b-table-column',{attrs:{"visible":!!_vm.columns.find(function (name) { return name === 'follows'; }),"field":"follows","label":"Follows","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var follows = ref.row.follows;
return [(follows.length)?_c('div',_vm._l((follows),function(account,index){return _c('span',{key:index},[_vm._v(" "+_vm._s(index > 0 ? ' ,' : '')+" "+_vm._s(("@" + account))+" ")])}),0):_c('span',[_vm._v("-")])]}}])}),_c('b-table-column',{attrs:{"field":"growth","label":"Growth","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var growth = ref.row.growth;
return [(typeof growth === 'number')?_c('b-image',{staticClass:"arrow-svg",attrs:{"src":growth > 0 ? '../img/arrow-up.svg' : '../img/arrow-down.svg'}}):_c('span',[_vm._v("-")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }