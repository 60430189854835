import { mapGetters, mapActions } from 'vuex';
import { convertColor } from '@/addons/$utils/formate';
import UbuContactImporterModal from '@/components/UbuModals/UbuContactImporterModal';
import { snackSuccess } from '@/addons/$catalog/snackbar';
import { getThreadName } from '@/addons/$utils/threadUtilities';

export default {
  data() {
    return {
      bulkAlready: [],
      bulkSuccess: [],
      bulkFail: [],
      isCardModalActive: false,
      isResearchEnded: false,
    };
  },
  computed: {
    ...mapGetters({
      getterContacts: 'Contact/getterContacts',
      getterCampaignActivitiesDetails: 'TheInfluence/getterCampaignActivitiesDetails',
    }),
    campaigns() {
      return this.getterCampaignActivitiesDetails.campaigns;
    },
    campaignSelector() {
      return {
        props: {
          choices: this.campaignItems(this.getterCampaignActivitiesDetails.campaigns),
          paths: { id: 'campaignId', label: 'name' },
          labelIcon: 'influence',
          tooltip: {
            label: 'Assign to a campaign',
            position: 'is-left',
          },
          searchPlaceholder: 'Search a campagn',
          createPlaceholder: null,
        },
      };
    },
  },
  methods: {
    ...mapActions({
      fetchContactsByUsernames: 'Contact/fetchContactsByUsernames',
      setUnscheduled: 'TheInfluence/setUnscheduled',
      setModal: 'Modal/setModal',
    }),
    usernamesToFetchVsStillInStore(usernames) {
      const contacts = Object
        .values(this.getterContacts)
        .reduce((acc, contact) => {
          const { username } = contact;
          if (username) acc[username] = contact;
          return acc;
        }, {});
      return usernames.reduce((acc, username) => {
        const knownContact = contacts[username];
        if (knownContact) {
          acc.contactsInStore.push(knownContact);
        } else {
          acc.usernamesToFetch.push(username);
        }
        return acc;
      }, { usernamesToFetch: [], contactsInStore: [] });
    },
    contactIsIncampaign(username, activities) {
      const isIn = activities.find((activity) => {
        const contact = activity.contactDetail;
        return contact.username === username;
      });
      return !!isIn;
    },
    contactsSillInCampaignVsToAssign({ campaign, contacts }) {
      console.log('Campaign is', campaign);
      return contacts.reduce((acc, contact) => {
        const { username } = contact;
        if (this.contactIsIncampaign(username, campaign.activities)) {
          acc.stillInCampaign.push(contact);
        } else {
          acc.toAssign.push(contact);
        }
        return acc;
      }, { stillInCampaign: [], toAssign: [] });
    },
    assignContactsToCampaign({ campaign, threads }) {
      const { campaignId, channelId } = campaign;
      const contacts = threads.map((t) => t.contact);

      const { stillInCampaign, toAssign } = this.contactsSillInCampaignVsToAssign({ campaign, contacts });
      this.setModal({
        cmp: UbuContactImporterModal,
        props: { bulkAlready: stillInCampaign },
      });
      const activities = toAssign.map((contact) => ({
        pk: contact.contactId,
        username: contact.username,
        status: 'unscheduled',
        channel: channelId,
        campaignId,
        campaign: campaignId,
      }));
      return this.setUnscheduled({
        campaignId,
        channelId,
        activities,
      }).then(() => {
        this.setModal({
          cmp: UbuContactImporterModal,
          props: {
            bulkAlready: stillInCampaign,
            bulkSuccess: toAssign,
            bulkFail: [],
            isResearchEnded: true,
          },
        });
        const message = threads.length === 1
          ? `Contact ${getThreadName(threads[0])} has been assign to campaign ${campaign.name}`
          : `${threads.length} contacts has been assign to campaign ${campaign.name}`;
        this.$buefy.snackbar.open({
          ...snackSuccess,
          message,
        });
      });
    },
    onBulkCommentCampaignSelected({ campaign, bulks }) {
      const { channelId, campaignId } = campaign;
      const _usernames = bulks.filter(() => true)
        .reduce((acc, { from }) => {
          if (from.username) {
            acc[from.username] = from;
          }
          return acc;
        }, {});
      const usernames = Object.keys(_usernames);
      const { usernamesToFetch, contactsInStore } = this.usernamesToFetchVsStillInStore(usernames);
      const { stillInCampaign, toAssign } = this.contactsSillInCampaignVsToAssign({
        campaign,
        contacts: contactsInStore,
      });
      this.setModal({
        cmp: UbuContactImporterModal,
        props: { bulkAlready: stillInCampaign },
      });
      Promise.resolve()
        .then(() => this.fetchContactsByUsernames(usernamesToFetch))
        .then(({ success, failures }) => {
          const contactsToAssignActivity = [...success, ...toAssign];
          this.bulkSuccess = contactsToAssignActivity;
          this.isResearchEnded = true;
          this.setModal({
            cmp: UbuContactImporterModal,
            props: {
              bulkAlready: stillInCampaign,
              bulkSuccess: contactsToAssignActivity,
              bulkFail: failures,
              isResearchEnded: true,
            },
          });
          const activities = contactsToAssignActivity.map((contact) => ({
            pk: contact.contactId,
            username: contact.username,
            status: 'unscheduled',
            channel: channelId,
            campaignId,
            campaign: campaignId,
          }));
          return this.setUnscheduled({
            campaignId,
            channelId,
            activities,
          });
        });
    },
    campaignItems(items) {
      return ((items && Object.values(items)) || [])
        .filter((campaign) => !(campaign.ended === true))
        .map((campaign) => ({
          ...campaign,
          color: convertColor(campaign.color),
          icon: 'influence',
        }))
        .sort((a, b) => {
          const _a = this.$moment(a.created);
          const _b = this.$moment(b.created);
          return _b.valueOf() - _a.valueOf();
        });
    },
  },
};
