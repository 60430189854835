<template>
  <UbuCommonMessageCard
    :is-me="isMe"
    :date="date"
    is-text
    :is-error="isError"
    @deleteMessage="$emit('deleteMessage')"
    @resend="$emit('resend')"
    @translateMessage="$emit('translateMessage', $event)"
  >
    <template v-slot:content>
      <div
        class="card-content-text cursor-pointer"
        @click="$emit('clickToReply', $event)"
      >
        {{ text }}
        <div
          v-if="!isMe"
          class="common-comment-correspondantDetails"
        >
          <div
            class="common-comment-avatarImg"
            :style="`background-image: url('${url}')`"
          />
          <UbuInstaName
            class="ubu-instalink"
            :username="username"
          />
        </div>
      </div>
    </template>
  </UbuCommonMessageCard>
</template>

<script>
export default {
  name: 'UbuCommonComment',
  props: {
    username: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    isMe: {
      type: Boolean,
      default: false,
    },
    date: {
      type: String,
      required: true,
    },
    isError: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.common-comment-correspondantDetails {
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  & .common-comment-avatarImg {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: var(--geraldine);
    background-size: cover;
    box-sizing: border-box;
    margin-right: 4px;
  }
}
</style>
