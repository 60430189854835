/* eslint-disable no-param-reassign */
import axios from 'axios';
import ErrorHandler from '@/addons/$utils/errors';
import store from '@/store';

const connectors = {
  barbara: () => axios.create({
    baseURL: process.env.VUE_APP_BARBARA_BASE_URL,
    headers: { 'Content-Type': 'application/json' },
  }),
  clark: () => axios.create({
    baseURL: process.env.VUE_APP_CLARK_BASE_URL,
    headers: { 'Content-Type': 'application/json' },
  }),
  yaerApi: () => axios.create({
    baseURL: process.env.VUE_APP_YAERAPI_BASE_URL,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      haspage: 'influenceV1',
    },
  }),
};

const internals = {
  // eslint-disable-next-line arrow-body-style
  parseResponse: ({ data }) => {
    /** Only for yaerApi */
    if (data && data.success === false) {
      const error = {
        message: data.reason,
        status: 'BackendError',
        statusText: data.error,
        ...data,
      };
      ErrorHandler.generic({ dispatch: store.dispatch }, { error });
    }
    return data;
  },
  // eslint-disable-next-line arrow-body-style
  parseError: (error) => {
    return ({
      error: {
        message: error.response.data,
        status: error.response.status,
        statusText: error.response.statusText,
      },
    });
  },
  httpQueries: (context) => {
    const { httpQuery = {} } = context;
    const queries = Object.entries(httpQuery);
    const httpQueries = queries
      .reduce((acc, [key, val], index) => {
        if (index > 0 && index <= (queries.length - 1)) {
          acc += val && typeof val !== 'boolean' ? `&${key}=${val}` : `&${key}`;
        } else {
          acc += val && typeof val !== 'boolean' ? `?${key}=${val}` : `?${key}`;
        }
        return acc;
      }, '');

    return `${context.url}${httpQueries}`;
  },
};

const requestBuilder = (context) => {
  const { payload: data, target = 'barbara', method } = context;
  const url = internals.httpQueries(context);
  return connectors[target]().request({ url, data, method });
};

const builder = (context) => requestBuilder(context)
  .then(internals.parseResponse)
  .catch(internals.parseError);

export default builder;
