import Vue from 'vue';
import VueRouter from 'vue-router';
// import { SnackbarProgrammatic as Snackbar } from 'buefy';
import { routes as addonsRoutes } from './addons';
// import store from './store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: process.env.VUE_APP_ROOT_PAGE || 'home',
  },
  // {
  //   path: '/connect-instagram',
  //   name: 'connect-instagram',
  //   component: () => import('@/views/UbuInstagramConnect.vue'),
  // },
  // {
  //   path: '/ui',
  //   // name: 'ui',
  //   component: () => import('@/views/UbuUi.vue'),
  // },
];

Object.values(addonsRoutes).forEach((addonRoutes) => {
  routes.push(...addonRoutes);
});

routes.push({ path: '*', redirect: '/error/404' });

const router = new VueRouter({
  routes,
  mode: 'history',
});

// router.beforeEach((to, from, next) => {
//   if (to.matched.some((record) => record.meta.auth === true)) {
//     const auth = store.getters['Authenticate/getterAuthenticate'];
//     console.log('🚀 ~ file: router.js ~ line 9 ~ auth', auth);
//     console.log('Going to', to, 'from', from, 'require auth', auth);
//     if (!auth) {
//       return next({
//         name: 'Login',
//         // query: { redirect: to.fullPath },
//       });
//     }
//     return next();
//   }
//   return next();
// });

// router.beforeEach((to, from, next) => {
//   // console.log('🚀 ~ file: router.js ~ line 53 ~ router.beforeEach ~ from', from);
//   // console.log('🚀 ~ file: router.js ~ line 53 ~ router.beforeEach ~ to', to);

//   if (from.meta.requireFacebookData && to.meta.requireData && !to.meta.requireFacebookData) {
//     console.log('||||||||||||||||||||| FETCH REGULAR DATA ||||||||||||||||||||||||||');
//     store.dispatch('Sequence/cleanAll')
//       .then(() => {
//         store.dispatch('Sequence/fetchAll');
//       });
//   }
//   if (from.meta.requireData && to.meta.requireFacebookData && !to.meta.requireData) {
//     console.log('********************* FETCH FACEBOOK DATA **************************');
//     store.dispatch('Sequence/cleanAll')
//       .then(() => {
//         store.dispatch('Sequence/fetchComments')
//           .catch((e) => {
//             Snackbar.open({
//               message: e.message,
//               type: 'is-danger',
//               position: 'is-bottom-right',
//               duration: 5000,
//             });
//           });
//       });
//   }
//   return next();
// });

export default router;
