import { mapActions } from 'vuex';
import { intersectionBy } from 'lodash';
import { snackSuccess, snackError } from '@/addons/$catalog/snackbar';

export default {
  methods: {
    ...mapActions({
      _assignThreadsToThreadGroup: 'TheThreadList/assignThreadsToThreadGroup',
      _removeThreadsFromThreadGroup: 'TheThreadList/removeThreadsFromThreadGroup',
      _createThreadGroupAndAssignMany: 'TheThreadList/createThreadGroupAndAssignMany',

      _assignContactsToContactGroup: 'TheThreadList/assignContactsToContactGroup',
      _removeContactsFromContactGroup: 'TheThreadList/removeContactsFromContactGroup',
      _createContactGroupAndAssignMany: 'TheThreadList/createContactGroupAndAssignMany',

      _doneManyThreads: 'TheThreadList/doneManyThreads',
      _openManyThreads: 'TheThreadList/openManyThreads',
      _closeManyThreads: 'TheThreadList/closeManyThreads',
    }),
    threadGroupItems(items) {
      return (items || []).map((threadGroup) => ({
        ...threadGroup,
        icon: 'tag',
      }));
    },
    contactGroupItems(items) {
      return (items || []).map((contactGroup) => ({
        ...contactGroup,
        icon: 'contact',
      }));
    },
    getIntersection(threads, prop) {
      const intersec = threads.reduce((acc, thread) => {
        if (!acc) { return thread[prop]; }
        return intersectionBy(acc, thread[prop]);
      }, false);
      return intersec;
    },
    allThreadsAreOpen(threads) {
      const allOpened = !threads.find((t) => t.closed === false);
      return allOpened;
    },
    isDone(thread, threadGroup) {
      return threadGroup && !!thread.threadGroups
        .find((tg) => String(tg.threadGroupId) === String(threadGroup.threadGroupId));
    },
    assignThreadsToThreadGroup($event) {
      this._assignThreadsToThreadGroup($event)
        .then(({ error, count, title }) => {
          if (error) {
            this.$buefy.snackbar.open({
              ...snackError,
              message: `Error while assigning "${count}" threads to tag "${title}"`,
              onAction: () => this.assignThreadsToThreadGroup($event),
            });
          } else {
            this.$buefy.snackbar.open({
              ...snackSuccess,
              message: `${count} threads has been assigned to tag "${title}"`,
            });
          }
        });
    },
    removeThreadsFromThreadGroup($event) {
      this._removeThreadsFromThreadGroup($event)
        .then(({ error, count, title }) => {
          if (error) {
            this.$buefy.snackbar.open({
              ...snackError,
              message: `Error while removing "${count}" threads from tag "${title}"`,
              onAction: () => this.removeThreadsFromThreadGroup($event),
            });
          } else {
            this.$buefy.snackbar.open({
              ...snackSuccess,
              message: `${count} threads has been removed from tag "${title}"`,
            });
          }
        });
    },
    createThreadGroupAndAssignMany($event) {
      this._createThreadGroupAndAssignMany($event)
        .then(({ error, count, title }) => {
          if (error) {
            this.$buefy.snackbar.open({
              ...snackError,
              message: `Error while creating "${title}"`,
              onAction: () => this.createThreadGroupAndAssignMany($event),
            });
          } else {
            this.$buefy.snackbar.open({
              ...snackSuccess,
              message: `"${title}" has been created and ${count} threads has been assigned`,
            });
          }
        });
    },
    assignContactsToContactGroup($event) {
      this._assignContactsToContactGroup($event)
        .then(({ error, count, title }) => {
          if (error) {
            this.$buefy.snackbar.open({
              ...snackError,
              message: `Error while assigning "${count}" contacts to folder "${title}"`,
              onAction: () => this.assignContactsToContactGroup($event),
            });
          } else {
            this.$buefy.snackbar.open({
              ...snackSuccess,
              message: `${count} contacts has been assigned to "${title}"`,
            });
          }
        });
    },
    removeContactsFromContactGroup($event) {
      this._removeContactsFromContactGroup($event)
        .then(({ error, count, title }) => {
          if (error) {
            this.$buefy.snackbar.open({
              ...snackError,
              message: `Error while removing "${count}" contacts from folder "${title}"`,
              onAction: () => this.removeContactsFromContactGroup($event),
            });
          } else {
            this.$buefy.snackbar.open({
              ...snackSuccess,
              message: `${count} contacts has been assigned to "${title}"`,
            });
          }
        });
    },
    createContactGroupAndAssignMany($event) {
      this._createContactGroupAndAssignMany($event)
        .then(({ error, count, title }) => {
          if (error) {
            this.$buefy.snackbar.open({
              ...snackError,
              message: `Error while creating contact folder "${title}"`,
              onAction: () => this.createContactGroupAndAssignMany($event),
            });
          } else {
            this.$buefy.snackbar.open({
              ...snackSuccess,
              message: `Folder "${title}" has been created and ${count} contacts has been assigned`,
            });
          }
        });
    },
    doneManyThreads($event) {
      this._doneManyThreads($event)
        .then(({ error, count }) => {
          if (error) {
            this.$buefy.snackbar.open({
              ...snackError,
              message: `Error while making done ${count} threads`,
              onAction: () => this.doneManyThreads($event),
            });
          } else {
            this.$buefy.snackbar.open({
              ...snackSuccess,
              message: `${count} threads has been done`,
            });
          }
        });
    },
    openManyThreads($event) {
      this._openManyThreads($event)
        .then(({ error, count }) => {
          if (error) {
            this.$buefy.snackbar.open({
              ...snackError,
              message: `Error while opening ${count} threads`,
              onAction: () => this.openManyThreads($event),
            });
          } else {
            this.$buefy.snackbar.open({
              ...snackSuccess,
              message: `${count} threads has been opened`,
            });
          }
        });
    },
    closeManyThreads($event) {
      this._closeManyThreads($event)
        .then(({ error, count }) => {
          if (error) {
            this.$buefy.snackbar.open({
              ...snackError,
              message: `Error while closing ${count} threads`,
              onAction: () => this.closeManyThreads($event),
            });
          } else {
            this.$buefy.snackbar.open({
              ...snackSuccess,
              message: `${count} threads has been closed`,
            });
          }
        });
    },
  },
};
