var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[{
    'UbuThreadCard_wrapperSelected': _vm.selected,
    'UbuThreadCard_isAway': _vm.isAway,
  }],attrs:{"id":"UbuThreadCard"}},[_c('div',{staticClass:"\n    UbuThreadCard_avatar",on:{"click":function($event){return _vm.$emit('onBulkSelect', $event)}}},[_c('div',{class:['UbuThreadCard_avatarImg', {
        'UbuThreadCard_avatarSelected': _vm.bulkSelected,
        'UbuThreadCard_broSelected': _vm.broSelected && !_vm.bulkSelected,
      }]},[_c('UbuInstaImg',{directives:[{name:"show",rawName:"v-show",value:(!_vm.bulkSelected && !_vm.broSelected),expression:"!bulkSelected && !broSelected"}],key:("contact/" + (_vm.contact.contactId)),attrs:{"insta-src":_vm.contact.has_bucket_media
          ? String(_vm.contact.contactId) : _vm.contact.profile_pic_url,"has-bucket":_vm.contact.has_bucket_media || false,"bucket-folder":'profile_pic',"fallback":"/img/no_profile_pict.svg"}}),(_vm.bulkSelected)?_c('img',{staticClass:"UbuThreadCard_checkMark",attrs:{"src":require("@/assets/pictos/is_ok.svg")}}):_vm._e(),(!_vm.broSelected && !_vm.bulkSelected)?_c('div',{staticClass:"UbuThreadCard_source"},[(_vm.source === 'instagram')?_c('img',{attrs:{"src":require("@/assets/pictos/instagram-logo.svg")}}):_vm._e(),(_vm.source === 'facebook')?_c('img',{attrs:{"src":require("@/assets/pictos/facebook-logo.svg")}}):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"UbuThreadCard_textBox",on:{"click":function($event){return _vm.$emit('onSelect', $event)}}},[_c('div',{class:[
        'UbuThreadCard_textRow',
        'ubu-text1', _vm.contactGroups.length ? ("text-color-" + (_vm.contactGroups[0].color)) :
          '']},[_vm._l((_vm.contactGroups),function(contactGroup){return _c('UbuIcon',{key:("contactGroup/" + (_vm.contact.contactId) + "/" + (contactGroup.contactGroupId)),attrs:{"color":contactGroup.color,"filled":"","name":"contact"}})}),_vm._v(" "+_vm._s(_vm.getFullName)+" ")],2),_c('div',{class:[
        'UbuThreadCard_textRow',
        'ubu-text2', _vm.threadGroups.length ? ("text-color-" + (_vm.threadGroups[0].color)) : ''
      ]},[_vm._l((_vm.assignments),function(ubuUser){return _c('UbuUserPicture',{key:("assignment/" + (_vm.contact.contactId) + "/" + (ubuUser.ubuUserId)),attrs:{"size":18,"initial-name":ubuUser.initials,"color":ubuUser.color}})}),_vm._l((_vm.threadGroups),function(threadGroup){return _c('UbuIcon',{key:("threadGroup/" + (_vm.contact.contactId) + "/" + (threadGroup.threadGroupId)),attrs:{"color":threadGroup.color,"filled":"","name":"tag"}})}),_vm._v(" "+_vm._s(_vm.getLastMessageText)+" ")],2)]),_c('div',{staticClass:"UbuThreadCard_right"},[_c('span',{staticClass:"UbuThreadCard_date"},[_vm._v(_vm._s(_vm.getParsedDate))]),(!_vm.contactSentLast)?_c('UbuIcon',{attrs:{"name":"send","size":"12px","filled":""}}):(_vm.unread)?_c('b-tooltip',{staticClass:"UbuThreadCard_badgeContainer",attrs:{"label":"Mark as read","position":"is-left"}},[_c('UbuBadge',{staticClass:"UbuThreadCard_badge",attrs:{"important":""},on:{"click":function($event){return _vm.$emit('onBadgeClick', $event)}}})],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }