import Vue from 'vue';
import buefy from 'buefy';
import 'buefy/dist/buefy.css';
import vClickOutside from 'v-click-outside';
import VueClipboard from 'vue-clipboard2';
import VueShortkey from 'vue-shortkey';
import VueVirtualScroller from 'vue-virtual-scroller';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import { VueMasonryPlugin } from 'vue-masonry';
import JsonCSV from 'vue-json-csv';

Vue.use(buefy);

Vue.use(vClickOutside);
Vue.use(VueClipboard);
Vue.use(VueShortkey);
Vue.use(VueVirtualScroller);
Vue.use(VueMasonryPlugin);
Vue.component('downloadCsv', JsonCSV);

Vue.directive('focus', { inserted(el) { setTimeout(() => el.focus(), 100); } });

Vue.filter('capitalize', (value) => (value ? value.toString().charAt(0).toUpperCase() + value.slice(1) : ''));

Vue.filter('lowercase', (value) => (value ? value.toString().toLowerCase() : ''));
