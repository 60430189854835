<template>
  <UbuBaseModal
    :title="title"
    :pic="'Error.gif'"
  >
    <template slot="info">
      {{ description }}
    </template>
    <template slot="actions">
      <div class="UbuReconnectChannel_actions">
        <UbuSimpleDropdown
          grow
          center
          class="mr-1"
          default-label="Ignore and switch to..."
          :value="null"
          :options="options"
          @input="selectNewChannel($event)"
        />
        <UbuButton
          class="ml-2"
          center
          grow
          secondary
          v-bind="disabledCounter <= 0 ? { href: '/', target: '_self' } : null"
          :disabled="disabledCounter > 0"
        >
          {{ actionTitle }} <template v-if="disabledCounter > 0">({{ disabledCounter }})</template>
        </UbuButton>
      </div>
    </template>
  </UbuBaseModal>
</template>
<script>
import UbuBaseModal from '@/components/UbuModals/UbuBaseModal';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    UbuBaseModal,
  },
  props: {
    kind: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      disabledCounter: 120,
    };
  },
  mounted() {
    const interval = setInterval(() => {
      this.disabledCounter -= 1;
      if (this.disabledCounter <= 0) { clearInterval(interval); }
    }, 1000);
  },
  computed: {
    title() {
      return `Your channel @${this.currentChannel.username} is overloaded.`;
    },
    description() {
      return 'Please wait a few minutes and refresh the page.';
    },
    actionTitle() {
      return 'Refresh';
    },
    ...mapGetters({
      channels: 'Channel/getterChannels',
      currentChannel: 'Channel/getterCurrentChannel',
    }),
    options() {
      return Object.values(this.channels)
        .filter((c) => c.channelId !== this.currentChannel.channelId)
        .map((c) => ({
          key: c.channelId, label: `@${c.username}`,
        }));
    },
  },
  methods: {
    selectNewChannel(channelId) {
      this.switchChannel({ channel: this.channels[channelId] });
      this.$emit('close');
    },
    ...mapActions({
      switchChannel: 'Channel/switchChannel',
    }),
  },

};
</script>
<style lang="scss">
  .UbuReconnectChannel_actions {
    display: flex;
    flex-grow: 1;
    > * {
      width: 50%;
    }
  }
</style>
