<template>
  <section id="TheLeads">
    <TheSidebar
      :menu-items-first-column="menuItemsFirstColumn"
    />
    <div id="UbuLeadsBoard">
      <div class="header-board">
        <h1 class="ubu-title">
          Lists
        </h1>
        <UbuButton
          :icon="{ name: 'add', filled: true }"
          secondary
          @click="isModalAddListActive = !isModalAddListActive"
        >
          New list
        </UbuButton>
      </div>
      <b-modal
        v-model="isModalAddListActive"
        :width="640"
        scroll="keep"
      >
        <UbuLeadsModalAddList
          v-model="usernameSearch"
          :is-card-modal-active="isModalAddListActive"
          :username-search-result="usernameSearchResult"
          @close="isModalAddListActive = false"
          @addFromModal="createList($event)"
        />
      </b-modal>
      <div class="button-group">
        <UbuButton
          v-for="(item, index) in lists"
          :key="index"
          class="list-button"
          center
          simple
        >
          {{ item.name }}
        </UbuButton>
      </div>
      <div
        v-if="listData.length"
        class="button-group"
      >
        <UbuSimpleInput
          v-model="searchValue"
          class="list-button"
          placeholder="Search..."
          searchbar
        >
          <UbuIcon
            slot="placeholderIcon"
            name="search"
          />
        </UbuSimpleInput>
        <UbuButton
          class="list-button"
          :icon="{ name: 'filter' }"
          :active="showFilters && canShowFilters"
          @click="showFilters = !showFilters"
        />
      </div>
      <div
        v-if="showFilters && canShowFilters"
        class="list-filters"
      >
        <h1 class="ubu-header1">Filters</h1>
        <div class="button-group">
          <UbuRangeSelector
            v-for="item in rangeFilters"
            :key="item.id"
            :button-title="item.buttonTitle"
            :modal-title="item.modalTitle"
            :range="item.range"
            :ticks="item.ticks"
            @setRangeFilter="setRangeFilter({ name: item.name, ...$event})"
          />
          <UbuCheckboxSelector
            v-for="(item, index) in checkBoxFilters"
            :key="index"
            :button-title="item.buttonTitle"
            :modal-title="item.modalTitle"
            :checkbox-list="item.name === 'location' ? locationList : item.name === 'language' ? languageList : item.name === 'gender' ? genderList : []"
            @setCheckFilter="setCheckFilter({ name: item.name, ...$event})"
          />
        </div>
      </div>

      <div
        v-if="checkedRows.length"
        class="list-filters"
      >
        <h1 class="ubu-header1">{{ checkedRows.length }} selected</h1>
        <div class="button-group">
          <b-dropdown aria-role="list">
            <template #trigger="{ active }">
              <b-button
                rounded
                :icon-right="active ? 'menu-up' : 'menu-down'"
              >
                <UbuIcon
                  name="influence"
                  :filled="true"
                />
                Add to campaign
              </b-button>
            </template>

            <b-dropdown-item
              v-for="(campaign, index) in campaignList"
              :key="index"
              aria-role="listitem"
            >
              {{ campaign }} <UbuIcon name="add" />
            </b-dropdown-item>
          </b-dropdown>

          <UbuButton
            class="list-button"
            dashed
            :icon="{ name: 'trash', filled: true }"
          >
            Remove from list
          </UbuButton>
          <UbuButton
            class="list-button"
            dashed
            :icon="{ name: 'failure', filled: true }"
            @click="clearCheckedRow()"
          >
            Cancel
          </UbuButton>
        </div>
      </div>

      <UbuLeadsTable
        v-if="listData.length"
        :list-filtered="listFiltered"
        :checked-rows="checkedRows"
        :selected-row="selectedRow"
        @checkRow="checkRow($event)"
        @selectRow="selectRow($event)"
        @checkAllRows="checkAllRows($event)"
      />

      <div
        v-else
        class="list-tuto-container"
      >
        <h1 class="ubu-title">
          How to use lists 💡
        </h1>
        <ul class="list-tuto-ul">
          <li
            v-for="(tips, index) in tipsList"
            :key="index"
            class="list-tuto-text"
          >
            {{ tips }}
          </li>
        </ul>
      </div>
    </div>
    <div v-if="isPanelActive">
      <b-button
        class="button-hide"
        @click="closePanel()"
      >
        <UbuIcon
          name="expand"
        />
      </b-button>
      <TheContactPanel
        class="list-panel"
        :contact-id="currentContactId"
      />
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { listData } from './dataset/fakeUserList';
import { debounce } from '../$utils/helpers';

export default {
  name: 'TheLeads',
  data() {
    return {
      searchValue: '',
      usernameSearch: '',
      usernameSearchResult: [],
      showFilters: false,
      isModalAddListActive: false,
      newList: '',
      checkedRows: [],
      isPanelActive: false,
      // listData: [],
      listData,
      campaignList: ['campaign #1', 'campaign #2'],
      tipsList: [
        'Click “Add ambassadors to list” and indicate an instagram username and a minimum amount of followers to get similar profiles added to your list.',
        'Specify how many new ambassadors you want to add each month.',
        'Get your list automatically populated with fresh new leads each month.',
        'Reach out to them in influence campaigns.',
      ],
      selectedRow: {},
      rangeFilters: [
        {
          id: 615,
          name: 'followers',
          buttonTitle: '+ Followers',
          modalTitle: 'Followers',
          range: [0, 5],
          ticks: [
            { text: '0', value: 0 },
            { text: '3k', value: 10 },
            { text: '10k', value: 10000 },
            { text: '50k', value: 50000 },
            { text: '200k', value: 200000 },
            { text: 'No limit', value: Infinity },
          ],
        },
        {
          id: 8845,
          name: 'engagement',
          buttonTitle: '+ Engagement',
          modalTitle: 'Engagement rate',
          range: [0, 5],
          ticks: [
            { text: '0%', value: 0 },
            { text: '5%', value: 5 },
            { text: '10%', value: 10 },
            { text: '15%', value: 15 },
            { text: '20%', value: 20 },
            { text: 'No limit', value: Infinity },
          ],
        },
      ],
      checkBoxFilters: [
        {
          name: 'location',
          buttonTitle: '+ Location',
          modalTitle: 'Location',
          // value: this.locationList,
        },
        {
          name: 'language',
          buttonTitle: '+ Language',
          modalTitle: 'Language',
          // value: this.languageList,
        },
        {
          name: 'gender',
          buttonTitle: '+ Gender',
          modalTitle: 'Gender',
          value: ['Male', 'Female', 'Other', 'Unknown'],
        },
      ],
      currentFollowersRange: [],
      currentEngagementRange: [],
      currentLocationCheckList: [],
      currentLanguageCheckList: [],
      currentGenderCheckList: [],
    };
  },
  computed: {
    ...mapGetters({
      currentChannelId: 'Channel/getterCurrentChannelId',
      lists: 'TheLeads/getterLists',
    }),
    menuItemsFirstColumn() {
      return [
        {
          icon: 'crm',
          text: 'List',
          href: '/list',
        },
        {
          icon: 'settings',
          text: 'Campaigns',
          href: '/influence',
        },
      ];
    },
    listFiltered() {
      // return list after all filter
      let myListFiltered = this.listData.length && this.listData
        .filter(({ username }) => username.includes(this.searchValue));

      if (this.currentFollowersRange.length) {
        const [min, max] = this.currentFollowersRange;
        myListFiltered = myListFiltered
          .filter(({ followers }) => followers >= min && followers <= max);
      }
      if (this.currentEngagementRange.length) {
        const [min, max] = this.currentEngagementRange;
        myListFiltered = myListFiltered
          .filter(({ engagement }) => engagement >= min && engagement <= max);
      }
      if (this.currentLocationCheckList.length) {
        myListFiltered = myListFiltered
          .filter(({ location }) => this.currentLocationCheckList.includes(location));
      }
      if (this.currentLanguageCheckList.length) {
        myListFiltered = myListFiltered
          .filter(({ language }) => this.currentLanguageCheckList.includes(language));
      }
      if (this.currentGenderCheckList.length) {
        myListFiltered = myListFiltered
          .filter(({ gender }) => this.currentGenderCheckList.includes(gender));
      }

      return myListFiltered;
    },
    allChecked() {
      return this.listFiltered.length && this.checkedRows.length === this.listFiltered.length;
    },
    canShowFilters() {
      return !this.checkedRows.length;
    },
    currentContactId() {
      return this.selectedRow ? this.selectedRow.contactId : null;
    },
    locationList() {
      // get location list without double values
      return this.listData.reduce((acc, row) => {
        if (acc.includes(row.location)) return acc;
        acc.push(row.location);
        return acc;
      }, []).sort();
    },
    languageList() {
      // get language list without double values
      return this.listData.reduce((acc, row) => {
        if (acc.includes(row.language)) return acc;
        acc.push(row.language);
        return acc;
      }, []).sort();
    },
    genderList() {
      // get gender list without double values
      return this.listData.reduce((acc, row) => {
        if (acc.includes(row.gender)) return acc;
        acc.push(row.gender);
        return acc;
      }, []).sort();
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    usernameSearch: debounce(function (newVal) {
      this.usernameSearchResult = [];
      if (newVal === '') return;
      this.searchUser(
        { channelId: this.currentChannelId, username: newVal },
      ).then((response) => {
        if (response) this.usernameSearchResult = response;
      });
    }, 700),
  },
  methods: {
    ...mapActions({
      searchUser: 'TheLeads/searchUser',
      addList: 'TheLeads/createList',
    }),
    closePanel() {
      this.isPanelActive = false;
    },
    clearCheckedRow() {
      this.checkedRows = [];
    },
    setRangeFilter(event) {
      console.log('🚀 ~ file: TheLeads.vue ~ line 959 ~ setRangeFilter ~ event', event);
      const { name, rangeValues } = event;
      if (name === 'followers') this.currentFollowersRange = rangeValues;
      if (name === 'engagement') this.currentEngagementRange = rangeValues;
    },
    setCheckFilter(event) {
      console.log('🚀 ~ file: TheLeads.vue ~ line 987 ~ setCheckFilter ~ event', event);
      const { name, checked } = event;
      if (name === 'location') this.currentLocationCheckList = checked;
      if (name === 'language') this.currentLanguageCheckList = checked;
      if (name === 'gender') this.currentGenderCheckList = checked;
    },
    selectRow(event) {
      this.selectedRow = event;
      this.isPanelActive = true;
    },
    checkAllRows(event) {
      this.checkedRows = [...event];
    },
    checkRow(event) {
      this.showFilters = false;
      this.checkedRows = [...event];
    },
    createList(list) {
      this.addList({
        ...list,
        quota: 50,
        channel: this.currentChannelId,
      }).then(() => {
        this.isModalAddListActive = false;
      });
    },
  },
};
</script>

<style lang="scss">
@import 'Leads.scss';
</style>
