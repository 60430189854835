<template>
  <li
    v-if="comment"
    :class="{
      'list-unstyle': true,
      'comment_child': nesting > 0,
      'comment_parent': nesting === 0,
      'isError': isError,
      'isPending': isPending,
    }"
  >
    <div
      class="conversation"
    >
      <div
        :class="
          'conversation__row' +
            [comment.isMe ? ' isMe' : ''] +
            [hover ? ' hover' : '']
        "
        @mouseover="hover = true"
        @mouseleave="hover = false"
      >
        <UbuIcon
          v-if="isError"
          name="refresh"
          class="isError__btn"
          style="color: var(--error)"
          clickable
          @click="$emit('resend', comment)"
        />
        <div
          :class="
            'comment' +
              [!comment.isMe ? ' isNotMe' : '']
          "
        >
          <div
            :class="
              'bulle' +
                [comment.isMe ? ' is-me' : ' is-not-me'] +
                [forward ? ' forward' : ''] +
                [isFocus ? ' focus' : '']
            "
            @click="handleClick"
          >
            <UbuCommonCommentBody
              :comment-id="comment.commentId"
              :comment="comment.text"
              :mentions="comment.mentions"
            />
            <div
              v-if="comment.attachments && comment.attachments.length"
              class="multibar mb-2"
            >
              <UbuButton
                v-if="comment.attachments[0].url"
                type="button"
                secondary
                v-bind="{ href: comment.attachments[0].url, target: '_blank' }"
                class="start"
              >
                Click here to see the attachment
              </UbuButton>
            </div>

            <UbuCommonCommentFooter
              :img-is-square="comment.isPost"
              :is-pending="isPending"
              :from="comment.from"
              :reactions="comment.reactions"
              :published-at="comment.createdAt"
              :reaction-count="comment.reactionCount"
              :options="options"
            />
          </div>
          <button
            v-if="isConversation"
            class="show"
            @click="makeConversation"
          >
            {{ open ? 'Hide' : showMore(comment) }}
          </button>
        </div>

        <UbuCommonCommentActions
          v-if="!isError"
          :options="options"
          @clickToReply="$emit('clickToReply', $event)"
          @delete="$emit('delete', comment)"
          @markAsRead="$emit('markAsRead', $event)"
          @reportComment="$emit('reportComment', $event)"
          @translateMessage="$emit('translateMessage', $event)"
        />
      </div>
      <ul
        v-if="isConversation && open"
        :class="
          'comment' +
            [open ? ' active': '']
        "
      >
        <UbuCommonCommentRecursive
          v-for="(child, i) in childs"
          :key="i"
          class="item"
          :comment="child"
          :nesting="nextNesting"
          :level-nesting="levelNesting"
          :show-all="showAll"
          :options="options"
          :is-error="isError"
          :is-pending="isPending"
          @makeConversation="$emit('makeConversation', $event)"
          @clickToReply="$emit('clickToReply', $event)"
          @delete="$emit('delete', $event)"
          @markAsRead="$emit('markAsRead', $event)"
          @reportComment="$emit('reportComment', $event)"
          @resend="$emit('resend', $event)"
          @translateMessage="$emit('translateMessage', $event)"
        />
      </ul>
    </div>
  </li>
</template>

<script>
// import UbuCommonCommentRecursive from './UbuCommonCommentRecursive';

export default {
  name: 'UbuCommonCommentRecursive',
  props: {
    comment: {
      type: Object,
      default: () => {},
    },
    options: {
      type: Object,
      default: () => {},
    },
    nesting: {
      type: Number,
      default: 0,
    },
    levelNesting: {
      type: Number,
      default: 0,
    },
    showAll: {
      type: Boolean,
      default: false,
    },
    selectedId: {
      type: String,
      default: null,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    isPending: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      focus: false,
      hover: false,
      open: false,
      value: null,
      forward: false,
      liked: false,
      reactions: [],
    };
  },
  computed: {
    childs() {
      const childs = this.comment.children;
      return childs.sort((a, b) => a.createdAt - b.createdAt);
    },
    isConversation() {
      return this.comment.children && this.comment.children.length;
    },
    nextNesting() {
      return this.nesting + 1;
    },
    isFocus() {
      return this.comment.commentId === this.selectedId;
    },
  },
  watch: {
    showAll(value) {
      this.open = value;
    },
    selectedElement(id) {
      if (id === this.comment.commentId) {
        this.focus = true;
      } else {
        this.focus = false;
      }
    },
  },
  mounted() {
    this.openByLevelNesting();
  },
  methods: {
    toggle() {
      if (this.isConversation) this.open = !this.open;
    },
    makeConversation() {
      if (this.isConversation) {
        this.$emit('makeConversation', this.comment);
        this.open = !this.open;
      }
    },
    showMore(items) {
      return `${this.comment.hasMore ? 'Last ' : ''}${items.children.length} response${items.children.length > 1 ? 's' : ''}`;
    },
    openByLevelNesting() {
      if (this.nesting < this.levelNesting) {
        this.open = true;
      }
    },
    handleClick() {
      this.$emit('clickToReply', this.comment);
    },
  },
};
</script>
