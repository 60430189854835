const parseNote = (text) => {
  try {
    return JSON.parse(text);
  } catch (error) {
    return text;
  }
};

export default function (note, threadId) {
  const parsed = parseNote(note.text);
  const toSpread = typeof parsed === 'object' ? parsed : { text: parsed };
  return {
    ...note,
    ...toSpread,
    noteId: note._id,
    threadId,
  };
}
