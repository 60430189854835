import { arrayMapper, arrayMapperCumulate } from '../../$utils/prototypes';
import { sanitizeUbuUser } from '../../$utils/ubuUsers';
import {
  updateDatasIfExists,
  updateRelationsIfExists,
  updateObjectRelationIfExists,
  addNewKeysToMap,
} from '../$utils/dataMapper';
import internals from './internals';
import contactTemplate from '../template/contact.template';
import MediaProvider from '../providers/media.provider';
import GlobalProvider from '../providers/global.provider';

/* eslint-disable max-len */
export default {
  namespaced: true,

  state: {
    channels: {},
    fbChannel: { connected: false },
    fbChannelId: null,
    channelsHasContactsBis: {},
    channelsHasThreadsBis: {},
    channelsHasCampaignsBis: {},
    channelsHasLists: {},
    channelOrder: [],
    channelsIds: [],
    currentChannelId: null,
    channelsHasCampaigns: [],
    channelsHasCustomTags: [],
    channelsHasContacts: [],
    channelsHasThreads: [],
    channelsHasThreadGroups: [],
    channelsHasContactGroups: [],
    channelsHasUbuUsers: [],
    channelsHasTemplates: [],
    channelHasMediasFb: [],
    isDriveConnected: false,
    driveAuthUrl: null,
  },

  getters: {
    getterCurrentChannel: (state) => (state.currentChannelId && state.channels[state.currentChannelId]) || null,
    getterCurrentChannelPk: (state) => (state.currentChannelId && state.channels[state.currentChannelId] ? state.channels[state.currentChannelId].data.pk : null),
    getterCurrentChannelId: (state) => state.currentChannelId,
    getterChannels: (state) => state.channels,
    getterFbChannel: (state) => state.fbChannel,
    getterFbChannelId: (state) => state.fbChannelId,
    getterChannelsIds: (state) => state.channelsIds,
    getterChannelsHasCampaigns: (state) => state.channelsHasCampaigns,
    getterChannelsHasCampaignsBis: (state) => state.channelsHasCampaignsBis,
    getterChannelsHasCustoms: (state) => state.channelsHasCustomTags,
    getterChannelsHasContacts: (state) => state.channelsHasContacts,
    getterChannelsHasLists: (state) => state.channelsHasLists,
    getterChannelsHasThreads: (state) => state.channelsHasThreads,
    getterChannelsHasContactsBis: (state) => state.channelsHasContactsBis,
    getterChannelsHasThreadsBis: (state) => state.channelsHasThreadsBis,
    getterChannelsHasThreadGroups: (state) => state.channelsHasThreadGroups,
    getterChannelsHasContactGroups: (state) => state.channelsHasContactGroups,
    getterChannelsHasUbuUsers: (state) => state.channelsHasUbuUsers,
    getterChannelsHasTemplates: (state) => state.channelsHasTemplates,
    getterChannelsHasMediasFB: (state) => state.channelHasMediasFb,
    getterIsDriveConnected: (state) => state.isDriveConnected,
    getterDriveAuthUrl: (state) => state.driveAuthUrl,
  },

  mutations: {
    SET_CHANNELS(state, newDatas) { state.channels = updateDatasIfExists({ newDatas, actualDatas: state.channels, key: 'channelId' }); },
    SET_FB_CHANNEL(state, fbChannel) { state.fbChannel = fbChannel; },
    SET_FB_CHANNEL_ID(state, fbChannelId) { state.fbChannelId = fbChannelId; },
    SET_AD_ACCOUNT_ID(state, adAccountId) { state.fbChannel.adAccountId = adAccountId; },
    SET_CHANNELS_IDS(state, newDatas) { state.channelsIds = updateRelationsIfExists(state.channelsIds, newDatas); },
    SET_CHANNELS_HAS_CAMPAIGNS(state, newDatas) { state.channelsHasCampaigns = updateRelationsIfExists(state.channelsHasCampaigns, newDatas); },
    SET_CHANNELS_HAS_CAMPAIGNS_BIS(state, newDatas) { state.channelsHasCampaignsBis = updateObjectRelationIfExists({ newDatas, actualDatas: state.channelsHasCampaignsBis }); },
    SET_CHANNELS_HAS_LISTS(state, newDatas) { state.channelsHasLists = updateObjectRelationIfExists({ newDatas, actualDatas: state.channelsHasLists }); },
    ADD_CAMPAIGNS_TO_CHANNEL(state, { channelId, campaignIds }) { state.channelsHasCampaignsBis = addNewKeysToMap({ map: state.channelsHasCampaignsBis, key: channelId, values: campaignIds }); },
    ADD_LISTS_TO_CHANNEL(state, { channelId, listIds }) { state.channelsHasLists = addNewKeysToMap({ map: state.channelsHasLists, key: channelId, values: listIds }); },
    SET_CHANNELS_HAS_CUSTOM_TAGS(state, newDatas) { state.channelsHasCustomTags = updateRelationsIfExists(state.channelsHasCustomTags, newDatas); },
    SET_CHANNELS_HAS_CONTACTS(state, newDatas) { state.channelsHasContacts = updateRelationsIfExists(state.channelsHasContacts, newDatas); },
    SET_CHANNELS_HAS_THREADS(state, newDatas) { state.channelsHasThreads = updateRelationsIfExists(state.channelsHasThreads, newDatas); },
    SET_CHANNELS_HAS_UBUUSERS(state, newDatas) { state.channelsHasUbuUsers = updateRelationsIfExists(state.channelsHasUbuUsers, newDatas); },
    SET_CHANNELS_HAS_CONTACT_GROUPS(state, newDatas) { state.channelsHasContactGroups = updateRelationsIfExists(state.channelsHasContactGroups, newDatas); },
    SET_CHANNELS_HAS_THREAD_GROUPS(state, newDatas) { state.channelsHasThreadGroups = updateRelationsIfExists(state.channelsHasThreadGroups, newDatas); },
    SET_CHANNELS_HAS_TEMPLATES(state, newDatas) { state.channelsHasTemplates = updateRelationsIfExists(state.channelsHasTemplates, newDatas); },
    SET_CHANNEL_HAS_MEDIASFB(state, newDatas) { state.channelHasMediasFb = updateRelationsIfExists(state.channelHasMediasFb, newDatas); },
    SET_CURRENT_CHANNEL_ID(state, channelId) { state.currentChannelId = channelId; },
    SET_CHANNEL_ORDER(state, channelOrder) { state.channelOrder = channelOrder; },
    SET_IS_DRIVE_CONNECTED(state, isDriveConnected) { state.isDriveConnected = isDriveConnected; },
    SET_DRIVE_AUTH_URL(state, driveAuthUrl) { state.driveAuthUrl = driveAuthUrl; },

    SET_CHANNELS_HAS_CONTACTS_BIS(state, newDatas) { state.channelsHasContactsBis = updateObjectRelationIfExists({ newDatas, actualDatas: state.channelsHasContactsBis, key: 'channelId' }); },
    ADD_CONTACT_TO_CHANNEL(state, { channelId, contactIds }) { state.channelsHasContactsBis = addNewKeysToMap({ map: state.channelsHasContactsBis, key: channelId, values: contactIds }); },

    SET_CHANNELS_HAS_THREADS_BIS(state, newDatas) { state.channelsHasThreadsBis = updateObjectRelationIfExists({ newDatas, actualDatas: state.channelsHasThreadsBis, key: 'channelId' }); },

    UNSET_CHANNEL(state, channelId) {
      const myChannels = Object.values({ ...state.channels }).filter((c) => c.channelId !== channelId);
      state.channels = arrayMapper(myChannels, 'channelId');
    },
    UNSET_CHANNEL_HAS_TEMPLATES(state, templateIdToRemove) { state.channelsHasTemplates.splice(state.channelsHasTemplates.findIndex(({ templateId }) => templateId === templateIdToRemove), 1); },
    UNSET_CHANNEL_HAS_CUSTOM_TAGS(state, tagIdToRemove) { state.channelsHasCustomTags.splice(state.channelsHasCustomTags.findIndex(({ tagId }) => tagId === tagIdToRemove), 1); },
    UNSET_CHANNEL_HAS_THREAD_GROUPS(state, threadGroupIdToRemove) { state.channelsHasThreadGroups.splice(state.channelsHasThreadGroups.findIndex(({ threadGroupId }) => threadGroupId === threadGroupIdToRemove), 1); },
    UNSET_CHANNEL_HAS_CONTACT_GROUP(state, contactGroupIdToRemove) { state.channelsHasContactGroups.splice(state.channelsHasContactGroups.findIndex(({ contactGroupId }) => contactGroupId === contactGroupIdToRemove), 1); },
    UNSET_CHANNEL_HAS_UBUUSER(state, ubuUserIdToRemove) { state.channelsHasUbuUsers.splice(state.channelsHasUbuUsers.findIndex(({ ubuUserId }) => ubuUserId === ubuUserIdToRemove), 1); },
    RESET_STORES(state) {
      state.channels = {};
      state.channelOrder = [];
      state.channelsIds = [];
      // state.currentChannelId = null;
      state.channelsHasCampaigns = [];
      state.channelsHasLists = {};
      state.channelsHasCustomTags = [];
      state.channelsHasContacts = [];
      state.channelsHasThreads = [];
      state.channelsHasThreadGroups = [];
      state.channelsHasContactGroups = [];
      state.channelsHasUbuUsers = [];
      state.channelsHasTemplates = [];
      state.isDriveConnected = false;
      state.driveAuthUrl = null;
      state.fbChannel = { connected: false };
      state.fbChannelId = null;
    },
  },

  actions: {
    fetchChannels({ commit }) {
      return Promise.resolve()
        .then(() => internals.fetchChannels())
        .then(({ response: channelList }) => {
          // console.log('🚀 ~ file: Channel.store.js ~ line 45 ~ .then ~ channelList', channelList);
          const channelsMapped = arrayMapper(channelList.map((c) => ({ ...c, channelId: c._id })), 'channelId');
          // console.log('🚀 ~ file: Channel.store.js ~ line 33 ~ .then ~ channelsMapped', channelsMapped);
          commit('SET_CHANNELS', channelsMapped);

          const contactDataExtended = Object.entries(channelsMapped)
            .flatMap(([channelId, channel]) => channel.account.contacts.map((c) => ({ ...contactTemplate(c), channelId })));
          // console.log('🚀 ~ file: Channel.store.js ~ line 43 ~ .then ~ contactDataExtended', contactDataExtended);
          // console.log('🚀 ~ file: Channel.store.js ~ line 43 ~ .then ~ contactDataExtended', Object.values(contactDataExtended).length);

          const contactDataExtendedMapped = arrayMapper(contactDataExtended.map(({ channelId, ...rest }) => rest), 'contactId');
          // console.log('🚀 ~ file: Channel.store.js ~ line 48 ~ .then ~ contactDataExtendedMapped', contactDataExtendedMapped);
          // console.log('🚀 ~ file: Channel.store.js ~ line 48 ~ .then ~ contactDataExtendedMapped', Object.values(contactDataExtendedMapped).length);
          commit('Contact/SET_CONTACTS', contactDataExtendedMapped, { root: true });

          const channelsHasContacts = Object.values(contactDataExtended)
            .map(({ channelId, contactId }) => ({ channelId, contactId }))
            .reduce((acc, row) => {
              const [key1, key2] = Object.keys(row);
              if (!acc.find((r) => r[key1] === row[key1] && r[key2] === row[key2])) { acc.push(row); }
              return acc;
            }, []);
          // console.log('🚀 ~ file: Channel.store.js ~ line 51 ~ .then ~ channelsHasContacts', channelsHasContacts.filter(({ channelId }) => channelId === '5f16a4ac59554b04ed0af866'));
          commit('SET_CHANNELS_HAS_CONTACTS', channelsHasContacts);

          const ubuUsersList = Object.entries(channelsMapped)
            .flatMap(([channelId, channel]) => channel.users.map((u) => ({ ...u, channelId, ubuUserId: u._id })));
          // console.log('🚀 ~ file: Channel.store.js ~ line 41 ~ .then ~ ubuUsersList', ubuUsersList);

          const ubuUsersSanitized = ubuUsersList
            // .map(({ channelId, ...rest }) => rest)
            .map(sanitizeUbuUser);
          const ubuUsersMapped = arrayMapperCumulate(ubuUsersSanitized, 'ubuUserId', { from: 'channelId', to: 'channelIds' });
          // console.log('🚀 ~ file: Channel.store.js ~ line 41 ~ .then ~ ubuUsersMapped', ubuUsersMapped);

          const ubuUserState = Object.values(ubuUsersMapped).map(({
            firstName,
            lastName,
            email,
            ubuUserId,
          }) => ({
            firstName,
            lastName,
            email,
            ubuUserId,
            doing: '',
            threadId: '',
          }));

          // console.log('🚀 ~ file: Channel.store.js ~ line 90 ~ .then ~ ubuUserState', ubuUserState);
          commit('UbuUser/SET_UBUUSERS', ubuUsersMapped, { root: true });
          commit('State/SET_UBUUSERS_DOING', arrayMapper(ubuUserState, 'ubuUserId'), { root: true });

          const channelsHasUbuUsers = Object.values(ubuUsersList)
            .map(({ channelId, ubuUserId }) => ({ channelId, ubuUserId }))
            .reduce((acc, row) => {
              const [key1, key2] = Object.keys(row);
              if (!acc.find((r) => r[key1] === row[key1] && r[key2] === row[key2])) { acc.push(row); }
              return acc;
            }, []);
          // console.log('🚀 ~ file: Channel.store.js ~ line 45 ~ .then ~ channelsHasUbuUsers', channelsHasUbuUsers);
          commit('SET_CHANNELS_HAS_UBUUSERS', channelsHasUbuUsers);

          const localChannelId = localStorage.getItem('channel_id');
          let currentChannelId;
          if (localChannelId && channelsMapped[localChannelId]) {
            currentChannelId = localChannelId;
          } else {
            const channels = Object.keys(channelsMapped) || [];
            [currentChannelId] = channels;
          }
          // console.log('🚀 ~ file: Channel.store.js ~ line 92 ~ .then ~ currentChannelId', currentChannelId);
          internals.channelsIds = [currentChannelId];
          // internals.channelsIds = ['5f16a4ac59554b04ed0af866'];
          commit('SET_CURRENT_CHANNEL_ID', currentChannelId);
          commit('SET_CHANNELS_IDS', internals.channelsIds);
          // internals.channelsIds = [channelsMapped[0]];
        });
    },
    fetchFbChannels({ commit }) {
      return Promise.resolve()
        .then(() => internals.fetchFbChannel())
        .then(({ response: channelList }) => {
          console.log('🚀 ~ file: Channel.store.js ~ line 45 ~ .then ~ channelList', channelList);
          if (!channelList.length) {
            throw new Error('No Channel connected, please sign up.');
          }
          const channelsMapped = arrayMapper(channelList.map((c) => ({ ...c, channelId: c._id })), 'channelId');
          // console.log('🚀 ~ file: Channel.store.js ~ line 33 ~ .then ~ channelsMapped', channelsMapped);
          commit('SET_CHANNELS', channelsMapped);

          const contactDataExtended = Object.entries(channelsMapped)
            .flatMap(([channelId, channel]) => channel.account.contacts.map((c) => ({ ...contactTemplate(c), channelId })));
          // console.log('🚀 ~ file: Channel.store.js ~ line 43 ~ .then ~ contactDataExtended', contactDataExtended);
          // console.log('🚀 ~ file: Channel.store.js ~ line 43 ~ .then ~ contactDataExtended', Object.values(contactDataExtended).length);

          const contactDataExtendedMapped = arrayMapper(contactDataExtended.map(({ channelId, ...rest }) => rest), 'contactId');
          // console.log('🚀 ~ file: Channel.store.js ~ line 48 ~ .then ~ contactDataExtendedMapped', contactDataExtendedMapped);
          // console.log('🚀 ~ file: Channel.store.js ~ line 48 ~ .then ~ contactDataExtendedMapped', Object.values(contactDataExtendedMapped).length);
          commit('Contact/SET_CONTACTS', contactDataExtendedMapped, { root: true });

          const channelsHasContacts = Object.values(contactDataExtended)
            .map(({ channelId, contactId }) => ({ channelId, contactId }))
            .reduce((acc, row) => {
              const [key1, key2] = Object.keys(row);
              if (!acc.find((r) => r[key1] === row[key1] && r[key2] === row[key2])) { acc.push(row); }
              return acc;
            }, []);
          // console.log('🚀 ~ file: Channel.store.js ~ line 51 ~ .then ~ channelsHasContacts', channelsHasContacts.filter(({ channelId }) => channelId === '5f16a4ac59554b04ed0af866'));
          commit('SET_CHANNELS_HAS_CONTACTS', channelsHasContacts);

          const ubuUsersList = Object.entries(channelsMapped)
            .flatMap(([channelId, channel]) => channel.users.map((u) => ({ ...u, channelId, ubuUserId: u._id })));
          // console.log('🚀 ~ file: Channel.store.js ~ line 41 ~ .then ~ ubuUsersList', ubuUsersList);

          const ubuUsersMapped = arrayMapper(ubuUsersList.map(({ channelId, ...rest }) => rest), 'ubuUserId');
          // console.log('🚀 ~ file: Channel.store.js ~ line 41 ~ .then ~ ubuUsersList', ubuUsersMapped);
          commit('UbuUser/SET_UBUUSERS', ubuUsersMapped, { root: true });

          const channelsHasUbuUsers = Object.values(ubuUsersList)
            .map(({ channelId, ubuUserId }) => ({ channelId, ubuUserId }))
            .reduce((acc, row) => {
              const [key1, key2] = Object.keys(row);
              if (!acc.find((r) => r[key1] === row[key1] && r[key2] === row[key2])) { acc.push(row); }
              return acc;
            }, []);
          // console.log('🚀 ~ file: Channel.store.js ~ line 45 ~ .then ~ channelsHasUbuUsers', channelsHasUbuUsers);
          commit('SET_CHANNELS_HAS_UBUUSERS', channelsHasUbuUsers);

          const localChannelId = localStorage.getItem('channel_id');
          let currentChannelId;
          if (localChannelId && channelsMapped[localChannelId]) {
            currentChannelId = localChannelId;
          } else {
            const channels = Object.keys(channelsMapped) || [];
            [currentChannelId] = channels;
          }
          // console.log('🚀 ~ file: Channel.store.js ~ line 92 ~ .then ~ currentChannelId', currentChannelId);
          internals.channelsIds = [currentChannelId];
          // internals.channelsIds = ['5f16a4ac59554b04ed0af866'];
          commit('SET_CURRENT_CHANNEL_ID', currentChannelId);
          commit('SET_CHANNELS_IDS', internals.channelsIds);
          // internals.channelsIds = [channelsMapped[0]];
        });
    },
    switchChannel({ commit, getters }, { channel }) {
      return Promise.resolve()
        .then(() => {
          const oldChannelId = getters.getterCurrentChannelId;
          const newChanId = channel.channelId;

          commit('Sequence/RESET_STORES', null, { root: true });
          commit('Activity/RESET_STORES', null, { root: true });
          commit('Campaign/RESET_STORES', null, { root: true });
          commit('Contact/RESET_STORES', null, { root: true });
          commit('ContactGroup/RESET_STORES', null, { root: true });
          commit('CustomTag/RESET_STORES', null, { root: true });
          commit('Gossip/RESET_STORES', null, { root: true });
          commit('Media/RESET_STORES', null, { root: true });
          commit('Mention/RESET_STORES', null, { root: true });
          commit('Message/RESET_STORES', null, { root: true });
          commit('Note/RESET_STORES', null, { root: true });
          commit('TagPost/RESET_STORES', null, { root: true });
          commit('Template/RESET_STORES', null, { root: true });
          commit('Thread/RESET_STORES', null, { root: true });
          commit('ThreadGroup/RESET_STORES', null, { root: true });
          commit('UbuUser/RESET_STORES', null, { root: true });
          commit('Channel/RESET_STORES', null, { root: true });
          commit('TheWorkspace/RESET_STORES', null, { root: true });

          console.log('🟦 Switching channel from', oldChannelId, 'to', newChanId);
          internals.channelsIds = [newChanId];
          commit('SET_CHANNELS_IDS', internals.channelsIds);
          commit('SET_CURRENT_CHANNEL_ID', newChanId);
          localStorage.setItem('channel_id', newChanId);
        })
        .then(() => this.dispatch('Sequence/fetchAll'));
    },

    isDriveActive({ commit, rootGetters }) {
      const {
        'Channel/getterCurrentChannelId': channelId,
      } = rootGetters;

      return MediaProvider
        .isDriveActive({ payload: { channelId } })
        .then(({ response, error }) => {
          if (error) {
            return false;
          }
          commit('SET_IS_DRIVE_CONNECTED', response);
          return response;
        });
    },

    isFacebookConnected({ commit, rootGetters }) {
      const {
        'Channel/getterCurrentChannel': channel,
      } = rootGetters;
      // console.log('🚀 ~ file: Channel.store.js ~ line 314 ~ isFacebookConnected ~ channel', channel);
      const { username } = channel;

      return GlobalProvider
        .isFacebookConnected({ payload: { username } })
        .then(({ response, error }) => {
          // console.log('🚀 ~ file: Channel.store.js ~ line 321 ~ .then ~ error', error);
          // console.log('🚀 ~ file: Channel.store.js ~ line 321 ~ .then ~ response', response);
          if (error) {
            commit('SET_FB_CHANNEL', { connected: false });
            commit('SET_FB_CHANNEL_ID', null);
            internals.fbChannelId = null;
            return false;
          }
          commit('SET_FB_CHANNEL', response);
          commit('SET_FB_CHANNEL_ID', response._id);
          internals.fbChannelId = response._id;
          return response;
        });
    },

    SOCKET_isDriveConnected({ commit, getters }, { state: isDriveConnected, channelId }) {
      if (getters.getterCurrentChannelId === channelId) {
        commit('SET_IS_DRIVE_CONNECTED', isDriveConnected);
      }
    },

    driveAuthUrl({ commit, rootGetters }) {
      const {
        'Channel/getterCurrentChannelId': channelId,
      } = rootGetters;

      return MediaProvider
        .getDriveAuthUrl({ payload: { channelId } })
        .then(({ response, error }) => {
          if (error) {
            return false;
          }
          commit('SET_DRIVE_AUTH_URL', response);
          return response;
        });
    },
  },

};
